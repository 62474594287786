<div class="modal-header">
  <h4>Synchroniser les plans</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->

  <div class="modal-body">

    <ng-select  [items]="versions"
    [searchable]="false"
    [clearable]="false"
    bindLabel="versionString"
    [(ngModel)]="version"
    placeholder="Version Nombre de pièges...">
  </ng-select>

    <div class="modal-footer">
      <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" ngbButton>
      <button type="submit" (click)="syncPieges();" class="btn btn-outline-success" ngbButton>
        
        <span>Synchroniser</span>
      </button>
    </div>
  </div>
