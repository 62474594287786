import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Designer } from '../../models/management/designer';
import { ToasterService } from 'angular2-toaster';

const strings = {
  titles : {
    added: 'Dessinateur ajouté', notAdded: 'Dessinateur non ajouté',
    edited: 'Dessinateur modifié', notEdited: 'Dessinateur non modifié',
    deleted: 'Dessinateur supprimé', notDeleted: 'Dessinateur non supprimé'
  },
  bodies: {
    internalError: 'Erreur interne',
    designerDoesntExsist: 'Dessinateur inexistant',
    unauthorized: 'Opération non autorisée'
  }
}

@Injectable({
  providedIn: 'root',
})
export class DesignerService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) { }

  // Codes: DESIGNER_RECOVERED | UNAUTHORIZED
  public getAll(): Observable<ApiResponse<Designer[]>> {
    return this.http.get<ApiResponse<Designer[]>>(environment.apiUrl + 'designer/all').pipe(map(
      res => {
        switch (res.code) {

          case Code.DESIGNER_RECOVERED:
          var designers: Designer[] = [];
          res.result.forEach(jsonDesigner => designers.push(Designer.loadFromJSON(jsonDesigner)));
          res.result = designers;
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.bodies.unauthorized);
          break;
        
        }
      
        return res;
      }
    ))
  }

  // Codes: DESIGNER_CREATED | WRONG_PARAMS | DESIGNER_ALREADY_EXSIST | UNAUTHORIZED
  public create(form: FormGroup): Observable<ApiResponse<Designer>> {
    return this.http.post<ApiResponse<Designer>>(environment.apiUrl + 'designer', form.value).pipe(map(
      res => {
        switch (res.code) {

          case Code.DESIGNER_CREATED:
          res.result = Designer.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.added);
          break;

          case Code.WRONG_PARAMS:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.internalError);
          break;

          case Code.DESIGNER_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: DESIGNER_EDITED | DESIGNER_DOESNT_EXSIST | DESIGNER_ALREADY_EXSIST | UNAUTHORIZED
  public edit(id: string, form: FormGroup): Observable<ApiResponse<Designer>> {
    return this.http.post<ApiResponse<Designer>>(environment.apiUrl + 'designer/' + id, form.value).pipe(map(
      res => {
        switch (res.code) {

          case Code.DESIGNER_EDITED:
          res.result = Designer.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.edited);
          break;

          case Code.DESIGNER_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notEdited, strings.bodies.designerDoesntExsist);
          break;

          case Code.DESIGNER_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notEdited, strings.bodies.unauthorized);
          break;

        }
        
        return res;
      }
    ))
  }

  // Codes: DESIGNER_DELETED | DESIGNER_DOESNT_EXSIST | UNAUTHORIZED
  public delete(id: string): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(environment.apiUrl + 'designer/' + id).pipe(map(
      res => {
        switch (res.code) {

          case Code.DESIGNER_DELETED:
          this.toasterService.pop('success', strings.titles.deleted);
          break;

          case Code.DESIGNER_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notDeleted, strings.bodies.designerDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notDeleted, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

}