import { Site } from "./site";
import { User } from "./user";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class Operator extends User {
  @jsonArrayMember(() => Site)
  sites: Site[];
  
  @jsonMember
  isAdmin: boolean;
  
  @jsonMember
  isAlertsEnabled: boolean;

  static loadFromJSON(json): Operator {
    let serializer = new TypedJSON(Operator);
		return serializer.parse(json);
  }
}