<div class="card mt-3">

  <!-- Loading -->
  <div class="card-body text-center" *ngIf="!user"><i class="fa fa-circle-o-notch fa-spin"></i></div>

  <!-- Form -->
  <form [formGroup]="form" (ngSubmit)="onFormSubmit()" *ngIf="user">
    <div class="card-body">

      <!-- Username -->
      <div class="form-group">
        <label for="username">Email</label>
        <input type="email" class="form-control" id="username" placeholder="Email" formControlName="username">
      </div>

      <!-- Last name -->
      <div class="form-group">
        <label for="lastName">Nom</label>
        <input type="text" class="form-control" id="lastName" placeholder="Nom" formControlName="lastName">
      </div>

      <!-- First name -->
      <div class="form-group">
        <label for="firstName">Prénom</label>
        <input type="text" class="form-control" id="firstName" placeholder="Prénom" formControlName="firstName">
      </div>

      <!-- Phone -->
      <div class="form-group mb-4">
        <label for="phone">Téléphone</label>
        <input type="text" class="form-control" id="phone" placeholder="Téléphone" formControlName="phone">
      </div>

      <button type="submit" class="btn btn-primary" [disabled]="formSubmitted" ngbButton>
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
        <span *ngIf="!formSubmitted">Enregistrer</span>
      </button>

    </div>
  </form>

  <!-- Password Form -->
  <form [formGroup]="passwordForm" (ngSubmit)="onPasswordFormSubmit()" *ngIf="user">
    <div class="card-body">

      <!-- password -->
      <div class="form-group">
        <label for="password">Nouveau mot de passe</label>
        <input type="email" class="form-control" id="password" placeholder="Nouveau mot de passe" formControlName="password">
      </div>

      <button type="submit" class="btn btn-primary" [disabled]="passwordFormSubmitted" ngbButton>
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="passwordFormSubmitted"></i>
        <span *ngIf="!passwordFormSubmitted">Enregistrer</span>
      </button>

    </div>
  </form>

</div>