<div class="row mt-3">
  <!-- Date Range -->
  <div class="col-sm-6">
    <div class="form-group">
      <label>Du</label>
      <div class="input-group">
        <input
          class="form-control"
          [(ngModel)]="from"
          ngbDatepicker
          #dFrom="ngbDatepicker"
          (ngModelChange)="onDateSelection()"
          [ngClass]="{ 'is-invalid': wrongRange }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            (click)="dFrom.toggle()"
            type="button"
          >
            <i class="fa fa-calendar"></i>
          </button>
        </div>
        <div class="invalid-feedback" *ngIf="wrongRange">
          La date de début est après la date de fin
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="form-group">
      <label>Au</label>
      <div class="input-group">
        <input
          class="form-control"
          [(ngModel)]="to"
          ngbDatepicker
          #dTo="ngbDatepicker"
          (ngModelChange)="onDateSelection()"
          [ngClass]="{ 'is-invalid': wrongRange }"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            (click)="dTo.toggle()"
            type="button"
          >
            <i class="fa fa-calendar"></i>
          </button>
        </div>
        <div class="invalid-feedback" *ngIf="wrongRange">
          La date de début est après la date de fin
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">

<ul ngbNav #nav="ngbNav" (activeIdChange)="navChanged()" class="nav-pills">
      <!-- Content -->
      <li ngbNavItem>
        <a ngbNavLink>Contenu</a>
        <ng-template ngbNavContent>
          <app-upc-group-stats-content
            [upcv3Group]="upcv3Group"
            [from]="from"
            [to]="to"
            [dateChanged]="dateChangeEvent"
          ></app-upc-group-stats-content>
        </ng-template>
      </li>

      <!-- Conso -->
      <li ngbNavItem>
        <a ngbNavLink>Consommation</a>
        <ng-template ngbNavContent>
          <app-upc-group-stats-consommation
            [upcv3Group]="upcv3Group"
            [from]="from"
            [to]="to"
            [dateChanged]="dateChangeEvent"
          ></app-upc-group-stats-consommation>
        </ng-template>
      </li>

      <!-- Diffusions -->
      <li ngbNavItem>
        <a ngbNavLink>Diffusions</a>
        <ng-template ngbNavContent>
          <app-upc-group-stats-diffusion
          *ngFor="let upc of upcv3Group.upcv3s"
            [upcv3]="upc"
            [from]="from"
            [to]="to"
            [dateChanged]="dateChangeEvent"
          ></app-upc-group-stats-diffusion>
        </ng-template>
      </li>

      <!-- Diffusions / CalendarView-->
      <li ngbNavItem>
        <a ngbNavLink>Diffusions (Vue Bétâ)</a>
        <ng-template ngbNavContent>
          <app-upc-group-stats-diffusion-beta
            [upcv3Group]="upcv3Group"
            [from]="from"
            [to]="to"
            [dateChanged]="dateChangeEvent"
          ></app-upc-group-stats-diffusion-beta>
        </ng-template>
      </li>

      <!-- Table -->
      <li ngbNavItem>
        <a ngbNavLink>Table</a>
        <ng-template ngbNavContent>
          <app-upc-group-stats-table
            [upcv3Group]="upcv3Group"
            [from]="from"
            [to]="to"
            [dateChanged]="dateChangeEvent"
          ></app-upc-group-stats-table>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
