export interface ApiResponse<T> {
    code: Code;
    result: T;
}

export type SortModel = {
    empty?: boolean;
    sorted?: boolean;
    unsorted?: boolean;
};

export type PageableModel = {
    offset?: number;
    sort?: SortModel;
    pageSize?: number;
    pageNumber?: number;
    paged?: boolean;
    unpaged?: boolean;
};

export interface Page<T> {
    content: T[];
    totalPages: number;
    totalElements: number;
    size: number;
    number: number;
    sort: SortModel;
    pageable: PageableModel;
    first: boolean;
    last: boolean;
    numberOfElements: number;
    empty: boolean;
}

export interface PagingResponse<T> extends ApiResponse<Page<T>> {}

export enum Code {
    // General
    WRONG_PARAMS = 'WRONG_PARAMS', INTERNAL_ERROR = 'INTERNAL_ERROR', UNAUTHORIZED = 'UNAUTHORIZED', OPERATION_OK = 'OPERATION_OK',

    // Token
    TOKEN_WRONG_IDENTIFIERS = 'TOKEN_WRONG_IDENTIFIERS', TOKEN_LOGGED_IN = 'TOKEN_LOGGED_IN',

    // Operator
    OPERATOR_RECOVERED = 'OPERATOR_RECOVERED', OPERATOR_CREATED = 'OPERATOR_CREATED', OPERATOR_DOESNT_EXSIST = 'OPERATOR_DOESNT_EXSIST',
    OPERATOR_ALREADY_EXSIST = 'OPERATOR_ALREADY_EXSIST', OPERATOR_EDITED = 'OPERATOR_EDITED', OPERATOR_DELETED = 'OPERATOR_DELETED',

    // Designer
    DESIGNER_RECOVERED = 'DESIGNER_RECOVERED', DESIGNER_DOESNT_EXSIST = 'DESIGNER_DOESNT_EXSIST', DESIGNER_ALREADY_EXSIST = 'DESIGNER_ALREADY_EXSIST',
    DESIGNER_CREATED = 'DESIGNER_CREATED', DESIGNER_EDITED = 'DESIGNER_EDITED', DESIGNER_DELETED = 'DESIGNER_DELETED',

    // Site
    SITE_RECOVERED = 'SITE_RECOVERED', SITE_CREATED = 'SITE_CREATED', SITE_DOESNT_EXSIST = 'SITE_DOESNT_EXSIST', SITE_ALREADY_EXSIST = 'SITE_ALREADY_EXSIST',
    SITE_EDITED = 'SITE_EDITED', SITE_DELETED = 'SITE_DELETED', SITE_USED = 'SITE_USED',

    // Stock
    STOCK_RECOVERED = 'STOCK_RECOVERED', STOCK_CREATED = 'STOCK_CREATED', STOCK_DOESNT_EXSIST = 'STOCK_DOESNT_EXSIST',
    STOCK_ALREADY_EXSIST = 'STOCK_ALREADY_EXSIST', STOCK_EDITED = 'STOCK_EDITED', STOCK_DELETED = 'STOCK_DELETED', STOCK_USED = 'STOCK_USED',

    // BottleType
    BOTTLE_TYPE_RECOVERED = 'BOTTLE_TYPE_RECOVERED', BOTTLE_TYPE_CREATED = 'BOTTLE_TYPE_CREATED', BOTTLE_TYPE_DOESNT_EXSIST = 'BOTTLE_TYPE_DOESNT_EXSIST',
    BOTTLE_TYPE_ALREADY_EXSIST = 'BOTTLE_TYPE_ALREADY_EXSIST', BOTTLE_TYPE_DELETED = 'BOTTLE_TYPE_DELETED', BOTTLE_TYPE_USED = 'BOTTLE_TYPE_USED',

    // Bottle
    BOTTLE_RECOVERED = 'BOTTLE_RECOVERED', BOTTLE_CREATED = 'BOTTLE_CREATED', BOTTLE_DOESNT_EXSIST = 'BOTTLE_DOESNT_EXSIST',
    BOTTLE_ALREADY_EXSIST = 'BOTTLE_ALREADY_EXSIST', BOTTLE_DELETED = 'BOTTLE_DELETED',

    // Project
    PROJECT_RECOVERED = 'PROJECT_RECOVERED', PROJECT_DOESNT_EXSIST = 'PROJECT_DOESNT_EXSIST', PROJECT_ALREADY_EXSIST = 'PROJECT_ALREADY_EXSIST',
    PROJECT_CREATED = 'PROJECT_CREATED', PROJECT_EDITED = 'PROJECT_EDITED', PROJECT_DELETED = 'PROJECT_DELETED', PROJECT_CANNOT_DELETE = 'PROJECT_CANNOT_DELETE',

    // Version
    VERSION_RECOVERED = 'VERSION_RECOVERED', VERSION_DOESNT_EXSIST = 'VERSION_DOESNT_EXSIST', VERSION_ALREADY_EXSIST = 'VERSION_ALREADY_EXSIST',
    VERSION_CREATED = 'VERSION_CREATED', VERSION_EDITED = 'VERSION_EDITED', VERSION_SYNCHRONIZED = 'VERSION_SYNCHRONIZED', VERSION_DELETED = 'VERSION_DELETED',

    // Tasks
    TASK_RECOVERED = 'TASK_RECOVERED', TASK_ALREADY_RUNNING = 'TASK_ALREADY_RUNNING',

    // UPCV3
    UPCV3_RECOVERED = 'UPCV3_RECOVERED', UPCV3_DOESNT_EXSIST = 'UPCV3_DOESNT_EXSIST', UPCV3_EDITED = 'UPCV3_EDITED',
    INTERVENTIONV3_RECOVERED = 'INTERVENTIONV3_RECOVERED', INTERVENTIONV3_DOESNT_EXSIST = 'INTERVENTIONV3_DOESNT_EXSIST',
    INTERVENTIONV3_CREATED = 'INTERVENTIONV3_CREATED', INTERVENTIONV3_DELETED = 'INTERVENTIONV3_DELETED',
    INTERVENTIONV3_USED = 'INTERVENTIONV3_USED', OBSERVATIONV3_RECOVERED = 'OBSERVATIONV3_RECOVERED',
    EVENT_RECOVERED = 'EVENT_RECOVERED', STATS_RECOVERED = 'STATS_RECOVERED', UPCV3_CREATED = 'UPCV3_CREATED',
    UPCV3_DELETED = 'UPCV3_DELETED', UPCV3_REFILL_OK = 'UPCV3_REFILL_OK', UPCV3_ARCHIVED = 'UPCV3_ARCHIVED',
    
    // Firmware
    FIRMWARE_RECOVERED = 'FIRMWARE_RECOVERED', FIRMWARE_UPLOADED = 'FIRMWARE_UPLOADED', FIRMWARE_DELETED = 'FIRMWARE_DELETED',
    FIRMWARE_FLASH_STARTED = 'FIRMWARE_FLASH_STARTED', FIRMWARE_FLASH_ERROR = 'FIRMWARE_FLASH_ERROR',
    
    // Poll / Init / Restore
    POLL_STARTED = 'POLL_STARTED', INIT_STARTED = 'INIT_STARTED', RESTORE_STARTED = 'RESTORE_STARTED',
    
    // Default UPCV3 Params
    DEFAULT_UPCV3_PARAMS_RECOVERED = 'DEFAULT_UPCV3_PARAMS_RECOVERED', DEFAULT_UPCV3_PARAMS_EDITED = 'DEFAULT_UPCV3_PARAMS_EDITED',
    
    // Modbus
    MODBUS_CONNECTION_ERROR = 'MODBUS_CONNECTION_ERROR', MODBUS_WRITE_ERROR = 'MODBUS_WRITE_ERROR', MODBUS_READ_ERROR = 'MODBUS_READ_ERROR',
    
    // Sync
    SYNC_STARTED = 'SYNC_STARTED', SYNC_START_ERROR = 'SYNC_START_ERROR', SYNC_ERROR = 'SYNC_ERROR', SYNC_TIMEOUT = 'SYNC_TIMEOUT', SYNC_OK = 'SYNC_OK',
    
    //WIPE
    WIPE_OK = 'WIPE_OK', WIPE_ERROR = 'WIPE_ERROR',
    
    // UPCV2
    UPCV2_RECOVERED = 'UPCV2_RECOVERED', UPCV2_DOESNT_EXSIST = 'UPCV2_DOESNT_EXSIST', UPCV2_EDITED = 'UPCV2_EDITED',
    INTERVENTIONV2_RECOVERED = 'INTERVENTIONV2_RECOVERED', INTERVENTIONV2_DOESNT_EXSIST = 'INTERVENTIONV2_DOESNT_EXSIST',
    INTERVENTIONV2_CREATED = 'INTERVENTIONV2_CREATED', INTERVENTIONV2_EDITED = 'INTERVENTIONV2_EDITED',
    INTERVENTIONV2_DELETED = 'INTERVENTIONV2_DELETED', INTERVENTIONV2_USED = 'INTERVENTIONV2_USED',
    OBSERVATIONV2_RECOVERED = 'OBSERVATIONV2_RECOVERED',

    // Tests
    UPCMCU_RECOVERED = 'UPCMCU_RECOVERED',
    // Jobs
    JOB_RECOVERED = 'JOB_RECOVERED', JOB_DELETED = 'JOB_DELETED', JOB_ERROR = 'JOB_ERROR'
}
