import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '../../models/ApiResponse';
import { BottleType } from '../../models/management/bottleType';
import { BottleTypePostDto } from 'src/app/models/dto/BottleTypePost.dto';

@Injectable({
  providedIn: 'root',
})
export class BottleTypeService {

  constructor(
    private http: HttpClient,
  ) { }

  // Codes: BOTTLE_TYPE_RECOVERED | UNAUTHORIZED
  public get(id: number): Observable<BottleType> {
    return this.http.get<ApiResponse<BottleType>>(`${environment.apiUrl}bottleType/${id}`).pipe(map(
      res => BottleType.loadFromJSON(res.result)
    ))
  }
  public getAll(): Observable<BottleType[]> {
    return this.http.get<ApiResponse<BottleType[]>>(environment.apiUrl + 'bottleType/all').pipe(map(
      res => res.result.map(jsonBottleType => BottleType.loadFromJSON(jsonBottleType))
    ))
  }

  // Codes: BOTTLE_TYPE_CREATED | WRONG_PARAMS | BOTTLE_TYPE_ALREADY_EXSIST | UNAUTHORIZED
  public create(form: BottleTypePostDto): Observable<BottleType> {
    return this.http.post<ApiResponse<BottleType>>(environment.apiUrl + 'bottleType', form).pipe(map(
      res => BottleType.loadFromJSON(res.result)
    ))
  }

  // Codes: BOTTLE_TYPE_DELETED | BOTTLE_TYPE_DOESNT_EXSIST | BOTTLE_TYPE_USED | UNAUTHORIZED
  public delete(id: number): Observable<{}> {
    return this.http.delete<ApiResponse<{}>>(`${environment.apiUrl}bottleType/${id}`).pipe(map(
      res => res.result
    ));
  }
}