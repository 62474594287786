import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VersionService } from '../../../../services/project/version.service';
import { Version } from '../../../../models/project/version';
import { Project } from '../../../../models/project/project';
import { ProjectService } from '../../../../services/project/project.service';
import { CAD } from '../../../../models/project/cad';
import { ToasterService } from 'angular2-toaster';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html'
})
export class VersionComponent implements OnInit {
  // NF
  project: Project;
  version: Version;
  cad: CAD;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,

    private projectService: ProjectService,
    private versionService: VersionService
  ) { }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }    
  ngOnInit() {
    // Load version
    this.route.params.subscribe(params => {
      this.versionService.get(params['id']).pipe(takeUntil(this.onDestroy$)).subscribe(
        res => {
          switch (res.code) {

            case Code.VERSION_RECOVERED:
            this.version = res.result;

            // Get project
            this.projectService.get(this.version.project.id).pipe(takeUntil(this.onDestroy$)).subscribe(
              res => {
                switch (res.code) {

                  case Code.PROJECT_RECOVERED:
                  this.project = res.result;

                  // Check if a local version exists
                  if (localStorage.getItem('version_' + this.version.id)) {

                    // Check local version date
                    var localVersion = Version.loadFromJSON(JSON.parse(localStorage.getItem('version_' + this.version.id)));
                    if (localVersion.lastEditionDate.getTime() > this.version.creationDate.getTime()) {
                      this.version = localVersion;
                      this.toasterService.pop('warning', 'Version locale chargée');
                    }
                  }

                  this.cad = new CAD(this.project, this.version);
                  break;
        
                  case Code.PROJECT_DOESNT_EXSIST:
                  case Code.UNAUTHORIZED:
                  this.router.navigateByUrl('/projects');
                  break;
        
                }
              }
            )
            break;
  
            case Code.VERSION_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
            this.router.navigateByUrl('/projects')
            break;
  
          }
        }, () => this.router.navigateByUrl('/projects')
      )
    })
  }

}