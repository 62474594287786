import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';

@Component({
  selector: 'wipe-upc-content',
  templateUrl: './wipe-upc.component.html'
})
export class WipeUPCModalComponent {
  upcId: number;

  // Modal
  modalRef: NgbModalRef;
  callbackSuccess: Function;
  callbackError: Function;
  // Form
  wipeSubmitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,

    private upcV3Service: UPCV3Service
  ) {

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  init(upcId: number, modalRef: NgbModalRef, callbackSuccess: Function, callbackError: Function) {
    this.upcId = upcId;
    this.modalRef = modalRef;
    this.callbackSuccess = callbackSuccess;
    this.callbackError = callbackError;
  }

  log(value: any) {
    console.log(value);
    return value;
  }

  // Modal
  close() {
    this.modalRef.close()
  }

  // Form
  onFormSubmit() {
    this.wipeSubmitted = true;

    // Try wipe
    this.upcV3Service.wipe(this.upcId).subscribe({
      next: (res) => {
        this.close(); 
        this.callbackSuccess();
      }, 
      error: (err) => {
        this.close(); 
        this.callbackError();
      }
    })
  }

}