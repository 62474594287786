export type Sort<T> = {
  col: keyof T;
  dir: "ASC" | "DESC";
}

export class PageParams<T> {
  page: number;
  size: number;
  sort: string;

  constructor(page: number, size: number, sort: Sort<T>) {
    this.page = page;
    this.size = size;
    this.sort = `${String(sort.col)},${sort.dir}`;
  }
} 