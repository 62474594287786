import { Belt } from "./belt";
import { Type as NodeType, Node } from "./node";
import { GDP } from "./gdp";
import { ToasterService } from "angular2-toaster";

export class Calculations {
  gdp: GDP;
  toasterService: ToasterService;

  // Calculations params
  temperature = 20; // T°C
  gramTrapHour = 20; // gr
  initialPressure = 0.64534; // Bar
  blasusFormula = 0.2;
  reynoldsFactor = 90;
  reynoldsThreshold = 3000;
  tubeLengthAdd = 0.5;
  literMinuteTrap = 0.168861871;
  co2MassVol = 1.8714 * 288 / (273.15 + this.temperature); // 1.838523622718745
  co2DynamicViscosity = 0.00000694501 * Math.pow((273.15 + this.temperature) / 273.15, 1.5) * (273.15 + 170) / (273.15 + 170 + this.temperature) * this.co2MassVol; // 0.000013583266313248221
  tolerance = 0.1;
  maxLoop = 100;

  constructor(gdp: GDP, toasterService: ToasterService) {
    this.gdp = gdp;
    this.toasterService = toasterService;
  }

  // CO2
  private currentIntensity: number;
  private currentBelt: Belt;
  flowCalculations(additionalDistanceSheath: number = 0.5) {
    this.tubeLengthAdd = additionalDistanceSheath;
    
    // For each belt
    this.gdp.version.belts.forEach(belt => {
      
      // Check calculations requirements
      var control = belt.nodes.find(n => n.type == NodeType.CONTROL);
      if (control) {

        /// Belt informations
        this.currentBelt = belt;
        belt.resetCalculationValues();


        // Loop test
        try {
          this.loopTest(control, null, 0);


          // Nodes informations
          belt.nodes.forEach(node => node.resetCalculationValues());

          // Loop on 10 intensity values (1 - 10)
          for (var i = 1; i < 11; i++) {

            // Initialization
            this.currentIntensity = i;
            control.flowIn = this.getInitialFlow();
            control.pressure = this.initialPressure * i / 10;

            var loop = 0;
            while (loop < this.maxLoop) {
              var flowCalculated = 0, //this.branchFlowRate(this.mainBranch), // Q
                  flowReference = this.getInitialFlow(); // Qref

              // if ||Qref - Q|| < eq
              if (Math.abs(flowReference - flowCalculated) < 1 / 1000) break;
              else control.pressure += (flowReference - flowCalculated) * (2 / this.currentBelt.trapsNumber); // P(0) = P(0) + (Qref - Q * ep)

              loop++;
            }
          }
        } catch {
          this.toasterService.pop('warning', 'Impossible de calculer les débits de la ceinture ' + belt.name, 'La ceinture fait une boucle');
        }
        
      } else {
        this.toasterService.pop('warning', 'Impossible de calculer les débits de la ceinture ' + belt.name, 'Régie inexistante');
      }

    });
  }

  /*private branchFlowRate(branch: Branch, parentBranch: Branch = null, parentNode: Node = null) {
  }*/


  private loopTest(node: Node, previousNode: Node, i: number) {
    i++;

    // Check maxloop
    if (i > this.maxLoop) throw 0;

    var paths = this.gdp.getPathsReferencesNode(node.id);
    
    // First / Final Node
    if (paths.length == 1) {

      // First Node
      if (!previousNode) {
        if (paths[0].from.id == node.id) return this.loopTest(paths[0].to, node, i);
        else return this.loopTest(paths[0].from, node, i);
      }

      // Last Node
      else return;

    }

    // Intermediate node
    else if (paths.length == 2) {

      // Get the right path
      if ((paths[0].from.id == node.id && paths[0].to.id == previousNode.id) || (paths[0].from.id == previousNode.id && paths[0].to.id == node.id)) {
        if (paths[1].from.id == node.id) return this.loopTest(paths[1].to, node, i);
        else return this.loopTest(paths[1].from, node, i);
      } else {
        if (paths[0].from.id == node.id) return this.loopTest(paths[0].to, node, i);
        else return this.loopTest(paths[0].from, node, i);
      }
      
    }

    // New branch start
    else if (paths.length == 3) {

      // Get the right path
      if (
        (paths[0].from.id == node.id && paths[0].to.id == previousNode.id) ||
        (paths[0].from.id == previousNode.id && paths[0].to.id == node.id)
      ) {

        // First path
        if (paths[1].from.id == node.id) this.loopTest(paths[1].to, node, i);
        else this.loopTest(paths[1].from, node, i);

        // Second path
        if (paths[2].from.id == node.id) this.loopTest(paths[2].to, node, i);
        else this.loopTest(paths[2].from, node, i);
      }

      else if (
        (paths[1].from.id == node.id && paths[1].to.id == previousNode.id) ||
        (paths[1].from.id == previousNode.id && paths[1].to.id == node.id)
      ) {

        // First path
        if (paths[0].from.id == node.id) this.loopTest(paths[0].to, node, i);
        else this.loopTest(paths[0].from, node, i);

        // Second path
        if (paths[2].from.id == node.id) this.loopTest(paths[2].to, node, i);
        else this.loopTest(paths[2].from, node, i);
      }

      else {

        // First path
        if (paths[0].from.id == node.id) this.loopTest(paths[0].to, node, i);
        else this.loopTest(paths[0].from, node, i);

        // Second path
        if (paths[1].from.id == node.id) this.loopTest(paths[1].to, node, i);
        else this.loopTest(paths[1].from, node, i);

      }

      return;

    }

    // Final Node
    else return;
  }



  // Methods
  private getTubeSection(tubeDiameter: number): number { return tubeDiameter * tubeDiameter * Math.PI / (4 * 1000000) }
  private getSpeed(flow: number, tubeDiameter: number): number { return flow / (60 * 1000 * this.getTubeSection(tubeDiameter)) }
  
  /*private getTrapsNumber(branch: Branch, index: number = -1): number {
    var number = 0;
    
    // Count all traps of the branch
    if (index != -1) number += branch.nodes.filter((node, nodeIndex) => nodeIndex > index && (node.nodeType == NodeType.RECTANGULAR_TRAP || node.nodeType == NodeType.HEXAGONAL_TRAP)).length;
    else number += branch.nodes.filter(node => node.nodeType == NodeType.RECTANGULAR_TRAP || node.nodeType == NodeType.HEXAGONAL_TRAP).length;
    
    // Count all traps of child branches
    for (var i = 0; i < this.currentBelt.branches.length; i++)
      if (this.currentBelt.branches[i].parentBranch == branch.id)
        number += this.getTrapsNumber(this.currentBelt.branches[i]);
    
    return number;
  }
  private getNodesNumber(branch: Branch): number {
    var number = 0;
    
    // Count all traps of the branch
    number += branch.nodes.length;
    
    // Count all traps of child branches
    for (var i = 0; i < this.currentBelt.branches.length; i++)
      if (this.currentBelt.branches[i].parentBranch == branch.id)
        number += this.getNodesNumber(this.currentBelt.branches[i]);
    
    return number;
  }*/

  private getOutModuleFlow(pressure: number, flow: number, tubeDiameter: number): number {
    var a = 0.110728364691098, b = 0.25715450;
    return ((a * Math.pow((pressure - this.getJunctionLoss(this.getSpeed(flow, tubeDiameter), false)), 2) + b * (pressure - this.getJunctionLoss(this.getSpeed(flow, tubeDiameter), false))));
  }
  private getInitialFlow(): number { return this.currentBelt.trapsNumber * (this.gramTrapHour / (60 * 1.974)) * this.currentIntensity / 10 }
  private getReynoldsNumber(flow: number, tubeDiameter: number): number { return this.co2MassVol * (tubeDiameter / 1000) * this.getSpeed(flow, tubeDiameter) / this.co2DynamicViscosity }
  private getDeltaPlaminaire(flow: number, tubeLength: number, tubeDiameter: number): number {
    var reynoldsNumber = this.getReynoldsNumber(flow, tubeDiameter), speed = this.getSpeed(flow, tubeDiameter);
    if (reynoldsNumber == 0) return 0;
    else if (reynoldsNumber < this.reynoldsThreshold) return ((this.reynoldsFactor / reynoldsNumber) * this.co2MassVol * speed * speed * (tubeLength + this.tubeLengthAdd) / (2 * (tubeDiameter / 1000)) / 100) / 1000;
    else return (this.blasusFormula * Math.pow(reynoldsNumber, -0.25) * this.co2MassVol * speed * speed * (tubeLength + this.tubeLengthAdd) / (2 * (tubeDiameter / 1000)) / 100) / 1000;
  }
  private getModulePressure(prevModulePressure: number, deltaPLaminaire: number, junctionLoss: number): number { return prevModulePressure - deltaPLaminaire - junctionLoss }
  private getJunctionLoss(speed: number, jn: boolean): number {
    var k = (jn ? 0.5 : 1.3);
    return k * this.co2MassVol * speed * speed / 200000;
  }
  private getFlowPercent(flow: number): number { return ((flow - this.literMinuteTrap) / this.literMinuteTrap * 100) }
}