<div class="modal-header">
  <h4 class="modal-title">Nouveau Projet</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Name -->
    <div class="form-group">
      <label for="name">Nom</label>
      <input type="text" class="form-control" id="name" placeholder="Nom" formControlName="name">
    </div>

    <!-- Client -->
    <div class="form-group">
      <label for="client">Client</label>
      <input type="text" class="form-control" id="client" placeholder="Client" formControlName="client">
    </div>

    <hr class="ml-0">

    <!-- Address -->
    <div class="form-group">
      <label for="address">Adresse</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="address" placeholder="Adresse" formControlName="address" [ngClass]="{ 'is-invalid': addressUnknow }" (keyup)="addressUnknow = false">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="geolocate()">Géolocaliser</button>
        </div>
        <div *ngIf="addressUnknow" class="invalid-feedback">
          <div *ngIf="addressUnknow">Adresse non trouvée</div>
        </div>
      </div>
    </div>

    <!-- Lat / Lng -->
    <div class="form-row">

      <!-- Lat -->
      <div class="col-md-6 form-group">
        <label for="lat">Latitude</label>
        <input type="number" class="form-control" id="lat" placeholder="Latitude" step="0.01" formControlName="lat">
      </div>

      <!-- Lng -->
      <div class="col-md-6 form-group">
        <label for="lng">Longitude</label>
        <input type="number" class="form-control" id="lng" placeholder="Longitude" step="0.01" formControlName="lng">
      </div>

    </div>

    <!-- HBM Distance -->
    <div class="form-group">
      <label for="distance">Distance de HBM Distribution</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="distance" placeholder="Distance de HBM Distribution" formControlName="distance">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="geoCalculate()">Calculer</button>
        </div>
      </div>
    </div>

    <!-- Map -->
    <agm-map  #map
              [latitude]="this.f.lat.value"
              [longitude]="this.f.lng.value"
              [zoom]="this.f.zoom.value"
              [mapTypeId]="'hybrid'"
              [usePanning]="true"
              style="height: 70vh;">
      <agm-marker #marker [latitude]="this.f.lat.value" [longitude]="this.f.lng.value" [markerDraggable]="true" (dragEnd)="dragEnd($event)"></agm-marker>
    </agm-map>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>