import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class User  {
  
  @jsonMember
  archived: boolean;
  
  @jsonMember
  id: string;
  
  @jsonMember
  username: string;
  
  @jsonMember
  lastName: string;
  
  @jsonMember
  firstName: string;
  
  @jsonMember
  phone: string;

  static loadFromJson(json) : User {
    let serializer = new TypedJSON(User);
		return serializer.parse(json);
  }
};