import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Operator } from '../../models/management/operator';
import { ToasterService } from 'angular2-toaster';

const strings = {
  titles : {
    added: 'Opérateur ajouté', notAdded: 'Opérateur non ajouté',
    edited: 'Opérateur modifié', notEdited: 'Opérateur non modifié',
    deleted: 'Opérateur supprimé', notDeleted: 'Opérateur non supprimé'
  },
  bodies: {
    internalError: 'Erreur interne',
    operatorDoesntExsist: 'Opérateur inexistant',
    unauthorized: 'Opération non autorisée'
  }
}

@Injectable({
  providedIn: 'root',
})
export class OperatorService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) { }

  // Codes: OPERATOR_RECOVERED | UNAUTHORIZED
  public getAll(): Observable<Operator[]> {
    return this.http.get<ApiResponse<Operator[]>>(environment.apiUrl + 'operator/all').pipe(map(
      res =>  res.result.map(json => Operator.loadFromJSON(json)) 
    ))
  }

  // Codes: OPERATOR_CREATED | WRONG_PARAMS | OPERATOR_ALREADY_EXSIST | UNAUTHORIZED
  public create(form: FormGroup): Observable<ApiResponse<Operator>> {
    return this.http.post<ApiResponse<Operator>>(environment.apiUrl + 'operator', form.value).pipe(map(
      res => {
        switch (res.code) {
          
          case Code.OPERATOR_CREATED:
          res.result = Operator.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.added);
          break;

          case Code.WRONG_PARAMS:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.internalError);
          break;

          case Code.OPERATOR_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.unauthorized);
          break;

        }
        
        return res;
      }
    ))
  }

  // Codes: OPERATOR_EDITED | OPERATOR_DOESNT_EXSIST | OPERATOR_ALREADY_EXSIST | UNAUTHORIZED
  public edit(id: string, form: FormGroup): Observable<ApiResponse<Operator>> {
    return this.http.post<ApiResponse<Operator>>(environment.apiUrl + 'operator/' + id, form.value).pipe(map(
      res => {
        switch (res.code) {
          
          case Code.OPERATOR_EDITED:
          res.result = Operator.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.edited);
          break;

          case Code.OPERATOR_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notEdited, strings.bodies.operatorDoesntExsist);
          break;

          case Code.OPERATOR_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notEdited, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  public getConnected () : Observable<Operator> {
    return this.http.get<ApiResponse<Operator>>(environment.apiUrl+"operator/getConnected").pipe(map(
      res => Operator.loadFromJSON(res.result)
    ))
  }

  // Codes: OPERATOR_DELETED | OPERATOR_DOESNT_EXSIST | UNAUTHORIZED
  public delete(id: string): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(environment.apiUrl + 'operator/' + id).pipe(map(
      res => {
        switch (res.code) {
          
          case Code.OPERATOR_DELETED:
          this.toasterService.pop('success', strings.titles.deleted);
          break;

          case Code.OPERATOR_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notDeleted, strings.bodies.operatorDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notDeleted, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

}
