import { jsonObject, jsonMember, TypedJSON } from 'typedjson';

@jsonObject
export class CardTest {
  
  @jsonMember
  id: string = '';
	
  @jsonMember
  upcMcuUid: string = '';
	
  @jsonMember
  date: Date;
  
  @jsonMember
  pointsTest1: number = 0;
  
  @jsonMember
  pointsTest2: number = 0;
  
  @jsonMember
  pointsTest3: number = 0;
  
  @jsonMember
  pointsTest4: number = 0;
  
  @jsonMember
  pointsTest5: number = 0;
  
  @jsonMember
  pointsTest6: number = 0;
  
  @jsonMember
  pointsTest7: number = 0;
  
  @jsonMember
  led1: boolean = false;
  
  @jsonMember
  led2: boolean = false;
  
  @jsonMember
  led3: boolean = false;
  
  @jsonMember
  led4: boolean = false;
	
  @jsonMember
  wifiLevel:  number = 0;
	
  @jsonMember
  gsmMode:    number = 0;
	
  @jsonMember
  gsmLevel:   number = 0;
	
  @jsonMember
  inPressOff:   number = 0;
	
  @jsonMember
  outPressOff:  number = 0;
	
  @jsonMember
  battTime: number = 0;
  
  @jsonMember
  observations: string = '';
  
  static loadFromJSON(json): CardTest {
   let serializer = new TypedJSON(CardTest);
   return serializer.parse(json);
  }
}