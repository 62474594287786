import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse, Page, PagingResponse } from 'src/app/models/ApiResponse';
import { Rack } from 'src/app/models/management/location';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RackService {
  constructor(private http: HttpClient) { }

  public getAll(page: number = 0, pagesize: number = 10): Observable<Page<Rack>> {
    return this.http.get<PagingResponse<Rack>>(`${environment.apiUrl}rack/all`, {
      params: {
        page: page,
        size: pagesize
      }
    }).pipe(
      map(res => {
        res.result.content = res.result.content.map(jsonRack => Rack.loadFromJSON(jsonRack));
        return res.result;
      })
    );
  }

  public getAllByLocation(locationId: string, page: number = 0, pagesize: number = 10) {
    return this.http.get<PagingResponse<Rack>>(`${environment.apiUrl}rack/all`, {
      params: {
        locationId: locationId,
        page: page,
        size: pagesize
      }
    }).pipe(
      map(res => {
        res.result.content = res.result.content.map(jsonRack => Rack.loadFromJSON(jsonRack));
        return res.result;
      })
    );
  }

  public returnToSupplier(rackId: string): Observable<Rack> {
    return this.http.post<ApiResponse<Rack>>(`${environment.apiUrl}rack/${rackId}/returnToSupplier`, {}).pipe(map(
      res => Rack.loadFromJSON(res.result)
    ));
  }
}