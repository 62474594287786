import { UserFormModel } from './../pages/settings/user/user.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse, Code } from '../models/ApiResponse';

import { environment } from '../../environments/environment';
import { Operator } from '../models/management/operator';
import { FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AuthResponse } from './AuthResponse';
import { ToasterService } from 'angular2-toaster';
import { Designer } from '../models/management/designer';
import { User } from '../models/management/user';
import jwt_decode from "jwt-decode";

interface IJWTData {
  sub: string;
  auth: Array<{authority: string}>
  iat: number;
  exp: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.cookieService.check('token'));

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private toasterService: ToasterService
  ) { }

  // Codes: TOKEN_LOGGED_IN | TOKEN_WRONG_IDENTIFIERS | WRONG_PARAMS
  login(user: UserFormModel): Observable<AuthResponse> {
    if (user.username !== '' && user.password !== '') {
      return this.http.post<AuthResponse>(environment.apiUrl + 'user/login', user).pipe(
        map(res => {
          switch (res.code) {
            case Code.TOKEN_LOGGED_IN:
              // Store token in cookies
              this.cookieService.set('token', res.result.token.toString(), null, '/');
              this.cookieService.set('refreshToken', res.result.refreshToken.toString(), null, '/');

              // Set logged in
              this.loggedIn.next(true);

              // Redirect
              if (this.isDesigner) this.router.navigate(['/projects']);
              else this.router.navigate(['/dashboard']);
              break;
          }
          return res;
        }), catchError(err => {
          switch(err.error.code) {
            case Code.TOKEN_WRONG_IDENTIFIERS:
              this.toasterService.pop('error', 'Identifiants invalides');
              break;

            case Code.WRONG_PARAMS:
              this.toasterService.pop('error', 'Erreur interne');
              break;
          }
          throw err;
        }),
      );
    }
  }

  // Codes: TOKEN_LOGGED_IN | TOKEN_WRONG_IDENTIFIERS | WRONG_PARAMS
  refreshToken(): Observable<AuthResponse> {
    // Request
    return this.http.post<AuthResponse>(environment.apiUrl + 'user/refreshToken', { refreshToken: this.cookieService.get('refreshToken') }).pipe(map(
      res => {
        switch (res.code) {
          case Code.TOKEN_LOGGED_IN:
            // Store tokens in cookies
            this.cookieService.set('token', res.result.token.toString(), null, '/');
            this.cookieService.set('refreshToken', res.result.refreshToken.toString(), null, '/');
            break;
        }
        return res;
      }
    ), catchError((err) => {
      switch (err.error.code) {
        case Code.TOKEN_WRONG_IDENTIFIERS:
          this.toasterService.pop('error', 'Session expirée !');
          this.logout();
          break;
  
        case Code.WRONG_PARAMS:
          this.toasterService.pop('error', 'Erreur interne !');
          this.logout();
          break;
      }
      throw err;
    }))
  }

  // Codes: OPERATOR_RECOVERED | DESIGNER_RECOVERED
  get(): Observable<User> {
    return this.http.get<ApiResponse<User>>(environment.apiUrl + 'user/profile').pipe(map(
      res => {
        switch (res.code) {
          case Code.OPERATOR_RECOVERED:
            return Operator.loadFromJSON(res.result);
          case Code.DESIGNER_RECOVERED:
           return Designer.loadFromJSON(res.result);
        }
      }
    ))
  }

  // Codes: OPERATOR_EDITED | DESIGNER_EDITED
  edit(form: FormGroup): Observable<ApiResponse<User>> {
    return this.http.post<ApiResponse<User>>(environment.apiUrl + 'user/edit', form.value).pipe(map(
      res => {
        switch (res.code) {

          case Code.OPERATOR_EDITED:
            res.result = Operator.loadFromJSON(res.result);
            this.toasterService.pop('success', 'Compte modifié');
            break;

          case Code.DESIGNER_EDITED:
            res.result = Designer.loadFromJSON(res.result);
            this.toasterService.pop('success', 'Compte modifié');
            break;

        }

        return res;
      }
    ))
  }

  getRole(): string {
    try {
      let token = this.cookieService.get('token');
      let decodedJwtData = jwt_decode<IJWTData>(token);
      return decodedJwtData.auth[0].authority;
    } catch(e) {
      // rethrow in order to get it in Sentry
      throw new Error("trying to parse malformed JWT Token: " + this.cookieService.get('token'));
    }
  }

  logout() {
    this.cookieService.delete('token');
    this.cookieService.delete('refreshToken', '/');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  get isAdmin() {
    return this.getRole() == 'ROLE_ADMINISTRATOR';
  }

  get isOperator() {
    return this.getRole() == 'ROLE_OPERATOR';
  }

  get isDesigner() {
    return this.getRole() == 'ROLE_DESIGNER';
  }
}