import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SiteService } from '../../../services/management/site.service';
import { Router } from '@angular/router';
import { Site } from 'src/app/models/management/site';
import { Modal } from 'src/app/models/ui/modal';

@Component({
  selector: 'delete-site-content',
  templateUrl: './delete-site.component.html'
})
export class DeleteSiteModalComponent extends Modal<Site> {
  // Form
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private siteService: SiteService
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.siteService.delete(this.subject.id).subscribe(
      () => this.close(true),
      () => this.close()
    )
  }

}