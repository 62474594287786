import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Page, PagingResponse } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Test } from '../../models/upcv3/test/test';
import { UPCV3 } from '../../models/upcv3/upc/upcv3';
import { Event } from '../../models/upcv3/event';
import { FormGroup } from '@angular/forms';
import { MailAlert } from "../../models/upcv3/mailalert";
import { SyncJob } from 'src/app/models/jobs/job';
import { RefillPostDto } from 'src/app/models/dto/UPCV3/RefillPost.dto';
import { EventGetDto } from 'src/app/models/dto/eventGet.dto';


export interface DiffusionChartDataWithoutMetadata {
  date: string;
  diffs: {
    intensity: number;
    start: string,
    stop: string,
  }[];
}

export interface ContentChartDataWithoutMetadata {
  b1: number;
  b2: number;
  date: string;
  diff: number;
}

export interface ContentChartDataWithMetadata {
  upcNameId: string;
  data: ContentChartDataWithoutMetadata[];
}

export interface ConsoChartDataWithoutMetadata {
  conso: number;
  date: string;
}

export interface ConsoChartDataWithoutMetadata {
  conso: number;
  date: string;
}

export interface ConsoChartDataWithMetadata {
  upcNameId: string;
  data: ConsoChartDataWithoutMetadata[];
}

@Injectable({
  providedIn: 'root',
})
export class UPCV3Service {

  constructor(
    private http: HttpClient,
  ) { }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED
  public getUnlinkeds(): Observable<UPCV3[]> {
    return this.http.get<ApiResponse<UPCV3[]>>(environment.apiUrl + 'upcv3/unlinkeds').pipe(map(
      res => res.result.map(json => UPCV3.loadFromJSON(json))
    ))
  }

  public resetLegacyTasks(upcName: string) {
    return this.http.get(environment.apiUrl + "upcv3/resetLegacyTask/" + upcName);
  }

  public resetSync(text) {
    return this.http.post(environment.apiUrl + "upcv3/" + text + "/resetSync", {}).pipe(map(
      res => {
        return res;
      }
    ))
  }
  public resetPolling(text) {
    return this.http.post(environment.apiUrl + "upcv3/" + text + "/resetPolling", {}).pipe(map(
      res => {
        return res;
      }
    ))
  }
  public getBeforeEvents(upcid, delay: string): Observable<Event> {
    return this.http.get<ApiResponse<Event>>(environment.apiUrl + "upcv3/" + upcid + "/beforeEvents?delay=" + delay).pipe(map(
      res => Event.loadFromJSON(res.result)
    ))


  }

  public getEvent(id): Observable<MailAlert[]> {
    return this.http.get<ApiResponse<MailAlert[]>>(environment.apiUrl + 'biobeltget/getalert/' + id).pipe(map(
      res => res.result.map(json => MailAlert.loadFromJSON(json))
    ))
  }

  public getAllEvent(): Observable<MailAlert[]> {
    return this.http.get<ApiResponse<MailAlert[]>>(environment.apiUrl + 'biobeltget/getAllAlert').pipe(map(
      res => res.result.map(json => MailAlert.loadFromJSON(json))
    ))
  }

  public calcProg(id): Observable<number> {
    return this.http.get<ApiResponse<number>>(environment.apiUrl + "/upcv3/calProg/" + id).pipe(map(
      res => res.result
    ))
  }

  public calcEvent(id): Observable<number> {
    return this.http.get<ApiResponse<number>>(environment.apiUrl + "/upcv3/calEvent/" + id).pipe(map(
      res => res.result
    ))
  }


  // Codes: UPCV3_RECOVERED | UNAUTHORIZED
  public getUninitied(): Observable<UPCV3[]> {
    return this.http.get<ApiResponse<UPCV3[]>>(environment.apiUrl + 'upcv3/uninitied').pipe(map(
      res => res.result.map(json => UPCV3.loadFromJSON(json))
    ))
  }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED
  public getAll(withGroups: boolean = false, fields: "primary" | "secondary" = "primary"): Observable<UPCV3[]> {
    let params = new HttpParams().append("withGroups", withGroups);
    params = params.append("fields", fields);
    return this.http.get<ApiResponse<UPCV3[]>>(environment.apiUrl + 'upcv3/all', {params}).pipe(map(
      res => res.result.map(json => UPCV3.loadFromJSON(json))
    ))
  }
  /*
  * @params: Weird parameters. Ids must be concatenated in a string, comma separated
  * TODO : Implement proper array of Ids, or any other more simply manipulated format
  */
  public getBeltsByIDs(id: string): Observable<UPCV3[]> {
    return this.http.get<ApiResponse<UPCV3[]>>(environment.apiUrl + 'upcv3/getbelts/' + id).pipe(map(
      res => res.result.map(json => UPCV3.loadFromJSON(json))
    ))
  }


  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public get(id: number): Observable<UPCV3> {
    return this.http.get<ApiResponse<UPCV3>>(environment.apiUrl + 'upcv3/' + id).pipe(map(
      res => UPCV3.loadFromJSON(res.result)
    ))
  }


  // Codes: UPCV3_EDITED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public edit(id: number, form: FormGroup): Observable<UPCV3> {

    return this.http.post<ApiResponse<UPCV3>>(environment.apiUrl + 'upcv3/' + id, form.value).pipe(map(
      res => UPCV3.loadFromJSON(res.result)
    ))
  }

  // Codes: UPCV3_DELETED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public delete(id: number): Observable<UPCV3> {
    return this.http.delete<ApiResponse<UPCV3>>(environment.apiUrl + 'upcv3/' + id).pipe(map(
      res => UPCV3.loadFromJSON(res.result)
    ))
  }

  // Codes: POLL_STARTED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | TASK_ALREADY_RUNNING | INTERNAL_ERROR
  public poll(id: number): Observable<ApiResponse<string>> {
    return this.http.get<ApiResponse<any>>(environment.apiUrl + 'upcv3/' + id + '/poll').pipe(map(
      res => res.result
    ))
  }

  // Codes: INIT_STARTED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | TASK_ALREADY_RUNNING | INTERNAL_ERROR
  public init(id: number): Observable<{}> {
    return this.http.get<ApiResponse<{}>>(environment.apiUrl + 'upcv3/' + id + '/init').pipe(map(
      res => res.result
    ))
  }

  // Codes: RESTORE_STARTED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | TASK_ALREADY_RUNNING | INTERNAL_ERROR
  public restore(id: number): Observable<{}> {
    return this.http.get<ApiResponse<{}>>(environment.apiUrl + 'upcv3/' + id + '/restore').pipe(map(
      res => res.result
    ))
  }

  public getSyncJobs(finished?: boolean): Observable<SyncJob[]> {
    const finishedQuery = finished == undefined ? "" : "?finished=" + finished;
    return this.http.get<ApiResponse<SyncJob[]>>(environment.apiUrl + "upcv3/sync/jobs" + finishedQuery, {}).pipe(map(
      res => res.result.map(json => SyncJob.loadFromJson(json))
    ))
  }

  public deleteJob(id: number): Observable<any> {
    return this.http.delete<ApiResponse<any>>(environment.apiUrl + "upcv3/" + id + "/sync/job", {})
  }

  public deleteJobs(ids: number[]): Observable<any> {
    return this.http.request<ApiResponse<any>>('delete', environment.apiUrl + "upcv3/sync/jobs", {
      body: ids
    })
  }

  public retrySync(id: number): Observable<void> {
    return this.http.get<ApiResponse<void>>(environment.apiUrl + 'upcv3/' + id + `/sync/job/retryNow`).pipe(map(
      res => res.result
    ))
  }

  public syncs(ids: number[], json: any): Observable<{started: number[], error: number[]}> {
    return this.http.post<ApiResponse<{started: number[], error: number[]}>>(environment.apiUrl + "upcv3/syncs", {
      parameters: json,
      upcs: ids
    }).pipe(map(
      res => res.result
    ))
  }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | MODBUS_CONNECTION_ERROR | UPCV3_DOESNT_EXSIST | INTERNAL_ERROR | MODBUS_WRITE_ERROR | MODBUS_READ_ERROR
  public sync(id: number, json: any, waitForCompletion: boolean = false): Observable<void> {
    let params = new HttpParams().append("waitForCompletion", waitForCompletion);
    return this.http.post<ApiResponse<void>>(environment.apiUrl + 'upcv3/' + id + `/sync`, json, { params }).pipe(map(
      () => null
    ));
  }

  public syncTemp(id, json): Observable<void> {
    return this.http.post<ApiResponse<void>>(environment.apiUrl + "upcv3/" + id + "/syncTemp", json).pipe(map(
      () => null
    ));
  }
  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | MODBUS_CONNECTION_ERROR | UPCV3_DOESNT_EXSIST | INTERNAL_ERROR | MODBUS_WRITE_ERROR | MODBUS_READ_ERROR
  public syncWithFile(id: string): Observable<UPCV3> {
    return this.http.post<ApiResponse<UPCV3>>(environment.apiUrl + 'upcv3/' + id + '/syncWithFile', {}).pipe(map(
      res => UPCV3.loadFromJSON(res.result)
    ))
  }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | MODBUS_CONNECTION_ERROR | UPCV3_DOESNT_EXSIST | INTERNAL_ERROR | MODBUS_WRITE_ERROR | MODBUS_READ_ERROR
  public refill(id: number, json: RefillPostDto): Observable<null> {
    return this.http.post<ApiResponse<null>>(environment.apiUrl + 'upcv3/' + id + '/refill', json).pipe(map(
      () =>  null
    ))
  }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | MODBUS_CONNECTION_ERROR | UPCV3_DOESNT_EXSIST | INTERNAL_ERROR | MODBUS_WRITE_ERROR | MODBUS_READ_ERROR
  public refillGroup(id: number, json: RefillPostDto): Observable<any> {
    return this.http.post<ApiResponse<void>>(environment.apiUrl + 'upcv3/' + id + '/refillGroup', json);
  }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public getOffsetsFromTests(id: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(environment.apiUrl + 'upcv3/' + id + '/offsetsTests').pipe(map(
      res => res.result
    ))
  }

  public getTestsByUuid(uuid: string): Observable<Test[]> {
    return this.http.get<ApiResponse<Test[]>>(environment.apiUrl + "upcv3/" + uuid + "/testsByUuid").pipe(map(
      res => res.result.map(json => Test.loadFromJSON(json))
    ))
  }
  // Codes: UPCV3_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public getTests(id: number): Observable<Test[]> {
    return this.http.get<ApiResponse<Test[]>>(environment.apiUrl + 'upcv3/' + id + '/tests').pipe(map(
      res => res.result.map(json => Test.loadFromJSON(json))
    ))
  }

  // Codes: EVENT_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public getEvents(id: number, eventGetDto: EventGetDto): Observable<Page<Event>> {
    let params = new HttpParams({
      fromObject: eventGetDto,
    })
    
    return this.http.get<PagingResponse<Event>>(`${environment.apiUrl}upcv3/${id}/events`, { params }).pipe(map(
      res => {
        res.result.content = res.result.content.map(json => Event.loadFromJSON(json));
        return res.result;
      }
    ))
  }
  // Get Last Poll Events Successful
  public getLastPollEvents(): Observable<Event> {
    return this.http.get<ApiResponse<Event>>(environment.apiUrl + 'upcv3/getLastPollSuccess').pipe(map(
      res => Event.loadFromJSON(res.result)
    ))
  }

  public getLastEvent(id: string): Observable<Event> {
    return this.http.get<ApiResponse<Event>>(environment.apiUrl + 'upcv3/' + id + '/lastEvent').pipe(map(
      res => Event.loadFromJSON(res.result)
    ))
  }

  // Search Events
  public searchEvents(id: number, pageSize: number, currentPage: number, sourceFilter: string): Observable<Page<Event>> {
    let params = new HttpParams();
    params = params.append('pageSize', pageSize);
    params = params.append('currentPage', currentPage);
    params = params.append('sourceFilter', sourceFilter);

    return this.http.get<PagingResponse<Event>>(environment.apiUrl + 'upcv3/' + id + '/searchEvents', { params }).pipe(map(
      res => {
        res.result.content = res.result.content.map(json => Event.loadFromJSON(json))
        return res.result;
      }
    ))
  }

  public eventbyDate(id: number, from: string, to: string, pageSize: number, currentPage: number): Observable<Page<Event>> {
    let params = new HttpParams();

    params = params.append('from', from);
    params = params.append('to', to);
    params = params.append('pageSize', pageSize);
    params = params.append('currentPage', currentPage);

    return this.http.get<PagingResponse<Event>>(environment.apiUrl + 'upcv3/' + id + '/eventdate', { params }).pipe(map(
      res => {
        res.result.content = res.result.content.map(json => Event.loadFromJSON(json))
        return res.result;
      }
    ))
  }

  public resetFirmUpd(text) {
    return this.http.post(environment.apiUrl + "upcv3/" + text + "/resetFirmwUpd", {}).pipe(map(
      res => {
        return res;
      }
    ))
  }
  // Codes: STATS_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | WRONG_PARAMS
  public getContentStats(id: number, from: string, to: string): Observable<ContentChartDataWithoutMetadata[]> {
    let params = new HttpParams();
    params = params.append('from', from);
    params = params.append('to', to);

    return this.http.get<ApiResponse<ContentChartDataWithoutMetadata[]>>(environment.apiUrl + 'upcv3/' + id + '/stats/content', { params }).pipe(map(
      res => res.result
    ))
  }
  public getContentStatsbetweenDates(id: number, from: string, to: string) {
    let params = new HttpParams();
    params = params.append('from', from);
    params = params.append('to', to);

    return this.http.get<ApiResponse<ContentChartDataWithoutMetadata[]>>(environment.apiUrl + 'upcv3/' + id + '/stats/consoBetweenDates', { params }).pipe(map(
      res => res.result
    ))
  }

  // Codes: STATS_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | WRONG_PARAMS
  public getContentStatsLDT(id: number, from: string, to: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('from', from);
    params = params.append('to', to);

    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + 'upcv3/' + id + '/statsldt/content', { params }).pipe(map(
      res => res.result
    ))
  }

  // Codes: STATS_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | WRONG_PARAMS
  public getConsoStats(id: number, from: string, to: string): Observable<ConsoChartDataWithoutMetadata[]> {
    let params = new HttpParams();
    params = params.append('from', from);
    params = params.append('to', to);

    return this.http.get<ApiResponse<ConsoChartDataWithoutMetadata[]>>(environment.apiUrl + 'upcv3/' + id + '/stats/conso', { params }).pipe(map(
      res => res.result
    ))
  }

  // Codes: STATS_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | WRONG_PARAMS
  public getDiffStats(id: number, from: string, to: string): Observable<DiffusionChartDataWithoutMetadata[]> {
    let params = new HttpParams();
    params = params.append('from', from);
    params = params.append('to', to);

    return this.http.get<ApiResponse<DiffusionChartDataWithoutMetadata[]>>(environment.apiUrl + 'upcv3/' + id + '/stats/diff', { params }).pipe(map(
      res => res.result
    ))
  }

  // Codes: STATS_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | WRONG_PARAMS
  public getTableStats(id: number, year: number): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('year', year);
    return this.http.get<ApiResponse<any[]>>(environment.apiUrl + 'upcv3/' + id + '/stats/table', { params }).pipe(map(
      res => res.result
    ))
  }

  // Codes: STATS_RECOVERED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST | WRONG_PARAMS
  public getYearStats(year: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('year', year);

    return this.http.get(environment.apiUrl + 'upcv3/stats/table/all', {
      responseType: 'arraybuffer',
      params,
    });
  }

  // Codes: UPCV3_EDITED | UNAUTHORIZED | UPCV3_DOESNT_EXSIST
  public dissociate(id: number): Observable<UPCV3> {
    return this.http.get<ApiResponse<UPCV3>>(environment.apiUrl + 'upcv3/' + id + '/dissociate').pipe(map(
      res => UPCV3.loadFromJSON(res.result)
    ))
  }

  public archiveBelts(ids: number[], archive: boolean): Observable<any> {
    return this.http.put<ApiResponse<any>>(environment.apiUrl + 'upcv3/batchArchive', {
      ids,
      archive,
    }).pipe(map(
      res => res.result
    ))
  }

  public wipe(id: number) : Observable<void> {
    return this.http.get<ApiResponse<void>>(environment.apiUrl + 'upcv3/' + id + '/wipe').pipe(map(
      () => null
    ))
  }
}
