<div class="card-body p-2 text-center" *ngIf="selectedNodeNumber == 0 && selectedPathNumber == 0 && !selectedBelt && !selectedProtectedArea">Aucune sélection</div>

<ul class="list-group list-group-flush">

  <!-- Belt -->
  <li class="list-group-item p-0" *ngIf="selectedBelt">
    <div class="card-body">
      <h6 class="card-title">Ceinture {{ selectedBelt.name }} sélectionnée</h6>
    
      <!-- Name -->
      <div class="form-group mb-2">
        <label>Nom</label>
        <input type="text" class="form-control form-control-sm" placeholder="Nom" [(ngModel)]="selectedBelt.name">
      </div>
    
      <!-- Traps color -->
      <div class="form-group mb-2">
        <label>Couleur des pièges</label>
        <select class="custom-select custom-select-sm" [(ngModel)]="selectedBelt.trapsColor">
          <option value="WOOD">Naturel</option>
          <option value="BROWN">Marron Noir</option>
          <option value="GREEN">Vert Anglais</option>
        </select>
      </div>
    
      <hr class="ml-0">
    
      <!-- Bottle Type-->
      <div class="form-group mb-2">
        <label>Type de bouteille</label>
        <select class="custom-select custom-select-sm" [(ngModel)]="selectedBelt.bottleType">
          <option value="B10">10 Kg</option>
          <option value="B20">20 Kg</option>
          <option value="B34">34 Kg</option>
          <option value="B37">37.5 Kg</option>
          <option value="B50LB">50 Lb</option>
          <option value="R100">Rembo 100 Kg</option>
          <option value="R180">Rembo 180 Kg</option>
        </select>
      </div>
    
      <!-- Bottle number -->
      <label>Nombre de bouteilles</label>
      <div class="row mb-2">
        <div class="col-6">
          <p>B1<b class="float-right">{{ selectedBelt.bottleNumberB1 }}</b></p>
        </div>
        <div class="col-6">
          <p>B2<b class="float-right">{{ selectedBelt.bottleNumberB2 }}</b></p>
        </div>
      </div>
    
      <!-- Bottle costs -->
      <label>Coûts de la bouteille</label>
      <div class="form-row">
        <div class="form-group col-md-6 mb-2">
          <label>€ / Kg CO2</label>
          <input type="number" class="form-control form-control-sm" min="0" step="0.05" placeholder="Entrez le prix" [(ngModel)]="selectedBelt.co2KgPrice">
        </div>
        <div class="form-group col-md-6 mb-0">
          <label>€ / Mois</label>
          <input type="number" class="form-control form-control-sm" min="0" step="0.05" placeholder="Entrez le prix" [(ngModel)]="selectedBelt.co2MonthRentPrice">
        </div>
      </div>
    
      <!-- Delete -->
      <hr class="ml-0">
      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="deleteBelt(selectedBelt)">Supprimer</button>
    </div>
  </li>

  <!-- Nodes -->
  <li class="list-group-item p-0" *ngIf="selectedNodeNumber > 0">
    <div class="card-body">
      <h6 class="card-title">{{ selectedNodeNumber }} Noeud(s) sélectionné(s)</h6>
    
      <!-- Type -->
      <div class="form-group mb-2">
        <label for="nodeType">Type de noeud</label>
        <select class="custom-select custom-select-sm" id="nodeType" [value]="firstSelectedNode?.type" (change)="updateNodesType($event.target.value)">
          <option value="HEXAGONAL_TRAP">Piège héxagonal</option>
          <option value="RECTANGULAR_TRAP">Piège rectangulaire</option>
          <option value="INTERMEDIATE">Noeud intermédiaire</option>
        </select>
      </div>
    
      <!-- Icon size -->
      <div class="form-group mb-2">
        <label for="name">Taille de l'icône</label>
        <input type="number" class="form-control form-control-sm" placeholder="Taille de l'icône" min="1" [value]="firstSelectedNode?.iconSize" (change)="updateNodesIconSize($event.target.value)">
      </div>
    
      <hr class="ml-0">
    
      <!-- Title color -->
      <div class="form-group mb-2">
        <label for="name">Couleur du texte</label>
        <input type="color" class="form-control" placeholder="Couleur du texte" [value]="firstSelectedNode?.titleColor" (change)="updateNodesTitleColor($event.target.value)" style="height: 2rem;">
      </div>
    
      <!-- Title background color -->
      <div class="form-group mb-2">
        <label for="name">Couleur du fond</label>
        <input type="color" class="form-control" placeholder="Couleur du fond" [value]="firstSelectedNode?.titleBackgroundColor" (change)="updateNodesTitleBackgroundColor($event.target.value)" style="height: 2rem;">
      </div>
    
      <!-- Title size -->
      <div class="form-group mb-0">
        <label for="name">Taille du texte</label>
        <input type="number" class="form-control form-control-sm" placeholder="Taille du texte" min="1" [value]="firstSelectedNode?.titleSize" (change)="updateNodesTitleSize($event.target.value)">
      </div>
    </div>
  </li>

  <!-- Paths -->
  <li class="list-group-item p-0" *ngIf="selectedPathNumber > 0">
    <div class="card-body">
      <h6 class="card-title">{{ selectedPathNumber }} Lien(s) sélectionné(s)</h6>
    
      <!-- Color -->
      <div class="form-group mb-2">
        <label for="name">Couleur</label>
        <input type="color" class="form-control form-control-sm" placeholder="Couleur" [value]="firstSelectedPath?.color" (change)="updatePathsColor($event.target.value)" style="height: 2rem;">
      </div>
    
      <!-- Width -->
      <div class="form-group mb-2">
        <label for="name">Largeur</label>
        <input type="number" class="form-control form-control-sm" placeholder="Largeur" min="1" [value]="firstSelectedPath?.width" (change)="updatePathsWidth($event.target.value)">
      </div>
    
      <!-- Dashed -->
      <div class="form-group mb-0">
        <label>Enterré</label><br>
        <ui-switch size="small" [checked]="firstSelectedPath?.dashed" (change)="updatePathsDashed($event)"></ui-switch>
      </div>
    </div>
  </li>

  <!-- Protected Areas -->
  <li class="list-group-item p-0" *ngIf="selectedProtectedArea">
    <div class="card-body">
      <h6 class="card-title">{{ selectedProtectedArea.name }} sélectionné</h6>

      <!-- Width -->
      <div class="form-group mb-2">
        <label>Largeur</label>
        <input type="number" class="form-control form-control-sm" min="1" placeholder="Largeur" [(ngModel)]="selectedProtectedArea.width" (ngModelChange)="updateProtectedAreaWidth()">
      </div>

      <!-- Color -->
      <div class="form-group mb-0">
        <label>Couleur</label>
        <input type="color" class="form-control" placeholder="Couleur" style="height: 2.5rem;" [(ngModel)]="selectedProtectedArea.color" (ngModelChange)="updateProtectedAreaColor()">
      </div>

      <!-- Delete -->
      <hr class="ml-0">
      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="deleteProtectedArea(selectedProtectedArea)">Supprimer</button>
    </div>
  </li>

</ul>