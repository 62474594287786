import { DiffCo2Program } from "./diffCo2Program";
import { DiffCo2 } from "./diffCo2";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class DiffusionParameters {
	
	@jsonMember
	diffHourSunrise: number = 0;
	
	@jsonMember
	diffHourSunset: number = 0;
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program1: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program2: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program3: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program4: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program5: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program6: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program7: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program8: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program9: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program10: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program1Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program2Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program3Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program4Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program5Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program6Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program7Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program8Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program9Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2Program)
	diffCo2Program10Temp: DiffCo2Program = new DiffCo2Program();
	
	@jsonMember(() => DiffCo2)
	diffCo2Sunrise: DiffCo2 = new DiffCo2();
	
	@jsonMember(() => DiffCo2)
	diffCo2Sunset: DiffCo2 = new DiffCo2();
	
	@jsonMember(() => DiffCo2)
	diffCo2Instant: DiffCo2 = new DiffCo2();
	
	@jsonMember(() => DiffCo2)
	diffCo2SunriseTemp: DiffCo2 = new DiffCo2();
	
	@jsonMember(() => DiffCo2)
	diffCo2SunsetTemp: DiffCo2 = new DiffCo2();
	
	@jsonMember(() => DiffCo2)
	diffCo2InstantTemp: DiffCo2 = new DiffCo2();
	
	@jsonMember
	co2PresInpMeas1: number = 0;
	
	@jsonMember
	co2PresInpMeas2: number = 0;
	
	@jsonMember
	co2PresOutMeas: number = 0;
	
	@jsonMember
	co2FlowMeas: number = 0;
	
	@jsonMember
	co2PresInpAvg: number = 0;
	
	@jsonMember
	co2PressInpOffs: number = 0;
	
	@jsonMember
	co2PresOutAvg: number = 0;
	
	@jsonMember
	co2PressOutOffs: number = 0;
	
	@jsonMember
	co2PressOutComp: number = 0;
	
	@jsonMember
	co2FlowAvg: number = 0;
	
	@jsonMember
	co2FlowOffs: number = 0;
	
	@jsonMember
	upcCo2DiffLvl: number = 0;
	
	@jsonMember
	upcDiffLvlAdj: number = 0;
	
	@jsonMember
	co2TempAvg: number = 0;
	
	static loadFromJSON(json): DiffusionParameters {
		let serializer = new TypedJSON(DiffusionParameters);
		return serializer.parse(json);
  }
}