<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Tâches</h4>
      <button type="button" class="btn btn-primary float-right" (click)="this.refreshToken$.next(undefined)"><i class="fa fa-refresh"></i> Rafraîchir</button>
    </div>

    <div class ="col-12 mb-2">
      <mat-slide-toggle class="float-right" [formControl]="toggleShowFinished" (change)="onToggleShowFinished()">Afficher les tâches terminées</mat-slide-toggle>
    </div>

    <div class="container" style="height: 50px">
      <div class="d-flex justify-content-center" *ngIf="isDataLoading">
        <mat-spinner color="success" diameter="40" *ngIf="isDataLoading"></mat-spinner>
      </div>
    </div>

    <!-- Polling -->
    <div class="col-12 mb-2">
      <div class="card">
        <div class="card-header font-weight-bold">Pollings</div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item text-center" *ngIf="!poolingTasks"><i class="fa fa-circle-o-notch fa-spin"></i></li>
          <li class="list-group-item text-center" *ngIf="poolingTasks && poolingTasks?.length == 0"><p class="mb-1">Aucun polling</p></li>
          <li class="list-group-item" *ngFor="let task of poolingTasks">
            <p class="mb-3"><b>{{ task.title }}<span class="float-right">{{ task.percent.toFixed(1) }}%
              <button type="button" class="btn btn-danger btn-sm"
              (click)="deletePollingJob(task.title)"><i class="fa fa-trash"></i>
              </button></span></b>
            </p>
            <p class="mb-1"><ngb-progressbar [value]="task.percent" [max]="100" type="info" [striped]="true" [animated]="true" height=".5rem"></ngb-progressbar></p>
          </li>
        </ul>
      </div>
    </div>

    <!-- Firmware -->
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-header font-weight-bold">Mises à jour du Firmware</div>
        <mat-table [dataSource]="datasourceFirmware" matSort>

          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggleFirmware() : null"
                            [checked]="selectionFirmware.hasValue() && isAllSelectedFirmware()"
                            [indeterminate]="selectionFirmware.hasValue() && !isAllSelectedFirmware()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selectionFirmware.toggle(row.upcv3.id) : null"
                            [checked]="selectionFirmware.isSelected(row.upcv3.id)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="upcNameId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom de l'UPC </mat-header-cell>
            <mat-cell *matCellDef="let element"> <a href="upc/v3/{{element.upcv3.id}}" target="_blank" [ngClass]="element.upcv3.upcNameIdClass">{{element.upcv3.upcNameId}}</a> </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="jobStatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Statut du processus </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.jobStatus}} ({{element.progress||0 | number:'1.0-2'}}%) </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="creationTimestamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date de lancement </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.creationTimestamp.toLocaleString('fr-FR')}} </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="updateTimestamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date du dernier statut </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.updateTimestamp.toLocaleString('fr-FR')}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tryCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Essai numéro </mat-header-cell>
            <mat-cell *matCellDef="let e"> {{e.tryCount}} {{ e.jobStatus == 'RETRYING' ? '(Prochain: ' + e.nextTry.toLocaleString('fr-FR') + ')' : '' }} </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="firmwareFilename">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Firmware </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.firmwareFilename}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let e">       
              <button type="button" class="btn btn-primary btn-sm" [disabled]="e.jobStatus != 'ERROR' && e.jobStatus != 'RETRYING'"
                (click)="retryFirmware(e.upcv3, e.firmwareFilename, e.jobStatus)"><i class="fa fa-refresh"></i> Réessayer
              </button>
              <button type="button" class="btn btn-danger btn-sm"
                (click)="deleteFirmwareJob(e.upcv3)"><i class="fa fa-trash"></i> Supprimer
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="footer">
            <mat-footer-cell *matFooterCellDef>
              <button type="button" class="btn btn-danger btn-sm" [disabled]="!isAtLeastOneSelectedFirmware()"
                (click)="deleteSelectedFirmwareJob()"><i class="fa fa-trash"></i> Supprimer la selection
              </button>
              <button type="button" class="btn btn-warning btn-sm ml-3" [disabled]="!isAtLeastOneSelectedArchivedOrHibernatedFirmware()"
                (click)="unselectArchivedAndHibernatedFirmwareJob()"><i class="fa fa-minus"></i> Désélectionner les archvées et en hivernage
              </button>
            </mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsFirmware"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsFirmware;"></mat-row>
          <mat-footer-row *matFooterRowDef="['footer']"></mat-footer-row>
        </mat-table>
      </div>
    </div>

    <!-- Synchronisation -->
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-header font-weight-bold">Synchronisation Paramètres</div>
        <mat-table [dataSource]="datasourceSync" matSort>

          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggleSync() : null"
                            [checked]="selectionSync.hasValue() && isAllSelectedSync()"
                            [indeterminate]="selectionSync.hasValue() && !isAllSelectedSync()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selectionSync.toggle(row.upcv3.id) : null"
                            [checked]="selectionSync.isSelected(row.upcv3.id)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="upcNameId">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom de l'UPC </mat-header-cell>
            <mat-cell *matCellDef="let element"> <a href="upc/v3/{{element.upcv3.id}}" target="_blank">{{element.upcv3.upcNameId}}</a> </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="jobStatus">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Statut du processus </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.jobStatus}} ({{element.progress||0 | number:'1.0-2'}}%) </mat-cell>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="creationTimestamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date de lancement </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.creationTimestamp.toLocaleString('fr-FR')}} </mat-cell>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="updateTimestamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date du dernier statut </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.updateTimestamp.toLocaleString('fr-FR')}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tryCount">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Essai numéro </mat-header-cell>
            <mat-cell *matCellDef="let e"> {{e.tryCount}} {{ e.jobStatus == 'RETRYING' ? '(Prochain: ' + e.nextTry.toLocaleString('fr-FR') + ')' : '' }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let e">       
              <button type="button" class="btn btn-primary btn-sm" [disabled]="e.jobStatus != 'ERROR' && e.jobStatus != 'RETRYING'" 
                (click)="retrySync(e.upcv3, e.parameters, e.jobStatus)"><i class="fa fa-refresh"></i> Réessayer
              </button>
              <button type="button" class="btn btn-danger btn-sm"
                (click)="deleteSyncJob(e.upcv3)"><i class="fa fa-trash"></i> Supprimer
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="footer">
            <mat-footer-cell *matFooterCellDef>
              <button type="button" class="btn btn-danger btn-sm" [disabled]="!isAtLeastOneSelectedSync()"
                (click)="deleteSelectedSyncJob()"><i class="fa fa-trash"></i> Supprimer la selection
              </button>
              <button type="button" class="btn btn-warning btn-sm ml-3" [disabled]="!isAtLeastOneSelectedSync()"
                (click)="unselectArchivedAndHibernatedSyncJob()"><i class="fa fa-minus"></i> Désélectionner les archvées et en hivernage
              </button>
            </mat-footer-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsSync"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsSync;"></mat-row>
          <mat-footer-row *matFooterRowDef="['footer']"></mat-footer-row>
        </mat-table>
      </div>
    </div>

    <!-- Legacy Task -->
    <div class="col-12 mb-3">
      <div class="card">
        <div class="card-header font-weight-bold">Tâches Legacy</div>
        <mat-table [dataSource]="datasourceLegacyTasks" matSort>
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Nom de l'UPC </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Actions </mat-header-cell>
            <mat-cell *matCellDef="let e">       
              <button type="button" class="btn btn-danger btn-sm"
                (click)="deleteLegacyTask(e.title)"><i class="fa fa-trash"></i> Supprimer
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsLegacy"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsLegacy;"></mat-row>
        </mat-table>
      </div>
    </div>

  </div>
</div>
