import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Project, PictureSource } from '../../../models/gdp/project';
import { ProjectService } from '../../../services/gdp/project.service';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';

@Component({
  templateUrl: './add-project.component.html'
})
export class AddProjectModalComponent extends Modal<Project> {
  // Form
  addressUnknow = false;
  form: FormGroup;
  formSubmitted = false;

  // Map
  @ViewChild('map', { static: false }) map: AgmMap;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      name: '',
      client: '',

      address: '',
      lat: 0,
      lng: 0,
      zoom: 0,
      distance: 0
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  /* Geolcation */
  geolocate() {
    new window['google'].maps.Geocoder().geocode({ 'address': this.f.address.value }, function(results, status) {
      if (status == 'OK') {
        // Update form
        this.form.patchValue({
          address: results[0].formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          zoom: 15
        });

        this.map.latitude = results[0].geometry.location.lat();
        this.map.longitude = results[0].geometry.location.lng();
        this.map.triggerResize();
      } else this.addressUnknow = true;
    }.bind(this));
  }
  geoCalculate() {
    new window['google'].maps.DistanceMatrixService().getDistanceMatrix({
      origins: [ new window['google'].maps.LatLng(43.6585209, 7.1359285) ],
      destinations: [ new window['google'].maps.LatLng(this.form.controls.lat.value, this.form.controls.lng.value) ],
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status == 'OK') {
        if (response.rows.length > 0 && response.rows[0].elements.length > 0 && response.rows[0].elements[0].distance != undefined)
          this.form.patchValue({ distance: response.rows[0].elements[0].distance.value / 1000 });
      } else
        alert('Distance matrix was not successful for the following reason: ' + status);
    })
  }

  // Map
  dragEnd(event) { this.form.patchValue({ lat: event.coords.lat, lng: event.coords.lng }) }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Get map zoom
    this.form.patchValue({ zoom: this.map.zoom });

    // Try create
    this.formSubmitted = true;
    this.projectService.create(this.form).subscribe(
      () => this.close(true),
      () => this.close()
    )
  }

}