import { Component, Input, Output, EventEmitter } from "@angular/core";
import { GDP } from "src/app/models/gdp/gdp";

@Component({
  selector: 'gdp-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: [ './treeview.component.css' ]
})
export class TreeviewComponent {
  // NF
  @Input('gdp') gdp: GDP;
  @Output('treeSelectedChange') treeSelectedChange = new EventEmitter();

  /*
   * Methods
   */
  selectBelt(e: any, id: string): void {
    if (e.target.checked) this.gdp.version.selectedBelt = id; // Selected
    else this.gdp.version.selectedBelt = null; // Unselected
  }
  selectProtectedArea(e: any, id: string): void {
    if (e.target.checked) this.gdp.version.selectedProtectedArea = id; // Selected
    else this.gdp.version.selectedProtectedArea = null; // Unselected
    this.treeSelectedChange.emit();
  }
  onTreeSelectedChange() {
    this.treeSelectedChange.emit();
  }
  selectAllNodes(e: any, beltId: string) {
    this.gdp.getBelt(beltId).nodes.forEach(node => node.selected = e.target.checked);
    this.treeSelectedChange.emit();
  }
  selectAllPaths(e: any, beltId: string) {
    this.gdp.getBelt(beltId).paths.forEach(path => path.selected = e.target.checked);
    this.treeSelectedChange.emit();
  }

}