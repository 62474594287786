<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Dessinateurs</h4>

      <button type="button" class="btn btn-primary float-right" (click)="openAddDesignerModal()"><i class="fa fa-plus"></i> Dessinateur</button>
    </div>

    <!-- Content -->
    <div class="col-12 mb-3">
      <ul class="list-group list-group-striped">

        <!-- Header -->
        <li class="list-group-item d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-4 mb-0 font-weight-bold">Nom complet</p>
                <p class="col-4 mb-0 font-weight-bold">Email</p>
                <p class="col-4 mb-0 font-weight-bold">Téléphone</p>
              </div>
            </div>
            <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
          </div>
        </li>

        <!-- Loading & Empty -->
        <li class="list-group-item px-3 py-2 text-center" *ngIf="!designers"><i class="fa fa-circle-o-notch fa-spin"></i></li>
        <li class="list-group-item px-3 py-2 text-center" *ngIf="designers && designers?.length == 0">Aucun dessinateur</li>

        <!-- Row -->
        <li class="list-group-item px-3 py-1" *ngFor="let designer of designers">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-md-4 mt-1 mb-0 font-weight-bold"><small class="d-md-none mr-2">Nom complet</small>{{ designer.lastName }} {{ designer.firstName }}</p>
                <p class="col-md-4 mt-1 mb-0"><small class="d-md-none mr-2">Email</small>{{ designer.username }}</p>
                <p class="col-md-4 mt-1 mb-0"><small class="d-md-none mr-2">Téléphone</small>{{ designer.phone }}</p>
              </div>
            </div>
            <div class="col-2 mb-0 text-right">
              <div placement="bottom-right" ngbDropdown>
                <button class="btn btn-link text-dark px-3 py-0" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
                <div class="p-0" ngbDropdownMenu>
                  <button class="dropdown-item px-3 py-2" (click)="openDeleteDesignerModal(designer)"><i class="fa fa-trash"></i> Supprimer</button>
                  <button class="dropdown-item px-3 py-2" (click)="openEditDesignerModal(designer)"><i class="fa fa-edit"></i> Modifier</button>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>

  </div>
</div>
