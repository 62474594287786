/*
 * Code Legacy pris de la logique de calul des stats par UPC
 * Le tableau n'est pas très comprehensible et ne semble pas fonctionner
 * même pour le cas d'une simple UPC
 */


import { map, takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { UPCV3Service } from "src/app/services/upcv3/upcv3.service";
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: "app-upc-group-stats-table",
  templateUrl: "./tableStats.component.html",
  styleUrls: ["./tableStats.component.css"],
})
export class TableStatsComponent implements OnInit, OnDestroy {
  @Input("upcv3Group") upcv3Group: GroupUPCV3;
  @Input("from") from: NgbDate;
  @Input("to") to: NgbDate;
  @Input() dateChanged: Observable<void>;

  private onDestroy$: Subject<void> = new Subject<void>();
  private dateChangedSubscription: Subscription;

  tableStats = [
    [0, 0, 0, 0, 0, 0], // January
    [0, 0, 0, 0, 0, 0], // February
    [0, 0, 0, 0, 0, 0], // March
    [0, 0, 0, 0, 0, 0], // April
    [0, 0, 0, 0, 0, 0], // May
    [0, 0, 0, 0, 0, 0], // June
    [0, 0, 0, 0, 0, 0], // July
    [0, 0, 0, 0, 0, 0], // August
    [0, 0, 0, 0, 0, 0], // September
    [0, 0, 0, 0, 0, 0], // October
    [0, 0, 0, 0, 0, 0], // November
    [0, 0, 0, 0, 0, 0], // December
  ];

  tableStatsGroup = [];

  constructor(private upcv3Service: UPCV3Service, private toasterService: ToasterService) { }

  ngOnInit() {
    this.loadTableStats();
    this.dateChangedSubscription = this.dateChanged.subscribe(() => {
      this.loadTableStats();
    });
  }

  ngOnDestroy(): void {
    this.dateChangedSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  loadTableStats() {
    const dataObservaleArray = [];

    for (const upc of this.upcv3Group.upcv3s) {
      dataObservaleArray.push(
        this.upcv3Service
          .getTableStats(upc.id, this.from.year)
          .pipe(takeUntil(this.onDestroy$)).pipe(
            map((res) => {
              if (res == null || !Array.isArray(res)) {
                this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des statistiques");
                this.tableStatsGroup.push([
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                  [0, 0, 0, 0, 0, 0],
                ]);
              } else {
                this.tableStatsGroup.push(res);
              }
            })
          )
      );
    }

    forkJoin(dataObservaleArray).subscribe(() => {});

    setTimeout(() => {
      var cpt = 0;
      this.tableStatsGroup.forEach((item) => {
        for (var j = 0; j < item.length; j++) {
          for (var k = 0; k < item[j].length; k++) {
            if (k % 4 == 0 && k != 0) {
              this.tableStats[j][k] = item[j][k];
            } else if (k % 5 == 0 && k != 0) {
              this.tableStats[j][k] += item[j][k];
            } else {
              this.tableStats[j][k] += item[j][k];
              if (cpt > this.tableStats.length) {
                this.tableStats[j][k] =
                  this.tableStats[j][k] / this.upcv3Group.upcv3s.length;
              }
            }
          }
          cpt++;
        }
      });
    }, 500);
  }
}
