import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { VersionService } from 'src/app/services/gdp/version.service';
import { Version } from 'src/app/models/gdp/version';

@Component({
  templateUrl: './delete-version.component.html'
})
export class DeleteVersionModalComponent extends Modal<Version> {
  // Form
  submitted = false;

  constructor(
    private router: Router,
    private versionService: VersionService
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.versionService.delete(this.subject.id).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }

}