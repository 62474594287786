import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Page, PagingResponse} from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { InterventionV3 } from '../../models/upcv3/interventionV3';
import { ToasterService } from 'angular2-toaster';
import { InterventionV3PostDto } from 'src/app/models/dto/InterventionV3Post.dto';


@Injectable({
  providedIn: 'root',
})
export class InterventionV3Service {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) { }

  // Codes: INTERVENTIONV3_RECOVERED | UNAUTHORIZED | INTERVENTIONV3_DOESNT_EXSIST
  public get(id: number): Observable<InterventionV3> {
    return this.http.get<ApiResponse<InterventionV3>>(environment.apiUrl + 'upcv3/intervention/' + id).pipe(map(
      res => InterventionV3.loadFromJSON(res.result)
    ))
  }

  public getByUpcv3(id: number, page: number = 0, pagesize: number = 10): Observable<Page<InterventionV3>> {
    return this.http.get<PagingResponse<InterventionV3>>(environment.apiUrl + 'upcv3/' + id + '/interventions', {
      params: {
        page: page,
        size: pagesize
      }
    }).pipe(map(
      res => {
        res.result.content = res.result.content.map(r => InterventionV3.loadFromJSON(r));
        return res.result;
      }
    ));
  }

  public getByGroupUPCV3Id(id: number, page: number = 0, pagesize: number = 10): Observable<Page<InterventionV3>> {
    return this.http.get<PagingResponse<InterventionV3>>(environment.apiUrl + 'groupUpcv3/' + id + '/interventions', {
      params: {
        page: page,
        size: pagesize
      }
    }).pipe(map(
      res => {
        res.result.content = res.result.content.map(r => InterventionV3.loadFromJSON(r));
        return res.result;
      }
    ));
  }

  public getBySiteId(id: string, page: number = 0, pagesize: number = 10): Observable<Page<InterventionV3>> {
    return this.http.get<PagingResponse<InterventionV3>>(environment.apiUrl + 'site/' + id + '/interventions', {
      params: {
        page: page,
        size: pagesize
      }
    }).pipe(map(
      res => {
        res.result.content = res.result.content.map(r => InterventionV3.loadFromJSON(r));
        return res.result;
      }
    ));
  }

  public interventionSheet(id: number): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('accept', 'application/pdf');
    return this.http.get(environment.apiUrl + 'upcv3/intervention/' + id + '/interventionSheet', { headers: headers, responseType: 'arraybuffer' })
  }

  // Codes: INTERVENTIONV3_CREATED | UNAUTHORIZED | WRONG_PARAMS | UPCV3_DOESNT_EXSIST | INTERNAL_ERROR
  public create(form: InterventionV3PostDto): Observable<InterventionV3> {
    return this.http.post<ApiResponse<InterventionV3>>(environment.apiUrl + 'upcv3/intervention', form).pipe(map(
      res => InterventionV3.loadFromJSON(res.result)
    ))
  }

  // Codes: INTERVENTIONV3_DELETED | UNAUTHORIZED | INTERVENTIONV3_DOESNT_EXSIST | INTERVENTIONV3_USED
  public delete(id: number): Observable<{}> {
    return this.http.delete<ApiResponse<{}>>(environment.apiUrl + 'upcv3/intervention/' + id).pipe(map(
      res => res.result
    ))
  }

}