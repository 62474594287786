import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models/ApiResponse';
import { InterventionLegacy } from 'src/app/models/upcv3/interventionLegacy';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InterventionLegacyService {
  constructor(
    private http: HttpClient,
  ) { }

  // Codes: INTERVENTION_LEGACY_RECOVERED | UNAUTHORIZED | INTERVENTION_LEGACY_DOESNT_EXSIST
  public get(id: number): Observable<InterventionLegacy> {
    return this.http.get<ApiResponse<InterventionLegacy>>(environment.apiUrl + 'upcv3/intervention/legacy/' + id).pipe(map(
      res => InterventionLegacy.loadFromJSON(res.result)
    ))
  }

  public getByUpcv3(id: number): Observable<InterventionLegacy[]> {
    return this.http.get<ApiResponse<InterventionLegacy[]>>(environment.apiUrl + 'upcv3/' + id + '/interventions_legacy').pipe(map(
      res => {
        res.result = res.result.map(r => InterventionLegacy.loadFromJSON(r));
        return res.result;
      }
    ));
  }
}