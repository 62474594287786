import { EventCode } from "./eventCode";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { UPCV3 } from "./upc/upcv3";

@jsonObject
export class Event {

  @jsonMember
  id: number;

  @jsonMember
  upcNameId: string;

  @jsonMember
  eventDatetime: Date;

  @jsonMember
  serverDatetime: Date;

  @jsonMember
  eventParam1: string;

  @jsonMember
  eventParam2: string;

  @jsonMember
  eventParam3: string;

  @jsonMember
  eventParam4: string;

  @jsonMember
  fromSms: boolean;

  @jsonMember(() => UPCV3)
  upcv3: UPCV3;

  @jsonMember
  eventCode: EventCode;

  @jsonMember
  eventCodeId: number;

  get eventParam1HTML(): string { return this.getEventParamHTML(1) }
  get eventParam2HTML(): string { return this.getEventParamHTML(2) }
  get eventParam3HTML(): string { return this.getEventParamHTML(3) }
  get eventParam4HTML(): string { return this.getEventParamHTML(4) }
  
  getEventParamHTML(index: number): string {
    var string = '';

    // Get params
    var eventParam: string = '', parameterNameFR: string = '', unit: string = '', parameterType: number = 0;
    switch (index) {
      case 1: eventParam = this.eventParam1, parameterNameFR = this.eventCode.parameterName1FR, unit = this.eventCode.unit1, parameterType = this.eventCode.parameterType1; break;
      case 2: eventParam = this.eventParam2, parameterNameFR = this.eventCode.parameterName2FR, unit = this.eventCode.unit2, parameterType = this.eventCode.parameterType2; break;
      case 3: eventParam = this.eventParam3, parameterNameFR = this.eventCode.parameterName3FR, unit = this.eventCode.unit3, parameterType = this.eventCode.parameterType3; break;
      case 4: eventParam = this.eventParam4, parameterNameFR = this.eventCode.parameterName4FR, unit = this.eventCode.unit4, parameterType = this.eventCode.parameterType4; break;
    }

    // Check params
    if (parameterNameFR != null && parameterNameFR != '') string = '<small>' + parameterNameFR + ' </small>';
    if (!parameterNameFR && eventParam == '0') return '';
    else {
      if (parameterType == 5) string += this.secondsToTime(parseInt(eventParam));                                         // Time
      else if (parameterType == 1 || parameterType == 3 || parameterType == 7) string += this.getFloatString(eventParam); // Float
      else if (parameterType == 8) string += this.getReserveTypeString(parseInt(eventParam));                             // Reserve Type
      else if (parameterType == 9) string += this.getActiveReserveString(parseInt(eventParam));                           // Active Reserve
      else if (parameterType == 10) string += this.getMdmModeString(parseInt(eventParam));                                // Modem Mode
      else if (parameterType == 11) string += this.getDiffusionDayModeString(parseInt(eventParam));                       // Day Code
      else if (parameterType == 12) string += this.getOffsetString(parseInt(eventParam));                                 // Offset +/-HH:MM
      else if (parameterType == 13) string += this.getContentStatusString(eventParam);                                    // Content Status
      else if (parameterType == 14) string += this.getModbusErrorString(eventParam);                                      // Modbus Error
      else if (parameterType == 15) string += this.getOTAErrorString(eventParam);                                         // OTA Error
      else if (parameterType == 16) string += this.getTCPErrorString(eventParam);                                         // TCP Error
      else string += eventParam;
    }

    // Add param Unit
    if (unit != null && unit != '') string += ' ' + unit;

    return string;

  }

  getMdmModeString(mode: number): string {
    switch (mode) {
      case 1: return '2G GPRS';
      case 2: return '2G EDGE';
      case 3: return '3G WCDMA';
      case 4: return '3G HSDPA';
      case 5: return '3G HSUPA';
      case 6: return '3G HSDPA/HSUPA';
      case 7: return '4G';
      default: return 'Non enregistré';
    }
  }
  getDiffusionDayModeString(mode: number): string {
    switch (mode) {
      case 0: return 'Dimanche';
      case 1: return 'Lundi';
      case 2: return 'Mardi';
      case 3: return 'Mercredi';
      case 4: return 'Jeudi';
      case 5: return 'Vendredi';
      case 6: return 'Samedi';
      case 7: return 'Tous les jours';
      case 8: return 'Weekend';
      case 9: return 'Semaine';
      default: return mode + '';
    }
  }

  getTCPErrorString(code: string) {
    const map = new Map<number, string>([
      [506, "SIM non détectée"],
      [507, "Code PIN non supprimé"],
    ]);
    try { return map.get(parseInt(code)) ?? code; }
    catch (e) { return code; }
  }

  getOTAErrorString(code: string) {
    var otaErr = ['N.D.', 'BootBlkSwp', 'UpBlkErase', 'UpBlkFlsh', 'RecWrt', 'CRC', 'UpBlkSec', 'EndBlkSwp'];
    try { return otaErr[parseInt(code)]; }
    catch (e) { return code; }
  }

  getModbusErrorString(code: string): string {
    var mdbFunc = ['Connexion', 'Lecture', 'Écriture', 'Lecture IReg'];
    try { return mdbFunc[parseInt(code)]; }
    catch (e) { return code; }
  }

  getContentStatusString(code: string): string {
    var contentStatus = ['Vide', 'Résiduel', 'Disponible'];
    try { return contentStatus[parseInt(code)]; }
    catch (e) { return code; }
  }
  
  getReserveTypeString(code: number): string {
    var reserveTypes;
    if (code == 1) reserveTypes = ['Symétrique', 'Primaire', 'Secondaire'];
    else reserveTypes = ['Symétrique', 'Secondaire', 'Primaire'];
    return reserveTypes[code];
  }
  getActiveReserveString(code: number) {
    switch (code) {
      case 1: return '&rArr;R1';
      case 2: return '&rArr;R2';
      default: return code;
    }
  }
  getOffsetString(param: number) {
    var negative: boolean = false, value: number = param;
    if (value > 0x7FFFFFFF) value -= 0x100000000, negative = true;
    return (negative ? '-' : '') + this.secondsToTime(Math.abs(value));
  }
  getFloatString(param: string): string {
    var float = Math.round(parseFloat(param) * 1000) / 1000;
    if (isNaN(float)) return param;
    else return float + '';
  }

  secondsToTime(sec_num: number): string {
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);

    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;

    return hours + ':' + minutes;
  }

  static loadFromJSON(json): Event {
    let serializer = new TypedJSON(Event);
		return serializer.parse(json);
  }
}