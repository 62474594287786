import { Component, OnInit, Input } from '@angular/core';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { Bottle, status } from 'src/app/models/management/bottle';
import { co2LitersToKg } from 'src/app/models/upcv3/upc/upcv3';


@Component({
  selector: 'app-group-upc-parameters',
  templateUrl: './groupParameters.component.html',
  styleUrls: [ './groupParameters.component.css' ]
})

export class GroupParametersComponent implements OnInit {
  @Input('group') group: GroupUPCV3;

  bottlesB1: Bottle[];
  bottlesB2: Bottle[];
  bottlesSite: Bottle[];
  displayedColumns = ['barcode', 'type', 'state'];

  co2LitersToKg = co2LitersToKg;

  constructor() { }

  ngOnInit() {
    const bottlesOfGroup = this.group.site?.bottles.filter(b => b.groupUPCV3?.id == this.group.id);
    this.bottlesB1 = bottlesOfGroup?.filter(b => b.status == status.B1);
    this.bottlesB2 = bottlesOfGroup?.filter(b => b.status == status.B2);
    this.bottlesSite = this.group.site?.bottles.filter(b => b.status == status.ENTREPOSE);
  }
}
