import { Component, OnInit } from '@angular/core';
import { Modal } from 'src/app/models/ui/modal';
import { StockService } from 'src/app/services/management/stock.service';
import { ToasterService } from 'angular2-toaster';
import { BottleTypeService } from 'src/app/services/management/bottleType.service';
import { UUID } from 'angular2-uuid';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Stock } from 'src/app/models/management/stock';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';

@Component({
  selector: 'app-add-bottles',
  templateUrl: './add-bottles.component.html',
  styleUrls: ['./add-bottles.component.css']
})
export class AddBottlesComponent extends Modal<any> implements OnInit {
  bottles: any;
  status = ["Pleine", "Entamée", "Vide"];
  statu = "Pleine";
  formSubmitted = false;
  bottleadded = {
    name: '',
    bottleiD: [],
    stock: '',
    date: new Date().toISOString().substring(0, 10),
    reserve: "",
    beltId: '',
    kg: [],
    barcodes: [],
    bottleaddedB1: 0,
    bottleaddedB2: 0,
    status: []
  };
  bottleadded2: any = { bottleiD: [] };
  desig: any;
  dg: any;
  barcode: string;
  stocks: Stock[] = [];
  upcv3: UPCV3[];
  qty = 1;


  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private stockService: StockService, private toastService: ToasterService, private bottleTypeServ: BottleTypeService, private upcv3Service: UPCV3Service) {
    super();

  }
  
  ngOnInit(): void {
      
  }
}
