<div class="row mt-3">
  <div class="col-12">
    <ul ngbNav #nav="ngbNav" class="nav-pills">
      <li ngbNavItem *ngFor="let test of upcMcu.tests">
        <a ngbNavLink>{{ test.date.toLocaleDateString('fr-FR') }}</a>
        <ng-template ngbNavContent>
          <div class="row mt-3">
    
            <!-- General -->
            <div class="col-lg-4">
              <div class="card">
                <div class="card-header font-weight-bold">Général</div>
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush">
                    
                    <li class="list-group-item py-2">Identifiant du boîtier<b class="float-right">{{ test.boxTest ? test.boxTest.upcBoxId : '-' }}</b></li>
                    
                    <li class="list-group-item py-2">Offsets
                      <ul class="list-group">
                        <li class="list-group-item py-2">Pression d'entrée (bar)<b class="float-right">{{ test.cardTest ? test.cardTest.inPressOff : '-' }}</b></li>
                        <li class="list-group-item py-2">Pression de sortie (bar)<b class="float-right">{{ test.cardTest ? test.cardTest.outPressOff : '-' }}</b></li>
                        <li class="list-group-item py-2">Flux de CO2 (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flowOff : '-' }}</b></li>
                      </ul>
                    </li>
    
                  </ul>
                </div>
              </div>
            </div>
    
            <!-- Card test -->
            <div class="col-md-4">
              <div class="card">
                <div class="card-header font-weight-bold">Tests de la carte</div>
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush">
    
                    <li class="list-group-item">Points de test (V)
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">TP1<b class="float-right">{{ test.cardTest ? test.cardTest.pointsTest1 : '-' }}</b></li>
                        <li class="list-group-item py-2">TP2<b class="float-right">{{ test.cardTest ? test.cardTest.pointsTest2 : '-' }}</b></li>
                        <li class="list-group-item py-2">TP3<b class="float-right">{{ test.cardTest ? test.cardTest.pointsTest3 : '-' }}</b></li>
                        <li class="list-group-item py-2">TP4<b class="float-right">{{ test.cardTest ? test.cardTest.pointsTest4 : '-' }}</b></li>
                        <li class="list-group-item py-2">TP5<b class="float-right">{{ test.cardTest ? test.cardTest.pointsTest5 : '-' }}</b></li>
                        <li class="list-group-item py-2">TP6<b class="float-right">{{ test.cardTest ? test.cardTest.pointsTest6 : '-' }}</b></li>
                        <li class="list-group-item py-2">LED D1<b class="float-right">{{ test.cardTest ? (test.cardTest.led1 ? 'Oui' : 'Non') : '-' }}</b></li>
                        <li class="list-group-item py-2">LED D2<b class="float-right">{{ test.cardTest ? (test.cardTest.led2 ? 'Oui' : 'Non') : '-' }}</b></li>
                        <li class="list-group-item py-2">LED D3<b class="float-right">{{ test.cardTest ? (test.cardTest.led3 ? 'Oui' : 'Non') : '-' }}</b></li>
                        <li class="list-group-item py-2">LED D4<b class="float-right">{{ test.cardTest ? (test.cardTest.led4 ? 'Oui' : 'Non') : '-' }}</b></li>
                      </ul>
                    </li>
    
                    <li class="list-group-item py-2">Wifi (%)
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Niveau de signal<b class="float-right">{{ test.cardTest ? test.cardTest.wifiLevel : '-' }}</b></li>
                      </ul>
                    </li>
    
                    <li class="list-group-item py-2">GSM
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Mode<b class="float-right">{{ test.cardTest ? test.cardTest.gsmMode : '-' }}</b></li>
                        <li class="list-group-item py-2">Niveau de signal (dB)<b class="float-right">{{ test.cardTest ? test.cardTest.gsmLevel : '-' }}</b></li>
                      </ul>
                    </li>
    
                  </ul>
                </div>
              </div>
              <div class="card mt-2">
                <div class="card-header font-weight-bold">Commentaires sur la carte</div>
                <div class="card-body">
                  <span>{{test.cardTest && test.cardTest.observations.length != 0 && test.cardTest.observations != null ? test.cardTest.observations : "Aucun commentaires..."}}</span>
                </div>
              </div>
            </div>
    
            <!-- Box test -->
            <div class="col-md-4">
              <div class="card">
                <div class="card-header font-weight-bold">Tests du boîtier</div>
                <div class="card-body p-0">
                  <ul class="list-group list-group-flush">
    
                    <li class="list-group-item py-2">Fuites (nl/mn)
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Valve 1
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item py-2">Débit interne<b class="float-right">{{ test.boxTest ? test.boxTest.leaksV1InternFlow : '-' }}</b></li>
                            <li class="list-group-item py-2">Débit externe<b class="float-right">{{ test.boxTest ? test.boxTest.leaksV1ExternFlow : '-' }}</b></li>
                          </ul>
                        </li>
                        <li class="list-group-item py-2">Valve 2
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item py-2">Débit interne<b class="float-right">{{ test.boxTest ? test.boxTest.leaksV2InternFlow : '-' }}</b></li>
                            <li class="list-group-item py-2">Débit externe<b class="float-right">{{ test.boxTest ? test.boxTest.leaksV2ExternFlow : '-' }}</b></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
    
                    <li class="list-group-item py-2">Pression d'entrée (bar)<b class="float-right">{{ test.boxTest ? test.boxTest.inPress : '-' }}</b></li>
    
                    <li class="list-group-item py-2">Débits
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Test à 0.5 nl/mn
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item py-2">Débit externe (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flow1Extern : '-' }}</b></li>
                            <li class="list-group-item py-2">Débit interne (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flow1Intern : '-' }}</b></li>
                            <li class="list-group-item py-2">Différence (bar)<b class="float-right">{{ test.boxTest ? abs((test.boxTest.flow1Extern - test.boxTest.flow1Intern) / test.boxTest.flow1Intern * 100) : '-' }}</b></li>
                          </ul>
                        </li>
                        <li class="list-group-item py-2">Test à 2 nl/mn
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item py-2">Débit externe (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flow2Extern : '-' }}</b></li>
                            <li class="list-group-item py-2">Débit interne (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flow2Intern : '-' }}</b></li>
                            <li class="list-group-item py-2">Différence (bar)<b class="float-right">{{ test.boxTest ? abs((test.boxTest.flow2Extern - test.boxTest.flow2Intern) / test.boxTest.flow2Intern * 100) : '-' }}</b></li>
                          </ul>
                        </li>
                        <li class="list-group-item py-2">Test à 10 nl/mn
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item py-2">Débit externe (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flow3Extern : '-' }}</b></li>
                            <li class="list-group-item py-2">Débit interne (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.flow3Intern : '-' }}</b></li>
                            <li class="list-group-item py-2">Différence (bar)<b class="float-right">{{ test.boxTest ? abs((test.boxTest.flow3Extern - test.boxTest.flow3Intern) / test.boxTest.flow3Intern * 100) : '-' }}</b></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
    
                    <li class="list-group-item py-2">Valve proprtionnelle
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Débit minimum (nl/mn)<b class="float-right">{{ test.boxTest ? test.boxTest.propValveMinFlow : '-' }}</b></li>
                        <li class="list-group-item py-2">Pression minimum (bar)<b class="float-right">{{ test.boxTest ? test.boxTest.propValveMinPressure : '-' }}</b></li>
                      </ul>
                    </li>
    
                    <li class="list-group-item py-2">Fermeture valves
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Pression avant (bar)<b class="float-right">{{ test.boxTest ? test.boxTest.closeValvePressBefore : '-' }}</b></li>
                        <li class="list-group-item py-2">Pression après (bar)<b class="float-right">{{ test.boxTest ? test.boxTest.closeValvePressAfter : '-' }}</b></li>
                        <li class="list-group-item py-2">Flux de CO2 externe (bar)<b class="float-right">{{ test.boxTest ? test.boxTest.closeValveExternFlow : '-' }}</b></li>
                      </ul>
                    </li>
    
                    <li class="list-group-item py-2">Clapets anti-retour (bar)
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item py-2">Pression avant<b class="float-right">{{ test.boxTest ? test.boxTest.returnCheckPressBefore : '-' }}</b></li>
                        <li class="list-group-item py-2">Pression après<b class="float-right">{{ test.boxTest ? test.boxTest.returnCheckPressAfter : '-' }}</b></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card mt-2">
                <div class="card-header font-weight-bold">Commentaires sur le boitier</div>
                <div class="card-body">
                  <span>{{test.boxTest && test.boxTest.observations.length != 0 && test.boxTest.observations != null ? test.boxTest.observations : "Aucun commentaires..."}}</span>
                </div>
              </div>
            </div>
    
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>

    <p class="text-center mb-0" *ngIf="!upcMcu.tests"><i class="fa fa-circle-o-notch fa-spin"></i></p>
    <p class="text-center mb-0" *ngIf="upcMcu.tests && upcMcu.tests.length == 0">Aucun test</p>
  </div>
</div>