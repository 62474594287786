import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
@jsonObject
export class DefaultUPCV3Params {
	@jsonMember
	id: string;
	
	@jsonMember
	co2FlowRefTrap: number;
	
	@jsonMember
	refFlowRateGperhour: number;
	
	@jsonMember
	daysCode1: string;
	
	@jsonMember
	startTime1: string;
	
	@jsonMember
	endTime1: string;
	
	@jsonMember
	intensity1: number;
	
	@jsonMember
	daysCode2: string;
	
	@jsonMember
	startTime2: string;
	
	@jsonMember
	endTime2: string;
	
	@jsonMember
	intensity2: number;
	
	@jsonMember
	sunriseDelay: number;
	
	@jsonMember
	sunriseDuration: number;
	
	@jsonMember
	sunriseIntensity: number;
	
	@jsonMember
	sunsetDelay: number;
	
	@jsonMember
	sunsetDuration: number;
	
	@jsonMember
	sunsetIntensity: number;
	
	@jsonMember
	alrResLowEn: boolean;
	
	@jsonMember
	alrResEmptyEn: boolean;
	
	@jsonMember
	alrPresInpEn: boolean;
	
	@jsonMember
	alrPresOutEn: boolean;
	
	@jsonMember
	alrFlowAvgEn: boolean;
	
	@jsonMember
	alrPowBackEn: boolean;
	
	@jsonMember
	alrPowDownEn: boolean;
	
	@jsonMember
	alrEmptyPress: number;
	
	@jsonMember
	alrEmptyVol: number;
	
	@jsonMember
	alrPresInpTol: number;
	
	@jsonMember
	alrPresOutTol: number;
	
	@jsonMember
	alrFlowSetTol: number;
	
	@jsonMember
	alrResLowLevel: number;
	
	@jsonMember
	alrResEmptyFlow: number;
	
	@jsonMember
	alrSmsNum0: string;
	
	@jsonMember
	comWiFiName: string;
	
	@jsonMember
	comWiFiPass: string;
	
	@jsonMember
	upcLanguage: number;
	
	@jsonMember
	comGsmName: string;
	
	@jsonMember
	comGsmPass: string;
	
	@jsonMember
	comWebSrvUrl: string;
	
	@jsonMember
	upcCo2PidProp: number;
	
	@jsonMember
	upcCo2PidInteg: number;
	
	@jsonMember
	upcCo2PidDiff: number;
	
	@jsonMember
	output_table_ref_temperature: number;
	
	@jsonMember
	output_table_tube_diam: number;
	
	@jsonMember
	output_table_kblasius: number;
	
	@jsonMember
	output_table_klaminaire: number;
	
	@jsonMember
	output_table_kjn: number;
	
	@jsonMember
	output_table_re_threshold: number;
	
	@jsonMember
	output_table_iter_start_pressure: number;
	
	@jsonMember
	output_table_co2_norm_density: number;
	
	@jsonMember
	output_table_masse_co2_temp: number;
	
	@jsonMember
	output_table_masse_co2_dens: number;
	
	@jsonMember
	output_table_dyn_visc_0deg: number;
	
	@jsonMember
	output_table_poise_constant: number;
	
	@jsonMember
	output_table_poise_temperature: number;
	
	@jsonMember
	output_table_s_tempk: number;
	
	@jsonMember
	output_table_epsilon_q: number;
	
	@jsonMember
	output_table_a_constant: number;
	
	@jsonMember
	output_table_b_constant: number;
	
	@jsonMember
	polling_start_time: string;
	
	@jsonMember
	polling_end_time: string;
	
	@jsonMember
	polling_frequency: number;
	
	@jsonMember
	daysCode1Int: number;
	
	@jsonMember
	daysCode2Int: number;
  

  static loadFromJSON(json): DefaultUPCV3Params {
    const serializer = new TypedJSON(DefaultUPCV3Params);
    return serializer.parse(json);
  }
}