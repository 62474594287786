<ul class="gdp-treeview p-2">
  
  <!-- Belt -->
  <li *ngFor="let belt of gdp.version.belts">

    <!-- Title -->
    <div class="d-flex">
      <i class="fa fa-caret-down pt-1 pr-3" [ngClass]="{ 'fa-caret-right': belt.collapsed }" (click)="belt.collapsed = !belt.collapsed"></i>
      <div class="form-check">
        <input id="{{ belt.id }}" class="form-check-input" type="checkbox" [checked]="belt.id == gdp.version.selectedBelt" (change)="selectBelt($event, belt.id)">
        <label for="{{ belt.id }}" class="form-check-label">
          {{ belt.name }}
        </label>
      </div>
    </div>
    
    <!-- Elements -->
    <ul [ngbCollapse]="belt.collapsed">
      
      <!-- Nodes -->
      <li>

        <!-- Title -->
        <div class="d-flex">
          <i class="fa fa-caret-down pt-1 pr-3" [ngClass]="{ 'fa-caret-right': belt.nodesCollapsed }" (click)="belt.nodesCollapsed = !belt.nodesCollapsed"></i>
          <div class="form-check">
            <input id="n_{{ belt.id }}" class="form-check-input" type="checkbox" (change)="selectAllNodes($event, belt.id)">
            <label for="n_{{ belt.id }}" class="form-check-label">
              Noeuds
            </label>
          </div>
        </div>

        <!-- Elements -->
        <ul [ngbCollapse]="belt.nodesCollapsed">
          <li *ngFor="let node of belt.nodes">
            <div class="form-check">
              <input id="{{ node.id }}" class="form-check-input" type="checkbox" [(ngModel)]="node.selected" (ngModelChange)="onTreeSelectedChange()">
              <label for="{{ node.id }}" class="form-check-label">
                {{ node.calculatedTitle }}
              </label>
            </div>
          </li>
        </ul>

      </li>

      <!-- Paths -->
      <li>

        <!-- Title -->
        <div class="d-flex">
          <i class="fa fa-caret-down pt-1 pr-3" [ngClass]="{ 'fa-caret-right': belt.pathsCollapsed }" (click)="belt.pathsCollapsed = !belt.pathsCollapsed"></i>
          <div class="form-check">
            <input id="p_{{ belt.id }}" class="form-check-input" type="checkbox" (change)="selectAllPaths($event, belt.id)">
            <label for="p_{{ belt.id }}" class="form-check-label">
                Liens
            </label>
          </div>
        </div>

        <!-- Elements -->
        <ul [ngbCollapse]="belt.pathsCollapsed">
          <li *ngFor="let path of belt.paths">
            <div class="form-check">
              <input id="{{ path.id }}" class="form-check-input" type="checkbox" [(ngModel)]="path.selected" (ngModelChange)="onTreeSelectedChange()">
              <label for="{{ path.id }}" class="form-check-label">
                {{ path.calculatedTitle }}
              </label>
            </div>
          </li>
        </ul>
        
      </li>

      <!-- Protected Areas -->
      <li>

        <!-- Title -->
        <div class="d-flex">
          <i class="fa fa-caret-down pt-1 pr-3" [ngClass]="{ 'fa-caret-right': belt.protectedAreasCollapsed }" (click)="belt.protectedAreasCollapsed = !belt.protectedAreasCollapsed"></i>
          <label class="mb-0">Zones protégées</label>
        </div>

        <!-- Elements -->
        <ul [ngbCollapse]="belt.protectedAreasCollapsed">
          <li *ngFor="let protectedArea of belt.protectedAreas">
            <div class="form-check">
              <input id="{{ protectedArea.id }}" class="form-check-input" type="checkbox" [checked]="protectedArea.id == gdp.version.selectedProtectedArea" (change)="selectProtectedArea($event, protectedArea.id)">
              <label for="{{ protectedArea.id }}" class="form-check-label">
                {{ protectedArea.name }}
              </label>
            </div>
          </li>
        </ul>

      </li>
    </ul>
  </li>

</ul>