import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Bottle } from 'src/app/models/management/bottle';
import { ConfirmationModal } from 'src/app/pages/dashboard/confirmationModal/confirmationModal';
import { BottleEventsModalComponent } from 'src/app/pages/stocks/stock/bottle-events/bottle-events.component';
import { BottleService } from 'src/app/services/management/bottle.service';

@Component({
  selector: 'bottles-table',
  templateUrl: './bottles-table.component.html',
  styleUrls: ['./bottles-table.component.css']
})
export class BottlesTableComponent implements OnInit, AfterViewInit {
  @Input() bottles: Bottle[] = [];
  @Input() displayedColumns = ["select", "barcode", "status", "state", "type", "action"];
  @Input() selectedBottles!: Bottle[];
  @Input() permitReturnToSupplier = false;
  @Output() selectedBottlesChange = new EventEmitter<Bottle[]>();

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<Bottle>(this.bottles);
  selection = new SelectionModel<Bottle>(true, []);

  pageSizeOptions = [10, 50, 100];

  constructor(
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private bottleService: BottleService,
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<Bottle>(this.bottles);
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<Bottle>(true, []);
    this.selection.changed.subscribe(s => {
      this.selectedBottles = this.selection.selected;
      this.selectedBottlesChange.emit(this.selection.selected);
    });
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'type': return item.bottleType?.designationString;
        case 'location': return item.location.name;
        case 'site': return item.location.name;
        case 'upcv3': return  item.upcv3?.upcNameId;
        case 'groupUPCV3': return  item.groupUPCV3?.name;
        default: return item[property];
      }
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.bottles?.currentValue) {
      this.dataSource = new MatTableDataSource<Bottle>(changes.bottles.currentValue);
      this.dataSource.paginator = this.paginator;
      this.selection.clear();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Bottle): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.barcode + 1}`;
  }

  /* Search */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter =  filterValue.trim().toLowerCase();
  }

  openBottleEventsModal(bottle: Bottle) {
    var bottleEventsModalRef = this.modalService.open(BottleEventsModalComponent, { size: 'lg' });
    bottleEventsModalRef.componentInstance.init(bottle, bottleEventsModalRef, () => { })
  }

  async openReturnToSupplier(bottle: Bottle) {
    const modalRef = this.modalService.open(ConfirmationModal, { size: 'lg' });
    modalRef.componentInstance.title = "Confirmez-vous le renvoi de la bouteille au fournisseur ?";
    modalRef.componentInstance.body =
      `Voulez-vous renvoyer la bouteille ${bottle.barcode} | ${bottle.bottleType.designationString} ?`;
    const result = await modalRef.result;
    if (result) {
      this.bottleService.returnToSupplier(bottle.id).subscribe({
        next: () => {
          this.toasterService.pop("success", "Succès", "La bouteille a bien été renvoyée au fournisseur.");
          this.bottles.splice(this.bottles.indexOf(bottle), 1);
          this.ngOnInit();
        },
        error: (err) => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors du retour de la bouteille au fournisseur.")
      });
    }
  }
}
