import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Stock } from '../../models/management/stock';
import { StockService } from '../../services/management/stock.service';
import { AddStockModalComponent } from './add-stock/add-stock.component';
import { EditStockModalComponent } from './edit-stock/edit-stock.component';
import { DeleteStockModalComponent } from './delete-stock/delete-stock.component';
import { BottleType } from '../../models/management/bottleType';
import { BottleTypeService } from '../../services/management/bottleType.service';
import { AddBottleTypeModalComponent } from './add-bottle-type/add-bottle-type.component';
import { DeleteBottleTypeModalComponent } from './delete-bottle-type/delete-bottle-type.component';
import { AuthService } from '../../auth/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { BottleService } from 'src/app/services/management/bottle.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { Bottle, status } from 'src/app/models/management/bottle'
import { ToasterService } from 'angular2-toaster';
import { LocationType } from 'src/app/models/management/location';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html'
})
export class StocksComponent implements OnInit, AfterViewInit {
  // NF
  stocks: Stock[];
  bottleTypes: BottleType[];
  clientStock: UPCV3[];
  customerBottles : Bottle[];
  onSiteBottles : Bottle[];
  returnedBottles: Bottle[];
  inTransitBottles: Bottle[];
  displayedColumnsOnSite = ["barcode", "type", "state", "status", "site", "action"];
  displayedColumnsReturned = ["barcode", "type", "state", "status", "action"];
  displayedColumnsInTransit = ["barcode", "type", "state", "status", "action"]
  displayedColumnsCustomer = ['barcode', 'type', 'state', 'status', 'site', 'upcv3', 'groupUPCV3', 'action'];
  private onDestroy$: Subject<void> = new Subject<void>();
  pager: any = {};
  pageSizes = [
    { value: 10, label: 'Afficher 10 Ceintures Client' },
    { value: 50, label: 'Afficher 50 Ceintures Client' },
    { value: 100, label: 'Afficher 100 Ceintures Client' },
    { value: 1000, label: 'Afficher Tout' }
  ];
  pageSize = 10;
  currentPage = 0;
  pageSizesFourn = [{ value: 5, label: 'Afficher 5 Bouteilles' },
  { value: 50, label: 'Afficher 50 Bouteilles' },
  { value: 100, label: 'Afficher 100 Bouteilles' },
  { value: 1000, label: 'Afficher Toutes Les Bouteilles' }]
  pageSizeFourn = 5;
  // Search
  searchForm: FormGroup = new FormGroup({
    filter: new FormControl()
  });
  searchFormSubmitted = false;
  filter = "";

  constructor(
    private stockService: StockService,
    private bottleTypesService: BottleTypeService,
    public authService: AuthService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private bottleService: BottleService,
  ) { }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  ngOnInit() {
    // Load bottleTypes
    this.loadBottlesTypes();
    // Load stocks
    this.loadStocks();
  }

  ngAfterViewInit() {
    this.loadCustomerBottles();
    this.loadReturnedBottles();
    this.loadInTransitBottles();
    this.loadOnSiteBottles();
  }

  loadReturnedBottles() {
    this.bottleService.getAll({ status: status.REVOKED, page: 0, size: 1000 }).subscribe({
      next: (res) => this.returnedBottles = res.content,
      error: (err) => this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors du chargement de bouteilles retournées aux fournisseurs")
    })
  }

  loadInTransitBottles() {
    this.bottleService.getAll({ status: status.IN_TRANSIT, page: 0, size: 1000 }).subscribe({
      next: (res) => this.inTransitBottles = res.content,
      error: (err) => this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors du chargement de bouteilles en transit")
    })
  }

  loadCustomerBottles() {
    forkJoin([
      this.bottleService.getAll({ status: status.B1, page: 0, size: 1000 }),
      this.bottleService.getAll({ status: status.B2, page: 0, size: 1000 })
    ]).subscribe({
      next: ([b1, b2]) => {
        this.customerBottles = [...b1.content, ...b2.content];
    },
    error: (err) => this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la récupération des bouteilles chez les clients.")
  })
  }

  loadOnSiteBottles() {
    this.bottleService.getAll({ status: status.ENTREPOSE, locationType: LocationType.SITE, page: 0, size: 1000 }).subscribe({
      next: (res) => this.onSiteBottles = res.content,
      error: (err) => this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors du chargement des bouteilles stockées sur site")
    })
  }

  loadBottlesTypes() {
    this.bottleTypes = null;
    this.bottleTypesService.getAll().pipe(takeUntil(this.onDestroy$)).subscribe({
      next: res => this.bottleTypes = res,
      error: res => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des types de bouteilels")
    })
  }

  loadStocks() {
    this.stocks = null;
    this.stockService.getAll().pipe(takeUntil(this.onDestroy$)).subscribe({
      next: res => this.stocks = res,
      error: err => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des stocks")
    })
  }

  /* Modals */
  openAddStockModal() {
    var addStockModalRef = this.modalService.open(AddStockModalComponent);
    addStockModalRef.componentInstance.init(null, addStockModalRef, () => this.loadStocks())
  }

  openEditStockModal(stock: Stock) {
    var editStockModalRef = this.modalService.open(EditStockModalComponent);
    editStockModalRef.componentInstance.init(stock, editStockModalRef, () => this.loadStocks())
  }

  openDeleteStockModal(stock: Stock) {
    var deleteStockModalRef = this.modalService.open(DeleteStockModalComponent);
    deleteStockModalRef.componentInstance.init(stock, deleteStockModalRef, () => this.loadStocks())
  }

  openAddBottleTypeModal() {
    var addBottleTypeModalRef = this.modalService.open(AddBottleTypeModalComponent);
    addBottleTypeModalRef.componentInstance.init(null, addBottleTypeModalRef, () => this.loadBottlesTypes())
  }

  openDeleteBottleTypeModal(bottleType: BottleType) {
    var deleteBottleTypeModalRef = this.modalService.open(DeleteBottleTypeModalComponent);
    deleteBottleTypeModalRef.componentInstance.init(bottleType, deleteBottleTypeModalRef, () => this.loadBottlesTypes())
  }

  setPage(pageSize, currentPage, filter) {

  }

  get sf() { return this.searchForm.controls }
 

  clearSearch(event) {
    this.filter = '';
    if (event.target.value.length == 0)
      this.setPage(0, this.pageSize, this.filter);
  }

}