<div class="modal-header">
  <h4 class="modal-title">Associer une Ceinture</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Belts V3 -->
    <div class="form-group">
      <label>UPC-V3</label>
      <ng-select  [items]="upcv3"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  bindLabel="upcNameId"
                  bindValue="id"
                  formControlName="upcv3">
      </ng-select>
    </div>

    <!-- Belts V2 -->
    <div class="form-group">
      <label>UPC-V2</label>
      <ng-select  [items]="upcv2"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  bindLabel="nom"
                  bindValue="id"
                  formControlName="upcv2">
      </ng-select>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Associer</span>
    </button>
  </div>
</form>