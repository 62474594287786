import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { UPCV3Service } from '../../services/upcv3/upcv3.service';
import { UPCV3 } from '../../models/upcv3/upc/upcv3';
import { intensities, daysCodes, DaysCode } from 'src/app/models/upcv3/upc/diffCo2Program';
import { AlarmVariables } from './alarms/parameters-alarms.component';
import { ToasterService } from 'angular2-toaster';
import { Observable, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CO2_LITERS_TO_KG_RATIO } from '../../constants/co2';

@Component({
  selector: 'app-parameterbelts',
  templateUrl: './parameterbelts.component.html',
  styleUrls: ['./parameterbelts.component.css']
})
export class ParameterbeltsComponent implements OnInit {
  @Input('upcv3') upcv3: UPCV3[];
  belts: string;
  belts2: string;
  syncparam: Array<number> = [];
  vars: any;
  intensities: any[] = intensities;
  daysCodes: any[] = daysCodes;
  names: string = "";
  isSync: boolean = false;
  sync = [];
  upcs: number[]; //ids of the upcs we are working on

  alarmVariables: AlarmVariables;
  dataLoaded: boolean = false;

  constructor(
    private upcv3Service: UPCV3Service,
    private router: Router,
    private toasterService: ToasterService,
  ) {
    this.upcs = this.router.getCurrentNavigation()?.extras?.state?.data as number[];
    if (this.upcs == null) {
      this.upcs = []
    }
  }

  ngOnInit() {
    if (localStorage.getItem("sync") !== null) {
      this.sync = JSON.parse(localStorage.getItem("sync"));
    }
    for (const id of this.upcs) {
      this.syncparam.push(id)
    }
    this.belts = this.syncparam.join(",")

    if ((this.upcv3 == null || this.upcv3.length == 0) && this.upcs.length !== 0) {
      let obsArray : Array<Observable<UPCV3>> = []
      for(const id of this.upcs) {
        const obs = this.upcv3Service.get(id)
        obsArray.push(obs)
      }
      forkJoin(obsArray).subscribe({
        next: (retrievedUpc) => {
          this.upcv3 = retrievedUpc;
          this.loadBelts()
        },
        error: () => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération de ou des UPCs sélectionnées"),
      })
    } else {
      this.loadBelts();
    }
  }

  get needSync(): boolean {
    const alarmNeedSync = (Object.keys(this.alarmVariables) as Array<keyof typeof this.alarmVariables>).some(v => this.alarmVariables[v].changed);
    return this.vars.upcMode.changed || this.vars.daysCode_1.changed || this.vars.start_1.changed || this.vars.end_1.changed || this.vars.intensity_1.changed ||
      this.vars.daysCode_2.changed || this.vars.start_2.changed || this.vars.end_2.changed || this.vars.intensity_2.changed ||
      this.vars.daysCode_3.changed || this.vars.start_3.changed || this.vars.end_3.changed || this.vars.intensity_3.changed ||
      this.vars.daysCode_4.changed || this.vars.start_4.changed || this.vars.end_4.changed || this.vars.intensity_4.changed ||
      this.vars.daysCode_5.changed || this.vars.start_5.changed || this.vars.end_5.changed || this.vars.intensity_5.changed ||
      this.vars.daysCode_6.changed || this.vars.start_6.changed || this.vars.end_6.changed || this.vars.intensity_6.changed ||
      this.vars.daysCode_7.changed || this.vars.start_7.changed || this.vars.end_7.changed || this.vars.intensity_7.changed ||
      this.vars.daysCode_8.changed || this.vars.start_8.changed || this.vars.end_8.changed || this.vars.intensity_8.changed ||
      this.vars.daysCode_9.changed || this.vars.start_9.changed || this.vars.end_9.changed || this.vars.intensity_9.changed ||
      this.vars.daysCode_10.changed || this.vars.start_10.changed || this.vars.end_10.changed || this.vars.intensity_10.changed ||

      this.vars.sunrise_sign.changed || this.vars.sunrise_delay.changed || this.vars.sunrise_duration.changed || this.vars.sunrise_intensity.changed ||
      this.vars.sunset_sign.changed || this.vars.sunset_delay.changed || this.vars.sunset_duration.changed || this.vars.sunset_intensity.changed ||

      alarmNeedSync;
  }

  syncBelts() {
    this.isSync = true;
    for (var i = 0; i < this.syncparam.length; i++) {
      this.sync.push(this.vars);
    }
    this.upcv3Service.syncs(this.syncparam, { ...this.vars, ...this.alarmVariables }).pipe(
      finalize(() => this.isSync = false)
    ).subscribe({
      next: upcs => {
        this.toasterService.pop("success", "Sync", `Tâches de synchronisation lancées`);
      },
      error: err => {
        this.toasterService.pop("error", "Sync", `Une erreur s'est produite lors du lancement des tâches de synchronisation`);
      }
    })
  }

  loadBelts() {
    this.upcv3Service.getBeltsByIDs(this.belts).subscribe({
      next: (belts : UPCV3[]) => {
        this.upcv3 = belts;
        this.upcv3.forEach(upc => this.names = this.names + upc.upcNameId + ' ');
        this.loadVars();
      },
      error: (e) => {
        if(e.status === 409) {
          this.toasterService.pop('error', "Conflit", `L'action demandé a généré un conflit`)
        }
        else if(e.status === 404) {
          this.toasterService.pop('error', "Resources introuvable", `La ressources demandé n'existe pas`)
        } else {
          this.toasterService.pop('error', "Erreur", `Une erreur inconnue s'est produite.`)
        }
      }
    }

    )
  }

  loadVars() {
    this.vars = {
      // General
      upcMode: { value: this.upcv3[0].generalParameters.upcMode, changed: false },


      // CO2 Status
      co2ResActive: { value: this.upcv3[0].reservesParameters.co2ResActive, changed: false },
      co2ResActPrev: { value: this.upcv3[0].reservesParameters.co2ResActPrev },
      co2ResInactPrev: { value: this.upcv3[0].reservesParameters.co2ResInactPrev },
      co2ResType: { value: this.upcv3[0].reservesParameters.co2ResType, changed: false },

      co2Res1Status: { value: this.upcv3[0].reservesParameters.co2Res1Status, changed: false },
      co2Res1ActVol: { value: Math.round(this.upcv3[0].reservesParameters.co2Res1ActVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false }, // Convert litter to kg

      co2Res2Status: { value: this.upcv3[0].reservesParameters.co2Res2Status, changed: false },
      co2Res2ActVol: { value: Math.round(this.upcv3[0].reservesParameters.co2Res2ActVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false }, // Convert litter to kg


      // Adjustments
      co2ResActAdj: { value: this.upcv3[0].reservesParameters.co2ResActAdj, changed: false },
      upcDiffLvlAdj: { value: this.upcv3[0].diffusionParameters.upcDiffLvlAdj, changed: false },
      co2FlowRefAdj: { value: this.upcv3[0].generalParameters.co2FlowRefAdj, changed: false },

      calcRefFlowRate: { value: this.upcv3[0].generalParameters.co2FlowRefAdj * this.upcv3[0].diffusionParameters.upcDiffLvlAdj / 10 },

      co2PresOutRef1: { value: this.upcv3[0].generalParameters.co2PresOutRef1 / 1000, changed: false },
      co2PresOutRef2: { value: this.upcv3[0].generalParameters.co2PresOutRef2 / 1000, changed: false },
      co2PresOutRef3: { value: this.upcv3[0].generalParameters.co2PresOutRef3 / 1000, changed: false },
      co2PresOutRef4: { value: this.upcv3[0].generalParameters.co2PresOutRef4 / 1000, changed: false },
      co2PresOutRef5: { value: this.upcv3[0].generalParameters.co2PresOutRef5 / 1000, changed: false },
      co2PresOutRef6: { value: this.upcv3[0].generalParameters.co2PresOutRef6 / 1000, changed: false },
      co2PresOutRef7: { value: this.upcv3[0].generalParameters.co2PresOutRef7 / 1000, changed: false },
      co2PresOutRef8: { value: this.upcv3[0].generalParameters.co2PresOutRef8 / 1000, changed: false },
      co2PresOutRef9: { value: this.upcv3[0].generalParameters.co2PresOutRef9 / 1000, changed: false },
      co2PresOutRef10: { value: this.upcv3[0].generalParameters.co2PresOutRef10 / 1000, changed: false },


      // Programs
      daysCode_1: { value: DaysCode.Sunday, changed: false },
      start_1: { value: "00:00", changed: false },
      end_1: { value: "00:00", changed: false },
      intensity_1: { value: "0", changed: false },

      daysCode_2: { value: DaysCode.Sunday, changed: false },
      start_2: { value: "00:00", changed: false },
      end_2: { value: "00:00", changed: false },
      intensity_2: { value: "0", changed: false },

      daysCode_3: { value: DaysCode.Sunday, changed: false },
      start_3: { value: "00:00", changed: false },
      end_3: { value: "00:00", changed: false },
      intensity_3: { value: "0", changed: false },

      daysCode_4: { value: DaysCode.Sunday, changed: false },
      start_4: { value: "00:00", changed: false },
      end_4: { value: "00:00", changed: false },
      intensity_4: { value: "0", changed: false },

      daysCode_5: { value: DaysCode.Sunday, changed: false },
      start_5: { value: "00:00", changed: false },
      end_5: { value: "00:00", changed: false },
      intensity_5: { value: "0", changed: false },

      daysCode_6: { value: DaysCode.Sunday, changed: false },
      start_6: { value: "00:00", changed: false },
      end_6: { value: "00:00", changed: false },
      intensity_6: { value: "0", changed: false },

      daysCode_7: { value: DaysCode.Sunday, changed: false },
      start_7: { value: "00:00", changed: false },
      end_7: { value: "00:00", changed: false },
      intensity_7: { value: "0", changed: false },

      daysCode_8: { value: DaysCode.Sunday, changed: false },
      start_8: { value: "00:00", changed: false },
      end_8: { value: "00:00", changed: false },
      intensity_8: { value: "0", changed: false },

      daysCode_9: { value: DaysCode.Sunday, changed: false },
      start_9: { value: "00:00", changed: false },
      end_9: { value: "00:00", changed: false },
      intensity_9: { value: "0", changed: false },

      daysCode_10: { value: DaysCode.Sunday, changed: false },
      start_10: { value: "00:00", changed: false },
      end_10: { value: "00:00", changed: false },
      intensity_10: { value: "0", changed: false },

      sunset_sign: { value: "+", changed: false },
      sunset_delay: { value: "00:00", changed: false },
      sunset_duration: { value: "00:00", changed: false },
      sunset_intensity: { value: "0", changed: false },

      sunrise_sign: { value: "+", changed: false },
      sunrise_delay: { value: "00:00", changed: false },
      sunrise_duration: { value: "00:00", changed: false },
      sunrise_intensity: { value: "0", changed: false },

      // Extras
      upcTimeZone: { value: this.upcv3[0].generalParameters.upcTimeZone, changed: false },
      upcLanguage: { value: this.upcv3[0].generalParameters.upcLanguage, changed: false },
      upcTrapNum: { value: this.upcv3[0].generalParameters.upcTrapNum, changed: false },
      co2FlowRefTrap: { value: this.upcv3[0].generalParameters.co2FlowRefTrap, changed: false },
      co2FlowRefNom: { value: this.upcv3[0].generalParameters.co2FlowRefNom, changed: false },
      upcCo2PidProp: { value: this.upcv3[0].generalParameters.upcCo2PidProp, changed: false },
      upcCo2PidInteg: { value: this.upcv3[0].generalParameters.upcCo2PidInteg, changed: false },
      upcCo2PidDiff: { value: this.upcv3[0].generalParameters.upcCo2PidDiff, changed: false },

      comGsmName: { value: this.upcv3[0].communicationParameters.comGsmName, changed: false },
      comGsmPass: { value: this.upcv3[0].communicationParameters.comGsmPass, changed: false },
      comWiFiName: { value: this.upcv3[0].communicationParameters.comWiFiName, changed: false },
      comWiFiPass: { value: this.upcv3[0].communicationParameters.comWiFiPass, changed: false },
      comWifiApCh: { value: this.upcv3[0].communicationParameters.comWifiApCh, changed: false },
      comWebSrvUrl: { value: this.upcv3[0].communicationParameters.comWebSrvUrl, changed: false },
      comMdmApnId: { value: this.upcv3[0].communicationParameters.comMdmApnId, changed: false },
      comMdmApnId2: { value: this.upcv3[0].communicationParameters.comMdmApnId2, changed: false },
      comMdmApnUser: { value: this.upcv3[0].communicationParameters.comMdmApnUser, changed: false },
      comMdmApnPass: { value: this.upcv3[0].communicationParameters.comMdmApnPass, changed: false },

      co2PressInpOffs: { value: this.upcv3[0].diffusionParameters.co2PressInpOffs, changed: false },
      co2PressOutOffs: { value: this.upcv3[0].diffusionParameters.co2PressOutOffs, changed: false },
      co2FlowOffs: { value: this.upcv3[0].diffusionParameters.co2FlowOffs, changed: false }

    }
    this.alarmVariables = {
      // Alarms
      alrResLowEn: { value: this.upcv3[0].alarmsParameters.alrResLowEn, changed: false },
      alrResEmptyEn: { value: this.upcv3[0].alarmsParameters.alrResEmptyEn, changed: false },
      alrPresInpEn: { value: this.upcv3[0].alarmsParameters.alrPresInpEn, changed: false },
      alrPresOutEn: { value: this.upcv3[0].alarmsParameters.alrPresOutEn, changed: false },
      alrFlowAvgEn: { value: this.upcv3[0].alarmsParameters.alrFlowAvgEn, changed: false },
      alrPowBackEn: { value: this.upcv3[0].alarmsParameters.alrPowBackEn, changed: false },
      alrPowDownEn: { value: this.upcv3[0].alarmsParameters.alrPowDownEn, changed: false },

      alrResLowLevel: { value: this.upcv3[0].alarmsParameters.alrResLowLevel, changed: false },
      alrResEmptyFlow: { value: this.upcv3[0].alarmsParameters.alrResEmptyFlow, changed: false },
      alrPresInpTol: { value: this.upcv3[0].alarmsParameters.alrPresInpTol, changed: false },
      alrPresOutTol: { value: this.upcv3[0].alarmsParameters.alrPresOutTol, changed: false },
      alrFlowSetTol: { value: this.upcv3[0].alarmsParameters.alrFlowSetTol, changed: false },
      alrResEmptyTest: { value: this.upcv3[0].alarmsParameters.alrResEmptyTest, changed: false },
      alrSmsNum1: { value: this.upcv3[0].alarmsParameters.alrSmsNum1, changed: false },
      alrSmsNum2: { value: this.upcv3[0].alarmsParameters.alrSmsNum2, changed: false },
      alrSmsNum3: { value: this.upcv3[0].alarmsParameters.alrSmsNum3, changed: false },
      alrSmsNum4: { value: this.upcv3[0].alarmsParameters.alrSmsNum4, changed: false },
      alrSmsNum5: { value: this.upcv3[0].alarmsParameters.alrSmsNum5, changed: false },
    }

    this.dataLoaded = true;
  }

  setProgramHasChanged() {
    this.vars.daysCode_1.changed = true;
    this.vars.start_1.changed = true;
    this.vars.end_1.changed = true;
    this.vars.intensity_1.changed = true;
    this.vars.daysCode_2.changed = true;
    this.vars.start_2.changed = true;
    this.vars.end_2.changed = true;
    this.vars.intensity_2.changed = true;
    this.vars.daysCode_3.changed = true;
    this.vars.start_3.changed = true;
    this.vars.end_3.changed = true;
    this.vars.intensity_3.changed = true;
    this.vars.daysCode_4.changed = true;
    this.vars.start_4.changed = true;
    this.vars.end_4.changed = true;
    this.vars.intensity_4.changed = true;
    this.vars.daysCode_5.changed = true;
    this.vars.start_5.changed = true;
    this.vars.end_5.changed = true;
    this.vars.intensity_5.changed = true;
    this.vars.daysCode_6.changed = true;
    this.vars.start_6.changed = true;
    this.vars.end_6.changed = true;
    this.vars.intensity_6.changed = true;
    this.vars.daysCode_7.changed = true;
    this.vars.start_7.changed = true;
    this.vars.end_7.changed = true;
    this.vars.intensity_7.changed = true;
    this.vars.daysCode_8.changed = true;
    this.vars.start_8.changed = true;
    this.vars.end_8.changed = true;
    this.vars.intensity_8.changed = true;
    this.vars.daysCode_9.changed = true;
    this.vars.start_9.changed = true;
    this.vars.end_9.changed = true;
    this.vars.intensity_9.changed = true;
    this.vars.daysCode_10.changed = true;
    this.vars.start_10.changed = true;
    this.vars.end_10.changed = true;
    this.vars.intensity_10.changed = true;
    this.vars.sunset_sign.changed = true;
    this.vars.sunset_delay.changed = true;
    this.vars.sunset_duration.changed = true;
    this.vars.sunset_intensity.changed = true;
    this.vars.sunrise_sign.changed = true;
    this.vars.sunrise_delay.changed = true;
    this.vars.sunrise_duration.changed = true;
    this.vars.sunrise_intensity.changed = true;
  }

  /* Diffusions */
  getDateFromSeconds(value: number): string {
    return new Date(value).toLocaleString()
  }

  getHourMinutesFromSeconds(value: number): string {
    return new Date(value * 1000).toISOString().substr(11, 5)
  }

  getSecondsFromHourMinutes(value: string): number {
    var hour = parseInt(value.split(':')[0]), minute = parseInt(value.split(':')[1]);
    return hour * 3600 + minute * 60;
  }

  getSunriseStartStop(): string {
    return this.getHourMinutesFromSeconds(this.upcv3[0].diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value))) +
      ' - ' +
      this.getHourMinutesFromSeconds(this.upcv3[0].diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunrise_duration.value));
  }

  getSunsetStartStop(): string {
    return this.getHourMinutesFromSeconds(this.upcv3[0].diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value))) +
      ' - ' +
      this.getHourMinutesFromSeconds(this.upcv3[0].diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunset_duration.value));
  }
}
