<!-- Node -->
<div class="card" *ngIf="cad.showContextMenu && cad.selectedNodes.length == 1">
  <div class="card-header pl-1 pr-1 pt-0 pb-0">
    <b *ngIf="cad.mode == 'ALL'">Mode Global</b>
    <b *ngIf="isCO2Mode">Mode CO2</b>
    <b *ngIf="isElectricMode">Mode Électrique</b>
  </div>

  <div class="card-body pl-2 pr-2 pt-1 pb-1">
    <!-- Control Data -->
    <div *ngIf="!isElectricMode && isSelectedNodeControl">
      Écart de débit
      <ul class="mb-1" style="list-style-type: none; padding: 0;">
        <li style="display: inline-block; width: 50%;">min <a (click)="cad.selectNode(cad.version.getMinFlowPercentFromBelt(cad.selectedBelt.id).nodeId, false)"><b>{{ cad.version.getMinFlowPercentFromBelt(cad.selectedBelt.id).value.toFixed(3) }} %</b></a></li>
        <li style="display: inline-block; width: 50%; text-align: right;">max <a (click)="cad.selectNode(cad.version.getMaxFlowPercentFromBelt(cad.selectedBelt.id).nodeId, false)"><b>{{ cad.version.getMaxFlowPercentFromBelt(cad.selectedBelt.id).value.toFixed(3) }} %</b></a></li>
      </ul>
    </div>
    <div *ngIf="!isElectricMode && isSelectedNodeControl">
      Consommation quotidienne
      <ul class="mb-1" style="list-style-type: none; padding: 0;">
        <li style="display: inline-block; width: 50%;">min <b>{{ cad.version.getMinMaxDailyConsumption(cad.selectedBelt, false).toFixed(3) }} Kg</b></li>
        <li style="display: inline-block; width: 50%; text-align: right;">max <b>{{ cad.version.getMinMaxDailyConsumption(cad.selectedBelt, true).toFixed(3) }} Kg</b></li>
      </ul>
    </div>
    <div *ngIf="!isElectricMode && isSelectedNodeControl">
      Consommation mensuelle
      <ul class="mb-1" style="list-style-type: none; padding: 0;">
        <li style="display: inline-block; width: 50%;">min <b>{{ cad.version.getMinMaxMonthlyConsumption(cad.selectedBelt, false).toFixed(3) }} Kg</b></li>
        <li style="display: inline-block; width: 50%; text-align: right;">max <b>{{ cad.version.getMinMaxMonthlyConsumption(cad.selectedBelt, true).toFixed(3) }} Kg</b></li>
      </ul>
    </div>
    <div *ngIf="!isElectricMode && isSelectedNodeControl">
      Consommation saisonnière
      <ul class="mb-1" style="list-style-type: none; padding: 0;">
        <li style="display: inline-block; width: 100%;"><b>{{ cad.version.getSeasonalConsumption(cad.selectedBelt).toFixed(3) }} Kg</b></li>
      </ul>
    </div>
    
    <p class="m-0" *ngIf="!isElectricMode && !isSelectedNodeControl && !isSelectedNodeIntermediate">Écart de débit<b class="float-right ml-2">{{ cad.selectedNodes[0].flowPercent.toFixed(3) }} %</b></p>
    <p class="m-0" *ngIf="!isElectricMode && !isSelectedNodeControl && !isSelectedNodeIntermediate">Débit du piège<b class="float-right ml-2">{{ cad.selectedNodes[0].flowOut.toFixed(3) }} nl/mn</b></p>
    <p class="m-0" *ngIf="!isCO2Mode && !isSelectedNodeControl && !isSelectedNodeIntermediate">Ventilateur <small>(puissance d'aspiration)</small><b class="float-right ml-2">{{ (100 - cad.selectedNodes[0].powerLoss * 100).toFixed(3) }} %</b></p>
    
    <hr class="ml-0 mt-2 mb-2">
    
    <p class="m-0" *ngIf="!isCO2Mode">Tension<b class="float-right ml-2">{{ cad.selectedNodes[0].voltage.toFixed(3) }} V</b></p>
    <p class="m-0" *ngIf="!isElectricMode">Pression<b class="float-right ml-2">{{ cad.selectedNodes[0].pressure.toFixed(3) }} bars</b></p>
    <p class="m-0" *ngIf="!isElectricMode">Débit en entrée<b class="float-right ml-2">{{ cad.selectedNodes[0].flowIn.toFixed(3) }} nl/mn</b></p>
    <p class="m-0" *ngIf="!isElectricMode">Débit cumulé<b class="float-right ml-2">{{ cad.selectedNodes[0].flowCumulated.toFixed(3) }} nl/mn</b></p>
    <p class="m-0" *ngIf="!isElectricMode">Perte de pression cumulée<b class="float-right ml-2">{{ cad.selectedNodes[0].lossPressureCumulated.toFixed(3) }} bars</b></p>
  </div>

  <div class="card-footer p-0" *ngIf="isCO2Mode">
    <div class="btn-group-vertical w-100">
      <button type="button" class="btn btn-light btn-sm btn-block" (click)="cad.addNodeBefore()" *ngIf="!isSelectedNodeControl">Ajouter un noeud avant</button>
      <button type="button" class="btn btn-light btn-sm btn-block" (click)="cad.addNodeAfter()">Ajouter un noeud après</button>
      <button type="button" class="btn btn-light btn-sm btn-block" (click)="cad.unSelectNode()">Modifier la branche</button>

      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delNode()" *ngIf="!isSelectedNodeControl">Supprimer ce noeud</button>
      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delNodeAndChildren()" *ngIf="!isSelectedNodeControl">Supprimer à partir de ce noeud</button>
      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delBranch()">Supprimer cette branche</button>
    </div>
  </div>

  <div class="card-footer p-0" *ngIf="isElectricMode">
    <div class="btn-group-vertical w-100">
      <button type="button" class="btn btn-light btn-sm btn-block" [disabled]="cad.version.isNodeInElectricalBranch(cad.selectedNodes[0].id) || !cad.selectedTransformer" (click)="cad.addNodeToTransformer()">Ajouter au réseau</button>

      <button type="button" class="btn btn-danger btn-sm btn-block" [disabled]="!cad.version.isNodeInElectricalBranch(cad.selectedNodes[0].id)" (click)="cad.delNodeFromElectricalBranch()">Supprimer du réseau</button>
    </div>
  </div>
</div>

<!-- ElectricalOutlet -->
<div class="card" *ngIf="cad.showContextMenu && cad.selectedNodes.length == 0 && cad.selectedElectricalOutlet && !cad.selectedTransformer">
  <div class="card-header pl-1 pr-1 pt-0 pb-0">
    <b *ngIf="cad.mode == 'ALL'">Mode Global</b>
    <b *ngIf="cad.mode == 'CO2'">Mode CO2</b>
    <b *ngIf="cad.mode == 'ELECTRIC'">Mode Électrique</b>
  </div>

  <div class="card-footer p-0">
    <div class="btn-group-vertical w-100">
      <button type="button" class="btn btn-light btn-sm btn-block" (click)="cad.addTransformer()">Ajouter un transformateur</button>

      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delElectricalOutlet()">Supprimer cette alimentation</button>
    </div>
  </div>
</div>

<!-- Transformer -->
<div class="card" *ngIf="cad.showContextMenu && cad.selectedNodes.length == 0 && cad.selectedTransformer">
  <div class="card-header pl-1 pr-1 pt-0 pb-0">
    <b *ngIf="cad.mode == 'ALL'">Mode Global</b>
    <b *ngIf="cad.mode == 'CO2'">Mode CO2</b>
    <b *ngIf="cad.mode == 'ELECTRIC'">Mode Électrique</b>
  </div>

  <div class="card-body pl-2 pr-2 pt-1 pb-1">
    <p class="m-0">Puissance consommée<b class="ml-2 float-right">{{ cad.selectedTransformer.powerConsumed.toFixed(3) }} W</b></p>
  </div>

  <div class="card-footer p-0">
    <div class="btn-group-vertical w-100">
      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delTransformer()">Supprimer ce transformateur</button>
    </div>
  </div>
</div>

<!-- ProtectedAreaPoint -->
<div class="card" *ngIf="cad.showContextMenu && cad.selectedProtectedAreaPointIndex != null">
  <div class="card-header pl-1 pr-1 pt-0 pb-0">
    <b *ngIf="cad.mode == 'ALL'">Mode Global</b>
    <b *ngIf="cad.mode == 'CO2'">Mode CO2</b>
    <b *ngIf="cad.mode == 'ELECTRIC'">Mode Électrique</b>
  </div>

  <div class="card-footer p-0">
    <div class="btn-group-vertical w-100">
      <button type="button" class="btn btn-light btn-sm btn-block" (click)="cad.addProtectedAreaPointBefore()">Ajouter un point avant</button>
      <button type="button" class="btn btn-light btn-sm btn-block" (click)="cad.addProtectedAreaPointAfter()">Ajouter un point après</button>
      
      <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delSelectedProtectedAreaPoint()">Supprimer ce point</button>
    </div>
  </div>
</div>