import { jsonObject, jsonMember, TypedJSON } from 'typedjson';

@jsonObject
export class Task {
  @jsonMember
  title: string;

  @jsonMember
	type: Type;

  @jsonMember
  percent: number;
  
  static loadFromJSON(json): Task {
    let serializer = new TypedJSON(Task);
		return serializer.parse(json);
  }
}

export enum Type {
  FIRMWARE_UPDATE = "FIRMWARE_UPDATE",
  POOLING = "POOLING",
  INIT = "INIT",
  SYNC = "SYNC"
}