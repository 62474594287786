<!-- Belts -->
<div id="cad-belts" class="card">
  <div class="card-header p-2 font-weight-bold">Ceintures</div>
  <div class="card-body p-2">
    <ul class="list-group">
      <p class="list-group-item list-group-item-action pl-4 pr-4 pt-1 pb-1" *ngIf="cad.version.belts.length == 0">Aucune ceinture</p>
      <button type="button" class="list-group-item list-group-item-action pl-4 pr-4 pt-1 pb-1" *ngFor="let belt of cad.version.belts" (click)="cad.selectedBelt && cad.selectedBelt.id == belt.id ? cad.unSelectBelt() : cad.selectBelt(belt.id)" [ngClass]="{'active': cad.selectedBelt && cad.selectedBelt.id == belt.id}">
        {{ belt.name }}
      </button>
    </ul>
    <button type="button" class="btn btn-success btn-sm btn-block mt-3" (click)="addBelt()"><i class="fa fa-plus"></i></button>

    <hr class="ml-0" *ngIf="cad.selectedBelt">

    <p class="card-title" *ngIf="cad.selectedBelt">Zones protégées</p>
    <ul class="list-group mt-3" *ngIf="cad.selectedBelt">
      <p class="list-group-item list-group-item-action pl-4 pr-4 pt-1 pb-1" *ngIf="cad.selectedBelt.protectedAreas.length == 0">Aucune zone</p>
      <button type="button" class="list-group-item list-group-item-action pl-4 pr-4 pt-1 pb-1" *ngFor="let protectedArea of cad.selectedBelt.protectedAreas; let i = index" (click)="cad.selectedProtectedArea && cad.selectedProtectedArea.id == protectedArea.id ? cad.unSelectProtectedArea() : cad.selectProtectedArea(protectedArea.id)" [ngClass]="{'active': cad.selectedProtectedArea && cad.selectedProtectedArea.id == protectedArea.id}">
        Zone {{ i + 1 }}
      </button>
    </ul>
    <button type="button" class="btn btn-success btn-sm btn-block mt-3" (click)="addProtectedArea()" *ngIf="cad.selectedBelt"><i class="fa fa-plus"></i></button>
  </div>
</div>