<!-- Map Type-->
<div id="cad-map-type" class="btn-group" *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'">
  <button type="button" class="btn btn-light" (click)="cad.setMapType('satellite')" [ngClass]="{'active': cad.version.mapType == 'satellite'}">Satellite</button>
  <button type="button" class="btn btn-light" (click)="cad.setMapType('roadmap')" [ngClass]="{'active': cad.version.mapType == 'roadmap'}">Plan</button>
</div>

<!-- Tools -->
<div id="cad-tools" class="btn-group">
  <button type="button" class="btn btn-light"
          (click)="cad.setTool('DRAW')"
          [disabled]="!cad.selectedBelt"
          *ngIf="cad.mode == 'CO2' && cad.tool != 'DRAW' && cad.tool != 'SELECT_PIXELS_RATIO' && cad.tool != 'SELECT_PARENT_NODE'"
          placement="bottom" ngbTooltip="Dessiner une branche">
    <i class="fa fa-pencil"></i>
  </button>
  
  <button type="button" class="btn btn-light"
          (click)="addElectricalOutlet()"
          [disabled]="!cad.selectedBelt"
          *ngIf="cad.mode == 'ELECTRIC' && cad.tool != 'DRAW' && cad.tool != 'SELECT_PIXELS_RATIO' && cad.tool != 'SELECT_PARENT_NODE'"
          placement="bottom" ngbTooltip="Ajouter un point électrique">
    <i class="fa fa-plus"></i>
  </button>

  <button type="button"
          class="btn btn-light"
          (click)="cad.showDiffusionParameters = true"
          *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PIXELS_RATIO' && cad.tool != 'SELECT_PARENT_NODE'"
          placement="bottom" ngbTooltip="Paramètrer les diffusions">
    <i class="fa fa-cog"></i>
  </button>

  <button type="button" class="btn btn-light"
          (click)="cad.setTool('SELECT_PIXELS_RATIO')"
          *ngIf="cad.mode == 'ALL' && cad.project.customPicture && cad.tool != 'SELECT_PIXELS_RATIO'"
          placement="bottom" ngbTooltip="Modifier l'échelle">
    <i class="fa fa-edit"></i>
  </button>

  <input type="number" [(ngModel)]="cad.metersPixelsRatio" min="0" max="100" step="0.1" *ngIf="cad.tool == 'SELECT_PIXELS_RATIO'">
  <button type="button" class="btn btn-light" (click)="cad.validate()" *ngIf="cad.tool == 'DRAW' || cad.tool == 'SELECT_PIXELS_RATIO'"><b>Valider</b></button>
  <button type="button" class="btn btn-light" (click)="cad.abort()" *ngIf="cad.tool == 'DRAW' || cad.tool == 'SELECT_PARENT_NODE' || cad.tool == 'SELECT_PIXELS_RATIO'"><b>Annuler</b></button>

  <button type="button"
          class="btn btn-light"
          (click)="save()"
          *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'"
          placement="bottom" ngbTooltip="Enregistrer">
    <i class="fa fa-save"></i>
  </button>

  <div class="btn-group" ngbDropdown *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'">
    <button class="btn btn-light" ngbDropdownToggle><i class="fa fa-download"></i><span class="caret"></span></button>
    <div class="dropdown-menu" ngbDropdownMenu>
      <button type="button"
              class="dropdown-item"
              (click)="downloadExcelSheet()"
              placement="right" ngbTooltip="Télécharger le fichier excel">
        <i class="fa fa-table"></i> Excel
      </button>
      <button type="button"
              class="dropdown-item"
              (click)="showSaveImage()"
              placement="right" ngbTooltip="Télécharger l'image">
        <i class="fa fa-image"></i> Image
      </button>
      <button type="button"
              class="dropdown-item"
              (click)="saveGoogleEarth()"
              [disabled]="this.cad.project.customPicture"
              placement="right" ngbTooltip="{{ this.cad.project.customPicture ? 'Indisponible avec une image personnalisée' : 'Télécharger le fichier Google Earth' }}">
        <i class="fa fa-globe"></i> Google Earth
      </button>
    </div>
  </div>
</div>

<!-- Mode -->
<div id="cad-mode" class="btn-group" *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'">
  <button type="button"
          class="btn btn-light"
          (click)="cad.setMode('ALL')"
          [ngClass]="{'active': cad.mode == 'ALL'}"
          placement="bottom" ngbTooltip="Mode Global">
    <i class="fa fa-object-group"></i>
  </button>
  <button type="button"
          class="btn btn-light"
          (click)="cad.setMode('CO2')"
          [ngClass]="{'active': cad.mode == 'CO2'}"
          placement="bottom" ngbTooltip="Mode CO2">
    <i class="fa fa-database"></i>
  </button>
  <button type="button"
          class="btn btn-light"
          (click)="cad.setMode('ELECTRIC')"
          [ngClass]="{'active': cad.mode == 'ELECTRIC'}"
          placement="bottom" ngbTooltip="Mode Électrique">
    <i class="fa fa-bolt"></i>
  </button>
</div>