<canvas #backgroundImageCanvas id="background-image-canvas"></canvas>

<canvas #protectedAreasCanvas id="protected-areas-canvas"></canvas>

<canvas #beltsCanvas id="belts-canvas"></canvas>

<canvas #electricCanvas id="electric-canvas"></canvas>

<canvas #drawingCanvas id="drawing-canvas"
        (mousedown)="onMouseDown($event)"
        (touchstart)="onMouseDown($event)"
        (mousemove)="onMouseMove($event)"
        (touchmove)="onMouseMove($event)"
        (contextmenu)="onContextMenu($event)">
</canvas>