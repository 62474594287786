export class LocalDateTime {
  year: number = 1971;
  dayOfYear: number = 1;

  month: string = 'JANUARY';
  monthValue: number = 1;
  dayOfMonth: number = 1;

  dayOfWeek: string = 'FRIDAY';

  hour: number = 0;
  minute: number = 0;
  second: number = 0;
  nano: number = 0;
  chronology = {
    calendarType: "iso8601",
    id: 'ISO'
  }

  get daysFromNow(): number {
    return Math.round((new Date().getTime() - new Date(this.year, this.monthValue - 1, this.dayOfMonth, this.hour, this.minute, this.second).getTime()) / (1000 * 60 * 60 * 24));
  }

  get hoursFromNow(): number {
    return Math.round((new Date().getTime() - new Date(this.year, this.monthValue - 1, this.dayOfMonth, this.hour, this.minute, this.second).getTime()) / (1000 * 60 * 60));
  }

  get minutesFromNow(): number {
    return Math.round((new Date().getTime() - new Date(this.year, this.monthValue - 1, this.dayOfMonth, this.hour, this.minute, this.second).getTime()) / (1000 * 60));
  }

  static loadFromJSON(json): LocalDateTime {
    return Object.assign(new LocalDateTime, json)
  }

  get date(): Date {
    return new Date(this.year, this.monthValue - 1, this.dayOfMonth);
  }

  get completeDate() : Date {
    return new Date(this.year,this.monthValue - 1,this.dayOfMonth, this.hour, this.minute, this.second);
  }

  isBefore(date: LocalDateTime): boolean {
    return new Date(this.year, this.monthValue - 1, this.dayOfMonth) <= new Date(date.year, date.monthValue - 1, date.dayOfMonth);
  }

  public isAfter(date: LocalDateTime): boolean {
    return new Date(this.year, this.monthValue - 1, this.dayOfMonth) >= new Date(date.year, date.monthValue - 1, date.dayOfMonth);
  }

  static toJsDate(date: LocalDateTime): Date {
    return new Date(date.year, date.monthValue - 1, date.dayOfMonth, date.hour, date.minute, date.second)
  }

  static mostRecent(date1: LocalDateTime, date2: LocalDateTime) : LocalDateTime {
    const date1JSDate = new Date(date1.year, date1.monthValue - 1, date1.dayOfMonth);
    const date2JSDate = new Date(date2.year, date2.monthValue - 1, date2.dayOfMonth);
    if(date1JSDate > date2JSDate) {
      return date1
    }
    return date2;
  }

  isEqual(date: LocalDateTime): boolean {
    return new Date(this.year, this.monthValue - 1, this.dayOfMonth) == new Date(date.year, date.monthValue - 1, date.dayOfMonth);
  }

  toLocaleString(): string {
    return new Date(this.year, this.monthValue - 1, this.dayOfMonth, this.hour, this.minute, this.second).toLocaleString();
  }

  toLocaleDateString(): string {
    return new Date(this.year, this.monthValue - 1, this.dayOfMonth).toLocaleDateString();
  }

  static getLastDayOfMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }
}