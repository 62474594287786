
import { map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Injectable } from "@angular/core";
import {
  ContentChartDataWithMetadata,
  ContentChartDataWithoutMetadata,
  UPCV3Service,
} from "src/app/services/upcv3/upcv3.service";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { CalendarData } from "../groupStats.component";
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { ToasterService } from 'angular2-toaster';

interface ContentChartAggregated extends ContentChartDataWithoutMetadata {
  details: {
    b1: number;
    b2: number;
    upcNameid: string;
  }[];
}

interface ContentChartSeries {
  name: string;
  value: number;
  tooltip: {
    b1: number;
    b2: number;
    upcNameid: string;
  }[]; // arbitrary object
}

interface ContentChartFormatted {
  name: String;
  series: ContentChartSeries[];
}

@Component({
  selector: "app-upc-group-stats-content",
  templateUrl: "./contentStats.component.html",
  styleUrls: ["./contentStats.component.css"],
})
export class ContentStatsComponent implements OnInit, OnDestroy {
  @Input("upcv3Group") upcv3Group: GroupUPCV3;
  @Input("from") from: NgbDate;
  @Input("to") to: NgbDate;
  @Input() dateChanged: Observable<CalendarData>;

  private onDestroy$: Subject<void> = new Subject<void>();
  contentStats: any[];
  contentStatsGroup: any[] = [];
  contentChartData: any[] = [];
  private dateChangedSubscription: Subscription;

  constructor(private upcv3Service: UPCV3Service, private toasterService: ToasterService) { }

  ngOnInit(): void {
    this.initContentChartData();
    this.loadContentStats({ from: this.from, to: this.to });
    this.dateChangedSubscription = this.dateChanged.subscribe((calendarData) => {
      this.loadContentStats(calendarData);
    });
  }

  ngOnDestroy(): void {
    this.dateChangedSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  dateToString(date: NgbDate) {
    return (
      date.year +
      "-" +
      (date.month > 9 ? date.month : "0" + date.month) +
      "-" +
      (date.day > 9 ? date.day : "0" + date.day)
    );
  }

  initContentChartData(): void {
    for (const upc of this.upcv3Group.upcv3s) {
      this.contentChartData.push({
        upc: upc.upcNameId,
        name: `${upc.upcNameId} B1`,
        series: [],
      });
      this.contentChartData.push({
        upc: upc.upcNameId,
        name: `${upc.upcNameId} B2`,
        series: [],
      });
    }
  }

  loadContentStats(calendarData: CalendarData) {
    const dataObservaleArray = [];
    const groupContentData: Array<ContentChartDataWithMetadata> = [];
    const formattedChartData: Array<ContentChartFormatted> = [];

    for (const upc of this.upcv3Group.upcv3s) {
      dataObservaleArray.push(
        this.upcv3Service
          .getContentStats(
            upc.id,
            this.dateToString(calendarData.from),
            this.dateToString(calendarData.to)
          )
          .pipe(takeUntil(this.onDestroy$)).pipe(
            map((res) => {
              if (!Array.isArray(res)) {
                this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des statistiques de contenue");
                return;
              }
              groupContentData.push({
                upcNameId: upc.upcNameId,
                data: res,
              });
            }))
      );
    }

    forkJoin(dataObservaleArray).subscribe(() => {
      const lineChartDataRaw =
        this.chartContentDataAggregateGenerator(groupContentData);

      formattedChartData.push({ name: "B1", series: [] });
      formattedChartData.push({ name: "B2", series: [] });

      for (const data of lineChartDataRaw) {
        const date = new Date(data.date).toLocaleDateString();

        formattedChartData[0].series.push({
          name: date,
          value: data.b1,
          tooltip: data.details,
        });

        formattedChartData[1].series.push({
          name: date,
          value: data.b2,
          tooltip: data.details,
        });
      }

      this.contentChartData = [...formattedChartData];
      this.contentStats = [...formattedChartData]; // TODO supprimer cette ligne, après avoir retirer les checks sur template
    });
  }

  chartContentDataAggregateGenerator(
    upcsData: Array<ContentChartDataWithMetadata>
  ): Array<ContentChartAggregated> {
    // Les upcs doivent renvoyer le même nombre de point à afficher si nous voulons combiner ces informations correctement
    if (
      upcsData.some(
        (upcData) => upcData.data.length !== upcsData[0].data.length
      )
    ) {
      console.error(
        `data length mismatch - one upc of the group does not have the same amount of data than the others`
      );
      return [];
    }

    let dataAgrregated: Array<ContentChartAggregated> = [];

    // le nombre de points à aggréger est égal au nombre de points d'une upc, car nous savons que ce nombre est le même pour chaque upc.
    const nbDataToAggregate = upcsData[0].data.length;

    for (
      let indexDataToAggregate = 0;
      indexDataToAggregate < nbDataToAggregate;
      indexDataToAggregate++
    ) {
      let details: Array<any> = [];
      // Calcul le cumul de la valeur de contenu pour chaque ensemble de points de la liste des upcs du groupe
      const { b1, b2 } = upcsData.reduce(
        (prev, cur) => {
          return {
            b1: prev.b1 + cur.data[indexDataToAggregate].b1,
            b2: prev.b2 + cur.data[indexDataToAggregate].b2,
          };
        },
        { b1: 0, b2: 0 }
      );
      // construit l'objet détaillant les valeurs pour chaque point agrrégé, utilisé pour l'affichage du tooltip
      for (const upcData of upcsData) {
        details = [
          ...details,
          {
            b1: upcData.data[indexDataToAggregate].b1,
            b2: upcData.data[indexDataToAggregate].b2,
            upcNameId: upcData.upcNameId,
          },
        ];
      }
      dataAgrregated = [
        ...dataAgrregated,
        {
          ...upcsData[0].data[indexDataToAggregate],
          b1: b1,
          b2: b2,
          details: details,
        },
      ];
    }
    return dataAgrregated;
  }
}
