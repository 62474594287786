import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { Operator } from '../management/operator';
import { Site } from '../management/site';
import { UPCV3 } from './upc/upcv3';
import { GroupUPCV3 } from '../GroupUPCV3/GroupUPCV3';
@jsonObject
export class Commentaires {
  
  @jsonMember
  id: number;
  
  @jsonMember
  date: Date;
  
  @jsonMember
  message: string;
  
  @jsonMember
  objet: string;
  
  @jsonMember(() => Site)
  site: Site;
  
  @jsonMember(() => UPCV3)
  upcv3: UPCV3;

  @jsonMember(() => GroupUPCV3)
  groupUPCV3: GroupUPCV3
  
  @jsonMember(() => Operator)
  auteur: Operator;
  
  @jsonMember
  showDashboard: boolean;
  
  @jsonMember
  done: boolean;

  @jsonMember
  toBeDone: boolean;
  
  static loadFromJSON(json): Commentaires {
    let serializer = new TypedJSON(Commentaires);
		return serializer.parse(json);
  }
}