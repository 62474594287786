import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';

@Component({
  selector: 'restore-upc-content',
  templateUrl: './restore-upc.component.html'
})
export class RestoreUPCModalComponent {
  upcId: number;

  // Modal
  modalRef: NgbModalRef;
  callbackSuccess: Function;
  callbackError: Function;
  // Form
  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,

    private upcV3Service: UPCV3Service
  ) {

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  init(upcId: number, modalRef: NgbModalRef, callbackSuccess: Function, callbackError: Function) {
    this.upcId = upcId;
    this.modalRef = modalRef;
    this.callbackSuccess = callbackSuccess;
    this.callbackError = callbackError;
  }

  // Modal
  close() {
    this.modalRef.close()
  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try restore
    this.upcV3Service.restore(this.upcId).subscribe({
      next: () => {
        this.close();
        this.callbackSuccess()
      },
      error: () => {
        this.close();
        this.callbackError();
      }
    });
  }

}