import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-upcmcu-tests',
  templateUrl: './upcMcuTestList.component.html'
})
export class UpcMcuTestListComponent implements OnInit {
  @Input('upcMcu') upcMcu: any;

  constructor(
  ) { }

  ngOnInit() {
    // Load tests
    this.upcMcu.tests = [];
    for (const boxTest of this.upcMcu.boxTests) {
      this.upcMcu.tests.push({
        boxTest: boxTest,
        cardTest: null,
        date: boxTest.date
      });
    }

    for (const cardTest of this.upcMcu.cardTests) {
      this.upcMcu.tests.push({
        boxTest: null,
        cardTest: cardTest,
        date: cardTest.date
      });
    }

    this.upcMcu.tests.sort((a, b) => a.date > b.date ? -1 : 1); // most recent first


  }

  abs(number: number) {
    return Math.abs(number).toFixed(2);
  }

}
