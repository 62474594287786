<div class="modal-header">
  <h4 class="modal-title">Restorer l'UPC</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>
    
<div class="modal-body">
  <p>Êtes-vous certain de vouloir restorer cet UPC ?</p>
</div>

<div class="modal-footer">
  <input type="button" class="btn btn-secondary" value="Annuler" [disabled]="submitted" (click)="close()" ngbButton>
  <button type="button" class="btn btn-success" [disabled]="submitted" (click)="onFormSubmit()" ngbButton>
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="submitted"></i>
    <span *ngIf="!submitted">Restorer</span>
  </button>
</div>