import { Component, AfterViewInit, ViewChild, ElementRef, Input } from "@angular/core";

@Component({
  selector: 'diff-canvas',
  template: '<canvas #canvas width="775" height="275"></canvas>',
  styles: [
    'canvas { max-width: 100%; }'
  ]
})
export class DiffCanvasComponent implements AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  @Input('vars') vars: any;
  @Input('upcv3') upcv3: any;

  constructor() { }

  ngAfterViewInit() {
    this.draw();
  }

  public draw() {
    setTimeout(()=>{
        // Define vars
    var fontSize = 16;

    // Get context
    var context = this.canvas.nativeElement.getContext('2d');

    // Clean canvas
    context.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    context.save();

    // Draw legend
    this.drawLegend(context, fontSize);

    // Draw diff programs
    this.drawDiff(context, this.vars.start_1.value, this.vars.end_1.value, parseInt(this.vars.intensity_1.value), fontSize, 40); // P1
    this.drawDiff(context, this.vars.start_2.value, this.vars.end_2.value, parseInt(this.vars.intensity_2.value), fontSize, 60); // P2
    this.drawDiff(context, this.vars.start_3.value, this.vars.end_3.value, parseInt(this.vars.intensity_3.value), fontSize, 80); // P3
    this.drawDiff(context, this.vars.start_4.value, this.vars.end_4.value, parseInt(this.vars.intensity_4.value), fontSize, 100); // P4
    this.drawDiff(context, this.vars.start_5.value, this.vars.end_5.value, parseInt(this.vars.intensity_5.value), fontSize, 120); // P5
    this.drawDiff(context, this.vars.start_6.value, this.vars.end_6.value, parseInt(this.vars.intensity_6.value), fontSize, 140); // P6
    this.drawDiff(context, this.vars.start_7.value, this.vars.end_7.value, parseInt(this.vars.intensity_7.value), fontSize, 160); // P7
    this.drawDiff(context, this.vars.start_8.value, this.vars.end_8.value, parseInt(this.vars.intensity_8.value), fontSize, 180); // P8
    this.drawDiff(context, this.vars.start_9.value, this.vars.end_9.value, parseInt(this.vars.intensity_9.value), fontSize, 200); // P9
    this.drawDiff(context, this.vars.start_10.value, this.vars.end_10.value, parseInt(this.vars.intensity_10.value), fontSize, 220); // P10

    // Draw sun programs
    // Sunrise
    this.drawDiff(
      context,
      this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value))),
      this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunrise_duration.value)),
      parseInt(this.vars.sunrise_intensity.value),
      fontSize,
      250
    );

    // Sunset
    this.drawDiff(
      context,
      this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value))),
      this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunset_duration.value)),
      parseInt(this.vars.sunset_intensity.value),
      fontSize,
      270
    );

    context.restore();
    },500)
    
  }

  private drawLegend(context: any, fontSize: number) {
    context.font = 'bold ' + fontSize + 'px Arial';

    context.fillText('0',   145.5, fontSize);
    context.fillText('6',   295.5, fontSize);
    context.fillText('12',  440.5, fontSize);
    context.fillText('18',  590.5, fontSize);
    context.fillText('24',  740.5, fontSize);

    context.fillText('P1',  0, 40);
    context.fillText('P2',  0, 60);
    context.fillText('P3',  0, 80);
    context.fillText('P4',  0, 100);
    context.fillText('P5',  0, 120);
    context.fillText('P6',  0, 140);
    context.fillText('P7',  0, 160);
    context.fillText('P8',  0, 180);
    context.fillText('P9',  0, 200);
    context.fillText('P10', 0, 220);

    context.fillText('Aube', 0, 250);
    context.fillText('Crépuscule', 0, 270);

    context.strokeStyle = 'lightgrey';
    context.beginPath(); context.moveTo(150, 20); context.lineTo(150, 270); context.stroke();
    context.beginPath(); context.moveTo(300, 20); context.lineTo(300, 270); context.stroke();
    context.beginPath(); context.moveTo(450, 20); context.lineTo(450, 270); context.stroke();
    context.beginPath(); context.moveTo(600, 20); context.lineTo(600, 270); context.stroke();
    context.beginPath(); context.moveTo(750, 20); context.lineTo(750, 270); context.stroke();

    context.beginPath(); context.moveTo(150, 32); context.lineTo(750, 32); context.stroke();
    context.beginPath(); context.moveTo(150, 52); context.lineTo(750, 52); context.stroke();
    context.beginPath(); context.moveTo(150, 72); context.lineTo(750, 72); context.stroke();
    context.beginPath(); context.moveTo(150, 92); context.lineTo(750, 92); context.stroke();
    context.beginPath(); context.moveTo(150, 112); context.lineTo(750, 112); context.stroke();
    context.beginPath(); context.moveTo(150, 132); context.lineTo(750, 132); context.stroke();
    context.beginPath(); context.moveTo(150, 152); context.lineTo(750, 152); context.stroke();
    context.beginPath(); context.moveTo(150, 172); context.lineTo(750, 172); context.stroke();
    context.beginPath(); context.moveTo(150, 192); context.lineTo(750, 192); context.stroke();
    context.beginPath(); context.moveTo(150, 212); context.lineTo(750, 212); context.stroke();

    context.beginPath(); context.moveTo(150, 242); context.lineTo(750, 242); context.stroke();
    context.beginPath(); context.moveTo(150, 262); context.lineTo(750, 262); context.stroke();
    
  }

  private drawDiff(
    context: any,
    start: string,
    stop: string,
    intensity: number,
    fontSize: number,
    y: number) {
    var xAndWidth = this.getXAndWidth(start, stop);

    context.fillStyle = this.getIntensityColor(intensity);
    context.fillRect(xAndWidth.x, y - fontSize, xAndWidth.width, fontSize);
  }

  /* Methods */
  private getXAndWidth(start: string, stop: string) {
    // Check if start is > stop
    if (this.getSecondsFromHourMinutes(start) > this.getSecondsFromHourMinutes(stop)) return { x: 0, width: 0 };

    // Calculations
    var from = 600 * (this.getSecondsFromHourMinutes(start) / 86400),
        to = 600 * (this.getSecondsFromHourMinutes(stop) / 86400) - from;

    return { x: 150 + from, width: to };
  }

  private getSecondsFromHourMinutes(value: string): number {
    var hour = parseInt(value.split(':')[0]), minute = parseInt(value.split(':')[1]);
    return hour * 3600 + minute * 60;
  }

  private getHourMinutesFromSeconds(value: number): string {
    return new Date(value * 1000).toISOString().substr(11, 5)
  }

  private getIntensityColor(intensity: number) {
    switch (intensity) {
      case 0:   return 'rgba(0, 0, 0, 0)';
      case 1:   return 'rgba(0, 0, 0, .1)';
      case 2:   return 'rgba(0, 0, 0, .2)';
      case 3:   return 'rgba(0, 0, 0, .3)';
      case 4:   return 'rgba(0, 0, 0, .4)';
      case 5:   return 'rgba(0, 0, 0, .5)';
      case 6:   return 'rgba(0, 0, 0, .6)';
      case 7:   return 'rgba(0, 0, 0, .7)';
      case 8:   return 'rgba(0, 0, 0, .8)';
      case 9:   return 'rgba(0, 0, 0, .9)';
      case 10:  return 'rgba(0, 0, 0, 1)';
    }
  }

}
