<div class="row mt-3">

  <!-- Maps -->
  <div class="col-12 mb-3" [ngClass]="{'col-lg-8 col-xl-9': selectedSite}">
    <div class="card" style="position: relative;">

      <!-- loading -->
      <div style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, .5); z-index: 1;" *ngIf="!sites">
        <i class="fa fa-circle-o-notch fa-spin" style="position: absolute; left: 50%; top: 50%; color: white; font-size: 2rem;"></i>
      </div>

      <!-- Map -->
      <agm-map [latitude]="0" [longitude]="0" [zoom]="2" #AgmMap style="height: 70vh;" (mapClick)="selectedSite = null" (zoomChange)="refreshClusters($event)">
        
        <!-- Markers -->
        <agm-marker [latitude]="site.latitude"
                    [longitude]="site.longitude"
                    [iconUrl]="getSiteIcon(site)"
                    [label]="{ color: '#F00', fontSize: '50px', fontWeight: 'bold', text: getSiteComms(site) ? '*' : ' ' }"
                    *ngFor="let site of markers"
                    (markerClick)="showSite(site)">
        </agm-marker>

        <!-- Clusters -->
        <agm-marker [latitude]="cluster.lat"
                    [longitude]="cluster.lng"
                    [iconUrl]="getClusterIcon(cluster)"
                    [label]="{ color: '#FFF', fontWeight: 'bold', text: getClusterComms(cluster) ? cluster.sites.length + '*' : cluster.sites.length + '' }"
                    *ngFor="let cluster of clusters"
                    (markerClick)="clusterZoom(cluster)">
        </agm-marker>

      </agm-map>

    </div>
    <div class="card-footer text-muted">
      <small><i class="fa fa-info-circle mr-2"></i>Les données calculées pour les UPC-V2 sont automatiquement mise à jour toutes les heures</small>
      <br>
      <small>
        Légende
        <ul>
          <li>
            <b>Marqueurs</b> :
            <b class="text-success">Vert</b> : Réserve B1 active |
            <b class="text-warning">Orange</b> : Une des réserves est sous le seuil vide |
            <b class="text-danger">Rouge</b> : Les deux réserves sont sous le seuil vide |
            <b class="text-info">*</b> : La dernière communication a échoué
          </li>
        </ul>
      </small>
    </div>
  </div>

  <!-- Details -->
  <div class="col-lg-4 col-xl-3 mb-3" *ngIf="selectedSite">
    <div class="list-group">

      <!-- Site -->
      <a class="list-group-item list-group-item-action flex-column align-items-start" routerLink="/site/{{ selectedSite.id }}">{{ selectedSite.name }}</a>

      <!-- Empty -->
      <a class="list-group-item list-group-item-action flex-column align-items-start" *ngIf="!selectedSite.hasBelts">Aucune ceinture</a>

      <!-- UPC-V3 -->
      <a class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let upc of selectedSite.upcv3s" routerLink="/upc/v3/{{ upc.id }}">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ upc.upcNameId }}</h5>
          <small>UPC-V3</small>
        </div>

        <div class="row text-center mt-3 mb-4">

          <div class="col-md-4">
            <p class="mb-0">Reserve</p>
            <p class="p-1"><small><b>{{ upc.co2ResActiveString }}</b></small></p>
          </div>

          <div class="col-md-4">
            <p class="mb-0">B1</p>
            <p class="mb-0"><small><b>{{ upc.co2LitersToKg(upc.reservesParameters.co2Res1ActVol).toFixed(1) }} kg<br>({{ upc.reservesParameters.co2Res1ActDur }} jour)</b></small></p>
          </div>

          <div class="col-md-4">
            <p class="mb-0">B2</p>
            <p class="mb-0"><small><b>{{ upc.co2LitersToKg(upc.reservesParameters.co2Res2ActVol).toFixed(1) }} kg<br>({{ upc.reservesParameters.co2Res2ActDur }} jour)</b></small></p>
          </div>

          <div class="col-md-4">
            <p class="mb-0">Diffusion</p>
            <p class="p-1"><small [ngClass]="upc.upcStatusClass"><b>{{ upc.upcStatusString }}</b></small></p>
          </div>

        </div>
      </a>

      <!-- UPC-V2 -->
      <a class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let upc of selectedSite.upcv2" routerLink="/upc/v2/{{ upc.id }}">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ upc.nom }}</h5>
          <small>{{ upc.upc }}</small>
        </div>

        <div class="row text-center mt-3 mb-4">

          <div class="col-md-4">
            <p class="mb-0">Reserve</p>
            <p class="p-1" [ngClass]="upc.reserveActiveClass"><small><b>{{ upc.reserveActiveString }}</b></small></p>
          </div>

          <div class="col-md-4">
            <p class="mb-0">B1</p>
            <p class="mb-0"><small><b>{{ upc.contenuB1 }} kg<br>({{ upc.dureeB1 }} jour)</b></small></p>
          </div>

          <div class="col-md-4">
            <p class="mb-0">B2</p>
            <p class="mb-0"><small><b>{{ upc.contenuB2 }} kg<br>({{ upc.dureeB2 }} jour)</b></small></p>
          </div>

        </div>

        <small *ngIf="upc.lastIntervention">
          Dernière intervention
          <b class="float-right"><a routerLink="/intervention/v2/{{ upc.lastIntervention.id }}">{{ upc.lastInterventionString }}</a></b>
        </small>
        <small *ngIf="!upc.lastIntervention">Aucune intervention</small>
        
        <br>
        
        <small *ngIf="upc.lastEvenement">
          Dernier SMS
          <b class="float-right">
            <b class="float-right"><a routerLink="/upc/v2/{{ upc.id }}">{{ upc.lastEvenementString }}</a></b>
          </b>
        </small>
        <small *ngIf="!upc.lastEvenement">Aucun SMS</small>
      </a>

    </div>
  </div>

  <!-- Loading -->
  <div class="col-12 mb-3 text-center" *ngIf="!sites"><i class="fa fa-circle-o-notch fa-spin"></i></div>

</div>