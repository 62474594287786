import { Component, ViewChild, ElementRef, Output, EventEmitter, Input } from "@angular/core";
import { Node, Type as NodeType } from "src/app/models/gdp/node";
import { Path } from "src/app/models/gdp/path";
import { GDP } from "src/app/models/gdp/gdp";
import { ToasterService } from "angular2-toaster";
import { ProtectedArea } from "src/app/models/project/protectedArea";

@Component({
  selector: 'gdp-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: [ './context-menu.component.css' ]
})
export class ContextMenuComponent {
  // NF
  @Input('gdp') gdp: GDP;
  @Output('nodeDeleted') nodeDeleted = new EventEmitter<string>();
  @Output('pathDeleted') pathDeleted = new EventEmitter<string>();
  @Output('protectedAreaPointDeleted') protectedAreaPointDeleted = new EventEmitter<any>();

  @Output('addNodeRequested') addNodeRequested = new EventEmitter<any>();
  @Output('addProtectedAreaPointRequested') addProtectedAreaPointRequested  = new EventEmitter<any>();
  @Output('addProtectedAreaRequested') addProtectedAreaRequested  = new EventEmitter<any>();

  // Context menu
  @ViewChild('contextMenu', { static: false }) contextMenu: ElementRef;
  contextMenuItem: any;
  x: number = 0;
  y: number = 0;
  get isContextMenuItemNode(): boolean { return this.contextMenuItem && this.contextMenuItem instanceof Node }
  get isContextMenuItemPath(): boolean { return this.contextMenuItem && this.contextMenuItem instanceof Path }
  get isContextMenuItemProtectedAreaPoint(): boolean { return this.contextMenuItem && this.contextMenuItem.protectedArea && this.contextMenuItem.index != null }

  get pathLength(): number {
    if (this.isContextMenuItemPath) {
      return this.gdp.project.pixelsPerMeter * (<Path> this.contextMenuItem).distance;
    }

    return 0;
  }

  constructor(
    private toasterService: ToasterService
  ) { }

  public show(item: any, x: number, y: number) {
    this.contextMenuItem = item;
    this.contextMenu.nativeElement.style.display = 'block';
    this.contextMenu.nativeElement.style.top = y + 'px';
    this.contextMenu.nativeElement.style.left = x + 'px';
    this.x = x;
    this.y = y;
  }
  public hide() {
    this.contextMenuItem = null;
    this.contextMenu.nativeElement.style.display = 'none';
  }


  /*
   * Methods
   */
  deleteNode(id: string) {

    // Check if control
    var node = this.gdp.getNode(id);
    if (node.type == NodeType.CONTROL) this.toasterService.pop('error', 'Impossible de supprimer la régie');
    else this.nodeDeleted.emit(id);

  }
  deletePath(id: string) {
    this.pathDeleted.emit(id);
  }
  deleteProtectedAreaPoint(id: string, index: number) {
    this.protectedAreaPointDeleted.emit({ protectedAreaId: id, index: index });
  }

  addControl() {
    this.addNodeRequested.emit({ x: this.x, y: this.y, nodeType: NodeType.CONTROL });
  }
  addIntermediateNode() {
    if (this.gdp.version.selectedBelt) this.addNodeRequested.emit({ x: this.x, y: this.y, nodeType: NodeType.INTERMEDIATE });
    else this.toasterService.pop('warning', 'Impossible d\'ajouter le noeud', 'Aucune ceinture sélectionnée');
  }
  addTrap() {
    if (this.gdp.version.selectedBelt) this.addNodeRequested.emit({ x: this.x, y: this.y, nodeType: NodeType.HEXAGONAL_TRAP });
    else this.toasterService.pop('warning', 'Impossible d\'ajouter le piège', 'Aucune ceinture sélectionnée');
  }
  addProtectedArea() {
    if (this.gdp.version.selectedBelt) this.addProtectedAreaRequested.emit({ x: this.x, y: this.y, nodeType: NodeType.HEXAGONAL_TRAP });
    else this.toasterService.pop('warning', 'Impossible d\'ajouter la zone protégée', 'Aucune ceinture sélectionnée');
  }

  addProtectedAreaPointBefore(id: string, index: number) {
    this.addProtectedAreaPointRequested.emit({ protectedAreaId: id, index: index, before: true });
  }
  addProtectedAreaPointAfter(id: string, index: number) {
    this.addProtectedAreaPointRequested.emit({ protectedAreaId: id, index: index, before: false });
  }

}