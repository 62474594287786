import { Component, OnInit } from '@angular/core';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteUPCModalComponent } from './delete-upc/delete-upc.component';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-settings-delete-upcv3',
  templateUrl: './delete-upcv3.component.html'
})
export class DeleteUPCV3Component implements OnInit {
  // UPCV3
  upcv3: UPCV3[] = [];

  constructor(
    private upcv3Service: UPCV3Service,
    private modalService: NgbModal,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    // Load upcv3
    this.loadUPCV3();
  }

  // UPCV3
  loadUPCV3() {
    this.upcv3 = [];
    this.upcv3Service.getAll().subscribe({
      next: (res) => this.upcv3 = res,
      error: (err) => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des Upc");
        this.upcv3 = [];
      }
    })
  }

  openDeleteUPCV3Modal(upc: UPCV3) {
    var deleteUPCModalRef = this.modalService.open(DeleteUPCModalComponent);
    deleteUPCModalRef.componentInstance.init(upc, deleteUPCModalRef, () => {
      this.loadUPCV3()
    })
  }
}