import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';

@Component({
  selector: 'delete-upc-content',
  templateUrl: './delete-upc.component.html'
})
export class DeleteUPCModalComponent extends Modal<UPCV3> {
  // Form
  submitted = false;

  constructor(
    private router: Router,
    private upcv3Service: UPCV3Service
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => { this.close() });

  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.upcv3Service.delete(this.subject.id).subscribe(
      () => this.close(true),
      () => this.close(true)
    );
  }

}