<div class="container-fluid">
  <div class="row mt-3" *ngIf="stock">
    <div class="col-12 mb-3 text-center" *ngIf="!stock && !ceint">
      <i class="fa fa-circle-o-notch fa-spin"></i>
    </div>

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left" *ngIf="stock">Entrepôt - {{ stock.name }}</h4>
      <h4 class="float-left" *ngIf="!stock">
        Ceinture - {{ ceint.upcNameId }}
      </h4>

      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="openAddBottleModal()"
      >
        <i class="fa fa-plus"></i> Ajouter
      </button>
    </div>

    <!-- Prices cards -->
    <div class="col-12 col-md-6 mb-2">
      <div class="card">
        <div class="card-body px-3 py-2">
          Bouteilles pleines
          <h5 class="card-title float-right mb-0" *ngIf="stock">
            {{ stock.fullBottlesNumber }}
          </h5>
          <h5 class="card-title float-right mb-0" *ngIf="!stock">
            {{ ceint.fullBottlesNumber }}
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 mb-2">
      <div class="card">
        <div class="card-body px-3 py-2">
          Bouteilles vides
          <h5 class="card-title float-right mb-0" *ngIf="stock">
            {{ stock.emptyBottlesNumber }}
          </h5>
          <h5 class="card-title float-right mb-0" *ngIf="!stock">
            {{ ceint.emptyBottlesNumber }}
          </h5>
        </div>
      </div>
    </div>

    <div class="col-12 mb-3">
      <bottles-table [bottles]="bottles" [displayedColumns]="displayedColumnsBottles" [permitReturnToSupplier]="true" [(selectedBottles)]="selectedBottles"></bottles-table>
    </div>
    <div class="col-12 mb-3">
      <button type="button" class="btn btn-primary" (click)="openReturnToSupplier()" [disabled]="selectedBottles.length == 0">
        <i class="fa fa-rotate-left"></i> Renvoyer la sélection au fournisseur
      </button>
    </div>

    <div class="col-12 mb-3">
      <racks-table [racksOwner]="stock" [permitReturnToSupplier]="true"></racks-table>
    </div>
  </div>
</div>
