import { Component } from '@angular/core';
import { UPCV3Service } from '../../../../services/upcv3/upcv3.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';

@Component({
  selector: 'dissociate-belt-content',
  templateUrl: './dissociate-belt.component.html'
})
export class DissociateBeltModalComponent extends Modal<number> {
  // Form
  submitted = false;

  constructor(
    private router: Router,

    private toasterService: ToasterService,
    private upcv3Service: UPCV3Service,
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.upcv3Service.dissociate(this.subject).subscribe(
      () => this.close(true),
      () => this.close()
    )

  }

}