<div #contextMenu class="card gdp-context-menu">

  <!-- Back -->
  <ng-container *ngIf="!contextMenuItem">
    <div class="card-header text-center pl-1 pr-1 pt-0 pb-0">Ajouter</div>
    <div class="card-footer p-0">
      <div class="btn-group-vertical w-100">
        <button type="button" class="btn btn-light btn-sm btn-block" (click)="addControl()">Régie</button>
        <button type="button" class="btn btn-light btn-sm btn-block" (click)="addIntermediateNode()">Noeud intermédiaire</button>
        <button type="button" class="btn btn-light btn-sm btn-block" (click)="addTrap()">Piège</button>
        <button type="button" class="btn btn-light btn-sm btn-block" (click)="addProtectedArea()">Zone protégée</button>
      </div>
    </div>
  </ng-container>

  <!-- Node -->
  <ng-container *ngIf="isContextMenuItemNode">
    <div class="card-header text-center pl-1 pr-1 pt-0 pb-0">{{ contextMenuItem.calculatedTitle }}</div>
    <div class="card-footer p-0">
      <div class="btn-group-vertical w-100">
        <button type="button" class="btn btn-danger btn-sm btn-block" (click)="deleteNode(contextMenuItem.id)">Supprimer</button>
      </div>
    </div>
  </ng-container>

  <!-- Path -->
  <ng-container *ngIf="isContextMenuItemPath">
    <div class="card-header text-center pl-1 pr-1 pt-0 pb-0">{{ contextMenuItem.calculatedTitle }}</div>
    <div class="card-body">{{ pathLength.toFixed(2) }} mètres</div>
    <div class="card-footer p-0">
      <div class="btn-group-vertical w-100">
        <button type="button" class="btn btn-danger btn-sm btn-block" (click)="deletePath(contextMenuItem.id)">Supprimer</button>
      </div>
    </div>
  </ng-container>

  <!-- Protected Area -->
  <ng-container *ngIf="isContextMenuItemProtectedAreaPoint">
    <div class="card-header text-center pl-1 pr-1 pt-0 pb-0">{{ contextMenuItem.protectedArea.name }}</div>
    <div class="card-footer p-0">
      <div class="btn-group-vertical w-100">
        <button type="button" class="btn btn-light btn-sm btn-block" (click)="addProtectedAreaPointBefore(contextMenuItem.protectedArea.id, contextMenuItem.index)">Ajouter avant</button>
        <button type="button" class="btn btn-light btn-sm btn-block" (click)="addProtectedAreaPointAfter(contextMenuItem.protectedArea.id, contextMenuItem.index)">Ajouter après</button>
        <button type="button" class="btn btn-danger btn-sm btn-block" (click)="deleteProtectedAreaPoint(contextMenuItem.protectedArea.id, contextMenuItem.index)">Supprimer</button>
      </div>
    </div>
  </ng-container>

</div>