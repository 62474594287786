import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ApiResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { DefaultUPCV3Params } from 'src/app/models/upcv3/defaultUPCV3Params';
import { FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DefaultUPCV3ParamsService {

  constructor(
    private http: HttpClient,
  ) { }

  // Codes: DEFAULT_UPCV3_PARAMS_RECOVERED | UNAUTHORIZED
  public get(): Observable<DefaultUPCV3Params> {
    return this.http.get<ApiResponse<DefaultUPCV3Params>>(`${environment.apiUrl}upcv3/defaultParams`).pipe(map(
      res => DefaultUPCV3Params.loadFromJSON(res.result)
    ))
  }

  // Codes: DEFAULT_UPCV3_PARAMS_EDITED | UNAUTHORIZED
  public edit(form: FormGroup): Observable<DefaultUPCV3Params> {
    return this.http.post<ApiResponse<DefaultUPCV3Params>>(`${environment.apiUrl}upcv3/defaultParams`, form.value).pipe(map(
      res => DefaultUPCV3Params.loadFromJSON(res.result)
    ))
  }

}