import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';

@Component({
  selector: 'link-upcv3-content',
  templateUrl: './link-upcv3.component.html'
})
export class LinkUPCV3ModalComponent extends Modal<UPCV3> implements OnInit {
  unlinkeds: any[];

  // Form
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,

    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      upcMcuUid: [ null, Validators.required ]
    });

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  ngOnInit() {

    // Load upcv3
    this.upcv3Service.getUninitied().subscribe({
      next: res => this.unlinkeds = res,
      error: err =>{
        this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des UPC non liées");
        this.close();
      } 
    })
  }

  // Form
  get f() { return this.form.controls; }
  
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try create
    this.formSubmitted = true;
    this.upcv3Service.edit(this.subject.id, this.form).subscribe(
      () => this.close(true),
      () => this.close()
    )
  }

}