import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DefaultUPCV3ParamsService } from 'src/app/services/upcv3/defaultUPCV3Params.service';
import { ToasterService } from 'angular2-toaster';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-settings-default-params',
  templateUrl: './default-params.component.html'
})
export class DefaultParamsComponent implements OnInit {
  // Default UPC-V3 Params form
  upcv3Form: FormGroup;
  upcv3FormSubmitted = false;
  loading = true;

  constructor(
    private fb: FormBuilder,
    private defaultUPCV3ParamsService: DefaultUPCV3ParamsService,
    private toasterService: ToasterService
  ) {
    // Default UPC-V3 Params form
    this.upcv3Form = this.fb.group({
      co2FlowRefTrap: [ 0, Validators.required ],
      refFlowRateGperhour: [ 0, Validators.required ],

      sunriseDelay: [ 0, Validators.required ],
      sunriseDuration: [ 0, Validators.required ],
      sunriseIntensity: [ 1, Validators.required ],

      sunsetDelay: [ 0, Validators.required ],
      sunsetDuration: [ 0, Validators.required ],
      sunsetIntensity: [ 1, Validators.required ],

      daysCode1: [ 'All week', Validators.required ],
      startTime1: [ '00:00', Validators.required ],
      endTime1: [ '00:00', Validators.required ],
      intensity1: [ 1, Validators.required ],

      daysCode2: [ 'All week', Validators.required ],
      startTime2: [ '00:00', Validators.required ],
      endTime2: [ '00:00', Validators.required ],
      intensity2: [ 1, Validators.required ],

      alrResLowEn: [ false, Validators.required ],
      alrResEmptyEn: [ false, Validators.required ],
      alrPresInpEn: [ false, Validators.required ],
      alrPresOutEn: [ false, Validators.required ],
      alrFlowAvgEn: [ false, Validators.required ],
      alrPowBackEn: [ false, Validators.required ],
      alrPowDownEn: [ false, Validators.required ],

      alrEmptyPress: [ 0, Validators.required ],
      alrEmptyVol: [ 0, Validators.required ],
      alrPresInpTol: [ 0, Validators.required ],
      alrPresOutTol: [ 0, Validators.required ],
      alrFlowSetTol: [ 0, Validators.required ],
      alrResLowLevel: [ 0, Validators.required ],
      alrResEmptyFlow: [ 0, Validators.required ],
      alrSmsNum0: '',

      comWiFiName: [ '', Validators.required ],
      comWiFiPass: '',
      upcLanguage: [ 0, Validators.required ],
      comGsmName: '',
      comGsmPass: '',
      comWebSrvUrl: [ '', Validators.required ],

      upcCo2PidProp: [null, Validators.required ],
      upcCo2PidInteg: [null, Validators.required ],
      upcCo2PidDiff: [null, Validators.required ],
    });
  }

  ngOnInit() {
    // get default UPC-V3 Params
    this.defaultUPCV3ParamsService.get().pipe(
      finalize(() => this.loading = false)
    ).subscribe({
      next: (res) => {
        this.upcv3Form.patchValue(res);
      },
      error: (err) => this.toasterService.pop("error", "Erreur" , "Une erreur s'est produite lors de la récupération des paramètres par défaut d'UPC"),
    })
  }

  // User
  get uf() { return this.upcv3Form.controls; }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.upcv3Form.invalid)
      return;

    // Try edit
    this.upcv3FormSubmitted = true;
    this.defaultUPCV3ParamsService.edit(this.upcv3Form).pipe(
      finalize(() => this.upcv3FormSubmitted = false)
    ).subscribe();
  }
}