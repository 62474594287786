<div class="modal-header">
  <h4 class="modal-title">Modifier la Version</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Type -->
    <div class="form-group">
      <label for="type">Version *</label>
      <select class="custom-select" formControlName="type">
        <option value="PRE_ESTIMATE">Pré-devis</option>
        <option value="ESTIMATE">Devis</option>
        <option value="ACCEPTED_ESTIMATE">Devis-accepté</option>
        <option value="INSTALLATION">Installation</option>
        <option value="EXPLOITATION">Exploitation</option>
      </select>
    </div>

    <!-- NumberOrder -->
    <div class="form-group">
      <label for="numberOrder">N° Ordre</label>
      <input type="number" class="form-control" id="numberOrder" placeholder="N° Ordre" step="1" formControlName="numberOrder" [ngClass]="{ 'is-invalid': f.numberOrder.errors }">
      <div *ngIf="f.numberOrder.errors" class="invalid-feedback">
        <div *ngIf="f.numberOrder.errors && f.numberOrder.errors.required">N° Ordre obligatoire</div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Modifier</span>
    </button>
  </div>
</form>