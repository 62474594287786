<div class="modal-header">
  <h4 class="modal-title">Créer une ceinture UPC-V3</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<div class="modal-body">

  <!-- Project -->
  <div class="form-group">
    <label>Projet</label>
    <ng-select  class="select-hidden-dropdown" placeholder="Sélectionnez le projet"
                [items]="projects" bindLabel="name" bindValue="id"
                [searchFn]="fakeSearch" (keyup)="searchProjects($event.target.value)"
                [(ngModel)]="selectedProjectId" [ngModelOptions]="{ standalone: true }" (ngModelChange)="loadVersions()">
    </ng-select>
  </div>

  <!-- Version -->
  <div class="form-group">
    <label>Version</label>
    <ng-select  class="select-hidden-dropdown" placeholder="Sélectionnez la version"
                [items]="versions" bindLabel="versionTypeToString" bindValue="id"
                [disabled]="!selectedProjectId"
                [(ngModel)]="selectedVersionId" [ngModelOptions]="{ standalone: true }" (ngModelChange)="versionSelected()">
      <ng-template ng-label-tmp ng-option-tmp let-item="item">{{ item.versionTypeToString }} - {{ item.numberOrder }}</ng-template>
    </ng-select>
  </div>

  <!-- Belt -->
  <div class="form-group mb-0" *ngIf="version?.belts">
    <label>Ceinture</label>
    <ng-select  class="select-hidden-dropdown" placeholder="Sélectionnez la ceinture"
                [items]="version.belts" bindLabel="name" bindValue="id"
                [disabled]="!selectedVersionId"
                [(ngModel)]="selectedBeltId" [ngModelOptions]="{ standalone: true }" (ngModelChange)="beltSelected()">
      <ng-template ng-label-tmp ng-option-tmp let-item="item">{{ item.name }}</ng-template>
    </ng-select>
  </div>

  <hr class="ml-0" *ngIf="selectedBeltId">

  <!-- Inputs -->
  <ul class="list-group" *ngIf="selectedBeltId">

    <!-- General -->
    <li class="list-group-item">
      <h5 class="mb-3">Général</h5>
      <div class="form-row">

        <!-- upcNameId -->
        <div class="form-group col-md-6">
          <label>Identifiant</label>
          <input type="text" class="form-control" placeholder="Identifiant" [(ngModel)]="upcv3ToCreate.upcNameId">
        </div>

        <!-- upcTrapNum -->
        <div class="form-group col-md-6">
          <label>Nombre de pièges</label>
          <input type="number" class="form-control" placeholder="Nombre de pièges" [(ngModel)]="upcv3ToCreate.generalParameters.upcTrapNum">
        </div>

      </div>
    </li>

    <!-- Diffusion programs -->
    <li class="list-group-item">
      <h5 class="mb-3">Programmes de diffusion</h5>
      <ngb-tabset type="pills">

        <!-- Sunrise -->
        <ngb-tab title="Levé">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Sun; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Sunrise }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Sunset -->
        <ngb-tab title="Couché">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Sun; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Sunset }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 1 -->
        <ngb-tab title="P1">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program1 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 2 -->
        <ngb-tab title="P2">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program2 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 3 -->
        <ngb-tab title="P3">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program3 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 4 -->
        <ngb-tab title="P4">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program4 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 5 -->
        <ngb-tab title="P5">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program5 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 6 -->
        <ngb-tab title="P6">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program6 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 7 -->
        <ngb-tab title="P7">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program7 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 8 -->
        <ngb-tab title="P8">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program8 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 9 -->
        <ngb-tab title="P9">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program9 }"></ng-container>
          </ng-template>
        </ngb-tab>

        <!-- Program 10 -->
        <ngb-tab title="P10">
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: upcv3ToCreate.diffusionParameters.diffCo2Program10 }"></ng-container>
          </ng-template>
        </ngb-tab>

      </ngb-tabset>
    </li>

    <!-- Alarms -->
    <li class="list-group-item">
      <h5 class="mb-3">Alarmes</h5>
      <div class="row">

        <!-- Configuration -->
        <div class="col-md-6">

          <!-- alrResLowEn -->
          <div class="form-group">
            <label>Réserve active basse</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrResLowEn"></ui-switch>
          </div>

          <!-- alrResEmptyEn -->
          <div class="form-group">
            <label>Réserve active vide</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrResEmptyEn"></ui-switch>
          </div>

          <!-- alrPresInpEn -->
          <div class="form-group">
            <label>Pression d'entrée moyenne</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrPresInpEn"></ui-switch>
          </div>

          <!-- alrPresOutEn -->
          <div class="form-group">
            <label>Pression de sortie moyenne</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrPresOutEn"></ui-switch>
          </div>

          <!-- alrFlowAvgEn -->
          <div class="form-group">
            <label>Flux moyen</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrFlowAvgEn"></ui-switch>
          </div>

          <!-- alrPowBackEn -->
          <div class="form-group">
            <label>Alimentation rétablie</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrPowBackEn"></ui-switch>
          </div>

          <!-- alrPowDownEn -->
          <div class="form-group">
            <label>Alimentation coupée</label><br>
            <ui-switch [(ngModel)]="upcv3ToCreate.alarmsParameters.alrPowDownEn"></ui-switch>
          </div>

        </div>

        <!-- Parameters -->
        <div class="col-md-6">

          <!-- alrResLowLevel -->
          <div class="form-group">
            <label>Res. active : Seuil contenu (%)</label>
            <input type="number" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrResLowLevel">
          </div>

          <!-- alrResEmptyFlow -->
          <div class="form-group">
            <label>Res. active : Seuil flux (%)</label>
            <input type="number" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrResEmptyFlow">
          </div>

          <!-- alrPresInpTol -->
          <div class="form-group">
            <label>Res. : Seuil pression (%)</label>
            <input type="number" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrPresInpTol">
          </div>

          <!-- alrPresOutTol -->
          <div class="form-group">
            <label>Sortie : Seuil pression (%)</label>
            <input type="number" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrPresOutTol">
          </div>

          <!-- alrFlowSetTol -->
          <div class="form-group">
            <label>Débit : Seuil écart (%)</label>
            <input type="number" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrFlowSetTol">
          </div>

          <!-- alrSmsNum1 -->
          <div class="form-group mt-5">
            <label>N° tel. 1</label>
            <input type="tel" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrSmsNum1">
          </div>

          <!-- alrSmsNum2 -->
          <div class="form-group">
            <label>N° tel. 2</label>
            <input type="tel" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrSmsNum2">
          </div>

          <!-- alrSmsNum3 -->
          <div class="form-group">
            <label>N° tel. 3</label>
            <input type="tel" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrSmsNum3">
          </div>

          <!-- alrSmsNum4 -->
          <div class="form-group">
            <label>N° tel. 4</label>
            <input type="tel" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrSmsNum4">
          </div>

          <!-- alrSmsNum5 -->
          <div class="form-group">
            <label>N° tel. 5</label>
            <input type="tel" class="form-control" [(ngModel)]="upcv3ToCreate.alarmsParameters.alrSmsNum5">
          </div>

        </div>

      </div>
    </li>

    <!-- Communication -->
    <li class="list-group-item">
      <h5 class="mb-3">Communication</h5>
      
      <!-- WiFi -->
      <div class="form-row">

        <!-- comWiFiName -->
        <div class="form-group col-md-6">
          <label>Nom WiFi</label>
          <input type="text" class="form-control" placeholder="Nom WiFi" [(ngModel)]="upcv3ToCreate.communicationParameters.comWiFiName">
        </div>

        <!-- comWiFiPass -->
        <div class="form-group col-md-6">
          <label>Mot de passe WiFi</label>
          <input type="text" class="form-control" placeholder="Mot de passe SSID" [(ngModel)]="upcv3ToCreate.communicationParameters.comWiFiPass">
        </div>

      </div>

      <!-- Modem -->
      <div class="form-row">

        <!-- comGsmName -->
        <div class="form-group col-md-6">
          <label>Identifiant GSM</label>
          <input type="text" class="form-control" placeholder="Identifiant GSM" [(ngModel)]="upcv3ToCreate.communicationParameters.comGsmName">
        </div>

        <!-- comGsmPass -->
        <div class="form-group col-md-6">
          <label>Mot de passe GSM</label>
          <input type="text" class="form-control" placeholder="Mot de passe GSM" [(ngModel)]="upcv3ToCreate.communicationParameters.comGsmPass">
        </div>

      </div>

    </li>

  </ul>

</div>

<div class="modal-footer">
  <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
  <button type="button" class="btn btn-outline-success" [disabled]="formSubmitted" (click)="onFormSubmit()" ngbButton>
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
    <span *ngIf="!formSubmitted">Créer</span>
  </button>
</div>

<!-- Templates -->
<ng-template #diffCo2Sun let-program='program'>
  <div class="form-row mt-3">

    <!-- delay -->
    <div class="form-group col-lg-4">
      <label>Décallage +/- minutes</label>
      <input type="number" class="form-control" [(ngModel)]="program.delay" [ngModelOptions]="{ standalone: true }">
    </div>

    <!-- duration -->
    <div class="form-group col-lg-3">
      <label>Durée minutes</label>
      <input type="number" class="form-control" [(ngModel)]="program.duration" [ngModelOptions]="{ standalone: true }">
    </div>

    <!-- intensity -->
    <div class="form-group col-lg-5">
      <label>Intensité</label>
      <input  type="range" class="slider" min="0" max="10" list="tickmarks"
              [(ngModel)]="program.intensity" [ngModelOptions]="{ standalone: true }">
      <small class="form-text text-muted">Intensité : {{ program.intensity }}</small>
    </div>

  </div>
</ng-template>

<ng-template #diffCo2Program let-program='program'>
  <div class="form-row mt-3">

    <!-- daysCode -->
    <div class="form-group col-md-6 col-xl-4">
      <label>Mode de diffusion</label>
      <ng-select  class="select-hidden-dropdown"
                  [items]="daysCodes" bindLabel="name" bindValue="value"
                  [clearable]="false" [searchable]="false"
                  [(ngModel)]="program.daysCode" [ngModelOptions]="{ standalone: true }">
      </ng-select>
    </div>

    <!-- intensity -->
    <div class="form-group col-md-6 col-xl-4">
      <label>Intensité</label>
      <input  type="range" class="slider" min="0" max="10" list="tickmarks"
              [(ngModel)]="program.intensity" [ngModelOptions]="{ standalone: true }">
      <small class="form-text text-muted">Intensité : {{ program.intensity }}</small>
    </div>

    <!-- startTime -->
    <div class="form-group col-6 col-xl-2">
      <label>Début</label>
      <input type="time" class="form-control" [(ngModel)]="program.start" [ngModelOptions]="{ standalone: true }">
    </div>

    <!-- endTime -->
    <div class="form-group col-6 col-xl-2">
      <label>Fin</label>
      <input type="time" class="form-control" [(ngModel)]="program.end" [ngModelOptions]="{ standalone: true }">
    </div>

  </div>
</ng-template>