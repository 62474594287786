import { Component, OnInit } from '@angular/core';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { SiteService } from 'src/app/services/management/site.service';
import { Site } from 'src/app/models/management/site';
import { GroupUPCV3PostDto } from 'src/app/models/dto/GroupUPCV3Post.dto';

@Component({
  selector: 'app-groupebelt',
  templateUrl: './groupebelt.component.html',
  styleUrls: ['./groupebelt.component.css']
})
export class GroupebeltComponent implements OnInit {
  name: string = "";
  candidates: UPCV3[] = [];
  siteId: string;
  site: Site;
  selectedUpcsIds: number[] = [];
  formSubmitted = false;
  upcs: UPCV3[];

  constructor(
    private upcv3Service: UPCV3Service,
    private siteService: SiteService,
    private toasterService: ToasterService,
    private router: Router) {
      this.siteId = this.router.getCurrentNavigation().extras.state.data as string;
  }

  ngOnInit() {
    this.siteService.get(this.siteId).subscribe({
      error: (err) => this.toasterService.pop("error", 'Erreur', "Une erreur s'est produite lors de la récuparation du site"),
      next: (res) => {
        this.site = res;
        this.candidates = this.site.upcv3s
      }
    })
  }

  onCreateGroup() {
    this.formSubmitted = true;
    if (this.selectedUpcsIds.length >= 2 && this.name.length > 0){
      const formData : GroupUPCV3PostDto =  {
        name: this.name,
        upcv3s: [...this.selectedUpcsIds]
      }
      this.siteService.createGroup(this.site.id, formData).subscribe({
        error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la création du groupe"),
        next: () => {
          this.toasterService.pop("success", "Succès", `Le groupe ${this.name} a été crée avec succès`);
          this.formSubmitted = false;
          this.router.navigate([`site/${this.site.id}`]);
        }
      })
    }else {
      this.formSubmitted = false;
      this.toasterService.pop("warning", "Le nom du group n'est pas rempli ou il y a moins de deux UPC sélectionnées")
    }
  }
}
