import { ToasterService } from 'angular2-toaster';
import { status } from 'src/app/models/management/bottle';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BottleService } from '../../../../services/management/bottle.service';
import { Stock } from '../../../../models/management/stock';
import { BottleType } from '../../../../models/management/bottleType';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { BottlePostDto } from 'src/app/models/dto/BottlePost.dto';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BottlePostReceiveFromSupplierDto } from 'src/app/models/dto/BottlePostReceiveFromSupplier.dto';

@Component({
  selector: 'add-bottle-content',
  templateUrl: './add-bottle.component.html',
  styleUrls: ['./add-bottle.component.css']
})
export class AddBottleModalComponent extends Modal<Stock> implements OnInit {
  @ViewChild('barcodeInput', { static: false }) barcodeInput: ElementRef;

  // Form
  form: FormGroup;
  formSubmitted = false;
  invalidBarcode = false;

  bottleTypes: BottleType[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bottleService: BottleService,
    private toasterService: ToasterService,
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    // Form
    this.form = this.fb.group({
      barcodes: [[], Validators.required],
      bottleType: ['', Validators.required],
    });

    if (this.subject.forceBarcodesEnabled == false) {
      this.form.get('barcodes').removeValidators(Validators.required);
      this.form.get('barcodes').updateValueAndValidity();
      this.form.setControl('numberOfBottlesToCreate', this.fb.control(1, Validators.required));
    }

    this.bottleTypes = this.subject.bottleTypes;

    if (this.bottleTypes.length == 1) {
      this.form.patchValue({ bottleType: this.bottleTypes[0].id });
    }
  }

  addBarcode(barcode) {
    // Clear input
    this.barcodeInput.nativeElement.value = '';

    // Add to list
    var barcodes = this.form.get('barcodes').value;
    if (!barcodes) this.form.patchValue({ barcodes: [{ label: barcode }] });
    else {
      // Check if exists
      if (barcodes.findIndex(i => i.label == barcode) != -1) return;
      barcodes.push({ label: barcode });
      this.form.patchValue({ barcodes: barcodes });
    }
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    if (!this.form.value.barcodes || this.form.value.barcodes.length == 0 && this.subject.forceBarcodesEnabled) {
      return;
    }

    // Parse barcodes
    var barcodes: string[] = [];
    this.form.value.barcodes.forEach(barcode => barcodes.push(barcode.label));
    this.form.patchValue({ barcodes: barcodes });

    // Try create
    this.formSubmitted = true;

    
    if (this.subject.forceBarcodesEnabled) {
      let formsData: BottlePostReceiveFromSupplierDto[] = [];
      barcodes.forEach(barcode => {
        let createBottleData: BottlePostReceiveFromSupplierDto = {
          barcode: barcode,
          bottleType: this.form.value.bottleType,
        }
  
        formsData.push(createBottleData);
      });

      this.bottleService.receiveMultipleFromSupplier(this.subject.id, formsData).pipe(
        finalize(() => this.formSubmitted = false)
      ).subscribe({
        next: () => {
          this.toasterService.pop('success', "Bouteille(s) ajoutée(s) avec succès");
          this.initForm();
        },
        error: (err) => {
          this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de l'ajout de bouteille au site")
        }
      })
    } else {
      let formsData: BottlePostDto[] = [];

      for (let i = 0; i < this.form.value.numberOfBottlesToCreate; i++) {
        const createBottleData: BottlePostDto = {
          bottleType: this.form.value.bottleType,
          location: this.subject.id,
          status: status.ENTREPOSE
        }
        formsData.push(createBottleData);
      }

      let observables = formsData.map(form => this.bottleService.add(form));

      forkJoin(observables).pipe(
        finalize(() => this.formSubmitted = false)
      ).subscribe({
        next: () => {
          this.toasterService.pop('success', "Bouteille(s) ajoutée(s) avec succès");
          this.initForm();
        },
        error: (err) => {
          this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de l'ajout de bouteille au site")
        }
      })
    }
  }

}