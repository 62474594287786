<div class="modal-header">
  <h4 class="modal-title">Historique des Commentaires</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<div #scrollMe [scrollTop]="scrollMe.scrollHeight" class="modal-body" style="overflow:hidden; overflow-y:scroll;height:50vh;">
  <div *ngFor="let com of comments">
      
    <div class="col-12 mb-2 message other-message">
      <div>{{com.auteur}}<span class="float-right" *ngIf="com.objet === 'A Faire'" ><i *ngIf="com.done !== 1" (click)="setDone(com,i);" class="fa fa-square text-danger" style="font-size : x-large"></i><i *ngIf="com.done === 1" class="fa fa-check-circle text-success" style="font-size : x-large"></i></span></div>
      <span> {{com['mess']}}</span><br/>

      <div class="time" text-right>
        {{localeDateString(com['date'])}}
      </div>
      <br/>
    </div>
    
  </div>
</div>
