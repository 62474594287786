<div class="modal-header">
  <h4 class="modal-title">Wipe l'UPC</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>
    
<div class="modal-body">
  <p>Êtes-vous certain de vouloir Wipe cet UPC ?</p>
</div>

<div class="modal-footer">
  <input type="button" class="btn btn-secondary" value="Annuler" [disabled]="wipeSubmitted" (click)="close()" ngbButton>
  <button type="button" class="btn btn-success" [disabled]="wipeSubmitted" (click)="onFormSubmit()" ngbButton>
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="wipeSubmitted"></i>
    <span *ngIf="!wipeSubmitted">Wipe</span>
  </button>
</div>