import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Pages
import { OperatorsComponent } from "./operators.component";

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Modals
import { AddOperatorModalComponent } from "./add-operator/add-operator.component";
import { EditOperatorModalComponent } from "./edit-operator/edit-operator.component";
import { DeleteOperatorModalComponent } from "./delete-operator/delete-operator.component";

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule
  ],
  declarations: [
    // Pages
    OperatorsComponent,
    // Modals
    AddOperatorModalComponent,
    EditOperatorModalComponent,
    DeleteOperatorModalComponent
  ]
})
export class OperatorsModule { }