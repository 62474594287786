import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';

import { ApiResponse } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { map} from 'rxjs/operators';
import { FirmwareJob } from 'src/app/models/jobs/job';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService {

  constructor(
    private http: HttpClient,
  ) { }

  // Codes: FIRMWARE_RECOVERED | UNAUTHORIZED | INTERNAL_ERROR
  public getAll(): Observable<string[]> {
    return this.http.get<ApiResponse<string[]>>(environment.apiUrl + 'firmware/all').pipe(map(
      res => res.result
    ))
  }

  // Codes: FIRMWARE_UPLOADED | UNAUTHORIZED | WRONG_PARAMS | INTERNAL_ERROR
  public upload(form: FormGroup): Observable<ApiResponse<string[]>> {
    var data = new FormData();
    data.append('file', form.value.file);
    return this.http.post<ApiResponse<string[]>>(environment.apiUrl + 'firmware/upload', data)
  }

  // Codes: FIRMWARE_DELETED | UNAUTHORIZED
  public delete(fileName: string): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(environment.apiUrl + 'firmware/' + fileName);
  }

  // Codes: FIRMWARE_FLASH_STARTED | UNAUTHORIZED | WRONG_PARAMS | INTERNAL_ERROR
  public flashUpc(fileName: string, form: FormGroup): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(environment.apiUrl + 'firmware/' + fileName + '/flash', form.value);
  }

  public flashOneUpc(fileName: string, upcId: number) {
    return this.http.post<ApiResponse<any>>(environment.apiUrl + "firmware/" + fileName + "/flashOneUPC/" + upcId.toString(), {})
  }

  public getJobs(finished?: boolean): Observable<FirmwareJob[]> {
    const finishedQuery = finished == undefined ? "" : "?finished=" + finished;
    return this.http.get<ApiResponse<FirmwareJob[]>>(environment.apiUrl + "firmware/jobs" + finishedQuery, {}).pipe(map(
      res => res.result.map(r => FirmwareJob.loadFromJson(r))
    ))
  }

  public deleteJob(id: number): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(environment.apiUrl + "firmware/job/" + id, {})
  }

  public deleteJobs(ids: number[]): Observable<ApiResponse<any>> {
    return this.http.request<ApiResponse<any>>('delete', environment.apiUrl + "firmware/jobs", {
      body: ids
    });
  }

  public retryJob(id: number): Observable<ApiResponse<void>> {
    return this.http.get<ApiResponse<void>>(environment.apiUrl + "firmware/job/" + id + "/retryNow")
  }
}