export class ProtectedArea {
  id: string = '';
  name: string = '';
  width: number = 100;
  color: string = '#0A4A14';
  points: any[] = [];

  get inlinePoints(): string {
    var arr: string[] = [];
    this.points.forEach(point => {
      arr.push(point.x + ',' + point.y);
    });
    return arr.join(' ');
  }

  static loadFromJSON(json): ProtectedArea {
    var protectedArea: ProtectedArea = Object.assign(new ProtectedArea, json);
    if (json.points) {

      // Parse points
      var pointArray: any[] = [];
      json.points.split(';').forEach(point => {
        var pArray: any[] = point.split(',');
        if (pArray.length == 2) pointArray.push({ x: parseInt(pArray[0]), y: parseInt(pArray[1]) });
      });
      protectedArea.points = pointArray;

    }
    return protectedArea;
  }
}