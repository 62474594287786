<!-- Edit Belt -->
<div class="card" *ngIf="cad.selectedBelt && !cad.selectedBranch && !cad.selectedElectricalOutlet && !cad.selectedProtectedArea">
  <div class="card-header p-2 font-weight-bold">Modifier la Ceinture</div>
  <div class="card-body p-3">
    <div class="form-group">
      <label>Nom</label>
      <input type="text" class="form-control" placeholder="Nom" [value]="cad.selectedBelt.name" (change)="cad.setSelectedBeltName($event.target.value)">
    </div>

    <div class="form-group">
      <label>Couleur des pièges</label>
      <select class="custom-select" [value]="cad.selectedBelt.trapsColor" (change)="cad.setSelectedBeltTrapsColor($event.target.value)">
        <option value="WOOD">Naturel</option>
        <option value="BROWN">Marron Noir</option>
        <option value="GREEN">Vert Anglais</option>
      </select>
    </div>

    <hr class="ml-0">

    <div class="form-group">
      <label>Type de bouteille</label>
      <select class="custom-select" [value]="cad.selectedBelt.bottleType" (change)="cad.setSelectedBeltBottleType($event.target.value)">
        <option value="B10">10 Kg</option>
        <option value="B20">20 Kg</option>
        <option value="B34">34 Kg</option>
        <option value="B37">37.5 Kg</option>
        <option value="B50LB">50 Lb</option>
        <option value="R100">Rembo 100 Kg</option>
        <option value="R180">Rembo 180 Kg</option>
      </select>
    </div>

    <label>Nombre de bouteilles</label>
    <div class="row">
      <div class="col-6">
        <p>B1<b class="float-right">{{ cad.selectedBelt.bottleNumberB1 }}</b></p>
      </div>
      <div class="col-6">
        <p>B2<b class="float-right">{{ cad.selectedBelt.bottleNumberB2 }}</b></p>
      </div>
    </div>

    <label>Coûts de la bouteille</label>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>€ / Kg CO2</label>
        <input type="number" class="form-control" min="0" step="0.05" placeholder="Entrez le prix" [value]="cad.selectedBelt.co2KgPrice" (change)="cad.setSelectedBeltCo2KgPrice($event.target.value)">
      </div>
      <div class="form-group col-md-6">
        <label>€ / Mois</label>
        <input type="number" class="form-control" min="0" step="0.05" placeholder="Entrez le prix" [value]="cad.selectedBelt.co2MonthRentPrice" (change)="cad.setSelectedBeltCo2MonthRentPrice($event.target.value)">
      </div>
    </div>

    <hr class="ml-0">
    <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delBelt(cad.selectedBelt.id)">Supprimer</button>

  </div>
</div>

<!-- Edit Branch -->
<div class="card" *ngIf="cad.selectedBranch && cad.selectedNodes.length == 0 && !cad.selectedElectricalOutlet">
  <div class="card-header p-2 font-weight-bold">Modifier la Branche</div>
  <div class="card-body p-3">
    <div class="form-group">
      <label for="name">Largeur</label>
      <input  type="number" class="form-control" placeholder="Largeur" min="1" max="50"
              [value]="cad.selectedBranch.width"
              (change)="cad.setSelectedBranchWidth($event.target.value)">
    </div>

    <div class="form-group">
      <label for="name">Couleur</label>
      <input  type="color" class="form-control" placeholder="Couleur" style="height: 2.5rem;"
              [value]="cad.selectedBranch.color"
              (change)="cad.setSelectedBranchColor($event.target.value)">
    </div>

    <div class="form-group">
      <label for="name">Couleur des numéros</label>
      <input  type="color" class="form-control" placeholder="Couleur" style="height: 2.5rem;"
              [value]="cad.selectedBranch.numbersColor"
              (change)="cad.setSelectedBranchNumbersColor($event.target.value)">
    </div>

    <div class="form-group">
      <label for="name">Suffixe</label>
      <input  type="text" class="form-control" placeholder="Suffixe" maxlength="1"
              [value]="cad.selectedBranch.suffix"
              (keyup)="cad.setSelectedBranchSuffix($event.target.value)">
    </div>

    <hr class="ml-0">

    <div class="form-group">
      <label for="name">Diamètre intérieur du tube de CO2</label>
      <input  type="number" class="form-control" placeholder="Diamètre" min="1" max="10"
              [(ngModel)]="cad.selectedBranch.tubeDiameter"
              (change)="cad.setSelectedBranchTubeDiameter($event.target.value)">
    </div>

  </div>
</div>

<!-- Edit Control -->
<div class="card" *ngIf="cad.selectedNodes.length == 1 && cad.selectedNodes[0].nodeType == 'CONTROL' && !cad.selectedElectricalOutlet">
  <div class="card-header p-2 font-weight-bold">Modifier la Régie</div>
  <div class="card-body p-3">

    <div class="form-group">
      <label>Abri bois Régie petit modèle</label><br>
      <ui-switch [checked]="cad.selectedBelt.controlSheltered" (change)="cad.setSelectedBeltControlSheltered($event)"></ui-switch>
    </div>
    <div class="form-group">
      <label>Abri bois Régie grand modèle</label><br>
      <ui-switch [checked]="cad.selectedBelt.controlShelteredBig" (change)="cad.setSelectedBeltControlShelteredBig($event)"></ui-switch> 
    </div>

  </div>
</div>

<!-- Edit Node -->
<div class="card" *ngIf="cad.selectedNodes.length == 1 && cad.selectedNodes[0].nodeType != 'CONTROL' && !cad.selectedElectricalOutlet">
  <div class="card-header p-2 font-weight-bold">Modifier le Noeud</div>
  <div class="card-body p-3">
    <div class="form-group">
      <label for="name">Angle du numéro</label>
      <input  type="number" class="form-control" min="0" max="360" step="5" placeholder="Angle du numéro"
              [value]="cad.selectedNodes[0].textAngle"
              (change)="cad.setSelectedNodeTextAngle($event.target.value)">
    </div>

    <div class="form-group">
      <label for="name">Distance du numéro</label>
      <input  type="number" class="form-control" min="0" max="50" step="1" placeholder="Distance du numéro"
              [value]="cad.selectedNodes[0].textDistance"
              (change)="cad.setSelectedNodeTextDistance($event.target.value)">
    </div>

    <div class="form-group">
      <label for="nodeType">Type de noeud</label>
      <select class="custom-select" id="nodeType" [value]="cad.selectedNodes[0].nodeType" (change)="cad.setSelectedNodeType($event.target.value)">
        <option value="HEXAGONAL_TRAP">Piège héxagonal</option>
        <option value="RECTANGULAR_TRAP">Piège rectangulaire</option>
        <option value="INTERMEDIATE">Noeud intermédiaire</option>
      </select>
    </div>

    <div class="form-group">
      <label>Lien précédent enterré</label><br>
      <ui-switch [checked]="cad.selectedNodes[0].prevLinkBuried" (change)="cad.setSelectedNodePrevLinkBuried($event)"></ui-switch>
    </div>

    <hr class="ml-0" *ngIf="cad.version.getNodeIndex(cad.selectedNodes[0].id) == 0">
    <button type="button" class="btn btn-warning btn-sm btn-block"
            (click)="cad.selectParentNode()"
            *ngIf="cad.version.getBranchIndex(cad.selectedBranch.id) > 0 && cad.version.getNodeIndex(cad.selectedNodes[0].id) == 0">Sélectionner le noeud parent</button>
  </div>
</div>

<!-- Edit Nodes -->
<div class="card" *ngIf="cad.selectedNodes.length > 1 && !cad.selectedElectricalOutlet">
  <div class="card-header p-2 font-weight-bold">Modifier les Noeuds</div>
  <div class="card-body p-3">
    <div class="form-group">
      <label for="name">Angle des numéros</label>
      <input  type="number" class="form-control" min="0" max="360" step="5" value="0" placeholder="Angle du numéro" (change)="cad.setSelectedNodesTextAngle($event.target.value)">
    </div>

    <div class="form-group">
      <label for="name">Distance des numéros</label>
      <input  type="number" class="form-control" min="0" max="50" step="1" value="5" placeholder="Distance du numéro" (change)="cad.setSelectedNodesTextDistance($event.target.value)">
    </div>

    <div class="form-group">
      <label for="nodeType">Type de noeud</label>
      <select class="custom-select" id="nodeType" value="HEXAGONAL_TRAP" (change)="cad.setSelectedNodesNodeType($event.target.value)">
        <option value="HEXAGONAL_TRAP">Piège héxagonal</option>
        <option value="RECTANGULAR_TRAP">Piège rectangulaire</option>
        <option value="INTERMEDIATE">Noeud intermédiaire</option>
      </select>
    </div>

    <!-- Traps recalulations -->
    <hr class="ml-0" *ngIf="cad.selectedNodes.length == 2 && cad.version.getBranchFromNode(this.cad.selectedNodes[0].id) == cad.version.getBranchFromNode(this.cad.selectedNodes[1].id)">
    <button type="button" class="btn btn-warning btn-sm btn-block"
            (click)="cad.trapsRecalculations()"
            *ngIf="cad.selectedNodes.length == 2 && cad.version.getBranchFromNode(this.cad.selectedNodes[0].id) == cad.version.getBranchFromNode(this.cad.selectedNodes[1].id)">Recalculer les pièges</button>
  </div>
</div>

<!-- Edit Transformer -->
<div class="card" *ngIf="cad.selectedTransformer">
  <div class="card-header p-2 font-weight-bold">Modifier le Transformateur</div>
  <div class="card-body p-3">
    <div class="form-group">
      <label for="name">Angle du transformateur</label>
      <input  type="number" class="form-control" min="0" max="360" step="5" placeholder="Angle du transformateur"
              [value]="cad.selectedTransformer.angle"
              (change)="cad.setSelectedTransformerAngle($event.target.value)">
    </div>

    <div class="form-group">
      <label>Avec boîtier</label><br>
      <ui-switch [checked]="cad.selectedTransformer.electricCase" (change)="cad.setSelectedTransformerElectricCase($event)"></ui-switch>
    </div>
  </div>
</div>

<!-- Edit ProtectedArea -->
<div class="card" *ngIf="cad.selectedProtectedArea">
  <div class="card-header p-2 font-weight-bold">Modifier la Zone protégée</div>
  <div class="card-body p-3">
    <div class="form-group">
      <label for="name">Largeur</label>
      <input  type="number" class="form-control" min="10" max="500" step="5" placeholder="Largeur du motif"
              [value]="cad.selectedProtectedArea.width"
              (change)="cad.setSelectedProtectedAreaWidth($event.target.value)">
    </div>

    <div class="form-group">
      <label for="name">Couleur</label>
      <input  type="color" class="form-control" placeholder="Couleur" style="height: 2.5rem;"
              [value]="cad.selectedProtectedArea.color"
              (change)="cad.setSelectedProtectedAreaColor($event.target.value)">
    </div>

    <hr class="ml-0">
    <button type="button" class="btn btn-danger btn-sm btn-block" (click)="cad.delProtectedArea(cad.selectedProtectedArea.id)">Supprimer</button>
  </div>
</div>