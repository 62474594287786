import { Component, Input } from '@angular/core';
import { UUID } from 'angular2-uuid';
import * as FileSaver from 'file-saver';
import { ElectricalOutlet } from '../../../../../../models/project/electricalOutlet';
import { Version } from '../../../../../../models/project/version';
import { CAD } from '../../../../../../models/project/cad';
import { VersionService } from '../../../../../../services/project/version.service';
import { ToasterService } from 'angular2-toaster';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'cad-top-center',
  styleUrls: [ './top-center.component.css' ],
  templateUrl: './top-center.component.html'
})
export class CADTopCenterComponent {
  // NF
  @Input('cad') cad: CAD;

  constructor(
    private versionService: VersionService,
    private toasterService: ToasterService
  ) {}

  addElectricalOutlet() {
    var electricalOutlet = new ElectricalOutlet(UUID.UUID(), this.cad.project.lat, this.cad.project.lng);
    this.cad.selectedBelt.electricalOutlets.push(electricalOutlet);
    this.cad.selectElectricalOutlet(electricalOutlet.id);
  }

  downloadExcelSheet() {
    this.versionService.excelSheet(this.cad.version.id).subscribe(
      res => {
        // Save
        var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, "export.xlsx");

        // Update local prices
        this.versionService.get(this.cad.version.id).subscribe(
          res => {
            switch (res.code) {

              case Code.VERSION_RECOVERED:
              this.cad.version.materialPrice = (res.result as Version).materialPrice;
              this.cad.version.installationPrice = (res.result as Version).installationPrice;
              this.cad.version.maintenancePrice = (res.result as Version).maintenancePrice;
              break;
    
              case Code.VERSION_DOESNT_EXSIST:
              case Code.UNAUTHORIZED:
              break;
    
            }
          }
        )
      }
    )
  }

  save() {
    // Check if all branch have existing parentNode
    var error = false;
    this.cad.version.belts.forEach(belt => {
      belt.branches.forEach(branch => {
        var parentNode = this.cad.version.getNode(branch.parentNode);
        if (branch.parentNode && !parentNode) error = true;
      })
    });
    if (error) {
      this.toasterService.pop('error', 'Impossible d\'enregistrer, une ou plusieurs branches ne sont pas correctement reliées au réseau !');
      return;
    }

    // Save
    this.cad.save(this.cad.project.additionalDistanceSheath).then(() => {
      
      this.versionService.sync(this.cad.version).subscribe(
        res => {
          switch (res.code) {

            case Code.VERSION_SYNCHRONIZED:
            localStorage.removeItem('version_' + this.cad.version.id);
            break;

            case Code.VERSION_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
            break;
  
          }
        }
      )
    });
  }

  showSaveImage() {
    this.cad.dowloadImage = true;
    document.dispatchEvent(new Event('CADImageShown'));
  }

  saveGoogleEarth() {
    if (!this.cad.project.customPicture) {
      var blob = new Blob([this.cad.exportToKML()], { type: 'application/vnd.google-earth.kml+xml' });
      FileSaver.saveAs(blob, this.cad.project.name + '.kml');
    }
  }

}