import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Maps
import { AgmCoreModule } from '@agm/core';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';

// Pages
import { DashboardComponent } from "./dashboard.component";
import { MapComponent } from "./map/map.component";
import { UPCV3Component } from "./upcv3/upcv3.component";
import { UpcnoinitComponent } from './upcnoinit/upcnoinit.component';
import { CommentmodalComponent } from './commentmodal/commentmodal.component';
import { GroupebeltComponent } from './groupebelt/groupebelt.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { ConfirmationPollModalContent } from "./confirmationPollModal/confirmationPollModal";
import { ConfirmationModal } from './confirmationModal/confirmationModal';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TableModule } from 'primeng/table';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationPollModalGroupContent } from "./confirmationPollModalGroupe/confirmationPollModalGroup"
import { ToasterModule } from 'angular2-toaster';
import { DialogModule } from 'primeng/dialog';
import { SpeedDialModule } from 'primeng/speeddial';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import {AvatarModule} from 'primeng/avatar';
import { MultiSelectModule } from "primeng/multiselect";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    AppRoutingModule,
    AgmCoreModule,
    AgmMarkerClustererModule,
    FormsModule,
    NgSelectModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    TableModule,
    BadgeModule,
    ChipModule,
    SkeletonModule,
    TooltipModule,
    ButtonModule,
    InputTextModule,
    ToasterModule.forChild(),
    DialogModule,
    SpeedDialModule,
    TriStateCheckboxModule,
    InputSwitchModule,
    AvatarModule,
    MultiSelectModule,
  ],
  declarations: [
    // Pages
    DashboardComponent,
    MapComponent,
    UPCV3Component,
    UpcnoinitComponent,
    CommentmodalComponent,
    GroupebeltComponent,
    ConfirmationModal,
    ConfirmationPollModalContent,
    ConfirmationPollModalGroupContent,
  ]
})
export class DashboardModule { }