import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { InterventionV3Service } from '../../../../../services/upcv3/interventionV3.service';
import { Router } from '@angular/router';

@Component({
  selector: 'delete-intervention-content',
  templateUrl: './delete-intervention.component.html'
})
export class DeleteInterventionModalComponent {
  interventionId: number;
  upcVersion: number = 3;

  // Modal
  modalRef: NgbModalRef;
  callBack: Function;

  // Form
  submitted = false;
  impactStock = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private toasterService: ToasterService,
    private interventionV3Service: InterventionV3Service
  ) {

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  init(interventionId: number, upcVersion: number, modalRef: NgbModalRef, callBack: Function) {
    this.interventionId = interventionId;
    this.upcVersion = upcVersion;
    this.modalRef = modalRef;
    this.callBack = callBack;
  }

  // Modal
  close() {
    this.modalRef.close()
  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.interventionV3Service.delete(this.interventionId).subscribe({
      next: () => {
        this.callBack();
        this.close();
      },
      error: () => {
        this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la suppresion de l'intervention");
        this.close();
      }
    })
  }
}