import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TaskService } from 'src/app/services/management/task.service';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { FirmwareService } from 'src/app/services/upcv3/firmware.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToasterService } from 'angular2-toaster';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { Task, Type } from 'src/app/models/management/task';
import { BehaviorSubject, forkJoin, interval, of, combineLatest, Subject, Observable } from 'rxjs';
import { catchError, delay, switchMap, startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { FirmwareJob, JobStatus, SyncJob } from 'src/app/models/jobs/job';
import { Code, ApiResponse } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: [ './tasks.component.css' ]
})
export class TasksComponent implements OnInit, OnDestroy {
  datasourceFirmware = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sortFirmware: MatSort;
  displayedColumnsFirmware = ['select', 'upcNameId', 'jobStatus', 'creationTimestamp', 'updateTimestamp', 'tryCount', 'firmwareFilename', 'actions'];
  selectionFirmware = new SelectionModel<any>(true, []);

  datasourceSync = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: false }) sortSync: MatSort;
  displayedColumnsSync = ['select', 'upcNameId', 'jobStatus', 'creationTimestamp', 'updateTimestamp', 'tryCount', 'actions'];
  selectionSync = new SelectionModel<any>(true, []);

  datasourceLegacyTasks = new MatTableDataSource<Task>([]);
  @ViewChild(MatSort, { static: false }) sortLegacy: MatSort;
  displayedColumnsLegacy = ['title', 'type', 'actions'];

  tasks: Task[];

  isDataLoading: boolean = false;

  toggleShowFinished = new FormControl(false);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  private readonly autoRefresh$ = interval(5000).pipe(
    startWith(0),
    takeUntil(this.destroy$),
  );
  public readonly refreshToken$ = new BehaviorSubject(undefined);

  private readonly taskLoadData$ = combineLatest([this.autoRefresh$, this.refreshToken$]).pipe(
    switchMap(() => this.loadData$())
  )

  constructor(
    public authService: AuthService,
    public taskService: TaskService,
    public upcv3Service: UPCV3Service,
    public firmwareService: FirmwareService,
    public toasterService: ToasterService,
  ) { }

  ngOnInit() {
    this.datasourceFirmware.sort = this.sortFirmware;

    // Load data
    //this.loadData()
    this.taskLoadData$.subscribe(([tasksData, firmwareUpdateData, syncData]) => {
      // FirmwareUpdate results
      this.datasourceFirmware.data = [...firmwareUpdateData];

      // Tasks results
      this.tasks = tasksData;
      this.datasourceLegacyTasks.data = tasksData.filter(t => t.type != "POOLING");
      // Sync results
      this.datasourceSync.data = [...syncData];

      this.isDataLoading = false;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  flashUpc(upcv3: UPCV3, firmware: string) {
    this.firmwareService.flashOneUpc(firmware, upcv3.id).subscribe(
      () => this.refreshToken$.next(undefined)
    );
  }
  retryFirmware(upcv3: UPCV3, firmware: string, jobStatus: JobStatus) {
    if (jobStatus == "RETRYING") {
      // delay un peu pour laisser le temps au système de lancer le prochain essai
      this.firmwareService.retryJob(upcv3.id).pipe(
        delay(250),
      ).subscribe(
        () => this.refreshToken$.next(undefined)
      );
    } else {
      this.flashUpc(upcv3, firmware);
    }
  }
  retrySync(upcv3: UPCV3, parameters: string, jobStatus: JobStatus) {
    if (jobStatus == "RETRYING") {
      // delay un peu pour laisser le temps au système de lancer le prochain essai
      this.upcv3Service.retrySync(upcv3.id).pipe(
        delay(250),
      ).subscribe(
        () => this.refreshToken$.next(undefined)
      );
    } else {
      this.upcv3Service.sync(upcv3.id, JSON.parse(parameters)).subscribe(
        () => this.refreshToken$.next(undefined)
      );
    }
  }
  deleteFirmwareJob(upcv3: UPCV3) {
    this.firmwareService.deleteJob(upcv3.id).subscribe(
      () => {
        this.refreshToken$.next(undefined);
        this.selectionFirmware.clear();
      }
    );
  }
  deleteSyncJob(upcv3: UPCV3) {
    this.upcv3Service.deleteJob(upcv3.id).subscribe(
      () => {
        this.refreshToken$.next(undefined);
        this.selectionSync.clear();
      }
    );
  }

  deletePollingJob(upc: string) {
    this.upcv3Service.resetPolling(upc).subscribe(
      () => {
        this.refreshToken$.next(undefined);
      }
    )
  }

  deleteLegacyTask(upcName: string) {
    this.upcv3Service.resetLegacyTasks(upcName).subscribe(
      () => {
        this.refreshToken$.next(undefined);
      }
    )
  }

  get poolingTasks(): Task[] {
    if (!this.tasks) return null;
    else return this.tasks.filter(t => t.type == Type.POOLING);
  }

  loadData$() {
    this.isDataLoading = true;
    const showFinished = this.toggleShowFinished.value;
    const tasks: Observable<Task[]> = this.taskService.getAll().pipe(catchError(error => {
      console.error(error);
      this.toasterService.pop('error', `Erreur de communication avec l'API`);
      return of(error);
    }));
    const syncJobs: Observable<SyncJob[]> = this.upcv3Service.getSyncJobs(showFinished).pipe(catchError(error => {
      console.error(error);
      this.toasterService.pop('error', `Erreur de communication avec l'API`);
      return of(error);
    }));
    const firmwareUpdateJobs: Observable<FirmwareJob[]> = this.firmwareService.getJobs(showFinished).pipe(catchError(error => {
      console.error(error);
      this.toasterService.pop('error', `Erreur de communication avec l'API`);
      return of(error);
    }));
    return forkJoin([tasks, firmwareUpdateJobs, syncJobs]);
  }

  onToggleShowFinished() {
    this.refreshToken$.next(undefined);
    this.selectionFirmware.clear();
    this.selectionSync.clear();
  }

  isAllSelectedFirmware() {
    const numSelected = this.selectionFirmware.selected.length;
    const numRows = this.datasourceFirmware.data.length;
    return numSelected == numRows;
  }

  isAtLeastOneSelectedFirmware() {
    return this.selectionFirmware.selected.length > 0;
  }

  isAtLeastOneSelectedArchivedOrHibernatedFirmware() {
    const selectedUpcs = this.selectionFirmware.selected.map(s => this.datasourceFirmware.data.find(row => row.upcv3.id == s).upcv3);
    const archivedUpcs = selectedUpcs.filter(upc => upc.archived || upc.hibernated);
    return archivedUpcs.length > 0;
  }
  
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleFirmware() {
    this.isAllSelectedFirmware() ?
        this.selectionFirmware.clear() :
        this.datasourceFirmware.data.forEach(row => this.selectionFirmware.select(row.upcv3.id));
  }

  unselectArchivedAndHibernatedFirmwareJob() {
    console.log(this.datasourceFirmware.data);
    const selectedUpcs = this.selectionFirmware.selected.map(s => this.datasourceFirmware.data.find(row => row.upcv3.id == s).upcv3);
    const archivedUpcs = selectedUpcs.filter(upc => upc.archived || upc.hibernated);
    archivedUpcs.forEach(upc => this.selectionFirmware.deselect(upc.id));
  }

  deleteSelectedFirmwareJob() {
    this.firmwareService.deleteJobs(this.selectionFirmware.selected).subscribe(
      () => {
        this.refreshToken$.next(undefined);
        this.selectionFirmware.clear();
      }
    );
  }

  isAllSelectedSync() {
    const numSelected = this.selectionSync.selected.length;
    const numRows = this.datasourceSync.data.length;
    return numSelected == numRows;
  }

  isAtLeastOneSelectedSync() {
    return this.selectionSync.selected.length > 0;
  }

  isAtLeastOneSelectedArchivedOrHibernatedSync() {
    const selectedUpcs = this.selectionSync.selected.map(s => this.datasourceSync.data.find(row => row.upcv3.id == s).upcv3);
    const archivedOrHibernatedUpcs = selectedUpcs.filter(upc => upc.archived || upc.hibernated);
    return archivedOrHibernatedUpcs.length > 0;
  }
  
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleSync() {
    this.isAllSelectedSync() ?
        this.selectionSync.clear() :
        this.datasourceSync.data.forEach(row => this.selectionSync.select(row.upcv3.id));
  }

  unselectArchivedAndHibernatedSyncJob() {
    const selectedUpcs = this.selectionSync.selected.map(s => this.datasourceSync.data.find(row => row.upcv3.id == s).upcv3);
    const archivedOrHibernatedUpcs = selectedUpcs.filter(upc => upc.archived || upc.hibernated);
    archivedOrHibernatedUpcs.forEach(upc => this.selectionSync.deselect(upc.id));
  }

  deleteSelectedSyncJob() {
    this.upcv3Service.deleteJobs(this.selectionSync.selected).subscribe(
      () => {
        this.refreshToken$.next(undefined);
        this.selectionSync.clear();
      }
    );
  }
}