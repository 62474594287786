import { Component, OnInit } from '@angular/core';
import { Operator } from '../../models/management/operator';
import { OperatorService } from '../../services/management/operator.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddOperatorModalComponent } from './add-operator/add-operator.component';
import { EditOperatorModalComponent } from './edit-operator/edit-operator.component';
import { DeleteOperatorModalComponent } from './delete-operator/delete-operator.component';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-operators',
  templateUrl: './operators.component.html'
})
export class OperatorsComponent implements OnInit {
  // NF
  operators: Operator[];

  constructor(
    private operatorService: OperatorService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
    this.loadOperators()
  }

  loadOperators() {
    this.operators = null;
    this.operatorService.getAll().subscribe({
      next: (res) => this.operators = res,
      error: (err) => {
        this.toasterService.pop("error", "Erreur", "Une erreur c(='est produite lors de la récupération des Opérateurs");
        this.operators = []
      }
    })
  }

  /* Modals */
  openAddOperatorModal() {
    var addOperatorModalRef = this.modalService.open(AddOperatorModalComponent);
    addOperatorModalRef.componentInstance.init(null, addOperatorModalRef, () => this.loadOperators())
  }

  openEditOperatorModal(operator: Operator) {
    var editOperatorModalRef = this.modalService.open(EditOperatorModalComponent);
    editOperatorModalRef.componentInstance.init(operator, editOperatorModalRef, () => this.loadOperators())
  }

  openDeleteOperatorModal(operator: Operator) {
    var deleteOperatorModalRef = this.modalService.open(DeleteOperatorModalComponent);
    deleteOperatorModalRef.componentInstance.init(operator, deleteOperatorModalRef, () => this.loadOperators())
  }

}