
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';

import {switchMap, take, filter, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
 
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: AuthService,
    private cookieService: CookieService
  ) { }

  addAuthHeader(request): any {
    let token = this.cookieService.get('token');
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    return request;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next.handle(request).pipe(catchError(error => {
      
      // For them: don't refresh token
      if (request.url.includes("refreshtoken") || request.url.includes("login")) {
        if (request.url.includes("refreshtoken")) {
          this.authService.logout();
        }
        return observableThrowError(error);
      }

      // Error statusdifferent than 403
      if (error.status !== 403) {
        return observableThrowError(error);
      }

      if (this.refreshTokenInProgress) {
        // Wait until refreshTokenSubject has a non-null value
        return this.refreshTokenSubject.pipe(
          filter(result => result !== null),
          take(1),
          switchMap(() => next.handle(this.addAuthHeader(request))),);
      } else {
        this.refreshTokenInProgress = true;

        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(null);

        // Call auth.refreshAccessToken(this is an Observable that will be returned)
        return this.authService
          .refreshToken().pipe(
          switchMap(token => {
            //When the call to refreshToken completes we reset the refreshTokenInProgress to false
            // for the next time the token needs to be refreshed
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);

            return next.handle(this.addAuthHeader(request));
          }),
          catchError(err => {
            this.refreshTokenInProgress = false;

            this.authService.logout();
            return observableThrowError(error);
          }),);
      }
    }))
    
  }
}
