import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parameterbelts-alarms',
  templateUrl: './parameters-alarms.component.html',
  styleUrls: ['./parameters-alarms.component.css']
})
export class ParameterBeltsAlarmsComponent {
  @Input() isSync: boolean;
  @Input() vars: AlarmVariables;
}

interface ValueChanged<T> {
  value: T;
  changed: boolean;
}

export interface AlarmVariables {
  alrResLowEn: ValueChanged<boolean>
  alrResLowLevel: ValueChanged<number>
  alrResEmptyEn: ValueChanged<boolean>
  alrResEmptyFlow: ValueChanged<number>
  alrResEmptyTest: ValueChanged<number>
  alrPresInpEn: ValueChanged<boolean>
  alrPresInpTol: ValueChanged<number>
  alrPresOutEn: ValueChanged<boolean>
  alrPresOutTol: ValueChanged<number>
  alrFlowAvgEn: ValueChanged<boolean>
  alrFlowSetTol: ValueChanged<number>
  alrPowBackEn: ValueChanged<boolean>
  alrPowDownEn: ValueChanged<boolean>
  alrSmsNum1: ValueChanged<string>
  alrSmsNum2: ValueChanged<string>
  alrSmsNum3: ValueChanged<string>
  alrSmsNum4: ValueChanged<string>
  alrSmsNum5: ValueChanged<string>
}