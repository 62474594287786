import { Operator } from 'src/app/models/management/operator';
import { jsonMember, jsonArrayMember, jsonObject, TypedJSON } from 'typedjson';
import { UPCV3 } from "../upcv3/upc/upcv3";
import { BottleType } from "./bottleType";
import { Location, LocationType } from "./location";
import { InterventionV3 } from '../upcv3/interventionV3';
import { GroupUPCV3 } from '../GroupUPCV3/GroupUPCV3';

@jsonObject
export class Bottle {
  @jsonMember
  id: string;

  @jsonMember
  barcode: string;

  @jsonMember
  status: status;

  @jsonMember
  state: state;

  @jsonMember(() => Location)
  location: Location;

  @jsonMember(() => UPCV3)
  upcv3?: UPCV3;

  @jsonMember(() => GroupUPCV3)
  groupUPCV3?: GroupUPCV3;

  @jsonMember(() => BottleType)
  bottleType: BottleType;

  @jsonArrayMember(() => BottleEvent)
  bottleEvents: BottleEvent[];

  get statusString(): string {
    switch (this.status) {
      case status.ENTREPOSE: return 'Entreposée';
      case status.B1: return 'Sur B1';
      case status.B2: return 'Sur B2';
      case status.REVOKED: return 'Retirée';
      case status.HL: return 'Hors Ligne';
      case status.IN_TRANSIT: return 'En Transit';

      case status.UNDEFINED: return 'Indéfini';
    }
  }

  get stateString(): string {
    switch (this.state) {
      case state.EMPTY: return "Vide";
      case state.FULL: return "Pleine";
      case state.IN_USE: return 'Entamée';
    }
  }

  get bottleString(): string {
    return this.bottleType.designationString + ' (' + this.barcode + ')'
  }

  static loadFromJSON(json): Bottle {
    const serializer = new TypedJSON(Bottle);
    return serializer.parse(json); 
  }
}

@jsonObject
export class BottleEvent {

  @jsonMember
  id: number;

  @jsonMember({constructor: String})
  type: eventType;

  @jsonMember
  date: Date;

  @jsonMember
  previousBottleState: state;

  @jsonMember
  newBottleState: state;

  @jsonMember
  previousStatus: status;

  @jsonMember(() => Location)
  previousLocation?: Location;

  @jsonMember(() => Location)
  nextLocation?: Location;

  @jsonMember(() => UPCV3)
  previousUpcv3?: UPCV3;

  @jsonMember(() => UPCV3)
  nextUpcv3?: UPCV3;

  @jsonMember(() => GroupUPCV3)
  previousGroupUpcv3?: GroupUPCV3;

  @jsonMember(() => GroupUPCV3)
  nextGroupUpcv3?: GroupUPCV3;

  @jsonMember(() => Operator)
  operator: Operator

  @jsonMember(() => Bottle)
  bottle: Bottle;

  @jsonMember(() => InterventionV3)
  intervention?: InterventionV3;

  static loadFromJson(json): BottleEvent {
    let serializer = new TypedJSON(BottleEvent);
    return serializer.parse(json);
  }

  get eventString(): string {
    let locationString = "";

    switch (this.nextLocation?.locationType) {
      case LocationType.STOCK:
        locationString = 'Ajout au stock'; break;
      case LocationType.SITE:
        locationString = 'Ajout au site'; break;
      case LocationType.RACK:
        locationString = 'Ajout au rack'; break;
    }

    let typeString = '';
    switch (this.type) {
      case eventType.CONNEXION_A_B1:
        typeString = "Connexion en B1"; break;
      case eventType.CONNEXION_A_B2:
        typeString = "Connexion en B2"; break;
      case eventType.RECEPTION:
        typeString = "Reception de la bouteille"; break;
      case eventType.ENTREPOSAGE:
        typeString = "Mise en entreposage"; break;
      case eventType.TRANSIT:
        typeString = "En Transit"; break;
      case eventType.RENVOIE:
        typeString = "Renvoi au fournisseur"; break;
    }

    return [locationString, typeString].filter(e => e.length != 0).join(' - ');
  }
}

@jsonObject
export class BottleHistory {
  @jsonMember
  id: number;

  @jsonMember
  barcode: string;

  @jsonMember
  state: state;

  @jsonMember
  status: status;

  @jsonMember
  locationName: string;

  @jsonMember
  upcv3Name: string;

  @jsonMember
  groupUpcv3Name: string;

  @jsonMember(() => Bottle)
  bottle: Bottle;
}

export enum status {
  ENTREPOSE = 'ENTREPOSE',
  B1 = 'B1',
  B2 = 'B2',
  REVOKED = 'REVOKED',
  HL = 'HL',
  UNDEFINED = 'UNDEFINED',
  IN_TRANSIT = 'IN_TRANSIT',
}

export enum state {
  FULL = 'FULL',
  IN_USE = 'IN_USE',
  EMPTY = 'EMPTY',
}

export enum eventType {
  ENTREPOSAGE = 'ENTREPOSAGE',
  CONNEXION_A_B1 = 'CONNEXION_A_B1',
  CONNEXION_A_B2 = 'CONNEXION_A_B2',
  RENVOIE = 'RENVOIE',
  RECEPTION = 'RECEPTION',
  TRANSIT = 'TRANSIT'

}

export enum Code {
  ENTREPOSAGE = 'ENTREPOSAGE',
  CONNEXION_A_B1 = 'CONNEXION_A_B1',
  CONNEXION_A_B2 = 'CONNEXION_A_B2',
  RECEPTION = 'RECEPTION',
  RENVOIE = 'RENVOIE'
}