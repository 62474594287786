import { SiteService } from './../../../../../services/management/site.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { intensities, daysCodes } from 'src/app/models/upcv3/upc/diffCo2Program';
import { VersionService } from 'src/app/services/project/version.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BottleService } from 'src/app/services/management/bottle.service';
import { VersionmodalComponent } from '../versionmodal/versionmodal.component';
import { NgbModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Code } from 'src/app/models/ApiResponse';
import { HttpErrorResponse } from '@angular/common/http';
import _ from "lodash";
import { Bottle, status } from 'src/app/models/management/bottle';
import { CO2_LITERS_TO_KG_RATIO, CO2_SL_TO_NL_RATIO } from 'src/app/constants/co2';

@Component({
  selector: 'app-upc-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.css']
})
export class ParametersComponent implements OnInit {
  @Input('upcv3') upcv3: UPCV3;
  @Output('upcv3Changed') upcv3Changed = new EventEmitter<UPCV3>();

  vars: any;
  intensities: any[] = intensities;
  daysCodes: any[] = daysCodes;
  isSync: boolean = false;
  from: NgbDate;
  to: NgbDate;
  wrongRange: boolean = false;
  progTempString = "";
  debut: any;
  fin: any;
  textSynctemp = "";

  dailyConsumption: number = 0;
  weeklyConsumption: number = 0;
  monthlyConsumption: number = 0;
  dailyConsumptionServer: number = 0;

  // Refill
  refillInterventionDatetime: string;
  refillReserve: number = 1;
  refillTotalAdded: number = 0;
  refillTotalConsumed: number = 0;
  refillReplaceAll: boolean = false;
  refillRealAdded: number = 0;
  desigB1A: any = [];
  desigB2A: any = [];
  marqueB1: any = [];
  marqueB2: any = [];
  codeB1: any = [];
  codeB2: any = [];
  idB1 = [];
  idB2 = [];
  idHL = [];
  tempSync = false;
  permSync = true;

  bottlesB1: Bottle[];
  bottlesB2: Bottle[];
  bottlesSite: Bottle[];
  displayedColumns = ['barcode', 'type', 'state'];

  //data sync with ui
  loadingData = false;

  private onDestroy$: Subject<void> = new Subject<void>();

  get refillFormInvalid(): boolean {
    if (this.refillInterventionDatetime == undefined || this.refillInterventionDatetime.length == 0) return true;
    else if (this.refillTotalAdded == null || this.refillTotalAdded < 0) return true;
    else if (this.refillTotalConsumed == null) return true;
    else if (this.refillRealAdded == null || this.refillRealAdded < 0) return true;
    return false;
  }

  get needSync(): boolean {
    return this.vars.upcMode.changed ||

      this.vars.co2ResType.changed || this.vars.co2ResActive.changed ||
      this.vars.co2Res1Status.changed || this.vars.co2Res1ActVol.changed || this.vars.co2Res1Enabled.changed ||
      this.vars.co2Res2Status.changed || this.vars.co2Res2ActVol.changed || this.vars.co2Res2Enabled.changed ||

      this.vars.co2ResActAdj.changed || this.vars.upcDiffLvlAdj.changed || this.vars.co2FlowRefAdj.changed ||

      this.vars.co2PresOutRef1.changed || this.vars.co2PresOutRef2.changed || this.vars.co2PresOutRef3.changed ||
      this.vars.co2PresOutRef4.changed || this.vars.co2PresOutRef5.changed || this.vars.co2PresOutRef6.changed ||
      this.vars.co2PresOutRef7.changed || this.vars.co2PresOutRef8.changed || this.vars.co2PresOutRef9.changed ||
      this.vars.co2PresOutRef10.changed ||

      this.vars.daysCode_1.changed || this.vars.start_1.changed || this.vars.end_1.changed || this.vars.intensity_1.changed ||
      this.vars.daysCode_2.changed || this.vars.start_2.changed || this.vars.end_2.changed || this.vars.intensity_2.changed ||
      this.vars.daysCode_3.changed || this.vars.start_3.changed || this.vars.end_3.changed || this.vars.intensity_3.changed ||
      this.vars.daysCode_4.changed || this.vars.start_4.changed || this.vars.end_4.changed || this.vars.intensity_4.changed ||
      this.vars.daysCode_5.changed || this.vars.start_5.changed || this.vars.end_5.changed || this.vars.intensity_5.changed ||
      this.vars.daysCode_6.changed || this.vars.start_6.changed || this.vars.end_6.changed || this.vars.intensity_6.changed ||
      this.vars.daysCode_7.changed || this.vars.start_7.changed || this.vars.end_7.changed || this.vars.intensity_7.changed ||
      this.vars.daysCode_8.changed || this.vars.start_8.changed || this.vars.end_8.changed || this.vars.intensity_8.changed ||
      this.vars.daysCode_9.changed || this.vars.start_9.changed || this.vars.end_9.changed || this.vars.intensity_9.changed ||
      this.vars.daysCode_10.changed || this.vars.start_10.changed || this.vars.end_10.changed || this.vars.intensity_10.changed ||

      this.vars.sunrise_sign.changed || this.vars.sunrise_delay.changed || this.vars.sunrise_duration.changed || this.vars.sunrise_intensity.changed ||
      this.vars.sunset_sign.changed || this.vars.sunset_delay.changed || this.vars.sunset_duration.changed || this.vars.sunset_intensity.changed ||

      this.vars.alrResLowEn.changed || this.vars.alrResLowLevel.changed ||
      this.vars.alrResEmptyEn.changed || this.vars.alrResEmptyFlow.changed || this.vars.alrResEmptyTest.changed ||
      this.vars.alrPresInpEn.changed || this.vars.alrPresInpTol.changed ||
      this.vars.alrPresOutEn.changed || this.vars.alrPresOutTol.changed ||
      this.vars.alrFlowAvgEn.changed || this.vars.alrFlowSetTol.changed ||
      this.vars.alrPowBackEn.changed || this.vars.alrPowDownEn.changed ||
      this.vars.alrSmsNum1.changed || this.vars.alrSmsNum2.changed || this.vars.alrSmsNum3.changed || this.vars.alrSmsNum4.changed || this.vars.alrSmsNum5.changed ||

      this.vars.upcTimeZone.changed ||
      this.vars.upcLanguage.changed ||
      this.vars.upcTrapNum.changed ||
      this.vars.co2FlowRefTrap.changed || this.vars.co2FlowRefNom.changed ||
      this.vars.upcCo2PidProp.changed || this.vars.upcCo2PidInteg.changed || this.vars.upcCo2PidDiff.changed ||

      this.vars.comGsmName.changed || this.vars.comGsmPass.changed ||
      this.vars.comWiFiName.changed || this.vars.comWiFiPass.changed || this.vars.comWifiApCh.changed ||
      this.vars.comWebSrvUrl.changed ||
      this.vars.comMdmApnId.changed || this.vars.comMdmApnId2.changed || this.vars.comMdmApnUser.changed || this.vars.comMdmApnPass.changed ||

      this.vars.co2PressInpOffs.changed
      || this.vars.co2PressOutOffs.changed ||
      this.vars.co2FlowOffs.changed ||
      this.vars.co2Res1StartVol.changed ||
      this.vars.co2Res2StartVol.changed;
  }

  onPerm() {
    this.permSync = true;
    this.tempSync = false;

    this.loadVars();
  }
  onTemp() {
    this.permSync = false;
    this.tempSync = true;

    this.loadVarsTemp();

  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  get beltRefConsumption() {
    return ((this.upcv3.generalParameters.co2FlowRefTrap * CO2_LITERS_TO_KG_RATIO) * this.upcv3.generalParameters.upcTrapNum) * 60;
  }

  private calculateConsumption() {

    // Parse diffusions (program - start - stop - intensity - TODO: mode)

    var diffusions = [
      {
        id: 1,
        start: this.getSecondsFromHourMinutes(this.vars.start_1.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_1.value) / 60,
        intensity: this.vars.intensity_1.value,
        dayCode: this.vars.daysCode_1.value
      },
      {
        id: 2,
        start: this.getSecondsFromHourMinutes(this.vars.start_2.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_2.value) / 60,
        intensity: this.vars.intensity_2.value,
        dayCode: this.vars.daysCode_2.value
      },
      {
        id: 3,
        start: this.getSecondsFromHourMinutes(this.vars.start_3.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_3.value) / 60,
        intensity: this.vars.intensity_3.value,
        dayCode: this.vars.daysCode_3.value
      },
      {
        id: 4,
        start: this.getSecondsFromHourMinutes(this.vars.start_4.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_4.value) / 60,
        intensity: this.vars.intensity_4.value,
        dayCode: this.vars.daysCode_4.value
      },
      {
        id: 5,
        start: this.getSecondsFromHourMinutes(this.vars.start_5.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_5.value) / 60,
        intensity: this.vars.intensity_5.value,
        dayCode: this.vars.daysCode_5.value
      },
      {
        id: 6,
        start: this.getSecondsFromHourMinutes(this.vars.start_6.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_6.value) / 60,
        intensity: this.vars.intensity_6.value,
        dayCode: this.vars.daysCode_6.value
      },
      {
        id: 7,
        start: this.getSecondsFromHourMinutes(this.vars.start_7.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_7.value) / 60,
        intensity: this.vars.intensity_7.value,
        dayCode: this.vars.daysCode_7.value
      },
      {
        id: 8,
        start: this.getSecondsFromHourMinutes(this.vars.start_8.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_8.value) / 60,
        intensity: this.vars.intensity_8.value,
        dayCode: this.vars.daysCode_8.value
      },
      {
        id: 9,
        start: this.getSecondsFromHourMinutes(this.vars.start_9.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_9.value) / 60,
        intensity: this.vars.intensity_9.value,
        dayCode: this.vars.daysCode_9.value
      },
      {
        id: 10,
        start: this.getSecondsFromHourMinutes(this.vars.start_10.value) / 60,
        end: this.getSecondsFromHourMinutes(this.vars.end_10.value) / 60,
        intensity: this.vars.intensity_10.value,
        dayCode: this.vars.daysCode_10.value
      },

      {
        id: 11,
        start: (this.upcv3.diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value))) / 60,
        end: (this.upcv3.diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunrise_duration.value)) / 60,
        intensity: this.vars.sunrise_intensity.value,
        dayCode: 7
      },
      {
        id: 12,
        start: (this.upcv3.diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value))) / 60,
        end: (this.upcv3.diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunset_duration.value)) / 60,
        intensity: this.vars.sunset_intensity.value,
        dayCode: 7
      }
    ];

    // For each day in a week
    var realDiffusions = [];
    for (var d = 0; d < 7; d++) {
      var currentProgram = 0, currentIntensity = 0;
      var diff = { id: 0, start: 0, end: 0, intensity: 0 };

      // For each minute in a day
      for (var m = 0; m < 60 * 24; m++) {
        var program = currentProgram, intensity = currentIntensity;

        // Check if current program is end
        if (program > 0 && m > diffusions[program - 1].end) program = 0, intensity = 0;

        /// Get current program
        for (let diffusion of diffusions) {
          if (m >= diffusion.start && m <= diffusion.end && diffusion.intensity > intensity) {
            if (
              (d == 0 && diffusion.dayCode == 1) || // Case Monday
              (d == 1 && diffusion.dayCode == 2) || // Case Tuesday
              (d == 2 && diffusion.dayCode == 3) || // Case Wednesday
              (d == 3 && diffusion.dayCode == 4) || // Case Thursday
              (d == 4 && diffusion.dayCode == 5) || // Case Friday
              (d == 5 && diffusion.dayCode == 6) || // Case Saturday
              (d == 6 && diffusion.dayCode == 0) || // Case Sunday
              diffusion.dayCode == 7 || // Case Every Day
              ((d == 5 || d == 6) && diffusion.dayCode == 8) || // Case Weekend
              ((d >= 0 && d <= 4) && diffusion.dayCode == 9) // Case Week
            ) program = diffusion.id, intensity = diffusion.intensity;
          }
        }

        // Case midnight: reset current diff
        if (m == 60 * 24 - 1) program = 0, intensity = 0;

        // Check if program changed
        if (program != currentProgram) {

          // Case start diff
          if (program > 0 && currentProgram == 0) {
            diff = { id: program, start: m * 60, end: 0, intensity: intensity };
          }

          // Case stop diff
          else if (program == 0 && currentProgram > 0) {
            diff.end = m * 60;
            realDiffusions.push(diff);
          }

          // Case stop - start diff
          else if (program > 0 && currentProgram > 0) {
            diff.end = m * 60;
            realDiffusions.push(diff);
            diff = { id: program, start: m * 60, end: 0, intensity: intensity };
          }

          currentProgram = program, currentIntensity = intensity;
        }

      }

    }

    // Calculate consumptions
    var consumption = 0;
    for (let diffusion of realDiffusions) {
      consumption += Math.round((this.beltRefConsumption * ((diffusion.end - diffusion.start) / 3600) * diffusion.intensity / 10) * 100) / 100;
    }

    consumption = consumption / CO2_SL_TO_NL_RATIO;

    this.dailyConsumption = Math.round(consumption / 7 * 100) / 100;
    this.weeklyConsumption = Math.round(consumption * 100) / 100;
    this.monthlyConsumption = Math.round(consumption * 4 * 100) / 100;

    this.vars.consumption = { value: this.dailyConsumption, changed: true };


  }

  constructor(
    private upcv3Service: UPCV3Service,
    private siteService: SiteService,
    private versionService: VersionService,
    private bottleService: BottleService,
    private modalService: NgbModal,
    private toasterService: ToasterService,
  ) { }


  async ngOnInit() {
    this.loadingData = true

    this.loadVars();

    const siteOfUpc = this.upcv3.site != null ? this.upcv3.site : this.upcv3.groupUpcv3?.site;
    const bottlesOfUpc = siteOfUpc?.bottles.filter(b => b.upcv3?.id == this.upcv3.id);
    this.bottlesB1 = bottlesOfUpc?.filter(b => b.status == status.B1);
    this.bottlesB2 = bottlesOfUpc?.filter(b => b.status == status.B2);
    this.bottlesSite = siteOfUpc?.bottles.filter(b => b.status == status.ENTREPOSE);

    if (this.upcv3.belt != null) {
      this.versionService.getBelts(this.upcv3.belt).subscribe(res => {
        if (res.code == Code.VERSION_RECOVERED) {
          //alert(JSON.stringify(res));
          //alert(JSON.stringify(res.result));
          localStorage.setItem("versions", JSON.stringify(res.result));
        } else {
          localStorage.removeItem("versions");
        }
      })
    } else {
      localStorage.removeItem("versions");
    }
    this.loadingData = false
  }

  /* Diffusions */
  getDateFromSeconds(value: number): string {
    return new Date(value).toLocaleString()
  }
  getHourMinutesFromSeconds(value: number): string {
    return new Date(value * 1000).toISOString().substr(11, 5)
  }
  getSecondsFromHourMinutes(value: string): number {
    var hour = parseInt(value.split(':')[0]), minute = parseInt(value.split(':')[1]);
    return hour * 3600 + minute * 60;
  }
  getSunriseStartStop(): string {
    return this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value))) +
      ' - ' +
      this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunrise + (this.vars.sunrise_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunrise_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunrise_duration.value));
  }
  getSunsetStartStop(): string {
    return this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value))) +
      ' - ' +
      this.getHourMinutesFromSeconds(this.upcv3.diffusionParameters.diffHourSunset + (this.vars.sunset_sign.value == '-' ? - this.getSecondsFromHourMinutes(this.vars.sunset_delay.value) : this.getSecondsFromHourMinutes(this.vars.sunset_delay.value)) + this.getSecondsFromHourMinutes(this.vars.sunset_duration.value));
  }

  getUpc(id: number): Promise<UPCV3> {
    return this.upcv3Service.get(id).toPromise()
  }

  syncOnlyAdjust() {
    this.isSync = true;
    const adjustVars = _.pickBy(this.vars, (val) => val.isAdjustTab == true)
    if(this.permSync) {
      console.log(adjustVars)
      this.upcv3Service.sync(this.upcv3.id, adjustVars, true).pipe(
        finalize(() => this.isSync = false)
      ).subscribe({
        next: async () => {
          try {
            this.upcv3 = await this.getUpc(this.upcv3.id)
            this.upcv3Changed.emit(this.upcv3);
            this.loadVars();
            this.toasterService.pop('success', 'Synchronisation réussie');
          } catch (err) {
            this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la mise à jour des données sur le serveur");
            throw new Error(`Unable to get Upc data and after successfull sync`);
          }
        },
        error: (err: HttpErrorResponse) => {
          if(err?.error?.code == null) {
            this.toasterService.pop('error', "Erreur", 'Erreur de synchronisation, voir la liste des tâches');
          }
          switch(err.error.code) {
            case Code.SYNC_ERROR: this.toasterService.pop('error', "Erreur", 'Erreur de synchronisation, voir la liste des tâches'); break;                   
            case Code.SYNC_TIMEOUT: this.toasterService.pop('error', "Erreur", 'Timeout lors de la tentative de synchronisation'); break;
            case Code.SYNC_START_ERROR : this.toasterService.pop("error", "Erreur", "Erreur lors du démarrage de la sychronisation"); break;
            case Code.TASK_ALREADY_RUNNING: this.toasterService.pop("warning", "Attention", "Une tache de synchronisation est déja en cours pour cette UPC, voir la liste des tâches"); break;
            default: this.toasterService.pop('error', "Erreur", 'Erreur de synchronisation inconnue'); break;
          }
        },
      })
    } else {
      this.toasterService.pop("error", "Erreur", "La synchronisation déclenchés est temporaires, cela ne devrait pas se produire, aucune action n'a été déclenchée")
    }
  }

  /* Sync */
  async sync() {
    this.isSync = true;
    if (this.permSync) {
      this.upcv3Service.sync(this.upcv3.id, this.vars, true).pipe(
        finalize(() => this.isSync = false)
      ).subscribe({
        next: async () => {
          try {
            this.upcv3 = await this.getUpc(this.upcv3.id)
            this.upcv3Changed.emit(this.upcv3);
            this.loadVars();
            this.toasterService.pop('success', 'Synchronisation réussie');
          } catch (err) {
            this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la mise à jour des données sur le serveur");
            throw new Error(`Unable to get Upc data and after successfull sync`);
          }
        },
        error: (err: HttpErrorResponse) => {
          if(err?.error?.code == null) {
            this.toasterService.pop('error', "Erreur", 'Erreur de synchronisation, voir la liste des tâches');
          }
          switch(err.error.code) {
            case Code.SYNC_ERROR: this.toasterService.pop('error', "Erreur", 'Erreur de synchronisation, voir la liste des tâches'); break;                   
            case Code.SYNC_TIMEOUT: this.toasterService.pop('error', "Erreur", 'Timeout lors de la tentative de synchronisation'); break;
            case Code.SYNC_START_ERROR : this.toasterService.pop("error", "Erreur", "Erreur lors du démarrage de la sychronisation"); break;
            case Code.TASK_ALREADY_RUNNING: this.toasterService.pop("warning", "Attention", "Une tache de synchronisation est déja en cours pour cette UPC, voir la liste des tâches"); break;
            default: this.toasterService.pop('error', "Erreur", 'Erreur de synchronisation inconnue'); break;
          }
        },
      })
    } else {
      if (this.from && this.to) {
        this.vars.debut = { value: this.from.year + "/" + this.from.month + "/" + this.from.day, changed: true };
        this.vars.fin = { value: this.to.year + "/" + this.to.month + "/" + this.to.day, changed: true };

        this.upcv3Service.syncTemp(this.upcv3.id, this.vars).pipe(
          finalize(() => this.isSync = false)
        ).subscribe({
          next: async () => {
            try {
              await this.getUpc(this.upcv3.id)

              var options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

              this.debut = new Date(this.upcv3.debut).toLocaleDateString("fr-Fr", options);
              // Soustrait un jour et affiche inclus pour éviter l'ambiguité des 00:00 et minuit
              this.fin = new Date(this.upcv3.fin);
              this.fin.setDate(this.fin.getDate() - 1);
              this.fin = this.fin.toLocaleDateString("fr-Fr", options);

              var date = new Date().getTime();

              if (date < new Date(this.upcv3.debut).getTime()) {
                this.textSynctemp = "Une programmation est prévu du " + this.debut + " au " + this.fin + " inclus";
              } else if (date >= new Date(this.upcv3.debut).getTime() && date <= new Date(this.upcv3.fin).getTime()) {
                this.textSynctemp = "Une Programmation temporaire est en cours, elle a débuté le " + this.debut + " et termine le " + this.fin + " inclus";
              } else if (date > new Date(this.upcv3.fin).getTime()) {
                this.textSynctemp = "";
              }

              this.upcv3Changed.emit(this.upcv3);

              this.loadVarsTemp();
              var dt = new Date();
              dt.setHours(0, 0, 0, 0);
              this.upcv3Service.calcProg(this.upcv3.upcNameId).subscribe(newCalcProg => {
                this.dailyConsumptionServer = newCalcProg;
              })
            } catch (err) {
              if(err?.error?.code == null) {
                this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la procédure de synchronisation des programmes temporaires")
              }
              switch(err.error.code) {
                case Code.MODBUS_CONNECTION_ERROR: this.toasterService.pop("error", "Erreur", "Erreur d'accès Modbus");break;
                case Code.TASK_ALREADY_RUNNING: this.toasterService.pop('warning', "Attention", "Une tache de synchronisation est déja en cours pour cette UPC, voir la liste des tâches")
              }
            }
          },
          error: (err) => {
            this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la procédure de synchronisation des programmes temporaires");
          },
        })
      }
      else {
        this.wrongRange = true;
        this.isSync = false;
      }
    }

  }

  loadVars() {

    var options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    this.debut = new Date(this.upcv3.debut).toLocaleDateString("fr-FR", options);
    // Soustrait un jour et affiche inclus pour éviter l'ambiguité des 00:00 et minuit
    this.fin = new Date(this.upcv3.fin);
    this.fin.setDate(this.fin.getDate() - 1);
    this.fin = this.fin.toLocaleDateString("fr-Fr", options);

    var date = new Date().getTime();

    if (date < new Date(this.upcv3.debut).getTime()) {
      this.textSynctemp = "Une programmation est prévu du " + this.debut + " au " + this.fin + " inclus";
    } else if (date >= new Date(this.upcv3.debut).getTime() && date <= new Date(this.upcv3.fin).getTime()) {
      this.textSynctemp = "Une Programmation temporaire est en cours, elle a débuté le " + this.debut + " et termine le " + this.fin + " inclus";
    } else if (date > new Date(this.upcv3.fin).getTime()) {
      this.textSynctemp = "";
    }

    this.vars = {

      // General
      upcMode: { value: this.upcv3.generalParameters.upcMode, changed: false },


      // CO2 Status
      co2ResActive: { value: this.upcv3.reservesParameters.co2ResActive, changed: false },
      co2ResActPrev: { value: this.upcv3.reservesParameters.co2ResActPrev },
      co2ResInactPrev: { value: this.upcv3.reservesParameters.co2ResInactPrev },
      co2ResType: { value: this.upcv3.reservesParameters.co2ResType, changed: false },
      consumption: { value: this.dailyConsumption, changed: true },

      co2Res1Status: { value: this.upcv3.reservesParameters.co2Res1Status, changed: false },
      co2Res1Enabled: { value: this.upcv3.reservesParameters.co2Res1Enabled, changed: false },
      co2Res1ActVol: { value: Math.round(this.upcv3.reservesParameters.co2Res1ActVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false }, // Convert litter to kg

      co2Res2Status: { value: this.upcv3.reservesParameters.co2Res2Status, changed: false },
      co2Res2Enabled: { value: this.upcv3.reservesParameters.co2Res2Enabled, changed: false },
      co2Res2ActVol: { value: Math.round(this.upcv3.reservesParameters.co2Res2ActVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false }, // Convert litter to kg

      co2Res1StartVol: { value: Math.round(this.upcv3.reservesParameters.co2Res1StartVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false },
      co2Res2StartVol: { value: Math.round(this.upcv3.reservesParameters.co2Res2StartVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false },

      // Adjustments
      co2ResActAdj: { value: this.upcv3.reservesParameters.co2ResActAdj, changed: false, isAdjustTab: true },
      upcDiffLvlAdj: { value: this.upcv3.diffusionParameters.upcDiffLvlAdj, changed: false, isAdjustTab: true },
      co2FlowRefAdj: { value: this.upcv3.generalParameters.co2FlowRefAdj, changed: false, isAdjustTab: true },
  
      calcRefFlowRate: { value:  this.upcv3.generalParameters.co2FlowRefAdj * this.upcv3.diffusionParameters.upcCo2DiffLvl / 10, isAdjustTab: true},

      co2PresOutRef1: { value: this.upcv3.generalParameters.co2PresOutRef1 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef2: { value: this.upcv3.generalParameters.co2PresOutRef2 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef3: { value: this.upcv3.generalParameters.co2PresOutRef3 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef4: { value: this.upcv3.generalParameters.co2PresOutRef4 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef5: { value: this.upcv3.generalParameters.co2PresOutRef5 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef6: { value: this.upcv3.generalParameters.co2PresOutRef6 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef7: { value: this.upcv3.generalParameters.co2PresOutRef7 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef8: { value: this.upcv3.generalParameters.co2PresOutRef8 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef9: { value: this.upcv3.generalParameters.co2PresOutRef9 / 1000, changed: false, isAdjustTab: true },
      co2PresOutRef10: { value: this.upcv3.generalParameters.co2PresOutRef10 / 1000, changed: false, isAdjustTab: true},


      // Programs
      daysCode_1: { value: this.upcv3.diffusionParameters.diffCo2Program1.daysCode, changed: false },
      start_1: { value: this.upcv3.diffusionParameters.diffCo2Program1.start.substr(0, 5), changed: false },
      end_1: { value: this.upcv3.diffusionParameters.diffCo2Program1.end.substr(0, 5), changed: false },
      intensity_1: { value: this.upcv3.diffusionParameters.diffCo2Program1.intensity, changed: false },

      daysCode_2: { value: this.upcv3.diffusionParameters.diffCo2Program2.daysCode, changed: false },
      start_2: { value: this.upcv3.diffusionParameters.diffCo2Program2.start.substr(0, 5), changed: false },
      end_2: { value: this.upcv3.diffusionParameters.diffCo2Program2.end.substr(0, 5), changed: false },
      intensity_2: { value: this.upcv3.diffusionParameters.diffCo2Program2.intensity, changed: false },

      daysCode_3: { value: this.upcv3.diffusionParameters.diffCo2Program3.daysCode, changed: false },
      start_3: { value: this.upcv3.diffusionParameters.diffCo2Program3.start.substr(0, 5), changed: false },
      end_3: { value: this.upcv3.diffusionParameters.diffCo2Program3.end.substr(0, 5), changed: false },
      intensity_3: { value: this.upcv3.diffusionParameters.diffCo2Program3.intensity, changed: false },

      daysCode_4: { value: this.upcv3.diffusionParameters.diffCo2Program4.daysCode, changed: false },
      start_4: { value: this.upcv3.diffusionParameters.diffCo2Program4.start.substr(0, 5), changed: false },
      end_4: { value: this.upcv3.diffusionParameters.diffCo2Program4.end.substr(0, 5), changed: false },
      intensity_4: { value: this.upcv3.diffusionParameters.diffCo2Program4.intensity, changed: false },

      daysCode_5: { value: this.upcv3.diffusionParameters.diffCo2Program5.daysCode, changed: false },
      start_5: { value: this.upcv3.diffusionParameters.diffCo2Program5.start.substr(0, 5), changed: false },
      end_5: { value: this.upcv3.diffusionParameters.diffCo2Program5.end.substr(0, 5), changed: false },
      intensity_5: { value: this.upcv3.diffusionParameters.diffCo2Program5.intensity, changed: false },

      daysCode_6: { value: this.upcv3.diffusionParameters.diffCo2Program6.daysCode, changed: false },
      start_6: { value: this.upcv3.diffusionParameters.diffCo2Program6.start.substr(0, 5), changed: false },
      end_6: { value: this.upcv3.diffusionParameters.diffCo2Program6.end.substr(0, 5), changed: false },
      intensity_6: { value: this.upcv3.diffusionParameters.diffCo2Program6.intensity, changed: false },

      daysCode_7: { value: this.upcv3.diffusionParameters.diffCo2Program7.daysCode, changed: false },
      start_7: { value: this.upcv3.diffusionParameters.diffCo2Program7.start.substr(0, 5), changed: false },
      end_7: { value: this.upcv3.diffusionParameters.diffCo2Program7.end.substr(0, 5), changed: false },
      intensity_7: { value: this.upcv3.diffusionParameters.diffCo2Program7.intensity, changed: false },

      daysCode_8: { value: this.upcv3.diffusionParameters.diffCo2Program8.daysCode, changed: false },
      start_8: { value: this.upcv3.diffusionParameters.diffCo2Program8.start.substr(0, 5), changed: false },
      end_8: { value: this.upcv3.diffusionParameters.diffCo2Program8.end.substr(0, 5), changed: false },
      intensity_8: { value: this.upcv3.diffusionParameters.diffCo2Program8.intensity, changed: false },

      daysCode_9: { value: this.upcv3.diffusionParameters.diffCo2Program9.daysCode, changed: false },
      start_9: { value: this.upcv3.diffusionParameters.diffCo2Program9.start.substr(0, 5), changed: false },
      end_9: { value: this.upcv3.diffusionParameters.diffCo2Program9.end.substr(0, 5), changed: false },
      intensity_9: { value: this.upcv3.diffusionParameters.diffCo2Program9.intensity, changed: false },

      daysCode_10: { value: this.upcv3.diffusionParameters.diffCo2Program10.daysCode, changed: false },
      start_10: { value: this.upcv3.diffusionParameters.diffCo2Program10.start.substr(0, 5), changed: false },
      end_10: { value: this.upcv3.diffusionParameters.diffCo2Program10.end.substr(0, 5), changed: false },
      intensity_10: { value: this.upcv3.diffusionParameters.diffCo2Program10.intensity, changed: false },

      sunset_sign: { value: this.upcv3.diffusionParameters.diffCo2Sunset.delay > 0 ? '+' : '-', changed: false },
      sunset_delay: { value: this.getHourMinutesFromSeconds(Math.abs(this.upcv3.diffusionParameters.diffCo2Sunset.delay * 60)), changed: false },
      sunset_duration: { value: this.getHourMinutesFromSeconds(Math.abs(this.upcv3.diffusionParameters.diffCo2Sunset.duration * 60)), changed: false },
      sunset_intensity: { value: this.upcv3.diffusionParameters.diffCo2Sunset.intensity, changed: false },

      sunrise_sign: { value: this.upcv3.diffusionParameters.diffCo2Sunrise.delay > 0 ? '+' : '-', changed: false },
      sunrise_delay: { value: this.getHourMinutesFromSeconds(Math.abs(this.upcv3.diffusionParameters.diffCo2Sunrise.delay * 60)), changed: false },
      sunrise_duration: { value: this.getHourMinutesFromSeconds(Math.abs(this.upcv3.diffusionParameters.diffCo2Sunrise.duration * 60)), changed: false },
      sunrise_intensity: { value: this.upcv3.diffusionParameters.diffCo2Sunrise.intensity, changed: false },


      // Alarms
      alrResLowEn: { value: this.upcv3.alarmsParameters.alrResLowEn, changed: false },
      alrResEmptyEn: { value: this.upcv3.alarmsParameters.alrResEmptyEn, changed: false },
      alrPresInpEn: { value: this.upcv3.alarmsParameters.alrPresInpEn, changed: false },
      alrPresOutEn: { value: this.upcv3.alarmsParameters.alrPresOutEn, changed: false },
      alrFlowAvgEn: { value: this.upcv3.alarmsParameters.alrFlowAvgEn, changed: false },
      alrPowBackEn: { value: this.upcv3.alarmsParameters.alrPowBackEn, changed: false },
      alrPowDownEn: { value: this.upcv3.alarmsParameters.alrPowDownEn, changed: false },

      alrResLowLevel: { value: this.upcv3.alarmsParameters.alrResLowLevel, changed: false },
      alrResEmptyFlow: { value: this.upcv3.alarmsParameters.alrResEmptyFlow, changed: false },
      alrPresInpTol: { value: this.upcv3.alarmsParameters.alrPresInpTol, changed: false },
      alrPresOutTol: { value: this.upcv3.alarmsParameters.alrPresOutTol, changed: false },
      alrFlowSetTol: { value: this.upcv3.alarmsParameters.alrFlowSetTol, changed: false },
      alrResEmptyTest: { value: this.upcv3.alarmsParameters.alrResEmptyTest, changed: false },
      alrSmsNum1: { value: this.upcv3.alarmsParameters.alrSmsNum1, changed: false },
      alrSmsNum2: { value: this.upcv3.alarmsParameters.alrSmsNum2, changed: false },
      alrSmsNum3: { value: this.upcv3.alarmsParameters.alrSmsNum3, changed: false },
      alrSmsNum4: { value: this.upcv3.alarmsParameters.alrSmsNum4, changed: false },
      alrSmsNum5: { value: this.upcv3.alarmsParameters.alrSmsNum5, changed: false },


      // Extras
      upcTimeZone: { value: this.upcv3.generalParameters.upcTimeZone, changed: false },
      upcLanguage: { value: this.upcv3.generalParameters.upcLanguage, changed: false },
      upcTrapNum: { value: this.upcv3.generalParameters.upcTrapNum, changed: false },
      co2FlowRefTrap: { value: this.upcv3.generalParameters.co2FlowRefTrap, changed: false },
      co2FlowRefNom: { value: this.upcv3.generalParameters.co2FlowRefNom, changed: false },
      upcCo2PidProp: { value: this.upcv3.generalParameters.upcCo2PidProp, changed: false },
      upcCo2PidInteg: { value: this.upcv3.generalParameters.upcCo2PidInteg, changed: false },
      upcCo2PidDiff: { value: this.upcv3.generalParameters.upcCo2PidDiff, changed: false },

      comGsmName: { value: this.upcv3.communicationParameters.comGsmName, changed: false },
      comGsmPass: { value: this.upcv3.communicationParameters.comGsmPass, changed: false },
      comWiFiName: { value: this.upcv3.communicationParameters.comWiFiName, changed: false },
      comWiFiPass: { value: this.upcv3.communicationParameters.comWiFiPass, changed: false },
      comWifiApCh: { value: this.upcv3.communicationParameters.comWifiApCh, changed: false },
      comWebSrvUrl: { value: this.upcv3.communicationParameters.comWebSrvUrl, changed: false },
      comMdmApnId: { value: this.upcv3.communicationParameters.comMdmApnId, changed: false },
      comMdmApnId2: { value: this.upcv3.communicationParameters.comMdmApnId2, changed: false },
      comMdmApnUser: { value: this.upcv3.communicationParameters.comMdmApnUser, changed: false },
      comMdmApnPass: { value: this.upcv3.communicationParameters.comMdmApnPass, changed: false },

      co2PressInpOffs: { value: this.upcv3.diffusionParameters.co2PressInpOffs, changed: false },
      co2PressOutOffs: { value: this.upcv3.diffusionParameters.co2PressOutOffs, changed: false },
      co2FlowOffs: { value: this.upcv3.diffusionParameters.co2FlowOffs, changed: false }
    }

    this.calculateConsumption();
  }

  loadVarsTemp() {
    this.vars = {

      // General
      upcMode: { value: this.upcv3.generalParameters.upcMode, changed: false },


      // CO2 Status
      co2ResActive: { value: this.upcv3.reservesParameters.co2ResActive, changed: false },
      co2ResActPrev: { value: this.upcv3.reservesParameters.co2ResActPrev },
      co2ResInactPrev: { value: this.upcv3.reservesParameters.co2ResInactPrev },
      co2ResType: { value: this.upcv3.reservesParameters.co2ResType, changed: false },
      consumption: { value: this.dailyConsumption, changed: true },

      co2Res1Status: { value: this.upcv3.reservesParameters.co2Res1Status, changed: false },
      co2Res1Enabled: { value: this.upcv3.reservesParameters.co2Res1Enabled, changed: false },
      co2Res1ActVol: { value: Math.round(this.upcv3.reservesParameters.co2Res1ActVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false }, // Convert litter to kg

      co2Res2Status: { value: this.upcv3.reservesParameters.co2Res2Status, changed: false },
      co2Res2Enabled: { value: this.upcv3.reservesParameters.co2Res2Enabled, changed: false },
      co2Res2ActVol: { value: Math.round(this.upcv3.reservesParameters.co2Res2ActVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false }, // Convert litter to kg


      // Adjustments
      co2ResActAdj: { value: this.upcv3.reservesParameters.co2ResActAdj, changed: false },
      upcDiffLvlAdj: { value: this.upcv3.diffusionParameters.upcDiffLvlAdj, changed: false },
      co2FlowRefAdj: { value: this.upcv3.generalParameters.co2FlowRefAdj, changed: false },

      calcRefFlowRate: { value: this.upcv3.generalParameters.co2FlowRefAdj * this.upcv3.diffusionParameters.upcCo2DiffLvl / 10 },

      co2PresOutRef1: { value: this.upcv3.generalParameters.co2PresOutRef1 / 1000, changed: false },
      co2PresOutRef2: { value: this.upcv3.generalParameters.co2PresOutRef2 / 1000, changed: false },
      co2PresOutRef3: { value: this.upcv3.generalParameters.co2PresOutRef3 / 1000, changed: false },
      co2PresOutRef4: { value: this.upcv3.generalParameters.co2PresOutRef4 / 1000, changed: false },
      co2PresOutRef5: { value: this.upcv3.generalParameters.co2PresOutRef5 / 1000, changed: false },
      co2PresOutRef6: { value: this.upcv3.generalParameters.co2PresOutRef6 / 1000, changed: false },
      co2PresOutRef7: { value: this.upcv3.generalParameters.co2PresOutRef7 / 1000, changed: false },
      co2PresOutRef8: { value: this.upcv3.generalParameters.co2PresOutRef8 / 1000, changed: false },
      co2PresOutRef9: { value: this.upcv3.generalParameters.co2PresOutRef9 / 1000, changed: false },
      co2PresOutRef10: { value: this.upcv3.generalParameters.co2PresOutRef10 / 1000, changed: false },

      co2Res1StartVol: { value: Math.round(this.upcv3.reservesParameters.co2Res1StartVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false },
      co2Res2StartVol: { value: Math.round(this.upcv3.reservesParameters.co2Res2StartVol * CO2_LITERS_TO_KG_RATIO * 1000) / 1000, changed: false },
      // Programs
      daysCode_1: { value: this.upcv3.diffusionParameters.diffCo2Program1Temp.daysCode, changed: false },
      start_1: { value: this.upcv3.diffusionParameters.diffCo2Program1Temp.start.substr(0, 5), changed: false },
      end_1: { value: this.upcv3.diffusionParameters.diffCo2Program1Temp.end.substr(0, 5), changed: false },
      intensity_1: { value: this.upcv3.diffusionParameters.diffCo2Program1Temp.intensity, changed: false },

      daysCode_2: { value: this.upcv3.diffusionParameters.diffCo2Program2Temp.daysCode, changed: false },
      start_2: { value: this.upcv3.diffusionParameters.diffCo2Program2Temp.start.substr(0, 5), changed: false },
      end_2: { value: this.upcv3.diffusionParameters.diffCo2Program2Temp.end.substr(0, 5), changed: false },
      intensity_2: { value: this.upcv3.diffusionParameters.diffCo2Program2Temp.intensity, changed: false },

      daysCode_3: { value: this.upcv3.diffusionParameters.diffCo2Program3Temp.daysCode, changed: false },
      start_3: { value: this.upcv3.diffusionParameters.diffCo2Program3Temp.start.substr(0, 5), changed: false },
      end_3: { value: this.upcv3.diffusionParameters.diffCo2Program3Temp.end.substr(0, 5), changed: false },
      intensity_3: { value: this.upcv3.diffusionParameters.diffCo2Program3Temp.intensity, changed: false },

      daysCode_4: { value: this.upcv3.diffusionParameters.diffCo2Program4Temp.daysCode, changed: false },
      start_4: { value: this.upcv3.diffusionParameters.diffCo2Program4Temp.start.substr(0, 5), changed: false },
      end_4: { value: this.upcv3.diffusionParameters.diffCo2Program4Temp.end.substr(0, 5), changed: false },
      intensity_4: { value: this.upcv3.diffusionParameters.diffCo2Program4Temp.intensity, changed: false },

      daysCode_5: { value: this.upcv3.diffusionParameters.diffCo2Program5Temp.daysCode, changed: false },
      start_5: { value: this.upcv3.diffusionParameters.diffCo2Program5Temp.start.substr(0, 5), changed: false },
      end_5: { value: this.upcv3.diffusionParameters.diffCo2Program5Temp.end.substr(0, 5), changed: false },
      intensity_5: { value: this.upcv3.diffusionParameters.diffCo2Program5Temp.intensity, changed: false },

      daysCode_6: { value: this.upcv3.diffusionParameters.diffCo2Program6Temp.daysCode, changed: false },
      start_6: { value: this.upcv3.diffusionParameters.diffCo2Program6Temp.start.substr(0, 5), changed: false },
      end_6: { value: this.upcv3.diffusionParameters.diffCo2Program6Temp.end.substr(0, 5), changed: false },
      intensity_6: { value: this.upcv3.diffusionParameters.diffCo2Program6Temp.intensity, changed: false },

      daysCode_7: { value: this.upcv3.diffusionParameters.diffCo2Program7Temp.daysCode, changed: false },
      start_7: { value: this.upcv3.diffusionParameters.diffCo2Program7Temp.start.substr(0, 5), changed: false },
      end_7: { value: this.upcv3.diffusionParameters.diffCo2Program7Temp.end.substr(0, 5), changed: false },
      intensity_7: { value: this.upcv3.diffusionParameters.diffCo2Program7Temp.intensity, changed: false },

      daysCode_8: { value: this.upcv3.diffusionParameters.diffCo2Program8Temp.daysCode, changed: false },
      start_8: { value: this.upcv3.diffusionParameters.diffCo2Program8Temp.start.substr(0, 5), changed: false },
      end_8: { value: this.upcv3.diffusionParameters.diffCo2Program8Temp.end.substr(0, 5), changed: false },
      intensity_8: { value: this.upcv3.diffusionParameters.diffCo2Program8Temp.intensity, changed: false },

      daysCode_9: { value: this.upcv3.diffusionParameters.diffCo2Program9Temp.daysCode, changed: false },
      start_9: { value: this.upcv3.diffusionParameters.diffCo2Program9Temp.start.substr(0, 5), changed: false },
      end_9: { value: this.upcv3.diffusionParameters.diffCo2Program9Temp.end.substr(0, 5), changed: false },
      intensity_9: { value: this.upcv3.diffusionParameters.diffCo2Program9Temp.intensity, changed: false },

      daysCode_10: { value: this.upcv3.diffusionParameters.diffCo2Program10Temp.daysCode, changed: false },
      start_10: { value: this.upcv3.diffusionParameters.diffCo2Program10Temp.start.substr(0, 5), changed: false },
      end_10: { value: this.upcv3.diffusionParameters.diffCo2Program10Temp.end.substr(0, 5), changed: false },
      intensity_10: { value: this.upcv3.diffusionParameters.diffCo2Program10Temp.intensity, changed: false },

      sunset_sign: { value: this.upcv3.diffusionParameters.diffCo2SunsetTemp.delay > 0 ? '+' : '-', changed: false },
      sunset_delay: { value: new Date(Math.abs(this.upcv3.diffusionParameters.diffCo2SunsetTemp.delay * 60) * 1000).toISOString().substr(11, 5), changed: false },
      sunset_duration: {
        value: new Date(Math.abs(this.upcv3.diffusionParameters.diffCo2SunsetTemp.duration * 60) * 1000).toISOString().substr(11, 5)
        , changed: false
      },
      sunset_intensity: { value: this.upcv3.diffusionParameters.diffCo2SunsetTemp.intensity, changed: false },

      sunrise_sign: { value: this.upcv3.diffusionParameters.diffCo2SunriseTemp.delay > 0 ? '+' : '-', changed: false },

      sunrise_delay: {
        value: new Date(Math.abs(this.upcv3.diffusionParameters.diffCo2SunriseTemp.delay * 60) * 1000).toISOString().substr(11, 5)
        , changed: false
      },
      sunrise_duration: {
        value: new Date(Math.abs(this.upcv3.diffusionParameters.diffCo2SunriseTemp.duration * 60) * 1000).toISOString().substr(11, 5)
        , changed: false
      },
      sunrise_intensity: { value: this.upcv3.diffusionParameters.diffCo2SunriseTemp.intensity, changed: false },



      // Alarms
      alrResLowEn: { value: this.upcv3.alarmsParameters.alrResLowEn, changed: false },
      alrResEmptyEn: { value: this.upcv3.alarmsParameters.alrResEmptyEn, changed: false },
      alrPresInpEn: { value: this.upcv3.alarmsParameters.alrPresInpEn, changed: false },
      alrPresOutEn: { value: this.upcv3.alarmsParameters.alrPresOutEn, changed: false },
      alrFlowAvgEn: { value: this.upcv3.alarmsParameters.alrFlowAvgEn, changed: false },
      alrPowBackEn: { value: this.upcv3.alarmsParameters.alrPowBackEn, changed: false },
      alrPowDownEn: { value: this.upcv3.alarmsParameters.alrPowDownEn, changed: false },

      alrResLowLevel: { value: this.upcv3.alarmsParameters.alrResLowLevel, changed: false },
      alrResEmptyFlow: { value: this.upcv3.alarmsParameters.alrResEmptyFlow, changed: false },
      alrPresInpTol: { value: this.upcv3.alarmsParameters.alrPresInpTol, changed: false },
      alrPresOutTol: { value: this.upcv3.alarmsParameters.alrPresOutTol, changed: false },
      alrFlowSetTol: { value: this.upcv3.alarmsParameters.alrFlowSetTol, changed: false },
      alrResEmptyTest: { value: this.upcv3.alarmsParameters.alrResEmptyTest, changed: false },
      alrSmsNum1: { value: this.upcv3.alarmsParameters.alrSmsNum1, changed: false },
      alrSmsNum2: { value: this.upcv3.alarmsParameters.alrSmsNum2, changed: false },
      alrSmsNum3: { value: this.upcv3.alarmsParameters.alrSmsNum3, changed: false },
      alrSmsNum4: { value: this.upcv3.alarmsParameters.alrSmsNum4, changed: false },
      alrSmsNum5: { value: this.upcv3.alarmsParameters.alrSmsNum5, changed: false },



      // Extras
      upcTimeZone: { value: this.upcv3.generalParameters.upcTimeZone, changed: false },
      upcLanguage: { value: this.upcv3.generalParameters.upcLanguage, changed: false },
      upcTrapNum: { value: this.upcv3.generalParameters.upcTrapNum, changed: false },
      co2FlowRefTrap: { value: this.upcv3.generalParameters.co2FlowRefTrap, changed: false },
      co2FlowRefNom: { value: this.upcv3.generalParameters.co2FlowRefNom, changed: false },
      upcCo2PidProp: { value: this.upcv3.generalParameters.upcCo2PidProp, changed: false },
      upcCo2PidInteg: { value: this.upcv3.generalParameters.upcCo2PidInteg, changed: false },
      upcCo2PidDiff: { value: this.upcv3.generalParameters.upcCo2PidDiff, changed: false },

      comGsmName: { value: this.upcv3.communicationParameters.comGsmName, changed: false },
      comGsmPass: { value: this.upcv3.communicationParameters.comGsmPass, changed: false },
      comWiFiName: { value: this.upcv3.communicationParameters.comWiFiName, changed: false },
      comWiFiPass: { value: this.upcv3.communicationParameters.comWiFiPass, changed: false },
      comWifiApCh: { value: this.upcv3.communicationParameters.comWifiApCh, changed: false },
      comWebSrvUrl: { value: this.upcv3.communicationParameters.comWebSrvUrl, changed: false },
      comMdmApnId: { value: this.upcv3.communicationParameters.comMdmApnId, changed: false },
      comMdmApnId2: { value: this.upcv3.communicationParameters.comMdmApnId2, changed: false },
      comMdmApnUser: { value: this.upcv3.communicationParameters.comMdmApnUser, changed: false },
      comMdmApnPass: { value: this.upcv3.communicationParameters.comMdmApnPass, changed: false },

      co2PressInpOffs: { value: this.upcv3.diffusionParameters.co2PressInpOffs, changed: false },
      co2PressOutOffs: { value: this.upcv3.diffusionParameters.co2PressOutOffs, changed: false },
      co2FlowOffs: { value: this.upcv3.diffusionParameters.co2FlowOffs, changed: false }
    }

    this.calculateConsumption();
  }

  /* Refill */
  totalConsumedCalculation() {
    function dateToString(date: Date) {
      return date.getFullYear() + '-' + ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) + '-' + (date.getDate() > 9 ? date.getDate() : '0' + date.getDate())
    }

    if (this.refillInterventionDatetime && this.refillInterventionDatetime.length > 0 && new Date(this.refillInterventionDatetime).getFullYear() >= 1970) {
      // Get content stats
      this.upcv3Service.getContentStats(this.upcv3.id, dateToString(new Date(this.refillInterventionDatetime)), dateToString(new Date())).pipe(takeUntil(this.onDestroy$)).subscribe({
        next: (res) => {
          this.refillTotalConsumed = 0;
          res.forEach((data, i) => {
            if (i == 0) return;
            // B1
            if (this.refillReserve == 1) {
              // Check for refill
              if (data.b1 > res[i - 1].b1) this.refillTotalConsumed = 0;
              else this.refillTotalConsumed += res[i - 1].b1 - data.b1;
            }
            // B2
            else if (this.refillReserve == 2) {
              // Check for refill
              if (data.b2 > res[i - 1].b2) this.refillTotalConsumed = 0;
              else this.refillTotalConsumed += res[i - 1].b2 - data.b2;
            }
          });
        },
        error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des statistiques de contenues")
      })
    }
  }

  /* Offsets */
  restoreOffsets() {
    this.upcv3Service.getOffsetsFromTests(this.upcv3.id).subscribe(
      res => {

        switch (res.code) {

          case Code.UPCV3_RECOVERED:

            // Pin
            this.vars.co2PressInpOffs.value = res.result.co2PressInpOffs;
            this.vars.co2PressInpOffs.changed = true;

            // Pout
            this.vars.co2PressOutOffs.value = res.result.co2PressOutOffs;
            this.vars.co2PressOutOffs.changed = true;

            // Flow
            this.vars.co2FlowOffs.value = res.result.co2FlowOffs;
            this.vars.co2FlowOffs.changed = true;

            break;

          case Code.UNAUTHORIZED:
          case Code.UPCV3_DOESNT_EXSIST:
            break;

        }
      }
    );
  }
  onVersions() {
    var versionModal = this.modalService.open(VersionmodalComponent, {
      backdrop: 'static',
      keyboard: false
    });
    versionModal.componentInstance.init({}, versionModal, () => { });

    versionModal.result.then((data) => {
      if (localStorage.getItem("idversion")) {
        this.syncProject(localStorage.getItem("idversion"));
      }
    })
    //versionModal.componentInstance.init({})
  }
  /* Project */
  syncProject(belt) {

    // Get belt


    this.versionService.getBelt(belt).subscribe(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
            this.vars.co2PresOutRef1 = { value: Math.round(res.result.pressureInt1 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef2 = { value: Math.round(res.result.pressureInt2 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef3 = { value: Math.round(res.result.pressureInt3 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef4 = { value: Math.round(res.result.pressureInt4 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef5 = { value: Math.round(res.result.pressureInt5 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef6 = { value: Math.round(res.result.pressureInt6 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef7 = { value: Math.round(res.result.pressureInt7 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef8 = { value: Math.round(res.result.pressureInt8 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef9 = { value: Math.round(res.result.pressureInt9 * 1000) / 1000, changed: true };
            this.vars.co2PresOutRef10 = { value: Math.round(res.result.pressureCalculated * 1000) / 1000, changed: true };

            this.vars.upcTrapNum = { value: res.result.trapsNumber, changed: true };

            this.vars.co2FlowRefNom = { value: Math.round(res.result.trapsNumber * this.vars.co2FlowRefTrap.value * 10) / 10, changed: true };
            this.vars.co2FlowRefAdj = { value: Math.round(res.result.trapsNumber * this.vars.co2FlowRefTrap.value * 10) / 10, changed: true };
            break;
        }
      }
    );
  }
}