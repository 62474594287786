import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { GetAllTestsParams, UpcMcuService } from '../../services/upcv3/upcmcu.service';

import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalWithUpcMcuTestsComponent } from './modalWithUpcMcuTests/modalWithUpcMcuTests';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { UpcMcu } from 'src/app/models/upcv3/upc/upcMcu';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, finalize, map, startWith, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';


interface TableRowDataFormat extends UpcMcu {
  first_test: Date;
  last_test: Date;
}

interface TableDataFormat extends Array<TableRowDataFormat> { }

@Component({
  selector: 'app-upcmcus',
  templateUrl: './upcMcuTable.component.html',
  styleUrls: ['./upcMcuTable.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UpcMcuTableComponent implements OnInit, AfterViewInit {
  filterInputFormControl = new FormControl('');
  filterInputValue = '';
  displayedColumns = ['upcMcuUid', 'id_boitier', 'upcv3Name', 'first_test', 'last_test'];
  datasource = new MatTableDataSource();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  totalData: number;
  pageSizeOptions = [10, 50, 100];

  filterMcuNotAssociated = false;
  isLoadingResults = true;

  private _upcMcuService: UpcMcuService;

  private getAllTestsParams: GetAllTestsParams;

  constructor(
    upcMcuService: UpcMcuService,
    private modalService: NgbModal,
    private toasterService: ToasterService
  ) {
    this._upcMcuService = upcMcuService;
  }

  protected getMostRecentDate(date1: Date, date2: Date) {
    if (date1 == null && date2 == null) {
      return null;
    }
    if ((date1 !== null) !== (date2 !== null)) {   // XOR
      return (date1 == null ? date2 : date1);
    }
    return date1 > date2 ? date1 : date2;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.datasource.paginator = this.paginator;
    this.paginator.page.pipe(
      startWith({}),
      switchMap(() => {
        this.getAllTestsParams = {...this.getAllTestsParams, page: this.paginator.pageIndex, size: this.paginator.pageSize};
        this.isLoadingResults = true;
        return this._upcMcuService.getAllTests(this.getAllTestsParams);
      }),
      map((testsData) => {
        if (testsData == null) return [];
        this.totalData = testsData.totalElements;
        const internalData = testsData.content.map((data) => {
          let firstTestBox, lastTestBox, firstTestCard, lastTestCard;
          if (data.cardTests.length === 0) {
            firstTestCard = null;
            lastTestCard = null;
          } else {
            firstTestCard = data.cardTests.reduce((prev, current) => {
              return prev.date < current.date ? prev : current;
            }).date;
  
            lastTestCard = data.cardTests.reduce((prev, current) => {
              return prev.date > current.date ? prev : current;
            }).date;
          }

          if (data.boxTests.length === 0) {
            firstTestBox = null;
            lastTestBox = null;
          } else {
            firstTestBox = new Date(data.boxTests.reduce((prev, current) => {
              return prev.date < current.date ? prev : current;
            }).date);
  
            lastTestBox = new Date(data.boxTests.reduce((prev, current) => {
              return prev.date > current.date ? prev : current;
            }).date);
          }

          return {
            ...data,
            first_test: this.getMostRecentDate(firstTestCard, firstTestBox),
            last_test: this.getMostRecentDate(lastTestCard, lastTestBox),
            id_boitier: data.boxTests.length === 0 ? '' : data.boxTests.reduce((prev, curr) => (prev.date > curr.date) ? prev : curr).upcBoxId
          };
        }).sort((a, b) => a.last_test < b.last_test ? 1 : -1);

        return internalData;
      })
    ).pipe(
      finalize(() => this.isLoadingResults = false)
    ).subscribe((data) => {
      this.datasource = new MatTableDataSource(data);
      this.isLoadingResults = false;
    }, (error) => {
      this.toasterService.pop('error', 'Erreur de communication avec l\'API');
    });

    this.filterInputFormControl.valueChanges.pipe(
      debounceTime(300)
    ).subscribe((value: string) => {
      this.refreshTable({...this.getAllTestsParams, upcMcuUid: value.trim(), upcNameId: value, boxName: value});
    })
  }

  refreshTable(getAllTestsParams: GetAllTestsParams) {
    this.getAllTestsParams = getAllTestsParams;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.paginator.firstPage();
  }

  sortData(sort: Sort) {
    const data = [...this.datasource.data];

    if (!sort.active || sort.direction === '') {
      return;
    }

    this.datasource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active], b[sort.active], isAsc) ?? 0;
    });
  }
  
  toggleFilterMcuNotAssociatedToBelt(): void {
    this.filterMcuNotAssociated = !this.filterMcuNotAssociated;
    this.refreshTable({...this.getAllTestsParams, hasUpcv3: this.filterMcuNotAssociated ? false : undefined});
  }

  getTableEntry(row: TableRowDataFormat): void {
    this.displayTestModal(row);
  }

  displayTestModal(upcMcu) {
    const modalRef = this.modalService.open(ModalWithUpcMcuTestsComponent, { size: <any>'xl', windowClass: 'test-modal' });
    modalRef.componentInstance.upcMcu = upcMcu;
  }
}


function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}