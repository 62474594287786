<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Réglages</h4>
    </div>

    <!-- Tabs -->
    <div class="col-12 mb-3">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">

        <!-- User -->
        <li [ngbNavItem]="1">
          <a ngbNavLink>Paramètres du compte</a>
          <ng-template ngbNavContent>
            <app-settings-user></app-settings-user>
          </ng-template>
        </li>

        <!-- Firmwares -->
        <li [ngbNavItem]="2" *ngIf="authService.isAdmin">
          <a ngbNavLink>Firmwares UPC-V3</a>
          <ng-template ngbNavContent>
            <app-settings-firmwares></app-settings-firmwares>
          </ng-template>
        </li>

        <!-- Default belt params -->
        <li [ngbNavItem]="3" *ngIf="authService.isAdmin">
        <a ngbNavLink>Paramètres par défaut UPC-V3</a>
          <ng-template ngbNavContent>
            <app-settings-default-params></app-settings-default-params>
          </ng-template>
        </li>

        <!-- Delete UPCV3 -->
        <li [ngbNavItem]="4" *ngIf="authService.isAdmin">
        <a ngbNavLink>Suppression des UPC-V3</a>
          <ng-template ngbNavContent>
            <app-settings-delete-upcv3></app-settings-delete-upcv3>
          </ng-template>
        </li>

        <li [ngbNavItem]="5" *ngIf="authService.isAdmin">
        <a ngbNavLink>Bilan de consommations</a>  
          <ng-template ngbNavContent>
            <app-bilan-consommations></app-bilan-consommations>
          </ng-template>
        </li>

      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>

  </div>
</div>
