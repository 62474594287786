import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Site } from '../../models/management/site';
import { UPCV2 } from 'src/app/models/upcv2/upcv2';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import * as _ from "lodash";
import { ApiResponse, Code } from 'src/app/models/ApiResponse';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  // Codes: SITE_RECOVERED | UNAUTHORIZED
  public getMap(): Observable<ApiResponse<Site[]>> {
    return this.http.get<ApiResponse<Site[]>>(environment.apiUrl + 'dashboard/map').pipe(map(
      res => {
        switch (res.code) {
          
          case Code.SITE_RECOVERED:
          var sites: Site[] = [];
          res.result.forEach(jsonSite => sites.push(Site.loadFromJSON(jsonSite)));
          res.result = sites;
          break;

          case Code.UNAUTHORIZED:
          break;
        
        }

        return res;
      }
    ))
  }

  // Codes: UPCV3_RECOVERED | UNAUTHORIZED
  public getUpcv3(options : {archived?: boolean; upcWithoutGroup?: boolean} = {}): Observable<ApiResponse<UPCV3[]>> {
    let params = new HttpParams();
    params = options.archived != null ? params.append("archived", options.archived) : params;
    params = options.upcWithoutGroup != null ? params.append("upcWithoutGroup", options.upcWithoutGroup) : params;

    return this.http.get<ApiResponse<UPCV3[]>>(environment.apiUrl + "dashboard/upcv3", {params}).pipe(map(
      res => {
        switch (res.code) {
          
          case Code.UPCV3_RECOVERED:
          var upcv3: UPCV3[] = [];
          res.result.forEach(jsonUpcv3 => upcv3.push(UPCV3.loadFromJSON(jsonUpcv3)));
          res.result = upcv3;
          break;

          case Code.UNAUTHORIZED:
          break;
        
        }

        return res;
      }
    ))
  }

  public getUpcv3InGroupOnly(options : {archived?: boolean} = {}): Observable<{[index: number]: UPCV3[]}> {
    let params = new HttpParams();
    params = options.archived != null ? params.append("archived", options.archived) : params;

    return this.http.get<ApiResponse<UPCV3[]>>(environment.apiUrl + "dashboard/upcv3InGroupOnly", {params}).pipe(map(
      res => {
        switch (res.code) {
          
          case Code.UPCV3_RECOVERED:
          var upcv3: UPCV3[] = [];
          res.result.forEach(jsonUpcv3 => upcv3.push(UPCV3.loadFromJSON(jsonUpcv3)));
          res.result = upcv3;
          break;

          case Code.UNAUTHORIZED:
          break;
        
        }
        return _.groupBy(upcv3, "group");
      }
    ))
  }

}
