import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Pages
import { DesignersComponent } from "./designers.component";

// Modals
import { AddDesignerModalComponent } from "./add-designer/add-designer.component";
import { EditDesignerModalComponent } from "./edit-designer/edit-designer.component";
import { DeleteDesignerModalComponent } from "./delete-designer/delete-designer.component";

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  declarations: [
    // Pages
    DesignersComponent,
    // Modals
    AddDesignerModalComponent,
    EditDesignerModalComponent,
    DeleteDesignerModalComponent
  ]
})
export class DesignersModule { }