import { Node } from "./node";

export class Path {
  id: string = '';

	color: string = '';
	width: number = 0;
	dashed: boolean = false;

	diameter: number = 0;

	from: Node;
  to: Node;

  // UI
  selected: boolean = false;
  get calculatedTitle(): string { return this.from.calculatedTitle + ' vers ' + this.to.calculatedTitle }
  get distance(): number {
    function sqr(a) { return a * a }
    return Math.sqrt(sqr(this.to.x - this.from.x) + sqr(this.to.y - this.from.y));
  }
  get midpoint() { 
    return {
      x: ((this.from.x + this.to.x) / 2),// - this.width,
      y: ((this.from.y + this.to.y) / 2)// - this.width
    };
  } 
  
  static loadFromJSON(json): Path {
    var path: Path = Object.assign(new Path, json);
    if (json.from) path.from = Node.loadFromJSON(json.from);
    if (json.to) path.to = Node.loadFromJSON(json.to);
    return path;
  }
}