import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { Modal } from 'src/app/models/ui/modal';
import { InterventionV3Service } from 'src/app/services/upcv3/interventionV3.service';
import { Commentaires } from 'src/app/models/upcv3/commentaire';

@Component({
  selector: 'app-interventionhistory',
  templateUrl: './interventionhistory.component.html',
  styleUrls: ['./interventionhistory.component.css']
})
export class InterventionhistoryComponent extends Modal<any> implements OnInit  {
  comments :any;
  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  constructor(private interventionV3Service : InterventionV3Service) {
    super();
   }

  ngOnInit() {
    
  }

  
}
