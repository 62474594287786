<div class="card mt-3 p-3">
  <div class="row">

    <!-- Title -->
    <div class="col-12 mb-3">
      <h4 class="float-left">UPC-V3</h4>
    </div>

    <!-- List -->
    <div class="col-12">
      <div class="list-group">
        <!-- Content Header (hidden on mobile) -->
        <a class="list-group-item list-group-item-action d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <small>Nom</small>
            </div>
            <div class="col-2">
              <small class="float-right">Actions</small>
            </div>
          </div>
        </a>
        <a class="list-group-item list-group-item-action text-center" *ngIf="!upcv3"><i class="fa fa-circle-o-notch fa-spin"></i></a>
        <a class="list-group-item list-group-item-action text-center" *ngIf="upcv3 && upcv3?.length == 0">Aucun UPC-V3</a>

        <ng-container *ngIf="upcv3">
          <a class="list-group-item list-group-item-action" *ngFor="let upc of upcv3" style="word-wrap: break-word;">
            <div class="row">
              <div class="col-10">
                <h6 class="mb-0 mt-1"><small class="d-md-none mr-2">upc</small>{{ upc.upcNameId }}</h6>
              </div>

              <div class="col-2">
                <button class="btn btn-danger btn-sm m-1 float-right" (click)="openDeleteUPCV3Modal(upc)" placement="top" ngbTooltip="Supprimer" ngbButton><i class="fa fa-trash"></i></button>
              </div>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>