import { jsonMember, jsonArrayMember, jsonObject, TypedJSON } from 'typedjson';
import { Stock } from "./stock";
import { UPCV3 } from "../upcv3/upc/upcv3";
import { Operator } from './operator';
import { Project } from '../project/project';
import { InterventionV3 } from '../upcv3/interventionV3';
import { Commentaires } from '../upcv3/commentaire';
import { GroupUPCV3 } from '../GroupUPCV3/GroupUPCV3';
import { Location } from "./location";
import _ from 'lodash';

///TODO : Les paramètres généré par la classe sont à revoir, la logique semble fausse 


@jsonObject
export class Site extends Location {
  @jsonMember
  clientNumber: string;
  
  @jsonMember
  infos: string;
  
  @jsonMember
  latitude: number;
  
  @jsonMember
  longitude: number;
  
  @jsonArrayMember(() => Stock)
  stocks: Array<Stock>;
  
  @jsonArrayMember(() => Operator)
  operators: Array<Operator>;
  
  @jsonMember(() => Project)
  project: Project;
  
  @jsonArrayMember(() => UPCV3)
  upcv3s: Array<UPCV3>;

  @jsonArrayMember(() => GroupUPCV3)
  groupUPCV3s: GroupUPCV3[];

  @jsonMember
  storedBottlesCount: number;
    
  get state(): State {
    var state: State = State.RESERVE_1;

    // If all belts hibernating
    var hibernating = true;
    this.upcv3s.forEach(upcv3 => { if (!upcv3.hibernated) hibernating = false });

    // If a belt is in B2
    var b2 = false;
    this.upcv3s.forEach(upcv3 => { if (!upcv3.hibernated && (upcv3.reservesParameters.co2Res1Status == 0 || upcv3.reservesParameters.co2Res2Status == 0)) b2 = true });

    // If a belt is in B3 OR DISABLE OR EMPTY
    var disable = false;
    this.upcv3s.forEach(upcv3 => { if (!upcv3.hibernated && (upcv3.reservesParameters.co2Res1Status == 0 && upcv3.reservesParameters.co2Res2Status == 0)) disable = true });

    if (hibernating)
      state = State.HIBERNATING;
    
    if (b2)
      state = State.RESERVE_2;

    if (disable)
      state = State.DISABLE;

    return state;
  }

  get communicationState(): boolean {
    var state = true;

    this.upcv3s.forEach(upcv3 => {
      if (!upcv3.hibernated && upcv3.lastPollResult != 0)
        state = false
    });

    return state;
  }

  get hasBelts(): boolean {
    return this.upcv3s.length > 0;
  }

  get stocksString(): string {
    return this.stocks.map(s => s.name).join(' | ');
  }
  
  static loadFromJSON(json): Site {
    let serializer = new TypedJSON(Site);
    return serializer.parse(json);
  }
}

export enum State {
  HIBERNATING,
  RESERVE_1,
  RESERVE_2,
  DISABLE
}