<div class="col-12 text-center">
  <p class="text-center" *ngIf="diffStats && diffStats.length == 0">
    Aucune donnée
  </p>
  <h2>{{upcv3.upcNameId}}</h2>
  <div style="height: 500px" *ngIf="diffStats && diffStats.length > 0">
    <ngx-charts-bar-vertical-stacked
      [results]="diffChartData"
      [customColors]="diffChartColors"
      [xAxis]="true"
      [yAxis]="true"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div class="p-1">
          {{ model.name }}
          <br />
          {{ model.content }}
        </div>
      </ng-template>
    </ngx-charts-bar-vertical-stacked>
  </div>
</div>
