<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Gestion des projets</h4>
      <button type="button" class="btn btn-primary float-right" (click)="openAddProjectModal()"><i class="fa fa-plus"></i> Projet</button>
    </div>

    <!-- Size -->
    <div class="col-sm-6 col-lg-3 mb-2">
      <ng-select  [items]="pageSizes"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                  [(ngModel)]="pageSize"
                  (change)="setPage(1, pageSize, filter)">
      </ng-select>
    </div>

    <!-- Search -->
    <div class="col-sm-6 col-lg-4 offset-lg-5 mb-2">
      <form [formGroup]="searchForm" (ngSubmit)="onSearchFormSubmit()">
        <div class="input-group">
          <input type="text" class="form-control" formControlName="filter" placeholder="Rechercher" (keyup)="clearSearch($event)" [ngClass]="{ 'is-invalid': searchFormSubmitted && sf.filter.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-primary" type="submit"><i class="fa fa-search"></i></button>
          </div>
          <div *ngIf="searchFormSubmitted && sf.filter.errors" class="invalid-feedback">
            <div *ngIf="sf.filter.errors.minlength">3 caractères minimum</div>
          </div>
        </div>
      </form>
    </div>

    <!-- Content -->
    <div class="col-12 mb-2">
      <ul class="list-group list-group-striped">

        <!-- Header -->
        <li class="list-group-item d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-4 mb-0 font-weight-bold">Nom</p>
                <p class="col-3 mb-0 font-weight-bold">Client</p>
                <p class="col-5 mb-0 font-weight-bold">Adresse</p>
              </div>
            </div>
            <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
          </div>
        </li>

        <!-- Loading & Empty -->
        <li class="list-group-item px-3 py-2 text-center" *ngIf="!projects"><i class="fa fa-circle-o-notch fa-spin"></i></li>
        <li class="list-group-item px-3 py-2 text-center" *ngIf="projects && projects?.length == 0">Aucun projet</li>

        <!-- Row -->
        <li class="list-group-item px-3 py-1" *ngFor="let project of projects">
          <div class="row">
            <div class="col-10">
              <a class="row no-link" routerLink="{{ project.id }}">
                <p class="col-md-4 mt-1 mb-0 font-weight-bold"><small class="d-md-none mr-2">Nom</small>{{ project.name }}</p>
                <p class="col-md-3 mt-1 mb-0"><small class="d-md-none mr-2">Client</small>{{ project.client }}</p>
                <p class="col-md-5 mt-1 mb-0"><small class="d-md-none mr-2">Adresse</small>{{ project.address }}</p>
              </a>
            </div>
            <div class="col-2 mb-0 text-right">
              <div placement="bottom-right" ngbDropdown>
                <button class="btn btn-link text-dark px-3 py-0" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
                <div class="p-0" ngbDropdownMenu>
                  <button class="dropdown-item px-3 py-2" (click)="openEditProjectModal(project)"><i class="fa fa-edit"></i> Modifier</button>
                  <button class="dropdown-item px-3 py-2" (click)="openDeleteProjectModal(project)" *ngIf="authService.isAdmin"><i class="fa fa-trash"></i> Supprimer</button>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>

    <!-- Pagination -->
    <div class="col-12 mb-3">
      <nav>
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end">
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="setPage(pager.currentPage - 1)" tabindex="-1">Précédent</a>
          </li>
          
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="setPage(page)">{{ page }}</a>
          </li>
          
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="setPage(pager.currentPage + 1)">Suivant</a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</div>