import { Component, OnInit, Input } from '@angular/core';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToasterService } from 'angular2-toaster';
import * as moment from "moment";

@Component({
  selector: 'app-upc-stats',
  templateUrl: './stats.component.html'
})
export class StatsComponent implements OnInit {
  @Input('upcv3') upcv3: UPCV3;

  // Stats
  wrongRange: boolean = false;
  from: NgbDate;
  to: NgbDate;
  max: NgbDate;
  private onDestroy$: Subject<void> = new Subject<void>();
  // Content Stats
  contentChartData = [
    { name: 'B1', series: [] },
    { name: 'B2', series: [] }
  ];

  // Conso Stats
  consoTotal = 0;
  consoChartData = [
    { name: 'Consommation', series: [] }
  ];

  // Diff Stats
  diffChartData = [];
  diffChartColors = [
    { name: 'Aucune diffusion', value: 'rgba(0, 0, 0, 0)' },
    { name: 'Intensité : 1', value: 'rgba(0, 0, 0, .1)' },
    { name: 'Intensité : 2', value: 'rgba(0, 0, 0, .2)' },
    { name: 'Intensité : 3', value: 'rgba(0, 0, 0, .3)' },
    { name: 'Intensité : 4', value: 'rgba(0, 0, 0, .4)' },
    { name: 'Intensité : 5', value: 'rgba(0, 0, 0, .5)' },
    { name: 'Intensité : 6', value: 'rgba(0, 0, 0, .6)' },
    { name: 'Intensité : 7', value: 'rgba(0, 0, 0, .7)' },
    { name: 'Intensité : 8', value: 'rgba(0, 0, 0, .8)' },
    { name: 'Intensité : 9', value: 'rgba(0, 0, 0, .9)' },
    { name: 'Intensité : 10', value: 'rgba(0, 0, 0, 1)' }
  ];

  // Table Stats (s1 - s2 - s3 - s4 - intensity - conso)
  tableStats = [
    [0, 0, 0, 0, 0, 0], // January
    [0, 0, 0, 0, 0, 0], // February
    [0, 0, 0, 0, 0, 0], // March
    [0, 0, 0, 0, 0, 0], // April
    [0, 0, 0, 0, 0, 0], // May
    [0, 0, 0, 0, 0, 0], // June
    [0, 0, 0, 0, 0, 0], // July
    [0, 0, 0, 0, 0, 0], // August
    [0, 0, 0, 0, 0, 0], // September
    [0, 0, 0, 0, 0, 0], // October
    [0, 0, 0, 0, 0, 0], // November
    [0, 0, 0, 0, 0, 0], // December
  ];

  constructor(
    public calendar: NgbCalendar,
    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService,
  ) {
    this.from = calendar.getPrev(calendar.getToday(), 'd', 28);
    this.to = calendar.getToday();
    this.max = calendar.getToday();
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  ngOnInit() {
    this.loadContentStats();
    this.loadConsoStats();
    this.loadDiffusionsStats();
    this.loadTableStats();
  }

  /*
   *  Nav Event
   */

  navChanged() {
    // https://github.com/swimlane/ngx-charts/issues/650
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  /*
   * DatePicker
   */
  onDateSelection() {
    if (!this.calendar.isValid(this.from) || !this.calendar.isValid(this.to)) return;

    if (new NgbDate(this.from.year, this.from.month, this.from.day).after(this.to)) {
      this.wrongRange = true;
      return;
    } else this.wrongRange = false;

    this.loadContentStats();
    this.loadConsoStats();
    this.loadDiffusionsStats();
    this.loadTableStats();
  }
  dateToString(date: NgbDate) {
    return date.year + '-' + (date.month > 9 ? date.month : '0' + date.month) + '-' + (date.day > 9 ? date.day : '0' + date.day)
  }

  /*
   * Stats
   */
  loadContentStats() {
    this.upcv3Service.getContentStats(this.upcv3.id, this.dateToString(this.from), this.dateToString(this.to)).pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (res) => {
        this.contentChartData[0].series = [];
        this.contentChartData[1].series = [];

        if (Array.isArray(res)) {
          res.forEach(data => {
            var date = new Date(data.date).toLocaleDateString();
            this.contentChartData[0].series.push({ name: date, value: data.b1 });
            this.contentChartData[1].series.push({ name: date, value: data.b2 });
          });
        }
        this.contentChartData = [...this.contentChartData];

      },
      error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des ContentStat")
    })

  }

  loadConsoStats() {
    this.upcv3Service.getConsoStats(this.upcv3.id, this.dateToString(this.from), this.dateToString(this.to)).pipe(takeUntil(this.onDestroy$)).subscribe({
      next: res => {
        this.consoTotal = res.reduce((accumulator, conso) => accumulator + conso.conso, 0);
        this.consoTotal = Math.round(this.consoTotal * 100) / 100;

        this.consoChartData[0].series = [];
        if (res) {
          this.consoChartData[0].series = res.map(data => {
            let date = new Date(data.date).toLocaleDateString();
            return { name: date, value: data.conso }
          })
        }
        this.consoChartData = [...this.consoChartData];
      },
      error: err => this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la récupération des statistiques de consommation")
    });
  }



  loadDiffusionsStats() {
    this.diffChartData = [];
    this.upcv3Service.getDiffStats(this.upcv3.id, this.dateToString(this.from), this.dateToString(this.to)).pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (data) => {
        if (Array.isArray(data)) {

          data.forEach(data => {
            var date = new Date(data.date).toLocaleDateString();
            var series = [];

            data.diffs.forEach((diff, index) => {
              var start = moment(diff.start, [moment.HTML5_FMT.TIME_SECONDS]);
              var stop = moment(diff.stop, [moment.HTML5_FMT.TIME_SECONDS]);
              // First diffusion of the day
              if (index == 0) {
                var dayStart = moment("00:00:00", [moment.HTML5_FMT.TIME_SECONDS]);
                series.push({ name: 'Aucune diffusion', value: Math.round((start.valueOf() - dayStart.valueOf()) / (1000 * 3600) * 100) / 100 });
                series.push({
                  name: 'Intensité : ' + diff.intensity,
                  content: 'Début : ' + start.toDate().toLocaleTimeString() + ' - Fin : ' + stop.toDate().toLocaleTimeString(),
                  value: Math.round((stop.valueOf() - start.valueOf()) / (1000 * 3600) * 100) / 100
                });
              }

              // Last diffusion of the day
              else if (index == data.diffs.length - 1) {
                var dayStop = moment("23:59:59", [moment.HTML5_FMT.TIME_SECONDS]);
                // Check previous
                var previous = data.diffs[index - 1];
                if (previous) {
                  var previousStop = moment(previous.stop, [moment.HTML5_FMT.TIME_SECONDS]);
                  series.push({ name: 'Aucune diffusion', value: Math.round((start.valueOf() - previousStop.valueOf()) / (1000 * 3600) * 100) / 100 });
                }

                series.push({
                  name: 'Intensité : ' + diff.intensity,
                  content: 'Début : ' + start.toDate().toLocaleTimeString() + ' - Fin : ' + stop.toDate().toLocaleTimeString(),
                  value: Math.round((stop.valueOf() - start.valueOf()) / (1000 * 3600) * 100) / 100
                });
                series.push({ name: 'Aucune diffusion', value: Math.round((dayStop.valueOf() - stop.valueOf()) / (1000 * 3600) * 100) / 100 });
              }

              // Inter diffusion
              else {
                var previous = data.diffs[index - 1];
                var previousStop = moment(previous.stop, [moment.HTML5_FMT.TIME_SECONDS]);
                series.push({ name: 'Aucune diffusion', value: Math.round((start.valueOf() - previousStop.valueOf()) / (1000 * 3600) * 100) / 100 });
                series.push({
                  name: 'Intensité : ' + diff.intensity,
                  content: 'Début : ' + start.toDate().toLocaleTimeString() + ' - Fin : ' + stop.toDate().toLocaleTimeString(),
                  value: Math.round((stop.valueOf() - start.valueOf()) / (1000 * 3600) * 100) / 100
                });
              }
            });

            this.diffChartData.push({ name: date, series: series });
          });
        }
      }
    })


  }

  loadTableStats() {
    this.upcv3Service.getTableStats(this.upcv3.id, this.from.year).pipe(takeUntil(this.onDestroy$)).subscribe({
      next: res => this.tableStats = res,
      error: err => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des tableStats")
        this.tableStats = [[0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0], [0, 0, 0, 0, 0, 0]];
      }
    })

  }

}