<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Nouveau Site</h4>
    </div>

    <!-- Project / Version selection -->
    <div class="col-12 col-md-3 mb-2">
      <div class="card">
        <div class="card-header font-weight-bold">Projet</div>
        <div class="card-body">

          <!-- Project -->
          <div class="form-group">
            <label>Projet</label>
            <ng-select  class="select-hidden-dropdown" placeholder="Sélectionnez le projet"
                        [items]="projects" bindLabel="name" bindValue="id"
                        [searchFn]="fakeSearch" (keyup)="searchProjects($event.target.value)"
                        [(ngModel)]="selectedProjectId" [ngModelOptions]="{ standalone: true }" (ngModelChange)="loadVersions()">
            </ng-select>
          </div>

          <!-- Version -->
          <div class="form-group mb-0">
            <label>Version</label>
            <ng-select  class="select-hidden-dropdown" placeholder="Sélectionnez la version"
                        [items]="versions" bindLabel="versionTypeToString" bindValue="id"
                        [disabled]="!selectedProjectId"
                        [(ngModel)]="selectedVersionId" [ngModelOptions]="{ standalone: true }" (ngModelChange)="versionSelected()">
              <ng-template ng-label-tmp ng-option-tmp let-item="item">{{ item.versionTypeToString }} - {{ item.numberOrder }}</ng-template>
            </ng-select>
          </div>

        </div>
      </div>
    </div>

    <!-- Form -->
    <div class="col-12 col-md-9 mb-3">
      <form [formGroup]="form" (ngSubmit)="onFormSubmit()">

        <!-- Site -->
        <div class="card mb-3">
          <div class="card-header font-weight-bold">Site</div>
        
          <!-- Loading -->
          <div class="card-body text-center" *ngIf="loadingVersion"><i class="fa fa-circle-o-notch fa-spin"></i></div>

          <!-- Inputs -->
          <ul class="list-group list-group-flush" *ngIf="!loadingVersion">
              
            <!-- General -->
            <li class="list-group-item">

              <!-- Client Number / Site Name -->
              <div class="form-row">
                <div class="form-group col-md-3">
                  <label>N° de client</label>
                  <input type="text" [ngClass]="{ 'is-invalid': f.clientNumber.errors }" class="form-control" placeholder="N° de client" formControlName="clientNumber">
                </div>
                <div class="form-group col-md-9">
                  <label>Nom du site *</label>
                  <input type="text" class="form-control" placeholder="Nom du site *" formControlName="name" [ngClass]="{ 'is-invalid': nameAlreadyUsed || f.name.errors }" (keyup)="nameAlreadyUsed = false">
                  <div *ngIf="nameAlreadyUsed || f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors && f.name.errors.required">Nom obligatoire</div>
                    <div *ngIf="nameAlreadyUsed">Nom déjà utilisé</div>
                  </div>
                </div>
              </div>

              <!-- Infos -->
              <div class="form-group">
                <label>Infos</label>
                <textarea class="form-control" rows="3" formControlName="infos"></textarea>
              </div>

            </li>

            <!-- Position -->
            <li class="list-group-item">

              <!-- Address -->
              <div class="form-group">
                <label>Adresse</label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Adresse" formControlName="address" [ngClass]="{ 'is-invalid': addressUnknow || f.address.errors }" (keyup)="addressUnknow = false">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" type="button" (click)="geolocate()">Géolocaliser</button>
                  </div>
                  <div *ngIf="addressUnknow || f.address.errors" class="invalid-feedback">
                    <div *ngIf="f.address.errors && f.address.errors.required">Adresse obligatoire</div>
                    <div *ngIf="addressUnknow">Adresse non trouvée</div>
                  </div>
                </div>
              </div>

              <!-- Lat / Lng -->
              <div class="form-row">

                <!-- Lat -->
                <div class="form-group col-md-6">
                  <label>Latitude</label>
                  <input type="number" class="form-control" placeholder="Latitude" [ngClass]="{ 'is-invalid':  f.lat.errors }" formControlName="lat">
                  <div *ngIf="f.lat.errors" class="invalid-feedback">
                    <div *ngIf="f.lat.errors && f.lat.errors.required">Latitude obligatoire</div>
                  </div>
                </div>

                <!-- Lng -->
                <div class="form-group col-md-6">
                  <label>Longitude</label>
                  <input type="number" class="form-control" placeholder="Longitude" [ngClass]="{ 'is-invalid':  f.lng.errors }" formControlName="lng">
                  <div *ngIf="f.lng.errors" class="invalid-feedback">
                    <div *ngIf="f.lng.errors && f.lng.errors.required">longitude obligatoire</div>
                  </div>
                </div>

              </div>

            </li>

            <!-- Stock -->
            <li class="list-group-item">
              <div class="form-group">
                <label>Stocks</label>
                <ng-select  class="select-hidden-dropdown" placeholder="Sélectionnez les stocks"
                            [items]="stocks"
                            bindLabel="name"
                            bindValue="id"
                            formControlName="stocks"
                            multiple="true">
                </ng-select>
                <div *ngIf="f.stocks.errors" class="invalid-feedback">
                  <div *ngIf="f.stocks.errors && f.stocks.errors.required">Stocks obligatoire</div>
                </div>
              </div>
            </li>
          
          </ul>

          <div class="card-footer" *ngIf="!selectedVersionId">
            <input type="button" class="btn btn-secondary" value="Annuler" routerLink="/sites" [disabled]="formSubmitted" ngbButton>
            <button type="submit" class="btn btn-success float-right" [disabled]="formSubmitted || !this.form.valid" ngbButton>
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
              <span *ngIf="!formSubmitted">Créer</span>
            </button>
          </div>
        </div>

        <!-- Belts -->
        <div class="card" *ngIf="selectedVersionId">
          <div class="card-header font-weight-bold">Ceintures</div>

          <!-- Loading -->
          <div class="card-body text-center" *ngIf="loadingVersion"><i class="fa fa-circle-o-notch fa-spin"></i></div>

          <!-- No belts -->
          <div class="card-body text-center" *ngIf="!loadingVersion && belts.length == 0">Aucune ceinture</div>

          <!-- Tabs -->
          <div class="card-body">
            <ngb-tabset type="pills">
              <ngb-tab title="{{ belt.upcNameId }}" *ngFor="let belt of belts">
                <ng-template ngbNavContent>

                  <!-- Inputs -->
                  <ul class="list-group mt-3">

                    <!-- General -->
                    <li class="list-group-item">
                      <h5 class="mb-3">Général</h5>

                      <!-- upcNameId & upcTrapNum -->
                      <div class="form-row">

                        <!-- upcNameId -->
                        <div class="form-group col-md-6">
                          <label>Identifiant</label>
                          <input type="text" class="form-control" placeholder="Identifiant" [(ngModel)]="belt.upcNameId" [ngModelOptions]="{ standalone: true }">
                        </div>

                        <!-- upcTrapNum -->
                        <div class="form-group col-md-6">
                          <label>Nombre de pièges</label>
                          <input type="number" class="form-control" placeholder="Nombre de pièges" [(ngModel)]="belt.generalParameters.upcTrapNum" [ngModelOptions]="{ standalone: true }">
                        </div>

                      </div>

                    </li>

                    <!-- Diffusion programs -->
                    <li class="list-group-item">
                      <h5 class="mb-3">Programmes de diffusion</h5>

                      <ngb-tabset type="pills">

                        <!-- Sunrise -->
                        <ngb-tab title="Levé">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Sun; context: { program: belt.diffusionParameters.diffCo2Sunrise }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Sunset -->
                        <ngb-tab title="Couché">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Sun; context: { program: belt.diffusionParameters.diffCo2Sunset }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 1 -->
                        <ngb-tab title="P1">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program1 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 2 -->
                        <ngb-tab title="P2">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program2 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 3 -->
                        <ngb-tab title="P3">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program3 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 4 -->
                        <ngb-tab title="P4">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program4 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 5 -->
                        <ngb-tab title="P5">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program5 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 6 -->
                        <ngb-tab title="P6">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program6 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 7 -->
                        <ngb-tab title="P7">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program7 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 8 -->
                        <ngb-tab title="P8">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program8 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 9 -->
                        <ngb-tab title="P9">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program9 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                        <!-- Program 10 -->
                        <ngb-tab title="P10">
                          <ng-template ngbNavContent>
                            <ng-container *ngTemplateOutlet="diffCo2Program; context: { program: belt.diffusionParameters.diffCo2Program10 }"></ng-container>
                          </ng-template>
                        </ngb-tab>

                      </ngb-tabset>
                    </li>

                    <!-- Alarms -->
                    <li class="list-group-item">
                      <h5 class="mb-3">Alarmes</h5>

                      <div class="row">

                        <!-- Configuration -->
                        <div class="col-md-6">

                          <!-- alrResLowEn -->
                          <div class="form-group">
                            <label>Réserve active basse</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrResLowEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>
          
                          <!-- alrResEmptyEn -->
                          <div class="form-group">
                            <label>Réserve active vide</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrResEmptyEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>
          
                          <!-- alrPresInpEn -->
                          <div class="form-group">
                            <label>Pression d'entrée moyenne</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrPresInpEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>
          
                          <!-- alrPresOutEn -->
                          <div class="form-group">
                            <label>Pression de sortie moyenne</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrPresOutEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>
          
                          <!-- alrFlowAvgEn -->
                          <div class="form-group">
                            <label>Flux moyen</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrFlowAvgEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>
          
                          <!-- alrPowBackEn -->
                          <div class="form-group">
                            <label>Alimentation rétablie</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrPowBackEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>
          
                          <!-- alrPowDownEn -->
                          <div class="form-group">
                            <label>Alimentation coupée</label><br>
                            <ui-switch [(ngModel)]="belt.alarmsParameters.alrPowDownEn" [ngModelOptions]="{ standalone: true }"></ui-switch>
                          </div>

                        </div>

                        <!-- Parameters -->
                        <div class="col-md-6">

                          <!-- alrResLowLevel -->
                          <div class="form-group">
                            <label>Res. active : Seuil contenu (%)</label>
                            <input type="number" class="form-control" [(ngModel)]="belt.alarmsParameters.alrResLowLevel" [ngModelOptions]="{ standalone: true }">
                          </div>
          
                          <!-- alrResEmptyFlow -->
                          <div class="form-group">
                            <label>Res. active : Seuil flux (%)</label>
                            <input type="number" class="form-control" [(ngModel)]="belt.alarmsParameters.alrResEmptyFlow" [ngModelOptions]="{ standalone: true }">
                          </div>
          
                          <!-- alrPresInpTol -->
                          <div class="form-group">
                            <label>Res. : Seuil pression (%)</label>
                            <input type="number" class="form-control" [(ngModel)]="belt.alarmsParameters.alrPresInpTol" [ngModelOptions]="{ standalone: true }">
                          </div>
          
                          <!-- alrPresOutTol -->
                          <div class="form-group">
                            <label>Sortie : Seuil pression (%)</label>
                            <input type="number" class="form-control" [(ngModel)]="belt.alarmsParameters.alrPresOutTol" [ngModelOptions]="{ standalone: true }">
                          </div>
          
                          <!-- alrFlowSetTol -->
                          <div class="form-group">
                            <label>Débit : Seuil écart (%)</label>
                            <input type="number" class="form-control" [(ngModel)]="belt.alarmsParameters.alrFlowSetTol" [ngModelOptions]="{ standalone: true }">
                          </div>
          
                          <!-- alrSmsNum1 -->
                          <div class="form-group mt-5">
                            <label>N° tel. 1</label>
                            <input type="tel" class="form-control" [(ngModel)]="belt.alarmsParameters.alrSmsNum1" [ngModelOptions]="{ standalone: true }">
                          </div>

                          <!-- alrSmsNum2 -->
                          <div class="form-group">
                            <label>N° tel. 2</label>
                            <input type="tel" class="form-control" [(ngModel)]="belt.alarmsParameters.alrSmsNum2" [ngModelOptions]="{ standalone: true }">
                          </div>

                          <!-- alrSmsNum3 -->
                          <div class="form-group">
                            <label>N° tel. 3</label>
                            <input type="tel" class="form-control" [(ngModel)]="belt.alarmsParameters.alrSmsNum3" [ngModelOptions]="{ standalone: true }">
                          </div>

                          <!-- alrSmsNum4 -->
                          <div class="form-group">
                            <label>N° tel. 4</label>
                            <input type="tel" class="form-control" [(ngModel)]="belt.alarmsParameters.alrSmsNum4" [ngModelOptions]="{ standalone: true }">
                          </div>

                          <!-- alrSmsNum5 -->
                          <div class="form-group">
                            <label>N° tel. 5</label>
                            <input type="tel" class="form-control" [(ngModel)]="belt.alarmsParameters.alrSmsNum5" [ngModelOptions]="{ standalone: true }">
                          </div>

                        </div>

                      </div>
                    </li>

                    <!-- Communication -->
                    <li class="list-group-item">
                      <h5 class="mb-3">Communication</h5>
                      
                      <!-- WiFi -->
                      <div class="form-row">

                        <!-- comWiFiName -->
                        <div class="form-group col-md-6">
                          <label>Nom WiFi</label>
                          <input type="text" class="form-control" placeholder="Nom WiFi" [(ngModel)]="belt.communicationParameters.comWiFiName" [ngModelOptions]="{ standalone: true }">
                        </div>

                        <!-- comWiFiPass -->
                        <div class="form-group col-md-6">
                          <label>Mot de passe WiFi</label>
                          <input type="text" class="form-control" placeholder="Mot de passe SSID" [(ngModel)]="belt.communicationParameters.comWiFiPass" [ngModelOptions]="{ standalone: true }">
                        </div>

                      </div>

                      <!-- Modem -->
                      <div class="form-row">

                        <!-- comGsmName -->
                        <div class="form-group col-md-6">
                          <label>Identifiant GSM</label>
                          <input type="text" class="form-control" placeholder="Identifiant GSM" [(ngModel)]="belt.communicationParameters.comGsmName" [ngModelOptions]="{ standalone: true }">
                        </div>

                        <!-- comGsmPass -->
                        <div class="form-group col-md-6">
                          <label>Mot de passe GSM</label>
                          <input type="text" class="form-control" placeholder="Mot de passe GSM" [(ngModel)]="belt.communicationParameters.comGsmPass" [ngModelOptions]="{ standalone: true }">
                        </div>

                      </div>

                    </li>

                  </ul>

                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>

          <div class="card-footer">
            <input type="button" class="btn btn-secondary" value="Annuler" routerLink="/sites" [disabled]="formSubmitted" ngbButton>
            <button type="submit" class="btn btn-success float-right" [disabled]="formSubmitted" ngbButton>
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
              <span *ngIf="!formSubmitted">Créer</span>
            </button>
          </div>
        </div>

      </form>
    </div>

  </div>
</div>

<!-- Templates -->
<ng-template #diffCo2Sun let-program='program'>
  <div class="form-row mt-3">

    <!-- delay -->
    <div class="form-group col-lg-4">
      <label>Décallage +/- minutes</label>
      <input type="number" class="form-control" [(ngModel)]="program.delay" [ngModelOptions]="{ standalone: true }">
    </div>

    <!-- duration -->
    <div class="form-group col-lg-3">
      <label>Durée minutes</label>
      <input type="number" class="form-control" [(ngModel)]="program.duration" [ngModelOptions]="{ standalone: true }">
    </div>

    <!-- intensity -->
    <div class="form-group col-lg-5">
      <label>Intensité</label>
      <input  type="range" class="slider" min="0" max="10" list="tickmarks"
              [(ngModel)]="program.intensity" [ngModelOptions]="{ standalone: true }">
      <small class="form-text text-muted">Intensité : {{ program.intensity }}</small>
    </div>

  </div>
</ng-template>

<ng-template #diffCo2Program let-program='program'>
  <div class="form-row mt-3">

    <!-- daysCode -->
    <div class="form-group col-md-6 col-xl-4">
      <label>Mode de diffusion</label>
      <ng-select  class="select-hidden-dropdown"
                  [items]="daysCodes" bindLabel="name" bindValue="value"
                  [clearable]="false" [searchable]="false"
                  [(ngModel)]="program.daysCode" [ngModelOptions]="{ standalone: true }">
      </ng-select>
    </div>

    <!-- intensity -->
    <div class="form-group col-md-6 col-xl-4">
      <label>Intensité</label>
      <input  type="range" class="slider" min="0" max="10" list="tickmarks"
              [(ngModel)]="program.intensity" [ngModelOptions]="{ standalone: true }">
      <small class="form-text text-muted">Intensité : {{ program.intensity }}</small>
    </div>

    <!-- startTime -->
    <div class="form-group col-6 col-xl-2">
      <label>Début</label>
      <input type="time" class="form-control" [(ngModel)]="program.start" [ngModelOptions]="{ standalone: true }">
    </div>

    <!-- endTime -->
    <div class="form-group col-6 col-xl-2">
      <label>Fin</label>
      <input type="time" class="form-control" [(ngModel)]="program.end" [ngModelOptions]="{ standalone: true }">
    </div>

  </div>
</ng-template>