import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class EventCode {

  @jsonMember
  id: number;
  
  @jsonMember
  descriptionEN: string;
  
  @jsonMember
  descriptionFR: string;
  
  @jsonMember
  parameterName1EN: string;
  
  @jsonMember
  parameterName2EN: string;
  
  @jsonMember
  parameterName3EN: string;
  
  @jsonMember
  parameterName4EN: string;
  
  @jsonMember
  parameterName1FR: string;
  
  @jsonMember
  parameterName2FR: string;
  
  @jsonMember
  parameterName3FR: string;
  
  @jsonMember
  parameterName4FR: string;
  
  @jsonMember
  unit1: string;
  
  @jsonMember
  unit2: string;
  
  @jsonMember
  unit3: string;
  
  @jsonMember
  unit4: string;
  
  @jsonMember
  parameterType1: number;
  
  @jsonMember
  parameterType2: number;
  
  @jsonMember
  parameterType3: number;
  
  @jsonMember
  parameterType4: number;
  
  @jsonMember
  severity: number;
  
  @jsonMember
  category: number;

  static loadFromJSON(json): EventCode {
    let serializer = new TypedJSON(EventCode);
		return serializer.parse(json);
  }
}