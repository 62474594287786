import { Component, Input } from '@angular/core';
import { Belt } from '../../../../../../models/project/belt';
import { UUID } from 'angular2-uuid';
import { CAD } from '../../../../../../models/project/cad';
import { ProtectedArea } from '../../../../../../models/project/protectedArea';

@Component({
  selector: 'cad-right-center',
  templateUrl: './right-center.component.html'
})
export class CADRightCenterComponent {
  // NF
  @Input('cad') cad: CAD;

  constructor() {}

  addBelt() {
    var belt = new Belt(UUID.UUID(), this.cad.project.name.replace(' ', ''));
    this.cad.version.belts.push(belt);
    this.cad.selectBelt(belt.id);
  }

  addProtectedArea() {
    var protectedArea = new ProtectedArea(UUID.UUID());
    protectedArea.points.push([this.cad.project.lat, this.cad.project.lng]);
    this.cad.selectedBelt.protectedAreas.push(protectedArea);
    document.dispatchEvent(new Event('CADProtectedAreaChanged')); 
  }

}