import { Component, Input } from '@angular/core';
import { CAD } from '../../../../../../models/project/cad';

@Component({
  selector: 'cad-diffusions',
  styleUrls: ['./diffusions.component.css'],
  templateUrl: './diffusions.component.html'
})
export class CADDiffusionsComponent {
  // NF
  @Input('cad') cad: CAD;

  constructor() {}

}