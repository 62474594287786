import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../services/gdp/project.service';
import { Project } from '../../../models/gdp/project';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddVersionModalComponent } from './add-version/add-version.component';
import { Version } from '../../../models/gdp/version';
import { EditVersionModalComponent } from './edit-version/edit-version.component';
import { AuthService } from 'src/app/auth/auth.service';
import { DeleteVersionModalComponent } from './delete-version/delete-version.component';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html'
})
export class ProjectComponent implements OnInit {
  project: Project;
  versions: Version[];

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private projectService: ProjectService
  ) { }

  ngOnInit() {
    // Load project
    this.route.params.subscribe(params => {
      this.projectService.get(params['id']).subscribe(
        res => {
          switch (res.code) {

            case Code.PROJECT_RECOVERED:
            this.project = res.result;
            this.loadVersions();
            break;
  
            case Code.PROJECT_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
            this.router.navigateByUrl('/gdp');
            break;
  
          }
        }, () => this.router.navigateByUrl('/gdp')
      )
    })
  }

  loadVersions() {
    this.versions = null;
    this.projectService.getVersions(this.project.id).subscribe(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
          this.versions = res.result;
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          case Code.UNAUTHORIZED:
          this.versions = [];
          break;

        }
      }
    )
  }

  /* Modals */
  openAddVersionModal() {
    var addVersionModalRef = this.modalService.open(AddVersionModalComponent, { size: 'lg' });
    addVersionModalRef.componentInstance.init(this.project, addVersionModalRef, () => this.loadVersions());
  }
  openEditVersionModal(version: Version) {
    var editVersionModalRef = this.modalService.open(EditVersionModalComponent, { size: 'lg' });
    editVersionModalRef.componentInstance.init(version, editVersionModalRef, () => this.loadVersions());
  }
  openDeleteVersionModal(version: Version) {
    var deleteVersionModalRef = this.modalService.open(DeleteVersionModalComponent);
    deleteVersionModalRef.componentInstance.init(version, deleteVersionModalRef, () => this.loadVersions());
  }

}