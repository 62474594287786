import { Component, Input, OnInit} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { GroupUPCV3 } from "src/app/models/GroupUPCV3/GroupUPCV3";

@Component({
  selector: "app-confirmation-poll-modal-content",
  styles: [
    `
    .small-info {
      color: orange;
      font-size: small;
      padding-left: 3vw;
      margin-right: 10px;
    }
    `
  ],
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Gestion pooling des UPC</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      Etes vous sûre de vouloir 
      <strong>{{ this.groupPollEnable ? 'désactiver': 'activer'}}</strong> 
      le polling sur le groupe {{this.upcGroup.name}} et donc les UPC suivantes : 
      <ul>
        <li *ngFor='let upc of this.upcGroup.upcv3s'>
          <span class="text-primary">
            {{upc.upcNameId}}   <em class='small-info'>Actuellement {{upc.upcPollEnable ? 'désactiver': 'activer'}}</em>
          </span>
        </li>
      </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">Ok</button>
  </div>
  `,
})
export class ConfirmationPollModalGroupContent implements OnInit{ 
  @Input() upcGroup: GroupUPCV3;
  groupPollEnable = false;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {
      this.groupPollEnable = this.upcGroup.upcv3s.every(upc => upc.upcPollEnable == true);
  }
}
