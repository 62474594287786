// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare global {
  interface Window {
    env?: {
      apiUrl: string;
      appEnv: string;
      sentryTracesSampleRate: string;
    };
  }
}

// Il faut aussi que env.sample.js dans assets soit raccord avec ce fichier
// https://nkpremices.com/dynamically-set-angular-env-variables-in-docker/
export const environment = {
  environment: window.env?.appEnv || "local",
  production: window.env?.appEnv == "prod",
  apiUrl: window.env?.apiUrl || "http://localhost:8080/",
  sentryTracesSampleRate: Number(window.env?.sentryTracesSampleRate) || 0.1,
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
