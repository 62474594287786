import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { InterventionLegacy } from 'src/app/models/upcv3/interventionLegacy';
import { InterventionLegacyService } from 'src/app/services/upcv3/interventionLegacy.service';
import { BottleLegacy, BottleTypeLegacy, State, Status } from 'src/app/models/management/bottleLegacy';

@Component({
  selector: 'app-intervention-legacy',
  templateUrl: './intervention-legacy.component.html'
})
export class InterventionLegacyComponent implements OnInit {
  upcv3: UPCV3;
  interventionLegacy: InterventionLegacy;
  downloadingInterventionSheet: boolean = false;
  bottleB1 : BottleLegacy[] = [];
  bottleB2 : BottleLegacy[] = [];
  bottleHL : BottleLegacy[] = [];
  bottleENB1 : BottleLegacy[] = [];
  bottleENB2 : BottleLegacy[] = [];
  bottleENHL : BottleLegacy[] = [];
  currentB1 = {designation :[], barcode : [], state : []};
  currentB2= {designation :[], barcode : [], state : []};
  currentHL = {designation :[], barcode : [], state : []};

  constructor(
    private route: ActivatedRoute,
    private router: Router,

    private interventionLegacyService: InterventionLegacyService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    // Load upc

        this.interventionLegacyService.get(this.route.snapshot.params.id).subscribe(
          res => {
              this.interventionLegacy = res;
              if (this.interventionLegacy.currentStateHL != null && this.interventionLegacy.currentStateHL != ""){
                for (var j = 0;j<this.interventionLegacy.currentStateHL.split(",").length;j=j+3){
                    
                  this.currentHL.designation.push(this.interventionLegacy.currentStateHL.split(",")[j+1]);
                  this.currentHL.barcode.push(this.interventionLegacy.currentStateHL.split(",")[j]);
                  if(this.interventionLegacy.currentStateHL.split(",")[j+2] === "IN_USE"){
                    this.currentHL.state.push("Entamé");
                  }
                  if(this.interventionLegacy.currentStateHL.split(",")[j+2] === "FULL"){
                    this.currentHL.state.push("Pleine");
                  }
                  if(this.interventionLegacy.currentStateHL.split(",")[j+2] === "EMPTY"){
                    this.currentHL.state.push("Vide");
                  }
                }
              }
              if(this.interventionLegacy.currentStateB1 != null && this.interventionLegacy.currentStateB1 != ""){
                for (var j =0;j<this.interventionLegacy.currentStateB1.split(",").length;j=j+3){
                  this.currentB1.designation.push(this.interventionLegacy.currentStateB1.split(",")[j+1]);
                  this.currentB1.barcode.push(this.interventionLegacy.currentStateB1.split(",")[j]);
                  this.currentB1.state.push("En Ligne");
                }
              }
              if(this.interventionLegacy.currentStateB2 != null && this.interventionLegacy.currentStateB2 != ""){
                for(var j =0; j<this.interventionLegacy.currentStateB2.split(",").length;j=j+3){
                  this.currentB2.designation.push(this.interventionLegacy.currentStateB2.split(",")[j+1]);
                  this.currentB2.barcode.push(this.interventionLegacy.currentStateB2.split(",")[j]);
                  this.currentB2.state.push("En Ligne");
                }
              }
              if(this.interventionLegacy.addedB1 != null && this.interventionLegacy.addedB1 != "") {
                for(var j = 0;j<this.interventionLegacy.addedB1.split(",").length;j=j+3) {
                  var bottle = new BottleLegacy();
                  bottle.bottleType = new BottleTypeLegacy();
                  var unite = "";
   
                  bottle.bottleType.contenue = parseFloat(this.interventionLegacy.addedB1.split(",")[j+1]);
                  bottle.barcode = this.interventionLegacy.addedB1.split(",")[j];
                  
                  bottle.state = State.IN_USE;
                  this.bottleB1.push(bottle);
                }
              } 
              if(this.interventionLegacy.addedB2 != null && this.interventionLegacy.addedB2 != "") {
                for(var j =0;j<this.interventionLegacy.addedB2.split(",").length;j = j+3){
                  var bottle = new BottleLegacy();
                  bottle.bottleType = new BottleTypeLegacy();
                  bottle.bottleType.contenue = parseFloat(this.interventionLegacy.addedB2.split(",")[j+1]);
                  bottle.barcode = this.interventionLegacy.addedB2.split(",")[j];
                  
                  bottle.state = State.IN_USE;
                  this.bottleB2.push(bottle);
                }
              } if(this.interventionLegacy.addedHL != null && this.interventionLegacy.addedHL != "") {
                for(var j =0;j<this.interventionLegacy.addedHL.split(",").length;j = j+3){
                  var bottle = new BottleLegacy();
                  bottle.bottleType = new BottleTypeLegacy();
                  bottle.bottleType.contenue = parseFloat(this.interventionLegacy.addedHL.split(",")[j+1]);
                  bottle.barcode = this.interventionLegacy.addedHL.split(",")[j];
                  
                  bottle.state = State.IN_USE;
                  this.bottleHL.push(bottle);
                }
              }
              
              this.interventionLegacy.bottleEvents.forEach(item=>{
                
                /*if(item.code === "CONNEXION_A_B1" && item.bottleEv.localisationId === this.upcv3.belt){
                  this.bottleB1.push(item.bottleEv);
                }*/
                /*if (item.code === "CONNEXION_A_B2" && item.bottleEv.localisationId === this.upcv3.belt){
                  this.bottleB2.push(item.bottleEv);
                }*/
                /*if(item.code === "ENTREPOSAGE" && item.destinationType === "BELT" && item.bottleEv.localisationId === this.upcv3.belt) {
                  this.bottleHL.push(item.bottleEv);
                }*/
                if(item.code === "ENTREPOSAGE" && item.bottleEv.lastLocalisationId === this.upcv3.belt && item.bottleEv.lastStatus === Status.B1) {
                  this.bottleENB1.push(item.bottleEv);
                  
                }
                if(item.code === "ENTREPOSAGE" && item.bottleEv.lastLocalisationId === this.upcv3.belt && item.bottleEv.lastStatus === Status.B2) {
                  this.bottleENB2.push(item.bottleEv);
                }
                if (item.code === "RENVOIE" && item.bottleEv.lastLocalisationId == this.upcv3.belt){
                  this.bottleENHL.push(item.bottleEv);
                }

                
              })
              var cptHL = this.bottleHL.length;
              this.currentHL.barcode.splice(this.currentHL.barcode.length-cptHL-1,cptHL);
              this.currentHL.designation.splice(this.currentHL.barcode.length-cptHL-1,cptHL);
              this.currentHL.state.splice(this.currentHL.barcode.length-cptHL-1,cptHL);
              /*for (var i=0;i<this.bottleHL.length;i++){
                for (var k = 0;k<this.currentHL.barcode.length;k++){
                  if(this.currentHL.barcode[k] === this.bottleHL[i].barcode){
                    this.currentHL.barcode.splice(k,1);
                    this.currentHL.designation.splice(k,1);
                    this.currentHL.state.splice(k,1);
                  }
                }
              }*/
              /*var cptB1 = this.bottleB1.length;
              this.currentB1.barcode.splice(this.currentB1.barcode.length-cptB1-1,cptB1);
              this.currentB1.designation.splice(this.currentB1.barcode.length-cptB1-1,cptB1);
              this.currentB1.state.splice(this.currentB1.barcode.length-cptB1-1,cptB1);*/
              for(var i =0;i<this.bottleB1.length;i++){
                for(var k =0;k<this.currentB1.barcode.length;k++){
                  if(this.currentB1.barcode[k] === this.bottleB1[i].barcode){
                    this.currentB1.barcode.splice(k,1);
                    this.currentB1.designation.splice(k,1);
                    this.currentB1.state.splice(k,1);
                  }
                }
              }
              for(var i=0;i<this.bottleB2.length;i++){
                for(var k =0;k<this.currentB2.barcode.length;k++){
                  if(this.currentB2.barcode[k] === this.bottleB2[i].barcode){
                    this.currentB2.barcode.splice(k,1);
                    this.currentB2.designation.splice(k,1);
                    this.currentB2.state.splice(k,1);
                  }
                }
              }
          }
        )
  }
}
