import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import * as _ from 'lodash';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-bilan-consommations',
  templateUrl: './bilan-consommations.component.html'
})
export class BilanConsommationsComponent implements OnInit {
  years: number[];

  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private upcv3Service: UPCV3Service,
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      year: new FormControl(null, [Validators.required, Validators.min(0)])
    });

    this.form.get("year").patchValue(null);
    
    this.years = _.rangeRight(2016, new Date().getFullYear() + 1);
  }

  // User
  get f() { return this.form.controls; }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    const year = this.form.get("year").value;

    this.formSubmitted = true;
    this.upcv3Service.getYearStats(year).subscribe(data => {
      let blob = new Blob([data], { type: 'application/ms-excel'});
      FileSaver.saveAs(blob, year + ".xls");
      this.formSubmitted = false;
    });
  }
}