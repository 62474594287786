import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
//import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Knob
import { KnobModule } from 'ng2-knob';

// Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// maps
import { AgmCoreModule } from '@agm/core';
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    UiSwitchModule,
    AgmCoreModule,
    TableModule,
    TooltipModule,
  ],
  declarations: [
    // Pages
    // Modals
  ]
})
export class EventsModule { }