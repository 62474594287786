<div class="col-12 mt-3 text-center" *ngIf="!intervention"><i class="fa fa-circle-o-notch fa-spin"></i></div>

<div class="container-fluid" *ngIf="intervention">
  <div class="row mt-3">
    <!-- Title -->
    <div class="col-12">
      <h4 class="float-left">Intervention - {{ intervention.site.name }}</h4>
      <span class="float-right" *ngIf="intervention?.origin == origin.WEB">Origine: <i class="pi pi-desktop" ></i></span>
      <span class="float-right" *ngIf="intervention?.origin == origin.MOBILE">Origine: <i class="pi pi-mobile" ></i></span>
    </div>
  </div>
  <div class="row mt-2">
    <!-- Infos -->
    <div class="col-12 mb-2">
      <div class="card-deck">
        <div class="card">
          <h5  class="card-header">Date</h5 >
          <div class="card-body">
            <p class="card-text text-center">Enregistrement : {{ intervention.dateCreated.toLocaleString() }}</p>
            <p class="card-text text-center">Début : {{ intervention.start.toLocaleString() }}</p>
            <p class="card-text text-center" *ngIf="intervention.end">Fin : {{ intervention.end?.toLocaleString() }}</p>
          </div>
        </div>

        <div class="card">
          <h5  class="card-header">Objet</h5>
          <div class="card-body">
            <p class="card-text text-center">{{ intervention.objet }}</p>
          </div>
        </div>

        <div class="card">
          <h5  class="card-header">Opérateurs + Rédacteur</h5>
          <div class="card-body">
            <p class="card-text text-center font-weight-bold">Opérateurs : {{intervenants}}</p>
            <p class="card-text text-center font-weight-bold">Rédacteur : {{intervention.operateur.firstName}} {{intervention.operateur.lastName}}</p>
          </div>
        </div>
        
        <div class="card">
          <h5  class="card-header">Fiche d'intervention</h5>
          <div class="card-body text-center">
            <button type="button" class="btn btn-success" [disabled]="downloadingInterventionSheet" (click)="downloadInterventionSheet()" ngbButton>
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="downloadingInterventionSheet"></i>
              <span *ngIf="!downloadingInterventionSheet">Afficher</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-2">
      <div class="card h-100">
        <h5 class="card-header">CO2 en B1</h5>
        <div class="card-body text-center">
          <div class="row">
            <div class="col-md-6 font-weight-bold">La réserve était vide ?</div>
            <div class="col-md-6"><span *ngIf="intervention.b1Empty != null">{{ intervention.b1Empty ? 'Oui' : 'Non' }}</span></div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Contenu à la date d'enregistrement de l'intervention :</div>
            <div class="col-md-6">{{intervention.co2B1ActualContent.toFixed(2)}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Contenu à la date de l'intervention :</div>
            <div class="col-md-6">{{intervention.co2B1ContentToDate.toFixed(2)}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Contenu ajouté :</div>
            <div class="col-md-6">{{intervention.co2TotalAddedB1}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Correction de contenu :</div>
            <div class="col-md-6">{{intervention.b1Empty || intervention.co2ReplaceAllB1 ? -intervention.co2B1ContentToDate.toFixed(2) : 0}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Remplacer tout ?</div>
            <div class="col-md-6">{{ intervention.co2ReplaceAllB1 ? 'Oui' : 'Non' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-2">
      <div class="card h-100">
        <h5 class="card-header">CO2 en B2</h5>
        <div class="card-body text-center">
          <div class="row">
            <div class="col-md-6 font-weight-bold">La réserve était vide ?</div>
            <div class="col-md-6"><span *ngIf="intervention.b2Empty != null">{{ intervention.b2Empty ? 'Oui' : 'Non' }}</span></div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Contenu à la date d'enregistrement de l'intervention :</div>
            <div class="col-md-6">{{intervention.co2B2ActualContent.toFixed(2)}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Contenu à la date de l'intervention :</div>
            <div class="col-md-6">{{intervention.co2B2ContentToDate.toFixed(2)}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Contenu ajouté :</div>
            <div class="col-md-6">{{intervention.co2TotalAddedB2}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Correction de contenu :</div>
            <div class="col-md-6">{{intervention.b2Empty || intervention.co2ReplaceAllB2 ? -intervention.co2B2ContentToDate.toFixed(2) : 0}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Remplacer tout ?</div>
            <div class="col-md-6">{{ intervention.co2ReplaceAllB2 ? 'Oui' : 'Non' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 mb-2" *ngFor="let bottleHistoryOfTargetKV of bottleHistoryOfTargets | keyvalue:keepOriginalOrder">
      <div class="card h-100">
        <h5  class="card-header">Bouteilles en {{ bottleHistoryOfTargetKV.key | uppercase }} après l'intervention</h5 >
        <div class="card-body">
          <table class="table table-striped" *ngIf="bottleHistoryOfTargetKV.value.length != 0">
            <thead>
              <tr>
                <th scope="col">Barcode</th>
                <th scope="col">Designation</th>
                <th scope="col">Historique</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let bottleHistory of bottleHistoryOfTargetKV.value"
                [ngClass]="{
                  'text-success': !isBottleInArray(bottleHistory.bottle, bottleHistoryBeforeOfTargets[bottleHistoryOfTargetKV.key]),
                  'text-danger': !isBottleInArray(bottleHistory.bottle, bottleHistoryAfterOfTargets[bottleHistoryOfTargetKV.key])
              }">
                <td>{{ bottleHistory.barcode}}</td>
                <td>{{ bottleHistory.bottle.bottleType.designationString }}</td>
                <td class="p-0 pl-4">
                  <button mat-icon-button (click)="openBottleEventsModal(bottleHistory.bottle)">
                    <mat-icon>list</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- Site -->
    <div class="col-md-6 mb-2">
      <div class="card h-100">
        <h5  class="card-header">Site</h5 >
        <div class="card-body text-center">
          <div class="row">
            <div class="col-md-6 font-weight-bold">Nom :</div>
            <div class="col-md-6">{{intervention.site.name}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Adresse :</div>
            <div class="col-md-6">{{intervention.site.address}}</div>
          </div>
          <div class="row">
            <div class="col-md-6 font-weight-bold">Infos :</div>
            <div class="col-md-6">{{intervention.site.infos}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- UPCV3 -->
    <div class="col-md-3 mb-2" *ngIf="intervention.upcv3">
      <div class="card h-100">
        <h5  class="card-header">UPCV3</h5 >
        <div class="card-body text-center">
          <div class="row">
            <div class="col-md-6 font-weight-bold">Nom :</div>
            <div class="col-md-6">{{intervention.upcv3?.upcNameId}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- GroupUPCV3 -->
    <div class="col-md-3 mb-2" *ngIf="intervention.groupUPCV3">
      <div class="card h-100">
        <h5  class="card-header">Groupe UPCV3</h5 >
        <div class="card-body text-center">
          <div class="row">
            <div class="col-md-6 font-weight-bold">Nom :</div>
            <div class="col-md-6">{{intervention.groupUPCV3?.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
