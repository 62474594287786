import { TypedJSON, jsonArrayMember, jsonMember, jsonObject } from "typedjson";
import { Site } from "../management/site";
import { UPCV3 } from "../upcv3/upc/upcv3";
import { MailAlert } from "../upcv3/mailalert";
import _ from "lodash";
import { ReservesParameters } from "../upcv3/upc/reservesParameters";
import { Commentaires } from "../upcv3/commentaire";

@jsonObject
export class GroupUPCV3 {

  @jsonMember
  id: number;

  @jsonMember
  name: string;

  @jsonMember
  archived: boolean;

  @jsonMember(() => Site)
  site: Site;

  @jsonArrayMember(() => UPCV3)
  upcv3s: UPCV3[];

  @jsonMember(() => Commentaires)
  lastActionNotDone?: Commentaires;

  @jsonMember(() => Commentaires)
  lastComment?: Commentaires;

  static loadFromJSON(json): GroupUPCV3 {
    const serializer = new TypedJSON(GroupUPCV3);
    return serializer.parse(json);
  }

  getStatusSeverity() {
    let severityArray = this.upcv3s.map(upc => upc.getStatusSeverity())
    return Math.max(...severityArray);
  }

  get trapNumber(): number {
    return this.upcv3s.reduce((acc, upc) => acc + upc.generalParameters.upcTrapNum, 0)
  }

  get alertsSincePolling(): MailAlert[] {
    return this.upcv3s.map(upc => upc.alertsSincePolling).flat().sort((a, b) => a.date > b.date ? -1 : 1);
  }

  get upcAlertsSinceLastPollingTooltip(): string {
    const fiveMostRecent = this.alertsSincePolling.slice(0, 4);
    let result = "";
    for (const alarm of fiveMostRecent) {
      result +=
        alarm.date.toLocaleString("fr-fr") +
        "  " +
        alarm.msg +
        "\n";
    }
    return result;
  }

  get showDashboardAsterisk() {
    return this.alertsSincePolling.length !== 0;
  }

  get groupNameClass() {
    const groupeSeverity = this.getStatusSeverity();
    return UPCV3.getStatusSeverityClass(groupeSeverity);
  }

  get upcGroupStatus(): number {
    const findMismatchInStatus = this.upcv3s.some((upc) => {
      return (
        upc.generalParameters.upcStatus !==
        this.upcv3s[0].generalParameters.upcStatus
      );
    });
    if (this.upcv3s.length == 0 || findMismatchInStatus) {
      return 999;
    } else {
      return this.upcv3s[0].generalParameters.upcStatus;
    }
  }


  get upcGroupStatusString() {
    switch (this.upcGroupStatus) {
      case 0:
        return "DIS";
      case 1:
        return "ENA";
      case 2:
        return "ADJ";
      case 3:
        return "CHK";
      case 4:
        return "CAL";
      case 100:
        return "EMPTY";
      case 999:
        return "###";
      default:
        return "";
    }

  }

  get _co2ResActiveString(): string {
    if (this.upcv3s.length != 0 && this.upcv3s.every(upc => upc.co2ResActiveString == this.upcv3s[0].co2ResActiveString)) {
      return this.upcv3s[0].co2ResActiveString;
    } else return '###';
  }

  get _co2Res1ActVol(): number {
    return this.upcv3s.reduce((acc, upc) => acc + upc.reservesParameters.co2Res1ActVol, 0)
  }

  get _co2Res1ActDur(): number {
    return this.upcv3s.reduce((acc, upc) => acc + upc.reservesParameters.co2Res1ActDur, 0)
  }

  get _co2Res2ActVol() {
    return this.upcv3s.reduce((acc, upc) => acc + upc.reservesParameters.co2Res2ActVol, 0)
  }

  get _co2Res2ActDur() {
    return this.upcv3s.reduce((acc, upc) => acc + upc.reservesParameters.co2Res2ActDur, 0)
  }

  get logLastVisit() {
    const mostRecentVisitUpc = this.upcv3s.reduce((mostRecent, upc) => {
      let mostRecentDate = mostRecent.logLastVisit;
      let currentUpcLastVisit = upc.logLastVisit;
      if (mostRecentDate > currentUpcLastVisit) {
        return mostRecent;
      } else return upc;
    });
    return mostRecentVisitUpc.logLastVisit;

  }

  get groupPollEnableClass() {
    if (this.upcv3s.every(upc => upc.upcPollEnable == true)) {
      return "button-success"
    }
    if (this.upcv3s.every(upc => upc.upcPollEnable == false)) {
      return "button-alert"
    } else {
      return 'button-partial'
    }
  }

  get groupPollEnableString() {
    if (this.upcv3s.every(upc => upc.upcPollEnable == true)) {
      return "Oui"
    }
    if (this.upcv3s.every(upc => upc.upcPollEnable == false)) {
      return "Non"
    } else {
      return 'Partiel'
    }
  }

  get _co2Res1StartVol() {
    return this.upcv3s.reduce((acc, upc) => acc + upc.reservesParameters.co2Res1StartVol, 0)
  }

  get _co2Res2StartVol() {
    return this.upcv3s.reduce((acc, upc) => acc + upc.reservesParameters.co2Res2StartVol, 0)
  }

  get _co2ResTypeString(): string {
    if (this.upcv3s.length != 0 &&
      this.upcv3s.every(
        (upc) =>
          upc.reservesParameters.co2ResType ===
          this.upcv3s[0].reservesParameters.co2ResType
      )
    ) {
      return this.upcv3s[0].Co2ResTypeString;
    } else {
      return null;
    }
  }
  get co2ResInactPrev(): Date[] {
    const co2ResInactPrev: Date[] = [];
    for (const upc of this.upcv3s) {
      co2ResInactPrev.push(
        upc.reservesParameters.co2ResInactPrev
      );
    }
    return co2ResInactPrev;
  }
  get co2resActArray(): Date[] {
    const co2resActArray: Date[] = [];
    for (const upc of this.upcv3s) {
      co2resActArray.push(upc.reservesParameters.co2ResActPrev);
    }
    return co2resActArray;

  }
  get _co2ResActPrev(): Date {
    return new Date(Math.min.apply(null, this.co2resActArray));
  }

  get _co2ResInactPrev(): Date {
    return new Date(Math.min.apply(null, this.co2ResInactPrev));
  }

  get _co2Res1Enabled(): boolean {
    if (this.upcv3s.length != 0 && this.upcv3s.every(upc => upc.reservesParameters.co2Res1Enabled === this.upcv3s[0].reservesParameters.co2Res1Enabled)) {
      return this.upcv3s[0].reservesParameters.co2Res1Enabled;
    } else {
      return null;
    }
  }

  get _co2Res1StatusString(): string {
    if (this.upcv3s.length != 0 &&
      this.upcv3s.every(
        (upc) =>
          upc.reservesParameters.co2Res1Status ===
          this.upcv3s[0].reservesParameters.co2Res1Status
      )
    ) {
      return ReservesParameters.co2ResStatusToString(
        this.upcv3s[0].reservesParameters.co2Res1Status
      );
    } else {
      return null;
    }

  }

  get _co2Res2Enabled() {
    if (this.upcv3s.length != 0 && this.upcv3s.every(upc => upc.reservesParameters.co2Res2Enabled === this.upcv3s[0].reservesParameters.co2Res2Enabled)) {
      return this.upcv3s[0].reservesParameters.co2Res2Enabled;
    } else {
      return null;
    }
  }

  get _co2Res2StatusString(): string {
    if (this.upcv3s.length != 0 &&
      this.upcv3s.every(
        (upc) =>
          upc.reservesParameters.co2Res2Status ===
          this.upcv3s[0].reservesParameters.co2Res2Status
      )
    ) {
      return ReservesParameters.co2ResStatusToString(
        this.upcv3s[0].reservesParameters.co2Res2Status
      );
    } else {
      return null;
    }
  }

  get hibernated(): boolean {
    if (this.upcv3s.length != 0 && this.upcv3s.every(upc => upc.hibernated == this.upcv3s[0].hibernated)) {
      return this.upcv3s[0].hibernated;
    } else {
      return undefined;
    }
  }
}