import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'reflect-metadata';

import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
  Sentry.init({
    environment: environment.environment,
    dsn: "https://f224f22063ca4c36b453e5125b3bea1e@sentry.i21-innovation.com/2",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        mutationBreadcrumbLimit: 500,
        mutationLimit: 1000,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
