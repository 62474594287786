import { Component, Input, Output, EventEmitter } from "@angular/core";
import { GDP } from "src/app/models/gdp/gdp";
import { Node, Type as NodeType } from "src/app/models/gdp/node";
import { Path } from "src/app/models/gdp/path";
import { Belt } from "src/app/models/gdp/belt";
import { ProtectedArea } from "src/app/models/gdp/protectedArea";

@Component({
  selector: 'gdp-selection',
  templateUrl: './selection.component.html'
})
export class SelectionComponent {
  // NF
  @Input('gdp') gdp: GDP;
  @Output('nodeChange') nodeChange = new EventEmitter<Node>();
  @Output('pathChange') pathChange = new EventEmitter<Path>();
  @Output('protectedAreaChange') protectedAreaChange = new EventEmitter<ProtectedArea>();
  @Output('beltDelete') beltDelete = new EventEmitter<Belt>();
  @Output('protectedAreaDelete') protectedAreaDelete = new EventEmitter<ProtectedArea>();

  get selectedNodeNumber(): number {
    var number: number = 0;
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => {
        if (node.selected)
          number++;
      })
    });
    return number;
  }
  get selectedPathNumber(): number {
    var number: number = 0;
    this.gdp.version.belts.forEach(belt => {
      belt.paths.forEach(path => {
        if (path.selected)
          number++;
      })
    });
    return number;
  }
  get firstSelectedNode(): Node {
    for (var i = 0; i < this.gdp.version.belts.length; i++) {
      for (var j = 0; j < this.gdp.version.belts[i].nodes.length; j++) {
        if (this.gdp.version.belts[i].nodes[j].selected) {
          return this.gdp.version.belts[i].nodes[j];
        }
      }
    }
    return null;
  }
  get firstSelectedPath(): Path {
    for (var i = 0; i < this.gdp.version.belts.length; i++) {
      for (var j = 0; j < this.gdp.version.belts[i].paths.length; j++) {
        if (this.gdp.version.belts[i].paths[j].selected) {
          return this.gdp.version.belts[i].paths[j];
        }
      }
    }
    return null;
  }
  get selectedBelt(): Belt {
    return this.gdp.getBelt(this.gdp.version.selectedBelt);
  }
  get selectedProtectedArea(): ProtectedArea {
    return this.gdp.getProtectedArea(this.gdp.version.selectedProtectedArea);
  }

  /*
   * Methods
   */
  updateNodesType(nodeType: NodeType): void {
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => {
        if (node.selected) {
          if (node.type != NodeType.CONTROL) node.type = nodeType; // NF
          this.nodeChange.emit(node); // UI
        }
      })
    })
  }
  updateNodesTitleColor(titleColor: string): void {
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => {
        if (node.selected) {
          if (node.type != NodeType.CONTROL) node.titleColor = titleColor; // NF
          this.nodeChange.emit(node); // UI
        }
      })
    })
  }
  updateNodesTitleBackgroundColor(titleBackgroundColor: string): void {
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => {
        if (node.selected) {
          if (node.type != NodeType.CONTROL) node.titleBackgroundColor = titleBackgroundColor; // NF
          this.nodeChange.emit(node); // UI
        }
      })
    })
  }
  updateNodesIconSize(iconSize: number): void {
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => {
        if (node.selected) {
          node.iconSize = Math.round(iconSize); // NF
          this.nodeChange.emit(node); // UI
        }
      })
    })
  }
  updateNodesTitleSize(titleSize: number): void {
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => {
        if (node.selected) {
          node.titleSize = Math.round(titleSize); // NF
          this.nodeChange.emit(node); // UI
        }
      })
    })
  }

  updatePathsColor(color: string): void {
    this.gdp.version.belts.forEach(belt => {
      belt.paths.forEach(path => {
        if (path.selected) {
          path.color = color; // NF
          this.pathChange.emit(path); // UI
        }
      })
    })
  }
  updatePathsWidth(width: number): void {
    this.gdp.version.belts.forEach(belt => {
      belt.paths.forEach(path => {
        if (path.selected) {
          path.width = Math.round(width); // NF
          this.pathChange.emit(path); // UI
        }
      })
    })
  }
  updatePathsDashed(dashed: boolean): void {
    this.gdp.version.belts.forEach(belt => {
      belt.paths.forEach(path => {
        if (path.selected) {
          path.dashed = dashed; // NF
          this.pathChange.emit(path); // UI
        }
      })
    })
  }

  updateProtectedAreaWidth(): void {
    this.protectedAreaChange.emit(this.selectedProtectedArea);
  }
  updateProtectedAreaColor(): void {
    this.protectedAreaChange.emit(this.selectedProtectedArea);
  }

  deleteBelt(belt: Belt): void {
    this.beltDelete.emit(belt);
  }
  deleteProtectedArea(protectedArea: ProtectedArea): void {
    this.protectedAreaDelete.emit(protectedArea);
  }

}