<div class="modal-header">
  <h4 class="modal-title">Ajouter des Bouteilles</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">
    <!-- Barcodes -->
    <label for="barcode" *ngIf="subject.forceBarcodesEnabled">Code-barres *</label>
    <div class="input-group mb-2" *ngIf="subject.forceBarcodesEnabled">
      <input #barcodeInput type="text" class="form-control" placeholder="Entrez un code-barre" [ngClass]="{ 'is-invalid': invalidBarcode }" (keyup)="invalidBarcode = false">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" (click)="addBarcode(barcodeInput.value)">Ajouter</button>
      </div>
      <div *ngIf="invalidBarcode" class="invalid-feedback">Code-barre invalide</div>
    </div>
    <div class="form-group" *ngIf="subject.forceBarcodesEnabled">
      <ng-select class="select-hidden-drowpdown" [items]="[]" [searchable]="false" [clearable]="false" [multiple]="true" formControlName="barcodes"></ng-select>
      <div *ngIf="f.barcodes.errors" class="invalid-feedback d-block">
        <div *ngIf="f.barcodes.errors && f.barcodes.errors.required">Doit contenir au moins 1 code-barres</div>
      </div>
    </div>

    <!-- BottleType -->
    <div class="form-group">
      <label for="bottleType">Type de bouteille *</label>
      <select class="custom-select" formControlName="bottleType" [ngClass]="{ 'is-invalid': f.bottleType.errors }">
        <option value="{{ bottleType.id }}" *ngFor="let bottleType of bottleTypes">{{ bottleType.contenue }} kg{{ bottleType.isRembo ? ' (Rembo)' : '' }} - {{ bottleType.brand }}</option>
      </select>
      <div *ngIf="f.bottleType.errors" class="invalid-feedback">
        <div *ngIf="f.bottleType.errors && f.bottleType.errors.required">Type de bouteille obligatoire</div>
      </div>
    </div>

    <div class="form-group" *ngIf="!subject.forceBarcodesEnabled">
      <label for="numberOfBottlesToCreate">Nombre de bouteilles</label>
      <input type="number" class="form-control" formControlName="numberOfBottlesToCreate" min="1" max="100" [ngClass]="{ 'is-invalid': f.numberOfBottlesToCreate.errors }">
      <div *ngIf="f.numberOfBottlesToCreate.errors" class="invalid-feedback">
        <div *ngIf="f.numberOfBottlesToCreate.errors">
          <p *ngIf="f.numberOfBottlesToCreate.errors.required">Une valeur doit être renseignée</p>
          <p *ngIf="f.numberOfBottlesToCreate.errors.min">Doit être au dessus de {{ f.numberOfBottlesToCreate.errors.min.min }}</p>
          <p *ngIf="f.numberOfBottlesToCreate.errors.max">Doit être en dessous de {{ f.numberOfBottlesToCreate.errors.max.max }}</p>
        </div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted || this.form.invalid" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>