<h2 mat-dialog-title>Ajouter une Bouteille</h2>

<mat-dialog-content>
  <ng-container *ngIf="data.addableBottlesInStock && data.addableBottlesInStock.length != 0">
    <h5>Bouteilles dans les stocks associés</h5>
    <bottles-table [bottles]="data.addableBottlesInStock" [displayedColumns]="displayedColumnsInStock" [(selectedBottles)]="selectedBottlesInStock"></bottles-table>
  </ng-container>
  <h5>Bouteilles déjà présentes sur place</h5>
  <bottles-table [bottles]="data.addableBottlesOnSite" [displayedColumns]="displayedColumns" [(selectedBottles)]="selectedBottlesOnSite"></bottles-table>
  <h5>Bouteilles en transit</h5>
  <bottles-table [bottles]="data.addableBottlesInTransit" [displayedColumns]="displayedColumns" [(selectedBottles)]="selectedBottlesInTransit"></bottles-table>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="undefined">Annuler</button>
  <button mat-stroked-button color="primary" (click)="add()">Ajouter</button>
</mat-dialog-actions>