import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class DiffCo2 {
  @jsonMember
  delay: number = 0;

  @jsonMember
  duration: number = 0;

  @jsonMember
  intensity: number = 0;

  static loadFromJSON(json): DiffCo2 {
    const serializer = new TypedJSON(DiffCo2);
    return serializer.parse(json);
  }
}