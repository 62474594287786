import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class GeneralParameters {
	
	@jsonMember
	upcMcuUid: string = "";
	
	@jsonMember
	upcFwVer: number = 0;
	
	@jsonMember
	upcMode: number = 0;
	
	@jsonMember
	upcStatus: number = 0;
	
	@jsonMember
	upcClock: Date = new Date(0);
	
	@jsonMember
	upcTimeZone: number = 0;
	
	@jsonMember
	upcLanguage: number = 0;
	
	@jsonMember
	upcTrapNum: number = 0;
	
	@jsonMember
	upcTrapNumBranch1: number = 0;
	
	@jsonMember
	upcTrapNumBranch2: number = 0;
	
	@jsonMember
	co2FlowRefTrap: number = 0;
	
	@jsonMember
	refFlowRateGperhour: number = 0;
	
	@jsonMember
	co2FlowRefNom: number = 0;
	
	@jsonMember
	co2FlowRefAdj: number = 0;
	
	@jsonMember
	co2PresOutRef1: number = 0;
	
	@jsonMember
	co2PresOutRef2: number = 0;
	
	@jsonMember
	co2PresOutRef3: number = 0;
	
	@jsonMember
	co2PresOutRef4: number = 0;
	
	@jsonMember
	co2PresOutRef5: number = 0;
	
	@jsonMember
	co2PresOutRef6: number = 0;
	
	@jsonMember
	co2PresOutRef7: number = 0;
	
	@jsonMember
	co2PresOutRef8: number = 0;
	
	@jsonMember
	co2PresOutRef9: number = 0;
	
	@jsonMember
	co2PresOutRef10: number = 0;
	
	@jsonMember
	co2PressOutTemp: number = 0;
	
	@jsonMember
	upcBattChrg: number = 0;
	
	@jsonMember
	upcBattTemp: number = 0;
	
	@jsonMember
	upcCo2PidInteg: number = 0;
	
	@jsonMember
	upcCo2PidDiff: number = 0;
	
	@jsonMember
	upcCo2PidProp: number = 0;

	static loadFromJSON(json): GeneralParameters {
		let serializer = new TypedJSON(GeneralParameters);
		return serializer.parse(json);
	}
}