<div class="row mt-3" *ngIf="!site">
  <div class="col-12 text-center"><i class="fa fa-circle-o-notch fa-spin"></i></div>
</div>
<div class="card mb-3" *ngIf="site">
  <div class="card-header font-weight-bold">Créer un Groupe pour le site {{site.name}}</div>
  <div class="col-12 col-md-9 my-3">
    <div class="form-group col-md-9">
      <label>Nom du Groupe *</label>
      <input type="text" class="form-control" placeholder="Nom du Groupe *" [(ngModel)]="name"
        [ngClass]="{ 'is-invalid': name.length == 0 }">
      <div *ngIf="name.length == 0" class="invalid-feedback">
        <div *ngIf="name.length == 0">Nom obligatoire</div>
      </div>
    </div>
    <div class="form-group col-md-9">
      <label>Ceintures *</label>
      <ng-select [items]="candidates" [(ngModel)]="selectedUpcsIds" [multiple]="true" [closeOnSelect]="false"
        bindLabel="upcNameId" bindValue="id" #select>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <span>{{ " "+item.upcNameId }}</span>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="card-footer">
    <input type="button" class="btn btn-secondary" value="Annuler" routerLink="/dashboard" [disabled]="formSubmitted"
      ngbButton>
    <button type="submit" class="btn btn-success float-right" (click)="onCreateGroup();" [disabled]="formSubmitted"
      ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Créer le Groupe</span>
    </button>
  </div>
</div>