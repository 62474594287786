<div class="modal-header">
  <h4 class="modal-title">Nouvelle Version</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<div class="modal-body text-center" *ngIf="!versions"><i class="fa fa-circle-o-notch fa-spin"></i></div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()" *ngIf="versions">
  <div class="modal-body">

    <!-- VersionType -->
    <div class="form-group">
      <label for="versionType">Version *</label>
      <select class="custom-select" formControlName="versionType" [ngClass]="{ 'is-invalid': versionAlreadyExist || f.versionType.errors }" (change)="versionAlreadyExist = false">
        <option value="PRE_ESTIMATE">Pré-devis</option>
        <option value="ESTIMATE">Devis</option>
        <option value="ACCEPTED_ESTIMATE">Devis-accepté</option>
        <option value="INSTALLATION">Installation</option>
      </select>
      <div *ngIf="versionAlreadyExist || f.versionType.errors" class="invalid-feedback">
        <div *ngIf="f.versionType.errors && f.versionType.errors.required">Version obligatoire</div>
        <div *ngIf="versionAlreadyExist">Version déjà existante</div>
      </div>
    </div>

    <!-- NumberOrder -->
    <div class="form-group">
      <label for="numberOrder">N° Ordre</label>
      <input type="number" class="form-control" id="numberOrder" placeholder="N° Ordre" step="1" formControlName="numberOrder" [ngClass]="{ 'is-invalid': versionAlreadyExist || f.numberOrder.errors }" (change)="versionAlreadyExist = false">
      <div *ngIf="versionAlreadyExist || f.numberOrder.errors" class="invalid-feedback">
        <div *ngIf="f.numberOrder.errors && f.numberOrder.errors.required">N° Ordre obligatoire</div>
        <div *ngIf="versionAlreadyExist">Version déjà existante</div>
      </div>
    </div>

    <hr class="ml-0 mt-4" *ngIf="versions.length > 0">

    <!-- Version source -->
    <div class="form-group" *ngIf="versions.length > 0">
      <label for="versionType">Version source <small>(La partie électrique n'est pour l'instant pas copiée)</small></label>
      <select class="custom-select" formControlName="versionSource">
        <option value="{{ version.id }}" *ngFor="let version of versions">{{ version.versionTypeToString }} - {{ version.numberOrder }}</option>
      </select>
      <div *ngIf="versionAlreadyExist || f.versionType.errors" class="invalid-feedback">
        <div *ngIf="f.versionType.errors && f.versionType.errors.required">Version obligatoire</div>
        <div *ngIf="versionAlreadyExist">Version déjà existante</div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>