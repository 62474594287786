<div class="container-fluid">
  <div class="row mt-3 mb-2 justify-content-end">
    <div class="col-auto align-self-center">
      <label>Rafraichissement automatique : </label>
      <ui-switch 
        checkedLabel="Activé" uncheckedLabel="Désactivé"
        class="ml-2"
        [(ngModel)]="enableAutoRefresh">
      </ui-switch>
    </div>
    <div class="col-auto">
      <div
        class="btn-group btn-group-toggle"
        ngbRadioGroup
        name="radioBasic"
        [(ngModel)]="eventsSourceFilter"
        (change)="loadEvents()"
      >
        <label ngbButtonLabel class="btn-primary" (click)="loadEvents()">
          <i class="fa fa-refresh mr-2"></i>Rafraîchir
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" [value]="'all'" /> Tout
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" [value]="'upc'" /> UPC
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" [value]="'server'" /> Serveur
        </label>
        <label ngbButtonLabel class="btn-primary">
          <input ngbButton type="radio" [value]="'sms'" /> SMS
        </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-7 col-lg-3 offset-lg-9 mb-2">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          placeholder="Rechercher"
          [(ngModel)]="inputSearch"
          (ngModelChange)="this.userSearchUpdate.next($event)"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-primary">
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <div class="input-group">
          <input
            class="form-control"
            [(ngModel)]="from"
            ngbDatepicker
            #dFrom="ngbDatepicker"
            (ngModelChange)="loadEvents()"
            [ngClass]="{ 'is-invalid': wrongRange }"
            placeholder="Du"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              (click)="dFrom.toggle()"
              type="button"
            >
              <i class="fa fa-calendar"></i>
            </button>
          </div>
          <div class="invalid-feedback" *ngIf="wrongRange">
            La date de début est après la date de fin
          </div>
        </div>
      </div>
    </div>
  
    <div class="col-sm-6">
      <div class="form-group">
        <div class="input-group">
          <input
            class="form-control"
            [(ngModel)]="to"
            ngbDatepicker
            #dTo="ngbDatepicker"
            (ngModelChange)="loadEvents()"
            [ngClass]="{ 'is-invalid': wrongRange }"
            placeholder="Au"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              (click)="dTo.toggle()"
              type="button"
            >
              <i class="fa fa-calendar"></i>
            </button>
          </div>
          <div class="invalid-feedback" *ngIf="wrongRange">
            La date de début est après la date de fin
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Table -->
  <div class="row">
    <div class="col-12">
      <p-table
        [value]="events"
        styleClass="p-datatable-striped p-datatable-sm"
        [tableStyle]="{'min-width': '75rem', 'width': '100%'}"
        responsiveLayout="scroll"
        [paginator]="true"
        [rows]="nbRows"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} à {last} sur {totalRecords} événements"
        [totalRecords]="totalEvents"
        [rowsPerPageOptions]="[100, 200, 500]"
        [lazy]="true"
        [loading]="loading"
        (onLazyLoad)="handleTableEvent($event)"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th colspan="4">Paramètres</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-event>
          <tr>
            <td
              tooltipPosition="top"
              pTooltip="Serveur : {{
                event.serverDatetime.toLocaleString('fr')
              }}"
              [ngClass]="{ 'text-primary': event.eventCode.category == 0 }"
            >
              {{
                event.eventCode.category == 0
                  ? event.eventDatetime.toLocaleString("fr")
                  : event.serverDatetime.toLocaleString("fr")
              }}
            </td>
            <td
              tooltipPosition="top"
              pTooltip="Code : {{ event.eventCode.id }}"
              [ngClass]="{
                'text-danger': event.eventCode.severity == 0,
                'text-warning': event.eventCode.severity == 1
              }"
            >
              {{ event.eventCode.descriptionFR }}
            </td>
            <td [innerHTML]="event.eventParam1HTML"></td>
            <td [innerHTML]="event.eventParam2HTML"></td>
            <td [innerHTML]="event.eventParam3HTML"></td>
            <td [innerHTML]="event.eventParam4HTML"></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">Aucun événement trouvé.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="row">
    <button class="btn btn-primary" (click)="scrollToTop()">Scroll Top</button>
    <div class="row"></div>
  </div>
</div>
