<div class="modal-header">
  <h4 class="modal-title">Nouveau Projet</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">
    <!-- Name -->
    <div class="form-group">
      <label for="name">Nom *</label>
      <input
        type="text"
        class="form-control"
        id="name"
        placeholder="Nom *"
        formControlName="name"
        [ngClass]="{ 'is-invalid': nameAlreadyUsed || f.name.errors }"
        (keyup)="nameAlreadyUsed = false"
      />
      <div *ngIf="nameAlreadyUsed || f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors && f.name.errors.required">
          Nom obligatoire
        </div>
        <div *ngIf="nameAlreadyUsed">Nom déjà utilisé</div>
      </div>
    </div>

    <!-- Client -->
    <div class="form-group">
      <label for="client">Client *</label>
      <input
        required
        type="text"
        class="form-control"
        id="client"
        placeholder="Client *"
        formControlName="client"
        [ngClass]="{ 'is-invalid': f.client.errors }"
      />
      <div *ngIf="f.client.errors" class="invalid-feedback">
        <div *ngIf="f.client.errors && f.client.errors.required">
          Client obligatoire
        </div>
      </div>
    </div>

    <hr class="ml-0" />

    <!-- Address -->
    <div class="form-group">
      <label for="address">Adresse *</label>
      <div class="input-group mb-3">
        <input
          type="text"
          [ngClass]="{ 'is-invalid': addressUnknow || f.address.errors }"
          class="form-control"
          id="address"
          placeholder="Adresse *"
          formControlName="address"
          [ngClass]="{ 'is-invalid': addressUnknow }"
          (keyup)="addressUnknow = false"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="geolocate()"
          >
            Géolocaliser
          </button>
        </div>
        <div *ngIf="addressUnknow || f.address.errors" class="invalid-feedback">
          <div *ngIf="addressUnknow">Adresse non trouvée</div>
          <div *ngIf="f.address.errors">Adresse requise</div>
        </div>
      </div>
    </div>

    <!-- Lat -->
    <div class="form-group">
      <label for="lat">Latitude *</label>
      <input
        type="number"
        class="form-control"
        id="lat"
        [ngClass]="{ 'is-invalid': f.lat.errors }"
        placeholder="Latitude"
        step="0.01"
        formControlName="lat"
      />
    </div>

    <!-- Lng -->
    <div class="form-group">
      <label for="lng">Longitude *</label>
      <input
        type="number"
        class="form-control"
        id="lng"
        [ngClass]="{ 'is-invalid': f.lng.errors }"
        placeholder="Longitude"
        step="0.01"
        formControlName="lng"
      />
    </div>

    <!-- HBM Distance -->
    <div class="form-group">
      <label for="hbmDistance">Distance de HBM Distribution</label>
      <div class="input-group mb-3">
        <input
          type="text"
          class="form-control"
          id="hbmDistance"
          placeholder="Distance de HBM Distribution"
          formControlName="hbmDistance"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary"
            type="button"
            (click)="geoCalculate()"
          >
            Calculer
          </button>
        </div>
      </div>
    </div>

    <hr class="ml-0">

     <!-- Custom Picture -->
     <div class="form-group">
      <label>Image personnalisée</label><br>
      <ui-switch formControlName="customPicture"></ui-switch>
    </div>

    <hr class="ml-0">

    <!-- File -->
    <form
      [formGroup]="customPictureForm"
      (ngSubmit)="onCustomPictureFormSubmit()"
      *ngIf="this.f.customPicture.value"
    >
      <label>Charger une nouvelle image</label>
      <div class="input-group">
        <input
          type="file"
          class="form-control"
          (change)="onFileChange($event)"
          [ngClass]="{ 'is-invalid': cpf.file.errors }"
        />
        
        <div *ngIf="cpf.file.errors" class="invalid-feedback">
          <div
            *ngIf="cpf.file.errors && cpf.file.errors.required && !readingFile"
          >
            Fichier obligatoire
          </div>
          <div *ngIf="readingFile">
            <i class="fa fa-circle-o-notch fa-spin mr-2"></i>Chargement du
            fichier...
          </div>
        </div>
      </div>
    </form>

    <!-- Custom Picture preview -->
    <img alt="custom picture" *ngIf="customPictureData" [src]="customPictureData" />

    <!-- Map -->
    <agm-map
      #map
      *ngIf="!this.f.customPicture.value"

      [latitude]="this.f.lat.value"
      [longitude]="this.f.lng.value"
      [zoom]="this.f.zoom.value"
      [mapTypeId]="'hybrid'"
      [usePanning]="true"
      style="height: 70vh"
    >
      <agm-marker
        #marker
        [latitude]="this.f.lat.value"
        [longitude]="this.f.lng.value"
        [markerDraggable]="true"
        (dragEnd)="dragEnd($event)"
      ></agm-marker>
    </agm-map>
  </div>

  <div class="modal-footer">
    <input
      type="button"
      class="btn btn-outline-secondary"
      value="Annuler"
      (click)="close()"
      [disabled]="formSubmitted"
      ngbButton
    />
    <button
      type="submit"
      class="btn btn-outline-success"
      [disabled]="formSubmitted || !this.form.valid || (f.customPicture.value && !this.customPictureForm.valid)"
      ngbButton
    >
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>