import { UPCV3 } from "./../../../../../../../../models/upcv3/upc/upcv3";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

import { Component, OnInit, Input, OnDestroy } from "@angular/core";

import { CalendarEvent } from "angular-calendar";

import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import {
  UPCV3Service,
  DiffusionChartDataWithoutMetadata,
} from "src/app/services/upcv3/upcv3.service";
import { takeUntil } from "rxjs/operators";
import { Observable, Subject, Subscription } from "rxjs";
import { CalendarData } from "../groupStats.component";
import { ToasterService } from "angular2-toaster";
import * as moment from "moment";

// Diff Stats

@Component({
  selector: "app-upc-group-stats-diffusion",
  templateUrl: "./diffusionStats.component.html",
  styleUrls: ["./diffusionStats.component.css"],
})
export class DiffusionStatsComponent implements OnInit, OnDestroy {
  @Input("upcv3") upcv3: UPCV3;
  @Input("from") from: NgbDate;
  @Input("to") to: NgbDate;
  @Input() dateChanged: Observable<CalendarData>;

  private onDestroy$: Subject<void> = new Subject<void>();

  max: NgbDate;

  viewDate: Date = new Date();

  diffChartColors = [
    { name: "Aucune diffusion", value: "rgba(0, 0, 0, 0)" },
    { name: "Intensité : 1", value: "rgba(0, 0, 0, .1)" },
    { name: "Intensité : 2", value: "rgba(0, 0, 0, .2)" },
    { name: "Intensité : 3", value: "rgba(0, 0, 0, .3)" },
    { name: "Intensité : 4", value: "rgba(0, 0, 0, .4)" },
    { name: "Intensité : 5", value: "rgba(0, 0, 0, .5)" },
    { name: "Intensité : 6", value: "rgba(0, 0, 0, .6)" },
    { name: "Intensité : 7", value: "rgba(0, 0, 0, .7)" },
    { name: "Intensité : 8", value: "rgba(0, 0, 0, .8)" },
    { name: "Intensité : 9", value: "rgba(0, 0, 0, .9)" },
    { name: "Intensité : 10", value: "rgba(0, 0, 0, 1)" },
  ];

  diffStats: DiffusionChartDataWithoutMetadata[];
  diffChartData = [];
  events: CalendarEvent[] = [];

  private dateChangedSubscription: Subscription;

  constructor(
    public calendar: NgbCalendar,
    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    this.loadDiffusionsStats(
      { from: this.from, to: this.to },
      this.upcv3
    );

    this.dateChangedSubscription = this.dateChanged.subscribe(
      (calendarData) => {
        this.loadDiffusionsStats(calendarData, this.upcv3);
      }
    );
  }


  public ngOnDestroy(): void {
    this.dateChangedSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  private dateToString(date: NgbDate) {
    return (
      date.year +
      "-" +
      (date.month > 9 ? date.month : "0" + date.month) +
      "-" +
      (date.day > 9 ? date.day : "0" + date.day)
    );
  }

  loadDiffusionsStats(timeRange: CalendarData, upc: UPCV3) {
    this.upcv3Service
      .getDiffStats(
        upc.id,
        this.dateToString(timeRange.from),
        this.dateToString(timeRange.to)
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (res) => {
          this.diffStats = res;
          this.diffChartData = [];
          if (this.diffStats) {
            this.diffStats.forEach((data) => {
              var date = new Date(data.date).toLocaleDateString();
              var series = [];

              data.diffs.forEach((diff, index) => {
                var start = moment(diff.start, [moment.HTML5_FMT.TIME_SECONDS]);
                var stop = moment(diff.stop, [moment.HTML5_FMT.TIME_SECONDS]);
                // First diffusion of the day
                if (index == 0) {
                  var dayStart = moment("00:00:00", [moment.HTML5_FMT.TIME_SECONDS]);
                  series.push({ name: 'Aucune diffusion', value: Math.round((start.valueOf() - dayStart.valueOf()) / (1000 * 3600) * 100) / 100 });
                  series.push({
                    name: 'Intensité : ' + diff.intensity,
                    content: 'Début : ' + start.toDate().toLocaleTimeString() + ' - Fin : ' + stop.toDate().toLocaleTimeString(),
                    value: Math.round((stop.valueOf() - start.valueOf()) / (1000 * 3600) * 100) / 100
                  });
                }
  
                // Last diffusion of the day
                else if (index == data.diffs.length - 1) {
                  var dayStop = moment("23:59:59", [moment.HTML5_FMT.TIME_SECONDS]);
                  // Check previous
                  var previous = data.diffs[index - 1];
                  if (previous) {
                    var previousStop = moment(previous.stop, [moment.HTML5_FMT.TIME_SECONDS]);
                    series.push({ name: 'Aucune diffusion', value: Math.round((start.valueOf() - previousStop.valueOf()) / (1000 * 3600) * 100) / 100 });
                  }
  
                  series.push({
                    name: 'Intensité : ' + diff.intensity,
                    content: 'Début : ' + start.toDate().toLocaleTimeString() + ' - Fin : ' + stop.toDate().toLocaleTimeString(),
                    value: Math.round((stop.valueOf() - start.valueOf()) / (1000 * 3600) * 100) / 100
                  });
                  series.push({ name: 'Aucune diffusion', value: Math.round((dayStop.valueOf() - stop.valueOf()) / (1000 * 3600) * 100) / 100 });
                }
  
                // Inter diffusion
                else {
                  var previous = data.diffs[index - 1];
                  var previousStop = moment(previous.stop, [moment.HTML5_FMT.TIME_SECONDS]);
                  series.push({ name: 'Aucune diffusion', value: Math.round((start.valueOf() - previousStop.valueOf()) / (1000 * 3600) * 100) / 100 });
                  series.push({
                    name: 'Intensité : ' + diff.intensity,
                    content: 'Début : ' + start.toDate().toLocaleTimeString() + ' - Fin : ' + stop.toDate().toLocaleTimeString(),
                    value: Math.round((stop.valueOf() - start.valueOf()) / (1000 * 3600) * 100) / 100
                  });
                }
              });
  
              this.diffChartData.push({ name: date, series: series });
            });
          }

        },
        error: (err) => {
          this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des stats de diffusion");
          this.diffStats = [];
        }
      });
  }
}
