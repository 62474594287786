<div class="container-fluid">
  <div class="row mt-3">

    <div class="col-12 mb-3 text-center" *ngIf="!project"><i class="fa fa-circle-o-notch fa-spin"></i></div>

    <!-- Title -->
    <div class="col-12 mb-2" *ngIf="project">
      <h4 class="float-left">Version - {{ version.versionTypeToString }} - {{ version.numberOrder }}</h4>
    </div>

    <!-- Prices cards -->
    <div class="col-12 col-md-4 mb-2" *ngIf="project">
      <div class="card">
        <div class="card-body">
          Installation
          <h5 class="card-title float-right mb-0">
            {{ version.installationPrice }}  € 
            <small>HT</small>
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-2" *ngIf="project">
      <div class="card">
        <div class="card-body">
          Materiel
          <h5 class="card-title float-right mb-0">
            {{ version.materialPrice }}  € 
            <small>HT</small>
          </h5>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-2" *ngIf="project">
      <div class="card">
        <div class="card-body">
          Maintenance
          <h5 class="card-title float-right mb-0">
            {{ version.maintenancePrice }} € 
            <small>HT</small>
          </h5>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="col-12 mb-3" *ngIf="cad">
      <app-cad [cad]="cad"></app-cad>
    </div>

  </div>
</div>
