<div class="card mt-3">

  <!-- Loading -->
  <div class="card-body text-center" *ngIf="loading"><i class="fa fa-circle-o-notch fa-spin"></i></div>

  <!-- Form -->
  <form class="p-3" [formGroup]="upcv3Form" (ngSubmit)="onFormSubmit()" *ngIf="!loading">
    <ngb-accordion [destroyOnHide]="false">

      <!-- Reference flow rate -->
      <ngb-panel title="Débit de référence">
        <ng-template ngbPanelContent>

          <!-- co2FlowRefTrap -->
          <div class="form-group">
            <label for="co2FlowRefTrap">Flux de référence (nl/mn) *</label>
            <input id="co2FlowRefTrap" class="form-control" type="number" step="0.01" formControlName="co2FlowRefTrap" [ngClass]="{ 'is-invalid': uf.co2FlowRefTrap.errors }">
            <div class="invalid-feedback" *ngIf="uf.co2FlowRefTrap.errors">Champ obligatoire</div>
          </div>

          <!-- refFlowRateGperhour -->
          <div class="form-group">
            <label for="refFlowRateGperhour">Flux de référence (g/h) *</label>
            <input id="refFlowRateGperhour" class="form-control" type="number" step="0.01" formControlName="refFlowRateGperhour" [ngClass]="{ 'is-invalid': uf.refFlowRateGperhour.errors }">
            <div class="invalid-feedback" *ngIf="uf.refFlowRateGperhour.errors">Champ obligatoire</div>
          </div>

        </ng-template>
      </ngb-panel>

      <!-- Diffusion programs -->
      <ngb-panel title="Programmes de diffusion">
        <ng-template ngbPanelContent>
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
              <a ngbNavLink>Levé</a>
              <ng-template ngbNavContent>
                <div class="form-group mt-3">
                  <label for="sunriseDelay">Décallage +/- minutes *</label>
                  <input type="number" class="form-control" id="sunriseDelay" formControlName="sunriseDelay" [ngClass]="{ 'is-invalid': uf.sunriseDelay.errors }">
                  <div class="invalid-feedback" *ngIf="uf.sunriseDelay.errors">Champ obligatoire</div>
                </div>

                <!-- sunriseDuration -->
                <div class="form-group">
                  <label for="sunriseDuration">Durée minutes *</label>
                  <input type="number" class="form-control" id="sunriseDuration" formControlName="sunriseDuration" [ngClass]="{ 'is-invalid': uf.sunriseDuration.errors }">
                  <div class="invalid-feedback" *ngIf="uf.sunriseDuration.errors">Champ obligatoire</div>
                </div>

                <!-- sunriseIntensity -->
                <div class="form-group">
                  <label for="sunriseIntensity">Intensité *</label>
                  <select class="form-control" id="sunriseIntensity" formControlName="sunriseIntensity">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Couché</a>
              <ng-template ngbNavContent>
                 <!-- sunsetDelay -->
                 <div class="form-group mt-3">
                  <label for="sunsetDelay">Décallage +/- minutes *</label>
                  <input type="number" class="form-control" id="sunsetDelay" formControlName="sunsetDelay" [ngClass]="{ 'is-invalid': uf.sunsetDelay.errors }">
                  <div class="invalid-feedback" *ngIf="uf.sunsetDelay.errors">Champ obligatoire</div>
                </div>

                <!-- sunsetDuration -->
                <div class="form-group">
                  <label for="sunsetDuration">Durée minutes *</label>
                  <input type="number" class="form-control" id="sunsetDuration" formControlName="sunsetDuration" [ngClass]="{ 'is-invalid': uf.sunsetDuration.errors }">
                  <div class="invalid-feedback" *ngIf="uf.sunsetDuration.errors">Champ obligatoire</div>
                </div>

                <!-- sunsetIntensity -->
                <div class="form-group">
                  <label for="sunsetIntensity">Intensité *</label>
                  <select class="form-control" id="sunsetIntensity" formControlName="sunsetIntensity">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Programme 1</a>
              <ng-template ngbNavContent>
                 <!-- daysCode1 -->
                 <div class="form-group mt-3">
                  <label for="daysCode1">Mode de diffusion *</label>
                  <select class="form-control" id="daysCode1" formControlName="daysCode1">
                    <option value="All week">Tous les jours</option>
                    <option value="Midweek">Semaine</option>
                    <option value="Weekend">Weekend</option>
                    <option value="Monday">Lundi</option>
                    <option value="Tuesday">Mardi</option>
                    <option value="Wednesday">Mercredi</option>
                    <option value="Thursday">Jeudi</option>
                    <option value="Friday">Vendredi</option>
                    <option value="Saturday">Samedi</option>
                    <option value="Sunday">Dimanche</option>
                  </select>
                </div>

                <!-- startTime1 -->
                <div class="form-group">
                  <label for="startTime1">Début *</label>
                  <input type="time" class="form-control" id="startTime1" formControlName="startTime1" [ngClass]="{ 'is-invalid': uf.startTime1.errors }">
                  <div class="invalid-feedback" *ngIf="uf.startTime1.errors">Champ obligatoire</div>
                </div>

                <!-- endTime1 -->
                <div class="form-group">
                  <label for="endTime1">Fin *</label>
                  <input type="time" class="form-control" id="endTime1" formControlName="endTime1" [ngClass]="{ 'is-invalid': uf.endTime1.errors }">
                  <div class="invalid-feedback" *ngIf="uf.endTime1.errors">Champ obligatoire</div>
                </div>

                <!-- intensity1 -->
                <div class="form-group">
                  <label for="intensity1">Intensité *</label>
                  <select class="form-control" id="intensity1" formControlName="intensity1">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Programme 2</a>
              <ng-template ngbNavContent>
                <!-- daysCode2 -->
                <div class="form-group mt-3">
                  <label for="daysCode2">Mode de diffusion *</label>
                  <select class="form-control" id="daysCode2" formControlName="daysCode2">
                    <option value="All week">Tous les jours</option>
                    <option value="Midweek">Semaine</option>
                    <option value="Weekend">Weekend</option>
                    <option value="Monday">Lundi</option>
                    <option value="Tuesday">Mardi</option>
                    <option value="Wednesday">Mercredi</option>
                    <option value="Thursday">Jeudi</option>
                    <option value="Friday">Vendredi</option>
                    <option value="Saturday">Samedi</option>
                    <option value="Sunday">Dimanche</option>
                  </select>
                </div>

                <!-- startTime2 -->
                <div class="form-group">
                  <label for="startTime2">Début *</label>
                  <input type="time" class="form-control" id="startTime2" formControlName="startTime2" [ngClass]="{ 'is-invalid': uf.startTime2.errors }">
                  <div class="invalid-feedback" *ngIf="uf.startTime2.errors">Champ obligatoire</div>
                </div>

                <!-- endTime2 -->
                <div class="form-group">
                  <label for="endTime2">Fin *</label>
                  <input type="time" class="form-control" id="endTime2" formControlName="endTime2" [ngClass]="{ 'is-invalid': uf.endTime2.errors }">
                  <div class="invalid-feedback" *ngIf="uf.endTime2.errors">Champ obligatoire</div>
                </div>

                <!-- intensity2 -->
                <div class="form-group">
                  <label for="intensity2">Intensité *</label>
                  <select class="form-control" id="intensity2" formControlName="intensity2">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </ng-template>
            </li>
            
          </ul>
          <div [ngbNavOutlet]="nav"></div>
          
        </ng-template>
      </ngb-panel>

      <!-- Alarms -->
      <ngb-panel title="Alarmes">
        <ng-template ngbPanelContent>
          <ul ngbNav #nav="ngbNav" class="nav-tabs">
            <li ngbNavItem>
              <a ngbNavLink>Configuration</a>
              <ng-template ngbNavContent>
                <!-- alrResLowEn -->
                <div class="form-group mt-3">
                  <label for="alrResLowEn">Réserve active basse</label><br>
                  <ui-switch id="alrResLowEn" formControlName="alrResLowEn"></ui-switch>
                </div>

                <!-- alrResEmptyEn -->
                <div class="form-group">
                  <label for="alrResEmptyEn">Réserve active vide</label><br>
                  <ui-switch id="alrResEmptyEn" formControlName="alrResEmptyEn"></ui-switch>
                </div>

                <!-- alrPresInpEn -->
                <div class="form-group">
                  <label for="alrPresInpEn">Pression d'entrée moyenne</label><br>
                  <ui-switch id="alrPresInpEn" formControlName="alrPresInpEn"></ui-switch>
                </div>

                <!-- alrPresOutEn -->
                <div class="form-group">
                  <label for="alrPresOutEn">Pression de sortie moyenne</label><br>
                  <ui-switch id="alrPresOutEn" formControlName="alrPresOutEn"></ui-switch>
                </div>

                <!-- alrFlowAvgEn -->
                <div class="form-group">
                  <label for="alrFlowAvgEn">Flux moyen</label><br>
                  <ui-switch id="alrFlowAvgEn" formControlName="alrFlowAvgEn"></ui-switch>
                </div>

                <!-- alrPowBackEn -->
                <div class="form-group">
                  <label for="alrPowBackEn">Alimentation rétablie</label><br>
                  <ui-switch id="alrPowBackEn" formControlName="alrPowBackEn"></ui-switch>
                </div>

                <!-- alrPowDownEn -->
                <div class="form-group">
                  <label for="alrPowDownEn">Alimentation coupée</label><br>
                  <ui-switch id="alrPowDownEn" formControlName="alrPowDownEn"></ui-switch>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink>Paramètres</a>
              <ng-template ngbNavContent>
                <!-- alrResLowLevel -->
                <div class="form-group mt-3">
                  <label for="alrResLowLevel">Réserve active : Seuil du contenu (%) *</label>
                  <input type="number" class="form-control" id="alrResLowLevel" formControlName="alrResLowLevel" [ngClass]="{ 'is-invalid': uf.alrResLowLevel.errors }">
                  <div class="invalid-feedback" *ngIf="uf.alrResLowLevel.errors">Champ obligatoire</div>
                </div>

                <!-- alrResEmptyFlow -->
                <div class="form-group">
                  <label for="alrResEmptyFlow">Réserve active : Seuil du flux (%) *</label>
                  <input type="number" class="form-control" id="alrResEmptyFlow" formControlName="alrResEmptyFlow" [ngClass]="{ 'is-invalid': uf.alrResEmptyFlow.errors }">
                  <div class="invalid-feedback" *ngIf="uf.alrResEmptyFlow.errors">Champ obligatoire</div>
                </div>

                <!-- alrPresInpTol -->
                <div class="form-group">
                  <label for="alrPresInpTol">Réserve : Seuil de pression (%) *</label>
                  <input type="number" class="form-control" id="alrPresInpTol" formControlName="alrPresInpTol" [ngClass]="{ 'is-invalid': uf.alrPresInpTol.errors }">
                  <div class="invalid-feedback" *ngIf="uf.alrPresInpTol.errors">Champ obligatoire</div>
                </div>

                <!-- alrPresOutTol -->
                <div class="form-group">
                  <label for="alrPresOutTol">Sortie : Seuil de pression (%) *</label>
                  <input type="number" class="form-control" id="alrPresOutTol" formControlName="alrPresOutTol" [ngClass]="{ 'is-invalid': uf.alrPresOutTol.errors }">
                  <div class="invalid-feedback" *ngIf="uf.alrPresOutTol.errors">Champ obligatoire</div>
                </div>

                <!-- alrFlowSetTol -->
                <div class="form-group">
                  <label for="alrFlowSetTol">Débit : Seuil d'écart (%) *</label>
                  <input type="number" class="form-control" id="alrFlowSetTol" formControlName="alrFlowSetTol" [ngClass]="{ 'is-invalid': uf.alrFlowSetTol.errors }">
                  <div class="invalid-feedback" *ngIf="uf.alrFlowSetTol.errors">Champ obligatoire</div>
                </div>

                <!-- alrSmsNum0 -->
                <div class="form-group mt-5">
                  <label for="alrSmsNum0">Numéro de téléphone</label>
                  <input type="tel" class="form-control" id="alrSmsNum0" formControlName="alrSmsNum0">
                </div>
              </ng-template>
            </li>

          </ul>
          <div [ngbNavOutlet]="nav"></div>

         
        </ng-template>
      </ngb-panel>

      <!-- Communications -->
      <ngb-panel title="Communications">
        <ng-template ngbPanelContent>

          <!-- comWiFiName -->
          <div class="form-group">
            <label for="comWiFiName">WiFi : SSID *</label>
            <input type="text" class="form-control" id="comWiFiName" formControlName="comWiFiName" [ngClass]="{ 'is-invalid': uf.comWiFiName.errors }">
            <div class="invalid-feedback" *ngIf="uf.comWiFiName.errors">Champ obligatoire</div>
          </div>

          <!-- comWiFiPass -->
          <div class="form-group">
            <label for="comWiFiPass">WiFi : Mot de passe</label>
            <input type="text" class="form-control" id="comWiFiPass" formControlName="comWiFiPass">
          </div>

          <!-- upcLanguage -->
          <div class="form-group">
            <label for="upcLanguage">Langue *</label>
            <select class="form-control" id="upcLanguage" formControlName="upcLanguage">
              <option value="0">Français</option>
              <option value="1">Anglais</option>
            </select>
          </div>

          <!-- comGsmName -->
          <div class="form-group">
            <label for="comGsmName">Modem : Identifiant</label>
            <input type="text" class="form-control" id="comGsmName" formControlName="comGsmName">
          </div>

          <!-- comGsmPass -->
          <div class="form-group">
            <label for="comGsmPass">Modem : Mot de passe</label>
            <input type="text" class="form-control" id="comGsmPass" formControlName="comGsmPass">
          </div>

          <!-- comWebSrvUrl -->
          <div class="form-group">
            <label for="comWebSrvUrl">Serveur : URL *</label>
            <input type="text" class="form-control" id="comWebSrvUrl" formControlName="comWebSrvUrl" [ngClass]="{ 'is-invalid': uf.comWebSrvUrl.errors }">
            <div class="invalid-feedback" *ngIf="uf.comWebSrvUrl.errors">Champ obligatoire</div>
          </div>

        </ng-template>
      </ngb-panel>

       <ngb-panel title="Asservissement">
        <ng-template ngbPanelContent>

          <!-- comWiFiName -->
          <div class="form-group">
            <label for="upcCo2PidProp">PID P *</label>
            <input type="number" class="form-control" id="upcCo2PidProp" formControlName="upcCo2PidProp" [ngClass]="{ 'is-invalid': uf.upcCo2PidProp.errors }">
            <div class="invalid-feedback" *ngIf="uf.upcCo2PidProp.errors">Champ obligatoire</div>
          </div>

          <!-- comWiFiPass -->
          <div class="form-group">
            <label for="upcCo2PidInteg">PID I *</label>
            <input type="number" class="form-control" id="upcCo2PidInteg" formControlName="upcCo2PidInteg" [ngClass]="{ 'is-invalid': uf.upcCo2PidInteg.errors }">
            <div class="invalid-feedback" *ngIf="uf.upcCo2PidInteg.errors">Champ obligatoire</div>
          </div>

          <!-- upcLanguage -->
          <div class="form-group">
            <label for="upcCo2PidDiff">PID D *</label>
            <input type="number" class="form-control" id="upcCo2PidDiff" formControlName="upcCo2PidDiff" [ngClass]="{ 'is-invalid': uf.upcCo2PidDiff.errors }">
            <div class="invalid-feedback" *ngIf="uf.upcCo2PidDiff.errors">Champ obligatoire</div>
          </div>


        </ng-template>
      </ngb-panel>

    </ngb-accordion>

    <button type="submit" class="btn btn-primary mt-3" [disabled]="upcv3FormSubmitted || this.upcv3Form.invalid" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="upcv3FormSubmitted"></i>
      <span *ngIf="!upcv3FormSubmitted">Enregistrer</span>
    </button>
    <div *ngIf="this.upcv3Form.invalid" class="text-danger">Il y a des erreurs dans le formulaire. Veuillez les régler afin de pouvoir enregistrer.</div>
  </form>

</div>
