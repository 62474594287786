import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";

import * as Sentry from "@sentry/angular-ivy";

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// angular2-toaster
import { ToasterModule, ToasterService } from 'angular2-toaster';

// material-ui Components
import { MaterialModule } from './material-module';

// maps
import { AgmCoreModule } from '@agm/core';

// Cookies
import { CookieService } from 'ngx-cookie-service';

// App & Routing
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// API
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { JwtInterceptor } from './auth/jwt.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';

// Header
import { HeaderComponent } from './header/header.component';

// Pages
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { ProjectsModule } from './pages/projects/projects.module';
import { StocksModule } from './pages/stocks/stocks.module';
import { DesignersModule } from './pages/designers/designers.module';
import { OperatorsModule } from './pages/operators/operators.module';
import { LoginModule } from './pages/login/login.module';
import { SettingsModule } from './pages/settings/settings.module';
import { SitesModule } from './pages/sites/sites.module';
import { TasksModule } from './pages/tasks/tasks.module';
import { GDPModule } from './pages/gdp/gdp.module';
import { EventsModule } from './pages/sites/site/upc/events/events.module';
import { ParameterbeltsModule } from './pages/parameterbelts/parameterbelts.module';
import { UiSwitchModule } from 'ngx-ui-switch';

import { UpcMcuTableModule } from 'src/app/pages/upcmcu/upcMcuTable.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './routing/reuse-strategy';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// Nécessaire au chargement des locales FR 
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);


@NgModule({
  declarations: [
    AppComponent,
    // Header
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgbModule,
    ToasterModule.forRoot(),
    HttpClientModule,
    UiSwitchModule,
    UpcMcuTableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAMHDF9VrzlRopZsJeEgMRxZgf73RPf8eA'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // Pages
    DashboardModule,
    ProjectsModule,
    StocksModule,
    DesignersModule,
    OperatorsModule,
    LoginModule,
    SettingsModule,
    SitesModule,
    TasksModule,
    EventsModule,
    GDPModule,
    ParameterbeltsModule,
  ],
  providers: [
    ToasterService,
    AuthGuard,
    AuthService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    CookieService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,

    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
