import { BottleType } from "./bottleType";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { StockManager } from "./stockManager";
import { Location } from "./location";


@jsonObject
export class Stock extends Location {
  @jsonArrayMember(() => BottleType)
  bottleTypes: BottleType[];
  
  @jsonArrayMember(() => StockManager)
  stockmanagers: StockManager[];

  @jsonMember
  forceBarcodesEnabled: boolean;
  
  @jsonMember
  fullBottlesNumber: number;
  
  @jsonMember
  emptyBottlesNumber: number;
  
  @jsonMember
  usedBottlesNumber: number;

  get bottleTypesString(): string {
    var names = [];
    this.bottleTypes.forEach(bottleType => names.push(bottleType.designationString));
    return names.join(' | ');
  }

  static loadFromJSON(json): Stock {
    let serializer = new TypedJSON(Stock);
		return serializer.parse(json);
  }
}