import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

// API
import { AuthGuard } from "./auth/auth.guard";

// Pages
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { SitesComponent } from "./pages/sites/sites.component";
import { AddSiteComponent } from "./pages/sites/add-site/add-site.component";
import { SiteComponent } from "./pages/sites/site/site.component";
import { UPCComponent } from "./pages/sites/site/upc/upc.component";
import { UPCGroupComponent } from "./pages/sites/site/upc/upcGroup.component";
import { InterventionComponent } from "./pages/sites/site/interventions/intervention/intervention.component";
import { OperatorsComponent } from "./pages/operators/operators.component";
import { StocksComponent } from "./pages/stocks/stocks.component";
import { StockComponent } from "./pages/stocks/stock/stock.component";
import { ProjectsComponent } from "./pages/projects/projects.component";
import { ProjectComponent } from "./pages/projects/project/project.component";
import { VersionComponent } from "./pages/projects/project/version/version.component";
import { SettingsComponent } from "./pages/settings/settings.component";
import { DesignersComponent } from "./pages/designers/designers.component";
import { TasksComponent } from "./pages/tasks/tasks.component";

import { AddInterventionComponent } from "./pages/sites/site/interventions/add-intervention/add-intervention.component";
import { ParameterbeltsComponent } from "./pages/parameterbelts/parameterbelts.component";

import { ProjectsComponent as GDPProjectsComponent } from "./pages/gdp/projects.component";
import { ProjectComponent as GDPProjectComponent } from "./pages/gdp/project/project.component";
import { VersionComponent as GDPVersionComponent } from "./pages/gdp/project/version/version.component";
import { GroupebeltComponent } from "./pages/dashboard/groupebelt/groupebelt.component";
import { UpcMcuTableComponent } from "./pages/upcmcu/upcMcuTable.component";
import { InterventionLegacyComponent } from './pages/sites/site/interventions/intervention-legacy/intervention-legacy.component';

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: true },
  },

  {
    path: "sites",
    component: SitesComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "site/add",
    component: AddSiteComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "site/:id",
    component: SiteComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "upc/:version/:id",
    component: UPCComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  // for UPCGroupComponent, no routing for v2, as v2 has no group capability in backend
  {
    path: "upc/v3/groupe/:groupId",
    component: UPCGroupComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "site/:siteId/intervention/add",
    component: AddInterventionComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "intervention/v3/:id",
    component: InterventionComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "intervention/legacy/:id",
    component: InterventionLegacyComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "operators",
    component: OperatorsComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "designers",
    component: DesignersComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },

  {
    path: "stocks",
    component: StocksComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "stock/:id",
    component: StockComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },

  {
    path: "projects",
    component: ProjectsComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "project/:id",
    component: ProjectComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "version/:id",
    component: VersionComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },

  {
    path: "gdp",
    component: GDPProjectsComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "gdp/:id",
    component: GDPProjectComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },
  {
    path: "gdp/:project_id/version/:version_id",
    component: GDPVersionComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },

  {
    path: "tasks",
    component: TasksComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },

  {
    path: "settings",
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { reuseRoute: false },
  },

  { path: "login", component: LoginComponent, data: { reuseRoute: false } },
  {
    path: "groupBelt",
    component: GroupebeltComponent,
    data: { reuseRoute: false },
  },

  { path: "", redirectTo: "/dashboard", pathMatch: "full" },
  {
    path: "parametersBelt",
    component: ParameterbeltsComponent,
    data: { reuseRoute: false },
  },
  {
    path: "upcmcus",
    component: UpcMcuTableComponent,
    data: { reuseRoute: false },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
