<div class="d-flex justify-content-center" *ngIf="loadingData; else dataLoaded">
  <i class="fa fa-circle-o-notch fa-spin mt-6"></i>
</div>
<ng-template #dataLoaded>
  <div class="row">
    <hr />

    <!-- Status -->
    <div class="col-12 text-center">
      <!-- Disable -->
      <button
        type="button"
        class="btn btn-outline-success mr-2"
        [ngClass]="{
          active: this.upcv3.generalParameters.upcStatus == 0,
          'btn-outline-warning': vars.upcMode.changed
        }"
        [disabled]="isSync"
        (click)="
          this.upcv3.generalParameters.upcStatus = 0;
          vars.upcMode.value = 0;
          vars.upcMode.changed = true
        "
      >
        Inactif
      </button>

      <!-- Enable -->
      <button
        type="button"
        class="btn btn-outline-success mr-2"
        [ngClass]="{
          active:
            this.upcv3.generalParameters.upcStatus == 1 ||
            this.upcv3.generalParameters.upcStatus == 5 ||
            this.upcv3.generalParameters.upcStatus == 6 ||
            this.upcv3.generalParameters.upcStatus == 7 ||
            this.upcv3.generalParameters.upcStatus == 100,
          'btn-outline-warning': vars.upcMode.changed
        }"
        [disabled]="isSync"
        (click)="
          this.upcv3.generalParameters.upcStatus = 1;
          vars.upcMode.value = 1;
          vars.upcMode.changed = true
        "
      >
        Actif
      </button>

      <!-- Ajustements -->
      <button
        type="button"
        class="btn btn-outline-success mr-2"
        [ngClass]="{
          active: this.upcv3.generalParameters.upcStatus == 2,
          'btn-outline-warning': vars.upcMode.changed
        }"
        [disabled]="isSync"
        (click)="
          this.upcv3.generalParameters.upcStatus = 2;
          vars.upcMode.value = 2;
          vars.upcMode.changed = true
        "
      >
        Ajustements
      </button>

      <!-- Check -->
      <button
        type="button"
        class="btn btn-outline-success"
        [ngClass]="{
          active: this.upcv3.generalParameters.upcStatus == 3,
          'btn-outline-warning': vars.upcMode.changed
        }"
        [disabled]="isSync"
        (click)="
          this.upcv3.generalParameters.upcStatus = 3;
          vars.upcMode.value = 3;
          vars.upcMode.changed = true
        "
      >
        Vérification
      </button>

      <p class="mt-3 mb-0">
        <small class="text-muted mr-2"
          >Dernière mise à jour des variables :
          <b>{{ upcv3.upcLastSyncDatetime?.toLocaleString() ?? '-' }}</b></small
        >
        <button
          type="button"
          class="btn btn-success btn-sm"
          [disabled]="isSync"
          (click)="sync()"
        >
          Synchroniser
        </button>
        <ng-container *ngIf="needSync">
          <br />
          <small class="text-warning"
            >Des variables sont en attente de synchronisation</small
          >
        </ng-container>
      </p>
    </div>

    <hr />

    <div class="col-12">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- General -->
        <li ngbNavItem>
          <a ngbNavLink>Général</a>
          <ng-template ngbNavContent>
            <div class="card card-body border-top-0">
              <div class="row">
                <!-- Modem -->
                <div class="col-sm-6">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Modem</h5>
                      <dl class="row mb-0">
                        <!-- comGsmMode -->
                        <dd class="col-md-6 mb-0">Mode de connection</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ upcv3.communicationParameters.comGsmModeString }}
                        </dt>

                        <!-- comGsmLevel -->
                        <dd class="col-md-6 mb-0">Signal</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ this.upcv3.communicationParameters.comGsmLevel }}
                          dB
                        </dt>

                        <!-- comGsmIpAdr -->
                        <dd class="col-md-6 mb-0">Adresse IP</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          {{ this.upcv3.communicationParameters.comGsmIpAdr }}
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Capacitors -->
                <div class="col-sm-6">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Batteries</h5>
                      <dl class="row mb-0">
                        <!-- upcBattChrg -->
                        <dd class="col-md-6 mb-0">Tension batterie</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ this.upcv3.generalParameters.upcBattChrg }} V
                        </dt>

                        <!-- upcBattTemp -->
                        <dd class="col-md-6 mb-0">Température batterie</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          {{ this.upcv3.generalParameters.upcBattTemp }} °C
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Firmware -->
                <div class="col-sm-6">
                  <div class="card bg-light">
                    <div class="card-body">
                      <h5 class="card-title">Firmware</h5>
                      <dl class="row mb-0">
                        <!-- upcFwVer -->
                        <dd class="col-md-6 mb-0">Version</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ upcv3.generalParameters.upcFwVer }}
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <!-- CO2 Status -->
        <li ngbNavItem>
          <a ngbNavLink>Status CO2</a>
          <ng-template ngbNavContent>
            <div class="card card-body border-top-0">
              <div class="row">
                <!-- CO2 Status -->
                <div class="col-lg-4">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Status CO2</h5>
                      <dl class="row mb-0">
                        <!-- co2ResType -->
                        <dd class="col-md-6 mb-0">Caractère de la réserve</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <select
                            class="custom-select custom-select-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2ResType.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.co2ResType.value"
                            (ngModelChange)="vars.co2ResType.changed = true"
                          >
                            <option value="0">Symétrique</option>
                            <option value="1">B1 Principale</option>
                            <option value="2">B2 Principale</option>
                          </select>
                        </dt>

                        <!-- co2ResActive -->
                        <dd class="col-md-6 mb-0">Réserve active</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <select
                            class="custom-select custom-select-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2ResActive.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.co2ResActive.value"
                            (ngModelChange)="vars.co2ResActive.changed = true"
                          >
                            <option value="0">B0</option>
                            <option value="1">B1</option>
                            <option value="2">B2</option>
                          </select>
                        </dt>

                        <!-- co2ResActPrev -->
                        <dd class="col-md-6 mb-0">Est. rés. active vide</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ vars.co2ResActPrev.value.toLocaleString() }}
                        </dt>

                        <!-- co2ResInactPrev -->
                        <dd class="col-md-6 mb-0">Est. rés. inactive vide</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          {{ vars.co2ResInactPrev.value.toLocaleString() }}
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- B1 -->
                <div class="col-sm-6 col-lg-4">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">B1</h5>
                      <!-- co2Res1Enabled -->
                      <dl class="row mb-0">
                        <dd class="col-md-6 mb-0">Utilisée ?</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcFwVer < 274
                            "
                            [(ngModel)]="vars.co2Res1Enabled.value"
                            (valueChange)="vars.co2Res1Enabled.changed = true"
                            [ngbTooltip]="
                              this.upcv3.generalParameters.upcFwVer < 274
                                ? 'Disponible à partir de la version 274'
                                : ''
                            "
                            [switchColor]="(vars.co2Res1Enabled.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>
                      </dl>
                      <!-- co2Res1Status -->
                      <dl class="row mb-0">
                        <dd class="col-md-6 mb-0">État du contenu</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <select
                            class="custom-select custom-select-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2Res1Status.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.co2Res1Status.value"
                            (ngModelChange)="vars.co2Res1Status.changed = true"
                          >
                            <option value="0">Vide</option>
                            <option value="1">Résiduelle</option>
                            <option value="2">Disponible</option>
                          </select>
                        </dt>

                        <!-- co2Res1ActVol -->
                        <dd class="col-md-6 mb-0">Contenu actuel [kg]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2Res1ActVol.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2Res1ActVol.value"
                            (ngModelChange)="vars.co2Res1ActVol.changed = true"
                          />
                        </dt>
                        <br /><br />
                        <dd class="col-md-6 mb-0">Capacité [kg]</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2Res1StartVol.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2Res1StartVol.value"
                            (ngModelChange)="
                              vars.co2Res1StartVol.changed = true
                            "
                          />
                        </dt>
                        <br /><br />
                        <table>
                          <tr *ngFor="let desig of desigB1A; let i = index">
                            <td>{{ desig + ", " }}</td>
                            <td>{{ marqueB1[i] }}</td>
                            <td>{{ codeB1[i] }}</td>
                          </tr>
                        </table>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- B2 -->
                <div class="col-sm-6 col-lg-4">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">B2</h5>
                      <!-- co2Res2Enabled -->
                      <dl class="row mb-0">
                        <dd class="col-md-6 mb-0">Utilisée ?</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcFwVer < 274
                            "
                            [(ngModel)]="vars.co2Res2Enabled.value"
                            (valueChange)="vars.co2Res2Enabled.changed = true"
                            [ngbTooltip]="
                              this.upcv3.generalParameters.upcFwVer < 274
                                ? 'Disponible à partir de la version 274'
                                : ''
                            "
                            [switchColor]="(vars.co2Res2Enabled.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>
                      </dl>
                      <!-- co2Res2Status -->
                      <dl class="row mb-0">
                        <dd class="col-md-6 mb-0">État du contenu</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <select
                            class="custom-select custom-select-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2Res2Status.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.co2Res2Status.value"
                            (ngModelChange)="vars.co2Res2Status.changed = true"
                          >
                            <option value="0">Vide</option>
                            <option value="1">Résiduelle</option>
                            <option value="2">Disponible</option>
                          </select>
                        </dt>

                        <!-- co2Res2ActVol -->
                        <dd class="col-md-6 mb-0">Contenu actuel [kg]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2Res2ActVol.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2Res2ActVol.value"
                            (ngModelChange)="vars.co2Res2ActVol.changed = true"
                          />
                        </dt>
                        <br /><br />
                        <dd class="col-md-6 mb-0">Capacité [kg]</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2Res2StartVol.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2Res2StartVol.value"
                            (ngModelChange)="
                              vars.co2Res2StartVol.changed = true
                            "
                          />
                        </dt>
                        <br /><br />
                        <table>
                          <tr *ngFor="let desig of desigB2A; let i = index">
                            <td>{{ desig + ", " }}</td>
                            <td>{{ marqueB2[i] }}</td>
                            <td>{{ codeB2[i] }}</td>
                          </tr>
                        </table>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Bouteilles B1-->
                <div class="col-lg-6">
                  <div class="card bg-light">
                    <div class="card-body">
                      <h5>Bouteilles en B1</h5>
                      <bottles-table [bottles]="bottlesB1" [displayedColumns]="displayedColumns"></bottles-table>
                    </div>
                  </div>
                </div>

                <!-- Bouteilles B2-->
                <div class="col-lg-6">
                  <div class="card bg-light">
                    <div class="card-body">
                      <h5>Bouteilles en B2</h5>
                      <bottles-table [bottles]="bottlesB2" [displayedColumns]="displayedColumns"></bottles-table>
                    </div>
                  </div>
                </div>

                <!-- Bouteilles Site-->
                <div class="col-lg-12 mt-2">
                  <div class="card bg-light">
                    <div class="card-body">
                      <h5>Bouteilles sur Site</h5>
                      <bottles-table [bottles]="bottlesSite" [displayedColumns]="displayedColumns"></bottles-table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </li>

        <!-- Programs -->
        <li ngbNavItem>
          <a ngbNavLink>Programmes</a>
          <ng-template ngbNavContent>
            <div class="card card-body border-top-0">
              <div class="row">
                <!-- Sun diffusion programs -->
                <div class="col-12">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <div
                        *ngIf="upcv3.debut"
                        style="text-align: center"
                        class="text-warning"
                      >
                        {{ textSynctemp }}
                      </div>
                      <div style="text-align: center">
                        <button
                          type="button"
                          (click)="onPerm(); diffCanvas.draw()"
                          [ngClass]="{
                            'mr-3 btn btn-light': !permSync,
                            'mr-3 btn btn-primary': permSync
                          }"
                        >
                          Permanent</button
                        ><button
                          type="button"
                          (click)="onTemp(); diffCanvas.draw()"
                          [ngClass]="{
                            'ml-3 btn btn-light': !tempSync,
                            'ml-3 btn btn-primary': tempSync
                          }"
                        >
                          Temporaire
                        </button>
                      </div>
                      <div class="row" *ngIf="tempSync">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <div class="input-group">
                              <input
                                class="form-control"
                                [(ngModel)]="from"
                                ngbDatepicker
                                #dFrom="ngbDatepicker"
                                [ngClass]="{ 'is-invalid': wrongRange }"
                                placeholder="Début"
                                (ngModelChange)="onChange()"
                              />
                              <div class="input-group-append">
                                <button
                                  class="btn btn-outline-secondary"
                                  (click)="dFrom.toggle()"
                                  type="button"
                                >
                                  <i class="fa fa-calendar"></i>
                                </button>
                              </div>
                              <div class="invalid-feedback" *ngIf="wrongRange">
                                La date de début est après la date de fin ou le
                                champ est vide
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-4" style="margin-left: 500px">
                          <div class="form-group">
                            <div class="input-group">
                              <input
                                class="form-control"
                                [(ngModel)]="to"
                                ngbDatepicker
                                #dTo="ngbDatepicker"
                                (ngModelChange)="onChange()"
                                [ngClass]="{ 'is-invalid': wrongRange }"
                                placeholder="Fin"
                              />
                              <div class="input-group-append">
                                <button
                                  class="btn btn-outline-secondary"
                                  (click)="dTo.toggle()"
                                  type="button"
                                >
                                  <i class="fa fa-calendar"></i>
                                </button>
                              </div>
                              <div class="invalid-feedback" *ngIf="wrongRange">
                                La date de début est après la date de fin ou le
                                champ est vide
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h5 class="card-title">Programmes Aube / Crépuscule</h5>
                      <table class="table table-borderless table-sm mb-0">
                        <!-- Sunrise -->
                        <tr>
                          <th class="align-middle">Aube</th>

                          <!-- sunrise_sign / sunrise_delay -->
                          <td>
                            <select
                              class="custom-select custom-select-sm d-inline-block mb-1"
                              [ngClass]="{
                                'bg-warning': vars.sunrise_sign.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunrise_sign.value"
                              (ngModelChange)="
                                vars.sunrise_sign.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                              style="width: 36%"
                            >
                              <option value="+">+</option>
                              <option value="-">-</option>
                            </select>
                            <input
                              type="time"
                              class="form-control form-control-sm d-inline-block"
                              [ngClass]="{
                                'bg-warning': vars.sunrise_delay.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunrise_delay.value"
                              (ngModelChange)="
                                vars.sunrise_delay.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                              style="width: 61%"
                            />
                          </td>

                          <!-- sunrise_duration -->
                          <td>
                            <input
                              type="time"
                              class="form-control form-control-sm"
                              [ngClass]="{
                                'bg-warning': vars.sunrise_duration.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunrise_duration.value"
                              (ngModelChange)="
                                vars.sunrise_duration.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                            />
                          </td>

                          <!-- sunrise_intensity -->
                          <td>
                            <select
                              class="custom-select custom-select-sm"
                              [ngClass]="{
                                'bg-warning': vars.sunrise_intensity.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunrise_intensity.value"
                              (ngModelChange)="
                                vars.sunrise_intensity.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                            >
                              <option
                                *ngFor="let intensity of intensities"
                                [value]="intensity.value"
                              >
                                {{ intensity.name }}
                              </option>
                            </select>
                          </td>

                          <!-- start_stop -->
                          <td>
                            {{ getSunriseStartStop() }}
                          </td>
                        </tr>

                        <!-- Sunset -->
                        <tr>
                          <th class="align-middle">Crépuscule</th>

                          <!-- sunset_sign / sunset_delay -->
                          <td>
                            <select
                              class="custom-select custom-select-sm d-inline-block mb-1"
                              [ngClass]="{
                                'bg-warning': vars.sunset_sign.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunset_sign.value"
                              (ngModelChange)="
                                vars.sunset_sign.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                              style="width: 36%"
                            >
                              <option value="+">+</option>
                              <option value="-">-</option>
                            </select>
                            <input
                              type="time"
                              class="form-control form-control-sm d-inline-block"
                              [ngClass]="{
                                'bg-warning': vars.sunset_delay.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunset_delay.value"
                              (ngModelChange)="
                                vars.sunset_delay.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                              style="width: 61%"
                            />
                          </td>

                          <!-- sunset_duration -->
                          <td>
                            <input
                              type="time"
                              class="form-control form-control-sm"
                              [ngClass]="{
                                'bg-warning': vars.sunset_duration.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunset_duration.value"
                              (ngModelChange)="
                                vars.sunset_duration.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                            />
                          </td>

                          <!-- sunset_intensity -->
                          <td>
                            <select
                              class="custom-select custom-select-sm"
                              [ngClass]="{
                                'bg-warning': vars.sunset_intensity.changed
                              }"
                              [disabled]="isSync"
                              [(ngModel)]="vars.sunset_intensity.value"
                              (ngModelChange)="
                                vars.sunset_intensity.changed = true;
                                diffCanvas.draw();
                                calculateConsumption()
                              "
                            >
                              <option
                                *ngFor="let intensity of intensities"
                                [value]="intensity.value"
                              >
                                {{ intensity.name }}
                              </option>
                            </select>
                          </td>

                          <!-- start_stop -->
                          <td>
                            {{ getSunsetStartStop() }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                <!-- Diffusion programs -->
                <div class="col-12">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Programmes de diffusion</h5>
                      <table class="table table-borderless table-sm mb-0">
                        <tbody>
                          <!-- Program 1 -->
                          <tr>
                            <th class="align-middle">P1</th>

                            <!-- daysCode_1 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_1.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_1.value"
                                (ngModelChange)="
                                  vars.daysCode_1.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_1 / end_1 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_1.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_1.value"
                                (ngModelChange)="
                                  vars.start_1.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_1.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_1.value"
                                (ngModelChange)="
                                  vars.end_1.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_1 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_1.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_1.value"
                                (ngModelChange)="
                                  vars.intensity_1.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 2 -->
                          <tr>
                            <th class="align-middle">P2</th>

                            <!-- daysCode_2 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_2.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_2.value"
                                (ngModelChange)="
                                  vars.daysCode_2.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_2 / end_2 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_2.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_2.value"
                                (ngModelChange)="
                                  vars.start_2.changed = true; diffCanvas.draw()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_2.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_2.value"
                                (ngModelChange)="
                                  vars.end_2.changed = true; diffCanvas.draw()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_2 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_2.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_2.value"
                                (ngModelChange)="
                                  vars.intensity_2.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 3 -->
                          <tr>
                            <th class="align-middle">P3</th>

                            <!-- daysCode_3 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_3.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_3.value"
                                (ngModelChange)="
                                  vars.daysCode_3.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_3 / end_3 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_3.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_3.value"
                                (ngModelChange)="
                                  vars.start_3.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_3.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_3.value"
                                (ngModelChange)="
                                  vars.end_3.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_3 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_3.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_3.value"
                                (ngModelChange)="
                                  vars.intensity_3.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 4 -->
                          <tr>
                            <th class="align-middle">P4</th>

                            <!-- daysCode_4 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_4.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_4.value"
                                (ngModelChange)="
                                  vars.daysCode_4.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_4 / end_4 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_4.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_4.value"
                                (ngModelChange)="
                                  vars.start_4.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_4.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_4.value"
                                (ngModelChange)="
                                  vars.end_4.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_4 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_4.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_4.value"
                                (ngModelChange)="
                                  vars.intensity_4.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 5 -->
                          <tr>
                            <th class="align-middle">P5</th>

                            <!-- daysCode_5 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_5.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_5.value"
                                (ngModelChange)="
                                  vars.daysCode_5.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_5 / end_5 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_5.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_5.value"
                                (ngModelChange)="
                                  vars.start_5.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_5.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_5.value"
                                (ngModelChange)="
                                  vars.end_5.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_5 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_5.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_5.value"
                                (ngModelChange)="
                                  vars.intensity_5.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 6 -->
                          <tr>
                            <th class="align-middle">P6</th>

                            <!-- daysCode_6 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_6.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_6.value"
                                (ngModelChange)="
                                  vars.daysCode_6.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_6 / end_6 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_6.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_6.value"
                                (ngModelChange)="
                                  vars.start_6.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_6.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_6.value"
                                (ngModelChange)="
                                  vars.end_6.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_6 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_6.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_6.value"
                                (ngModelChange)="
                                  vars.intensity_6.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 7 -->
                          <tr>
                            <th class="align-middle">P7</th>

                            <!-- daysCode_7 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_7.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_7.value"
                                (ngModelChange)="
                                  vars.daysCode_7.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_7 / end_7 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_7.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_7.value"
                                (ngModelChange)="
                                  vars.start_7.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_7.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_7.value"
                                (ngModelChange)="
                                  vars.end_7.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_7 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_7.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_7.value"
                                (ngModelChange)="
                                  vars.intensity_7.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 8 -->
                          <tr>
                            <th class="align-middle">P8</th>

                            <!-- daysCode_8 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_8.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_8.value"
                                (ngModelChange)="
                                  vars.daysCode_8.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_8 / end_8 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_8.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_8.value"
                                (ngModelChange)="
                                  vars.start_8.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_8.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_8.value"
                                (ngModelChange)="
                                  vars.end_8.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_8 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_8.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_8.value"
                                (ngModelChange)="
                                  vars.intensity_8.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 9 -->
                          <tr>
                            <th class="align-middle">P9</th>

                            <!-- daysCode_9 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_9.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_9.value"
                                (ngModelChange)="
                                  vars.daysCode_9.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_9 / end_9 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_9.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_9.value"
                                (ngModelChange)="
                                  vars.start_9.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{ 'bg-warning': vars.end_9.changed }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_9.value"
                                (ngModelChange)="
                                  vars.end_9.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_9 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_9.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_9.value"
                                (ngModelChange)="
                                  vars.intensity_9.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <!-- Program 10 -->
                          <tr>
                            <th class="align-middle">P10</th>

                            <!-- daysCode_10 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.daysCode_10.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.daysCode_10.value"
                                (ngModelChange)="
                                  vars.daysCode_10.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let daysCode of daysCodes"
                                  [value]="daysCode.value"
                                >
                                  {{ daysCode.name }}
                                </option>
                              </select>
                            </td>

                            <!-- start_10 / end_10 -->
                            <td>
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.start_10.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.start_10.value"
                                (ngModelChange)="
                                  vars.start_10.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                              -
                              <input
                                type="time"
                                class="form-control form-control-sm d-inline-block"
                                [ngClass]="{
                                  'bg-warning': vars.end_10.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.end_10.value"
                                (ngModelChange)="
                                  vars.end_10.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                                style="width: 45%"
                              />
                            </td>

                            <!-- intensity_10 -->
                            <td>
                              <select
                                class="custom-select custom-select-sm"
                                [ngClass]="{
                                  'bg-warning': vars.intensity_10.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.intensity_10.value"
                                (ngModelChange)="
                                  vars.intensity_10.changed = true;
                                  diffCanvas.draw();
                                  calculateConsumption()
                                "
                              >
                                <option
                                  *ngFor="let intensity of intensities"
                                  [value]="intensity.value"
                                >
                                  {{ intensity.name }}
                                </option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <!-- Chart -->
                <div class="col-12">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Graphique</h5>
                      <div class="text-center">
                        <diff-canvas
                          #diffCanvas
                          [vars]="vars"
                          [upcv3]="upcv3"
                        ></diff-canvas>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Calculations -->
                <div class="col-12">
                  <div class="card bg-light">
                    <div class="card-body">
                      <h5 class="card-title">Consommations théoriques</h5>
                      <dl class="row mb-0">
                        <!-- Daily -->
                        <dd class="col-md-6 mb-0">Par jour</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ dailyConsumption }} kg
                        </dt>

                        <!-- Weekly -->
                        <dd class="col-md-6 mb-0">Par semaine</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ weeklyConsumption }} kg
                        </dt>

                        <!-- Monthly -->
                        <dd class="col-md-6 mb-0">Par mois</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          {{ monthlyConsumption }} kg
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <!-- Alarms -->
        <li ngbNavItem>
          <a ngbNavLink>Alarmes</a>
          <ng-template ngbNavContent>
            <div class="card card-body border-top-0">
              <div class="row">
                <!-- Reserve Low Alarm -->
                <div class="col-sm-6 col-lg-4">
                  <div
                    class="card bg-light mb-2"
                  >
                    <div class="card-body">
                      <h5 class="card-title">Réserve active basse</h5>
                      <dl class="row mb-0">
                        <!-- alrResLowEn -->
                        <dd class="col-md-6 mb-0">E-mail</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrResLowEn.value"
                            (valueChange)="vars.alrResLowEn.changed = true"
                            [switchColor]="(vars.alrResLowEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>

                        <!-- alrResLowLevel -->
                        <dd class="col-md-6 mb-0">Seuil contenu [%]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.alrResLowLevel.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrResLowLevel.value"
                            (ngModelChange)="vars.alrResLowLevel.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Reserve Empty Alarm -->
                <div class="col-sm-6 col-lg-4">
                  <div
                    class="card bg-light mb-2"
                  >
                    <div class="card-body">
                      <h5 class="card-title">Réserve active vide</h5>
                      <dl class="row mb-0">
                        <!-- alrResEmptyEn -->
                        <dd class="col-md-6 mb-0">E-mail</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrResEmptyEn.value"
                            (valueChange)="vars.alrResEmptyEn.changed = true"
                            [switchColor]="(vars.alrResEmptyEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>

                        <!-- alrResEmptyFlow -->
                        <dd class="col-md-6 mb-0">Seuil flux [%]</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.alrResEmptyFlow.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrResEmptyFlow.value"
                            (ngModelChange)="
                              vars.alrResEmptyFlow.changed = true
                            "
                          />
                        </dt>

                        <!-- alrResEmptyTest -->
                        <dd class="col-md-6 mb-0">
                          Période de test de rés. vide [s]
                        </dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            min="0"
                            [ngClass]="{
                              'bg-warning': vars.alrResEmptyTest.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrResEmptyTest.value"
                            (ngModelChange)="
                              vars.alrResEmptyTest.changed = true
                            "
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Input Pressure Alarm -->
                <div class="col-sm-6 col-lg-4">
                  <div
                    class="card bg-light mb-2"
                  >
                    <div class="card-body">
                      <h5 class="card-title">Pression d'entrée moyenne</h5>
                      <dl class="row mb-0">
                        <!-- alrPresInpEn -->
                        <dd class="col-md-6 mb-0">E-mail</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrPresInpEn.value"
                            (valueChange)="vars.alrPresInpEn.changed = true"
                            [switchColor]="(vars.alrPresInpEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>

                        <!-- alrPresInpTol -->
                        <dd class="col-md-6 mb-0">Seuil pression [%]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.alrPresInpTol.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrPresInpTol.value"
                            (ngModelChange)="vars.alrPresInpTol.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Output Pressure Alarm -->
                <div class="col-sm-6 col-lg-4">
                  <div
                    class="card bg-light mb-2"
                  >
                    <div class="card-body">
                      <h5 class="card-title">Pression de sortie moyenne</h5>
                      <dl class="row mb-0">
                        <!-- alrPresOutEn -->
                        <dd class="col-md-6 mb-0">E-mail</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrPresOutEn.value"
                            (valueChange)="vars.alrPresOutEn.changed = true"
                            [switchColor]="(vars.alrPresOutEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>

                        <!-- alrPresOutTol -->
                        <dd class="col-md-6 mb-0">Seuil pression [%]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.alrPresOutTol.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrPresOutTol.value"
                            (ngModelChange)="vars.alrPresOutTol.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Flow Alarm -->
                <div class="col-sm-6 col-lg-4">
                  <div
                    class="card bg-light mb-2"
                  >
                    <div class="card-body">
                      <h5 class="card-title">Flux moyen</h5>
                      <dl class="row mb-0">
                        <!-- alrFlowAvgEn -->
                        <dd class="col-md-6 mb-0">E-mail</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrFlowAvgEn.value"
                            (valueChange)="vars.alrFlowAvgEn.changed = true"
                            [switchColor]="(vars.alrFlowAvgEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>

                        <!-- alrFlowSetTol -->
                        <dd class="col-md-6 mb-0">Seuil d'écart [%]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.alrFlowSetTol.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrFlowSetTol.value"
                            (ngModelChange)="vars.alrFlowSetTol.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Power Alarm -->
                <div class="col-sm-6 col-lg-4">
                  <div
                    class="card bg-light mb-2"
                  >
                    <div class="card-body">
                      <h5 class="card-title">Alimentation</h5>
                      <dl class="row mb-0">
                        <!-- alrPowBackEn -->
                        <dd class="col-md-6 mb-0">E-mail Rétablie</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrPowBackEn.value"
                            (valueChange)="vars.alrPowBackEn.changed = true"
                            [switchColor]="(vars.alrPowBackEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>

                        <!-- alrPowDownEn -->
                        <dd class="col-md-6 mb-0">E-mail Coupée</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <ui-switch
                            size="small"
                            [disabled]="isSync"
                            [(ngModel)]="vars.alrPowDownEn.value"
                            (valueChange)="vars.alrPowDownEn.changed = true"
                            [switchColor]="(vars.alrPowDownEn.changed) ? 'orange' : null">
                          </ui-switch>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <!-- Adjustments -->
        <li ngbNavItem>
          <a ngbNavLink>Ajustements</a>
          <ng-template ngbNavContent>
            <div class="card card-body border-top-0">
              <div class="row justify-content-center">
                <div class="d-flex col-1">
                  <mat-spinner color="success" diameter="40" *ngIf="isSync"></mat-spinner>
                </div>  
                <div class="col-3">
                  <button (click)="syncOnlyAdjust()" class="btn btn-primary center">
                    Synchroniser uniquement les variables d'ajustements
                  </button>
                </div>
              </div>  
              <div class="row mt-2">
                <!-- Adjustments -->
                <div class="col-md-4">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Ajustements</h5>
                      <dl class="row mb-0">
                        <!-- co2ResActAdj -->
                        <dd class="col-md-6 mb-0">Réserve active</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <select
                            class="custom-select custom-select-sm"
                            [ngClass]="{
                              'bg-warning': vars.co2ResActAdj.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.co2ResActAdj.value"
                            (ngModelChange)="vars.co2ResActAdj.changed = true"
                          >
                            <option value="0">B0</option>
                            <option value="1">B1</option>
                            <option value="2">B2</option>
                          </select>
                        </dt>

                        <!-- upcDiffLvlAdj -->
                        <dd class="col-md-6 mb-0">Intensité du flux</dd>
                        <dt class="col-md-6 mb-2 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcDiffLvlAdj.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.upcDiffLvlAdj.value"
                            (ngModelChange)="vars.upcDiffLvlAdj.changed = true"
                          />
                        </dt>

                        <!-- co2FlowRefAdj -->
                        <dd class="col-md-6 mb-0">Flux maximal [nl/min]</dd>
                        <dt class="col-md-6 mb-0 text-md-right">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            step="0.1"
                            [ngClass]="{
                              'bg-warning': vars.co2FlowRefAdj.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.co2FlowRefAdj.value"
                            (ngModelChange)="vars.co2FlowRefAdj.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Flows -->
                <div class="col-sm-6 col-md-4">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Flux</h5>
                      <dl class="row mb-0">
                        <!-- upcCo2DiffLvl -->
                        <dd class="col-lg-7 mb-0">Intensité du flux actuel</dd>
                        <dt class="col-lg-5 mb-2 text-lg-right">
                          {{ this.upcv3.diffusionParameters.upcCo2DiffLvl }}
                        </dt>

                        <!-- calcRefFlowRate -->
                        <dd class="col-lg-7 mb-0">Flux de référence</dd>
                        <dt class="col-lg-5 mb-2 text-lg-right">
                          {{ vars.calcRefFlowRate.value.toFixed(3) }} nl/min
                        </dt>

                        <!-- co2FlowAvg -->
                        <dd class="col-lg-7 mb-0">Flux</dd>
                        <dt class="col-lg-5 mb-2 text-lg-right">
                          {{
                            this.upcv3.diffusionParameters.co2FlowAvg?.toFixed(3) ?? '-'
                          }}
                          nl/min
                        </dt>

                        <!-- co2TempAvg -->
                        <dd class="col-lg-7 mb-0">Température du flux</dd>
                        <dt class="col-lg-5 mb-0 text-lg-right">
                          {{ this.upcv3.diffusionParameters.co2TempAvg }} °C
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Pressures -->
                <div class="col-sm-6 col-md-4">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Pressions</h5>
                      <dl class="row mb-0">
                        <!-- co2PresInpAvg -->
                        <dd class="col-lg-7 mb-0">Pression d'entrée</dd>
                        <dt class="col-lg-5 mb-2 text-lg-right">
                          {{
                            this.upcv3.diffusionParameters.co2PresInpAvg?.toFixed(
                              3
                            ) ?? '-'
                          }}
                          bar
                        </dt>

                        <!-- co2PresOutAvg -->
                        <dd class="col-lg-7 mb-0">Pression de sortie</dd>
                        <dt class="col-lg-5 mb-2 text-lg-right">
                          {{
                            this.upcv3.diffusionParameters.co2PresOutAvg?.toFixed(
                              3
                            ) ?? '-'
                          }}
                          bar
                        </dt>

                        <!-- co2PressOutComp -->
                        <dd class="col-lg-7 mb-0">
                          Pression de sortie compensée
                        </dd>
                        <dt class="col-lg-5 mb-0 text-lg-right">
                          {{
                            this.upcv3.diffusionParameters.co2PressOutComp.toFixed(
                              3
                            )
                          }}
                          bar
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Pressures table -->
                <div class="col-12">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Table des pressions</h5>
                      <table
                        class="table table-borderless table-sm text-center mb-0"
                      >
                        <thead>
                          <tr>
                            <th>Intensité</th>
                            <th>Entée B1 [bar]</th>
                            <th>Entée B2 [bar]</th>
                            <th>Sortie [bar]</th>
                            <th>Sortie réf. [bar]</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- 1 -->
                          <tr>
                            <th>1</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_1 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_1 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_1 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef1.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef1.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef1.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 2 -->
                          <tr>
                            <th>2</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_2 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_2 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_2 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef2.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef2.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef2.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 3 -->
                          <tr>
                            <th>3</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_3 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_3 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_3 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef3.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef3.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef3.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 4 -->
                          <tr>
                            <th>4</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_4 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_4 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_4 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef4.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef4.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef4.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 5 -->
                          <tr>
                            <th>5</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_5 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_5 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_5 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef5.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef5.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef5.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 6 -->
                          <tr>
                            <th>6</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_6 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_6 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_6 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef6.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef6.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef6.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 7 -->
                          <tr>
                            <th>7</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_7 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_7 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_7 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef7.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef7.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef7.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 8 -->
                          <tr>
                            <th>8</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_8 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_8 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_8 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef8.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef8.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef8.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 9 -->
                          <tr>
                            <th>9</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_9 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_9 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_9 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef9.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef9.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef9.changed = true
                                "
                              />
                            </td>
                          </tr>

                          <!-- 10 -->
                          <tr>
                            <th>10</th>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet1_10 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressInpSet2_10 / 1000
                              }}
                            </td>
                            <td>
                              {{
                                upcv3.alarmsParameters.alrPressOutSet_10 / 1000
                              }}
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control form-control-sm mx-auto"
                                style="max-width: 100px"
                                step="0.001"
                                [ngClass]="{
                                  'bg-warning': vars.co2PresOutRef10.changed
                                }"
                                [disabled]="isSync"
                                [(ngModel)]="vars.co2PresOutRef10.value"
                                (ngModelChange)="
                                  vars.co2PresOutRef10.changed = true
                                "
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <!-- More -->
        <li ngbNavItem>
          <a ngbNavLink><i class="fa fa-cog"></i></a>

          <ng-template ngbNavContent>
            <div class="card card-body border-top-0">
              <div class="row">
                <!-- General parameters -->
                <div class="col-md-6 col-lg-3">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">General parameters</h5>
                      <dl class="row mb-0">
                        <!-- upcMcuUid -->
                        <dd class="col-12 mb-0">UID</dd>
                        <dt class="col-12 mb-2">
                          {{ upcv3.generalParameters.upcMcuUid }}
                        </dt>

                        <!-- upcNameId -->
                        <dd class="col-12 mb-0">Nom</dd>
                        <dt class="col-12 mb-2">{{ upcv3.upcNameId }}</dt>

                        <!-- upcClock -->
                        <dd class="col-12 mb-0">Horloge</dd>
                        <dt class="col-12 mb-2">
                          {{
                            getDateFromSeconds(upcv3.generalParameters.upcClock)
                          }}
                        </dt>

                        <!-- upcTimeZone -->
                        <dd class="col-12 mb-0">Fuseau horaire</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcTimeZone.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.upcTimeZone.value"
                            (ngModelChange)="vars.upcTimeZone.changed = true"
                          />
                        </dt>

                        <!-- upcLanguage -->
                        <dd class="col-12 mb-0">Langue</dd>
                        <dt class="col-12 mb-2">
                          <select
                            class="custom-select custom-select-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcLanguage.changed
                            }"
                            [disabled]="isSync"
                            [(ngModel)]="vars.upcLanguage.value"
                            (ngModelChange)="vars.upcLanguage.changed = true"
                          >
                            <option value="0">Français</option>
                            <option value="1">Anglais</option>
                          </select>
                        </dt>

                        <!-- upcTrapNum -->
                        <dd class="col-12 mb-0">Nombre de pièges</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcTrapNum.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.upcTrapNum.value"
                            (ngModelChange)="vars.upcTrapNum.changed = true"
                          />
                        </dt>

                        <hr />

                        <!-- co2FlowRefTrap -->
                        <dd class="col-12 mb-0">
                          Débit de ref. / piège [nl/mn]
                        </dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            step="0.01"
                            [ngClass]="{
                              'bg-warning': vars.co2FlowRefTrap.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2FlowRefTrap.value"
                            (ngModelChange)="vars.co2FlowRefTrap.changed = true"
                          />
                        </dt>

                        <!-- co2FlowRefNom -->
                        <dd class="col-12 mb-0">
                          Débit de ref. nominal [nl/mn]
                        </dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            step="0.1"
                            [ngClass]="{
                              'bg-warning': vars.co2FlowRefNom.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2FlowRefNom.value"
                            (ngModelChange)="vars.co2FlowRefNom.changed = true"
                          />
                        </dt>

                        <!-- co2PressOutTemp -->
                        <dd class="col-12 mb-0">co2PressOutTemp [°C]</dd>
                        <dt class="col-12 mb-2">
                          {{ this.upcv3.generalParameters.co2PressOutTemp }}
                        </dt>

                        <hr />

                        <!-- upcCo2PidProp -->
                        <dd class="col-12 mb-0">PID P</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcCo2PidProp.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.upcCo2PidProp.value"
                            (ngModelChange)="vars.upcCo2PidProp.changed = true"
                          />
                        </dt>

                        <!-- upcCo2PidInteg -->
                        <dd class="col-12 mb-0">PID I</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcCo2PidInteg.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.upcCo2PidInteg.value"
                            (ngModelChange)="vars.upcCo2PidInteg.changed = true"
                          />
                        </dt>

                        <!-- upcCo2PidDiff -->
                        <dd class="col-12 mb-0">PID D</dd>
                        <dt class="col-12 mb-0">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.upcCo2PidDiff.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.upcCo2PidDiff.value"
                            (ngModelChange)="vars.upcCo2PidDiff.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Comunication -->
                <div class="col-md-6 col-lg-3">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Comunication</h5>
                      <dl class="row mb-0">
                        <!-- comWiFiName -->
                        <dd class="col-12 mb-0">WiFi : SSID</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comWiFiName.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comWiFiName.value"
                            (ngModelChange)="vars.comWiFiName.changed = true"
                          />
                        </dt>

                        <!-- comWiFiPass -->
                        <dd class="col-12 mb-0">WiFi : Mot de passe</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comWiFiPass.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comWiFiPass.value"
                            (ngModelChange)="vars.comWiFiPass.changed = true"
                          />
                        </dt>

                        <!-- comWifiApCh -->
                        <dd class="col-12 mb-0">WiFi : Canal RF</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comWifiApCh.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comWifiApCh.value"
                            (ngModelChange)="vars.comWifiApCh.changed = true"
                          />
                        </dt>

                        <hr />

                        <!-- comWebSrvUrl -->
                        <dd class="col-12 mb-0">Serveur : URL</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comWebSrvUrl.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comWebSrvUrl.value"
                            (ngModelChange)="vars.comWebSrvUrl.changed = true"
                          />
                        </dt>

                        <hr />

                        <!-- comMdmApnId2 -->
                        <dd class="col-12 mb-0">APN : Identifiant 2</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comMdmApnId2.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comMdmApnId2.value"
                            (ngModelChange)="vars.comMdmApnId2.changed = true"
                          />
                        </dt>

                        <!-- comMdmApnUser -->
                        <dd class="col-12 mb-0">APN : Utilisateur</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comMdmApnUser.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comMdmApnUser.value"
                            (ngModelChange)="vars.comMdmApnUser.changed = true"
                          />
                        </dt>

                        <!-- comMdmApnPass -->
                        <dd class="col-12 mb-0">APN : Mot de passe</dd>
                        <dt class="col-12 mb-0">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            [ngClass]="{
                              'bg-warning': vars.comMdmApnPass.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.comMdmApnPass.value"
                            (ngModelChange)="vars.comMdmApnPass.changed = true"
                          />
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- CO2 diffusion -->
                <div class="col-md-6 col-lg-3">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">CO2 diffusion</h5>
                      <dl class="row mb-0">
                        <!-- diffHourSunrise -->
                        <dd class="col-12 mb-0">Aube</dd>
                        <dt class="col-12 mb-2">
                          {{
                            getHourMinutesFromSeconds(
                              upcv3.diffusionParameters.diffHourSunrise
                            )
                          }}
                        </dt>

                        <!-- diffHourSunset -->
                        <dd class="col-12 mb-0">Crépuscule</dd>
                        <dt class="col-12 mb-0">
                          {{
                            getHourMinutesFromSeconds(
                              upcv3.diffusionParameters.diffHourSunset
                            )
                          }}
                        </dt>

                        <hr />

                        <!-- co2PressInpOffs -->
                        <dd class="col-12 mb-0">Décalage Pin [bar]</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            step="0.001"
                            [ngClass]="{
                              'bg-warning': vars.co2PressInpOffs.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2PressInpOffs.value"
                            (ngModelChange)="
                              vars.co2PressInpOffs.changed = true
                            "
                          />
                        </dt>

                        <!-- co2PressOutOffs -->
                        <dd class="col-12 mb-0">Décalage Pout [bar]</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            step="0.001"
                            [ngClass]="{
                              'bg-warning': vars.co2PressOutOffs.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2PressOutOffs.value"
                            (ngModelChange)="
                              vars.co2PressOutOffs.changed = true
                            "
                          />
                        </dt>

                        <!-- co2FlowOffs -->
                        <dd class="col-12 mb-0">Décalage Flux [nl/mn]</dd>
                        <dt class="col-12 mb-2">
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            step="0.001"
                            [ngClass]="{
                              'bg-warning': vars.co2FlowOffs.changed
                            }"
                            [disabled]="
                              isSync ||
                              this.upcv3.generalParameters.upcStatus == 3
                            "
                            [(ngModel)]="vars.co2FlowOffs.value"
                            (ngModelChange)="vars.co2FlowOffs.changed = true"
                          />
                        </dt>

                        <dt class="col-12">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-sm btn-block"
                            (click)="restoreOffsets()"
                          >
                            Restorer
                          </button>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>

                <!-- Plan -->
                <div class="col-md-6 col-lg-3">
                  <div class="card bg-light mb-2">
                    <div class="card-body">
                      <h5 class="card-title">Plan</h5>
                      <dl class="row mb-0">
                        <dt class="col-12">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-sm btn-block"
                            (click)="onVersions()"
                          >
                            Synchroniser
                          </button>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</ng-template>
