import { Component, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { FirmwareService } from 'src/app/services/upcv3/firmware.service';
import { Modal } from 'src/app/models/ui/modal';

@Component({
  selector: 'add-firmware-content',
  templateUrl: './add-firmware.component.html'
})
export class AddFirmwareModalComponent extends Modal<any> {
  // Form
  form: FormGroup;
  formSubmitted = false;
  readingFile = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,

    private toasterService: ToasterService,
    private firmwareService: FirmwareService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      file: [ null, Validators.required ]
    });

    // On location change
    this.router.events.subscribe(() => { this.close() });

  }

  // Files
  onFileChange(event) {
    const reader = new FileReader();
 
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      // Check file size
      var filesize = ((file.size / 1024) / 1024); // MB
      if (filesize > 10) {
        this.toasterService.pop('error', 'Le fichier sélectionné dépasse la limite des 10 Mo !');
        event.target.value = null;
        return;
      }

      this.readingFile = true;
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        this.form.patchValue({
          file: new File([new Blob([reader.result])], file.name)
        });
      
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
        this.readingFile = false;
      };
    }
  }

  // Form
  get f() { return this.form.controls; }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try create
    this.formSubmitted = true;
    this.firmwareService.upload(this.form).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }

}