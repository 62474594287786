import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '../../models/gdp/project';
import { ProjectService } from '../../services/gdp/project.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PagerService } from 'src/app/services/pager.service';
import { AddProjectModalComponent } from './add-project/add-project.component';
import { EditProjectModalComponent } from './edit-project/edit-project.component';
import { DeleteProjectModalComponent } from './delete-project/delete-project.component';
import { AuthService } from 'src/app/auth/auth.service';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html'
})
export class ProjectsComponent implements OnInit {
  // NF
  projects: Project[];

  // Pagination
  pageSizes = [
    { value: 10,  label: 'Afficher 10 Projets' },
    { value: 50,  label: 'Afficher 50 Projets' },
    { value: 100, label: 'Afficher 100 Projets' }
  ];
  pager: any = {};
  pageSize = this.pageSizes[0].value;
  filter: string = '';
  currentPage = 1;

  // Search
  searchForm: FormGroup;
  searchFormSubmitted = false;

  constructor(
    public authService: AuthService,
    private projectService: ProjectService,
    private pagerService: PagerService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { this.searchForm = this.fb.group({ filter: ['', Validators.minLength(3)] }) }

  ngOnInit() { this.setPage(this.currentPage, this.pageSize) }

  setPage(currentPage: number = 1, pageSize: number = 10, filter: string = '') {
    // Get records from service
    this.projects = null;
    this.projectService.getPage(pageSize, currentPage, filter).subscribe(
      res => {
        switch (res.code) {
          
          case Code.PROJECT_RECOVERED:
          this.projects = res.result.content;
          break;

          case Code.UNAUTHORIZED:
          this.projects = [];
          break;

        }

        // get pager object from service
        this.pager = this.pagerService.getPager(res.result.totalElements, res.result.totalElements, currentPage, pageSize);
        this.currentPage = this.pager.currentPage;
      }
    );
  }

  /* Modals */
  openAddProjectModal() {
    var addProjectModalRef = this.modalService.open(AddProjectModalComponent, { size: 'lg', keyboard: false, backdrop: 'static' });
    addProjectModalRef.componentInstance.init(null, addProjectModalRef, () => this.setPage(this.currentPage, this.pageSize, this.filter))
  }
  openEditProjectModal(project: Project) {
    var editProjectModalRef = this.modalService.open(EditProjectModalComponent, { size: 'lg', keyboard: false, backdrop: 'static' });
    editProjectModalRef.componentInstance.init(project, editProjectModalRef, () => this.setPage(this.currentPage, this.pageSize, this.filter))
  }
  openDeleteProjectModal(project: Project) {
    var deleteProjectModalRef = this.modalService.open(DeleteProjectModalComponent);
    deleteProjectModalRef.componentInstance.init(project, deleteProjectModalRef, () => this.setPage(this.currentPage, this.pageSize, this.filter))
  }

  /* Search */
  get sf() { 
    if(this.searchForm){
      return this.searchForm.controls 
    }  
    
  }
  onSearchFormSubmit() {
    this.searchFormSubmitted = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) return;

    this.filter = this.searchForm.controls['filter'].value;
    this.setPage(1, this.pageSize, this.filter);
  }
  clearSearch(event) {
    this.filter = '';
    if (event.target.value.length == 0)
      this.setPage(1, this.pageSize, this.filter);
  }


}