import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Bottle, eventType, state } from 'src/app/models/management/bottle';
import { Subject } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { BottleService } from 'src/app/services/management/bottle.service';
import { finalize } from 'rxjs/operators';

interface IDisplayableData {
  locationString: string;
  date: Date;
  idEvent: number;
  eventString: string;
  previousState: state;
  newState: state;
}
@Component({
  selector: 'bottle-events-content',
  templateUrl: './bottle-events.component.html'
})
export class BottleEventsModalComponent extends Modal<Bottle> implements OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();

  public displayableData: IDisplayableData[] = [];
  public isLoading: boolean = true;

  constructor(
    private router: Router,
    private toastService: ToasterService,
    private bottleService: BottleService,
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  async ngOnInit() {
    this.isLoading = true;
    this.bottleService.get(this.subject.id).pipe(
      finalize(() => this.isLoading = false),
    ).subscribe(bottle => {
      this.displayableData = bottle.bottleEvents.map(event => {
        let locationString;
        if (event.nextLocation) {
          locationString = `${event.nextLocation.locationType} - ${event.nextLocation.name}`;
        } else {
          if (event.type == eventType.RENVOIE) {
            locationString = `Chez le fournisseur`;
          } else {
            locationString = ``;
          }
        }
        const eventString = event.eventString;
        const date = event.date;
        const idEvent = event.id;
        const newState = event.newBottleState;
        const previousState = event.previousBottleState;
  
        return {
          locationString,
          date,
          idEvent,
          eventString,
          previousState,
          newState,
        }
      }).sort((a, b) => {
        return b.date.getTime() - a.date.getTime() || b.idEvent - a.idEvent;
      });
    })
  }
}