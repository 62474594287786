import { jsonObject, jsonMember, TypedJSON } from 'typedjson';

@jsonObject
export class BoxTest {

	@jsonMember
	id: string = '';

	@jsonMember
	upcMcuUid: string = '';

	@jsonMember
	upcBoxId: string = '';

	@jsonMember
	date: Date;

	@jsonMember
	flowOff: number = 0;

	@jsonMember
	leaksV1InternFlow: number = 0;

	@jsonMember
	leaksV1ExternFlow: number = 0;

	@jsonMember
	leaksV2InternFlow: number = 0;

	@jsonMember
	leaksV2ExternFlow: number = 0;

	@jsonMember
	inPress: number = 0;

	@jsonMember
	flow1Extern: number = 0;

	@jsonMember
	flow1Intern: number = 0;

	@jsonMember
	flow2Extern: number = 0;

	@jsonMember
	flow2Intern: number = 0;

	@jsonMember
	flow3Extern: number = 0;

	@jsonMember
	flow3Intern: number = 0;

	@jsonMember
	propValveMinFlow: number = 0;

	@jsonMember
	propValveMinPressure: number = 0;

	@jsonMember
	closeValvePressBefore: number = 0;

	@jsonMember
	closeValvePressAfter: number = 0;

	@jsonMember
	closeValveExternFlow: number = 0;

	@jsonMember
	returnCheckPressBefore: number = 0;

	@jsonMember
	returnCheckPressAfter: number = 0;

	@jsonMember
	observations: string = '';

	static loadFromJSON(json): BoxTest {
		let serializer = new TypedJSON(BoxTest);
		return serializer.parse(json);
	}

}