import { LocationStrategy } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouteReuseStrategy,
  DetachedRouteHandle,
  UrlSegment,
} from "@angular/router";
import { LoginComponent } from "../pages/login/login.component";
import { DashboardComponent } from "../pages/dashboard/dashboard.component";
import * as _ from "lodash";
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  storedHandles: { [key: string]: DetachedRouteHandle } = {};
  isPrevious = false;
  constructor(location: LocationStrategy) {
    location.onPopState(() => {
      this.isPrevious = true;
    });
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseRoute === true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const id = this.createIdentifier(route);
    if (route.data.reuseRoute) {
      this.storedHandles[id] = handle;
    }
  }
  clearStoreHandle() {
    this.storedHandles = {};
    return false;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    /*shouldAttach is not guaranteed to becalled only once per route change */
    /*Clear isPrevious when going to anything else than the dashboard */
    if (route.component !== DashboardComponent) {
      this.isPrevious = false;
    }
    if (route.component === LoginComponent) {
      return this.clearStoreHandle();
    }
    // If route should use routeReuse and isPrevious is not set by eventHandler in constructor, reset the cache
    if (route.data?.reuseRoute == true && this.isPrevious === false) {
      return this.clearStoreHandle();
    }

    const id = this.createIdentifier(route);
    const handle = this.storedHandles[id];
    const canAttach = !!route.routeConfig && !!handle;
    return canAttach;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const id = this.createIdentifier(route);
    if (!route.routeConfig || !this.storedHandles[id]) {
      return null;
    }
    return this.storedHandles[id];
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  private createIdentifier(route: ActivatedRouteSnapshot) {
    // Build the complete path from the root to the input route
    const segments: UrlSegment[][] = route.pathFromRoot.map((r) => r.url);
    const subpaths = ([] as UrlSegment[])
      .concat(...segments)
      .map((segment) => segment.path);
    // Result: ${route_depth}-${path}
    return segments.length + "-" + subpaths.join("/");
  }
}
