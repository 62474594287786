import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';
import { FirmwareService } from 'src/app/services/upcv3/firmware.service';
import { DeleteFirmwareModalComponent } from './delete-firmware/delete-firmware.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddFirmwareModalComponent } from './add-firmware/add-firmware.component';
import { UpdateUPCFirmwareModalComponent } from './update-upc-firmware/update-upc-firmware.component';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-settings-firmwares',
  templateUrl: './firmwares.component.html'
})
export class FirmwaresComponent implements OnInit {
  // Firmwares
  firmwares: string[];

  constructor(
    private authService: AuthService,
    private firmwareService: FirmwareService,
    private modalService: NgbModal,
    private toasterService : ToasterService
  ) { }

  ngOnInit() {
    // Load firmwares
    this.loadFirmwares();
  }

  // Firmware
  loadFirmwares() {
    this.firmwares = null;
    if (this.authService.isAdmin) {
      this.firmwareService.getAll().subscribe({
        next: (res) => this.firmwares = res,
        error: (err) => {
          this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la récupération des firmwares");
          this.firmwares = []
        }
      })
    }
  }
  
  openAddFirmwareModal() {
    var addFirmwareModalRef = this.modalService.open(AddFirmwareModalComponent);
    addFirmwareModalRef.componentInstance.init(null, addFirmwareModalRef, () => {
      this.loadFirmwares()
    })
  }
  openUpdateUPCFirmwareModal(firmware: string) {
    var updateUPCFirmwareModalRef = this.modalService.open(UpdateUPCFirmwareModalComponent, { size: 'lg' });
    updateUPCFirmwareModalRef.componentInstance.init(firmware, updateUPCFirmwareModalRef, () => {
      this.loadFirmwares()
    })
  }
  openDeleteFirmwareModal(firmware: string) {
    var deleteFirmwareModalRef = this.modalService.open(DeleteFirmwareModalComponent);
    deleteFirmwareModalRef.componentInstance.init(firmware, deleteFirmwareModalRef, () => {
      this.loadFirmwares()
    })
  }

}