<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Sites</h4>

      <button type="button" class="btn btn-primary float-right" routerLink="/site/add" *ngIf="authService.isAdmin"><i class="fa fa-plus"></i> Site</button>
    </div>

    <!-- Size -->
    <div class="col-sm-6 col-lg-3 mb-2">
      <ng-select  [items]="pageSizes"
                  [searchable]="false"
                  [clearable]="false"
                  bindLabel="label"
                  bindValue="value"
                  [(ngModel)]="pageSize"
                  (change)="setPage(1, pageSize, filter)">
      </ng-select>
    </div>

    <!-- Search -->
    <div class="col-sm-6 col-lg-4 offset-lg-5 mb-2">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Rechercher" (keyup)="setPage(1, pageSize, $event.target.value)">
        <div class="input-group-append">
          <button class="btn btn-outline-primary"><i class="fa fa-search"></i></button>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="col-12 mb-2">
      <ul class="list-group list-group-striped">

        <!-- Header -->
        <li class="list-group-item d-none d-md-block">
          <div class="row">
            <div class="col-11">
              <div class="row">
                <p class="col-1 mb-0 font-weight-bold">N° Client</p>
                <p class="col-2 mb-0 font-weight-bold">Nom</p>
                <p class="col-3 mb-0 font-weight-bold">Infos</p>
                <p class="col-2 mb-0 font-weight-bold">Adresse</p>
                <p class="col-3 mb-0 font-weight-bold">Stocks</p>
                <p class="col-1 mb-0 font-weight-bold">Bouteilles en stock</p>
              </div>
            </div>
            <p class="col-1 mb-0 font-weight-bold text-right">Actions</p>
          </div>
        </li>

        <!-- Loading & Empty -->
        <li class="list-group-item px-3 py-2 text-center" *ngIf="!pagedSites"><i class="fa fa-circle-o-notch fa-spin"></i></li>
        <li class="list-group-item px-3 py-2 text-center" *ngIf="pagedSites && pagedSites?.length == 0">Aucun site</li>

        <!-- Row -->
        <li class="list-group-item px-3 py-1" *ngFor="let site of pagedSites">
          <div class="row">
            <div class="col-11">
              <a class="row no-link" routerLink="/site/{{ site.id }}">
                <p class="col-md-1 mt-1 mb-0"><small class="d-md-none mr-2">N° Client</small>{{ site.clientNumber }}</p>
                <p class="col-md-2 mt-1 mb-0 font-weight-bold"><small class="d-md-none mr-2">Nom</small>{{ site.name }}</p>
                <p class="col-md-3 mt-1 mb-0"><small class="d-md-none mr-2">Infos</small>{{ site.infos }}</p>
                <p class="col-md-2 mt-1 mb-0"><small class="d-md-none mr-2">Adresse</small>{{ site.address }}</p>
                <p class="col-md-3 mt-1 mb-0"><small class="d-md-none mr-2">Stocks</small>{{ site.stocksString }}</p>
                <p class="col-md-1 mt-1 mb-0"><small class="d-md-none mr-2">Bouteilles en stock</small>{{ site.storedBottlesCount }}</p>
              </a>
            </div>
            <div class="col-1 mb-0 text-right">
              <div placement="bottom-right" ngbDropdown>
                <button class="btn btn-link text-dark px-3 py-0" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
                <div class="p-0" ngbDropdownMenu>
                  <button class="dropdown-item px-3 py-2" (click)="openDeleteSiteModal(site)" *ngIf="authService.isAdmin"><i class="fa fa-trash"></i> Archiver</button>
                  <button class="dropdown-item px-3 py-2" (click)="openEditSiteModal(site)" *ngIf="authService.isAdmin"><i class="fa fa-edit"></i> Modifier</button>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>

    <!-- Pagination -->
    <div class="col-12 mb-3">
      <nav>
        <ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-end">
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
            <a class="page-link" (click)="setPage(pager.currentPage - 1, pageSize, filter)" tabindex="-1">Précédent</a>
          </li>
          
          <li class="page-item" *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}">
            <a class="page-link" (click)="setPage(page, pageSize, filter)">{{ page }}</a>
          </li>
          
          <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
            <a class="page-link" (click)="setPage(pager.currentPage + 1, pageSize, filter)">Suivant</a>
          </li>
        </ul>
      </nav>
    </div>


  </div>
</div>
