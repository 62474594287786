import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InterventionV3Service } from 'src/app/services/upcv3/interventionV3.service';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Operator } from 'src/app/models/management/operator';
import { OperatorService } from 'src/app/services/management/operator.service';
import { ToasterService } from 'angular2-toaster';
import { InterventionV3PostDto } from 'src/app/models/dto/InterventionV3Post.dto';
import { Bottle, state, status } from 'src/app/models/management/bottle';
import { AddBottleModalComponent, DialogData } from './add-bottle/add-bottle.component';
import { LocationSelectionModalComponent as LocationSelectionModalComponent, DialogData as LocationSelectionDialogData } from './location-selection/location-selection.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { debounceTime, finalize, map, switchMap } from 'rxjs/operators';
import { BottlesToAddToLocation, RefillPostDto } from 'src/app/models/dto/UPCV3/RefillPost.dto';
import { Site } from 'src/app/models/management/site';
import { SiteService } from 'src/app/services/management/site.service';
import { ContentChartDataWithoutMetadata, UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { UPCV3, co2LitersToKg } from 'src/app/models/upcv3/upc/upcv3';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { Observable, forkJoin, of } from 'rxjs';
import { Location } from 'src/app/models/management/location';
import _ from 'lodash';
import { Origin } from 'src/app/models/upcv3/interventionV3';
import { BottleService } from 'src/app/services/management/bottle.service';
import { CO2_LITERS_TO_KG_RATIO } from 'src/app/constants/co2';

interface BottleGoingToStock extends Bottle {
  destinationStock?: Location;
}

interface BottlesState {
  initialState: Bottle[];
  finalState: BottleGoingToStock[];
  callbackAddBottle?: () => void;
}

interface BottlesDiff {
  b1: BottlesState;
  b2: BottlesState;
  stock: BottlesState;
  in_transit: BottlesState;
}

enum AvailableObjets {
  INSTALLATION,
  VISITE_TECHNIQUE,
  VISITE_TECHNIQUE_AVEC_CHGMT_BOUTEILLES,
  REMPLISSAGE_REMBO,
  HIVERNAGE
};

const availableObjetsLabelMapping: Record<AvailableObjets, string> = {
  [AvailableObjets.INSTALLATION]: "Installation",
  [AvailableObjets.VISITE_TECHNIQUE]: "Visite technique",
  [AvailableObjets.VISITE_TECHNIQUE_AVEC_CHGMT_BOUTEILLES]: "Visite technique avec changement de bouteilles",
  [AvailableObjets.REMPLISSAGE_REMBO]: "Remplissage Rembo",
  [AvailableObjets.HIVERNAGE]: "Hivernage"
};
@Component({
  selector: 'add-intervention',
  templateUrl: './add-intervention.component.html',
  styleUrls: ['./add-intervention.component.css']
})
export class AddInterventionComponent implements OnInit {
  form: FormGroup;

  siteId: string;
  site: Site;
  upcv3Id: number;
  upcv3: UPCV3;
  groupUpcv3Id: number;
  groupUpcv3: GroupUPCV3;
  intervenants: Operator[];
  connectedUser: Operator;

  todayDate: Date = new Date();

  bottlesDiff: BottlesDiff = {
    b1: {
      initialState: [],
      finalState: [],
      callbackAddBottle: () => this.onAddBottleB1(),
    },
    b2: {
      initialState: [],
      finalState: [],
      callbackAddBottle: () => this.onAddBottleB2(),
    },
    stock: {
      initialState: [],
      finalState: [],
      callbackAddBottle: () => this.onAddBottleToStockableLocation(),
    },
    in_transit: {
      initialState: [],
      finalState: [],
      callbackAddBottle: () => this.onAddBottleToStockableLocation(),
    }
  }

  refillTotalConsumedB1: number = 0;
  refillTotalConsumedB2: number = 0;
  refillTotalAddedB1: number = 0;
  refillTotalAddedB2: number = 0;
  refillRealAddedB1: number = 0;
  refillRealAddedB2: number = 0;
  refillReplaceAllB1: boolean = false;
  refillReplaceAllB2: boolean = false;
  b1Empty: boolean = false;
  b2Empty: boolean = false;

  isSaving: boolean = false;
  isContentToDateLoading = false;

  bottlesOperationsEnabled: boolean = true;

  selectableObjets = [];
  selectedObjets = [];

  constructor(
    private route: ActivatedRoute,
    private siteService: SiteService,
    private upcv3Service: UPCV3Service,
    private bottlesService: BottleService,
    private operatorService: OperatorService,
    private interventionV3Service: InterventionV3Service,
    private toasterService: ToasterService,
    private dialogService: MatDialog,
  ) {
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.upcv3Id = this.route.snapshot.queryParamMap.get('upcv3Id') ? Number(this.route.snapshot.queryParamMap.get('upcv3Id')) : null;
    this.groupUpcv3Id = this.route.snapshot.queryParamMap.get('groupUpcv3Id') ? Number(this.route.snapshot.queryParamMap.get('groupUpcv3Id')) : null;
    if (this.upcv3Id && this.groupUpcv3Id) {
      this.toasterService.pop('error', 'Impossible de créer une intervention ciblant une upc et un groupe en même temps');
      this.upcv3Id = null;
      this.groupUpcv3Id = null;
    }
    for (const key in availableObjetsLabelMapping) {
      this.selectableObjets.push({
        id: key,
        value: availableObjetsLabelMapping[key],
      });
    }
  }

  co2LitersToKg = co2LitersToKg;

  ngOnInit(): void {
    this.newFormGroup();
    this.refillTotalAddedB1 = 0;
    this.refillTotalAddedB2 = 0;

    this.site = null;

    forkJoin([
      this.siteService.get(this.siteId),
      this.bottlesService.getAll({page: 0, size: 1000, status: status.IN_TRANSIT}),
      this.siteService.getStocks(this.siteId),
    ]).subscribe({
      next: ([site, bottlesInTransit, stocks]) => {
        this.site = site;
        this.bottlesDiff.b1.initialState = site.bottles.filter(b => b.status === status.B1 && b.upcv3?.id == this.upcv3Id && b.groupUPCV3?.id == this.groupUpcv3Id);
        this.bottlesDiff.b2.initialState = site.bottles.filter(b => b.status === status.B2 && b.upcv3?.id == this.upcv3Id && b.groupUPCV3?.id == this.groupUpcv3Id);
        this.bottlesDiff.in_transit.initialState = bottlesInTransit.content;
        this.bottlesDiff.stock.initialState = [
          ...stocks.flatMap(s => s.bottles).filter(b => b.status === status.ENTREPOSE), 
          ...site.bottles.filter(b => b.status === status.ENTREPOSE)
        ];
        this.bottlesDiff.b1.finalState = [...this.bottlesDiff.b1.initialState];
        this.bottlesDiff.b2.finalState = [...this.bottlesDiff.b2.initialState];
        this.bottlesDiff.in_transit.finalState = [...this.bottlesDiff.in_transit.initialState];
        this.bottlesDiff.stock.finalState = [...this.bottlesDiff.stock.initialState];

        this.upcv3 = this.site.upcv3s.find(upc => upc.id === this.upcv3Id);
        this.groupUpcv3 = this.site.groupUPCV3s.find(groupUpc => groupUpc.id === this.groupUpcv3Id);

        if (this.upcv3 == null && this.groupUpcv3 == null) {
          this.upcv3 = this.site.groupUPCV3s.flatMap(groupUpc => groupUpc.upcv3s).find(upc => upc.id === this.upcv3Id);
          this.groupUpcv3 = this.site.groupUPCV3s.find(groupUpc => groupUpc.upcv3s.find(upc => upc.id === this.upcv3?.id));

          this.bottlesOperationsEnabled = false;
        }

        this.totalConsumedCalculation();
      },
      error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des informations du site")
    });
    this.operatorService.getAll().subscribe({
      next: operators => this.intervenants = operators,
      error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération de la liste des opérateurs")
    });

    this.operatorService.getConnected().subscribe({
      next: operator => this.connectedUser = operator,
      error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération de l'utilisateur connecté")
    });

    this.f.interventionDatetime.valueChanges.pipe(
      debounceTime(500),
    ).subscribe(() => {
      this.totalConsumedCalculation()
    });
  }

  newFormGroup() {
    this.form = new FormGroup({
      interventionDatetime: new FormControl(new Date(), [
        Validators.required
      ]),
      objet: new FormControl(null, [
        Validators.required
      ]),
      intervenantsId: new FormControl(null, [
        Validators.required
      ]),
      rapport: new FormControl(null, []),
    });
  }

  get f() {
    return this.form.controls;
  }

  invalidForm() {
    return this.form.invalid;
  }

  public keepOriginalOrder = (a, b) => a.key;

  onAddBottleB1() {
    this.onAddBottle(this.bottlesDiff.b2.finalState, this.bottlesDiff.stock.finalState, this.bottlesDiff.in_transit.finalState).subscribe(selectedBottles => {
      // Si les bouteilles selectionnées n'étaient pas dans l'état actuel, on les y ajoute
      this.bottlesDiff.b1.finalState.push(...selectedBottles.filter(r => !this.bottlesDiff.b1.finalState.some(bf => bf.id == r.id)));
      // Renvoie les bouteilles HL et InStock qui ne sont pas sélectionnées
      this.bottlesDiff.stock.finalState = this.bottlesDiff.stock.finalState.filter(b => !selectedBottles.some(bs => bs.id == b.id));
      // Si c'est des bouteilles de B2 qui sont selectionnées, on les enlève
      this.bottlesDiff.b2.finalState = this.bottlesDiff.b2.finalState.filter(b => !selectedBottles.some(bs => bs.id == b.id));
      this.bottlesDiff.in_transit.finalState = this.bottlesDiff.in_transit.finalState.filter(b => !selectedBottles.some(bs => bs.id == b.id));

      // Recalcule le total ajouté
      this.computeAllAdded();
    }, e => e);
  }

  onAddBottleB2() {
    this.onAddBottle(this.bottlesDiff.b1.finalState, this.bottlesDiff.stock.finalState, this.bottlesDiff.in_transit.finalState).subscribe(selectedBottles => {
      // Si les bouteilles selectionnées n'étaient pas dans l'état actuel, on les y ajoute
      this.bottlesDiff.b2.finalState.push(...selectedBottles.filter(r => !this.bottlesDiff.b2.finalState.some(bf => bf.id == r.id)));
      // Renvoie les bouteilles HL et InStock qui ne sont pas sélectionnées
      this.bottlesDiff.stock.finalState = this.bottlesDiff.stock.finalState.filter(b => !selectedBottles.some(bs => bs.id == b.id));
      // Si c'est des bouteilles de B1 qui sont selectionnées, on les enlève
      this.bottlesDiff.b1.finalState = this.bottlesDiff.b1.finalState.filter(b => !selectedBottles.some(bs => bs.id == b.id));
      this.bottlesDiff.in_transit.finalState = this.bottlesDiff.in_transit.finalState.filter(b => !selectedBottles.some(bs => bs.id == b.id));

      // Recalcule le total ajouté
      this.computeAllAdded();
    }, e => e);
  }

  onAddBottleToStockableLocation() {
    const dialogConfig = new MatDialogConfig<LocationSelectionDialogData>();
    dialogConfig.data = {
      selectableLocations: [...this.site.stocks, this.site]
    };
    dialogConfig.minWidth = '25%';
    this.dialogService.open(LocationSelectionModalComponent, dialogConfig).afterClosed().pipe(switchMap(
      (selectedLocation: Location) => {
        if (selectedLocation == undefined) {
          throw new Error();
        }
        return this.onAddBottle([...this.bottlesDiff.b1.finalState, ...this.bottlesDiff.b2.finalState], [], this.bottlesDiff.in_transit.finalState).pipe(map(selectedBottles => {
          return selectedBottles.map(b => {
            (b as BottleGoingToStock).destinationStock = selectedLocation;
            return b;
          });
        }));
      }
    )).subscribe(selectedBottles => {
      // Si les bouteilles selectionnées n'étaient pas dans l'état actuel, on les y ajoute
      this.bottlesDiff.stock.finalState.push(...selectedBottles.filter(r => !this.bottlesDiff.stock.finalState.some(bf => bf.id == r.id)));
      this.bottlesDiff.stock.finalState = this.bottlesDiff.stock.finalState.map(b => {
        if (b.destinationStock?.id == this.site.id) {
          return b;
        }
        const bEdit = _.cloneDeep(b);
        bEdit.state = state.EMPTY;
        return bEdit;
      });
      // Renvoie les bouteilles B1, B2 et InStock qui ne sont pas sélectionnées
      this.bottlesDiff.b1.finalState = this.bottlesDiff.b1.finalState.filter(b => !this.bottlesDiff.stock.finalState.some(bf => bf.id == b.id));
      this.bottlesDiff.b2.finalState = this.bottlesDiff.b2.finalState.filter(b => !this.bottlesDiff.stock.finalState.some(bf => bf.id == b.id));
      this.bottlesDiff.in_transit.finalState = this.bottlesDiff.in_transit.finalState.filter(b => !this.bottlesDiff.stock.finalState.some(bf => bf.id == b.id));

      // Recalcule le total ajouté
      this.computeAllAdded();
    }, e => e)
  }

  onAddBottle(addableBottlesOnSite: Bottle[], addableBottlesInStock: Bottle[], addableBottlesInTransit: Bottle[]) {
    // On arrange les bouteilles pour les afficher correctement
    // les bouteilles entreposées sur site (considérées comme du stock) doivent être affichées comme étant préstente sur place
    addableBottlesOnSite = [...addableBottlesOnSite, ...addableBottlesInStock.filter(b => b.location.name == this.site.name)];
    addableBottlesInStock = addableBottlesInStock.filter(b => b.location.name != this.site.name);
    const dialogConfig = new MatDialogConfig<DialogData>();
    dialogConfig.data = {
      addableBottlesOnSite,
      addableBottlesInStock,
      addableBottlesInTransit,
    };
    dialogConfig.minWidth = '75%';
    // Retourne les bouteilles selectionnées depuis celles passées en paramètre
    return this.dialogService.open(AddBottleModalComponent, dialogConfig).afterClosed().pipe(map(
      (result: Bottle[]) => {
        if (!Array.isArray(result)) {
          throw new Error();
        }
        return result;
      }
    ));
  }

  onRemoveBottle(bottle: Bottle) {
    // Si la bouteille était originellement là, et qu'elle n'est pas dans l'état actuel
    // on la remet dans l'état actuel
    if (this.bottlesDiff.b1.initialState.some(b => b.id == bottle.id) && !this.bottlesDiff.b1.finalState.some(b => b.id == bottle.id)) {
      this.bottlesDiff.b1.finalState.push(this.bottlesDiff.b1.initialState.find(b => b.id == bottle.id));
    } else {
      // sinon, si on la trouve dans l'état actuel, on supprime
      const index = this.bottlesDiff.b1.finalState.findIndex(b => b.id == bottle.id);
      if (index != -1) {
        this.bottlesDiff.b1.finalState.splice(index, 1);
      }
    }
    if (this.bottlesDiff.b2.initialState.some(b => b.id == bottle.id) && !this.bottlesDiff.b2.finalState.some(b => b.id == bottle.id)) {
      this.bottlesDiff.b2.finalState.push(this.bottlesDiff.b2.initialState.find(b => b.id == bottle.id));
    } else {
      const index = this.bottlesDiff.b2.finalState.findIndex(b => b.id == bottle.id);
      if (index != -1) {
        this.bottlesDiff.b2.finalState.splice(index, 1);
      }
    }
    if (this.bottlesDiff.in_transit.initialState.some(b => b.id == bottle.id) && !this.bottlesDiff.in_transit.finalState.some(b => b.id == bottle.id)) {
      this.bottlesDiff.in_transit.finalState.push(this.bottlesDiff.in_transit.initialState.find(b => b.id == bottle.id));
    } else {
      const index = this.bottlesDiff.in_transit.finalState.findIndex(b => b.id == bottle.id);
      if (index != -1) {
        this.bottlesDiff.in_transit.finalState.splice(index, 1);
      }
    }
    if (this.bottlesDiff.stock.initialState.some(b => b.id == bottle.id) && !this.bottlesDiff.stock.finalState.some(b => b.id == bottle.id)) {
      this.bottlesDiff.stock.finalState.push(bottle);
    } else {
      const index = this.bottlesDiff.stock.finalState.findIndex(b => b.id == bottle.id);
      if (index != -1) {
        this.bottlesDiff.stock.finalState.splice(index, 1);
      }
    }
    // Si la bouteille était dans le stock, on remet dans le stock
    if (this.bottlesDiff.stock.initialState.some(b => b.id == bottle.id)) {
      this.bottlesDiff.stock.finalState.push(bottle);
    }

    // Recalcule les totaux ajoutés
    this.computeAllAdded();
  }

  computeTotalAdded(bottlesState: BottlesState) {
    return this.getBottlesDiff(bottlesState).bottlesAdded.reduce((accumulator, currentBottle) => {
      return accumulator + currentBottle.bottleType.contenue;
    }, 0);
  }

  computeRealAdded() {
    this.refillRealAddedB1 = this.refillReplaceAllB1 ? parseFloat((this.refillTotalAddedB1 - this.refillTotalConsumedB1).toFixed(2)) : parseFloat((this.refillTotalAddedB1 || 0).toFixed(2));
    this.refillRealAddedB2 = this.refillReplaceAllB2 ? parseFloat((this.refillTotalAddedB2 - this.refillTotalConsumedB2).toFixed(2)) : parseFloat((this.refillTotalAddedB2 || 0).toFixed(2));
  }

  computeAllAdded() {
    this.refillTotalAddedB1 = this.computeTotalAdded(this.bottlesDiff.b1);
    this.refillTotalAddedB2 = this.computeTotalAdded(this.bottlesDiff.b2);
    // Coche automatiquement le remplacement de toute la réserve si les bouteilles sont toutes supprimées
    if (this.getBottlesDiff(this.bottlesDiff.b1).bottlesRemoved.length == this.bottlesDiff.b1.initialState.length) {
      this.refillReplaceAllB1 = true;
    } else {
      this.refillReplaceAllB1 = false;
    }
    if (this.getBottlesDiff(this.bottlesDiff.b2).bottlesRemoved.length == this.bottlesDiff.b2.initialState.length) {
      this.refillReplaceAllB2 = true;
    } else {
      this.refillReplaceAllB2 = false;
    }
    const b1Rembos = this.bottlesDiff.b1.finalState.filter(e => e.bottleType.isRembo == true);
    if (b1Rembos.length > 0) {
      if (this.selectedObjets.some(element => element.id == AvailableObjets.REMPLISSAGE_REMBO)) {
        this.refillTotalAddedB1 += b1Rembos.reduce((sum, current) => sum + current.bottleType.contenue, 0);
        this.refillReplaceAllB1 = true;
      }
    }
    const b2Rembos = this.bottlesDiff.b2.finalState.filter(e => e.bottleType.isRembo == true);
    if (b2Rembos.length > 0) {
      if (this.selectedObjets.some(element => element.id == AvailableObjets.REMPLISSAGE_REMBO)) {
        this.refillTotalAddedB2 += b1Rembos.reduce((sum, current) => sum + current.bottleType.contenue, 0);
        this.refillReplaceAllB2 = true;
      }
    }
    this.computeRealAdded();
  }

  getBottlesDiff(bottlesState: BottlesState) {
    // Les bouteilles ajoutées sont celles qui n'étaient pas dans l'état initial par rapport à l'état final
    const bottlesAdded = bottlesState.finalState.filter(b => !bottlesState.initialState.some(bi => bi.id == b.id));
    // Les bouteilles supprimées sont celles qui ne sont pas dans l'état final par rapport à l'initial
    const bottlesRemoved = bottlesState.initialState.filter(b => !bottlesState.finalState.some(bf => bf.id == b.id));
    // Les bouteilles qui n'ont pas changées sont celles identitques à l'état initial
    const bottlesNotChanged = bottlesState.finalState.filter(b => bottlesState.initialState.some(bi => bi.id == b.id));
    return {
      bottlesNotChanged,
      bottlesAdded,
      bottlesRemoved,
    };
  }

  onChangeSwitch() {
    this.computeRealAdded();
  }

  onChangeObjet($event: Array<{id: AvailableObjets, value: string}>) {
    this.computeAllAdded();
  }

  refill() {
    this.isSaving = true;
    const interventionToPost: InterventionV3PostDto = {
      ...this.form.value,
      objet: this.form.value.objet.map(o => o.value).join(', '),
      upcv3Id: this.upcv3Id,
      siteId: this.site.id,
      start: new Date(this.form.value.interventionDatetime),
      operateurId: this.connectedUser.id,
      origin: Origin.WEB,
    };
    const groupedBottlesGoingToLocation = _.groupBy(this.getBottlesDiff(this.bottlesDiff.stock).bottlesAdded, b => b.destinationStock.id);
    const bottlesIdToAddToLocation = _.map(groupedBottlesGoingToLocation, (value, key): BottlesToAddToLocation => {
      return {
        locationId: key,
        bottlesId: value.map(b => b.id),
      };
    });
    const refillToPost: RefillPostDto = {
      refillInterventionDatetime: new Date(this.form.value.interventionDatetime),
      bottlesIdToAddToB1: this.getBottlesDiff(this.bottlesDiff.b1).bottlesAdded.map(b => b.id),
      bottlesIdToAddToB2: this.getBottlesDiff(this.bottlesDiff.b2).bottlesAdded.map(b => b.id),
      bottlesIdToPutInTransit: this.getBottlesDiff(this.bottlesDiff.in_transit).bottlesAdded.map(b => b.id),
      bottlesIdToAddToLocation: bottlesIdToAddToLocation,
      refillRealAddedB1: this.refillRealAddedB1,
      refillRealAddedB2: this.refillRealAddedB2,
      refillReplaceAllB1: this.refillReplaceAllB1,
      refillReplaceAllB2: this.refillReplaceAllB2,
      b1Empty: this.b1Empty,
      b2Empty: this.b2Empty,
      actualContentB1: co2LitersToKg(this.upcv3.reservesParameters.co2Res1ActVol),
      actualContentB2: co2LitersToKg(this.upcv3.reservesParameters.co2Res2ActVol),
      contentToDateB1: this.refillTotalConsumedB1,
      contentToDateB2: this.refillTotalConsumedB2,
      refillTotalAddedB1: this.refillTotalAddedB1,
      refillTotalAddedB2: this.refillTotalAddedB2,
      interventionV3PostDto: interventionToPost,
    };
    this.upcv3Service.refill(this.upcv3Id, refillToPost).pipe(
      finalize(() => this.isSaving = false)
    ).subscribe(() => {
      this.toasterService.pop('success', `Refill fait`);
      this.ngOnInit();
    }, err => {
      this.toasterService.pop('error', `Impossible de créer l'intervention`, err.error.code);
    });
  }

  refillGroup() {
    this.isSaving = true;
    const interventionToPost: InterventionV3PostDto = {
      ...this.form.value,
      objet: this.form.value.objet.map(o => o.value).join(', '),
      groupUpcv3Id: this.groupUpcv3Id,
      siteId: this.site.id,
      start: new Date(this.form.value.interventionDatetime),
      operateurId: this.connectedUser.id,
      origin: Origin.WEB,
    };
    const groupedBottlesGoingToLocation = _.groupBy(this.getBottlesDiff(this.bottlesDiff.stock).bottlesAdded, b => b.destinationStock.id);
    const bottlesIdToAddToLocation = _.map(groupedBottlesGoingToLocation, (value, key): BottlesToAddToLocation => {
      return {
        locationId: key,
        bottlesId: value.map(b => b.id),
      };
    });
    const refillToPost: RefillPostDto = {
      refillInterventionDatetime: new Date(this.form.value.interventionDatetime),
      bottlesIdToAddToB1: this.getBottlesDiff(this.bottlesDiff.b1).bottlesAdded.map(b => b.id),
      bottlesIdToAddToB2: this.getBottlesDiff(this.bottlesDiff.b2).bottlesAdded.map(b => b.id),
      bottlesIdToPutInTransit: this.getBottlesDiff(this.bottlesDiff.in_transit).bottlesAdded.map(b => b.id),
      bottlesIdToAddToLocation: bottlesIdToAddToLocation,
      interventionV3PostDto: interventionToPost,
      refillRealAddedB1: this.refillRealAddedB1,
      refillRealAddedB2: this.refillRealAddedB2,
      refillReplaceAllB1: this.refillReplaceAllB1,
      refillReplaceAllB2: this.refillReplaceAllB2,
      b1Empty: this.b1Empty,
      b2Empty: this.b2Empty,
      actualContentB1: co2LitersToKg(this.groupUpcv3._co2Res1ActVol),
      actualContentB2: co2LitersToKg(this.groupUpcv3._co2Res2ActVol),
      contentToDateB1: this.refillTotalConsumedB1,
      contentToDateB2: this.refillTotalConsumedB2,
      refillTotalAddedB1: this.refillTotalAddedB1,
      refillTotalAddedB2: this.refillTotalAddedB2,
    };
    this.upcv3Service.refillGroup(this.groupUpcv3Id, refillToPost).pipe(
      finalize(() => this.isSaving = false)
    ).subscribe(() => {
      this.toasterService.pop('success', `Refill fait`);
      this.ngOnInit();
    }, err => {
      this.toasterService.pop('error', `Impossible de créer l'intervention`, err.error.code);
    });
  }

  addIntervention() {
    this.isSaving = true;
    const interventionToPost: InterventionV3PostDto = {
      ...this.form.value,
      objet: this.form.value.objet.map(o => o.value).join(', '),
      siteId: this.site.id,
      start: new Date(this.form.value.interventionDatetime),
      operateurId: this.connectedUser.id,
      origin: Origin.WEB,
    };
    this.interventionV3Service.create(interventionToPost).pipe(
      finalize(() => this.isSaving = false)
    ).subscribe(() => {
      this.toasterService.pop('success', 'Intervention crée');
      this.ngOnInit();
    }, err => {
      this.toasterService.pop('error', `Impossible de créer l'intervention`, err.error.code);
    });
  }

  totalConsumedCalculationPerUpc(upcv3: UPCV3, from: Date): Observable<{
      refillTotalConsumed: {
        refillTotalConsumedB1: number;
        refillTotalConsumedB2: number;
        upc: UPCV3;
      };
      contentBetweenDates: ContentChartDataWithoutMetadata[] | null;
    }> {
    let refillTotalConsumedB1 = 0;
    let refillTotalConsumedB2 = 0;

    // Utility funciton for date to string manipulation
    function dateToDatePlusNDays(date: Date, nbDaysToAdd: number): Date {
      let newDate = new Date(date);
      newDate.setDate(newDate.getDate() + nbDaysToAdd);
      return newDate;
    }
    function diff_minutes(dt2: Date, dt1: Date) {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
    function sameDay(d1: Date, d2: Date) {
      return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    }
    var toDate = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60000);
    var to = toDate.toISOString();
    // Get content stats
    if (sameDay(from, new Date())) {
      const computedContent = of({
        refillTotalConsumedB1: co2LitersToKg(upcv3.reservesParameters.co2Res1ActVol),
        refillTotalConsumedB2: co2LitersToKg(upcv3.reservesParameters.co2Res2ActVol),
        upc: upcv3,
      })
      return forkJoin({
        refillTotalConsumed: computedContent,
        contentBetweenDates: of(null),
      });
    }
    else {
      const computedContent = this.upcv3Service.getBeforeEvents(upcv3.id, from.toISOString()).pipe(switchMap(
        async res => {
          if (res === undefined) {
            refillTotalConsumedB1 = 0;
            refillTotalConsumedB2 = 0;
          } else {
            if (res.eventCode.id === 50 && res.eventParam3 === "1") {
              //param 2
              var intensities = (Number(res.eventParam2) * CO2_LITERS_TO_KG_RATIO * upcv3.generalParameters.co2FlowRefNom) / 10;

              refillTotalConsumedB1 = Number(res.eventParam4) - diff_minutes(from, res.eventDatetime) * intensities;
              const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
              refillTotalConsumedB2 = stats[0].b2;
            }
            else if (res.eventCode.id === 50 && res.eventParam3 === "2") {
              var intensities = (Number(res.eventParam2) * CO2_LITERS_TO_KG_RATIO * upcv3.generalParameters.co2FlowRefNom) / 10;

              refillTotalConsumedB2 = Number(res.eventParam4) - diff_minutes(from, res.eventDatetime) * intensities;
              //param 2
              const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
              refillTotalConsumedB1 = stats[0].b1;
            }
            else if (res.eventCode.id >= 51 && res.eventCode.id <= 53 && res.eventParam2 === "1") {
              //param 1
              var intensities = (Number(res.eventParam1) * CO2_LITERS_TO_KG_RATIO * upcv3.generalParameters.co2FlowRefNom) / 10;
              refillTotalConsumedB1 = Number(res.eventParam3) - diff_minutes(from, res.eventDatetime) * intensities;
              const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
              refillTotalConsumedB2 = stats[0].b2;
            }
            else if (res.eventCode.id >= 51 && res.eventCode.id <= 53 && res.eventParam2 === "2") {
              //param 1
              //v258 gitlab
              var intensities = (Number(res.eventParam1) * CO2_LITERS_TO_KG_RATIO * upcv3.generalParameters.co2FlowRefNom) / 10;

              refillTotalConsumedB2 = Number(res.eventParam3) - diff_minutes(from, res.eventDatetime) * intensities;
              const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
              refillTotalConsumedB1 = stats[0].b1;
            }
            else if (res.eventCode.id == 59) {
              if (res.eventParam1 === "1") {
                // Intensité fixée à 2 temporairement à changer lors du refactor de cette partie
                var intensities = (Number(2) * CO2_LITERS_TO_KG_RATIO * upcv3.generalParameters.co2FlowRefNom) / 10;
                refillTotalConsumedB1 = Number(res.eventParam3) - diff_minutes(from, res.eventDatetime) * intensities;
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                refillTotalConsumedB2 = Number(res.eventParam4);
              } else if (res.eventParam1 === "2") {
                // Intensité fixée à 2 temporairement à changer lors du refactor de cette partie
                var intensities = (Number(2) * CO2_LITERS_TO_KG_RATIO * upcv3.generalParameters.co2FlowRefNom) / 10;
                refillTotalConsumedB2 = Number(res.eventParam4) - diff_minutes(from, res.eventDatetime) * intensities;
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                refillTotalConsumedB1 = Number(res.eventParam3);
              }
            }
            else if (res.eventCode.id == 56 || res.eventCode.id == 57) {
              if (res.eventParam1 === "1") {
                refillTotalConsumedB1 = Number(res.eventParam2);
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                refillTotalConsumedB2 = stats[0].b2;
              }
              else if (res.eventParam1 === "2") {
                refillTotalConsumedB2 = Number(res.eventParam2);
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                refillTotalConsumedB1 = stats[0].b1;
              }
            }
            else {
              if (res.eventParam2 === "1") {
                refillTotalConsumedB1 = Number(res.eventParam3);
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                refillTotalConsumedB2 = stats[0].b2;
              }
              else if (res.eventParam2 === "2") {
                refillTotalConsumedB2 = Number(res.eventParam3);
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                refillTotalConsumedB1 = stats[0].b1;
              }
              else {
                const stats = await this.upcv3Service.getContentStatsLDT(upcv3.id, from.toISOString(), to).toPromise();
                var diff = from.getHours();
                var days = stats[0].diff;
                refillTotalConsumedB2 = stats[0].b2;
                refillTotalConsumedB1 = stats[0].b1;
              }
            }
          }
          return {
            refillTotalConsumedB1,
            refillTotalConsumedB2,
            upc: upcv3
          };
        }
      ));
      const seventDaysBeforeInterventionDate = dateToDatePlusNDays(from, -7);
      return forkJoin({
        refillTotalConsumed: computedContent,
        contentBetweenDates: this.upcv3Service.getContentStatsbetweenDates(upcv3.id,
          seventDaysBeforeInterventionDate.toISOString().split('T')[0],
          from.toISOString().split('T')[0])
      });
    }
  }

  totalConsumedCalculation() {
    if (!this.form.value.interventionDatetime) {
      return;
    }
    this.isSaving = true;
    this.isContentToDateLoading = true;
    const interventionDatetime: Date = this.form.value.interventionDatetime;
    var from = new Date(interventionDatetime.getTime() - interventionDatetime.getTimezoneOffset()*60000);

    if (interventionDatetime && new Date(interventionDatetime).getFullYear() > 1971) {
      if (this.upcv3) {
        this.totalConsumedCalculationPerUpc(this.upcv3, from).pipe(
          finalize(() => this.isContentToDateLoading = false)
        ).subscribe(({refillTotalConsumed, contentBetweenDates}) => {
          this.refillTotalConsumedB1 = 0;
          this.refillTotalConsumedB2 = 0;
          const { refillTotalConsumedB1, refillTotalConsumedB2 } = refillTotalConsumed;
          if (contentBetweenDates?.length == 0) {
            this.toasterService.pop('warning', "Erreur", "Aucune information de contenu n'est presente dans les 7 jours précédent la date d'intervention. Prière d'effectuer l'intervention à la date d'aujourd'hui");
          } else if (contentBetweenDates?.every(data => data.b1 == null)) {
            this.toasterService.pop('warning', "RESERVE B1", "Aucune information de contenu n'est presente dans les 7 jours precedent pour la reserve B1");
          }
          if (contentBetweenDates?.length == 0) {
            this.toasterService.pop('warning', "Erreur", "Aucune information de contenu n'est presente dans les 7 jours précédent la date d'intervention. Prière d'effectuer l'intervention à la date d'aujourd'hui");
          } else if (contentBetweenDates?.every(data => data.b2 == null)) {
            this.toasterService.pop('warning', "RESERVE B2", "Aucune information de contenu n'est presente dans les 7 jours precedent pour la reserve B2");
          }
          this.refillTotalConsumedB1 = refillTotalConsumedB1;
          this.refillTotalConsumedB2 = refillTotalConsumedB2;
          this.computeRealAdded();
          this.isSaving = false;
        });
      }
      if (this.groupUpcv3) {
        forkJoin(this.groupUpcv3.upcv3s.map(upc => this.totalConsumedCalculationPerUpc(upc, from))).pipe(
          finalize(() => this.isContentToDateLoading = false)
        ).subscribe(totalConsumedOfGroup => {
          this.refillTotalConsumedB1 = 0;
          this.refillTotalConsumedB2 = 0;
          for (const totalConsumedOfUpc of totalConsumedOfGroup) {
            const { refillTotalConsumedB1, refillTotalConsumedB2, upc } = totalConsumedOfUpc.refillTotalConsumed;
            this.refillTotalConsumedB1 += refillTotalConsumedB1;
            this.refillTotalConsumedB2 += refillTotalConsumedB2;
            if (totalConsumedOfUpc.contentBetweenDates?.length == 0) {
              this.toasterService.pop('warning', "Erreur" + upc.upcNameId, "Aucune information de contenu n'est presente dans les 7 jours précédent la date d'intervention. Prière d'effectuer l'intervention à la date d'aujourd'hui");
              continue;
            }
            if (totalConsumedOfUpc.contentBetweenDates?.every(data => data.b1 == null)) {
              this.toasterService.pop('warning', "RESERVE B1 : " + upc.upcNameId, "Aucune information de contenu n'est presente dans les 7 jours precedent pour la reserve B1");
            }
            if (totalConsumedOfUpc.contentBetweenDates?.every(data => data.b2 == null)) {
              this.toasterService.pop('warning', "RESERVE B2 : " + upc.upcNameId, "Aucune information de contenu n'est presente dans les 7 jours precedent pour la reserve B2");
            }
          }
          this.computeRealAdded();
          this.isSaving = false;
        });
      }
    }
  }
}