import { Component, OnInit } from '@angular/core';
import { Site } from '../../../models/management/site';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteService } from '../../../services/management/site.service';
import { Stock } from '../../../models/management/stock';
import { StockService } from '../../../services/management/stock.service';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { ToasterService } from 'angular2-toaster';
import { SitePutDto } from 'src/app/models/dto/SitePut.dto';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'edit-site-content',
  templateUrl: './edit-site.component.html'
})
export class EditSiteModalComponent extends Modal<Site> implements OnInit {
  // Form
  addressUnknow = false;
  nameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  stocks: Stock[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private siteService: SiteService,
    private stockService: StockService,
    private toasterService: ToasterService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      clientNumber: '',
      name: ['', Validators.required],
      infos: '',
      address: '',
      lat: 0,
      lng: 0,
      postcode: '',
      city: '',
      departement: '',
      stocks: null
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  ngOnInit() {
    // Update form
    this.form.patchValue({
      clientNumber: this.subject.clientNumber,
      name: this.subject.name,
      infos: this.subject.infos,
      address: this.subject.address,
      lat: this.subject?.latitude ?? 0,
      lng: this.subject?.longitude ?? 0,
      stocks: this.subject.stocks ? this.subject.stocks.map(s => s.id) : null,
      postcode: this.subject.postcode,
      city: this.subject.city,
      departement: this.subject.departement,
    });

    // Load stocks
    this.loadStocks();
  }

  // Stock
  loadStocks() {
    this.stockService.getAll().subscribe({
      next: res => this.stocks = res,
      error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des stocks")
    })
  }

  // Geolcate
  geolocate() {
    new window['google'].maps.Geocoder().geocode({ 'address': this.f.address.value }, function (results, status) {
      if (status == 'OK') {
        // Update form
        this.form.patchValue({
          address: results[0].formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          postcode: results[0].address_components.find(a => a.types.includes('postal_code'))?.long_name,
          city: results[0].address_components.find(a => a.types.includes('locality'))?.long_name,
          departement: results[0].address_components.find(a => a.types.includes('administrative_area_level_2'))?.long_name
        })
      } else {
        this.addressUnknow = true;
      }
    }.bind(this));
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try edit
    this.formSubmitted = true;
    let editedSitedto: SitePutDto = {
      ...(this.form.value.address != null && { address: this.form.value.address }),
      ...(this.form.value.name != null && { name: this.form.value.name }),
      ...(this.form.value.clientNumber != null && { clientNumber: this.form.value.clientNumber }),
      ...(this.form.value.infos != null && { infos: this.form.value.infos }),
      ...(this.form.value.address != null && { address: this.form.value.address }),
      ...(this.form.value.lat != null && { latitude: this.form.value.lat }),
      ...(this.form.value.lng != null && { longitude: this.form.value.lng }),
      ...(this.form.value.stocks != null && { stocks: this.form.value.stocks }),
      ...(this.form.value.postcode != null && { postcode: this.form.value.postcode }),
      ...(this.form.value.city != null && { city: this.form.value.city }),
      ...(this.form.value.departement != null && { departement: this.form.value.departement }),
    };


    this.siteService.edit(this.subject.id, editedSitedto).subscribe({
      next: (res) => this.close(true),
      error: (err: HttpErrorResponse) => {
        if (err.status == 409) {
          this.toasterService.pop('error', "Erreur", "Une erreur s'est produite, un site avec le même nom doit surement déja exister");
          this.nameAlreadyUsed = true;
          this.formSubmitted = false;
        } else {
          this.toasterService.pop('error', "Erreur", "Une erreur s'est produite...");

        }
      }
    });
  }

}