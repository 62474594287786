<!-- Zoom -->
<div id="cad-zoom" class="btn-group-vertical">
  <button type="button" class="btn btn-light" (click)="cad.setZoom(cad.version.zoom / 0.9)"><i class="fa fa-plus"></i></button>
  <button type="button" class="btn btn-light" (click)="cad.setZoom(cad.version.zoom * 0.9)"><i class="fa fa-minus"></i></button>
</div><br>

<!-- Rotation -->
<knob [min]="0"
      [max]="360"
      [(ngModel)]="cad.version.rotation"
      (ngModelChange)="cad.setRotation(cad.version.rotation)"
      [startDegree]="0"
      [endDegree]="360">
</knob>