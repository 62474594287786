import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Project } from 'src/app/models/gdp/project';
import { VersionService } from 'src/app/services/gdp/version.service';

@Component({
  templateUrl: './add-version.component.html'
})
export class AddVersionModalComponent extends Modal<Project> implements OnInit {
  // Form
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private versionService: VersionService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      type: [ '', Validators.required ],
      numberOrder: [ 1, Validators.required ],
      project: '',
      versionSource: null
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  ngOnInit() { this.form.patchValue({ project: this.subject.id }) }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid) return;

    // Try create
    this.formSubmitted = true;
    this.versionService.create(this.form).subscribe(
      () => this.close(true),
      () =>this.close(true)
    );
  }

}