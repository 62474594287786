<div class="card card-body ">
  <div class="row mb-4">
    <!-- Reserve Low Alarm -->
    <div class="col-4">
      <div
        class="card bg-light h-100"
      >
        <div class="card-body">
          <h5 class="card-title">Réserve active basse</h5>
          <dl class="row mb-0">
            <!-- alrResLowEn -->
            <dd class="col-md-6 mb-0">E-mail</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrResLowEn.value"
                (valueChange)="vars.alrResLowEn.changed = true"
                [switchColor]="(vars.alrResLowEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>

            <!-- alrResLowLevel -->
            <dd class="col-md-6 mb-0">Seuil contenu [%]</dd>
            <dt class="col-md-6 mb-0 text-md-right">
              <input
                type="number"
                class="form-control form-control-sm"
                [ngClass]="{
                  'bg-warning': vars.alrResLowLevel.changed
                }"
                [disabled]="isSync"
                [(ngModel)]="vars.alrResLowLevel.value"
                (ngModelChange)="vars.alrResLowLevel.changed = true"
              />
            </dt>
          </dl>
        </div>
      </div>
    </div>

    <!-- Reserve Empty Alarm -->
    <div class="col-4">
      <div
        class="card bg-light h-100"
      >
        <div class="card-body">
          <h5 class="card-title">Réserve active vide</h5>
          <dl class="row mb-0">
            <!-- alrResEmptyEn -->
            <dd class="col-md-6 mb-0">E-mail</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrResEmptyEn.value"
                (valueChange)="vars.alrResEmptyEn.changed = true"
                [switchColor]="(vars.alrResEmptyEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>

            <!-- alrResEmptyFlow -->
            <dd class="col-md-6 mb-0">Seuil flux [%]</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <input
                type="number"
                class="form-control form-control-sm"
                [ngClass]="{
                  'bg-warning': vars.alrResEmptyFlow.changed
                }"
                [disabled]="isSync"
                [(ngModel)]="vars.alrResEmptyFlow.value"
                (ngModelChange)="vars.alrResEmptyFlow.changed = true"
              />
            </dt>

            <!-- alrResEmptyTest -->
            <dd class="col-md-6 mb-0">Période de test de rés. vide [s]</dd>
            <dt class="col-md-6 mb-0 text-md-right">
              <input
                type="number"
                class="form-control form-control-sm"
                min="0"
                [ngClass]="{
                  'bg-warning': vars.alrResEmptyTest.changed
                }"
                [disabled]="isSync"
                [(ngModel)]="vars.alrResEmptyTest.value"
                (ngModelChange)="vars.alrResEmptyTest.changed = true"
              />
            </dt>
          </dl>
        </div>
      </div>
    </div>

    <!-- Input Pressure Alarm -->
    <div class="col-4">
      <div
        class="card bg-light h-100"
      >
        <div class="card-body">
          <h5 class="card-title">Pression d'entrée moyenne</h5>
          <dl class="row mb-0">
            <!-- alrPresInpEn -->
            <dd class="col-md-6 mb-0">E-mail</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrPresInpEn.value"
                (valueChange)="vars.alrPresInpEn.changed = true"
                [switchColor]="(vars.alrPresInpEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>

            <!-- alrPresInpTol -->
            <dd class="col-md-6 mb-0">Seuil pression [%]</dd>
            <dt class="col-md-6 mb-0 text-md-right">
              <input
                type="number"
                class="form-control form-control-sm"
                [ngClass]="{
                  'bg-warning': vars.alrPresInpTol.changed
                }"
                [disabled]="isSync"
                [(ngModel)]="vars.alrPresInpTol.value"
                (ngModelChange)="vars.alrPresInpTol.changed = true"
              />
            </dt>
          </dl>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <!-- Output Pressure Alarm -->
    <div class="col-4">
      <div
        class="card bg-light h-100"
      >
        <div class="card-body">
          <h5 class="card-title">Pression de sortie moyenne</h5>
          <dl class="row mb-0">
            <!-- alrPresOutEn -->
            <dd class="col-md-6 mb-0">E-mail</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrPresOutEn.value"
                (valueChange)="vars.alrPresOutEn.changed = true"
                [switchColor]="(vars.alrPresOutEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>

            <!-- alrPresOutTol -->
            <dd class="col-md-6 mb-0">Seuil pression [%]</dd>
            <dt class="col-md-6 mb-0 text-md-right">
              <input
                type="number"
                class="form-control form-control-sm"
                [ngClass]="{
                  'bg-warning': vars.alrPresOutTol.changed
                }"
                [disabled]="isSync"
                [(ngModel)]="vars.alrPresOutTol.value"
                (ngModelChange)="vars.alrPresOutTol.changed = true"
              />
            </dt>
          </dl>
        </div>
      </div>
    </div>

    <!-- Flow Alarm -->
    <div class="col-4">
      <div
        class="card bg-light h-100"
      >
        <div class="card-body">
          <h5 class="card-title">Flux moyen</h5>
          <dl class="row mb-0">
            <!-- alrFlowAvgEn -->
            <dd class="col-md-6 mb-0">E-mail</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrFlowAvgEn.value"
                (valueChange)="vars.alrFlowAvgEn.changed = true"
                [switchColor]="(vars.alrFlowAvgEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>

            <!-- alrFlowSetTol -->
            <dd class="col-md-6 mb-0">Seuil d'écart [%]</dd>
            <dt class="col-md-6 mb-0 text-md-right">
              <input
                type="number"
                class="form-control form-control-sm"
                [ngClass]="{
                  'bg-warning': vars.alrFlowSetTol.changed
                }"
                [disabled]="isSync"
                [(ngModel)]="vars.alrFlowSetTol.value"
                (ngModelChange)="vars.alrFlowSetTol.changed = true"
              />
            </dt>
          </dl>
        </div>
      </div>
    </div>

    <!-- Power Alarm -->
    <div class="col-4">
      <div
        class="card bg-light h-100"
      >
        <div class="card-body">
          <h5 class="card-title">Alimentation</h5>
          <dl class="row mb-0">
            <!-- alrPowBackEn -->
            <dd class="col-md-6 mb-0">E-mail Rétablie</dd>
            <dt class="col-md-6 mb-2 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrPowBackEn.value"
                (valueChange)="vars.alrPowBackEn.changed = true"
                [switchColor]="(vars.alrPowBackEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>

            <!-- alrPowDownEn -->
            <dd class="col-md-6 mb-0">E-mail Coupée</dd>
            <dt class="col-md-6 mb-0 text-md-right">
              <ui-switch
                size="small"
                [disabled]="isSync"
                [(ngModel)]="vars.alrPowDownEn.value"
                (valueChange)="vars.alrPowDownEn.changed = true"
                [switchColor]="(vars.alrPowDownEn.changed) ? 'orange' : null">
              </ui-switch>
            </dt>
          </dl>
        </div>
      </div>
    </div>
  </div>

</div>
