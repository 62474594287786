import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DesignerService } from '../../../services/management/designer.service';
import { Designer } from '../../../models/management/designer';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'edit-designer-content',
  templateUrl: './edit-designer.component.html'
})
export class EditDesignerModalComponent extends Modal<Designer> implements OnInit {
  // Form
  usernameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private designerService: DesignerService
  ) {
    super();

    // Form
    this.form = this.fb.group({ username: [ '', Validators.required ], password: '', lastName: '', firstName: '', phone: '' });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  ngOnInit() {
    this.form.setValue({ username: this.subject.username, password: '', lastName: this.subject.lastName, firstName: this.subject.firstName, phone: this.subject.phone })
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try edit
    this.formSubmitted = true;
    this.designerService.edit(this.subject.id, this.form).subscribe(
      res => {
        switch (res.code) {

          case Code.DESIGNER_EDITED:
          case Code.DESIGNER_DOESNT_EXSIST:
          case Code.UNAUTHORIZED:
          this.close(true);
          break;

          case Code.DESIGNER_ALREADY_EXSIST:
          this.usernameAlreadyUsed = true;
          this.formSubmitted = false;
          break;

        }
      }, () => this.close()
    )
  }

}