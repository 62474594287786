import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../../material-module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Pages
import { UpcMcuTableComponent } from './upcMcuTable.component';
import { ModalWithUpcMcuTestsComponent } from './modalWithUpcMcuTests/modalWithUpcMcuTests';
import { UpcMcuTestListComponent } from './upcMcuTestList/upcMcuTestList.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: [
    // Pages
    UpcMcuTableComponent,
    ModalWithUpcMcuTestsComponent,
    UpcMcuTestListComponent
  ]
})

export class UpcMcuTableModule { }
