import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { Stock } from './stock';

@jsonObject
export class StockManager {
  
  @jsonMember
  archived: boolean;
  
  @jsonMember
  id: string;
  username: string;
  
  @jsonMember
  lastName: string;
  
  @jsonMember
  firstName: string;
  
  @jsonMember
  phone: string;
  
  @jsonArrayMember(() => Stock)
  stocks: Array<Stock>;

  static loadFromJSON(json): StockManager {
		let serializer = new TypedJSON(StockManager);
		return serializer.parse(json);
	}}