<div class="row mt-3">

  <!-- Date Range -->
  <div class="col-sm-6">
    <div class="form-group">
      <label>Du</label>
      <div class="input-group">
        <input class="form-control" [(ngModel)]="from" ngbDatepicker #dFrom="ngbDatepicker" (ngModelChange)="onDateSelection()" [ngClass]="{ 'is-invalid': wrongRange }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="dFrom.toggle()" type="button"><i class="fa fa-calendar"></i></button>
        </div>
        <div class="invalid-feedback" *ngIf="wrongRange">La date de début est après la date de fin</div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="form-group">
      <label>Au</label>
      <div class="input-group">
        <input class="form-control" [(ngModel)]="to" ngbDatepicker #dTo="ngbDatepicker" (ngModelChange)="onDateSelection()" [ngClass]="{ 'is-invalid': wrongRange }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="dTo.toggle()" type="button"><i class="fa fa-calendar"></i></button>
        </div>
        <div class="invalid-feedback" *ngIf="wrongRange">La date de début est après la date de fin</div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <ul ngbNav #nav="ngbNav" (activeIdChange)="navChanged()" class="nav-pills">
      <!-- Content -->
      <li ngbNavItem>
        <a ngbNavLink>Contenu</a>
        <ng-template ngbNavContent>
          <p class="text-center" *ngIf="contentChartData && contentChartData.length == 0">Aucune donnée</p>
          <div style="height: 500px;" *ngIf="contentChartData && contentChartData.length > 0">
            <ngx-charts-line-chart
              [results]="contentChartData"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [autoScale]="true">
            </ngx-charts-line-chart>
          </div>
        </ng-template>
      </li>
      <!-- Conso -->
      <li ngbNavItem>
        <a ngbNavLink>Consommation</a>
        <ng-template ngbNavContent>
          <p class="text-center" *ngIf="consoChartData && consoChartData.length == 0">Aucune donnée</p>
          <p class="text-center" *ngIf="consoChartData && consoChartData.length > 0">Consommation totale pour la période sélectionnée : <b>{{ consoTotal }}</b> kg</p>
          <div style="height: 500px;" *ngIf="consoChartData && consoChartData.length > 0">
            <ngx-charts-line-chart
              [results]="consoChartData"
              [xAxis]="true"
              [yAxis]="true"
              [legend]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true"
              [autoScale]="true">
            </ngx-charts-line-chart>
          </div>
        </ng-template>
      </li>
      <!-- Diffusions -->
      <li ngbNavItem>
        <a ngbNavLink>Diffusions</a>
        <ng-template ngbNavContent>
          <p class="text-center" *ngIf="diffChartData && diffChartData.length == 0">Aucune donnée</p>
          <div style="height: 500px;" *ngIf="diffChartData && diffChartData.length > 0">
            <ngx-charts-bar-vertical-stacked
              [results]="diffChartData"
              [customColors]="diffChartColors"
              [xAxis]="true"
              [yAxis]="true"
              [showXAxisLabel]="true"
              [showYAxisLabel]="true">
              <ng-template #tooltipTemplate let-model="model">
                <div class="p-1">
                  {{ model.name }}
                  <br>
                  {{ model.content }}
                </div>
              </ng-template>
            </ngx-charts-bar-vertical-stacked>
          </div>
        </ng-template>
      </li>

      <!-- Table -->
      <li ngbNavItem>
        <a ngbNavLink>Table</a>
        <ng-template ngbNavContent>
          <div class="row justify-content-center align-items-center mt-2">
            <div class="col-12 col-sm-10 col-md-7 col-lg-5">
              <table class="m-2 table table-sm table-bordered text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th>S1</th>
                    <th>S2</th>
                    <th>S3</th>
                    <th>S4</th>
                    <th>Intensité</th>
                    <th>Consommation (kg)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Janvier</th>
                    <td>{{ tableStats[0][0] }}</td>
                    <td>{{ tableStats[0][1] }}</td>
                    <td>{{ tableStats[0][2] }}</td>
                    <td>{{ tableStats[0][3] }}</td>
                    <td>{{ tableStats[0][4] }}</td>
                    <td>{{ tableStats[0][5] }}</td>
                  </tr>
                  <tr>
                    <th>Février</th>
                    <td>{{ tableStats[1][0] }}</td>
                    <td>{{ tableStats[1][1] }}</td>
                    <td>{{ tableStats[1][2] }}</td>
                    <td>{{ tableStats[1][3] }}</td>
                    <td>{{ tableStats[1][4] }}</td>
                    <td>{{ tableStats[1][5] }}</td>
                  </tr>
                  <tr>
                    <th>Mars</th>
                    <td>{{ tableStats[2][0] }}</td>
                    <td>{{ tableStats[2][1] }}</td>
                    <td>{{ tableStats[2][2] }}</td>
                    <td>{{ tableStats[2][3] }}</td>
                    <td>{{ tableStats[2][4] }}</td>
                    <td>{{ tableStats[2][5] }}</td>
                  </tr>
                  <tr>
                    <th>Avril</th>
                    <td>{{ tableStats[3][0] }}</td>
                    <td>{{ tableStats[3][1] }}</td>
                    <td>{{ tableStats[3][2] }}</td>
                    <td>{{ tableStats[3][3] }}</td>
                    <td>{{ tableStats[3][4] }}</td>
                    <td>{{ tableStats[3][5] }}</td>
                  </tr>
                  <tr>
                    <th>Mai</th>
                    <td>{{ tableStats[4][0] }}</td>
                    <td>{{ tableStats[4][1] }}</td>
                    <td>{{ tableStats[4][2] }}</td>
                    <td>{{ tableStats[4][3] }}</td>
                    <td>{{ tableStats[4][4] }}</td>
                    <td>{{ tableStats[4][5] }}</td>
                  </tr>
                  <tr>
                    <th>Juin</th>
                    <td>{{ tableStats[5][0] }}</td>
                    <td>{{ tableStats[5][1] }}</td>
                    <td>{{ tableStats[5][2] }}</td>
                    <td>{{ tableStats[5][3] }}</td>
                    <td>{{ tableStats[5][4] }}</td>
                    <td>{{ tableStats[5][5] }}</td>
                  </tr>
                  <tr>
                    <th>Juillet</th>
                    <td>{{ tableStats[6][0] }}</td>
                    <td>{{ tableStats[6][1] }}</td>
                    <td>{{ tableStats[6][2] }}</td>
                    <td>{{ tableStats[6][3] }}</td>
                    <td>{{ tableStats[6][4] }}</td>
                    <td>{{ tableStats[6][5] }}</td>
                  </tr>
                  <tr>
                    <th>Août</th>
                    <td>{{ tableStats[7][0] }}</td>
                    <td>{{ tableStats[7][1] }}</td>
                    <td>{{ tableStats[7][2] }}</td>
                    <td>{{ tableStats[7][3] }}</td>
                    <td>{{ tableStats[7][4] }}</td>
                    <td>{{ tableStats[7][5] }}</td>
                  </tr>
                  <tr>
                    <th>Septembre</th>
                    <td>{{ tableStats[8][0] }}</td>
                    <td>{{ tableStats[8][1] }}</td>
                    <td>{{ tableStats[8][2] }}</td>
                    <td>{{ tableStats[8][3] }}</td>
                    <td>{{ tableStats[8][4] }}</td>
                    <td>{{ tableStats[8][5] }}</td>
                  </tr>
                  <tr>
                    <th>Octobre</th>
                    <td>{{ tableStats[9][0] }}</td>
                    <td>{{ tableStats[9][1] }}</td>
                    <td>{{ tableStats[9][2] }}</td>
                    <td>{{ tableStats[9][3] }}</td>
                    <td>{{ tableStats[9][4] }}</td>
                    <td>{{ tableStats[9][5] }}</td>
                  </tr>
                  <tr>
                    <th>Novembre</th>
                    <td>{{ tableStats[10][0] }}</td>
                    <td>{{ tableStats[10][1] }}</td>
                    <td>{{ tableStats[10][2] }}</td>
                    <td>{{ tableStats[10][3] }}</td>
                    <td>{{ tableStats[10][4] }}</td>
                    <td>{{ tableStats[10][5] }}</td>
                  </tr>
                  <tr>
                    <th>Décembre</th>
                    <td>{{ tableStats[11][0] }}</td>
                    <td>{{ tableStats[11][1] }}</td>
                    <td>{{ tableStats[11][2] }}</td>
                    <td>{{ tableStats[11][3] }}</td>
                    <td>{{ tableStats[11][4] }}</td>
                    <td>{{ tableStats[11][5] }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">Consommation totale</td>
                    <td>
                      <b>
                        {{ tableStats[0][5] + tableStats[1][5] + tableStats[2][5] + tableStats[3][5] + tableStats[4][5] + tableStats[5][5] + tableStats[6][5] + tableStats[7][5] + tableStats[8][5] + tableStats[9][5] + tableStats[10][5] + tableStats[11][5] }}
                      </b>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="7">
                      <small>L'année du champ date de départ est utilisé</small>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>

</div>