import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from '../../../services/project/project.service';
import { Project } from '../../../models/project/project';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddVersionModalComponent } from './add-version/add-version.component';
import { Version } from '../../../models/project/version';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html'
})
export class ProjectComponent implements OnInit {
  project: Project;
  versions: Version[];
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    
    private modalService: NgbModal,
    private projectService: ProjectService
  ) { }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }  
  ngOnInit() {
    // Load project
    this.route.params.subscribe(params => {
      this.projectService.get(params['id']).pipe(takeUntil(this.onDestroy$)).subscribe(
        res => {
          switch (res.code) {

            case Code.PROJECT_RECOVERED:
            this.project = res.result;
            this.loadVersions();
            break;
  
            case Code.PROJECT_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
            this.router.navigateByUrl('/projects');
            break;
  
          }
        }, () => this.router.navigateByUrl('/projects')
      )
    })
  }

  loadVersions() {
    this.versions = null;
    this.projectService.getVersions(this.project.id).pipe(takeUntil(this.onDestroy$)).subscribe(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
          this.versions = res.result;
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          case Code.UNAUTHORIZED:
          this.versions = [];
          break;

        }
      }
    )
  }

  openAddVersionModal() {
    var addVersionModalRef = this.modalService.open(AddVersionModalComponent, { size: 'lg' });
    addVersionModalRef.componentInstance.init(this.project, addVersionModalRef, () => this.loadVersions());
  }

}