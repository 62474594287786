import { Commentaires } from './../../../models/upcv3/commentaire';
import { GroupUPCV3Service } from './../../../services/upcv3/groupUpcv3.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { UPCV3, co2LitersToKg } from "src/app/models/upcv3/upc/upcv3";
import { UPCV3Service } from "src/app/services/upcv3/upcv3.service";
import { FormBuilder, FormControl } from "@angular/forms";
import { Subject, forkJoin, timer, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NgbModal, NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { CommentmodalComponent } from "../commentmodal/commentmodal.component";
import { Router } from "@angular/router";
import { ConfirmationPollModalContent } from "src/app/pages/dashboard/confirmationPollModal/confirmationPollModal";
import { ConfirmationPollModalGroupContent } from "src/app/pages/dashboard/confirmationPollModalGroupe/confirmationPollModalGroup";
import { ConfirmationModal } from "../confirmationModal/confirmationModal";
import * as _ from "lodash";
import { Table } from "primeng/table";

import { MailAlert } from "../../../models/upcv3/mailalert";
import { Toast, ToasterService } from "angular2-toaster";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import moment from "moment";
import { MenuItem } from "primeng/api";
import { PrimeNGConfig, Translation } from "primeng/api";
import { GroupUPCV3 } from "src/app/models/GroupUPCV3/GroupUPCV3";
import { Site } from 'src/app/models/management/site';
import { MultiSelect } from 'primeng/multiselect';

interface DisplayableComment {
  commentClass: string;
  lastComment: Commentaires | null;
  lastCommentDate: Date | null;
}
interface DisplayableData {
  upc: UPCV3;
  isGroup: false;
  lastPollingHumanReadable?: string;
  name: string;
  checked: boolean;
  alarmSeverity: number;
  statusSeverity: number;
  severityStringUI: string;
  isRembo: boolean;
  site: Site;
  comment: DisplayableComment;
  trapNumber: number;
}

interface DisplayableDataGroup {
  isGroup: true;
  groupe: GroupUPCV3;
  displayableUpcs: DisplayableData[];
  name: string;
  groupId: number;
  lastPollingHumanReadable?: string;
  checked: boolean;
  alarmSeverity?: number;
  statusSeverity?: number;
  severityStringUI?: string;
  alertsSincePolling: MailAlert[];
  expanded: boolean;
  isRembo?: boolean;
  site: Site;
  comment: DisplayableComment;
  trapNumber: number;
}

const translationFR: Translation = {
  startsWith: "Commence par",
  contains: "Contient",
  notContains: "Ne contient pas ",
  endsWith: "Se termine par",
  equals: "Est égale à",
  notEquals: "N'est pas égale à",
  noFilter: "Aucun filtre",
  lt: "Inférieure à",
  lte: "Inférieure ou égale à",
  gt: "Supérieure à",
  gte: "Supérieure ou égale à",
  is: "Est",
  isNot: "N'est pas",
  before: "Avant",
  after: "Après",
  dateIs: "La date est",
  dateIsNot: "La date n`est pas",
  dateBefore: "Date avant",
  dateAfter: "Date après",
  clear: "Effacer",
  apply: "Appliquer",
  matchAll: "Correspond à tout",
  matchAny: "Correspond à l'une",
  addRule: "Ajouter une règle",
  removeRule: "Supprimer une règle",
  accept: "Oui",
  reject: "Non",
  choose: "Chosir",
  upload: "Envoyer",
  cancel: "Annuler",
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juileet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Auot",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  dateFormat: "dd/mm/yy",
  firstDayOfWeek: 1,
  today: "Aujourd'hui",
  weekHeader: "Sem.",
  weak: "Faible",
  medium: "Moyen",
  strong: "Fort",
  passwordPrompt: "Entrer un mot de passe",
  emptyMessage: "Aucun résultat",
  emptyFilterMessage: "Aucun résultat",
};

@Component({
  selector: "app-dashboard-upcv3",
  templateUrl: "./upcv3.component.html",
  styleUrls: ["./upcv3.component.css"],
  animations: [
    trigger("rowExpansionTrigger", [
      state(
        "void",
        style({
          transform: " scale(1,0)",
          opacity: 1,
        })
      ),
      state(
        "active",
        style({
          transform: " scale(1,1)",
          opacity: 1,
        })
      ),
      transition(
        "* <=> active",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)")
      ),
    ]),
  ],
  styles: [
    `
      .expanded {
        background-color: rgba(50, 50, 50, 0.2) !important;
      }
      .p-datatable-auto-layout > .p-datatable-wrapper {
        overflow-x: auto;
      }
      .p-datatable-responsive-scroll > .p-datatable-wrapper {
        overflow-x: auto;
      }
      .layout-news-active :host ::ng-deep .p-datatable tr > th {
        top: 0;
      }
    `,
  ],
})
export class UPCV3Component implements OnInit {
  @ViewChild("t", { static: false }) tooltip: NgbTooltip;
  @ViewChild("upcDataTable", { static: false }) table: Table;
  @ViewChild("InputFilterRef") inputFilterRef;
  @ViewChild("departementsFilter") departementsFilter: MultiSelect;
  selectedUpcs: Array<DisplayableData | DisplayableDataGroup> = [];
  upcv3group: DisplayableDataGroup[];
  choosebelt: Boolean = false;
  belts: Array<number> = [];
  clicked: boolean = false;
  message: string = "";
  daysview: Array<string> = [];
  eventdate: any;
  alerteName: any = [];
  allEvent: any;
  upcMap: any;
  displayableContentLoaded: Boolean;
  loading: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();
  toggleShowArchived = new FormControl();
  upcv3: Array<DisplayableData | DisplayableDataGroup>;
  displayableData: Array<DisplayableData | DisplayableDataGroup>;
  reloadDataTimer: NodeJS.Timeout;
  lastLoadedDataDate: Date;
  lastLoadedDataDateDisplayable: String;
  lastLoadedDataSubscription: Subscription;
  selectedUpcsAction: MenuItem[];

  departements: {[key:string]: string}[];

  constructor(
    private fb: FormBuilder,
    private upcv3Service: UPCV3Service,
    private groupUpcv3Service: GroupUPCV3Service,
    private modalService: NgbModal,
    private router: Router,
    private toasterService: ToasterService,
    private primeconfig: PrimeNGConfig
  ) {
    moment.locale("fr");
    this.upcMap = new Map();
    this.upcv3 = [];
    this.upcv3group = [];
    this.selectedUpcs = [];
    this.displayableContentLoaded = false;
    this.loading = true;
    this.updateLastLoadedData();

    this.selectedUpcsAction = [
      {
        tooltipOptions: {
          tooltipLabel: "Paramétrer Ceintures",
          tooltipPosition: "right",
        },
        icon: "pi pi-cog",
        command: () => {
          this.goToSetup();
        },
      },
      {
        //TODO: Fix tooltip
        tooltipOptions: {
          tooltipLabel: this.toggleShowArchived.value
            ? "Restaurer Ceintures"
            : "Archiver Ceintures",
          tooltipPosition: "top",
        },
        icon: "pi pi-trash",
        command: () => {
          this.goToArchive();
        },
      },
    ];
  }

  public ngOnDestroy(): void {
    clearTimeout(this.reloadDataTimer);
    this.onDestroy$.next();
  }

  co2LitersToKg = co2LitersToKg;

  updateLastLoadedData() {
    this.lastLoadedDataSubscription = timer(0, 1000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.lastLoadedDataDateDisplayable = moment(
          this.lastLoadedDataDate
        ).fromNow();
        if (moment(this.lastLoadedDataDate).add(2, "hours") < moment()) {
          this.ngOnInit();
        }
      });
  }

  clearFilters() {
    this.inputFilterRef.nativeElement.value = "";
    this.departementsFilter.uncheckAll();

    /* Primeng Table ne reset pas automatiquement le tri visuellement.
      Pour arriver au resultat escompté, il faut retrier par nom, puis
      Forcer un tri par statusSeverity, dans le mauvais sens (pour simuler l'appui sur un bouton du header)
      A modifier une fois que primeNg aura fix
    */
    this.table.reset();

    this.table.sortOrder = -1;
    this.table.sortField = "name";
    this.table.sort({ field: "name" });

    this.table.sortOrder = 1;
    this.table.sortField = "statusSeverity";
    this.table.sort({ field: "statusSeverity" });

    this.table.sortMode = "single";
    this.table.globalFilterFields = ["name"];
  }

  skeletonCounter(nbSkeletonLines: number): number[] {
    return new Array(nbSkeletonLines);
  }

  toLocaleString(date: Date | null, defaultValue: string = "-"): string {
    return (date != null && date.getFullYear() > 1971) ? date.toLocaleString("fr-FR") : defaultValue;
  }

  getStatusSeverityClass(severity: number): string {
    return UPCV3.getStatusSeverityClass(severity);
  }


  getAlarmSeverity(upc: DisplayableData | DisplayableDataGroup): number {
    let severity = 0;
    if ("upc" in upc) {
      severity = upc.upc.upcDashboardAlarmSeverityLevel;
    } else if ("groupe" in upc) {
      severity = upc.groupe.upcv3s.reduce((acc: number, current: UPCV3) => {
        return Math.max(acc, current.upcDashboardAlarmSeverityLevel);
      }, 0);
    }
    return severity;
  }

  goToSetup() {
    let upcArray = this.selectedUpcs.flatMap((displayable) => {
      if(displayable.isGroup === true) {
        return displayable.displayableUpcs.map((displayableUpc) => displayableUpc.upc);
      }
      return displayable.upc;
    });
    this.router.navigate(["/parametersBelt"], {
      state: { 
        data: upcArray.map((upc) => upc.id) 
      },
    });
  }

  goToArchive() {
    if (this.toggleShowArchived.value || false) {
      this.unarchiveBelts();
    } else {
      this.archiveBelts();
    }
  }

  getAlarmSeverityString(alarmSeverity: number): string {
    switch (alarmSeverity) {
      case 0:
      case 1:
        return "success";
      case 2:
        return "info";
      case 3:
        return "warning";
      case 4:
        return "critical";
      case 5:
        return "danger";

      default:
        return "";
    }
  }

  getAlarmSeverityClass(upc: DisplayableData | DisplayableDataGroup): string {
    switch (upc.alarmSeverity) {
      case 4:
        return "critical";

      default:
        return "";
    }
  }

  getLastPollingHumanReadable(upc: UPCV3): string {
    let lastPollingHumanReadable = "";
    if (
      upc.lastSuccessfullPollingEvent &&
      !upc.hibernated &&
      upc.lastPollResult != 0
    ) {
      const difftime = Math.round(
        (Date.now() -
          upc.lastSuccessfullPollingEvent.serverDatetime.getTime()) /
          (1000 * 60 * 60 * 24)
      );
      const readableParenthesis =
        Math.trunc(difftime) !== 0
          ? `(${difftime} Jours)`
          : `(${difftime * 24})`;
      lastPollingHumanReadable = `${upc.upcLastPollDatetimeString || ""} ${
        readableParenthesis || ""
      }`;
    } else {
      lastPollingHumanReadable = upc.upcLastPollDatetimeString;
    }

    return lastPollingHumanReadable;
  }

  onOpenCommentModal(site, upc?, group?) {
    let commentModal = this.modalService.open(CommentmodalComponent);
    commentModal.componentInstance.init({ upc: upc, group: group, site: site }, commentModal, () => {});
    commentModal.result.then(
      (data) => this.ngOnInit(),
      (reason) => this.ngOnInit()
    );
  }

  generateDisplayableDataForUpc(upc: UPCV3): DisplayableData {
    return {
      site: upc.site,
      upc: upc,
      name: upc.upcNameId,
      isGroup: false,
      checked: false,
      comment: {
        lastComment: upc.lastComment,
        commentClass: upc.lastActionNotDone ? "warning" : "primary",
        lastCommentDate: upc.lastComment?.date ?? null,
      },
      alarmSeverity: upc.upcDashboardAlarmSeverityLevel,
      statusSeverity: upc.getStatusSeverity(),
      severityStringUI: this.getAlarmSeverityString(upc.upcDashboardAlarmSeverityLevel),
      isRembo: upc.site.bottles.some(bottle => bottle.bottleType.isRembo && bottle.upcv3?.id == upc.id),
      trapNumber: upc.generalParameters.upcTrapNum,
      lastPollingHumanReadable: this.getLastPollingHumanReadable(upc),
    };
  }

  generateDisplayableDataForGroup(group: GroupUPCV3): DisplayableDataGroup {
    let displayableUpc: DisplayableData[] = group.upcv3s.map(upc => {
      upc.site = group.site;
      return this.generateDisplayableDataForUpc(upc)
    });
    let alarmSeverity = Math.max(...group.upcv3s.map(upc => upc.upcDashboardAlarmSeverityLevel))

    let lastComment = group.lastComment;

    return {
      isGroup: true,
      groupe: group,
      site: group.site,
      groupId: group.id,
      name: group.name,
      isRembo: group.site.bottles.some(bottle => bottle.bottleType.isRembo && bottle?.groupUPCV3?.id == group.id),
      checked: false,
      alarmSeverity: alarmSeverity,
      statusSeverity: group.getStatusSeverity(),
      alertsSincePolling: group.upcv3s.map(upc => upc.alertsSincePolling).flat(),
      severityStringUI: this.getAlarmSeverityString(alarmSeverity),
      displayableUpcs: displayableUpc,
      expanded: false,
      comment: {
        lastComment: lastComment,
        commentClass: group.lastActionNotDone ? "warning" : "primary",
        lastCommentDate: lastComment != null ? lastComment.date : null,
      },
      trapNumber: group.upcv3s.reduce((acc, upc) => acc + upc.generalParameters.upcTrapNum, 0),
    }
  }

  loadPage(): Promise<Array<DisplayableData | DisplayableDataGroup>> {
    return new Promise((resolve, reject) => {
      let upcv3ToReturn: Array<DisplayableData | DisplayableDataGroup> = [];
      const showArchived = this.toggleShowArchived.value || false;

      forkJoin([
        this.upcv3Service.getAll(false),
        this.groupUpcv3Service.get(showArchived)
      ]).subscribe({
        next: async ([upcs, groups]) => {
          upcs = upcs.filter(upc => upc.site != null && upc.archived == showArchived);
          upcs.sort((a, b) => a.upcNameId > b.upcNameId ? 1 : -1);

          for (const upc of upcs) {
            let data = this.generateDisplayableDataForUpc(upc);
            upcv3ToReturn.push(data);
          }

          //Groups
          for (const group of groups) {
            let displayableGroup: DisplayableDataGroup = this.generateDisplayableDataForGroup(group);
            upcv3ToReturn.push(displayableGroup);
          }

          this.departements = [...new Set([...upcs.map(u => u.site.departement), ...groups.map(g => g.site.departement)])]
            .map(d => ({
              value: d ? d : ''
            }));
          return resolve(upcv3ToReturn);
        },
        error: (err) => {
          if (err.status != 403) {
            this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des informations du backend");
          };
          reject(err);
        }
      });
    });
  }

  ngOnInit() {
    this.primeconfig.setTranslation(translationFR);
    this.upcv3 = [];
    this.displayableContentLoaded = false;
    this.lastLoadedDataDate = new Date();
    this.loadPage()
      .then((upcs) => {
        this.upcv3 = [...upcs];
        this.displayableContentLoaded = true;
        // Charge des données qui ne sont pas obligatoire au premier affichage
        // Cela donne l'impression que les données chargent vite
        // d'abord on rafraichi les upcs
        this.upcv3Service.getAll(false, "secondary").subscribe(upcs => {
          this.upcv3.filter(d => d.isGroup == false).map((displayedUpc: DisplayableData) => {
            const foundUpc = upcs.find(u => u.id == displayedUpc.upc.id);
            displayedUpc.upc.lastIpEvent = foundUpc.lastIpEvent;
            displayedUpc.upc.lastSuccessfullPollingEvent = foundUpc.lastSuccessfullPollingEvent;
            return displayedUpc;
          });
        });
        // et on rafraichi les upcs des groupes
        this.upcv3Service.getAll(true, "secondary").subscribe(upcs => {
          this.upcv3.filter(u => u.isGroup).map((displayedGroup: DisplayableDataGroup) => {
            displayedGroup.displayableUpcs.map(displayedUpcOfGroup => {
              const foundUpc = upcs.find(u => u.id == displayedUpcOfGroup.upc.id);
              displayedUpcOfGroup.upc.lastIpEvent = foundUpc.lastIpEvent;
              displayedUpcOfGroup.upc.lastSuccessfullPollingEvent = foundUpc.lastSuccessfullPollingEvent;
            })
            return displayedGroup;
          });
        });
      })
      .catch((err) => {
        var toast: Toast = {
          type: "error",
          title: "Erreur de récupération des données",
          body: "Cliquez sur ce message pour rééssayer",
          onClickCallback: (toast) => {
            this.ngOnInit();
          },
          tapToDismiss: true,
          timeout: 0,
        };
        this.upcv3 = [];
        this.displayableContentLoaded = true;
        if (err.status == 403) {
          return;
        };
        this.toasterService.pop(toast);
      });
  }

  togglePoll(upc: UPCV3, upcPollEnable: boolean) {
    this.upcv3Service
      .edit(upc.id, this.fb.group({ upcPollEnable }))
      .subscribe({
        next: (res) => upc.upcPollEnable = res.upcPollEnable,
        error: (err) => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la l'edition du poll actif")
      });
  }

  togglePollModalConfirmation(upc: UPCV3) {
    const modalRef = this.modalService.open(ConfirmationPollModalContent);
    modalRef.componentInstance.upc = upc;
    modalRef.result.then((result) => {
      if (result) {
        this.togglePoll(upc, !upc.upcPollEnable);
      }
    });
  }

  togglePollModalConfirmationforGroup(groupe: GroupUPCV3) {
    const modalRef = this.modalService.open(ConfirmationPollModalGroupContent);
    modalRef.componentInstance.upcGroup = groupe;
    modalRef.result.then((result) => {
      if (result) {
        if (groupe.upcv3s.every((upc) => upc.upcPollEnable == true)) {
          for (const upc of groupe.upcv3s) {
            this.togglePoll(upc, false);
          }
        } else {
          for (const upc of groupe.upcv3s) {
            this.togglePoll(upc, true);
          }
        }
      }
    });
  }

  async archiveBelts() {
    const ids = Array.from(this.selectedUpcs).flatMap(
      (displayableUpc) => {
        if (displayableUpc.isGroup === true) {
          return displayableUpc.displayableUpcs.map((displayableUpc) => displayableUpc.upc.id);
        } 
        return displayableUpc.upc.id
      }
    );
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = "Confirmez-vous l'archivage ?";
    modalRef.componentInstance.body =
      "Voulez-vous archiver les ceintures sélectionnées ?";
    const result = await modalRef.result;
    if (result) {
      this.upcv3Service.archiveBelts(ids, true).subscribe({
        next: (res) => this.ngOnInit(),
        error: err => this.toasterService.pop("error", "Erreur", "Un erreur s'est produite lors de l'archivage de ou des UPC sélectionnée(s)")
      });
    }
  }

  async unarchiveBelts() {
      const ids = Array.from(this.selectedUpcs).flatMap(
        (displayableUpc) => {
          if(displayableUpc.isGroup === true) {
            return displayableUpc.displayableUpcs.map((displayableUpc) => displayableUpc.upc.id);
          }
          return displayableUpc.upc.id;
        }
      );

      const modalRef = this.modalService.open(ConfirmationModal);
      modalRef.componentInstance.title = "Confirmez-vous la restauration ?";
      modalRef.componentInstance.body =
        "Voulez-vous restaurer les ceintures sélectionnées ?";
      const result = await modalRef.result;
      if (result) {
        this.upcv3Service.archiveBelts(ids, false).subscribe({
          next : (res) =>  this.ngOnInit(),
          error: err => this.toasterService.pop("error", "Erreur" , "Une rreur s'est produite lors du désarchivage de ou des UPC sélectionnée(s)")
        });
      }
    }

    onToggleShowArchived() {
      this.selectedUpcsAction = [
        {
          tooltipOptions: {
            tooltipLabel: "Paramétrer Ceintures",
            tooltipPosition: "right",
          },
          icon: "pi pi-cog",
          command: () => {
            this.goToSetup();
          },
        },
        {
          //TODO: Fix tooltip
          tooltipOptions: {
            tooltipLabel: this.toggleShowArchived.value
              ? "Restaurer Ceintures"
              : "Archiver Ceintures",
            tooltipPosition: "top",
          },
          icon: this.toggleShowArchived.value ? "pi pi-lock-open" : "pi pi-lock",
          command: () => {
            this.goToArchive();
          },
        },
      ];
      this.selectedUpcs = [];
      this.ngOnInit();
    }


    onHeaderCheckboxToggle(event:any) {
      if(event.checked == true) {
        // Si aucun filtre n'est appliqué alors on selectionne tout, même les upcs des groupes
        if (this.table.filteredValue == null) {
          for(const upc of this.upcv3) {
            if(upc.isGroup === true) {
              this.selectedUpcs.push(...upc.displayableUpcs);
            }
          }
        } else { // Sinon cela veut dire que des valeurs sont filtrées alors on selectionne que celles-ci
          const filteredUpcs = this.table.filteredValue.filter(u => u.isGroup == false);
          const filteredGroups = this.table.filteredValue.filter(u => u.isGroup == true);
          this.selectedUpcs.push(...filteredUpcs);
          this.selectedUpcs.push(...filteredGroups.flatMap(g => g.displayableUpcs));
        }
      } else {
        this.selectedUpcs = [];
      }
      this.table.updateSelectionKeys();
      this.table.expandedRowKeys = {};
    }
   
  }
