<div class="modal-header">
  <h4 class="modal-title">Nouveau Firmware</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- File -->
    <div class="input-group">
      <input type="file" class="form-control" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': f.file.errors }">
      <div *ngIf="f.file.errors" class="invalid-feedback">
        <div *ngIf="f.file.errors && f.file.errors.required && !readingFile">Fichier obligatoire</div>
        <div *ngIf="readingFile"><i class="fa fa-circle-notch fa-spin mr-2"></i>Chargement du fichier...</div>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>