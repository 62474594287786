import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Site } from '../../../models/management/site';
import { SiteService } from '../../../services/management/site.service';
import { UPCV3 } from '../../../models/upcv3/upc/upcv3';
import { AuthService } from '../../../auth/auth.service';
import { AssociateBeltModalComponent } from './associate-belt/associate-belt.component';
import { DissociateBeltModalComponent } from './dissociate-belt/dissociate-belt.component';

import { CreateBeltModalComponent } from './create-belt/create-belt.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { ConfirmationModal } from '../../dashboard/confirmationModal/confirmationModal';
import { GroupUPCV3Service } from 'src/app/services/upcv3/groupUpcv3.service';
import { Bottle, status } from 'src/app/models/management/bottle';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: [ './site.component.css' ]
})
export class SiteComponent implements OnInit {
  site: Site;
  storedBottles: Bottle[];
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,

    private siteService: SiteService,
    private upcv3Service: UPCV3Service,
    private groupUpcv3Service: GroupUPCV3Service,
    public authService: AuthService,
    private toasterService: ToasterService,
  ) { }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
   }    
  ngOnInit() {
    
    // Load site
    this.route.params.subscribe(params => {
      this.siteService.get(params['id']).pipe(takeUntil(this.onDestroy$)).subscribe({
        next: (res) => {
          this.site = res;
          this.storedBottles = res.bottles.filter(b => b.status == status.ENTREPOSE);
        },
        error: (err) => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des informations du site"),
      })
    })
  }

  /* Modals */
  openAssociateBeltModal() {
    var associateBeltModalRef = this.modalService.open(AssociateBeltModalComponent);
    associateBeltModalRef.componentInstance.init(this.site, associateBeltModalRef, () => this.ngOnInit())
  }

  async openArchiveGroupModal(id: number) {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = "Confirmez-vous l'archivage ?";
    modalRef.componentInstance.body =
      "Voulez-vous archiver le groupe sélectionné ? Les UPCs du groupes seront remises dans le site.";
    const result = await modalRef.result;
    if (result) {
      this.groupUpcv3Service.archive(id).subscribe({
        next: () => this.ngOnInit(),
        error: () => this.toasterService.pop("error", "Une erreur s'est produite lors de l'archivage du groupe."),
      });
    }
  }

  openCreateBeltModal() {
    var createBeltModalRef = this.modalService.open(CreateBeltModalComponent, { size: 'lg', backdrop: 'static' });
    createBeltModalRef.componentInstance.init(this.site, createBeltModalRef, () => this.ngOnInit())
  }

  openAddInterventionModal() {
    this.router.navigate(["site/"+this.site.id+"/intervention/add"]);
  }

  openCreateGroupe() {
    this.router.navigate(["groupBelt"], {
      state: { data: this.site.id },
    });
  }

  async openArchiveUpcModal(upcId: number) {
    const modalRef = this.modalService.open(ConfirmationModal);
    modalRef.componentInstance.title = "Confirmez-vous l'archivage ?";
    modalRef.componentInstance.body =
      "Voulez-vous archiver l'UPC sélectionné ?";
    const result = await modalRef.result;
    if (result) {
      this.upcv3Service.archiveBelts([upcId], true).subscribe({
        next: () => this.ngOnInit(),
        error: () => this.toasterService.pop("error", "Une erreur s'est produite lors de l'archivage de l'UPC."),
      });
    }  }
}