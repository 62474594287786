export class BottleLegacy {
  id: string = '';
  barcode: string = '';
  status: Status = Status.ENTREPOSE;
  state: State = State.FULL;
  bottleType: BottleTypeLegacy = null;
  bottleEvents: BottleEventLegacy[] = [];
  localisationId : string;
  localisationType: string;
  lastStatus: Status = Status.ENTREPOSE;

  lastLocalisationId : string;
  lastLocalisationType:string;

  get statusString(): string {
    switch (this.status) {
      case Status.ENTREPOSE: return 'Entreposée';
      case Status.B1: return 'Sur B1';
      case Status.B2: return 'Sur B2';
      case Status.REVOKED: return 'Retirée';

      case Status.UNDEFINED : return 'Indéfini'

    }
  }

  get bottleString(): string {
    return this.bottleType.designationString + ' (' + this.barcode + ')'
  }

  static loadFromJSON(json): BottleLegacy {
    var bottle: BottleLegacy = Object.assign(new BottleLegacy, json);
    if (json.bottleType) bottle.bottleType = BottleTypeLegacy.loadFromJSON(json.bottleType);
    if (json.bottleEvents) {
      bottle.bottleEvents = [];
      json.bottleEvents.forEach(jsonBottleEvent =>
        bottle.bottleEvents.push(BottleEventLegacy.loadFromJSON(jsonBottleEvent))
      );
    }
    return bottle;
  }
}

export class BottleEventLegacy {
  id: string = '';
  code: Code = Code.ENTREPOSAGE;
  date: Date = new Date();
  parameter: string = '';
  destinationId: string = '';
  destinationType : string = '';
  state : string = '';
  intervention_time :  Date = new Date();
  operator : string = '';
  bottle : BottleLegacy;
  bottleEv : BottleLegacy;
  bottleId : string;

  get codeString(): string {
    switch(this.code) {
      case Code.ENTREPOSAGE: 
      if(this.parameter === "0840ffbf-82ee-4f23-a3b9-96b1f99cefdd" || this.parameter === '1e2b0d98-44a8-4fe8-a412-299f0991919d' || this.parameter === "8eea82b3-e17c-4a62-8e4a-389a8f15e9a2" || this.parameter === "ff1c41aa-f9f7-478b-8b41-8616313f6d88"){
        return 'Ajout au stock';
      }
      else {
        return 'Ajout à la ceinture';
      }
      case Code.CONNEXION_A_B1: return 'Ajout à B1';
      case Code.CONNEXION_A_B2 : return 'Ajout à B2';
      case Code.RECEPTION: return 'Reception au stock';
      case Code.RENVOIE: return 'Suppression du stock';
    }
  }

  get parameterString(): string {
    if (this.parameter) return this.parameter;
    else return '-';
  }

  static loadFromJSON(json): BottleEventLegacy {
    var bottleEvent = Object.assign(new BottleEventLegacy, json);
    if (json.date) bottleEvent.date = new Date(json.date);
    return bottleEvent;
  }
}

export enum Status {
  ENTREPOSE = 'ENTREPOSE',
  B1 = 'B1',
  B2 = 'B2',
  REVOKED = 'REVOKED',
  UNDEFINED = 'UNDEFINED'
}

export enum State {
  FULL = 'FULL',
  IN_USE = 'IN_USE',
  EMPTY = 'EMPTY'
}

export enum Code {
  ENTREPOSAGE = 'ENTREPOSAGE',
  CONNEXION_A_B1 = 'CONNEXION_A_B1',
  CONNEXION_A_B2 = 'CONNEXION_A_B2',
  RECEPTION = 'RECEPTION',
  RENVOIE = 'RENVOIE'
}

export class BottleTypeLegacy {
  id:           string  = '';
	contenue:  number  = 0;
	brand:        string  = '';
  isRembo:      boolean = false;
  designation : string = "";

  get designationString(): string {
    return this.designation + ' ' + ' - ' + this.brand;
  }

  static loadFromJSON(json): BottleTypeLegacy {
    return Object.assign(new BottleTypeLegacy, json)
  }
}
