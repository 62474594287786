import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Project } from '../../models/project/project';
import { ProjectService } from '../../services/project/project.service';
import { AddProjectModalComponent } from './add-project/add-project.component';
import { EditProjectModalComponent } from './edit-project/edit-project.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PagerService } from 'src/app/services/pager.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationModal } from '../dashboard/confirmationModal/confirmationModal';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html'
})
export class ProjectsComponent implements OnInit {
  // NF
  projects: Project[];

  // Pagination
  pageSizes = [
    { value: 10,  label: 'Afficher 10 Projets' },
    { value: 50,  label: 'Afficher 50 Projets' },
    { value: 100, label: 'Afficher 100 Projets' }
  ];
  pager: any = {};
  pageSize = this.pageSizes[0].value;
  filter: string = '';
  currentPage = 1;
  private onDestroy$: Subject<void> = new Subject<void>();
  // Search
  searchForm: FormGroup;
  searchFormSubmitted = false;

  constructor(
    private projectService: ProjectService,
    private pagerService: PagerService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { }

  ngOnInit() {

    // Search
    this.searchForm = this.fb.group({
      filter: ['', Validators.minLength(3)]
    });

    // Load clients
    this.setPage(this.currentPage, this.pageSize);

  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  setPage(currentPage: number = 1, pageSize: number = 10, filter: string = '') {
    // Get records from service
    this.projects = null;
    this.projectService.getPage(pageSize, currentPage, filter).pipe(takeUntil(this.onDestroy$)).subscribe(
      res => {
        switch (res.code) {
          
          case Code.PROJECT_RECOVERED:
          this.projects = res.result.content;
          break;

          case Code.UNAUTHORIZED:
          this.projects = [];
          break;

        }

        // get pager object from service
        this.pager = this.pagerService.getPager(res.result.totalElements, res.result.totalElements, currentPage, pageSize);
        this.currentPage = this.pager.currentPage;
      }
    );
  }


  /* Modals */
  openAddProjectModal() {
    var addProjectModalRef = this.modalService.open(AddProjectModalComponent, { size: 'lg', backdrop: 'static' });
    addProjectModalRef.componentInstance.init(null, addProjectModalRef, () => this.setPage(this.currentPage, this.pageSize, this.filter))
  }

  openEditProjectModal(project: Project) {
    var editProjectModalRef = this.modalService.open(EditProjectModalComponent, { size: 'lg', backdrop: 'static' });
    editProjectModalRef.componentInstance.init(project, editProjectModalRef, () => this.setPage(this.currentPage, this.pageSize, this.filter))
  }

  async openDeleteProjectModal(project: Project) {
    const deleteProjectModalRef = this.modalService.open(ConfirmationModal);
    deleteProjectModalRef.componentInstance.title = "Confirmez-vous la suppression ?";
    deleteProjectModalRef.componentInstance.body = "Voulez-vous supprimer le projet ?";
    const result = await deleteProjectModalRef.result;
    if (result) {
      this.projectService.delete(project.id).subscribe(res => {
        this.setPage(this.currentPage, this.pageSize);
      });
    }
  }


  /* Search */
  get sf() { return this.searchForm.controls }
  onSearchFormSubmit() {
    this.searchFormSubmitted = true;

    // stop here if form is invalid
    if (this.searchForm.invalid) return;

    this.filter = this.searchForm.controls['filter'].value;
    this.setPage(1, this.pageSize, this.filter);
  }
  clearSearch(event) {
    this.filter = '';
    if (event.target.value.length == 0)
      this.setPage(1, this.pageSize, this.filter);
  }


}