<div class="row">
  <div class="col-12 mb-3">
    <mat-table [dataSource]="dataSource" matSort class="w-100">
      <ng-container matColumnDef="start">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold text-dark">Date</mat-header-cell>
        <mat-cell *matCellDef="let intervention">{{ intervention.start.toLocaleString() }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="objet">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold text-dark">Objet</mat-header-cell>
        <mat-cell *matCellDef="let intervention">{{ intervention.objet }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="upc">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="font-weight-bold text-dark">UPC ou Groupe</mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          <span *ngIf="intervention.upcv3">
            UPC: {{ intervention.upcv3.upcNameId }}
          </span>
          <span *ngIf="intervention.groupUPCV3">
            Groupe: {{ intervention.groupUPCV3.name }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="b1">
        <mat-header-cell *matHeaderCellDef class="font-weight-bold text-dark">B1</mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          <ng-container *ngIf="intervention.bottlesB1Added">+ {{ intervention.bottlesB1Added }}<br></ng-container>
          <ng-container *ngIf="intervention.bottlesB1Removed">- {{ intervention.bottlesB1Removed }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="b2">
        <mat-header-cell *matHeaderCellDef class="font-weight-bold text-dark">B2</mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          <ng-container *ngIf="intervention.bottlesB2Added">+ {{ intervention.bottlesB2Added }}<br></ng-container>
          <ng-container *ngIf="intervention.bottlesB2Removed">- {{ intervention.bottlesB2Removed }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="site">
        <mat-header-cell *matHeaderCellDef class="font-weight-bold text-dark">Site</mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          <ng-container *ngIf="intervention.bottlesHLAdded">+ {{ intervention.bottlesHLAdded }}<br></ng-container>
          <ng-container *ngIf="intervention.bottlesHLRemoved">- {{ intervention.bottlesHLRemoved }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="stock">
        <mat-header-cell *matHeaderCellDef class="font-weight-bold text-dark">Stock</mat-header-cell>
        <mat-cell *matCellDef="let intervention">
          <ng-container *ngIf="intervention.bottlesPutOutside">+ {{ intervention.bottlesPutOutside }}<br></ng-container>
          <ng-container *ngIf="intervention.bottlesFromOutside">- {{ intervention.bottlesFromOutside }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rapport">
        <mat-header-cell *matHeaderCellDef class="font-weight-bold text-dark">Rapport</mat-header-cell>
        <mat-cell *matCellDef="let intervention">{{ intervention.rapport }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openIntervention(row)"></mat-row>
    </mat-table>

    <!-- Pagination -->
    <div class="col-12 mb-3">
      <mat-paginator #paginator class="paginator" showFirstLastButtons [length]="totalData" [pageSizeOptions]="pageSizeOptions">
      </mat-paginator>
    </div>
  </div>
</div>

<div class="row">
  <!-- Table -->
  <div class="col-12">
    <ul class="list-group list-group-striped">

      <!-- Header -->
      <li class="list-group-item d-none d-md-block">
        <div class="row">
          <div class="col-10">
            <div class="row">
              <p class="col-2 mb-0 font-weight-bold">Date</p>
              <p class="col-2 mb-0 font-weight-bold">Objet</p>
              
              <p class="col-2 mb-0 font-weight-bold">B1</p>
              <p class="col-2 mb-0 font-weight-bold">B2</p>
              <p class="col-4 mb-0 font-weight-bold">Commentaires</p>
            </div>
          </div>
          <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
        </div>
      </li>

      <!-- Loading & Empty -->
      <li class="list-group-item px-3 py-2 text-center" *ngIf="!interventionsLegacy"><i class="fa fa-circle-o-notch fa-spin"></i></li>
      <li class="list-group-item px-3 py-2 text-center" *ngIf="(interventionsLegacy && interventionsLegacy.length == 0)">Aucune intervention</li>

      <!-- Row - UPC-V3 -->
      <li class="list-group-item px-3 py-1" *ngFor="let intervention of interventionsLegacy">
        <div class="row">
          <div class="col-10">
            <a class="row no-link" routerLink="/intervention/legacy/{{ intervention.id }}">
              <p class="col-md-2 mt-1 mb-0"><small class="d-md-none mr-2">Date</small>{{ intervention.datetime.toLocaleString() }}</p>
              <p class="col-md-2 mt-1 mb-0"><small class="d-md-none mr-2">Objet</small>{{ intervention.objet }}</p>
              
              <p class="col-6 mt-1 mb-0 d-md-none"><small>B1</small></p>
              <ul class="col-md-2 mt-1 mb-0" style="list-style: none; padding-left: 0;">
                
                <li style="font-weight: bolder;">Bouteilles Ajoutés : {{ intervention.bouteilleB1Added }} </li>
                <li style="font-weight: bolder;">Bouteilles Enlevés : {{intervention.bouteilleB1Deleted}}</li>
              </ul>

              <p class="col-6 mt-1 mb-0 d-md-none"><small>B2</small></p>
              <ul class="col-md-2 mt-1 mb-0" style="list-style: none; padding-left: 0;">
                <li style="font-weight: bolder;">Bouteilles Ajoutés : {{ intervention.bouteilleB2Added }} </li>
                <li style="font-weight: bolder;">Bouteilles Enlevés : {{intervention.bouteilleB2Deleted}}</li>
              </ul>

              <p class="col-md-4 mt-1 mb-0" *ngIf="intervention?.commentaires?.length > 0"><small class="d-md-none mr-2">Commentaire</small>{{intervention.commentaires[0].mess.substring(0,80)+ (intervention.commentaires[0].mess.length >80 ? '...':'')}}</p>
              <p class="col-md-4 mt-1 mb-0" *ngIf="intervention?.commentaires?.length == 0"><small class="d-md-none mr-2">Commentaire</small></p>
              
            </a>
          </div>
          <div class="col-2 mb-0 text-right">
          </div>
        </div>
      </li>
    </ul>
  </div>

</div>