import { Component, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { FirmwareService } from 'src/app/services/upcv3/firmware.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { Modal } from 'src/app/models/ui/modal';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'update-upc-firmware-content',
  templateUrl: './update-upc-firmware.component.html'
})
export class UpdateUPCFirmwareModalComponent extends Modal<string> {
  // Form
  form: FormGroup;
  formSubmitted = false;
  readingFile = false;
  closeSelect=false;

  upcv3: UPCV3[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,

    private upcv3Service: UPCV3Service,

    private toasterService: ToasterService,
    private firmwareService: FirmwareService
  ) {
    super();

    // Form
    this.form = this.fb.group({ upcv3: [] });

    // Load upcv3
    forkJoin([
      this.upcv3Service.getAll(),     // Récupère les UPCs sans groupe
      this.upcv3Service.getAll(true)  // Et les UPCs avec groupe
    ]).pipe(map(res => res.flat())).subscribe({
      next: (res) => this.upcv3 = res,
      error: (err) => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des Upcs");
      },
    })

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  // Form
  get f() { return this.form.controls; }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;
    //Evénement trop long raccourcir le temps de chargement
    // Try create
    this.formSubmitted = true;
    
    this.firmwareService.flashUpc(this.subject, this.form).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }
  onSelectAll() {
    this.closeSelect = true;
    const selected = this.upcv3.map(item=> item.id);
    this.form.get("upcv3").patchValue(selected);
    
  }
  
}