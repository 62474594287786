import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Site } from '../../models/management/site';
import { SiteService } from '../../services/management/site.service';
import { EditSiteModalComponent } from './edit-site/edit-site.component';
import { DeleteSiteModalComponent } from './delete-site/delete-site.component';
import { AuthService } from '../../auth/auth.service';
import { PagerService } from '../../services/pager.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html'
})
export class SitesComponent implements OnInit {
  // NF
  sites: Site[];
  pagedSites: Site[];
  private onDestroy$: Subject<void> = new Subject<void>();

  // Pagination
  pageSizes = [
    { value: 10, label: 'Afficher 10 Sites' },
    { value: 50, label: 'Afficher 50 Sites' },
    { value: 100, label: 'Afficher 100 Sites' }
  ];
  pager: any = {};
  pageSize = this.pageSizes[0].value;
  filter: string = '';
  currentPage = 1;
  loading: boolean = true;

  // Search
  searchForm: FormGroup;
  searchFormSubmitted = false;

  constructor(
    private siteService: SiteService,
    public authService: AuthService,
    private pagerService: PagerService,

    private modalService: NgbModal,
    private fb: FormBuilder,
    private toasterService: ToasterService,
  ) { }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }
  ngOnInit() {
    // Search
    this.searchForm = this.fb.group({
      filter: ['', Validators.minLength(3)]
    });

    // Load sites
    this.loadSites();
  }

  // Sites
  loadSites() {
    this.sites = null;
    this.pagedSites = null;
    this.siteService.getAll().pipe(takeUntil(this.onDestroy$)).subscribe({
      next: (res) => {
        this.sites = res;
        this.setPage(this.currentPage, this.pageSize, this.filter);
      },
      error: err => this.toasterService.pop("error", "Erreur" , "Une erreur s'est produite lors de la récupération des sites")
    })
  }

  setPage(currentPage: number = 1, pageSize: number = 10, filter: string = '') {
    // Perform filter
    var filteredSites: Site[] = [];
    this.filter = filter.toUpperCase()
    this.sites.forEach(site => {
      if (
        site.clientNumber?.includes(this.filter) ||
        site.name?.toUpperCase()?.includes(this.filter) ||
        site.infos?.toUpperCase()?.includes(this.filter) ||
        site.address?.toUpperCase()?.includes(this.filter) ||
        (site.stocks != null && site.stocks.flatMap(s => s.name.toUpperCase()).includes(this.filter))
      ) {
        filteredSites.push(site);
      }
    });

    // get pager object from service
    this.pager = this.pagerService.getPager(this.sites.length, filteredSites.length, currentPage, pageSize);
    this.currentPage = this.pager.currentPage;
    this.pageSize = this.pager.pageSize;

    // get current page of items
    this.pagedSites = filteredSites.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  /* Modals */
  openEditSiteModal(site: Site) {
    var editSiteModalRef = this.modalService.open(EditSiteModalComponent, { size: 'lg' });
    editSiteModalRef.componentInstance.init(site, editSiteModalRef, () => this.loadSites())
  }
  openDeleteSiteModal(site: Site) {
    var deleteSiteModalRef = this.modalService.open(DeleteSiteModalComponent);
    deleteSiteModalRef.componentInstance.init(site, deleteSiteModalRef, () => this.loadSites())
  }

}