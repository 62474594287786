import { Component, ViewChild, OnInit } from '@angular/core';
import { Type as NodeType, Node } from 'src/app/models/gdp/node';
import { GDP, Tool } from 'src/app/models/gdp/gdp';
import { Path } from 'src/app/models/gdp/path';
import { Belt } from 'src/app/models/gdp/belt';
import { UUID } from 'angular2-uuid';
import { KonvaComponent } from './konva/konva.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ToasterService } from 'angular2-toaster';
import { ProjectService } from 'src/app/services/gdp/project.service';
import { VersionService } from 'src/app/services/gdp/version.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Calculations } from 'src/app/models/gdp/calculations';
import * as FileSaver from 'file-saver';
import { ProtectedArea } from 'src/app/models/gdp/protectedArea';
import { PictureSource } from 'src/app/models/gdp/project';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: [ './version.component.css' ]
})
export class VersionComponent implements OnInit {
  // NF
  gdp: GDP;

  // Konva
  @ViewChild('gdpKonva', { static: false }) gdpKonva: KonvaComponent;
  @ViewChild('gdpContextMenu', { static: false }) gdpContextMenu: ContextMenuComponent;

  // UI
  isCollapseTreeview: boolean = false;
  isCollapseSettings: boolean = true;
  isCollapseSelection: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private projectService: ProjectService,
    private versionService: VersionService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.projectService.get(params['project_id']).subscribe(
        projectRes => {
          if (projectRes.code == Code.PROJECT_RECOVERED) {
            this.versionService.get(params['version_id']).subscribe(
              versionRes => {
                if (versionRes.code == Code.VERSION_RECOVERED) {
                  // Init GDP
                  this.gdp = new GDP(projectRes.result, versionRes.result);
                } else this.router.navigateByUrl('/gdp')
              },
              () => this.router.navigateByUrl('/gdp')
            );
          } else this.router.navigateByUrl('/gdp');
        },
        () => this.router.navigateByUrl('/gdp')
      )
    })
  }


  /*
   * Events
   */
  onNodeClicked(id: string) {

    // Selection
    if (this.gdp.tool == Tool.SELECT) {
      this.gdp.version.belts.forEach(belt => {
        belt.nodes.forEach(node => {
          if (node.id == id) {

            // Select on tree
            node.selected = !node.selected;
            belt.collapsed = false;
            belt.nodesCollapsed = false;
  
            // Show shadow
            this.gdpKonva.toogleShadow(id, node.selected);
  
            return;

          }
        })
      })
    }

    // Link
    else if (this.gdp.tool == Tool.LINK) {

      // Set From Node
      if (!this.gdp.linkFromNode) {
        this.gdp.linkFromNode = id;
        this.gdpKonva.updateDrawLayer(Tool.LINK);
      }

      // Set To Node
      else if (!this.gdp.linkToNode && id != this.gdp.linkFromNode) {
        this.gdp.linkToNode = id;
        this.gdpKonva.updateDrawLayer(Tool.LINK);

        /// Check from & to nodes
        // Check if same belt
        if (this.gdp.getBeltFromNode(this.gdp.linkFromNode).id != this.gdp.getBeltFromNode(this.gdp.linkToNode).id) {
          this.toasterService.pop('warning', 'Impossible d\'ajouter le lien', 'Les 2 noeuds ont une ceinture différente');
          this.resetLink();
          return;
        }

        // Check if a relation already exists
        if (this.gdp.getNodesAlreadyConnected(this.gdp.linkFromNode, this.gdp.linkToNode)) {
          this.toasterService.pop('warning', 'Impossible d\'ajouter le lien', 'Les 2 noeuds sont déjà reliés');
          this.resetLink();
          return;
        }

        // TODO: check relation number for each node

        // Create Path
        var path: Path = Path.loadFromJSON({
          id: UUID.UUID(),

          color: this.gdp.version.defaultPathColor,
          width: this.gdp.version.defaultPathWidth,
          dashed: false,
        
          diameter: this.gdp.version.defaultPathDiameter
        });
        this.gdp.createPath(this.gdp.getBeltFromNode(this.gdp.linkFromNode).id, path);
        path.from = this.gdp.getNode(this.gdp.linkFromNode);
        path.to = this.gdp.getNode(this.gdp.linkToNode);

        this.gdpKonva.addPath(path);

        // Reset link
        this.resetLink();

      }

    }

    // Delete
    else if (this.gdp.tool == Tool.DELETE) {

      // Check if control
      var node = this.gdp.getNode(id);
      if (node.type == NodeType.CONTROL) this.toasterService.pop('error', 'Impossible de supprimer la régie');
      else this.onNodeDeleted(id);
      
    }

  }
  onPathClicked(id: string) {

    // Selection
    if (this.gdp.tool == Tool.SELECT) {
      this.gdp.version.belts.forEach(belt => {
        belt.paths.forEach(path => {
          if (path.id == id) {

            // Select on tree
            path.selected = !path.selected;
            belt.collapsed = false;
            belt.pathsCollapsed = false;
  
            // Show shadow
            this.gdpKonva.toogleShadow(id, path.selected);
  
            return;

          }
        })
      })
    }

    // Delete
    else if (this.gdp.tool == Tool.DELETE) this.onPathDeleted(id);

  }
  onContextmenuClicked(e: any) {
    this.gdpContextMenu.show(e.item, e.x, e.y);
  }
  onContextmenuHideRequested() {
    this.gdpContextMenu.hide();
  }

  onTreeSelectedChange() {
    this.gdpKonva.disableShadow();
    this.gdp.version.belts.forEach(belt => {
      belt.nodes.forEach(node => { if (node.selected) this.gdpKonva.toogleShadow(node.id, true); });
      belt.paths.forEach(path => { if (path.selected) this.gdpKonva.toogleShadow(path.id, true); });
      belt.protectedAreas.forEach(protectedArea => { this.gdpKonva.updateProtectedArea(protectedArea); });
    });
  }

  onNodeDeleted(e: string) {
    // NF
    var deletedPaths: Path[] = this.gdp.deleteNode(e);

    /// UI
    this.gdpKonva.delNode(e);
    deletedPaths.forEach(path => this.gdpKonva.delPath(path.id));

    // Hide context menu
    this.gdpContextMenu.hide();
  }
  onPathDeleted(e: string) {
    // NF
    this.gdp.deletePath(e);

    // UI
    this.gdpKonva.delPath(e);

    // Hide context menu
    this.gdpContextMenu.hide();
  }
  onProtectedAreaPointDeleted(e: any) {
    // NF
    var protectedArea = this.gdp.deleteProtectedAreaPoint(e.protectedAreaId, e.index);

    // UI
    this.gdpKonva.updateProtectedArea(protectedArea);

    // Hide context menu
    this.gdpContextMenu.hide();
  }

  onAddNodeRequested(e: any) {
    /// NF
    // Get position
    var pos = this.gdpKonva.rotate(
      0,
      0,
      Math.round((e.x - this.gdpKonva.stage.x()) / this.gdpKonva.stage.scaleX()),
      Math.round((e.y - this.gdpKonva.stage.y()) / this.gdpKonva.stage.scaleX()),
      this.gdpKonva.stage.rotation()
    );
    pos.x += this.gdpKonva.stage.offsetX();
    pos.y += this.gdpKonva.stage.offsetY();

    // Get selected belt
    var belt = this.gdp.getBelt(this.gdp.version.selectedBelt);

    // Check node type
    var node: Node;
    switch (e.nodeType) {
      
      // Control
      case NodeType.CONTROL:
        // Create Belt
        var belt: Belt = Belt.loadFromJSON({ id: UUID.UUID(), name: 'Test' });
        this.gdp.createBelt(belt);

        // Create Node
        node = Node.loadFromJSON({ id: UUID.UUID(), type: NodeType.CONTROL, x: pos.x, y: pos.y, iconSize: this.gdp.version.defaultNodeIconSize });
        this.gdp.createNode(belt.id, node);
      break;

      // Other
      default:
        if (belt) {
          // Create Node
          node = Node.loadFromJSON({
            id: UUID.UUID(),
            type: e.nodeType,
            x: pos.x,
            y: pos.y,
            iconSize: this.gdp.version.defaultNodeIconSize,
            title: '-',
            titleX: pos.x + this.gdp.version.defaultNodeIconSize / 2,
            titleY: pos.y - this.gdp.version.defaultNodeIconSize / 2,
            titleColor: this.gdp.version.defaultNodeTitleColor,
            titleBackgroundColor: this.gdp.version.defaultNodeTitleBackgroundColor,
            titleSize: this.gdp.version.defaultNodeTitleSize
          });
          this.gdp.createNode(belt.id, node);
        }
      break;

    }

    /// UI
    if (node) {
      this.gdpKonva.addNode(node);
      this.gdpContextMenu.hide();
    }
  }
  onAddProtectedAreaPointRequested(e: any) {
    /// NF
    var protectedArea = this.gdp.createProtectedAreaPoint(e.protectedAreaId, e.index, e.before);

    // UI
    this.gdpKonva.updateProtectedArea(protectedArea);

    // Hide context menu
    this.gdpContextMenu.hide();
  }
  onAddProtectedAreaRequested(e: any) {
    /// NF
    // Get position
    var pos = this.gdpKonva.rotate(
      0,
      0,
      Math.round((e.x - this.gdpKonva.stage.x()) / this.gdpKonva.stage.scaleX()),
      Math.round((e.y - this.gdpKonva.stage.y()) / this.gdpKonva.stage.scaleX()),
      this.gdpKonva.stage.rotation()
    );
    pos.x += this.gdpKonva.stage.offsetX();
    pos.y += this.gdpKonva.stage.offsetY();

    // Get selected belt
    var belt = this.gdp.getBelt(this.gdp.version.selectedBelt);

    // Create protected area
    var protectedArea = ProtectedArea.loadFromJSON({
      id : UUID.UUID(),
      name: 'Zone protégée',
      points: pos.x + ',' + pos.y
    });
    this.gdp.createProtectedArea(belt.id, protectedArea);

    /// UI
    this.gdpKonva.addProtectedArea(protectedArea);
    this.gdpContextMenu.hide();
  }

  onNodeChange(node: Node) {
    this.gdpKonva.updateNode(node);
  }
  onPathChange(path: Path) {
    this.gdpKonva.updatePath(path);
  }
  onProtectedAreaChange(protectedArea: ProtectedArea) {
    this.gdpKonva.updateProtectedArea(protectedArea);
  }
  onBeltDelete(belt: Belt) {
    // UI
    belt.nodes.forEach(node => this.gdpKonva.delNode(node.id));
    belt.paths.forEach(path => this.gdpKonva.delPath(path.id));
    belt.protectedAreas.forEach(protectedArea => this.gdpKonva.delProtectedArea(protectedArea.id));

    // NF
    this.gdp.deleteBelt(belt);
  }
  onProtectedAreaDelete(protectedArea: ProtectedArea) {
    // UI
    this.gdpKonva.delProtectedArea(protectedArea.id);
    
    // NF
    this.gdp.deleteProtectedArea(protectedArea);
  }

  onOpacityChange() {
    this.gdpKonva.updateOpacity();
  }


  /*
   * Other functions
   */
  updateRotation(): void {
    this.gdpKonva.updateRotation();
  }
  setTool(tool: Tool): void {
    var prevTool = this.gdp.tool;

    if (this.gdp.tool == tool) this.gdp.tool = null;
    else this.gdp.tool = tool;

    // Enable / Disable node dragging
    if (this.gdp.tool == Tool.MOVE) this.gdpKonva.toogleDragging(true);
    if (prevTool == Tool.MOVE) this.gdpKonva.toogleDragging(false);

    // Enable / Disable node link
    if (prevTool == Tool.LINK) this.resetLink();

    // Enable / Disable draw
    if (this.gdp.tool == Tool.DRAW) {

      // Check if no node selected
      if (this.gdp.getSelectedNodeNumber() == 0) {
        this.toasterService.pop('warning', 'Impossible d\'activer le dessin', 'Aucun noeud sélectionné');
        this.setTool(null);
        return;
      }

      // Check if multiple node selected
      if (this.gdp.getSelectedNodeNumber() > 1) {
        this.toasterService.pop('warning', 'Impossible d\'activer le dessin', '1 seul noeud peut être sélectionné');
        this.setTool(null);
        return;
      }

      // Init draw points
      var selectedNode = this.gdp.getFirstSelectedNode();
      this.gdp.drawPoints = [{ x: selectedNode.x, y: selectedNode.y }];

    }
    if (prevTool == Tool.DRAW) this.resetDrawPoints();

    // Enable / Disable capture
    if (this.gdp.tool == Tool.CAPTURE) this.gdpKonva.toogleCapture(true);
    if (prevTool == Tool.CAPTURE) this.gdpKonva.toogleCapture(false);
    
  }
  setZoom(zoom: number): void {
    this.gdp.version.zoom = zoom;
    
    var oldScale = this.gdpKonva.stage.scaleX();
    var mousePointTo = {
      x: (this.gdpKonva.stage.width() / 2) / oldScale - this.gdpKonva.stage.x() / oldScale,
      y: (this.gdpKonva.stage.height() / 2) / oldScale - this.gdpKonva.stage.y() / oldScale
    };
    var newScale = zoom;
    this.gdpKonva.stage.scale({ x: newScale, y: newScale });
    this.gdpKonva.stage.position({ x: -(mousePointTo.x - (this.gdpKonva.stage.width() / 2) / newScale) * newScale, y: -(mousePointTo.y - (this.gdpKonva.stage.height() / 2) / newScale) * newScale });
    this.gdpKonva.stage.batchDraw();
    this.gdp.version.zoom = newScale;
  }

  resetLink() {
    this.gdp.linkFromNode = null;
    this.gdp.linkToNode = null;
    this.gdpKonva.updateDrawLayer(Tool.LINK);
  }
  resetDrawPoints() {
    this.gdp.drawPoints = [];
    this.gdpKonva.updateDrawLayer(Tool.DRAW);
  }

  save(): void {
    
    this.versionService.sync(this.gdp.version).subscribe(
      () => {},
      () => {}
    );
  }
  svg(): void {
    var canvas;
    function getBase64Image(img) {
      // Create an empty canvas element
      canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
  
      // Copy the image contents to the canvas
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
  
      return canvas.toDataURL('image/png');
    }
    function getTextWidth(text, font) {
      canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      context.font = font;
      var metrics = context.measureText(text);
      return metrics.width;
    }

    // Create svg container
    var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns:xlink','http://www.w3.org/1999/xlink');
    svg.setAttribute('width', this.gdp.backgroundImage.width + '');
    svg.setAttribute('height', this.gdp.backgroundImage.height + '');

    /*
     * Create Defs
     */
    var svgDefs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

    // Markers
    var svgControlMarker = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    var svgHexagonalTrapMarker = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    var svgRectangularTrapMarker = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    svgControlMarker.setAttribute('id', 'control-marker');
    svgControlMarker.setAttributeNS('http://www.w3.org/1999/xlink', 'href', getBase64Image(this.gdp.controlMarker));
    svgControlMarker.setAttribute('width', this.gdp.controlMarker.width + '');
    svgControlMarker.setAttribute('height', this.gdp.controlMarker.height + '');
    svgHexagonalTrapMarker.setAttribute('id', 'hexagonal-trap-marker');
    svgHexagonalTrapMarker.setAttributeNS('http://www.w3.org/1999/xlink', 'href', getBase64Image(this.gdp.hexagonalTrapMarker));
    svgHexagonalTrapMarker.setAttribute('width', this.gdp.hexagonalTrapMarker.width + '');
    svgHexagonalTrapMarker.setAttribute('height', this.gdp.hexagonalTrapMarker.height + '');
    svgRectangularTrapMarker.setAttribute('id', 'rectangular-trap-marker');
    svgRectangularTrapMarker.setAttributeNS('http://www.w3.org/1999/xlink', 'href', getBase64Image(this.gdp.rectangularTrapMarker));
    svgRectangularTrapMarker.setAttribute('width', this.gdp.rectangularTrapMarker.width + '');
    svgRectangularTrapMarker.setAttribute('height', this.gdp.rectangularTrapMarker.height + '');
    svgDefs.appendChild(svgControlMarker);
    svgDefs.appendChild(svgHexagonalTrapMarker);
    svgDefs.appendChild(svgRectangularTrapMarker);

    // Protected Area Patterns
    this.gdp.version.belts.forEach(belt => {
      belt.protectedAreas.forEach(protectedArea => {
        var svgpattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
        svgpattern.setAttribute('id', protectedArea.id);
        svgpattern.setAttribute('width', protectedArea.width * 0.00158 + '');
        svgpattern.setAttribute('height', protectedArea.width * 0.001387 + '');

        var svgpatternimage = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        svgpatternimage.setAttributeNS('http://www.w3.org/1999/xlink', 'href', this.gdpKonva.getPattern(protectedArea.width * 10, protectedArea.color).toDataURL());
        svgpatternimage.setAttribute('width', protectedArea.width + '');
        svgpatternimage.setAttribute('height', protectedArea.width / 2 + '');
        svgpattern.appendChild(svgpatternimage);

        svgDefs.appendChild(svgpattern);
      })
    });
    svg.appendChild(svgDefs);

    // Background Image
    var oldRotation = this.gdpKonva.backgroundImage.rotation();
    this.gdpKonva.backgroundImage.rotation(360 - this.gdp.version.rotation);
    this.gdpKonva.backgroundImage.toDataURL({
      callback: (img: any) => {
        var svgimg = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        svgimg.setAttribute('width', this.gdp.backgroundImage.width + '');
        svgimg.setAttribute('height', this.gdp.backgroundImage.height + '');
        svgimg.setAttributeNS('http://www.w3.org/1999/xlink', 'href', img);
        svgimg.setAttribute('x', '0');
        svgimg.setAttribute('y', '0');
        svg.appendChild(svgimg);

        // Belts
        this.gdp.version.belts.forEach(belt => {

          // Protected Areas
          belt.protectedAreas.forEach(protectedArea => {
            var svgpolygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
            svgpolygon.setAttribute('points', protectedArea.inlinePoints);
            svgpolygon.setAttribute('style', 'fill: url(#' + protectedArea.id + ');');
            svg.appendChild(svgpolygon);
          });

          // Paths
          belt.paths.forEach(path => {
            var svgline = document.createElementNS('http://www.w3.org/2000/svg', 'line');
            svgline.setAttribute('x1', path.from.x + '');
            svgline.setAttribute('y1', path.from.y + '');
            svgline.setAttribute('x2', path.to.x + '');
            svgline.setAttribute('y2', path.to.y + '');
            svgline.setAttribute('style', 'stroke: ' + path.color + '; stroke-width: ' + path.width + ';');
            if (path.dashed) svgline.setAttribute('stroke-dasharray', path.width + ', ' + path.width * 2);
            svg.appendChild(svgline);
          });

          // Nodes
          belt.nodes.forEach(node => {

            // Title
            if (node.type == NodeType.HEXAGONAL_TRAP || node.type == NodeType.RECTANGULAR_TRAP) {
              var width = getTextWidth(node.title, 'Helvetica Neue ' + node.titleSize + 'px'),
                  padding = node.titleSize / 3;

                  console.log (width, padding);

              var svgrect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
              svgrect.setAttribute('x', - (width + padding * 2) / 2 + '');
              svgrect.setAttribute('y', - (node.titleSize + padding * 2) / 2 + '');
              svgrect.setAttribute('width', width + padding * 2);
              svgrect.setAttribute('height', node.titleSize + padding * 2 + '');
              svgrect.setAttribute('fill', node.titleBackgroundColor);
              svgrect.setAttribute('transform', 'translate(' + node.titleX + ', ' + node.titleY + ') rotate(' + (360 - this.gdp.version.rotation) + ')');
              svg.appendChild(svgrect);

              var svgtext = document.createElementNS('http://www.w3.org/2000/svg', 'text');
              svgtext.setAttribute('x', - width / 2 + '');
              svgtext.setAttribute('y', + node.titleSize / 3 + '');
              svgtext.setAttribute('font-size', node.titleSize + '');
              svgtext.setAttribute('font-family', 'Helvetica Neue');
              svgtext.setAttribute('fill', node.titleColor);
              svgtext.setAttribute('transform', 'translate(' + node.titleX + ', ' + node.titleY + ') rotate(' + (360 - this.gdp.version.rotation) + ')');
              svgtext.textContent = node.title;
              svg.appendChild(svgtext);
            }

            // Marker
            // TODO: handle INTERMEDIATE
            if (node.type == NodeType.CONTROL || node.type == NodeType.HEXAGONAL_TRAP || node.type == NodeType.RECTANGULAR_TRAP) {
              var svgmarker = document.createElementNS('http://www.w3.org/2000/svg', 'use');

              switch (node.type) {

                case NodeType.CONTROL:
                  svgmarker.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#control-marker');
                  svgmarker.setAttribute('transform',
                  'translate(' + (node.x - node.iconSize / 2) + ', ' + (node.y - node.iconSize / 2) + ')' +
                    ' rotate(' + (360 - this.gdp.version.rotation) + ', ' + node.iconSize / 2 + ', ' + node.iconSize / 2 + ')' +
                    ' scale(' + node.iconSize / this.gdp.controlMarker.width + ', ' + node.iconSize / this.gdp.controlMarker.height + ')'
                  );
                  break;

                case NodeType.HEXAGONAL_TRAP:
                  svgmarker.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#hexagonal-trap-marker');
                  svgmarker.setAttribute('transform',
                    'translate(' + (node.x - node.iconSize / 2) + ', ' + (node.y - node.iconSize / 2) + ')' +
                    ' rotate(' + (360 - this.gdp.version.rotation) + ', ' + node.iconSize / 2 + ', ' + node.iconSize / 2 + ')' +
                    ' scale(' + node.iconSize / this.gdp.hexagonalTrapMarker.width + ', ' + node.iconSize / this.gdp.hexagonalTrapMarker.height + ')'
                  );
                  break;

                case NodeType.RECTANGULAR_TRAP:
                  svgmarker.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '#rectangular-trap-marker');
                  svgmarker.setAttribute('transform',
                  'translate(' + (node.x - node.iconSize / 2) + ', ' + (node.y - node.iconSize / 2) + ')' +
                    ' rotate(' + (360 - this.gdp.version.rotation) + ', ' + node.iconSize / 2 + ', ' + node.iconSize / 2 + ')' +
                    ' scale(' + node.iconSize / this.gdp.rectangularTrapMarker.width + ', ' + node.iconSize / this.gdp.rectangularTrapMarker.height + ')'
                  );
                  break;
              
              }

              svg.appendChild(svgmarker);
            }

          });

        });

      }
    });
    this.gdpKonva.backgroundImage.rotation(oldRotation);

    var newWindow = window.open();
    newWindow.document.write(svg.outerHTML);
    //FileSaver.saveAs(new Blob([ svg.outerHTML ], { type: 'image/svg+xml' }), 'test.svg');

  }
  calculations(): void {
    var calculations: Calculations = new Calculations(this.gdp, this.toasterService);
    calculations.flowCalculations();
  }
  acceptDraw(): void {
    // Get belt
    var belt = this.gdp.getBeltFromNode(this.gdp.getFirstSelectedNode().id);

    // 1. Split lines to get all points possibilities
    var points = [];
    if (this.gdp.drawPoints.length > 0) {
      this.gdp.drawPoints.forEach((point, index) => {
        if (index + 1 < this.gdp.drawPoints.length) {
          this.splitLine(
            point,
            this.gdp.drawPoints[index + 1],
            this.distance(point.x, point.y, this.gdp.drawPoints[index + 1].x, this.gdp.drawPoints[index + 1].y)
          ).forEach(point => { points.push(point) });

        }
      });
    }
    
    // 2. Convert path to nodes
    var requestedDistance = 5.5 / this.gdp.project.pixelsPerMeter;
    var distance = 0, prevNode: Node = this.gdp.getFirstSelectedNode();
    for (var i = 0; i < points.length; i++) {
      var prevNodeDistance = 0;

      // Get distance beetwen previous trap
      if (prevNode) prevNodeDistance = this.distance(prevNode.x, prevNode.y, points[i].x, points[i].y);

      // Increment distance beetween this & previous
      if (i > 0) distance += this.distance(points[i].x, points[i].y, points[i - 1].x, points[i - 1].y);

      // Add node
      if ((!prevNode && distance >= requestedDistance) || prevNodeDistance >= requestedDistance) {
        var node = Node.loadFromJSON({
          id: UUID.UUID(),
          type: NodeType.HEXAGONAL_TRAP,
          x: points[i].x,
          y: points[i].y,
          iconSize: this.gdp.version.defaultNodeIconSize,
          title: '-',
          titleX: points[i].x + this.gdp.version.defaultNodeIconSize / 2,
          titleY: points[i].y - this.gdp.version.defaultNodeIconSize / 2,
          titleColor: this.gdp.version.defaultNodeTitleColor,
          titleBackgroundColor: this.gdp.version.defaultNodeTitleBackgroundColor,
          titleSize: this.gdp.version.defaultNodeTitleSize
        });
        this.gdp.createNode(belt.id, node);
        this.gdpKonva.addNode(node);


        // Add path with previous Node
        var path: Path = Path.loadFromJSON({
          id: UUID.UUID(),
          color: this.gdp.version.defaultPathColor,
          width: this.gdp.version.defaultPathWidth,
          dashed: false,
          diameter: this.gdp.version.defaultPathDiameter
        });
        this.gdp.createPath(belt.id, path);
        path.from = this.gdp.getNode(prevNode.id);
        path.to = this.gdp.getNode(node.id);
        this.gdpKonva.addPath(path);

        prevNode = node;
        distance = 0;
      }
    }

    // 3. Update UI
    this.setTool(null);

  }
  acceptCapture(): void {

    // W & H OK
    var width = Math.round((this.gdpKonva.captureRect.width() * this.gdpKonva.captureRect.scaleX()) * this.gdpKonva.stage.scaleX()),
        height = Math.round((this.gdpKonva.captureRect.height() * this.gdpKonva.captureRect.scaleY()) * this.gdpKonva.stage.scaleX()),
        x = (this.gdpKonva.captureRect.x() + this.gdpKonva.stage.x()) * this.gdpKonva.stage.scaleX(),
        y = (this.gdpKonva.captureRect.y() + this.gdpKonva.stage.y()) * this.gdpKonva.stage.scaleX() - height;

    console.log ('x : ' + x, 'y : ' + y);
    console.log ('width : ' + width, 'height : ' + height);

    // Get image from stage
    this.gdpKonva.stage.toDataURL({
      mimeType: 'image/jpeg',
      /*x: xy.x,
      y: xy.y,
      width: width,
      height: height,*/
      quality: 1,
      pixelRatio: 1,
      callback: (img: any) => {
        function dataURItoBlob(dataURI) {
          var byteString = atob(dataURI.split(',')[1]),
              mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0],
              ab = new ArrayBuffer(byteString.length),
              ia = new Uint8Array(ab);
        
          // set the bytes of the buffer to the correct values
          for (var i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        
          // write the ArrayBuffer to a blob, and you're done
          var blob = new Blob([ab], {type: mimeString});
          return blob;
        }
        FileSaver.saveAs(dataURItoBlob(img), 'test.jpg');
      }
    });

  }

  splitLine(start, end, segments) {
    var deltaX = (end.x - start.x) / segments, deltaY = (end.y - start.y) / segments, points = [];
    points.push(start);
    for(var i = 1; i < segments; i++) points.push({ x: start.x + i * deltaX, y: start.y + i * deltaY });
    return points;
  }
  distance(x1, y1, x2, y2) {
    function sqr(a) { return a * a }  
    return Math.sqrt( sqr(x2 - x1) + sqr(y2 - y1) );
  }
  getCoordinates(x, y) {

    // Case custom picture
    if (this.gdp.project.pictureSource == PictureSource.CUSTOM) {
      // Convert px to kilometers
      /*x = (x / 1000) / this.project.customPictureRatio;
      y = (y / 1000) / this.project.customPictureRatio;

      var lat = this.project.lat + y * 360 / 40008,
          lng = this.project.lng + x * 360 / (Math.cos(lat * Math.PI / 180) * 40075);

      return { lat: lat, lng: lng }*/

      return { lat: 0, lng: 0 };
    }
    
    // Case google maps
    else {
      return {
        lat: this.gdp.project.lat - (360 / Math.pow(2, this.gdp.project.zoom + 9.1) * Math.cos(this.gdp.project.lat * Math.PI / 180)) * y,
        lng: this.gdp.project.lng + (360 / Math.pow(2, this.gdp.project.zoom + 9)) * x
      }
    }

  }
  getDistance(latLng1, latLng2) {
    function rad(x) { return x * Math.PI / 180 }
      
    var R = 6378137, // Earth’s mean radius in meter
        dLat = rad(latLng2.lat - latLng1.lat),
        dLong = rad(latLng2.lng - latLng1.lng);
      
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(latLng1.lat)) * Math.cos(rad(latLng2.lat)) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
      
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
    var d = R * c;
      
    return d; // returns the distance in meter
  }

}