<h2 mat-dialog-title>Choisir le stock où vont aller les bouteilles</h2>

<mat-dialog-content>
  <ul *ngFor="let location of data.selectableLocations">
    <li class="mt-2">
      {{ location.name }} <button type="button" class="btn btn-primary btn-sm ml-2 float-right" (click)="add(location)"><i class="fa fa-check"></i></button>
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="undefined">Annuler</button>
</mat-dialog-actions>