<div class="modal-header">
  <h4 class="modal-title">Modifier le Site</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Client number -->
    <div class="form-group">
      <label for="clientNumber">Numéro de client</label>
      <input type="text" class="form-control" id="clientNumber" placeholder="Numéro de client" formControlName="clientNumber">
    </div>

    <!-- Name -->
    <div class="form-group">
      <label for="name">Nom *</label>
      <input type="text" class="form-control" id="name" placeholder="Nom *" formControlName="name" [ngClass]="{ 'is-invalid': nameAlreadyUsed || f.name.errors }" (keyup)="nameAlreadyUsed = false">
      <div *ngIf="nameAlreadyUsed || f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors && f.name.errors.required">Nom obligatoire</div>
        <div *ngIf="nameAlreadyUsed">Nom déjà utilisé</div>
      </div>
    </div>

    <!-- Infos -->
    <div class="form-group">
      <label for="infos">Infos</label>
      <textarea class="form-control" id="infos" placeholder="Infos" formControlName="infos"></textarea>
    </div>

    <hr class="ml-0">

    <!-- Address -->
    <div class="form-group">
      <label for="address">Adresse</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="address" placeholder="Adresse" formControlName="address" [ngClass]="{ 'is-invalid': addressUnknow }" (keyup)="addressUnknow = false">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="geolocate()">Géolocaliser</button>
        </div>
        <div *ngIf="addressUnknow" class="invalid-feedback">
          <div *ngIf="addressUnknow">Adresse non trouvée</div>
        </div>
      </div>
    </div>

    <!-- Lat -->
    <div class="form-group">
      <label for="lat">Latitude</label>
      <input type="number" class="form-control" id="lat" placeholder="Latitude" step="0.01" formControlName="lat">
    </div>

    <!-- Lng -->
    <div class="form-group">
      <label for="lng">Longitude</label>
      <input type="number" class="form-control" id="lng" placeholder="Longitude" step="0.01" formControlName="lng">
    </div>

    <hr class="ml-0">

    <!-- Stock -->
    <div class="form-group">
      <label>Stocks</label>
      <ng-select  [items]="stocks"
                  bindLabel="name"
                  bindValue="id"
                  formControlName="stocks"
                  multiple="true">
      </ng-select>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Enregistrer</span>
    </button>
  </div>
</form>