import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent, CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();

    if(this.locale == null) {
      this.locale = 'fr'
    }
  }

  // you can override any of the methods defined in the parent class

  month(event: CalendarEvent): string {
    return `
      <div style="color: black">
        De ${formatDate(event.start, 'h:m a', this.locale)} à ${formatDate(event.end, 'h:m a', this.locale)}
        <br>
      ${event.title}     
      </div>`
  }

  week(event: CalendarEvent): string {

    if(event.meta.displayInnerBarDesc === false) {
      return "";
    }

    if(event.meta.upc && event.meta.intensity) {
      return `
      <div class="text-dark">
        <b>${event.meta.intensity} / ${event.meta.upc} <b/>
      </div>`
    }
    return this.weekTooltip(event)
  }

  weekTooltip(event: CalendarEvent): string {
return `
      <div>
        De ${formatDate(event.start, 'h:m:s a', this.locale)} à ${formatDate(event.end, 'h:m:s a', this.locale)}
        <br>
        ${event.title}     
      </div>`
    }

  day(event: CalendarEvent): string {
    return `
      <div style="color: black">
        De ${formatDate(event.start, 'h:m a', this.locale)} à ${formatDate(event.end, 'h:m a', this.locale)}
        <br>
      ${event.title}
      </div>     
      `
  }
}

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale);
  }

  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "MMM y", locale);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale);
  }
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "HH:mm", locale);
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.dayViewHour({ date, locale });
  }
}

