<div class="container-fluid bg-light">
  <nav *ngIf="isLoggedIn$ | async" class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="/dashboard">BioBelt</a>
    <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" routerLink="/dashboard" routerLinkActive="active" *ngIf="authService.isAdmin || authService.isOperator">
          <a class="nav-link">Tableau de bord</a>
        </li>
        <li class="nav-item" routerLink="/sites" routerLinkActive="active" *ngIf="authService.isAdmin || authService.isOperator">
          <a class="nav-link">Sites</a>
        </li>
        <li class="nav-item" routerLink="/operators" routerLinkActive="active" *ngIf="authService.isAdmin">
          <a class="nav-link">Opérateurs</a>
        </li>
        <li class="nav-item" routerLink="/designers" routerLinkActive="active" *ngIf="authService.isAdmin">
          <a class="nav-link">Dessinateurs</a>
        </li>
        <li class="nav-item" routerLink="/stocks" routerLinkActive="active" *ngIf="authService.isAdmin || authService.isOperator">
          <a class="nav-link">Bouteilles de CO2</a>
        </li>
        <li class="nav-item" routerLink="/projects" routerLinkActive="active" *ngIf="authService.isAdmin || authService.isDesigner">
          <a class="nav-link">Projets</a>
        </li>
        <li class="nav-item" routerLink="/upcmcus" routerLinkActive="active" *ngIf="authService.isAdmin">
          <a class="nav-link">Test UPC</a>
        </li>
      </ul>

      <span class="navbar-text pt-0 pb-0 pull-right">
        <button type="button" class="btn btn-link text-secondary" placement="bottom" ngbTooltip="Tâches" routerLink="/tasks" *ngIf="authService.isAdmin"><i class="fa fa-tasks"></i></button>
        <button type="button" class="btn btn-link text-secondary" placement="bottom" ngbTooltip="Se déconnecter" (click)="onLogout()"><i class="fa fa-sign-out"></i></button>
        <button type="button" class="btn btn-link text-secondary" placement="bottom" ngbTooltip="Réglages" routerLink="/settings"><i class="fa fa-cog"></i></button>
      </span>
    </div>
  </nav>
</div>