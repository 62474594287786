<div class="modal-header modal-danger">
  <h4 class="modal-title">Supprimer l'Opérateur</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>
    
<div class="modal-body modal-danger">
  <p>Êtes-vous certain de vouloir supprimer cet opérateur ?</p>
</div>

<div class="modal-footer modal-danger">
  <input type="button" class="btn btn-secondary" value="Annuler" [disabled]="submitted" (click)="close()" ngbButton>
  <button type="button" class="btn btn-success" [disabled]="submitted" (click)="onFormSubmit()" ngbButton>
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="submitted"></i>
    <span *ngIf="!submitted">Supprimer</span>
  </button>
</div>
