<div class="modal-header">
  <h4 class="modal-title">Historique de la bouteille</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<div class="modal-body text-center" *ngIf="isLoading"><i class="fa fa-circle-o-notch fa-spin"></i></div>

<div class="modal-body">
  <div class="list-group">

    <div class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="let event of displayableData">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">{{ event.eventString }}</h5>
        <small>{{ event.date.toLocaleString() }}</small>
      </div>
      <p class="mb-0">{{event.locationString}}</p>
      <span class="badge badge-secondary" *ngIf="event.previousState == null">Inconnu</span>
      <span class="badge badge-success" *ngIf="event.previousState == 'FULL'">Pleine</span>
      <span class="badge badge-info" *ngIf="event.previousState == 'IN_USE'">En Ligne</span>
      <span class="badge badge-warning" *ngIf="event.previousState == 'EMPTY'">Vide</span>
      <mat-icon class="align-middle">arrow_right_alt</mat-icon>
      <span class="badge badge-secondary" *ngIf="event.newState == null">Inconnu</span>
      <span class="badge badge-success" *ngIf="event.newState == 'FULL'">Pleine</span>
      <span class="badge badge-info" *ngIf="event.newState == 'IN_USE'">En Ligne</span>
      <span class="badge badge-warning" *ngIf="event.newState == 'EMPTY'">Vide</span>
    </div>

  </div>
</div>

<div class="modal-footer">
  <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" ngbButton>
</div>