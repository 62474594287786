import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { ProjectService } from 'src/app/services/gdp/project.service';
import { Project } from 'src/app/models/gdp/project';

@Component({
  templateUrl: './delete-project.component.html'
})
export class DeleteProjectModalComponent extends Modal<Project> {
  // Form
  submitted = false;

  constructor(
    private router: Router,
    private projectService: ProjectService
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.projectService.delete(this.subject.id).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }

}