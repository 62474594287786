import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Bottle } from 'src/app/models/management/bottle';

@Component({
  selector: 'add-bottle-intervention-content',
  templateUrl: './add-bottle.component.html'
})
export class AddBottleModalComponent {
  displayedColumns = ["select", "barcode", "status", "state", "type"];
  displayedColumnsInStock = ["select", "barcode", "status", "location", "state", "type"];
  selectedBottlesOnSite: Bottle[] = [];
  selectedBottlesInStock: Bottle[] = [];
  selectedBottlesInTransit: Bottle[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddBottleModalComponent, Bottle[]>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  add() {
    // Rend les éléments du tableau uniques
    const bottlesToReturn = [...new Set([...this.selectedBottlesOnSite, ...this.selectedBottlesInStock, ...this.selectedBottlesInTransit])];
    this.dialogRef.close(bottlesToReturn);
  }
}

export interface DialogData {
  addableBottlesOnSite: Bottle[];
  addableBottlesInStock: Bottle[];
  addableBottlesInTransit: Bottle[];
}