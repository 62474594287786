import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InterventionV3, Origin} from '../../../../../models/upcv3/interventionV3';
import { InterventionV3Service } from '../../../../../services/upcv3/interventionV3.service';
import { Bottle, BottleHistory, status } from 'src/app/models/management/bottle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BottleEventsModalComponent } from 'src/app/pages/stocks/stock/bottle-events/bottle-events.component';
import { finalize } from 'rxjs/operators';
import _ from "lodash";

interface BottleEventsOfTargets {
  b1: BottleHistory[],
  b2: BottleHistory[],
  site: BottleHistory[],
}

@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.css']
})
export class InterventionComponent implements OnInit {
  origin: typeof Origin = Origin;
  interventionId: number;
  intervention: InterventionV3;
  intervenants: String;
  downloadingInterventionSheet: boolean = false;
  bottleHistoryAfterOfTargets: BottleEventsOfTargets = {
    b1: [],
    b2: [],
    site: [],
  };
  bottleHistoryBeforeOfTargets: BottleEventsOfTargets = {
    b1: [],
    b2: [],
    site: [],
  };
  bottleHistoryOfTargets: BottleEventsOfTargets = {
    b1: [],
    b2: [],
    site: [],
  };

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private interventionV3Service: InterventionV3Service,
  ) { }

  ngOnInit() {
    this.interventionId = this.route.snapshot.params.id;
    this.interventionV3Service.get(this.interventionId).subscribe(intervention => {
      this.intervention = intervention;
      this.intervenants = intervention.intervenants.map(i => `${i.firstName} ${i.lastName}`).join(', ');
      this.bottleHistoryAfterOfTargets.b1 = intervention.bottlesAfterIntervention.filter(be => be.status == status.B1);
      this.bottleHistoryAfterOfTargets.b2 = intervention.bottlesAfterIntervention.filter(be => be.status == status.B2);
      this.bottleHistoryAfterOfTargets.site = intervention.bottlesAfterIntervention.filter(be => be.status == status.ENTREPOSE);
      this.bottleHistoryBeforeOfTargets.b1 = intervention.bottlesBeforeIntervention.filter(be => be.status == status.B1);
      this.bottleHistoryBeforeOfTargets.b2 = intervention.bottlesBeforeIntervention.filter(be => be.status == status.B2);
      this.bottleHistoryBeforeOfTargets.site = intervention.bottlesBeforeIntervention.filter(be => be.status == status.ENTREPOSE);
      this.bottleHistoryOfTargets.b1 = _.unionBy(
        this.bottleHistoryBeforeOfTargets.b1,
        this.bottleHistoryAfterOfTargets.b1,
        be => be.bottle.id);
      this.bottleHistoryOfTargets.b2 = _.unionBy(
        this.bottleHistoryBeforeOfTargets.b2,
        this.bottleHistoryAfterOfTargets.b2,
        be => be.bottle.id);
      this.bottleHistoryOfTargets.site = _.unionBy(
        this.bottleHistoryBeforeOfTargets.site,
        this.bottleHistoryAfterOfTargets.site,
        be => be.bottle.id);
    });
  }

  downloadInterventionSheet() {
    // UPC-V3
    if (this.intervention != null) {
      this.downloadingInterventionSheet = true;
      this.interventionV3Service.interventionSheet(this.intervention.id).pipe(
        finalize(() => this.downloadingInterventionSheet = false)
      ).subscribe({
        next: data => {
          var blob = new Blob([data], { type: 'application/pdf' });
          window.open(URL.createObjectURL(blob));
        }, 
      })
    }
  }

  openBottleEventsModal(bottle: Bottle) {
    var bottleEventsModalRef = this.modalService.open(BottleEventsModalComponent, { size: 'lg' });
    bottleEventsModalRef.componentInstance.init(bottle, bottleEventsModalRef, () => { })
  }

  isBottleInArray(bottle: Bottle, array: BottleHistory[]) {
    return array.some(b => b.bottle.id == bottle.id);  
  }
}