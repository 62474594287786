import { Component, Input } from '@angular/core';
import { CAD, Mode } from '../../../../../../models/project/cad';
import { NodeType } from '../../../../../../models/project/node';

@Component({
  selector: 'cad-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css']
})
export class CADContextMenuComponent {
  // NF
  @Input('cad') cad: CAD;

  get isElectricMode(): boolean {
    return this.cad.mode == Mode.ELECTRIC
  }

  get isCO2Mode(): boolean {
    return this.cad.mode == Mode.CO2
  }

  get isSelectedNodeControl(): boolean {
    return this.cad.selectedNodes[0].nodeType == NodeType.CONTROL
  }

  get isSelectedNodeIntermediate(): boolean {
    return this.cad.selectedNodes[0].nodeType == NodeType.INTERMEDIATE
  }

  constructor() {}

}