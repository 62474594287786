import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OperatorService } from '../../../services/management/operator.service';
import { Router } from '@angular/router';
import { Operator } from 'src/app/models/management/operator';
import { Modal } from 'src/app/models/ui/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'add-operator-content',
  templateUrl: './add-operator.component.html'
})
export class AddOperatorModalComponent extends Modal<Operator> {
  // Form
  usernameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private operatorService: OperatorService,
    private toasterService: ToasterService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      username: [ '', Validators.required ],
      password: [ '', Validators.required ],
      lastName: null,
      firstName: null,
      phone: null,
      isAdmin: false,
      isAlertsEnabled: false,
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try create
    this.formSubmitted = true;
    this.operatorService.create(this.form).subscribe({
      next: () => this.close(true),
      error: (err: HttpErrorResponse) => {
        if(err.status == 409) {
          this.usernameAlreadyUsed = true;
            this.formSubmitted = false;
          this.toasterService.pop('error', "Error", "Un conflit avec les données sur le serveurs c'est produit, un utilisiteur existe déja avec une partie de ces informations.");
        } else {
          this.toasterService.pop('error', "Error", "Une erreur s'est produite");
        }
      }
    })
  }

}