import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VersionService } from '../../../../services/project/version.service';
import { Router } from '@angular/router';
import { ProjectService } from '../../../../services/project/project.service';
import { Version } from '../../../../models/project/version';
import { Modal } from 'src/app/models/ui/modal';
import { Project } from 'src/app/models/project/project';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'add-version-content',
  templateUrl: './add-version.component.html'
})
export class AddVersionModalComponent extends Modal<Project> implements OnInit {
  // Form
  versionAlreadyExist = false;
  form: FormGroup;
  formSubmitted = false;

  versions: Version[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private versionService: VersionService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      versionType: [ '', Validators.required ],
      numberOrder: [ 1, Validators.required ],
      project: '',
      versionSource: null
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  ngOnInit() {
    // Update form
    this.form.patchValue({
      project: this.subject.id
    });

    // Load versions
    this.projectService.getVersions(this.subject.id).subscribe(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
          this.versions = res.result;
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          case Code.UNAUTHORIZED:
          this.close();
          break;

        }
      }, () => this.close()
    )
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try create
    this.formSubmitted = true;
    this.versionService.create(this.form).subscribe(
      res => {
        switch (res.code) {

          case Code.VERSION_CREATED:
          case Code.WRONG_PARAMS:
          case Code.INTERNAL_ERROR:
          case Code.PROJECT_DOESNT_EXSIST:
          case Code.UNAUTHORIZED:
          this.close(true);
          break;

          case Code.VERSION_ALREADY_EXSIST:
          this.versionAlreadyExist = true;
          this.formSubmitted = false;
          break;

        }
    });
  }

}