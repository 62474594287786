import { Project, PictureSource } from "./project";
import { Version } from "./version";
import { Node, Type as NodeType } from "./node";
import { Path } from "./path";
import { Belt } from "./belt";
import { ProtectedArea } from "./protectedArea";

export class GDP {
  project: Project;
  version: Version;

  backgroundImage: HTMLImageElement = new Image();
  controlMarker: HTMLImageElement = new Image();
  hexagonalTrapMarker: HTMLImageElement = new Image();
  rectangularTrapMarker: HTMLImageElement = new Image();

  tool: Tool;

  googleMapsParams: any = {
    width: 640,
    height: 640,
    scale: 2,
    maptype: 'satellite',
    key: 'AIzaSyDNhsUG6VjyDQ_R_gZ2cT2ktTBbsy6M_uQ'
  };

  // Link
  linkFromNode: string;
  linkToNode: string;

  // Draw [{ x: 0, y: 0 }]
  drawPoints: any[] = [];

  constructor(project: Project, version: Version) {
    this.project = project;
    this.version = version;

    this.backgroundImage.crossOrigin = 'anonymous';
    this.controlMarker.crossOrigin = 'anonymous';
    this.hexagonalTrapMarker.crossOrigin = 'anonymous';
    this.rectangularTrapMarker.crossOrigin = 'anonymous';

    if (this.project.pictureSource == PictureSource.GOOGLE) {
      this.backgroundImage.src = 'https://maps.googleapis.com/maps/api/staticmap?center=' + this.project.lat + ',' + this.project.lng + '&zoom=' + this.project.zoom + '&size=' + this.googleMapsParams.width + 'x' + this.googleMapsParams.height + '&scale=' + this.googleMapsParams.scale + '&maptype=' + this.googleMapsParams.maptype + '&style=feature:all|element:labels|visibility:off&key=' + this.googleMapsParams.key;
    } else {
      this.backgroundImage.src = this.project.customPictureFilename;
    }

    this.controlMarker.src = '/assets/img/markers/control.png';
    this.hexagonalTrapMarker.src = '/assets/img/markers/trap_hexa.png';
    this.rectangularTrapMarker.src = '/assets/img/markers/trap_rect.png';
  }

  /*
   * Methods
   */
  getBelt(id: string): Belt {
    for (var i = 0; i < this.version.belts.length; i++)
      if (this.version.belts[i].id == id)
        return this.version.belts[i];
    
    return null;
  }
  getNode(id: string): Node {
    for (var i = 0; i < this.version.belts.length; i++)
      for (var j = 0; j < this.version.belts[i].nodes.length; j++)
        if (this.version.belts[i].nodes[j].id == id)
          return this.version.belts[i].nodes[j];
    
    return null;
  }
  getPath(id: string): Path {
    for (var i = 0; i < this.version.belts.length; i++)
      for (var j = 0; j < this.version.belts[i].paths.length; j++)
        if (this.version.belts[i].paths[j].id == id)
          return this.version.belts[i].paths[j];
    
    return null;
  }
  getPathsReferencesNode(nodeId: string): Path[] {
    var paths: Path[] = [];
    this.version.belts.forEach(belt => { belt.paths.forEach(path => { if (path.from.id == nodeId || path.to.id == nodeId) paths.push(path); }) });
    return paths;
  }
  getNodeTypeMarker(nodeType: NodeType): HTMLImageElement {
    switch (nodeType) {
      case NodeType.CONTROL: return this.controlMarker;
      case NodeType.HEXAGONAL_TRAP: return this.hexagonalTrapMarker;
      case NodeType.RECTANGULAR_TRAP: return this.rectangularTrapMarker;
    }
    return null;
  }
  getBeltFromNode(nodeId: string): Belt {
    for (var i = 0; i < this.version.belts.length; i++)
      for (var j = 0; j < this.version.belts[i].nodes.length; j++)
        if (this.version.belts[i].nodes[j].id == nodeId)
          return this.version.belts[i];
    
    return null;
  }
  getProtectedArea(id: string): ProtectedArea {
    for (var i = 0; i < this.version.belts.length; i++)
      for (var j = 0; j < this.version.belts[i].protectedAreas.length; j++)
        if (this.version.belts[i].protectedAreas[j].id == id)
          return this.version.belts[i].protectedAreas[j];
    
    return null;
  }

  getNodesAlreadyConnected(fromId: string, toId: string): boolean {
    var paths = this.getPathsReferencesNode(fromId);
    for (var i = 0; i < paths.length; i++) {
      if ((paths[i].from.id == fromId && paths[i].to.id == toId) || (paths[i].from.id == toId && paths[i].to.id == fromId)) {
        return true;
      }
    }
    return false;
  }

  getSelectedNodeNumber(): number {
    var number = 0;
    this.version.belts.forEach(belt => number += belt.nodes.filter(n => n.selected).length);
    return number;
  }
  getFirstSelectedNode(): Node {
    for (var i = 0; i < this.version.belts.length; i++) {
      for (var j = 0; j < this.version.belts[i].nodes.length; j++) {
        if (this.version.belts[i].nodes[j].selected) return this.version.belts[i].nodes[j];
      }
    }
    return null;
  }

  updateNodePosition(id: string, x: number, y: number): Node {
    x = Math.round(x), y = Math.round(y);

    // Find & update Node
    var node = this.getNode(id);
    var distanceX = node.x - node.titleX, distanceY = node.y - node.titleY;
    node.x = x, node.y = y, node.titleX = node.x - distanceX, node.titleY = node.y - distanceY;

    return node;
  }
  updateNodeTitlePosition(id: string, x: number, y: number): Node {
    x = Math.round(x), y = Math.round(y);

    // Find & update Node
    var node = this.getNode(id);
    node.titleX = x, node.titleY = y;

    return node;
  }

  updateProtectedAreaPointPosition(id: string, index: number, x: number, y: number): ProtectedArea {
    x = Math.round(x), y = Math.round(y);

    // Find & update ProtectedArea
    var protectedArea = this.getProtectedArea(id);
    if (protectedArea.points[index]) {
      protectedArea.points[index].x = x;
      protectedArea.points[index].y = y;
    }

    return protectedArea;
  }

  deleteNode(id: string): Path[] {
    // Delete references Paths
    var paths: Path[] = this.getPathsReferencesNode(id);
    paths.forEach(path => this.deletePath(path.id));
    
    // Find & delete Node
    this.version.belts.forEach(belt => {
      var index = belt.nodes.findIndex(p => p.id == id);
      if (index != -1) belt.nodes.splice(index, 1);
    });

    return paths;
  }
  deletePath(id: string): void {
    // Find & delete Path
    this.version.belts.forEach(belt => {
      var index = belt.paths.findIndex(p => p.id == id);
      if (index != -1) belt.paths.splice(index, 1);
    });
  }
  deleteProtectedAreaPoint(id: string, index: number): ProtectedArea {
    // Find ProtectedArea
    var protectedArea = this.getProtectedArea(id);

    // Find & delete index
    if (protectedArea) {
      protectedArea.points.splice(index, 1);
    }

    return protectedArea;
  }

  createBelt(belt: Belt): void {
    this.version.belts.push(belt);
  }
  createNode(beltId: string, node: Node): void {
    // Find belt & add node
    var belt: Belt = this.getBelt(beltId);
    belt.nodes.push(node);
  }
  createPath(beltId: string, path: Path): void {
    // Find belt & add path
    var belt: Belt = this.getBelt(beltId);
    belt.paths.push(path);
  }
  createProtectedArea(beltId: string, protectedArea: ProtectedArea) {
    // Find belt & add protected area
    var belt: Belt = this.getBelt(beltId);
    belt.protectedAreas.push(protectedArea);
  }
  createProtectedAreaPoint(id: string, index: number, before: boolean): ProtectedArea {
    // Get protected area
    var protectedArea = this.getProtectedArea(id);

    // Get point
    var point = protectedArea.points[index];

    // Create point
    protectedArea.points.splice(before ? index : index + 1, 0, {
      x: point.x + 10,
      y: point.y + 10
    });

    return protectedArea;
  }

  deleteBelt(belt: Belt): void {
    
    // Check if selected belt
    if (this.version.selectedBelt == belt.id) this.version.selectedBelt = null;

    // Delete belt
    var index = this.version.belts.findIndex(b => b.id == belt.id);
    if (index != -1) this.version.belts.splice(index, 1);

  }
  deleteProtectedArea(protectedArea: ProtectedArea): void {

    // Check if selected protectedArea
    if (this.version.selectedProtectedArea == protectedArea.id) this.version.selectedProtectedArea = null;

    // Delete protectedArea
    var index = -1;
    for (var i = 0; i < this.version.belts.length; i++) {
      for (var j = 0; j < this.version.belts[i].protectedAreas.length; j++) {
        if (this.version.belts[i].protectedAreas[j].id == protectedArea.id) {
          index = j;
        }
      }
    }
    if (index != -1) this.version.belts.forEach(belt => belt.protectedAreas.splice(index, 1));

  }

}

export enum Tool {
  SELECT = 'SELECT',
  MOVE = 'MOVE',
  LINK = 'LINK',
  DRAW = 'DRAW',
  DELETE = 'DELETE',
  CAPTURE = 'CAPTURE'
}