import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../auth/auth.service';
import { Operator } from '../../../models/management/operator';
import { ToasterService } from 'angular2-toaster';
import { Designer } from '../../../models/management/designer';
import { Code } from 'src/app/models/ApiResponse';
import { User } from 'src/app/models/management/user';

export interface UserFormModel {
  id: string;
  username: string;
  password: string;
}

@Component({
  selector: 'app-settings-user',
  templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {
  user: User;

  // User Form
  usernameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  // Password Form
  passwordForm: FormGroup;
  passwordFormSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    
    // User Form
    this.form = this.fb.group({
      username: { value: '', disabled: true },
      lastName: '',
      firstName: '',
      phone: ''
    });

    // Password Form
    this.passwordForm = this.fb.group({
      password: [ '', Validators.required ]
    });

    // Get user
    this.authService.get().subscribe(data => {
      switch(this.authService.getRole()) {
        case 'ROLE_ADMINISTRATOR':
        case 'ROLE_DESIGNER':
        case 'ROLE_OPERATOR':
          // Update form
          this.user = Operator.loadFromJson(data);
          this.form.setValue({
            username: data.username,
            lastName: data.lastName,
            firstName: data.firstName,
            phone: data.phone
          })
        break;

          // Update form
      }
    });
  }

  // User
  get f() { return this.form.controls; }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try edit
    this.formSubmitted = true;
    this.authService.edit(this.form).subscribe(
      res => {
        switch (res.code) {

          case Code.OPERATOR_EDITED:
          case Code.DESIGNER_EDITED:
          this.formSubmitted = false;
          break;

        }
      }
    );
  }
  onPasswordFormSubmit() {
    // stop here if form is invalid
    if (this.passwordForm.invalid)
      return;

    // Try edit
    this.passwordFormSubmitted = true;
    this.authService.edit(this.passwordForm).subscribe(
      res => {
        switch (res.code) {

          case Code.OPERATOR_EDITED:
          case Code.DESIGNER_EDITED:
          this.passwordFormSubmitted = false;
          break;

        }
      }
    );
  }

}