import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UPCV3, co2LitersToKg } from '../../../../models/upcv3/upc/upcv3';
import { UPCV3Service } from '../../../../services/upcv3/upcv3.service';
import { NgbModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { LinkUPCV3ModalComponent } from './link-upcv3/link-upcv3.component';
import { InitUPCModalComponent } from './init-upc/init-upc.component';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { RestoreUPCModalComponent } from './restore-upc/restore-upc.component';
import { Site } from 'src/app/models/management/site';
import { CommentmodalComponent } from 'src/app/pages/dashboard/commentmodal/commentmodal.component';
import { AlarmVariables } from 'src/app/pages/parameterbelts/alarms/parameters-alarms.component';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { ToasterService } from 'angular2-toaster';
import { GroupUPCV3Service } from 'src/app/services/upcv3/groupUpcv3.service';
import _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { CO2_LITERS_TO_KG_RATIO } from 'src/app/constants/co2';

@Component({
  selector: 'app-upc-group',
  templateUrl: './upcGroup.component.html',
  styleUrls: ['./upcGroup.component.css']
})

export class UPCGroupComponent implements OnInit {
  // UPC-V3
  group: GroupUPCV3;
  site: Site;
  activeIdString: any = '1';

  // Wrap static method for use in the template
  co2LitersToKg = co2LitersToKg;

  isSync: boolean = false;
  alarmVariables: AlarmVariables;

  constructor(
    private fb: FormBuilder,

    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,

    public authService: AuthService,
    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService,
    private groupUPCV3service: GroupUPCV3Service
  ) { }


  // Call the UPCV3 Group factory
  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      const groupId = parseInt(params['groupId'], 10);

      if(isNaN(groupId)){
        this.toasterService.pop('error', "Erreur", "L'information de groupe n'est pas valide")
      }

      this.groupUPCV3service.getById(groupId).subscribe({
        error: (err) => this.toasterService.pop('error', "Erreur", "Erreur lors de la récupération des informations du groupe"),
        next: (group) => {
          this.group = group;
          this.site = group.site;
          this.group.upcv3s.sort((a, b) => a.upcNameId > b.upcNameId ? 1 : -1)
          this.loadAlarmsVariables();
        }
      });
    });
  }

  loadAlarmsVariables() {
    this.alarmVariables = {
      // Alarms
      alrResLowEn: { value: this.group.upcv3s[0].alarmsParameters.alrResLowEn, changed: false },
      alrResEmptyEn: { value: this.group.upcv3s[0].alarmsParameters.alrResEmptyEn, changed: false },
      alrPresInpEn: { value: this.group.upcv3s[0].alarmsParameters.alrPresInpEn, changed: false },
      alrPresOutEn: { value: this.group.upcv3s[0].alarmsParameters.alrPresOutEn, changed: false },
      alrFlowAvgEn: { value: this.group.upcv3s[0].alarmsParameters.alrFlowAvgEn, changed: false },
      alrPowBackEn: { value: this.group.upcv3s[0].alarmsParameters.alrPowBackEn, changed: false },
      alrPowDownEn: { value: this.group.upcv3s[0].alarmsParameters.alrPowDownEn, changed: false },

      alrResLowLevel: { value: this.group.upcv3s[0].alarmsParameters.alrResLowLevel, changed: false },
      alrResEmptyFlow: { value: this.group.upcv3s[0].alarmsParameters.alrResEmptyFlow, changed: false },
      alrPresInpTol: { value: this.group.upcv3s[0].alarmsParameters.alrPresInpTol, changed: false },
      alrPresOutTol: { value: this.group.upcv3s[0].alarmsParameters.alrPresOutTol, changed: false },
      alrFlowSetTol: { value: this.group.upcv3s[0].alarmsParameters.alrFlowSetTol, changed: false },
      alrResEmptyTest: { value: this.group.upcv3s[0].alarmsParameters.alrResEmptyTest , changed: false },
      alrSmsNum1: { value: this.group.upcv3s[0].alarmsParameters.alrSmsNum1, changed: false },
      alrSmsNum2: { value: this.group.upcv3s[0].alarmsParameters.alrSmsNum2, changed: false },
      alrSmsNum3: { value: this.group.upcv3s[0].alarmsParameters.alrSmsNum3, changed: false },
      alrSmsNum4: { value: this.group.upcv3s[0].alarmsParameters.alrSmsNum4, changed: false },
      alrSmsNum5: { value: this.group.upcv3s[0].alarmsParameters.alrSmsNum5, changed: false },
    }
  }

  get needSync(): boolean {
    const alarmNeedSync = (Object.keys(this.alarmVariables) as Array<keyof typeof this.alarmVariables>).some(v => this.alarmVariables[v].changed);
    return alarmNeedSync;
  }

  syncBelts() {
    this.isSync = true;
    this.upcv3Service.syncs(this.group.upcv3s.map(u => u.id), {...this.alarmVariables}).pipe(
      finalize(() => this.isSync = false)
    ).subscribe({
      error: err => this.toasterService.pop("error", "Erreur" , "Une erreur s'est produite lors de la synchronisation de ou des Upcs"),
    });
  }

  onOpenCommentModal( upc, group) {
    const commentModal = this.modalService.open(CommentmodalComponent);
    commentModal.componentInstance.init({ site: this.site, upc: upc, group: group }, commentModal, () => {});
    commentModal.result.then(data => {
      this.ngOnInit();
    }, (reason) => {
      this.ngOnInit();
    });
  }

  openLinkUpcv3Modal(upc: UPCV3) {
    const linkUpcv3ModalRef = this.modalService.open(LinkUPCV3ModalComponent);
    linkUpcv3ModalRef.componentInstance.init(upc, linkUpcv3ModalRef, () => location.reload());
  }
  openInitUpcv3Modal(upc: UPCV3) {
    const initUpcv3ModalRef = this.modalService.open(InitUPCModalComponent);
    initUpcv3ModalRef.componentInstance.init(upc.id, initUpcv3ModalRef, () => { });
  }
  openRestoreUpcv3Modal(upc: UPCV3) {
    const restoreUpcv3ModalRef = this.modalService.open(RestoreUPCModalComponent);
    restoreUpcv3ModalRef.componentInstance.init(upc.id, restoreUpcv3ModalRef, () => { });
  }

  openAddInterventionModal() {
    this.router.navigate(["site/"+this.site.id+"/intervention/add"], { queryParams: { groupUpcv3Id: this.group.id }});
  }

  // Call Polling action to all the upcs from the group
  immediatePoll() {
    for (const upcv3 of this.group.upcv3s) {
      this.upcv3Service.poll(upcv3.id).subscribe({
        next: () => {
          this.toasterService.pop("success", 'Succès', `Lancement du polling pour l'UPC ${upcv3.upcNameId} réussi.`)
        },
        error: () => {
          this.toasterService.pop("error", "Erreur",  `Une erreur s'est produite lors du lancement du polling de l'upc ${upcv3.upcNameId}`)
        }
      });
    }
  }

  togglePoll(upc: UPCV3, upcPollEnable: boolean) {
   
    this.upcv3Service.edit(upc.id, this.fb.group({ upcPollEnable: upcPollEnable })).subscribe({
      next: (res) => {
        const editedIndex = this.group.upcv3s.findIndex(element => element.id === upc.id);
        this.group.upcv3s[editedIndex].upcPollEnable = res.upcPollEnable;
      },
      error: (e) => {
        this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors du changement de la configuration du polling");
      }
    })
  }

  toogleHibernated(upc: UPCV3, hibernated: boolean) {
    this.upcv3Service.edit(upc.id, this.fb.group({ hibernated: hibernated })).subscribe({
      next: (res) => {
        const editedIndex = this.group.upcv3s.findIndex(element => element.id === upc.id);
        this.group.upcv3s[editedIndex].hibernated = res.hibernated;
      },
      error: (e) => {
        this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors du changement de la configuration de l'hivernage");
      }
    })
  };

/**
 * Fonction utilitaire pour le formatage des commentaire à l'écran
 * @param {UPCV3} upc - L'upc pour laquelle le commentaire est a afficher
 * @param {number} nbCharToTrunc - Nombre de caractère du commentaires à afficher à l'écran
 * @return {string} Le commentaire tronqué, avec la date du commentaire en fin de chaîne
 */
  formatLastCommentStringForUpc(upc: UPCV3, nbCharToTrunc: number) {    
    let lastComment = upc.lastComment;
    if( lastComment == null) return null;
    let truncatedMessage = _.truncate(lastComment.message, {length: nbCharToTrunc});
    let classString = upc.lastActionNotDone ? "warning" : "primary";
    

    const displayDateFormat: Intl.DateTimeFormatOptions = {
      hour12: false,
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    };
    const dateString = lastComment.date.toLocaleString('fr-fr', displayDateFormat);

    return {
      text: `${lastComment.auteur} : ${truncatedMessage} }`,
      date: dateString,
      class: classString
    }
  }

/**
 * Calcul le nombre total de piège du groupe, fonction du nombre de piège de ses membres
 * @return {number} Le nombre de piège total du groupe
 */
  getTotalTrap(): number {
    let result = 0;
    for (const upc of this.group.upcv3s) {
      result += upc.generalParameters.upcTrapNum;
    }
    return result;
  }

/**
 * Renvoie la valeur du contenu calculé en additionnant celle des membres, pour la reserve 1
 * @return {string} La quantité totale du contenue calculé de la reserve 1 du groupe
 */
  getTotalCo2Res1ActVol(): string {
    let result = 0;
    for (const upc of this.group.upcv3s) {
      result += upc.reservesParameters.co2Res1ActVol;
    }
    return (result * CO2_LITERS_TO_KG_RATIO).toFixed(1); // cf legacy magic value in UPC class
  }

/**
 * Renvoie la valeur du contenu calculé en additionnant celle des membres, pour la reserve 2
 * @return {string} La quantité totale du contenue calculé de la reserve 2 du groupe
 */
  getTotalCo2Res2ActVol(): string {
    let result = 0;
    for (const upc of this.group.upcv3s) {
      result += upc.reservesParameters.co2Res2ActVol;
    }
    return (result * CO2_LITERS_TO_KG_RATIO).toFixed(1); // cf legacy magic value in UPC class
  }

/**
 * Renvoie la valeur de la durée estimé en gardant la valeur la plus conservatrice de celle des membres, pour la reserve 1
 * @return {string} La durée estimé calculé de la reserve 1 du groupe
 */
  getTotalCo2Res1ActDur(): number {
    return Math.min(...this.group.upcv3s.map(u => u.reservesParameters.co2Res1ActDur));
  }

/**
 * Renvoie la valeur de la durée estimé en gardant la valeur la plus conservatrice de celle des membres, pour la reserve 2
 * @return {string} La durée estimé calculé de la reserve 2 du groupe
 */
  getTotalCo2Res2ActDur(): number {
    return Math.min(...this.group.upcv3s.map(u => u.reservesParameters.co2Res2ActDur));
  }

  onConfigurationShown(upc: UPCV3) {
    this.upcv3Service.get(upc.id).subscribe(upc => {
      this.group.upcv3s.find(u => u.id == upc.id).communicationParameters = upc.communicationParameters;
    });
  }
}
