<!-- Search -->
<div class="col-sm-6 col-lg-4 offset-lg-5 mb-2">
  <mat-form-field>
    <mat-label>Filtre</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
  </mat-form-field>
</div>

<mat-table [dataSource]="dataSource" matSort class="w-100">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <!-- CodeBarre Column  -->
  <ng-container matColumnDef="barcode">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Code-Barre</mat-header-cell>
    <mat-cell *matCellDef="let bottle">{{ bottle.barcode }}</mat-cell>
  </ng-container>

  <!-- Status Column  -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let bottle">{{ bottle.statusString }}</mat-cell>
  </ng-container>

  <!-- Status Column  -->
  <ng-container matColumnDef="location">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
    <mat-cell *matCellDef="let bottle">{{ bottle.location?.name }}</mat-cell>
  </ng-container>

  <!-- Etat Column  -->
  <ng-container matColumnDef="state">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Etat</mat-header-cell>
    <mat-cell *matCellDef="let bottle">{{ bottle.stateString }}</mat-cell>
  </ng-container>

  <!-- Type Column  -->
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
    <mat-cell *matCellDef="let bottle">
      {{ bottle.bottleType?.designationString }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="site">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Site</mat-header-cell>
    <mat-cell *matCellDef="let bottle">
      {{ bottle.location.name }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="upcv3">
    <mat-header-cell *matHeaderCellDef mat-sort-header>UPCV3</mat-header-cell>
    <mat-cell *matCellDef="let bottle">
      {{ bottle.upcv3?.upcNameId }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="groupUPCV3">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Groupe UPCV3</mat-header-cell>
    <mat-cell *matCellDef="let bottle">
      {{ bottle.groupUPCV3?.name }}
    </mat-cell>
  </ng-container>

  <!-- Action Column  -->
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let bottle">
      <div>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="openBottleEventsModal(bottle)">
            <mat-icon>list</mat-icon>
            <span>Afficher l'historique</span>
          </button>
          <button mat-menu-item (click)="openReturnToSupplier(bottle)" [disabled]="!permitReturnToSupplier">
            <mat-icon>keyboard_return</mat-icon>
            <span>Renvoyer au fournisseur</span>
          </button>
        </mat-menu>
      </div>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<!-- Pagination -->
<div class="col-12 mb-3">
  <mat-paginator #paginator class="paginator" showFirstLastButtons [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>