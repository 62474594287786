<h4>
  {{
    names.length > 30
      ? "Ceintures : " + names.substring(0, 30) + "..."
      : "Ceintures : " + names
  }}
</h4>
<div *ngIf="dataLoaded">
  <div class="col-12 text-center">
    <!-- Disable -->
    <button
      type="button"
      class="btn btn-outline-success mr-2"
      [ngClass]="{
        active: this.upcv3[0].generalParameters.upcStatus == 0,
        'btn-outline-warning': vars.upcMode.changed
      }"
      [disabled]="isSync"
      (click)="
        this.upcv3[0].generalParameters.upcStatus = 0;
        vars.upcMode.value = 0;
        vars.upcMode.changed = true
      "
    >
      Inactif
    </button>

    <!-- Enable -->
    <button
      type="button"
      class="btn btn-outline-success mr-2"
      [ngClass]="{
        active:
          this.upcv3[0].generalParameters.upcStatus == 1 ||
          this.upcv3[0].generalParameters.upcStatus == 5 ||
          this.upcv3[0].generalParameters.upcStatus == 6 ||
          this.upcv3[0].generalParameters.upcStatus == 7 ||
          this.upcv3[0].generalParameters.upcStatus == 100,
        'btn-outline-warning': vars.upcMode.changed
      }"
      [disabled]="isSync"
      (click)="
        this.upcv3[0].generalParameters.upcStatus = 1;
        vars.upcMode.value = 1;
        vars.upcMode.changed = true
      "
    >
      Actif
    </button>
  </div>
  <p class="mt-3 mb-0 text-center">
    <small class="text-muted mr-2"
      >Dernière mise à jour des variables :
      <b>{{ upcv3[0].upcLastSyncDatetime.toLocaleString() }}</b></small
    >
    <button
      type="button"
      class="btn btn-success btn-sm"
      (click)="syncBelts()"
      [disabled]="isSync"
    >
      Synchroniser
    </button>
    <ng-container *ngIf="needSync">
      <br />
      <small class="text-warning"
        >Des variables sont en attente de synchronisation</small
      >
    </ng-container>
  </p>
  <br />
  <ul ngbNav #nav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Programmes</a>
      <ng-template ngbNavContent>
        <!-- Sun diffusion programs -->
        <div class="col-12">
          <div class="card bg-light mb-2">
            <div class="card-body">
              <h5 class="card-title">Programmes Aube / Crépuscule</h5>
              <table class="table table-borderless table-sm mb-0">
                <!-- Sunrise -->
                <tr>
                  <th class="align-middle">Aube</th>

                  <!-- sunrise_sign / sunrise_delay -->
                  <td>
                    <select
                      class="custom-select custom-select-sm d-inline-block mb-1"
                      [ngClass]="{
                        'bg-warning': vars.sunrise_sign.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunrise_sign.value"
                      (ngModelChange)="setProgramHasChanged();"
                      style="width: 36%"
                    >
                      <option value="+">+</option>
                      <option value="-">-</option>
                    </select>
                    <input
                      type="time"
                      class="form-control form-control-sm d-inline-block"
                      [ngClass]="{
                        'bg-warning': vars.sunrise_delay.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunrise_delay.value"
                      (ngModelChange)="setProgramHasChanged();"
                      style="width: 61%"
                    />
                  </td>

                  <!-- sunrise_duration -->
                  <td>
                    <input
                      type="time"
                      class="form-control form-control-sm"
                      [ngClass]="{
                        'bg-warning': vars.sunrise_duration.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunrise_duration.value"
                      (ngModelChange)="setProgramHasChanged();"
                    />
                  </td>

                  <!-- sunrise_intensity -->
                  <td>
                    <select
                      class="custom-select custom-select-sm"
                      [ngClass]="{
                        'bg-warning': vars.sunrise_intensity.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunrise_intensity.value"
                      (ngModelChange)="setProgramHasChanged();"
                    >
                      <option
                        *ngFor="let intensity of intensities"
                        [value]="intensity.value"
                      >
                        {{ intensity.name }}
                      </option>
                    </select>
                  </td>

                  <!-- start_stop -->
                  <td>
                  </td>
                </tr>

                <!-- Sunset -->
                <tr>
                  <th class="align-middle">Crépuscule</th>

                  <!-- sunset_sign / sunset_delay -->
                  <td>
                    <select
                      class="custom-select custom-select-sm d-inline-block mb-1"
                      [ngClass]="{
                        'bg-warning': vars.sunset_sign.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunset_sign.value"
                      (ngModelChange)="setProgramHasChanged();"
                      style="width: 36%"
                    >
                      <option value="+">+</option>
                      <option value="-">-</option>
                    </select>
                    <input
                      type="time"
                      class="form-control form-control-sm d-inline-block"
                      [ngClass]="{
                        'bg-warning': vars.sunset_delay.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunset_delay.value"
                      (ngModelChange)="setProgramHasChanged();"
                      style="width: 61%"
                    />
                  </td>

                  <!-- sunset_duration -->
                  <td>
                    <input
                      type="time"
                      class="form-control form-control-sm"
                      [ngClass]="{
                        'bg-warning': vars.sunset_duration.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunset_duration.value"
                      (ngModelChange)="setProgramHasChanged();"
                    />
                  </td>

                  <!-- sunset_intensity -->
                  <td>
                    <select
                      class="custom-select custom-select-sm"
                      [ngClass]="{
                        'bg-warning': vars.sunset_intensity.changed
                      }"
                      [disabled]="isSync"
                      [(ngModel)]="vars.sunset_intensity.value"
                      (ngModelChange)="setProgramHasChanged();"
                    >
                      <option
                        *ngFor="let intensity of intensities"
                        [value]="intensity.value"
                      >
                        {{ intensity.name }}
                      </option>
                    </select>
                  </td>

                  <!-- start_stop -->
                  <td>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <!-- Diffusion programs -->
        <div class="col-12">
          <div class="card bg-light mb-2">
            <div class="card-body">
              <h5 class="card-title">Programmes de diffusion</h5>
              <table class="table table-borderless table-sm mb-0">
                <tbody>
                  <!-- Program 1 -->
                  <tr>
                    <th class="align-middle">P1</th>

                    <!-- daysCode_1 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_1.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_1.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_1 / end_1 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_1.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_1.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_1.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_1.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_1 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_1.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_1.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 2 -->
                  <tr>
                    <th class="align-middle">P2</th>

                    <!-- daysCode_2 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_2.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_2.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_2 / end_2 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_2.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_2.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_2.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_2.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_2 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_2.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_2.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 3 -->
                  <tr>
                    <th class="align-middle">P3</th>

                    <!-- daysCode_3 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_3.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_3.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_3 / end_3 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_3.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_3.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_3.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_3.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_3 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_3.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_3.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 4 -->
                  <tr>
                    <th class="align-middle">P4</th>

                    <!-- daysCode_4 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_4.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_4.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_4 / end_4 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_4.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_4.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_4.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_4.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_4 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_4.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_4.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 5 -->
                  <tr>
                    <th class="align-middle">P5</th>

                    <!-- daysCode_5 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_5.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_5.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_5 / end_5 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_5.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_5.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_5.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_5.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_5 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_5.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_5.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 6 -->
                  <tr>
                    <th class="align-middle">P6</th>

                    <!-- daysCode_6 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_6.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_6.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_6 / end_6 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_6.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_6.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_6.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_6.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_6 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_6.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_6.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 7 -->
                  <tr>
                    <th class="align-middle">P7</th>

                    <!-- daysCode_7 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_7.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_7.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_7 / end_7 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_7.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_7.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_7.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_7.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_7 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_7.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_7.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 8 -->
                  <tr>
                    <th class="align-middle">P8</th>

                    <!-- daysCode_8 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_8.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_8.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_8 / end_8 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_8.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_8.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_8.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_8.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_8 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_8.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_8.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 9 -->
                  <tr>
                    <th class="align-middle">P9</th>

                    <!-- daysCode_9 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_9.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_9.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_9 / end_9 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_9.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_9.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_9.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_9.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_9 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_9.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_9.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <!-- Program 10 -->
                  <tr>
                    <th class="align-middle">P10</th>

                    <!-- daysCode_10 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.daysCode_10.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.daysCode_10.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let daysCode of daysCodes"
                          [value]="daysCode.value"
                        >
                          {{ daysCode.name }}
                        </option>
                      </select>
                    </td>

                    <!-- start_10 / end_10 -->
                    <td>
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.start_10.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.start_10.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                      -
                      <input
                        type="time"
                        class="form-control form-control-sm d-inline-block"
                        [ngClass]="{
                          'bg-warning': vars.end_10.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.end_10.value"
                        (ngModelChange)="setProgramHasChanged();"
                        style="width: 45%"
                      />
                    </td>

                    <!-- intensity_10 -->
                    <td>
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.intensity_10.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.intensity_10.value"
                        (ngModelChange)="setProgramHasChanged();"
                      >
                        <option
                          *ngFor="let intensity of intensities"
                          [value]="intensity.value"
                        >
                          {{ intensity.name }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>Alarmes</a>

      <ng-template ngbNavContent>
        <app-parameterbelts-alarms [isSync]="isSync" [vars]="alarmVariables"></app-parameterbelts-alarms>
      </ng-template>
    </li>
    <li ngbNavItem>
      <a ngbNavLink>
        <i class="fa fa-cog"></i>
      </a>

      <ng-template ngbNavContent>
        <div class="card card-body border-top-0">
          <div class="row d-flex justify-content-center">
            <!-- General parameters -->
            <div class="col-md-6 col-lg-3">
              <div class="card bg-light mb-2">
                <div class="card-body">
                  <h5 class="card-title">General parameters</h5>
                  <dl class="row mb-0">
                    <!-- upcTimeZone -->
                    <dd class="col-12 mb-0">Fuseau horaire</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.upcTimeZone.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.upcTimeZone.value"
                        (ngModelChange)="vars.upcTimeZone.changed = true"
                      />
                    </dt>

                    <!-- upcLanguage -->
                    <dd class="col-12 mb-0">Langue</dd>
                    <dt class="col-12 mb-2">
                      <select
                        class="custom-select custom-select-sm"
                        [ngClass]="{
                          'bg-warning': vars.upcLanguage.changed
                        }"
                        [disabled]="isSync"
                        [(ngModel)]="vars.upcLanguage.value"
                        (ngModelChange)="vars.upcLanguage.changed = true"
                      >
                        <option value="0">Français</option>
                        <option value="1">Anglais</option>
                      </select>
                    </dt>

                    <hr />

                    <!-- co2FlowRefTrap -->
                    <dd class="col-12 mb-0">Débit de ref. / piège [nl/mn]</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        step="0.01"
                        [ngClass]="{
                          'bg-warning': vars.co2FlowRefTrap.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.co2FlowRefTrap.value"
                        (ngModelChange)="vars.co2FlowRefTrap.changed = true"
                      />
                    </dt>

                    <hr />

                    <!-- upcCo2PidProp -->
                    <dd class="col-12 mb-0">PID P</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.upcCo2PidProp.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.upcCo2PidProp.value"
                        (ngModelChange)="vars.upcCo2PidProp.changed = true"
                      />
                    </dt>

                    <!-- upcCo2PidInteg -->
                    <dd class="col-12 mb-0">PID I</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.upcCo2PidInteg.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.upcCo2PidInteg.value"
                        (ngModelChange)="vars.upcCo2PidInteg.changed = true"
                      />
                    </dt>

                    <!-- upcCo2PidDiff -->
                    <dd class="col-12 mb-0">PID D</dd>
                    <dt class="col-12 mb-0">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.upcCo2PidDiff.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.upcCo2PidDiff.value"
                        (ngModelChange)="vars.upcCo2PidDiff.changed = true"
                      />
                    </dt>
                  </dl>
                </div>
              </div>
            </div>

            <!-- Comunication -->
            <div class="col-md-6 col-lg-3">
              <div class="card bg-light mb-2">
                <div class="card-body">
                  <h5 class="card-title">Comunication</h5>
                  <dl class="row mb-0">
                    <!-- comWiFiName -->
                    <dd class="col-12 mb-0">WiFi : SSID</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comWiFiName.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comWiFiName.value"
                        (ngModelChange)="vars.comWiFiName.changed = true"
                      />
                    </dt>

                    <!-- comWiFiPass -->
                    <dd class="col-12 mb-0">WiFi : Mot de passe</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comWiFiPass.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comWiFiPass.value"
                        (ngModelChange)="vars.comWiFiPass.changed = true"
                      />
                    </dt>

                    <!-- comWifiApCh -->
                    <dd class="col-12 mb-0">WiFi : Canal RF</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comWifiApCh.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comWifiApCh.value"
                        (ngModelChange)="vars.comWifiApCh.changed = true"
                      />
                    </dt>

                    <hr />

                    <!-- comWebSrvUrl -->
                    <dd class="col-12 mb-0">Serveur : URL</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comWebSrvUrl.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comWebSrvUrl.value"
                        (ngModelChange)="vars.comWebSrvUrl.changed = true"
                      />
                    </dt>

                    <hr />

                    <!-- comMdmApnId2 -->
                    <dd class="col-12 mb-0">APN : Identifiant 2</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comMdmApnId2.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comMdmApnId2.value"
                        (ngModelChange)="vars.comMdmApnId2.changed = true"
                      />
                    </dt>

                    <!-- comMdmApnUser -->
                    <dd class="col-12 mb-0">APN : Utilisateur</dd>
                    <dt class="col-12 mb-2">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comMdmApnUser.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comMdmApnUser.value"
                        (ngModelChange)="vars.comMdmApnUser.changed = true"
                      />
                    </dt>

                    <!-- comMdmApnPass -->
                    <dd class="col-12 mb-0">APN : Mot de passe</dd>
                    <dt class="col-12 mb-0">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        [ngClass]="{
                          'bg-warning': vars.comMdmApnPass.changed
                        }"
                        [disabled]="
                          isSync ||
                          this.upcv3[0].generalParameters.upcStatus == 3
                        "
                        [(ngModel)]="vars.comMdmApnPass.value"
                        (ngModelChange)="vars.comMdmApnPass.changed = true"
                      />
                    </dt>
                  </dl>
                </div>
              </div>
            </div>

            <!-- CO2 diffusion -->
            <div class="col-md-6 col-lg-3">
              <div class="card bg-light mb-2">
                <div class="card-body">
                  <h5 class="card-title">CO2 diffusion</h5>
                  <dl class="row mb-0">
                    <!-- diffHourSunrise -->
                    <dd class="col-12 mb-0">Aube</dd>
                    <dt class="col-12 mb-2">
                      {{
                        getHourMinutesFromSeconds(
                          upcv3[0].diffusionParameters.diffHourSunrise
                        )
                      }}
                    </dt>

                    <!-- diffHourSunset -->
                    <dd class="col-12 mb-0">Crépuscule</dd>
                    <dt class="col-12 mb-0">
                      {{
                        getHourMinutesFromSeconds(
                          upcv3[0].diffusionParameters.diffHourSunset
                        )
                      }}
                    </dt>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
