<div class="modal-header">
  <h4 class="modal-title">Nouveau Stock</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Name -->
    <div class="form-group">
      <label for="name">Nom *</label>
      <input type="text" class="form-control" id="name" placeholder="Nom *" formControlName="name" [ngClass]="{ 'is-invalid': nameAlreadyUsed || f.name.errors }" (keyup)="nameAlreadyUsed = false">
      <div *ngIf="nameAlreadyUsed || f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors && f.name.errors.required">Nom obligatoire</div>
        <div *ngIf="nameAlreadyUsed">Nom déjà utilisé</div>
      </div>
    </div>

    <!-- Force Barcodes Enabled -->
    <div class="form-check">
      <label for="forceBarcodesEnabled" class="form-check-label">
        <input class="form-check-input" type="checkbox" id="forceBarcodesEnabled" formControlName="forceBarcodesEnabled">Utiliser les code-barres ?
      </label>
    </div>
  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>