<div id="CAD" class="card" style="height: 97vh;">
  <!-- Canvas -->
  <cad-canvas #canvas [cad]="cad"></cad-canvas>
  <!--<cad-pixi [cad]="cad"></cad-pixi>-->

  <!-- Top Center -->
  <cad-top-center [cad]="cad"></cad-top-center>

  <!-- Bottom Center -->
  <cad-bottom-center [cad]="cad" *ngIf="cad.selectedNodes.length == 1 || cad.tool == 'DRAW' || cad.tool == 'SELECT_PARENT_NODE' || cad.tool == 'SELECT_PIXELS_RATIO'"></cad-bottom-center>

  <!-- Bottom Right -->
  <cad-bottom-right [cad]="cad"  *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'"></cad-bottom-right>

  <!-- Right Center -->
  <cad-right-center [cad]="cad" *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'"></cad-right-center>

  <!-- Bottom Left -->
  <cad-bottom-left [cad]="cad" *ngIf="cad.tool != 'DRAW' && !cad.selectedBelt && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'"></cad-bottom-left>

  <!-- Top Left -->
  <cad-top-left [cad]="cad" *ngIf="cad.tool != 'DRAW' && cad.tool != 'SELECT_PARENT_NODE' && cad.tool != 'SELECT_PIXELS_RATIO'"></cad-top-left>

  <!-- Clip canvas -->
  <cad-clip-canvas #clipCanvas *ngIf="cad.dowloadImage"></cad-clip-canvas>

  <!-- Diffusion -->
  <cad-diffusions [cad]="cad" *ngIf="cad.showDiffusionParameters"></cad-diffusions>

  <!-- Context Menu -->
  <cad-context-menu [cad]="cad" [ngStyle]="{ left: cad.contextMenuLeft + 'px', top: cad.contextMenuTop + 'px' }"></cad-context-menu>
</div>