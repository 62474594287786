import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StockService } from '../../../services/management/stock.service';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Stock } from 'src/app/models/management/stock';
import { StockPostDto } from 'src/app/models/dto/StockPostDto';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'add-stock-content',
  templateUrl: './add-stock.component.html'
})
export class AddStockModalComponent extends Modal<Stock> {
  // Form
  nameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private stockService: StockService,
    private toasterService: ToasterService
  ) {
    super();

    // Form
    this.form = this.fb.group({ name: ['', Validators.required], forceBarcodesEnabled: [true, Validators.required] });

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  // Form
  get f() { return this.form.controls }

  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try create
    this.formSubmitted = true;

    let stockData: StockPostDto = {
      name: this.form.value.name,
      forceBarcodesEnabled: this.form.value.forceBarcodesEnabled,
    }
    this.stockService.create(stockData).subscribe({
      next: () => this.close(true),
      error: (err) => {
        this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la création du stock");
        this.close(false);
      }
    })
  }
}