<div class="container-fluid">
  <div class="row mt-3">
    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Entrepôts</h4>

      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="openAddStockModal()"
        *ngIf="authService.isAdmin"
      >
        <i class="fa fa-plus"></i> Nouveau
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <!-- Content -->
    <div class="col-12 mb-2">
      <ul class="list-group list-group-striped">
        <!-- Header -->
        <li class="list-group-item d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-3 mb-0 font-weight-bold">Nom</p>
                <p class="col-1 mb-0 font-weight-bold">Code barres activés?</p>
                <p class="col-5 mb-0 font-weight-bold">Types associés</p>
                <p class="col-1 mb-0 font-weight-bold">Pleines</p>
                <p class="col-1 mb-0 font-weight-bold">Entamée</p>
                <p class="col-1 mb-0 font-weight-bold">Vides</p>
              </div>
            </div>
            <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
          </div>
        </li>

        <!-- Loading & Empty -->
        <li class="list-group-item px-3 py-2 text-center" *ngIf="!stocks">
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </li>
        <li
          class="list-group-item px-3 py-2 text-center"
          *ngIf="stocks && stocks?.length == 0"
        >
          Aucun stock
        </li>

        <!-- Row -->
        <li class="list-group-item px-3 py-1" *ngFor="let stock of stocks">
          <div class="row">
            <div class="col-10">
              <a class="row no-link" routerLink="/stock/{{ stock.id }}">
                <p class="col-md-3 mt-1 mb-0 font-weight-bold">
                  <small class="d-md-none mr-2">Nom</small>{{ stock.name }}
                </p>
                <p class="col-md-1 mt-1 mb-0 font-weight-bold">
                  <small class="d-md-none mr-2">Code barres activés</small><mat-icon>{{ stock.forceBarcodesEnabled ? 'check' : 'close' }}</mat-icon>
                </p>
                <p class="col-md-5 mt-1 mb-0">
                  <small class="d-md-none mr-2">Types associés</small
                  >{{ stock.bottleTypesString }}
                </p>
                <p class="col-md-1 mt-1 mb-0">
                  <small class="d-md-none mr-2">Pleines</small
                  >{{ stock.fullBottlesNumber }}
                </p>
                <p class="col-md-1 mt-1 mb-0">
                  <small class="d-md-none mr-2">Entamée</small
                  >{{ stock.usedBottlesNumber }}
                </p>
                <p class="col-md-1 mt-1 mb-0">
                  <small class="d-md-none mr-2">Vides</small
                  >{{ stock.emptyBottlesNumber }}
                </p>
              </a>
            </div>
            <div class="col-2 mb-0 text-right">
              <div placement="bottom-right" ngbDropdown>
                <button
                  class="btn btn-link text-dark px-3 py-0"
                  ngbDropdownToggle
                >
                  <i class="fa fa-ellipsis-v"></i>
                </button>
                <div class="p-0" ngbDropdownMenu>
                  <button
                    class="dropdown-item px-3 py-2"
                    (click)="openDeleteStockModal(stock)"
                    *ngIf="authService.isAdmin"
                  >
                    <i class="fa fa-trash"></i> Archiver
                  </button>
                  <button
                    class="dropdown-item px-3 py-2"
                    (click)="openEditStockModal(stock)"
                    *ngIf="authService.isAdmin"
                  >
                    <i class="fa fa-edit"></i> Modifier
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 mb-2">
      <h4 class="float-left">Bouteilles chez les Clients</h4>
    </div>
  </div>
  <div class="row mt-3">
    <!-- Content -->
    <div class="col-12 mb-3">
      <bottles-table
        *ngIf="customerBottles"
        [bottles]="customerBottles"
        [displayedColumns]="displayedColumnsCustomer"
      ></bottles-table>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 mb-2">
      <h4 class="float-left">Bouteilles stockées sur site</h4>
    </div>
  </div>
  <div class="row mt-3">
    <!-- Content -->
    <div class="col-12 mb-3">
      <bottles-table
        *ngIf="onSiteBottles"
        [bottles]="onSiteBottles"
        [displayedColumns]="displayedColumnsOnSite"
      ></bottles-table>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 mb-2">
      <h4 class="float-left">Bouteilles rendues au Fournisseur</h4>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 mb-3" *ngIf="authService.isAdmin">
      <bottles-table
        *ngIf="returnedBottles"
        [bottles]="returnedBottles"
        [displayedColumns]="displayedColumnsReturned"
      ></bottles-table>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 mb-2">
      <h4 class="float-left">Bouteilles en transit</h4>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12 mb-3" *ngIf="authService.isAdmin">
      <bottles-table
        *ngIf="inTransitBottles"
        [bottles]="inTransitBottles"
        [displayedColumns]="displayedColumnsInTransit"
      ></bottles-table>
    </div>
  </div>

  <!-- Title 2 -->
  <div class="row mt-3" *ngIf="authService.isAdmin">
    <div class="col-12 mt-2 mb-2">
      <h4 class="float-left">Types de bouteilles</h4>

      <button
        type="button"
        class="btn btn-primary float-right"
        (click)="openAddBottleTypeModal()"
      >
        <i class="fa fa-plus"></i> Nouveau
      </button>
    </div>
  </div>

  <!-- Content 2 -->
  <div class="row mt-3">
    <div class="col-12 mb-3" *ngIf="authService.isAdmin">
      <ul class="list-group list-group-striped">
        <!-- Header -->
        <li class="list-group-item d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-4 mb-0 font-weight-bold">Contenu</p>
                <p class="col-4 mb-0 font-weight-bold">Designation</p>
                <p class="col-4 mb-0 font-weight-bold">Marque</p>
              </div>
            </div>
            <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
          </div>
        </li>

        <!-- Loading & Empty -->
        <li class="list-group-item px-3 py-2 text-center" *ngIf="!bottleTypes">
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </li>
        <li
          class="list-group-item px-3 py-2 text-center"
          *ngIf="bottleTypes && bottleTypes?.length == 0"
        >
          Aucun type
        </li>

        <!-- Row -->
        <li
          class="list-group-item px-3 py-1"
          *ngFor="let bottleType of bottleTypes"
        >
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-md-4 mt-1 mb-0 font-weight-bold">
                  {{ bottleType.contenue }} kg{{
                    bottleType.isRembo ? " (Rembo)" : ""
                  }}
                </p>
                <p class="col-md-4 mt-1 mb-0">{{ bottleType.designation }}</p>
                <p class="col-md-4 mt-1 mb-0">{{ bottleType.brand }}</p>
              </div>
            </div>
            <div class="col-2 mb-0 text-right">
              <div placement="bottom-right" ngbDropdown>
                <button
                  class="btn btn-link text-dark px-3 py-0"
                  ngbDropdownToggle
                >
                  <i class="fa fa-ellipsis-v"></i>
                </button>
                <div class="p-0" ngbDropdownMenu>
                  <button
                    class="dropdown-item px-3 py-2"
                    (click)="openDeleteBottleTypeModal(bottleType)"
                  >
                    <i class="fa fa-trash"></i> Supprimer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
