import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

export enum Co2ResStatusType {
	Vide = 0,
	Résiduelle = 1,
	Disponible = 2,
	Indeterminé = 3,
}
@jsonObject
export class ReservesParameters {
	
	@jsonMember
	co2ResActive: number;
	
	@jsonMember
	co2ResActAdj: number;
	
	@jsonMember
	co2ResActPrev: Date;
	
	@jsonMember
	co2ResInactPrev: Date;
	
	@jsonMember
	co2ResType: number;
	
	@jsonMember
	co2Res1Status: number;
	
	@jsonMember
	co2Res1Enabled: boolean;
	
	@jsonMember
	co2Res1FillVol: number;
	
	@jsonMember
	co2Res1FillTime: Date;
	
	@jsonMember
	co2Res1ActVol: number;
	
	@jsonMember
	co2Res1ActDur: number;
	
	@jsonMember
	co2Res1StartVol: number;
	
	@jsonMember
	co2Res1AuxVol: number;
	
	@jsonMember
	co2Res2Status: number;
	
	@jsonMember
	co2Res2Enabled: boolean;
	
	@jsonMember
	co2Res2FillVol: number;
	
	@jsonMember
	co2Res2FillTime: Date;
	
	@jsonMember
	co2Res2ActVol: number;
	
	@jsonMember
	co2Res2ActDur: number;
	
	@jsonMember
	co2Res2StartVol: number;
	
	@jsonMember
	co2Res2AuxVol: number;
	
	@jsonMember
	co2ResLow: number;

	static loadFromJSON(json): ReservesParameters {
		let serialiazer = new TypedJSON(ReservesParameters);
		return serialiazer.parse(json)
	}

	static co2ResActiveToString(co2ResActive: number): string {
		switch (co2ResActive) {
			case 0: return 'B0';
			case 1: return 'B1';
			case 2: return 'B2';
			default: return 'Indeterminé';
		}
	}
	static co2ResStatusToString(co2ResStatus: number): string {
		switch (co2ResStatus) {
			case 0: return 'Vide';
			case 1: return 'Résiduelle';
			case 2: return 'Disponible';
			default: return 'Indeterminé';
		}
	}
}