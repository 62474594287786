<!-- Loading -->
<div class="col-12 mt-3 text-center" *ngIf="!site"><i class="fa fa-circle-o-notch fa-spin"></i></div>

<div class="container-fluid" *ngIf="site">
  <form [formGroup]="form">
    <!-- Title -->
    <div class="row mt-3">
      <div class="col-12">
        <h4 class="float-left">Nouvelle intervention sur site : {{ site.name }} {{ upcv3?.upcNameId | wrap: '| UPC: ' }} {{ groupUpcv3?.name | wrap: '| Groupe: ' }}</h4>
      </div>
    </div>
    <!-- Intervention -->
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <ul class="list-group list-group-flush">

            <!-- Datetime & Object -->
            <li class="list-group-item p-0">
              <div class="card-body">
                <div class="form-row">

                  <!-- Datetime -->
                  <div class="col-lg-4 form-group mb-2 mb-lg-0">
                    <div class="row">
                      <div class="col-12">
                        <label for="interventionDatetime">Date et Heure *</label>
                      </div>
                      <div class="col-12">
                        <p-calendar [class.is-invalid]="f.interventionDatetime.invalid && (f.interventionDatetime.dirty || f.interventionDatetime.touched)"
                        id="interventionDatetime" formControlName="interventionDatetime"
                        [showTime]="true" [showSeconds]="true" [maxDate]="todayDate"></p-calendar>
                      </div>
                    </div>
                  </div>

                  <!-- Object -->
                  <div class="col-lg-4 form-group mb-0">
                    <label for="object">Objet *</label>
                    <ng-select [items]="selectableObjets" [addTag]="true" [multiple]="true" 
                      bindLabel="value" (change)="onChangeObjet($event)" [(ngModel)]="selectedObjets"
                      placeholder="Objet de l'intervention..." formControlName="objet" id="objet" addTagText="Autre : "
                      [class.is-invalid]="f.objet.invalid && (f.objet.dirty || f.objet.touched)">
                    </ng-select>
                  </div>

                  <div class="col-lg-4 form-group">
                    <label for="mess">Intervenants *</label>
                    <ng-select [items]="intervenants" [searchable]="true" [clearable]="false" bindLabel="firstName"
                      bindValue="id" placeholder="Intervenant(s)..." [multiple]="true" formControlName="intervenantsId"
                      id="intervenantsId"
                      [class.ng-invalid]="f.intervenantsId.invalid && (f.intervenantsId.dirty || f.intervenantsId.touched)">
                    </ng-select>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Bouteilles -->
    <div class="row mt-2" *ngIf="(upcv3 || groupUpcv3) && bottlesOperationsEnabled">
      <ng-container *ngFor="let bottleDiff of bottlesDiff | keyvalue:keepOriginalOrder">
        <div class="col-sm-4" *ngIf="bottleDiff.key == 'b1' || bottleDiff.key == 'b2'"> <!-- Affiche (B1, B2) -->
          <div class="card bg-light h-100">
            <div class="card-body">
              <h5 class="card-title">{{ bottleDiff.key | uppercase }}</h5>
              <div class="row mb-0">
                <div class="col-md-12 mb-0"
                  *ngFor="let bottlesKeyValue of getBottlesDiff(this.bottleDiff.value) | keyvalue:keepOriginalOrder">
                  <ul *ngFor="let bottle of bottlesKeyValue.value">
                    <li
                      [ngClass]="{'text-success': bottlesKeyValue.key === 'bottlesAdded', 'text-danger text-decoration-line-through': bottlesKeyValue.key === 'bottlesRemoved'}">
                      {{bottle.barcode}} : {{bottle.bottleType.brand}} - {{bottle.bottleType.designation}} - {{bottle.bottleType.contenue}} kg
                      <button type="button" class="btn btn-success ml-2 float-right"
                        *ngIf="bottlesKeyValue.key === 'bottlesRemoved'" (mouseup)="onRemoveBottle(bottle)">
                        <i class="fa fa-plus"></i>
                      </button>
                      <button type="button" class="btn btn-danger ml-2 float-right"
                        *ngIf="bottlesKeyValue.key === 'bottlesAdded'" (mouseup)="onRemoveBottle(bottle)">
                        <i class="fa fa-minus"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-primary btn-block" (click)="bottleDiff.value.callbackAddBottle()">
                <i class="fa fa-plus mr-2"></i>Bouteille
              </button>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let bottleDiff of bottlesDiff | keyvalue:keepOriginalOrder">
        <div class="col-sm-4" *ngIf="bottleDiff.key == 'stock'">
          <div class="card bg-light h-100">
            <div class="card-body">
              <h5 class="card-title">{{ bottleDiff.key | uppercase }}</h5>
              <p>Il y a {{ bottleDiff.value.initialState.length }} bouteilles dans les stocks associés au site</p>
              <div *ngFor="let stock of this.site.stocks">
                {{ stock.name }}
                <div *ngFor="let bottleType of stock.bottleTypes">
                  <li>
                    {{ bottleType.designationString }} 
                    | Vides : {{ (stock.bottles | filterBy: ['bottleType.id']: bottleType.id | filterBy: ['state']: 'EMPTY')?.length }} 
                    | Pleines : {{ (stock.bottles | filterBy: ['bottleType.id']: bottleType.id | filterBy: ['state']: 'FULL')?.length }}
                    | Entamées : {{ (stock.bottles | filterBy: ['bottleType.id']: bottleType.id | filterBy: ['state']: 'IN_USE')?.length }}
                  </li>
                </div>
              </div>
              <br>
              <p>Il y a {{ (bottleDiff.value.initialState | filterBy: ['location.name']: site.name).length }} bouteilles entreposées sur le site</p>
                {{ site.name }}
                <div *ngFor="let bottleType of (bottleDiff.value.initialState | pluck: 'bottleType') | unique: 'id'">
                  <li>
                    {{ bottleType.designationString }} 
                    | Vides : {{ (site.bottles | filterBy: ['status']: 'ENTREPOSE' | filterBy: ['bottleType.id']: bottleType.id | filterBy: ['state']: 'EMPTY')?.length }} 
                    | Pleines : {{ (site.bottles | filterBy: ['status']: 'ENTREPOSE' | filterBy: ['bottleType.id']: bottleType.id | filterBy: ['state']: 'FULL')?.length }}
                    | Entamées : {{ (site.bottles | filterBy: ['status']: 'ENTREPOSE' | filterBy: ['bottleType.id']: bottleType.id | filterBy: ['state']: 'IN_USE')?.length }}
                  </li>
                </div>
              <div class="row mb-0">
                <hr/>
                <!-- Récupère les diff des bouteilles stock -->
                <div class="col-md-12 mb-0"
                  *ngFor="let bottlesKeyValue of getBottlesDiff(this.bottleDiff.value) | keyvalue:keepOriginalOrder">
                  <!-- Groupe les diffs par le stock de destination -->
                  <div *ngFor="let bottleGroup of bottlesKeyValue.value | groupBy: 'destinationStock.name' | keyvalue:keepOriginalOrder">
                    <ng-container *ngIf="bottleGroup.key !== 'undefined'">
                      {{ bottleGroup.key }}
                      <ul *ngFor="let bottle of bottleGroup.value">
                        <li *ngIf="bottlesKeyValue.key === 'bottlesAdded' || bottlesKeyValue.key === 'bottlesRemoved'"
                          [ngClass]="{'text-success': bottlesKeyValue.key === 'bottlesAdded', 'text-danger text-decoration-line-through': bottlesKeyValue.key === 'bottlesRemoved'}">
                          {{bottle.barcode}} : {{bottle.bottleType.brand}} - {{bottle.bottleType.designation}} |
                          {{bottle.state}}
                          <button type="button" class="btn btn-success ml-2 float-right"
                            *ngIf="bottlesKeyValue.key === 'bottlesRemoved'" (mouseup)="onRemoveBottle(bottle)">
                            <i class="fa fa-plus"></i>
                          </button>
                          <button type="button" class="btn btn-danger ml-2 float-right"
                            *ngIf="bottlesKeyValue.key === 'bottlesAdded'" (mouseup)="onRemoveBottle(bottle)">
                            <i class="fa fa-minus"></i>
                          </button>
                        </li>
                      </ul>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="button" class="btn btn-primary btn-block" (click)="bottleDiff.value.callbackAddBottle()">
                <i class="fa fa-plus mr-2"></i>Bouteille
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- Avertissement intervention sur upc d'un groupe -->
    <div class="row mt-2" *ngIf="upcv3 && groupUpcv3 && !bottlesOperationsEnabled">
      <div class="col-sm-12">
        <div class="card bg-light">
          <div class="card-body">
            L'intervention est effectuée sur une UPC faisant partie d'un groupe ({{ groupUpcv3.name }}).
            Les opérations sur les bouteilles sont désactivées.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2" *ngIf="upcv3 || groupUpcv3">
      <div class="col-sm-12">
        <div class="card bg-light">
          <div class="card-body">
            <h5 class="card-title">État préalable de l'intervention</h5>
            <dl class="row mb-0">
              <dd class="col-md-6 mb-0"></dd>
              <h5 class="col-md-3 mb-2 text-center">B1</h5>
              <h5 class="col-md-3 mb-2 text-center">B2</h5>
              <!-- Reserve empty -->
              <dd class="col-md-6 mb-0">Est-ce que la réserve était vide ?</dd>
              <dt class="col-md-3 mb-2 text-md-right">
                <ui-switch size="small" [disabled]="isSaving" [(ngModel)]="b1Empty"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeSwitch();"></ui-switch>
              </dt>
              <dt class="col-md-3 mb-2 text-md-right">
                <ui-switch size="small" [disabled]="isSaving" [(ngModel)]="b2Empty"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeSwitch();"></ui-switch>
              </dt>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- Contenus -->
    <div class="row mt-2" *ngIf="upcv3 || groupUpcv3">
      <div class="col-sm-12">
        <div class="card bg-light">
          <div class="card-body">
            <h5 class="card-title">Contenus</h5>
            <dl class="row mb-0">
              <dd class="col-md-6 mb-0"></dd>
              <h5 class="col-md-3 mb-2 text-center">B1</h5>
              <h5 class="col-md-3 mb-2 text-center">B2</h5>

              <dd class="col-md-6 mb-0">Contenu actuel</dd>
              <!-- Si UPCV3 -->
              <dt class="col-md-3 mb-2 text-md-right" *ngIf="upcv3">
                {{co2LitersToKg(upcv3.reservesParameters.co2Res1ActVol).toFixed(1)+" kg"}}
              </dt>
              <dt class="col-md-3 mb-2 mb-0 text-md-right" *ngIf="upcv3">
                {{co2LitersToKg(upcv3.reservesParameters.co2Res2ActVol).toFixed(1)+" kg"}}
              </dt>
              <!-- Si Groupe -->
              <dt class="col-md-3 mb-2 text-md-right" *ngIf="groupUpcv3 && upcv3 == null">
                {{co2LitersToKg(groupUpcv3._co2Res1ActVol).toFixed(1)+" kg"}}
              </dt>
              <dt class="col-md-3 mb-2 mb-0 text-md-right" *ngIf="groupUpcv3 && upcv3 == null">
                {{co2LitersToKg(groupUpcv3._co2Res2ActVol).toFixed(1)+" kg"}}
              </dt>
              <!-- FIN SI -->

              <!-- Consumed content -->
              <dd class="col-md-6 mb-0">Contenu à la date d'intervention</dd>
              <dt class="col-md-3 mb-2 text-md-right">
                <span *ngIf="isContentToDateLoading == false">{{ refillTotalConsumedB1.toFixed(2) }} kg</span>
                <i class="fa fa-circle-o-notch fa-spin" *ngIf="isContentToDateLoading"></i>
              </dt>
              <dt class="col-md-3 mb-2 text-md-right">
                <span *ngIf="isContentToDateLoading == false">{{ refillTotalConsumedB2.toFixed(2) }} kg</span>
                <i class="fa fa-circle-o-notch fa-spin" *ngIf="isContentToDateLoading"></i>
              </dt>

              <!-- Added content -->
              <dd class="col-md-6 mb-0">Total ajouté [kg]</dd>
              <dt class="col-md-3 mb-2 text-md-right">
                <input type="number" class="form-control form-control-sm" placeholder="Entrez le total"
                  [disabled]="isSaving" [(ngModel)]="refillTotalAddedB1" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="computeRealAdded();">
              </dt>
              <dt class="col-md-3 mb-2 text-md-right">
                <input type="number" class="form-control form-control-sm" placeholder="Entrez le total"
                  [disabled]="isSaving" [(ngModel)]="refillTotalAddedB2" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="computeRealAdded();">
              </dt>

              <!-- Real content -->
              <dd class="col-md-6 mb-0">Correction du contenu [kg]</dd>
              <dt class="col-md-3 mb-2 text-md-right">
                {{ refillReplaceAllB1 || b1Empty ? -refillTotalConsumedB1?.toFixed(2) : 0 }} kg
              </dt>
              <dt class="col-md-3 mb-2 text-md-right">
                {{ refillReplaceAllB2 || b2Empty ? -refillTotalConsumedB2?.toFixed(2) : 0 }} kg
              </dt>


              <!-- All bottles replaced -->
              <dd class="col-md-6 mb-0">Remplacement de toute la réserve</dd>
              <dt class="col-md-3 mb-2 text-md-right">
                <ui-switch size="small" [disabled]="isSaving" [(ngModel)]="refillReplaceAllB1"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeSwitch();"></ui-switch>
              </dt>
              <dt class="col-md-3 mb-2 text-md-right">
                <ui-switch size="small" [disabled]="isSaving" [(ngModel)]="refillReplaceAllB2"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangeSwitch();"></ui-switch>
              </dt>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- Rapport -->
    <div class="row mt-2">
      <div class="col-sm-12">
        <div class="card bg-light">
          <div class="card-body">
            <div class="form-group mb-0">
              <h5 class="card-title"><label for="rapport">Rapport d'intervention</label></h5>
              <textarea class="form-control" id="rapport" formControlName="rapport" rows="3"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Boutons -->
    <div class="row my-3">
      <div class="col-sm-2">
        <button type="button" class="btn btn-link" disabled>
          <i class="fa fa-plus"></i>&nbsp;
          <i class="fa fa-image" style="font-size : xx-large;"></i>
        </button>
      </div>
      <div class="col-sm-2">
        <button class="btn btn-link" disabled>
          <i class="fa fa-plus"></i>&nbsp;
          <i class="fa fa-video-camera" style="font-size : xx-large;"></i>
        </button>
      </div>
      <div class="col-sm-2">
        <button type="button" class="btn btn-link" disabled>
          <i class="fa fa-plus"></i>&nbsp;
          <i class="fa fa-volume-up" style="font-size: xx-large;"></i>
        </button>
      </div>
      <div class="col-sm-4"></div>
      <div class="col-sm-2">
        <button type="button" class="btn btn-success btn-sm float-right" [disabled]="isSaving || invalidForm()" *ngIf="upcv3"
          (click)="refill()"><span *ngIf="!isSaving">Synchroniser</span>
          <i class="fa fa-circle-o-notch fa-spin" *ngIf="isSaving"></i>
        </button>
        <button type="button" class="btn btn-success btn-sm float-right" [disabled]="isSaving || invalidForm()" *ngIf="groupUpcv3 && upcv3 == null"
          (click)="refillGroup()"><span *ngIf="!isSaving">Synchroniser</span>
          <i class="fa fa-circle-o-notch fa-spin" *ngIf="isSaving"></i>
        </button>
        <button type="button" class="btn btn-success btn-sm float-right" [disabled]="isSaving || invalidForm()" *ngIf="!upcv3 && !groupUpcv3"
          (click)="addIntervention()"><span *ngIf="!isSaving">Sauvegarder</span>
          <i class="fa fa-circle-o-notch fa-spin" *ngIf="isSaving"></i>
        </button>
      </div>
    </div>
  </form>
</div>