import { Component, OnInit, Input, ViewChild, AfterViewInit, SimpleChanges } from '@angular/core';
import { InterventionV3 } from 'src/app/models/upcv3/interventionV3';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { eventType, status } from 'src/app/models/management/bottle';
import { LocationType } from 'src/app/models/management/location';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { map, startWith, switchMap } from 'rxjs/operators';
import { InterventionV3Service } from 'src/app/services/upcv3/interventionV3.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { Site } from 'src/app/models/management/site';
import { InterventionLegacy } from 'src/app/models/upcv3/interventionLegacy';
import { InterventionLegacyService } from 'src/app/services/upcv3/interventionLegacy.service';

type InterventionV3WithMoreInfo = InterventionV3 & {
  bottlesB1Added: number;
  bottlesB1Removed: number;
  bottlesB2Added: number;
  bottlesB2Removed: number;
  bottlesHLAdded: number;
  bottlesHLRemoved: number;
  bottlesPutOutside: number;
  bottlesFromOutside: number;
};

@Component({
  selector: 'app-site-interventions',
  templateUrl: './interventions.component.html',
  styleUrls: [ './interventions.component.css' ],
})
export class InterventionsComponent implements OnInit, AfterViewInit {
  @Input('interventionsOwner') interventionsOwner: Site | UPCV3 | GroupUPCV3;
  @Input() displayedColumns = ["start", "objet", "upc", "b1", "b2", "site", "stock", "rapport"];
  interventionsWithMoreInfo: InterventionV3WithMoreInfo[] = [];
  totalData: number;
  
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<InterventionV3WithMoreInfo>();
  pageSizeOptions = [10, 50, 100];

  interventionsLegacy: InterventionLegacy[] = [];

  constructor(
    public authService: AuthService,
    public router: Router,
    private interventionV3Service: InterventionV3Service,
    private interventionLegacyService: InterventionLegacyService,
  ) { }

  ngOnInit() {
    this.loadLegacyInterventions();
  }

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource<InterventionV3WithMoreInfo>(this.interventionsWithMoreInfo);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.paginator.page.pipe(
      startWith({}),
      switchMap(() => {
        if (this.interventionsOwner instanceof UPCV3) {
          return this.interventionV3Service.getByUpcv3(this.interventionsOwner.id, this.paginator.pageIndex, this.paginator.pageSize);
        } else if (this.interventionsOwner instanceof GroupUPCV3) {
          return this.interventionV3Service.getByGroupUPCV3Id(this.interventionsOwner.id, this.paginator.pageIndex, this.paginator.pageSize);
        } else if (this.interventionsOwner instanceof Site) {
          return this.interventionV3Service.getBySiteId(this.interventionsOwner.id, this.paginator.pageIndex, this.paginator.pageSize);
        }
      }),
      map((data) => {
        if (data == null) return [];
        this.totalData = data.totalElements;
        return data.content;
      })
    ).subscribe((data) => {
      this.interventionsWithMoreInfo = this.computeMoreInfoInterventions(data);
      this.dataSource = new MatTableDataSource<InterventionV3WithMoreInfo>(this.interventionsWithMoreInfo);
    })
  }

  computeMoreInfoInterventions(interventions: InterventionV3[]) {
    return interventions.map(i => {
      const newI: InterventionV3WithMoreInfo = {
        ...i,
        bottlesB1Added: i.bottleEvents.filter(be => be.type == eventType.CONNEXION_A_B1).length,
        bottlesB1Removed: i.bottleEvents.filter(be => be.previousStatus == status.B1).length,
        bottlesB2Added: i.bottleEvents.filter(be => be.type == eventType.CONNEXION_A_B2).length,
        bottlesB2Removed: i.bottleEvents.filter(be => be.previousStatus == status.B2).length,
        bottlesHLAdded: i.bottleEvents.filter(be => be.type == eventType.ENTREPOSAGE && be.nextLocation?.locationType == LocationType.SITE).length,
        bottlesHLRemoved: i.bottleEvents.filter(be => be.previousStatus == status.ENTREPOSE && be.previousLocation.locationType == LocationType.SITE).length,
        bottlesPutOutside: i.bottleEvents.filter(be => be.type == eventType.TRANSIT || (be.type == eventType.ENTREPOSAGE && be.nextLocation?.locationType == LocationType.STOCK)).length,
        bottlesFromOutside: i.bottleEvents.filter(be => be.previousStatus == status.ENTREPOSE && be.previousLocation.locationType == LocationType.STOCK).length,
      }
      return newI;
    });
  }

  loadLegacyInterventions() {
    if (!(this.interventionsOwner instanceof UPCV3)) {
      return;
    }
    this.interventionsLegacy = null;
    this.interventionLegacyService.getByUpcv3(this.interventionsOwner.id).subscribe(res => {
      this.interventionsLegacy = res;
      for (const intervention of this.interventionsLegacy) {
        intervention.bouteilleB1Added = intervention.addedB1 ? Math.floor(intervention.addedB1?.split(',')?.length / 3) : 0;
        intervention.bouteilleB2Added = intervention.addedB2 ? Math.floor(intervention.addedB2?.split(',')?.length / 3) : 0;
      }
    });
  }

  openIntervention(row: InterventionV3WithMoreInfo) {
    this.router.navigateByUrl(`/intervention/v3/${row.id}`);
  }
}