import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Stock } from '../../../models/management/stock';
import { StockService } from '../../../services/management/stock.service';
import { Bottle } from '../../../models/management/bottle';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddBottleModalComponent } from './add-bottle/add-bottle.component';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { ToasterService } from 'angular2-toaster';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ConfirmationModal } from '../../dashboard/confirmationModal/confirmationModal';
import { BottleService } from 'src/app/services/management/bottle.service';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Rack } from 'src/app/models/management/location';
import { RackService } from 'src/app/services/management/rack.service';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html'
})
export class StockComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // NF
  stockId: string;
  stock: Stock;
  ceint: UPCV3;
  bottles: Bottle[];
  displayedColumnsBottles = ['select', 'barcode', 'type', 'state', 'status', 'action'];
  selectedBottles: Bottle[] = [];
  racks: Rack[];
  selectedRacks: Rack[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,

    private stockService: StockService,
    private bottleService: BottleService,
    private toasterService: ToasterService,
    private rackService: RackService,
  ) {
  }

  //TODO : Check if this is used in the project, i think it is not
  loadStock() {
    this.stockService.get(this.stockId).subscribe({
      next: (stock) => {
        this.stock = stock;
        this.bottles = [...stock.bottles];
      },
      error: err => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des informations de stock");
        this.router.navigateByUrl('/stocks');
      }
    })
  }

  ngAfterViewInit() {
    this.route.params.subscribe(params => {
      this.stockId = params['id'];
      this.loadStock();
    });
  }

  /* Modals */
  openAddBottleModal() {
    var addBottleModalRef = this.modalService.open(AddBottleModalComponent, { size: 'lg', keyboard: false, backdrop: 'static' });
    addBottleModalRef.closed.subscribe({
      next: () => {
        this.loadStock();
      }
    });
    addBottleModalRef.componentInstance.init(this.stock, addBottleModalRef, () => { })
  }
  openDeleteBottleModal(bottle: Bottle) {
    alert('fonctionnalité supprimé');
  }

  async openReturnToSupplier() {
    if (this.selectedBottles.length == 0) {
      return;
    }
    const modalRef = this.modalService.open(ConfirmationModal, { size: 'lg' });
    modalRef.componentInstance.title = "Confirmez-vous le renvoi des bouteilles au fournisseur ?";
    modalRef.componentInstance.body =
      `Voulez-vous renvoyer ${this.selectedBottles.length} bouteilles ?`;
    const result = await modalRef.result;
    if (result) {
      const arrayObs: Observable<Bottle | {error: HttpErrorResponse, bottle: Bottle}>[] = [];
      for (const bottle of this.selectedBottles) {
        arrayObs.push(this.bottleService.returnToSupplier(bottle.id).pipe(catchError(error => of({error, bottle}))));
      }
      forkJoin(arrayObs).subscribe(results => {
        let anyError = false;
        for (const res of results) {
          if (!(res instanceof Bottle) && res.error != null) {
            anyError = true;
            const toast = this.toasterService.pop('error', "Impossible de renvoyer au fournisseur", `Une erreur s'est produite lors du renvoi de la bouteille ${res.bottle.barcode}`);
            toast.timeout = 0;
          }
        }
        if (!anyError) {
          this.toasterService.pop('success', "Bouteilles renvoyées avec succès");
        }
        this.loadStock();
      });
    }
  }
}