<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4>
        GDP
        <ng-container *ngIf="gdp">
          <span>- {{ gdp.project.name }} </span>
          <span>- {{ gdp.version.versionTypeToString }} {{ gdp.version.numberOrder }}</span>
        </ng-container>
      </h4>
    </div>

    <!-- Loading -->
    <div class="col-12 mb-3 text-center" *ngIf="!gdp"><i class="fa fa-circle-o-notch fa-spin"></i></div>

    <!-- Tools -->
    <div class="col-lg-9 col-xl-10 offset-lg-3 offset-xl-2 mb-2 d-flex flex-row justify-content-center" *ngIf="gdp">

      <!-- Tools -->
      <div class="btn-group mr-2">

        <!-- Select -->
        <button type="button" class="btn btn-secondary" (click)="setTool('SELECT')" [ngClass]="{ 'active': gdp.tool == 'SELECT' }" placement="bottom" ngbTooltip="Sélectionner">
          <i class="fa fa-mouse-pointer"></i>
        </button>

        <!-- Move -->
        <button type="button" class="btn btn-secondary" (click)="setTool('MOVE')" [ngClass]="{ 'active': gdp.tool == 'MOVE' }" placement="bottom" ngbTooltip="Déplacer">
          <i class="fa fa-arrows-alt"></i>
        </button>

        <!-- Link -->
        <button type="button" class="btn btn-secondary" (click)="setTool('LINK')" [ngClass]="{ 'active': gdp.tool == 'LINK' }" placement="bottom" ngbTooltip="Lier">
          <i class="fa fa-link"></i>
        </button>

        <!-- Delete -->
        <button type="button" class="btn btn-secondary" (click)="setTool('DELETE')" [ngClass]="{ 'active': gdp.tool == 'DELETE' }" placement="bottom" ngbTooltip="Supprimer">
          <i class="fa fa-trash"></i>
        </button>

        <!-- Capture -->
        <button type="button" class="btn btn-secondary" (click)="setTool('CAPTURE')" [ngClass]="{ 'active': gdp.tool == 'CAPTURE' }" placement="bottom" ngbTooltip="Capturer">
          <i class="fa fa-object-group"></i>
        </button>

        <!-- Draw -->
        <button type="button" class="btn btn-secondary" (click)="setTool('DRAW')" [ngClass]="{ 'active': gdp.tool == 'DRAW' }" placement="bottom" ngbTooltip="Dessiner">
          <i class="fa fa-pencil"></i>
        </button>

        <!-- Accept Draw -->
        <button type="button" class="btn btn-success" (click)="acceptDraw()" placement="bottom" ngbTooltip="Valider" *ngIf="gdp.tool == 'DRAW'">
          OK
        </button>

        <!-- Accept Capture -->
        <button type="button" class="btn btn-success" (click)="acceptCapture()" placement="bottom" ngbTooltip="Valider" *ngIf="gdp.tool == 'CAPTURE'">
          OK
        </button>

      </div>

      <!-- Buttons -->
      <div class="btn-group mr-2">

        <!-- Save -->
        <button type="button" class="btn btn-secondary" (click)="save()" placement="bottom" ngbTooltip="Enregistrer">
          <i class="fa fa-save"></i>
        </button>

        <!-- SVG -->
        <button type="button" class="btn btn-secondary font-weight-bold" (click)="svg()" placement="bottom" ngbTooltip="Télécharger le SVG">
          <small>SVG</small>
        </button>

        <!-- Calculations -->
        <button type="button" class="btn btn-secondary font-weight-bold" (click)="calculations()" placement="bottom" ngbTooltip="Calculer les débits">
          <i class="fa fa-calculator"></i>
        </button>

      </div>

    </div>

    <!-- Treeview & Settings -->
    <div class="col-lg-3 col-xl-2 mb-2 pr-lg-0" *ngIf="gdp">
      
      <!-- Treeview -->
      <div class="card mb-2">
        <div class="card-header font-weight-bold px-3 py-2">
          Ceintures
          <i class="fa float-right p-1" [ngClass]="isCollapseTreeview ? 'fa-plus' : 'fa-minus'" (click)="isCollapseTreeview = !isCollapseTreeview" style="cursor: pointer;"></i>
        </div>
        <gdp-treeview [gdp]="gdp" (treeSelectedChange)="onTreeSelectedChange()" *ngIf="!isCollapseTreeview"></gdp-treeview>
      </div>

      <!-- Settings -->
      <div class="card mb-2">
        <div class="card-header font-weight-bold px-3 py-2">
          Paramètres
          <i class="fa float-right p-1" [ngClass]="isCollapseSettings ? 'fa-plus' : 'fa-minus'" (click)="isCollapseSettings = !isCollapseSettings" style="cursor: pointer;"></i>
        </div>
        <gdp-settings [gdp]="gdp" (opacityChange)="onOpacityChange()" *ngIf="!isCollapseSettings"></gdp-settings>
      </div>

    </div>

    <!-- Konva -->
    <div class="col-lg-6 col-xl-8 mb-2" *ngIf="gdp">
      <div #card class="card bg-dark" style="height: 80vh; overflow: hidden;">
        
        <!-- Konva -->
        <gdp-konva
          #gdpKonva
          [gdp]="gdp"
          (nodeClicked)="onNodeClicked($event)"
          (pathClicked)="onPathClicked($event)"
          (contextmenuClicked)="onContextmenuClicked($event)"
          (contextmenuHideRequested)="onContextmenuHideRequested()">
        </gdp-konva>

        <!-- Context menu-->
        <gdp-context-menu
          #gdpContextMenu
          [gdp]="gdp"
          (nodeDeleted)="onNodeDeleted($event)"
          (pathDeleted)="onPathDeleted($event)"
          (protectedAreaPointDeleted)="onProtectedAreaPointDeleted($event)"
          (addNodeRequested)="onAddNodeRequested($event)"
          (addProtectedAreaRequested)="onAddProtectedAreaRequested($event)"
          (addProtectedAreaPointRequested)="onAddProtectedAreaPointRequested($event)">
        </gdp-context-menu>

        <!-- Zoom -->
        <div id="gdp-zoom" class="btn-group-vertical">
          <button type="button" class="btn btn-light" (click)="setZoom(gdp.version.zoom * 1.1)"><i class="fa fa-plus"></i></button>
          <button type="button" class="btn btn-light" (click)="setZoom(gdp.version.zoom / 1.1)"><i class="fa fa-minus"></i></button>
        </div>

        <!-- Rotation -->
        <knob
          [min]="0"
          [max]="360"
          [(ngModel)]="gdp.version.rotation"
          (ngModelChange)="updateRotation()"
          [startDegree]="0"
          [endDegree]="360">
        </knob>
        
      </div>
    </div>

    <!-- Selection -->
    <div class="col-lg-3 col-xl-2 mb-2" *ngIf="gdp">
      <div class="card mb-2">
        <div class="card-header font-weight-bold px-3 py-2">
          Sélection
          <i class="fa float-right p-1" [ngClass]="isCollapseSelection ? 'fa-plus' : 'fa-minus'" (click)="isCollapseSelection = !isCollapseSelection" style="cursor: pointer;"></i>
        </div>
        <gdp-selection
          [gdp]="gdp"
          (nodeChange)="onNodeChange($event)"
          (pathChange)="onPathChange($event)"
          (protectedAreaChange)="onProtectedAreaChange($event)"
          (beltDelete)="onBeltDelete($event)"
          (protectedAreaDelete)="onProtectedAreaDelete($event)"
          *ngIf="!isCollapseSelection">
        </gdp-selection>
      </div>
    </div>

  </div>
</div>