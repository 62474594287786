import { Component, Input, AfterViewInit, HostListener, Output, EventEmitter } from "@angular/core";
import { GDP, Tool } from "src/app/models/gdp/gdp";
import { Path } from "src/app/models/gdp/path";
import { Node, Type as NodeType } from "src/app/models/gdp/node";
import { ProtectedArea } from "src/app/models/gdp/protectedArea";

declare const Konva: any;

@Component({
  selector: 'gdp-konva',
  templateUrl: './konva.component.html'
})
export class KonvaComponent implements AfterViewInit {
  // NF
  @Input('gdp') gdp: GDP;
  @Output('nodeClicked') nodeClicked = new EventEmitter<string>();
  @Output('pathClicked') pathClicked = new EventEmitter<string>();
  @Output('contextmenuClicked') contextmenuClicked = new EventEmitter<any>();
  @Output('contextmenuHideRequested') contextmenuHideRequested = new EventEmitter();

  // Konva
  public stage: any;
  public backgroundImageLayer: any;
  public beltsLayer: any;
  public protectedAreaLayer: any;
  public drawLayer: any;

  // Background Image
  public backgroundImage: any;

  // Markers
  private controlMarker: any;
  private hexagonalTrapMarker: any;
  private rectangularTrapMarker: any;

  // Capture
  public captureRect: any;
  public captureTransformer: any;

  // Textarea
  private textarea: any;

  /*
   * Init
   */
  ngAfterViewInit() {
    this.initKonva();
    this.initBackgroundImage();
    this.initBelts();
    this.initZoom();
    this.initCapture();
  }
  initKonva() {
    this.stage = new Konva.Stage({ container: 'konva', width: 800, height: 600, draggable: true });
    this.stage.on('dragstart', () => {
      
      // Hide context menu
      this.contextmenuHideRequested.emit();

      // Hide textarea
      if (this.textarea) {
        document.body.removeChild(this.textarea);
        this.textarea = null;
      }

    });
    this.stage.on('mousemove', () => {
      if (this.gdp.tool == Tool.LINK && this.gdp.linkFromNode && !this.gdp.linkToNode) this.updateDrawLayer();
      else if (this.gdp.tool == Tool.DRAW && this.gdp.drawPoints.length > 0) this.updateDrawLayer();
    });
    this.stage.on('click', (e: any) => {
      if (this.gdp.tool == Tool.DRAW) {
        var point = this.rotate(
          0, 0,
          (this.stage.getPointerPosition().x - this.stage.x()) / this.stage.scaleX(),
          (this.stage.getPointerPosition().y - this.stage.y()) / this.stage.scaleX(),
          this.stage.rotation()
        );
        point.x += this.stage.offsetX();
        point.y += this.stage.offsetY();

        // Add draw point
        this.gdp.drawPoints.push({ x: point.x, y: point.y });

        // Update draw layer
        this.updateDrawLayer();

      }
    });
    this.backgroundImageLayer = new Konva.Layer();
    this.protectedAreaLayer = new Konva.Layer();
    this.beltsLayer = new Konva.Layer();
    this.drawLayer = new Konva.Layer();
    this.stage.add(this.backgroundImageLayer);
    this.stage.add(this.protectedAreaLayer);
    this.stage.add(this.beltsLayer);
    this.stage.add(this.drawLayer);

    this.backgroundImageLayer.setZIndex(0);
    this.protectedAreaLayer.setZIndex(1);
    this.beltsLayer.setZIndex(2);
    this.drawLayer.setZIndex(2);

    this.beltsLayer.add(new Konva.Group({ id: 'paths' }));
    this.beltsLayer.add(new Konva.Group({ id: 'nodes' }));

    this.onResize();
  }
  initBackgroundImage() {
    this.gdp.backgroundImage.onload = () => {
      this.backgroundImage = new Konva.Image({ image: this.gdp.backgroundImage });

      this.backgroundImage.filters([Konva.Filters.RGBA]);
      this.backgroundImage.red(211);
      this.backgroundImage.blue(211);
      this.backgroundImage.green(211);
      this.backgroundImage.alpha(this.gdp.version.opacity / 100);

      this.backgroundImage.on('contextmenu', (e: any) => {
        e.evt.preventDefault();
        this.contextmenuClicked.emit({ x: this.stage.getPointerPosition().x, y: this.stage.getPointerPosition().y, item: null });
      });
      this.backgroundImage.cache();
      this.backgroundImageLayer.add(this.backgroundImage);

      this.stage.offsetX(this.gdp.backgroundImage.width / 2);
      this.stage.offsetY(this.gdp.backgroundImage.height / 2);
      this.stage.position({ x: this.stage.width() / 2, y: this.stage.height() / 2 });
      this.stage.batchDraw();
    }
  }
  initBelts() {
    /// Load images
    // Control
    this.gdp.controlMarker.onload = () => {
      this.controlMarker = new Konva.Image({ name: 'node', image: this.gdp.getNodeTypeMarker(NodeType.CONTROL) });
      this.controlMarker.cache();

      // Try create belts
      if (this.controlMarker && this.hexagonalTrapMarker && this.rectangularTrapMarker) {
        this.gdp.version.belts.forEach(belt => {
          belt.paths.forEach(path => this.addPath(path));
          belt.nodes.forEach(node => this.addNode(node));
          belt.protectedAreas.forEach(protectedArea => this.addProtectedArea(protectedArea));
        });
      }
    }

    // Hexagonal Trap
    this.gdp.hexagonalTrapMarker.onload = () => {
      this.hexagonalTrapMarker = new Konva.Image({ name: 'node', image: this.gdp.getNodeTypeMarker(NodeType.HEXAGONAL_TRAP) });
      this.hexagonalTrapMarker.cache();

      // Try create belts
      if (this.controlMarker && this.hexagonalTrapMarker && this.rectangularTrapMarker) {
        this.gdp.version.belts.forEach(belt => {
          belt.paths.forEach(path => this.addPath(path));
          belt.nodes.forEach(node => this.addNode(node));
          belt.protectedAreas.forEach(protectedArea => this.addProtectedArea(protectedArea));
        });
      }
    }

    // Rectangular Trap
    this.gdp.rectangularTrapMarker.onload = () => {
      this.rectangularTrapMarker = new Konva.Image({ name: 'node', image: this.gdp.getNodeTypeMarker(NodeType.RECTANGULAR_TRAP) });
      this.rectangularTrapMarker.cache();

      // Try create belts
      if (this.controlMarker && this.hexagonalTrapMarker && this.rectangularTrapMarker) {
        this.gdp.version.belts.forEach(belt => {
          belt.paths.forEach(path => this.addPath(path));
          belt.nodes.forEach(node => this.addNode(node));
          belt.protectedAreas.forEach(protectedArea => this.addProtectedArea(protectedArea));
        });
      }
    }
  }
  initZoom() {
    var scaleBy = 1.1;
    this.stage.on('wheel', (e: any) => {
      e.evt.preventDefault();
      var oldScale = this.stage.scaleX();
      var mousePointTo = {
        x: this.stage.getPointerPosition().x / oldScale - this.stage.x() / oldScale,
        y: this.stage.getPointerPosition().y / oldScale - this.stage.y() / oldScale
      };
      var newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      this.stage.scale({ x: newScale, y: newScale });
      this.stage.position({ x: -(mousePointTo.x - this.stage.getPointerPosition().x / newScale) * newScale, y: -(mousePointTo.y - this.stage.getPointerPosition().y / newScale) * newScale });
      this.stage.batchDraw();
      this.gdp.version.zoom = newScale;
      this.contextmenuHideRequested.emit();
    });
  }
  initCapture() {

    // Create rectagle with transformer
    this.captureRect = new Konva.Rect({
      width: 200,
      height: 100,
      fill: 'grey',
      opacity: 0.5,
      draggable: true,
      rotation: 360 - this.gdp.version.rotation,
      visible: false
    });
    this.drawLayer.add(this.captureRect);

    this.captureTransformer = new Konva.Transformer({
      rotateEnabled: false,
      visible: false
    });
    this.captureTransformer.attachTo(this.captureRect);
    this.drawLayer.add(this.captureTransformer);

  }


  /*
   * Events
   */
  // Resize
  @HostListener('window:resize')
  onResize() {
    this.stage.setWidth((<any> document.getElementById('konva').parentNode.parentNode).offsetWidth);
    this.stage.setHeight((<any> document.getElementById('konva').parentNode.parentNode).offsetHeight);
    this.updateRotation();
  }


  /*
   * Dragging
   */
  toogleDragging(enabled: boolean): void {
    if (this.stage) {
      this.stage.find('.node').forEach(image => image.draggable(enabled));
      this.stage.find('.nodeTitle').forEach(label => label.draggable(enabled));
    }
  }


  /*
   * Shadow
   */
  disableShadow(): void {
    if (this.stage) {
      this.stage.find('.node').forEach(image => image.shadowEnabled(false));
      this.stage.find('.path').forEach(group => group.find('Line').shadowEnabled(false));
      this.beltsLayer.batchDraw();
    }
  }
  toogleShadow(id: string, enabled: boolean): void {
    if (this.stage) {
      var elem = this.stage.find('#' + id)[0];
      if (elem.getName() == 'path') elem.find('Line').shadowEnabled(enabled);
      else elem.shadowEnabled(enabled);
      this.beltsLayer.batchDraw();
    }
  }

  /*
   * Capture
   */
  toogleCapture(enabled: boolean): void {
    this.captureRect.visible(enabled);
    this.captureTransformer.visible(enabled);
    this.drawLayer.batchDraw();
  }


  /*
   * Methods
   */
  public addPath(path: Path): void {

    // Create a Group containing: Line & Label
    var group = new Konva.Group({
      id: path.id,
      name: 'path'
    });

    var line = new Konva.Line({
      points: [ path.from.x, path.from.y, path.to.x, path.to.y ],
      stroke: path.color,
      strokeWidth: path.width,
      dash: [ path.width, path.width * 2 ],
      dashEnabled: path.dashed,
      shadowColor: 'yellow',
      shadowEnabled: false,
      shadowBlur: 10,
      perfectDrawEnabled: false
    });
    group.add(line);

    var label = new Konva.Label({
      x: path.midpoint.x,
      y: path.midpoint.y
    });
    label.add(new Konva.Tag({
      fill: 'black'
    }));
    label.add(new Konva.Text({
      text: (this.gdp.project.pixelsPerMeter * path.distance).toFixed(1),
      fill: 'white',
      fontSize: path.width,
      padding: path.width / 3,
      perfectDrawEnabled: false,
      listening: false
    }));
    label.offsetX(label.width() / 2);
    label.offsetY(label.height() / 2);
    label.rotation(360 - this.gdp.version.rotation);
    group.add(label);


    // Add listeners on Group
    group.on('click', () => this.pathClicked.emit(path.id));
    group.on('contextmenu', (e: any) => {
      e.evt.preventDefault();
      this.contextmenuClicked.emit({
        x: this.stage.getPointerPosition().x,
        y: this.stage.getPointerPosition().y,
        item: path
      });
    });


    // Add Group to the paths & draw it
    this.beltsLayer.find('#paths').add(group);
    this.beltsLayer.batchDraw();
    
  }
  public addNode(node: Node): void {

    // Create node title for traps only
    if (node.type == NodeType.HEXAGONAL_TRAP || node.type == NodeType.RECTANGULAR_TRAP) {

      // Create a label
      var label = new Konva.Label({
        id: 't_' + node.id,
        name: 'nodeTitle',
        x: node.titleX,
        y: node.titleY,
        draggable: this.gdp.tool == Tool.MOVE
      });
      label.add(new Konva.Tag({ fill: node.titleBackgroundColor }));
      label.add(new Konva.Text({
        text: node.title,
        fontFamily: 'Helvetica Neue',
        fill: node.titleColor,
        padding: node.titleSize / 3,
        fontSize: node.titleSize,
        perfectDrawEnabled: false,
        listening: false
      }));
      label.offsetX(label.width() / 2);
      label.offsetY(label.height() / 2);
      label.rotation(360 - this.gdp.version.rotation);


      // Add listeners to this label to handle edition
      label.on('dragend', () => this.updateNodeTitlePos(node.id, label.getX(), label.getY()));
      label.on('dblclick', () => {
        if (this.textarea != null) return;

        // Create textarea over canvas with absolute position
        var textPosition = label.getAbsolutePosition(),
            stageBox = this.stage.container().getBoundingClientRect(),
            areaPosition = {
              x: stageBox.left + textPosition.x,
              y: stageBox.top + textPosition.y
            };

        // Create textarea and style it
        this.textarea = document.createElement('textarea');
        document.body.appendChild(this.textarea);
        this.textarea.value = node.title;
        this.textarea.style.position = 'absolute';
        this.textarea.style.top = areaPosition.y + 'px';
        this.textarea.style.left = areaPosition.x + 'px';
        this.textarea.style.width = label.width();
        this.textarea.focus();
        this.textarea.setSelectionRange(0, node.title.length);

        this.textarea.addEventListener('keydown', (e: any) => {
          // hide on enter
          if (e.keyCode === 13) {

            // NF
            node.title = this.textarea.value;

            // UI
            label.find('Text')[0].text(this.textarea.value);
            this.beltsLayer.batchDraw();
            document.body.removeChild(this.textarea);
            this.textarea = null;

          }
        });
      });
      

      // Add label to the nodes & draw it
      this.beltsLayer.find('#nodes').add(label);
      label.draw();

    }


    // Create image for control & traps only
    if (node.type == NodeType.CONTROL || node.type == NodeType.HEXAGONAL_TRAP || node.type == NodeType.RECTANGULAR_TRAP) {

      // Create image
      var image;
      switch (node.type) {
        case NodeType.CONTROL: image = this.controlMarker.clone(); break;
        case NodeType.HEXAGONAL_TRAP: image = this.hexagonalTrapMarker.clone(); break;
        case NodeType.RECTANGULAR_TRAP: image = this.rectangularTrapMarker.clone(); break;
      }
      
      // Check image
      if (image) {
        image.id(node.id);
        image.position({ x: node.x, y: node.y });
        image.width(node.iconSize);
        image.height(node.iconSize);
        image.draggable(this.gdp.tool == Tool.MOVE);
        image.shadowColor('yellow');
        image.shadowEnabled(node.selected);
        image.shadowBlur(10);
        image.offsetX(node.iconSize / 2);
        image.offsetY(node.iconSize / 2);
        image.rotation(360 - this.gdp.version.rotation);
        image.perfectDrawEnabled(false);

        // Add listeners to edit the node
        image.on('click', () => this.nodeClicked.emit(node.id));
        image.on('contextmenu', (e: any) => {
          e.evt.preventDefault();
          this.contextmenuClicked.emit({ x: this.stage.getPointerPosition().x, y: this.stage.getPointerPosition().y, item: node });
        });
        image.on('dragend', () => this.updateNodePos(node.id, image.getX(), image.getY()));
        

        // Add image to nodes & draw it
        this.beltsLayer.find('#nodes').add(image);
        image.draw();

      }

    }


    // Create circle for intermediate nodes
    if (node.type == NodeType.INTERMEDIATE) {

      // Create a circle
      var circle = new Konva.Circle({
        id: node.id,
        name: 'node',
        x: node.x,
        y: node.y,
        radius: node.iconSize / 2,
        fill: node.titleBackgroundColor,
        draggable: this.gdp.tool == Tool.MOVE,
        shadowColor: 'yellow',
        shadowEnabled: node.selected,
        shadowBlur: 10
      });


      // Add listeners to edit the node
      circle.on('click', () => this.nodeClicked.emit(node.id));
      circle.on('contextmenu', (e: any) => {
        e.evt.preventDefault();
        this.contextmenuClicked.emit({ x: this.stage.getPointerPosition().x, y: this.stage.getPointerPosition().y, item: node });
      });
      circle.on('dragend', () => this.updateNodePos(node.id, circle.getX(), circle.getY()));


      // Add circle to the nodes & draw it
      this.beltsLayer.find('#nodes').add(circle);
      circle.draw();

    }

  }
  public addProtectedArea(protectedArea: ProtectedArea) {

    // Create a Group containing: Line & Circles
    var group = new Konva.Group({ id: protectedArea.id, name: 'protected-area' });

    var points: number[] = [];
    protectedArea.points.forEach(point => { points.push(point.x), points.push(point.y) });
    var line = new Konva.Line({
      points: points,
      stroke: 'yellow',
      strokeWidth: protectedArea.id == this.gdp.version.selectedProtectedArea ? 20 : 0,
      closed: true,
      perfectDrawEnabled: false,
      listening: false
    });
    line.fillPatternScale({ x: 0.1, y: 0.1 });
    line.fillPatternImage(this.getPattern(protectedArea.width * 10, protectedArea.color));
    group.add(line);

    if (protectedArea.id == this.gdp.version.selectedProtectedArea) {
      protectedArea.points.forEach((point, index) => {
        var circle = new Konva.Circle({
          x: point.x,
          y: point.y,
          radius: this.gdp.version.defaultNodeIconSize / 3,
          fill: 'yellow',
          draggable: true
        });

        // Add listeners to this circle to handle edition
        circle.on('contextmenu', (e: any) => {
          e.evt.preventDefault();
          this.contextmenuClicked.emit({
            x: this.stage.getPointerPosition().x,
            y: this.stage.getPointerPosition().y,
            item: { protectedArea: protectedArea, index: index }
          });
        });
        circle.on('dragend', () => this.updateProtectedAreaPointPos(protectedArea.id, index, circle.getX(), circle.getY()));
        
        group.add(circle);
      });
    }


    // Add to layer & draw it
    this.protectedAreaLayer.add(group);
    group.draw();

  }

  private getPath(id: string): any {
    return this.stage.find('#' + id)[0];
  }
  private getNode(id: string): any {
    return this.stage.find('#' + id)[0];
  }
  private getNodeTitle(id: string): any {
    return this.stage.find('#t_' + id)[0];
  }
  private getProtectedArea(id: string): any {
    return this.stage.find('#' + id)[0];
  }

  public updateNode(node: Node): void {
    this.delNode(node.id);
    this.addNode(node);
  }
  public updatePath(path: Path): void {

    // Update path
    var group = this.getPath(path.id);
    if (group) {
      
      // Update line
      var line = group.find('Line');
      if (line) {
        line.setStroke(path.color);
        line.setStrokeWidth(path.width);
        line.setDashEnabled(path.dashed);
        line.dash([path.width, path.width * 2]);
      }

      // Update label
      var label = group.find('Label');
      if (label) {
        label.x(path.midpoint.x);
        label.y(path.midpoint.y);

        var text = group.find('Text');
        if (text) {
          text.text((this.gdp.project.pixelsPerMeter * path.distance).toFixed(1));
          text.setFontSize(path.width);
          text.setPadding(path.width / 3);
        }
      }

    }

    // Update UI
    this.beltsLayer.batchDraw();

  }
  public updateProtectedArea(protectedArea): void {
    this.delProtectedArea(protectedArea.id);
    this.addProtectedArea(protectedArea);
  }

  public delNode(id: string) {
    var image = this.getNode(id);
    if (image) image.destroy();

    var label = this.getNodeTitle(id);
    if (label) label.destroy();

    this.beltsLayer.batchDraw();
  }
  public delPath(id: string) {
    var group = this.getPath(id);
    if (group) group.destroy();
    this.beltsLayer.batchDraw();
  }
  public delProtectedArea(id: string) {
    var group = this.getProtectedArea(id);
    if (group) group.destroy();
    this.protectedAreaLayer.batchDraw();
  }

  public updateRotation() {
    this.stage.rotation(this.gdp.version.rotation);

    // Paths
    this.stage.find('.path').forEach(group => group.find('Label').rotation(360 - this.gdp.version.rotation));

    // Nodes
    this.stage.find('.node').forEach(image => image.rotation(360 - this.gdp.version.rotation));

    // Node Titles
    this.stage.find('.nodeTitle').forEach(label => label.rotation(360 - this.gdp.version.rotation));

    // Capture
    if (this.captureRect) this.captureRect.rotation(360 - this.gdp.version.rotation);

    this.stage.batchDraw();
  }
  public updateDrawLayer(tool: Tool = null) {
    if (tool == null) tool = this.gdp.tool;

    // LINK
    if (tool == Tool.LINK) {
      var linkLine = this.stage.find('.draw')[0];

      // Create / Update link
      if (this.gdp.linkFromNode) {
        var to = this.rotate(
          0,
          0,
          (this.stage.getPointerPosition().x - this.stage.x()) / this.stage.scaleX(),
          (this.stage.getPointerPosition().y - this.stage.y()) / this.stage.scaleX(),
          this.stage.rotation()
        );
        to.x += this.stage.offsetX();
        to.y += this.stage.offsetY();

        // Create
        if (!linkLine) {
          var from = this.gdp.getNode(this.gdp.linkFromNode);
          linkLine = new Konva.Line({
            name: 'draw',
            points: [ from.x, from.y, to.x, to.y ],
            stroke: 'red', strokeWidth: this.gdp.version.defaultPathWidth
          });
          this.drawLayer.add(linkLine);
        }

        // Update
        else {
          var points = linkLine.points();
          points[2] = to.x;
          points[3] = to.y;
          linkLine.points(points);
        }

      }

      // Remove Link
      else if (linkLine) linkLine.destroy();
    }


    // DRAW
    else if (tool == Tool.DRAW) {
      var drawLine = this.stage.find('.draw-line')[0];

      // Get points
      var drawPoints: number[] = [];
      this.gdp.drawPoints.forEach(point => drawPoints.push(point.x, point.y));

      // Create / Update path
      if (drawPoints.length > 0) {

        // Create
        if (!drawLine) {
          drawLine = new Konva.Line({
            name: 'draw-line',
            points: drawPoints,
            stroke: 'red', strokeWidth: this.gdp.version.defaultPathWidth
          });
          this.drawLayer.add(drawLine);
        }

        // Update
        else {
          // Add pointer position
          var pointerPos = this.rotate(0, 0, (this.stage.getPointerPosition().x - this.stage.x()) / this.stage.scaleX(), (this.stage.getPointerPosition().y - this.stage.y()) / this.stage.scaleX(), this.stage.rotation());
          pointerPos.x += this.stage.offsetX();
          pointerPos.y += this.stage.offsetY();
          drawPoints.push(pointerPos.x, pointerPos.y);

          drawLine.points(drawPoints);
        }

      }

      // Remove path
      else if (drawLine) drawLine.destroy();
    }

    // CAPTURE
    else if (tool == Tool.CAPTURE) {

    }


    this.drawLayer.batchDraw();
  }
  public updateOpacity() {
    this.backgroundImage.alpha(this.gdp.version.opacity / 100);
    this.stage.batchDraw();
  }

  /*
   * Other functions
   */
  private updateNodePos(id: string, x: number, y: number): void {
    x = Math.round(x), y = Math.round(y);

    // NF
    var node = this.gdp.updateNodePosition(id, x, y);

    /// UI
    // Nodes
    var image = this.getNode(id);
    if (image) image.x(node.x), image.y(node.y);
    
    // Nodes title
    var label = this.getNodeTitle(id);
    if (label) label.x(node.titleX), label.y(node.titleY);
    
    // Paths
    this.gdp.getPathsReferencesNode(id).forEach(path => {
      var group = this.getPath(path.id);
      if (group) {
        group.find('Line').points([ path.from.x, path.from.y, path.to.x, path.to.y ]);
        group.find('Label').x(path.midpoint.x);
        group.find('Label').y(path.midpoint.y);
        group.find('Text').text((this.gdp.project.pixelsPerMeter * path.distance).toFixed(1));
      }
    });
    
    // Update UI
    this.beltsLayer.batchDraw();
  }
  private updateNodeTitlePos(id: string, x: number, y: number): void {
    x = Math.round(x), y = Math.round(y);

    // NF
    var node = this.gdp.updateNodeTitlePosition(id, x, y);

    // UI
    var label = this.getNodeTitle(id);
    if (label) label.x(node.titleX), label.y(node.titleY);
    this.beltsLayer.batchDraw();
  }
  private updateProtectedAreaPointPos(id: string, index: number, x: number, y: number): void {
    x = Math.round(x), y = Math.round(y);

    // NF
    var protectedArea = this.gdp.updateProtectedAreaPointPosition(id, index, x, y);

    // UI
    this.updateProtectedArea(protectedArea);
  }

  public getPattern(width, color) {
    if (!width) return null;

    var height = width / 2,
        lineWidth1 = 4 * width / 200,
        lineWidth2 = 2 * width / 200;

    var canvas = document.createElement('canvas');

    canvas.width = width;
    canvas.height = height;

    var x0 = width + (width / 2), x1 = - (width / 2),
        y0 = - (height / 2), y1 = height + (height / 2),

        offset = height / 8.14,
        offset2 = height + height / 1.14,
        offset3 = height + height;

    var context = canvas.getContext('2d');
    context.save();

    context.translate(width / 2, height / 2);
    context.strokeStyle = color;

    context.lineWidth = lineWidth1;
    context.beginPath();
    context.translate(0.5,0.5);
    context.moveTo(x0 - offset2 - width / 2, y0 - height / 2);
    context.lineTo(x1 - offset2 - width / 2, y1 - height / 2);
    context.stroke();
    context.moveTo(x0 + offset2 - width / 2, y0 - height / 2);
    context.lineTo(x1 + offset2 - width / 2, y1 - height / 2);
    context.stroke();
    context.moveTo(x0 - offset - width / 2, y0 - height / 2);
    context.lineTo(x1 - offset - width / 2, y1 - height / 2);
    context.stroke();
    context.moveTo(x0 + offset - width / 2, y0 - height / 2);
    context.lineTo(x1 + offset - width / 2, y1 - height / 2);
    context.stroke();

    context.lineWidth = lineWidth2;
    context.moveTo(x0 - width / 2, y0 - height / 2);
    context.lineTo(x1 - width / 2, y1 - height / 2);
    context.stroke();

    context.moveTo(x0 - offset3 - width / 2, y0 - height / 2);
    context.lineTo(x1 - offset3 - width / 2, y1 - height / 2);
    context.stroke();
    context.moveTo(x0 + offset3 - width / 2, y0 - height / 2);
    context.lineTo(x1 + offset3 - width / 2, y1 - height / 2);
    context.stroke();

    context.restore();

    return canvas;
  }

  public rotate(cx, cy, x, y, angle): any {
    var radian = (Math.PI / 180) * angle,
        cos = Math.cos(radian),
        sin = Math.sin(radian),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    
    return {
      x: Math.round(nx),
      y: Math.round(ny)
    };
  }

}