import { Component, OnInit } from '@angular/core';
import {UPCV3Service} from '../../../services/upcv3/upcv3.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-upcnoinit',
  templateUrl: './upcnoinit.component.html',
  styleUrls: ['./upcnoinit.component.css']
})
export class UpcnoinitComponent implements OnInit {
  upcnnoinit: UPCV3[];
  constructor(private upcv3Service : UPCV3Service,
              private router : Router, private toasterService: ToasterService) { }

  ngOnInit() {
      this.upcv3Service.getUninitied().subscribe({
        next: (res) => this.upcnnoinit = res,
        error: (err) => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des UPC non intilialisé"),
      })
  }

  onTestUPC() {
    this.router.navigate(['tests']);
  }

}
