import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from "typedjson";
import { Stock } from "./stock";

@jsonObject
export class BottleType {

  @jsonMember
  archived: boolean;

  @jsonMember
  id: number;

  @jsonMember
  contenue: number;

  @jsonMember
  designation: string;

  @jsonMember
  brand: string;

  @jsonMember
  isRembo: boolean;

  @jsonArrayMember(() => Stock)
  authorizedStock: Stock[];

  get designationString(): string {
    const stringDesignation = this.designation ? `(${this.designation})` : '';
    return `${this.contenue} kg ${stringDesignation} - ${this.brand}`
  }

  static loadFromJSON(json): BottleType {
    const serializer = new TypedJSON(BottleType);
    return serializer.parse(json);
  }
}