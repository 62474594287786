import { Component } from '@angular/core';
import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { FirmwareService } from 'src/app/services/upcv3/firmware.service';
import { Modal } from 'src/app/models/ui/modal';

@Component({
  selector: 'delete-firmware-content',
  templateUrl: './delete-firmware.component.html'
})
export class DeleteFirmwareModalComponent extends Modal<string> {
  // Form
  submitted = false;

  constructor(
    private router: Router,

    private toasterService: ToasterService,
    private firmwareService: FirmwareService
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => { this.close() });

  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.firmwareService.delete(this.subject).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }

}