<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-12 mb-3 text-center" *ngIf="!group">
      <i class="fa fa-circle-o-notch fa-spin"></i>
    </div>
  </div>

  <div class="row">
    <!-- Title -->
    <div class="col-12 mb-2" *ngIf="group != null">
      <h4 class="float-left">Groupe - {{ group.name }}</h4>

      <ng-container>
        <button
          type="button"
          class="btn btn-primary float-right"
          (click)="immediatePoll()"
        >
          <i class="fa fa-refresh"></i> Poll immédiat de toutes les ceintures du
          groupe
        </button>
      </ng-container>
    </div>
  </div>

  <div class="row" *ngIf="site != null">
    <div class="col-12">
      <span class="font-weight-bold">{{ site.name }}</span> :
      <span>{{ site.address }}</span>
    </div>
  </div>
  <!-- Status -->
  <div class="row" *ngIf="group != null">
    <div class="col-12 mb-3" >
      <div class="card">
        <div class="table-responsive">
          <!-- UPC-V3-->
          <table class="table table-sm text-center mb-0">
            <thead>
              <tr>
                <th rowspan="3">UPC</th>
                <th rowspan="3">Pièges</th>
                <th rowspan="3">Mode diff.</th>
                <th colspan="9">Reserves de CO2</th>
                <th rowspan="3">Dernier pool</th>
                <th rowspan="3">Pool actif</th>
                <th rowspan="3">Hivernage</th>
              </tr>
              <tr>
                <th rowspan="2">Reserve active</th>
                <th colspan="2">B1</th>
                <th colspan="2">B2</th>
                <!--<th colspan="4">Dern. remplissage</th>-->
              </tr>
              <tr>
                <th>Cont. calc.</th>
                <th>Durée est.</th>
                <th>Cont. calc.</th>
                <th>Durée est.</th>
                <th colspan="4">Commentaires</th>
                <!--<th>Date B1</th>
                <th>Cont. B1</th>
                <th>Date B2</th>
                <th>Cont. B2</th>-->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let upc of group.upcv3s"
                [ngClass]="{ 'text-primary': upc.hibernated }"
              >
                <td>
                  <a routerLink="/upc/v3/{{ upc.id }}">{{ upc.upcNameId }}</a>
                </td>
                <td>{{ upc.generalParameters.upcTrapNum }}</td>
                <td [ngClass]="upc.upcStatusClass">
                  {{ upc.upcStatusString }}
                </td>
                <td>{{ upc.co2ResActiveString }}</td>
                <td [ngClass]="upc.co2Res1ActVolClass">
                  {{
                    this.co2LitersToKg(
                      upc.reservesParameters.co2Res1ActVol
                    ).toFixed(1)
                  }}
                  kg
                </td>
                <td [ngClass]="upc.co2Res1ActVolClass">
                  {{ upc.reservesParameters.co2Res1ActDur }} jours
                </td>
                <td [ngClass]="upc.co2Res2ActVolClass">
                  {{
                    this.co2LitersToKg(
                      upc.reservesParameters.co2Res2ActVol
                    ).toFixed(1)
                  }}
                  kg
                </td>
                <td [ngClass]="upc.co2Res2ActVolClass">
                  {{ upc.reservesParameters.co2Res2ActDur }} jours
                </td>
                <td colspan="4">
                  <p-button
                      *ngIf="upc.lastComment != null; else noComment"
                      [badge]="this.formatLastCommentStringForUpc(upc, '').date"
                      badgeClass="bigger"
                      [label]="upc.lastComment.message"
                      (click)="onOpenCommentModal(upc, null)"
                      [style]="{ width: '100%' }"
                      [styleClass]="
                        'p-button-sm center max-width small-text p-button-' +
                        this.formatLastCommentStringForUpc(upc, '').class
                      "
                    >
                    </p-button>
                    <ng-template #noComment>
                      <p-button
                        badgeClass="bigger"
                        icon="pi pi-plus"
                        iconPos="center"
                        (click)="onOpenCommentModal(upc, null)"
                        [style]="{ width: '100%' }"
                        styleClass="center max-width p-button-raised p-button-outlined"
                      ></p-button>
                    </ng-template>
                </td>
                

                <td
                  placement="top"
                  [ngClass]="upc.upcLastPollDatetimeClass"
                  ngbTooltip="{{
                    upc.upcLastPollDatetime ? upc.upcLastPollDatetime : '-'
                  }}"
                  container="body"
                >
                  {{ upc.upcLastPollDatetimeString }}
                </td>
                <td
                  [ngClass]="upc.upcPollEnableClass"
                  (click)="togglePoll(upc, !upc.upcPollEnable)"
                  style="cursor: pointer"
                >
                  {{ upc.upcPollEnable ? "Oui" : "Non" }}
                </td>
                <td
                  (click)="toogleHibernated(upc, !upc.hibernated)"
                  style="cursor: pointer"
                >
                  {{ upc.hibernated ? "Oui" : "Non" }}
                </td>
              </tr>
              <tr>
                <td colspan="16"></td>
              </tr>

              <tr class="table-info">
                <td><b>Total</b></td>
                <td>{{ getTotalTrap() }}</td>
                <td></td>
                <td></td>
                <td>{{ getTotalCo2Res1ActVol() }} Kg</td>
                <td>{{ getTotalCo2Res1ActDur() }} jours</td>
                <td>{{ getTotalCo2Res2ActVol() }} Kg</td>
                <td>{{ getTotalCo2Res2ActDur() }} jours</td>
                <td colspan="4"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->

  <!-- New -->
  <div class="row">
    <div class="col-12 mb-3" *ngIf="group">
      <hr />
      <ul ngbNav #nav="ngbNav" class="nav-pills nav-fill">
        <li ngbNavItem id="allUpcTab">
          <a ngbNavLink class="all-upc-tab">
            <div class="container-grid">
              <b>Toutes les ceintures</b>
              <span><i class="right-align fa fa-angle-double-right"></i></span>
            </div>
          </a>
          <ng-template ngbNavContent>
            <hr />
            <ul ngbNav #navAllUpc="ngbNav" class="nav-tabs">
              <!-- Status CO2 -->
              <li ngbNavItem>
                <a ngbNavLink>Status CO2</a>
                <ng-template ngbNavContent>
                  <app-group-upc-parameters
                    [group]="group"
                  ></app-group-upc-parameters>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink>Interventions</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-12 my-2">
                      <button type="button" class="btn btn-primary float-right" (click)="openAddInterventionModal()"><i class="fa fa-plus mr-1"></i>Intervention</button>
                    </div>  
                  </div>
                  <app-site-interventions [interventionsOwner]="group"></app-site-interventions>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink>Statistiques</a>
                <ng-template ngbNavContent>
                  <app-upc-group-stats [group]="group"></app-upc-group-stats>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink>Alarmes</a>
                <ng-template ngbNavContent>
                  <p class="my-3 text-center">
                    <small class="text-muted mr-2"
                      >Dernière mise à jour des variables :
                      <b>{{ group.upcv3s[0].upcLastSyncDatetime.toLocaleString("fr") }}</b></small
                    >
                    <button
                      type="button"
                      class="btn btn-success btn-sm"
                      (click)="syncBelts()"
                      [disabled]="isSync"
                    >
                      Synchroniser
                    </button>
                    <ng-container *ngIf="needSync">
                      <br />
                      <small class="text-warning"
                        >Des variables sont en attente de synchronisation</small
                      >
                    </ng-container>
                  </p>
                  <app-parameterbelts-alarms
                    [isSync]="isSync"
                    [vars]="alarmVariables"
                  ></app-parameterbelts-alarms>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="navAllUpc"></div>
          </ng-template>
        </li>
        <li ngbNavItem *ngFor="let upc of group.upcv3s">
          <a ngbNavLink>{{ upc.upcNameId }}</a>
          <ng-template ngbNavContent>
            <hr />
            <ul ngbNav #navChild="ngbNav" class="nav-tabs">
              <!-- Configuration -->
              <li ngbNavItem *ngIf="upc">
                <a ngbNavLink (click)="onConfigurationShown(upc)">Configuration</a>

                <ng-template ngbNavContent>
                  <app-upc-parameters
                    [upcv3]="upc"
                    (upcv3Changed)="onUpcv3Changed($event)"
                  ></app-upc-parameters>
                </ng-template>
              </li>

              <!-- Interventions -->
              <li ngbNavItem>
                <a ngbNavLink>Interventions</a>
                <ng-template ngbNavContent>
                  <app-site-interventions [interventionsOwner]="group"></app-site-interventions>
                </ng-template>
              </li>

              <!-- SMS -->

              <!-- Event -->
              <li ngbNavItem *ngIf="upc && upc?.id != -1">
                <a ngbNavLink>Événements</a>

                <ng-template ngbNavContent>
                  <app-upc-events [upcv3]="upc"></app-upc-events>
                </ng-template>
              </li>

              <!-- Stats -->
              <li ngbNavItem *ngIf="upc">
                <a ngbNavLink>Statistiques</a>
                <ng-template ngbNavContent>
                  <app-upc-stats [upcv3]="upc"></app-upc-stats>
                </ng-template>
              </li>

              <!-- Tests -->
              <li
                ngbNavItem
                *ngIf="authService.isAdmin && upc && upc?.id != -1"
              >
                <a ngbNavLink>Tests UPC</a>

                <ng-template ngbNavContent>
                  <app-upc-tests [upcv3]="upc"></app-upc-tests>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="navChild"></div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
