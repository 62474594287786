<div class="modal-header">
  <h4 class="modal-title">Associer un Boîtier</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- UPC-V3 -->
    <div class="form-group">
      <label>Boîtier</label>
      <ng-select [items]="unlinkeds" bindLabel="upcBoxId" bindValue="upcMcuUid" placeholder="Sélectionnez le boîtier" formControlName="upcMcuUid"></ng-select>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Associer</span>
    </button>
  </div>
</form>
