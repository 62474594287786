import { CardTest } from "./cardTest";
import { BoxTest } from "./boxTest";

export class Test {
  date: Date = new Date();
	cardTest: CardTest = new CardTest();
  boxTest: BoxTest = new BoxTest();
  
  static loadFromJSON(json) {
    var test = Object.assign(new Test, json);

    if (json.date) test.date = new Date(json.date);
    if (json.cardTest) test.cardTest = CardTest.loadFromJSON(json.cardTest);
    if (json.boxTest) test.boxTest = BoxTest.loadFromJSON(json.boxTest);

    return test;
  }
}