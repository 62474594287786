import { GeneralParameters } from "./generalParameters";
import { CommunicationParameters } from "./communicationParameters";
import { DiffusionParameters } from "./diffusionParameters";
import { ReservesParameters, Co2ResStatusType } from "./reservesParameters";
import { AlarmsParameters } from "./alarmsParameters";
import { DefaultUPCV3Params } from "../defaultUPCV3Params";
import { DaysCode } from "./diffCo2Program";
import { Commentaires } from "../commentaire";
import { MailAlert } from "../mailalert";
import { Event } from "../event";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { Site } from "../../management/site";
import { GroupUPCV3 } from "../../GroupUPCV3/GroupUPCV3";
import { CO2_LITERS_TO_KG_RATIO } from 'src/app/constants/co2';

export function co2LitersToKg(liters: number): number {
  return liters * CO2_LITERS_TO_KG_RATIO;
}

@jsonObject
export class UPCV3 {
  @jsonMember
  id: number;
  
  @jsonMember
  upcNameId: string;
  
  @jsonMember
  lat?: number;
  
  @jsonMember
  lng?: number;
  
  @jsonMember
  upcError?: number;
  
  @jsonMember
  hibernated?: boolean;
  
  @jsonMember(() => GeneralParameters)
  generalParameters?: GeneralParameters = new GeneralParameters();
  
  @jsonMember(() => CommunicationParameters)
  communicationParameters?: CommunicationParameters = new CommunicationParameters();

  @jsonMember(() => DiffusionParameters)
  diffusionParameters?: DiffusionParameters = new DiffusionParameters();

  @jsonMember(() => ReservesParameters)
  reservesParameters?: ReservesParameters = new ReservesParameters();

  @jsonMember(() => AlarmsParameters)
  alarmsParameters?: AlarmsParameters = new AlarmsParameters();

  @jsonMember
  upcLastPollDatetime?: Date = new Date(0);
  
  @jsonMember
  consumption?: number;
  
  @jsonMember
  consumptionTemp?: number;
  
  @jsonMember
  debut?: Date;
  
  @jsonMember
  fin?: Date;
  
  @jsonMember
  lastPollResult?: number;
  
  @jsonMember
  upcPollEnable?: boolean;
  
  @jsonMember
  upcPollTasks?: number;
  
  @jsonMember
  upcLastSyncDatetime?: Date;
  
  @jsonMember
  logLastVisit?: Date;
  
  @jsonMember
  belt?: string;
  
  @jsonMember(() => Site)
  site?: Site;
  
  @jsonMember
  fullBottlesNumber?: number;
  
  @jsonMember
  emptyBottlesNumber?: number;
  
  @jsonMember
  usedBottlesNumber?: number;
  
  @jsonArrayMember(() => MailAlert)
  alertsSincePolling?: MailAlert[];
  
  @jsonMember(() => Event)
  lastIpEvent?: Event;
  
  @jsonMember
  archived?: boolean;
  
  @jsonMember(() => Commentaires)
  lastComment?: Commentaires;
  
  @jsonMember(() => Commentaires)
  lastActionNotDone?: Commentaires;
  
  @jsonMember(() => Event)
  lastSuccessfullPollingEvent?: Event;

  @jsonMember(() => GroupUPCV3)
  groupUpcv3?: GroupUPCV3;
  
  
  get upcAlertsSinceLastPollingTooltip(): string {
    const fiveMostRecent = this.alertsSincePolling.slice(0, 4);
    let result = "";
    for (const alarm of fiveMostRecent) {
      result +=
        alarm.date.toLocaleString("fr-fr") +
        "  " +
        alarm.msg +
        "\n";
    }
    return result;
  }


  get upcDashboardAsteriskClass(): string {
    if (this.alertsSincePolling.length === 0) {
      return "";
    }

    this.alertsSincePolling.sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
    const lastAlert = this.alertsSincePolling[0];
    if (["4", "68", "69", "60", "61"].includes(lastAlert.event)) {
      return "text-danger h5";
    }

    if (["72", "74", "76"].includes(lastAlert.event)) {
      return "text-warning h5";
    }

    if (["5"].includes(lastAlert.event)) {
      return "text-primary h5";
    }

    if (["1086", "1087"].includes(lastAlert.event)) {
      return "rembo h5";
    }
  }

  get upcDashboardAlarmSeverityLevel(): number {
    if (this.alertsSincePolling.length === 0) {
      return 0;
    }

    this.alertsSincePolling.sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
    const lastAlert = this.alertsSincePolling[0];
    if (["4", "68", "69", "60", "61"].includes(lastAlert.event)) {
      return 5;
    }

    if (["1086", "1087"].includes(lastAlert.event)) {
      return 4;
    }

    if (["72", "73", "74", "75", "76",
      "1072", "1073", "1074", "1075", "1076",].includes(lastAlert.event)) {
      return 3;
    }

    if (["5"].includes(lastAlert.event)) {
      return 2;
    }
  }

  get showDashboardAsterisk() {
    return this.alertsSincePolling.length !== 0;
  }

  co2LitersToKg = co2LitersToKg;

  get upcStatusString(): string {
    switch (this.generalParameters.upcStatus) {
      case 0:
        return "DIS";
      case 1:
        return "ENA";
      case 2:
        return "ADJ";
      case 3:
        return "CHK";
      case 4:
        return "CAL";
      case 100:
        return "EMPTY";
      case 999:
        return "###";
      default:
        return "";
    }
  }

  get upcStatusChipStyle(): string {
    if (this.hibernated) {
      return "chip-neutral";
    }
    if (
      [0, 2].includes(this.generalParameters.upcStatus) ||
      this.generalParameters.upcStatus > 7
    ) {
      return "chip-danger";
    }
    if (this.generalParameters.upcStatus == 3) {
      return "chip-warning";
    } else return "chip-neutral";
  }

  get co2ResActiveString(): string {
    //if (this.isRembo) return `Rembo ${this.resRemboAct}`;
    if (this.generalParameters.upcMode == 0) return "/";

    switch (this.reservesParameters.co2ResActive) {
      case 1:
        var co2ResActive = "B" + this.reservesParameters.co2ResActive;
        if (this.reservesParameters.co2ResType == 1) co2ResActive += "P";
        if (this.reservesParameters.co2ResType == 2) co2ResActive += "S";
        return co2ResActive;

      case 2:
        var co2ResActive = "B" + this.reservesParameters.co2ResActive;
        if (this.reservesParameters.co2ResType == 1) co2ResActive += "S";
        if (this.reservesParameters.co2ResType == 2) co2ResActive += "P";
        return co2ResActive;

      default:
        return "-";
    }
  }
  get upcLastPollDatetimeString(): string {
    if (this.upcLastPollDatetime && this.upcLastPollDatetime.getFullYear() > 1971) {

      let units = {
        year: 24 * 60 * 60 * 1000 * 365,
        month: 24 * 60 * 60 * 1000 * 365 / 12,
        day: 24 * 60 * 60 * 1000,
        hour: 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
      };

      const rtf = new Intl.RelativeTimeFormat("fr", { numeric: "auto", style: "long" });
      var elapsed = this.upcLastPollDatetime.getTime() - new Date().getTime()
      for (var unit in units) {
        if (Math.abs(elapsed) > units[unit] || unit == 'second') {
          return rtf.format(Math.round(elapsed / units[unit]), unit as (Intl.RelativeTimeFormatUnit))
        }
      }
      return "-";
    } else {
      return "-";
    }
  }

  get upcNameIdIndex(): number {
    // 0: hibernating   1: OK   2: Alert    3: Comm error   4: Empty
    if (!this.hibernated) {
      if (this.upcPollEnable && this.lastPollResult != 0 && !this.hibernated)
        return 6;
      if (
        this.reservesParameters.co2Res1Status == 0 &&
        this.reservesParameters.co2Res2Status == 0
      )
        return 4;
      else if (this.upcPollEnable && this.lastPollResult != 0) return 5;
      else if (
        (this.reservesParameters.co2Res1Status == 0 ||
          this.reservesParameters.co2Res2Status == 0) //&& !this.isRembo
      )
        return 2;
      else return 1;
    } else return 0;
  }

  getStatusSeverity(): number {
    const r1 = this.reservesParameters.co2Res1Status;
    const r2 = this.reservesParameters.co2Res2Status;
    const r1Enabled = this.reservesParameters.co2Res1Enabled
    const r2Enabled = this.reservesParameters.co2Res2Enabled
    if (this.hibernated) return 0;

    if (this.upcPollEnable && this.lastPollResult != 0 && !this.hibernated) {
      if (
        this.lastIpEvent && this.lastIpEvent.eventDatetime.getTime() > this.upcLastPollDatetime.getTime()
      ) {
        return 5;
      } else {
        return 4;
      }
    }
    if (
      (r1 == Co2ResStatusType.Vide && r2 == Co2ResStatusType.Vide) ||
      (r1 == Co2ResStatusType.Vide && r2 == Co2ResStatusType.Indeterminé) ||
      (r1 == Co2ResStatusType.Indeterminé && r2 == Co2ResStatusType.Vide)
    ) {
      return 3;
    }
    if (this.generalParameters.upcFwVer >= 274) {
      if (
        ((r1 == Co2ResStatusType.Vide && r1Enabled) || (r2 == Co2ResStatusType.Vide && r2Enabled)) &&
        r1 != Co2ResStatusType.Indeterminé &&
        r2 != Co2ResStatusType.Indeterminé //&& !this.isRembo
      ) {
        return 2;
      }
    } else {
      if (
        ((r1 == Co2ResStatusType.Vide) || (r2 == Co2ResStatusType.Vide)) &&
        r1 != Co2ResStatusType.Indeterminé &&
        r2 != Co2ResStatusType.Indeterminé //&& !this.isRembo
      ) {
        return 2;
      }
    }

    return 1;
  }
  static getStatusSeverityClass(index: number) {
    switch (index) {
      case 6:
        return;
      case 5:
        return "lightmauve";
      case 4:
        return "mauve";
      case 3:
        return "text-danger";
      case 2:
        return "text-warning";
      case 1:
        return "text-success";
      case 0:
      default:
        return "text-primary";
    }
  }

  get upcNameIdClass(): string {
    var res = "";
    const r1 = this.reservesParameters.co2Res1Status;
    const r2 = this.reservesParameters.co2Res2Status;
    if (this.upcPollEnable && this.lastPollResult != 0 && !this.hibernated) {
      if (
        this.lastIpEvent &&
        this.lastIpEvent.eventDatetime.getTime() > this.upcLastPollDatetime.getTime()
      ) {
        res = res + "lightmauve";
      } else {
        res = res + "mauve";
      }
    } else if (
      (!this.hibernated && r1 == 0 && r2 == 0) ||
      (r1 == 0 && r2 == 3) ||
      (r1 == 3 && r2 == 0)
    )
      res = res + "text-danger";
    else if (
      !this.hibernated &&
      (r1 == 0 || r2 == 0) &&
      r1 != 3 &&
      r2 != 3 //&& !this.isRembo
    )
      res = res + "text-warning";
    else if (this.hibernated) res = res + "text-primary";
    else res = res + "text-success";
    /*if (this.message !== ""){
      res+= " font-italic"
    }*/
    return res;
  }
  get upcStatusClass(): string {
   
    if (
      !this.hibernated &&
      (this.generalParameters.upcStatus == 0 ||
        this.generalParameters.upcStatus == 2 ||
        this.generalParameters.upcStatus > 7)
    )
      return "text-danger font-weight-bold";
    else if (!this.hibernated && this.generalParameters.upcStatus == 3)
      return "text-warning font-weight-bold";
    else return "";
  }
  get co2Res1ActVolClass(): string {
   
    if (!this.hibernated && this.reservesParameters.co2Res1Status == 0)
      return "text-danger font-weight-bold";
    else if (!this.hibernated && this.reservesParameters.co2Res1Status == 1)
      return "text-warning font-weight-bold";
    else return "";
  }
  get co2Res2ActVolClass(): string {
    if (!this.hibernated && this.reservesParameters.co2Res2Status == 0)
      return "text-danger font-weight-bold";
    else if (!this.hibernated && this.reservesParameters.co2Res2Status == 1)
      return "text-warning font-weight-bold";
    else return "";
  }
  get upcLastPollDatetimeClass(): string {
    if (!this.hibernated && this.lastPollResult != 0)
      return "text-danger font-weight-bold";
    else return "";
  }
  get upcPollEnableClass(): string {
    if (!this.hibernated && !this.upcPollEnable)
      return "text-danger font-weight-bold";
    else return "";
  }

  get Co2ResTypeString(): string {
    switch (this.reservesParameters.co2ResType) {
      case 0:
        return "Symétrique";
      case 1:
        return "B1 Principale";
      case 2:
        return "B2 Principale";
      default:
        return "Indeterminé";
    }
  }

  get upcStatusCompleteString(): string {
    switch (this.generalParameters.upcStatus) {
      case 0:
        return "CO2 diffusion OFF";
      case 1:
        return "CO2 diffusion ENABLED";
      case 2:
        return "CO2 diffusion ADJUST";
      case 3:
        return "CO2 diffusion CHECK";
      case 4:
        return "Offsets auto-calibrate";
      case 5:
        return "Diff. CHECK while R1 empty";
      case 6:
        return "Diff. CHECK while R2 empty";
      case 7:
        return "Diff. reserve empty TEST";
      case 100:
      default:
        return "ERROR (R1 & R2 empty)";
    }
  }

  constructor(defaultParams: DefaultUPCV3Params = null) {
    if (defaultParams) {
      this.generalParameters.co2FlowRefTrap = defaultParams.co2FlowRefTrap;
      this.generalParameters.refFlowRateGperhour =
        defaultParams.refFlowRateGperhour;

      this.diffusionParameters.diffCo2Program1.daysCode =
        DaysCode[defaultParams.daysCode1];
      this.diffusionParameters.diffCo2Program1.start = defaultParams.startTime1;
      this.diffusionParameters.diffCo2Program1.end = defaultParams.endTime1;
      this.diffusionParameters.diffCo2Program1.intensity =
        defaultParams.intensity1;

      this.diffusionParameters.diffCo2Program2.daysCode =
        DaysCode[defaultParams.daysCode2];
      this.diffusionParameters.diffCo2Program2.start = defaultParams.startTime2;
      this.diffusionParameters.diffCo2Program2.end = defaultParams.endTime2;
      this.diffusionParameters.diffCo2Program2.intensity =
        defaultParams.intensity2;

      this.diffusionParameters.diffCo2Sunrise.delay =
        defaultParams.sunriseDelay;
      this.diffusionParameters.diffCo2Sunrise.duration =
        defaultParams.sunriseDuration;
      this.diffusionParameters.diffCo2Sunrise.intensity =
        defaultParams.sunriseIntensity;

      this.diffusionParameters.diffCo2Sunset.delay = defaultParams.sunsetDelay;
      this.diffusionParameters.diffCo2Sunset.duration =
        defaultParams.sunsetDuration;
      this.diffusionParameters.diffCo2Sunset.intensity =
        defaultParams.sunsetIntensity;

      this.alarmsParameters.alrResLowEn = defaultParams.alrResLowEn;
      this.alarmsParameters.alrResEmptyEn = defaultParams.alrResEmptyEn;
      this.alarmsParameters.alrPresInpEn = defaultParams.alrPresInpEn;
      this.alarmsParameters.alrPresOutEn = defaultParams.alrPresOutEn;
      this.alarmsParameters.alrFlowAvgEn = defaultParams.alrFlowAvgEn;
      this.alarmsParameters.alrPowBackEn = defaultParams.alrPowBackEn;
      this.alarmsParameters.alrPowDownEn = defaultParams.alrPowDownEn;

      this.alarmsParameters.alrEmptyPress = defaultParams.alrEmptyPress;
      this.alarmsParameters.alrEmptyVol = defaultParams.alrEmptyVol;
      this.alarmsParameters.alrPresInpTol = defaultParams.alrPresInpTol;
      this.alarmsParameters.alrPresOutTol = defaultParams.alrPresOutTol;
      this.alarmsParameters.alrFlowSetTol = defaultParams.alrFlowSetTol;
      this.alarmsParameters.alrResLowLevel = defaultParams.alrResLowLevel;
      this.alarmsParameters.alrResEmptyFlow = defaultParams.alrResEmptyFlow;

      this.alarmsParameters.alrSmsNum1 = defaultParams.alrSmsNum0;

      this.communicationParameters.comWiFiName = defaultParams.comWiFiName;
      this.communicationParameters.comWiFiPass = defaultParams.comWiFiPass;
      this.generalParameters.upcLanguage = defaultParams.upcLanguage;
      this.communicationParameters.comGsmName = defaultParams.comGsmName;
      this.communicationParameters.comGsmPass = defaultParams.comGsmPass;
      this.communicationParameters.comWebSrvUrl = defaultParams.comWebSrvUrl;
    }
  }

  static loadFromJSON(json): UPCV3 {
    const serializer = new TypedJSON(UPCV3);
    return serializer.parse(json);
  }
}
