import { Sort } from '@angular/material/sort';
import { Component, OnInit, Input } from '@angular/core';
import { UPCV3 } from '../../../../../models/upcv3/upc/upcv3';
import { UPCV3Service } from '../../../../../services/upcv3/upcv3.service';
import { Event } from '../../../../../models/upcv3/event';
import { ToasterService } from 'angular2-toaster';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { LazyLoadEvent } from 'primeng/api';
import { PageParams } from 'src/app/models/dto/UPCV3/Page';
import { EventGetDto } from 'src/app/models/dto/eventGet.dto';

@Component({
  selector: 'app-upc-events',
  templateUrl: './events.component.html'
})
export class EventsComponent implements OnInit {
  @Input('upcv3') upcv3: UPCV3;
  events: Event[];
  currentPage = 0;
  nbRows = 100;
  totalEvents: number;

  eventsSourceFilter = 'all';
  loading = true;

  from: NgbDate;
  to: NgbDate;
  max: NgbDate;

  wrongRange: boolean = false;
  private onDestroy$: Subject<void> = new Subject<void>();

  userSearchUpdate = new Subject<string>();
  inputSearch: string;
  
  enableAutoRefresh: boolean = false;
  lastLoadEvent: Subscription;

  constructor(
    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService,
    public calendar: NgbCalendar
  ) {
    this.from = calendar.getPrev(calendar.getToday(), 'y', 1);
    this.to = calendar.getToday();
    this.max = calendar.getToday();
    this.userSearchUpdate.pipe(
      debounceTime(600),
      distinctUntilChanged()).subscribe({
        next: () => this.loadEvents()
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit() { 
    this.autoUpdateEvents();
  } //Initial loading handled by lazyloadEvent on table

  loadEvents() {
    this.loading = true;
    let {page, size, sort} = new PageParams<Event>(this.currentPage, this.nbRows, {col:"serverDatetime", dir:"DESC"});
    // Ajoute un jour pour avoir aussi les données de la journée choisie
    let to = new Date(this.NgbDateToString(this.to));
    to.setDate(to.getDate() + 1);
    let params : EventGetDto = {
      sourceFilter: this.eventsSourceFilter,
      from: this.NgbDateToString(this.from),
      to: to.toISOString().split('T')[0],
      ...(this.inputSearch != null && { searchQuery: this.inputSearch }),
      ...{page,size,sort},
    }
    
    this.upcv3Service.getEvents(this.upcv3.id, params).pipe(
      takeUntil(this.onDestroy$),
      finalize(() => this.loading = false)
      ).subscribe({
      next: (res) => {
        this.events = res.content;
        this.totalEvents = res.totalElements;
      },
      error: (err) => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des évenements");
        this.events = [];
      },
    })
  }

  handleTableEvent(tableEvent: LazyLoadEvent) {
    if (tableEvent.first != null && tableEvent.rows != null) {
      this.nbRows = tableEvent.rows
      this.currentPage = tableEvent.first / tableEvent.rows
      this.loadEvents()
    }
  }

  NgbDateToString(date: NgbDate) {
    return date.year + '-' + (date.month > 9 ? date.month : '0' + date.month) + '-' + (date.day > 9 ? date.day : '0' + date.day)
  }

  scrollToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  autoUpdateEvents() {
    this.lastLoadEvent = timer(0, 5000)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe((res) => {
      if(this.enableAutoRefresh) {
        this.loadEvents();
      }
    });
  }

}
