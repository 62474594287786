import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Task } from 'src/app/models/management/task';
import { ToasterService } from 'angular2-toaster';

@Injectable({
  providedIn: 'root',
})
export class TaskService {

  constructor(
    private http: HttpClient,
    private toasterService : ToasterService
  ) { }
  
  // Codes: TASK_RECOVERED
  public getAll(): Observable<Task[]> {
    return this.http.get<ApiResponse<Task[]>>(environment.apiUrl + 'task/all').pipe(map(
      res => {
        return res.result.map(task => Task.loadFromJSON(task));
      }
    ))
  }
  public reset () {
    return this.http.get(environment.apiUrl+'upcv3/reset').pipe(map(
      res => {
        this.toasterService.pop('success','Reset réalisé avec succès !');
      }
    ))
  }

}