import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Knob
import { Ng14KnobModule } from 'ng14-knob/dist/ng14-knob';

// Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// maps
import { AgmCoreModule } from '@agm/core';

// Pages
import { ProjectsComponent } from "./projects.component";
import { ProjectComponent } from "./project/project.component";
import { VersionComponent } from "./project/version/version.component";
import { CADComponent } from "./project/version/CAD/cad.component";
import { ClipCanvasComponent } from "./project/version/CAD/clip-canvas/clip-canvas.component";
import { CADTopCenterComponent } from "./project/version/CAD/top-center/top-center.component";
import { CADBottomCenterComponent } from "./project/version/CAD/bottom-center/bottom-center.component";
import { CADBottomRightComponent } from "./project/version/CAD/bottom-right/bottom-right.component";
import { CADRightCenterComponent } from "./project/version/CAD/right-center/right-center.component";
import { CADBottomLeftComponent } from "./project/version/CAD/bottom-left/bottom-left.component";
import { CADTopLeftComponent } from "./project/version/CAD/top-left/top-left.component";
import { CADCanvasComponent } from "./project/version/CAD/canvas/canvas.component";
import { CADContextMenuComponent } from "./project/version/CAD/context-menu/context-menu.component";
import { CADDiffusionsComponent } from "./project/version/CAD/diffusions/diffusions.component";
import { CADPixiComponent } from "./project/version/CAD/pixi/pixi.component";

// Modals
import { AddProjectModalComponent } from "./add-project/add-project.component";
import { EditProjectModalComponent } from "./edit-project/edit-project.component";
import { AddVersionModalComponent } from "./project/add-version/add-version.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    Ng14KnobModule,
    UiSwitchModule,
    AgmCoreModule
  ],
  declarations: [
    // Pages
    ProjectsComponent,
    ProjectComponent,
    VersionComponent,
    CADComponent,
    ClipCanvasComponent,
    CADTopCenterComponent,
    CADBottomCenterComponent,
    CADBottomRightComponent,
    CADRightCenterComponent,
    CADBottomLeftComponent,
    CADTopLeftComponent,
    CADCanvasComponent,
    CADContextMenuComponent,
    CADDiffusionsComponent,
    CADPixiComponent,
    // Modals
    AddProjectModalComponent,
    EditProjectModalComponent,
    AddVersionModalComponent
  ]
})
export class ProjectsModule { }