import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class MailAlert {
  
  @jsonMember
  id: number;
  
  @jsonMember
  belt_id: string;
  
  @jsonMember
  uuid: string;
  
  @jsonMember
  event: string;
  
  @jsonMember
  msg: string;
  
  @jsonMember
  date: Date;

  static loadFromJSON(json):MailAlert {
  	let serializer = new TypedJSON(MailAlert);
		return serializer.parse(json);
  }

 
}