import { Component, Input } from '@angular/core';
import { CAD } from '../../../../../../models/project/cad';

@Component({
  selector: 'cad-bottom-right',
  styleUrls: [ './bottom-right.component.css' ],
  templateUrl: './bottom-right.component.html'
})
export class CADBottomRightComponent {
  // NF
  @Input('cad') cad: CAD;

  constructor() {}

}