import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/models/ApiResponse";
import { GroupUPCV3 } from "src/app/models/GroupUPCV3/GroupUPCV3";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class GroupUPCV3Service {
  constructor(
    private http: HttpClient
  ) {}

  public get(archived: boolean = false): Observable<GroupUPCV3[]> {
    let params = new HttpParams().append("archived", archived);
    return this.http.get<ApiResponse<GroupUPCV3[]>>(`${environment.apiUrl}groupUpcv3`, {params}).pipe(map(
      res => res.result.map(json => GroupUPCV3.loadFromJSON(json))
    ))
  }

  public getById(id: number): Observable<GroupUPCV3> {
    return this.http.get<ApiResponse<GroupUPCV3>>(`${environment.apiUrl}groupUpcv3/${id}`).pipe(map(
      res => GroupUPCV3.loadFromJSON(res.result)
    ))
  }

  public archive(id: number): Observable<void> {
    return this.http.get<ApiResponse<void>>(`${environment.apiUrl}groupUpcv3/${id}/archive`).pipe(map(
      res => res.result
    ));
  }
}