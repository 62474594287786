<div class="modal-header">
  <h4 class="modal-title">Nouveau Type de bouteille</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- contenue -->
    <div class="form-group">
      <label for="contenue">Contenu en kg *</label>
      <input type="number" class="form-control" id="contenue" step="0.5" placeholder="Contenu en kg *" formControlName="contenue" [ngClass]="{ 'is-invalid': contenueAndBrandAlreadyUsed || f.contenue.errors }" (keyup)="contenueAndBrandAlreadyUsed = false">
      <div *ngIf="contenueAndBrandAlreadyUsed || f.contenue.errors" class="invalid-feedback">
        <div *ngIf="f.contenue.errors && f.contenue.errors.required">Contenu obligatoire</div>
        <div *ngIf="contenueAndBrandAlreadyUsed">Le type de bouteille {{ f.contenue.value }} de la marque {{ f.brand.value }}, existe déjà</div>
      </div>
    </div>

    <!-- Brand -->
    <div class="form-group">
      <label for="brand">Marque *</label>
      <input type="text" class="form-control" id="brand" placeholder="Marque *" formControlName="brand" [ngClass]="{ 'is-invalid': contenueAndBrandAlreadyUsed || f.brand.errors }" (keyup)="contenueAndBrandAlreadyUsed = false">
      <div *ngIf="contenueAndBrandAlreadyUsed || f.brand.errors" class="invalid-feedback">
        <div *ngIf="f.brand.errors && f.brand.errors.required">Marque obligatoire</div>
        <div *ngIf="contenueAndBrandAlreadyUsed">Le type de bouteille {{ f.contenue.value }} de la marque {{ f.brand.value }}, existe déjà</div>
      </div>
    </div>

    <!-- Designation -->
    <div class="form-group">
      <label for="designation">Désignation</label>
      <input type="text" class="form-control" id="designation" placeholder="Désignation" formControlName="designation">
    </div>

    <!-- isRembo -->
    <div class="form-group">
      <label for="name">Rembo</label><br>
      <ui-switch formControlName="isRembo"></ui-switch>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>