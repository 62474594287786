import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';

@jsonObject
export class AlarmsParameters {
	
	@jsonMember
	alrResLowEn: boolean = false;
	
	@jsonMember
	alrResEmptyEn: boolean = false;
	
	@jsonMember
	alrPresInpEn: boolean = false;
	
	@jsonMember
	alrPresOutEn: boolean = false;
	
	@jsonMember
	alrFlowAvgEn: boolean = false;
	
	@jsonMember
	alrPowBackEn: boolean = false;
	
	@jsonMember
	alrPowDownEn: boolean = false;
	
	@jsonMember
	alrEmptyPress: number = 0;
	
	@jsonMember
	alrEmptyVol: number = 0;
	
	@jsonMember
	alrPresInpTol: number = 0;
	
	@jsonMember
	alrPresOutTol: number = 0;
	
	@jsonMember
	alrFlowSetTol: number = 0;
	
	@jsonMember
	alrResLowLevel: number = 0;
	
	@jsonMember
	alrResEmptyFlow: number = 0;
	
	@jsonMember
	alrResEmptyTest: number = 3600;
	
	@jsonMember
	alrSmsNum1: string = "";
	
	@jsonMember
	alrSmsNum2: string = "";
	
	@jsonMember
	alrSmsNum3: string = "";
	
	@jsonMember
	alrSmsNum4: string = "";
	
	@jsonMember
	alrSmsNum5: string = "";
	
	@jsonMember
	alrPressInpSet1_1: number = 0;
	
	@jsonMember
	alrPressInpSet1_2: number = 0;
	
	@jsonMember
	alrPressInpSet1_3: number = 0;
	
	@jsonMember
	alrPressInpSet1_4: number = 0;
	
	@jsonMember
	alrPressInpSet1_5: number = 0;
	
	@jsonMember
	alrPressInpSet1_6: number = 0;
	
	@jsonMember
	alrPressInpSet1_7: number = 0;
	
	@jsonMember
	alrPressInpSet1_8: number = 0;
	
	@jsonMember
	alrPressInpSet1_9: number = 0;
	
	@jsonMember
	alrPressInpSet1_10: number = 0;
	
	@jsonMember
	alrPressInpSet2_1: number = 0;
	
	@jsonMember
	alrPressInpSet2_2: number = 0;
	
	@jsonMember
	alrPressInpSet2_3: number = 0;
	
	@jsonMember
	alrPressInpSet2_4: number = 0;
	
	@jsonMember
	alrPressInpSet2_5: number = 0;
	
	@jsonMember
	alrPressInpSet2_6: number = 0;
	
	@jsonMember
	alrPressInpSet2_7: number = 0;
	
	@jsonMember
	alrPressInpSet2_8: number = 0;
	
	@jsonMember
	alrPressInpSet2_9: number = 0;
	
	@jsonMember
	alrPressInpSet2_10: number = 0;
	
	@jsonMember
	alrPressOutSet_1: number = 0;
	
	@jsonMember
	alrPressOutSet_2: number = 0;
	
	@jsonMember
	alrPressOutSet_3: number = 0;
	
	@jsonMember
	alrPressOutSet_4: number = 0;
	
	@jsonMember
	alrPressOutSet_5: number = 0;
	
	@jsonMember
	alrPressOutSet_6: number = 0;
	
	@jsonMember
	alrPressOutSet_7: number = 0;
	
	@jsonMember
	alrPressOutSet_8: number = 0;
	
	@jsonMember
	alrPressOutSet_9: number = 0;
	
	@jsonMember
	alrPressOutSet_10: number = 0;
	
	@jsonMember
	alrPressSetTemp: number = 0;


	static loadFromJSON(json): AlarmsParameters {
    const serializer = new TypedJSON(AlarmsParameters);
    return serializer.parse(json);
  }
}