import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BottleTypeService } from '../../../services/management/bottleType.service';
import { Router } from '@angular/router';
import { BottleType } from 'src/app/models/management/bottleType';
import { Modal } from 'src/app/models/ui/modal';
import { BottleTypePostDto } from 'src/app/models/dto/BottleTypePost.dto';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'add-bottle-type-content',
  templateUrl: './add-bottle-type.component.html'
})
export class AddBottleTypeModalComponent extends Modal<BottleType> {
  // Form
  contenueAndBrandAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private bottleTypeService: BottleTypeService,
    private toasterService: ToasterService,
  ) {
    super();

    // Form
    this.form = this.fb.group({
      contenue: ['', Validators.required],
      brand: ['', Validators.required],
      designation: [null],
      isRembo: false,
    });

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try create
    this.formSubmitted = true;

    let formData: BottleTypePostDto = {
      brand: this.form.value.brand,
      contenue: this.form.value.contenue,
      designation: this.form.value.designation,
      isRembo: this.form.value.isRembo
    }
    this.bottleTypeService.create(formData).subscribe({
      next: () => this.close(true),
      error: (err) => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la création du nouveau type de bouteille");
        this.close(false)
      }
    })
  }
}