import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Page, PagingResponse } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Bottle, status } from 'src/app/models/management/bottle';
import { BottlePostDto } from 'src/app/models/dto/BottlePost.dto';
import { LocationType } from 'src/app/models/management/location';
import _ from 'lodash';
import { BottlePostReceiveFromSupplierDto } from 'src/app/models/dto/BottlePostReceiveFromSupplier.dto';

export interface GetAllOptions {
  status?: status;
  locationType?: LocationType;
  hasBarcode?: boolean;
  page: number;
  size: number;
}
@Injectable({
  providedIn: 'root',
})
export class BottleService {
  constructor(
    private http: HttpClient,
  ) { }
  // Codes: BOTTLE_CREATED | WRONG_PARAMS | INTERNAL_ERROR | BOTTLE_TYPE_DOESNT_EXSIST | STOCK_DOESNT_EXSIST | BOTTLE_ALREADY_EXSIST | UNAUTHORIZED

  public getAll(options: GetAllOptions): Observable<Page<Bottle>> {
    // définis les valeurs par défaut, les écrase par celles passées en paramètre et supprime les undefined
    const params = _.pickBy({page: 0, size: 10, ...options}, x => x !== undefined);
    return this.http.get<PagingResponse<Bottle>>(`${environment.apiUrl}bottle`, {
      params
    }).pipe(map(
      res => {
        res.result.content = res.result.content.map(jsonBottle => Bottle.loadFromJSON(jsonBottle));
        return res.result;
      }
    ))
  }

  public add(form: BottlePostDto): Observable<Bottle> {
    return this.http.post<ApiResponse<Bottle>>(`${environment.apiUrl}bottle`, form).pipe(map(
      res => Bottle.loadFromJSON(res.result)
    ))
  }

  public returnToSupplier(bottleId: string): Observable<Bottle> {
    return this.http.post<ApiResponse<Bottle>>(`${environment.apiUrl}bottle/${bottleId}/returnToSupplier`, {}).pipe(map(
      res => Bottle.loadFromJSON(res.result)
    ))
  }

  public returnToStock(bottleId: string, stockId: string): Observable<Bottle> {
    return this.http.post<ApiResponse<Bottle>>(`${environment.apiUrl}bottle/${bottleId}/returnToStock/${stockId}`, {}).pipe(map(
      res => Bottle.loadFromJSON(res.result)
    ))
  }

  public putInTransit(bottleId: string): Observable<Bottle> {
    return this.http.post<ApiResponse<Bottle>>(`${environment.apiUrl}bottle/${bottleId}/putInTransit`, {}).pipe(map(
      res => Bottle.loadFromJSON(res.result)
    ))
  }

  public putInRack(bottleId: string, rackId: string): Observable<Bottle> {
    return this.http.post<ApiResponse<Bottle>>(`${environment.apiUrl}bottle/${bottleId}/putInRack/${rackId}`, {}).pipe(map(
      res => Bottle.loadFromJSON(res.result)
    ))
  }

  public addToSite(bottleId: string, siteId: string): Observable<Bottle> {
    return this.http.post<ApiResponse<Bottle>>(`${environment.apiUrl}bottle/${bottleId}/addToSite/${siteId}`, {}).pipe(map(
      res => Bottle.loadFromJSON(res.result)
    ))
  }

  public receiveMultipleFromSupplier(locationId: string, bottles: BottlePostReceiveFromSupplierDto[]) {
    return this.http.post(`${environment.apiUrl}bottle/receiveMultipleFromSupplier/${locationId}`, bottles);
  }

  public get(uuid: string): Observable<Bottle> {
    return this.http.get<ApiResponse<Bottle>>(`${environment.apiUrl}bottle/${uuid}`).pipe(map(res =>
      Bottle.loadFromJSON(res.result)
    ))
  }
}