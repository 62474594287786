import { Node, Type as NodeType } from "./node";
import { Path } from "./path";
import { ProtectedArea } from "./protectedArea";

export class Belt {
  id: string = '';

	name: string = '';

	bottleNumberB1: number = 0;
	bottleNumberB2: number = 0;
	bottleType: BottleType = BottleType.B10;
	co2KgPrice: number = 0;
	co2MonthRentPrice: number = 0;

  controlSheltered: boolean = false;
  controlShelteredBig : boolean = false;
  
	trapsColor: TrapsColor = TrapsColor.WOOD;

	flowCumulated: number = 0;
	flowTheoretical: number = 0;

	pressureInt1: number = 0;
	pressureInt2: number = 0;
	pressureInt3: number = 0;
	pressureInt4: number = 0;
	pressureInt5: number = 0;
	pressureInt6: number = 0;
	pressureInt7: number = 0;
	pressureInt8: number = 0;
	pressureInt9: number = 0;
  pressureInt10: number = 0;

  nodes: Node[] = [];
  paths: Path[] = [];
  protectedAreas: ProtectedArea[] = [];

  // UI
  collapsed: boolean = true;
  nodesCollapsed: boolean = true;
  pathsCollapsed: boolean = true;
  protectedAreasCollapsed: boolean = true;

  get trapsNumber(): number {
    return this.nodes.filter(n => n.type == NodeType.HEXAGONAL_TRAP || n.type == NodeType.RECTANGULAR_TRAP).length;
  }

  resetCalculationValues() {
		this.flowCumulated = 0;
    this.flowTheoretical = 0;
    this.pressureInt1 = 0;
    this.pressureInt2 = 0;
    this.pressureInt3 = 0;
    this.pressureInt4 = 0;
    this.pressureInt5 = 0;
    this.pressureInt6 = 0;
    this.pressureInt7 = 0;
    this.pressureInt8 = 0;
    this.pressureInt9 = 0;
    this.pressureInt10 = 0;
	}
  
  static loadFromJSON(json): Belt {
    var belt: Belt = Object.assign(new Belt, json);
    if (json.bottleType) belt.bottleType = <BottleType> BottleType[json.bottleType];
    if (json.trapsColor) belt.trapsColor = <TrapsColor> TrapsColor[json.trapsColor];
    if (json.nodes) {
      belt.nodes = [];
      json.nodes.forEach(jsonNode => belt.nodes.push(Node.loadFromJSON(jsonNode)));
    }
    if (json.paths) {
      belt.paths = [];
      json.paths.forEach(jsonPath => belt.paths.push(Path.loadFromJSON(jsonPath)));
    }
    if (json.protectedAreas) {
      belt.protectedAreas = [];
      json.protectedAreas.forEach(jsonProtectedArea => belt.protectedAreas.push(ProtectedArea.loadFromJSON(jsonProtectedArea)));
    }
    return belt;
  }
}

export enum BottleType {
  B10 = 'B10',
  B20 = 'B20',
  B34 = 'B34',
  B37 = 'B37',
  B50LB = 'B50LB',
  R100 = 'R100',
  R180 = 'R180'
}

export enum TrapsColor {
  WOOD = 'WOOD',
  GREEN = 'GREEN',
  BROWN = 'BROWN'
}