import { UpcMcu } from '../../models/upcv3/upc/upcMcu';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Page, PagingResponse } from '../../models/ApiResponse';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { pickBy } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UpcMcuService {

  constructor(
    private http: HttpClient,
  ) { }


  public getAllTests(getAllTestsParams?: GetAllTestsParams): Observable<Page<UpcMcu>> {
    const paramsWithoutUndefined = pickBy(getAllTestsParams, v => v !== undefined);
    return this.http.get<PagingResponse<UpcMcu>>(`${environment.apiUrl}upcmcus`, {
      params: paramsWithoutUndefined
    }).pipe(map(
      res => {
        res.result.content = res.result.content.map(json => UpcMcu.loadFromJSON(json));
        return res.result;
      }
    ));
  }
}

export type GetAllTestsParams = {
  page?: number;
  size?: number;
  upcNameId?: string;
  upcMcuUid?: string;
  boxName?: string;
  hasUpcv3?: boolean;
}