<div class="card mt-3 p-3">
  <div class="row">

    <!-- Add -->
    <div class="col-12 mb-3">
      <h4 class="float-left">Firmwares</h4>
      <button type="button" class="btn btn-primary float-right" (click)="openAddFirmwareModal()"><i class="fa fa-plus"></i> Firmware</button>
    </div>

    <!-- List -->
    <div class="col-12">
      <div class="list-group">
        <!-- Content Header (hidden on mobile) -->
        <a class="list-group-item list-group-item-action d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <small>Nom</small>
            </div>
            <div class="col-2">
              <small class="float-right">Actions</small>
            </div>
          </div>
        </a>
        <a class="list-group-item list-group-item-action text-center" *ngIf="!firmwares"><i class="fa fa-circle-o-notch fa-spin"></i></a>
        <a class="list-group-item list-group-item-action text-center" *ngIf="firmwares && firmwares?.length == 0">Aucun firmware</a>

        <ng-container *ngIf="firmwares">
          <a class="list-group-item list-group-item-action" *ngFor="let firmware of firmwares" style="word-wrap: break-word;">
            <div class="row">
              <div class="col-10">
                <h6 class="mb-0 mt-1"><small class="d-md-none mr-2">Nom</small>{{ firmware }}</h6>
              </div>

              <div class="col-2">
                <button class="btn btn-danger btn-sm m-1 float-right" (click)="openDeleteFirmwareModal(firmware)" placement="top" ngbTooltip="Supprimer" ngbButton><i class="fa fa-trash"></i></button>
                <button class="btn btn-warning btn-sm m-1 float-right" (click)="openUpdateUPCFirmwareModal(firmware)" placement="top" ngbTooltip="Mettre à jour des UPC-V3" ngbButton><i class="fa fa-download"></i></button>
              </div>
            </div>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>