<p class="text-center" *ngIf="consoStats && consoStats.length == 0">
  Aucune donnée
</p>
<p class="text-center" *ngIf="consoStats && consoStats.length > 0">
  Consommation totale pour la période sélectionnée : <b>{{ consoTotal }}</b> kg
</p>
<div style="height: 500px" *ngIf="consoStats && consoStats.length > 0">
  <ngx-charts-line-chart
    [results]="consoChartData"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [showXAxisLabel]="true"
    [showYAxisLabel]="true"
    [autoScale]="true"
  >
    <ng-template #seriesTooltipTemplate let-model="model">
      <table style="margin: 5px">
        <thead>
          <tr>
            <th class="align-left" scope="col">UPC</th>
            <th class="align-left" scope="col">Consommation</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dataPoint of model[0].tooltip; index as index">
            <td>{{ model[0].tooltip[index].upcNameId }}</td>
            <td>{{ model[0].tooltip[index].conso.toFixed(2) }}</td>
          </tr>
          <tr>
            <td><strong>Total</strong></td>
            <td>
              <strong>{{ model[0].value.toFixed(2) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </ngx-charts-line-chart>
</div>
