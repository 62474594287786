import { StockPutDto } from "./../../models/dto/StockPutDto";
import { StockPostDto } from "./../../models/dto/StockPostDto";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { FormGroup } from "@angular/forms";

import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";

import { ApiResponse, Code, PagingResponse } from "../../models/ApiResponse";
import { environment } from "../../../environments/environment";
import { Stock } from "../../models/management/stock";
import { Bottle } from "../../models/management/bottle";
import { ToasterService } from "angular2-toaster";
import { UPCV3 } from "src/app/models/upcv3/upc/upcv3";

const strings = {
  titles: {
    added: "Stock ajouté",
    notAdded: "Stock non ajouté",
    edited: "Stock modifié",
    notEdited: "Stock non modifié",
    deleted: "Stock supprimé",
    notDeleted: "Stock non supprimé",
  },
  bodies: {
    internalError: "Erreur interne",
    stockDoesntExsist: "Stock inexistant",
    stockUsed: "Stock utilisé",
    unauthorized: "Opération non autorisée",
  },
};

@Injectable({
  providedIn: "root",
})
export class StockService {
  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) {}

  public getAll(archived: boolean = false): Observable<Stock[]> {
    let params = new HttpParams().append("archived", archived);
    return this.http
      .get<ApiResponse<Stock[]>>(environment.apiUrl + "stock/all", { params })
      .pipe(
        map((res) =>
          res.result.map((jsonStock) => Stock.loadFromJSON(jsonStock))
        )
      );
  }

  public get(id: string): Observable<Stock> {
    return this.http
      .get<ApiResponse<Stock>>(`${environment.apiUrl}stock/${id}`)
      .pipe(map((res) => Stock.loadFromJSON(res.result)));
  }

  public create(stockDto: StockPostDto): Observable<Stock> {
    return this.http
      .post<ApiResponse<Stock>>(`${environment.apiUrl}stock`, stockDto)
      .pipe(map((res) => Stock.loadFromJSON(res.result)));
  }

  public edit(id: string, stockPutDto: StockPutDto): Observable<void> {
    return this.http
      .put<ApiResponse<Stock>>(environment.apiUrl + "stock/" + id, stockPutDto)
      .pipe(map(() => { return; }));
  }

  public delete(id: string): Observable<void> {
    return this.http
      .delete<ApiResponse<{}>>(environment.apiUrl + "stock/" + id)
      .pipe(
        map(() => {
          return;
        })
      );
  }

  public getBottles(stockId: string): Observable<Bottle[]> {
    return this.get(stockId).pipe(
      map((res) => res.bottles)
    );
  }
}
