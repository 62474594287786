import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// Pages
import { StocksComponent } from "./stocks.component";
import { StockComponent } from "./stock/stock.component";

// Modals
import { AddStockModalComponent } from "./add-stock/add-stock.component";
import { EditStockModalComponent } from "./edit-stock/edit-stock.component";
import { DeleteStockModalComponent } from "./delete-stock/delete-stock.component";
import { AddBottleTypeModalComponent } from "./add-bottle-type/add-bottle-type.component";
import { DeleteBottleTypeModalComponent } from "./delete-bottle-type/delete-bottle-type.component";
import { AddBottleModalComponent } from "./stock/add-bottle/add-bottle.component";
import { BottleEventsModalComponent } from "./stock/bottle-events/bottle-events.component";
import {MatNativeDateModule} from '@angular/material/core';
import { MaterialModule } from "src/app/material-module";
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    UiSwitchModule,
    MatNativeDateModule,
    MaterialModule,
    ComponentsModule,
  ],
  declarations: [
    // Pages
    StocksComponent,
    StockComponent,
    // Modals
    AddStockModalComponent,
    EditStockModalComponent,
    DeleteStockModalComponent,
    AddBottleTypeModalComponent,
    DeleteBottleTypeModalComponent,
    AddBottleModalComponent,
    BottleEventsModalComponent,
  ]
})


export class StocksModule { }