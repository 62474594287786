import { Component, OnInit } from '@angular/core';
import { Designer } from '../../models/management/designer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddDesignerModalComponent } from './add-designer/add-designer.component';
import { EditDesignerModalComponent } from './edit-designer/edit-designer.component';
import { DeleteDesignerModalComponent } from './delete-designer/delete-designer.component';
import { DesignerService } from '../../services/management/designer.service';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-designers',
  templateUrl: './designers.component.html'
})
export class DesignersComponent implements OnInit {
  // NF
  designers: Designer[];

  constructor(
    private designerService: DesignerService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.loadDesigners()
  }

  loadDesigners() {
    this.designers = null;
    this.designerService.getAll().subscribe(
      res => {
        switch (res.code) {

          case Code.DESIGNER_RECOVERED:
          this.designers = res.result;
          break;

          case Code.UNAUTHORIZED:
          this.designers = [];
          break;
        
        }
      }
    )
  }

  /* Modals */
  openAddDesignerModal() {
    var addDesignerModalRef = this.modalService.open(AddDesignerModalComponent);
    addDesignerModalRef.componentInstance.init(null, addDesignerModalRef, () => this.loadDesigners())
  }

  openEditDesignerModal(designer: Designer) {
    var editDesignerModalRef = this.modalService.open(EditDesignerModalComponent);
    editDesignerModalRef.componentInstance.init(designer, editDesignerModalRef, () => this.loadDesigners())
  }

  openDeleteDesignerModal(designer: Designer) {
    var deleteDesignerModalRef = this.modalService.open(DeleteDesignerModalComponent);
    deleteDesignerModalRef.componentInstance.init(designer, deleteDesignerModalRef, () => this.loadDesigners())
  }

}