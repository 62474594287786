import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UPCV3 } from '../../../../models/upcv3/upc/upcv3';
import { UPCV3Service } from '../../../../services/upcv3/upcv3.service';
import { SiteService } from '../../../../services/management/site.service';
import { UPCV2 } from '../../../../models/upcv2/upcv2';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Site } from 'src/app/models/management/site';

@Component({
  selector: 'associate-belt-content',
  templateUrl: './associate-belt.component.html'
})
export class AssociateBeltModalComponent extends Modal<Site> implements OnInit {
  // Form
  addressUnknow = false;
  nameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  upcv3: UPCV3[];
  upcv2: UPCV2[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private upcv3Service: UPCV3Service,
    private siteService: SiteService
  ) {
    super();

    // Form
    this.form = this.fb.group({ upcv3: [], upcv2: [] });

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  ngOnInit() {
    // Load upcv3
    this.upcv3Service.getUnlinkeds().subscribe({
      next: (res) => this.upcv3 = res,
      error: () => this.close()
    })
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try associate
    this.formSubmitted = true;
    this.siteService.associateUpc(this.subject.id, this.form).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }

}