import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'angular2-toaster';
import { map, startWith, switchMap } from 'rxjs/operators';
import { Location, Rack } from 'src/app/models/management/location';
import { ConfirmationModal } from 'src/app/pages/dashboard/confirmationModal/confirmationModal';
import { RackService } from 'src/app/services/management/rack.service';
import { BottlesTableComponent } from '../bottles-table/bottles-table.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'racks-table',
  templateUrl: './racks-table.component.html',
  styleUrls: ['./racks-table.component.css']
})
export class RacksTableComponent implements OnInit, AfterViewInit {
  @Input() racksOwner: typeof Rack.prototype.location;
  @Input() displayedColumns = ["select", "barcode", "numberOfBottles", "action"];
  @Input() selectedRacks!: Rack[];
  @Input() permitReturnToSupplier = false;
  @Output() selectedRacksChange = new EventEmitter<Rack[]>();

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  racks: Rack[] = [];
  dataSource = new MatTableDataSource<Rack>();
  selection = new SelectionModel<Rack>(true, []);
  totalData: number;

  pageSizeOptions = [10, 50, 100];

  constructor(
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private rackService: RackService,
    private dialogService: MatDialog,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.dataSource = new MatTableDataSource<Rack>();
    this.dataSource.paginator = this.paginator;
    this.selection = new SelectionModel<Rack>(true, []);
    this.selection.changed.subscribe(s => {
      this.selectedRacks = this.selection.selected;
      this.selectedRacksChange.emit(this.selection.selected);
    })
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.paginator.page.pipe(
      startWith({}),
      switchMap(() => {
        if (this.racksOwner instanceof Location) {
          return this.rackService.getAllByLocation(this.racksOwner.id, this.paginator.pageIndex, this.paginator.pageSize);
        }
      }),
      map((data) => {
        if (data == null) return [];
        this.totalData = data.totalElements;
        return data.content;
      })
    ).subscribe((data) => {
      this.racks = data;
      this.dataSource = new MatTableDataSource<Rack>(this.racks);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.bottles?.currentValue) {
      this.dataSource = new MatTableDataSource<Rack>(changes.racks.currentValue);
      this.dataSource.paginator = this.paginator;
      this.selection.clear();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Rack): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.barcode + 1}`;
  }

  /* Search */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter =  filterValue.trim().toLowerCase();
  }

  async openReturnToSupplier(rack: Rack) {
    const modalRef = this.modalService.open(ConfirmationModal, { size: 'lg' });
    modalRef.componentInstance.title = "Confirmez-vous le renvoi du rack au fournisseur ?";
    modalRef.componentInstance.body =
      `Voulez-vous renvoyer le rack ${rack.barcode} ?`;
    const result = await modalRef.result;
    if (result) {
      this.rackService.returnToSupplier(rack.id).subscribe({
        next: () => {
          this.toasterService.pop("success", "Succès", "Le rack a bien été renvoyé au fournisseur.");
          this.selection.clear();
          this.paginator._changePageSize(this.paginator.pageSize);
        },
        error: (err) => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors du retour du rack au fournisseur.")
      });
    }
  }

  showBottles(rack: Rack) {
    const bottlesTableDialog = this.dialogService.open(BottlesTableComponent, { minWidth: '60%' });
    bottlesTableDialog.componentInstance.bottles = rack.bottles;
    bottlesTableDialog.componentInstance.displayedColumns = ['barcode', 'type', 'state', 'status', 'action'];
  }
}
