import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Location } from 'src/app/models/management/location';

@Component({
  selector: 'location-selection-intervention-content',
  templateUrl: './location-selection.component.html'
})
export class LocationSelectionModalComponent {
  constructor(
    public dialogRef: MatDialogRef<LocationSelectionModalComponent, Location>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  add(location: Location) {
    this.dialogRef.close(location);
  }
}

export interface DialogData {
  selectableLocations: Location[];
}