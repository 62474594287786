import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, PagingResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Project } from '../../models/project/project';
import { Version } from '../../models/project/version';
import { ToasterService } from 'angular2-toaster';

const strings = {
  titles : {
    added: 'Projet ajouté', notAdded: 'Projet non ajouté',
    edited: 'Projet modifié', notEdited: 'Projet non modifié',
    deleted: 'Projet supprimé', notDeleted: 'Projet non supprimé',
    uploaded: 'Image chargée', notUploaded: 'Image non chargée'
  },
  bodies: {
    internalError: 'Erreur interne',
    projectDoesntExsist: 'Projet inexistant',
    unauthorized: 'Opération non autorisée'
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) { }

  // Codes: PROJECT_RECOVERED | UNAUTHORIZED
  public getPage(pageSize: number, currentPage: number, filter: string): Observable<PagingResponse<Project>> {
    return this.http.get<PagingResponse<Project>>(environment.apiUrl + 'project/all?pageSize=' + pageSize + '&currentPage=' + (currentPage - 1) + '&filter=' + filter).pipe(map(
      res => {
        switch (res.code) {
          
          case Code.PROJECT_RECOVERED:
          var projects: Project[] = [];
          res.result.content.forEach(jsonProject => projects.push(Project.loadFromJSON(jsonProject)));
          res.result.content = projects;
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.bodies.unauthorized);
          break;

        }
        
        return res;
      }
    ))
  }

  // Codes: PROJECT_RECOVERED | PROJECT_DOESNT_EXSIST | UNAUTHORIZED
  public get(id: string): Observable<ApiResponse<Project>> {
    return this.http.get<ApiResponse<Project>>(environment.apiUrl + 'project/' + id).pipe(map(
      res => {
        switch (res.code) {

          case Code.PROJECT_RECOVERED:
          res.result = Project.loadFromJSON(res.result);
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.bodies.projectDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: PROJECT_CREATED | WRONG_PARAMS | PROJECT_ALREADY_EXSIST | UNAUTHORIZED
  public create(form: FormGroup): Observable<ApiResponse<Project>> {
    return this.http.post<ApiResponse<Project>>(environment.apiUrl + 'project', form.value).pipe(map(
      res => {
        switch (res.code) {

          case Code.PROJECT_CREATED:
          res.result = Project.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.added);
          break;

          case Code.WRONG_PARAMS:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.internalError);
          break;

          case Code.PROJECT_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: PROJECT_EDITED | PROJECT_DOESNT_EXSIST | PROJECT_ALREADY_EXSIST | UNAUTHORIZED
  public edit(id: string, form: FormGroup): Observable<ApiResponse<Project>> {
    return this.http.post<ApiResponse<Project>>(environment.apiUrl + 'project/' + id, form.value).pipe(map(
      res => {
        switch (res.code) {

          case Code.PROJECT_EDITED:
          res.result = Project.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.edited);
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notEdited, strings.bodies.projectDoesntExsist);
          break;

          case Code.PROJECT_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notEdited, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: PROJECT_DELETED | PROJECT_DOESNT_EXSIST | UNAUTHORIZED
  public delete(id: string): Observable<ApiResponse<any>> {
    return this.http.delete<ApiResponse<any>>(environment.apiUrl + 'project/' + id).pipe(map(
      res => {
        switch (res.code) {

          case Code.PROJECT_DELETED:
          this.toasterService.pop('success', strings.titles.deleted);
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notDeleted, strings.bodies.projectDoesntExsist);
          break;

          case Code.PROJECT_CANNOT_DELETE:
          this.toasterService.pop('error', "Le projet contient un version Installation liée à une ceinture");
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notDeleted, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: VERSION_RECOVERED | PROJECT_DOESNT_EXSIST | UNAUTHORIZED
  public getVersions(id: string): Observable<ApiResponse<Version[]>> {
    return this.http.get<ApiResponse<Version[]>>(environment.apiUrl + 'project/' + id + '/versions').pipe(map(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
          var versions: Version[] = [];
          res.result.forEach(jsonVersion => versions.push(Version.loadFromJSON(jsonVersion)));
          res.result = versions;
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.bodies.projectDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: PROJECT_EDITED | PROJECT_DOESNT_EXSIST | WRONG_PARAMS | UNAUTHORIZED
  public uploadCustomPicture(id: string, form: FormGroup): Observable<ApiResponse<any>> {
    var data = new FormData();
    data.append('file', form.value.file);
    return this.http.post<ApiResponse<any>>(environment.apiUrl + 'project/' + id + '/upload', data).pipe(map(
      res => {
        switch (res.code) {

          case Code.PROJECT_EDITED:
          this.toasterService.pop('success', strings.titles.uploaded);
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notUploaded, strings.bodies.projectDoesntExsist);
          break;

          case Code.WRONG_PARAMS:
          this.toasterService.pop('error', strings.titles.notUploaded, strings.bodies.internalError);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notUploaded, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: none
  public downloadCustomPicture(id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('accept', '*');
    return this.http.get(environment.apiUrl + 'project/' + id + '/download', { headers: headers, responseType: 'arraybuffer' })
  }


}
