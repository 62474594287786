import { Component } from '@angular/core';
import { DesignerService } from '../../../services/management/designer.service';
import { Router } from '@angular/router';
import { Designer } from 'src/app/models/management/designer';
import { Modal } from 'src/app/models/ui/modal';

@Component({
  selector: 'delete-designer-content',
  templateUrl: './delete-designer.component.html'
})
export class DeleteDesignerModalComponent extends Modal<Designer> {
  // Form
  submitted = false;

  constructor(
    private router: Router,
    private designerService: DesignerService
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  // Form
  onFormSubmit() {
    this.submitted = true;

    // Try delete
    this.designerService.delete(this.subject.id).subscribe(
      () => this.close(true),
      () => this.close()
    );
  }

}