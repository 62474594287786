<div class="modal-header">
  <h4 class="modal-title">Mettre à jour des UPC-V3</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Belts V3 -->
    <div class="form-group">
      <label>UPC-V3</label>
      <ng-select  [items]="upcv3"
                  [multiple]="true"
                  [closeOnSelect]="false"
                  bindLabel="upcNameId"
                  bindValue="id"
                  formControlName="upcv3" #select>
                  <ng-template ng-header-tmp>

                    <div>
                      <button class="btn btn-link"
                              (click)="this.select.close();onSelectAll();">Tout Mettre à jour </button>
                      
                    </div>
      
                  </ng-template>          
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [checked]="item$.selected">

          <span [ngClass]="item.hibernated ? 'text-primary': item.lastPollResult === 0 ? '':'text-danger'">{{ "   "+item.upcNameId }}</span>
          <span class="float-right">v.{{ item.generalParameters.upcFwVer }}</span>
        </ng-template>
      </ng-select>
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Mettre à jour</span>
    </button>
  </div>
</form>