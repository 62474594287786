import { GeneralParameters } from './../../../../models/upcv3/upc/generalParameters';
import { Component } from '@angular/core';
import { UPCV3Service } from '../../../../services/upcv3/upcv3.service';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Site } from 'src/app/models/management/site';
import { DefaultUPCV3ParamsService } from 'src/app/services/upcv3/defaultUPCV3Params.service';
import { Project } from 'src/app/models/project/project';
import { Version, VersionType } from 'src/app/models/project/version';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { intensities, daysCodes, DaysCode, DiffCo2Program } from 'src/app/models/upcv3/upc/diffCo2Program';
import { ProjectService } from 'src/app/services/project/project.service';
import { VersionService } from 'src/app/services/project/version.service';
import { Belt } from 'src/app/models/project/belt';
import { SiteService } from 'src/app/services/management/site.service';
import { Code } from 'src/app/models/ApiResponse';
import { ToasterService } from 'angular2-toaster';
import { UPCV3PostDto } from 'src/app/models/dto/UPCV3/UPCV3Post.dto';
import { DefaultUPCV3Params } from 'src/app/models/upcv3/defaultUPCV3Params';
import { DiffusionParameters } from 'src/app/models/upcv3/upc/diffusionParameters';
import { DiffCo2 } from 'src/app/models/upcv3/upc/diffCo2';
import { AlarmsParameters } from 'src/app/models/upcv3/upc/alarmsParameters';
import { CommunicationParameters } from 'src/app/models/upcv3/upc/communicationParameters';
import { TypedJSON } from 'typedjson';

@Component({
  selector: 'create-belt-content',
  templateUrl: './create-belt.component.html',
  styleUrls: ['./create-belt.component.css']
})
export class CreateBeltModalComponent extends Modal<Site> {
  // Project
  projects: Project[];
  selectedProjectId: string;
  project: Project;

  versions: Version[];
  selectedVersionId: string;
  version: Version;
  loadingVersion = false;

  selectedBeltId: string;

  // Belt
  defaultUPCV3Params: DefaultUPCV3Params;
  upcv3ToCreate: UPCV3PostDto;
  intensities: any[] = intensities;
  daysCodes: any[] = daysCodes;

  // form
  formSubmitted = false;

  constructor(
    private router: Router,
    private defaultUPCV3ParamsService: DefaultUPCV3ParamsService,
    private projectService: ProjectService,
    private versionService: VersionService,
    private siteService: SiteService,
    private toasterService: ToasterService,
  ) {
    super();

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  ngOnInit() {
    // Load general parameters
    this.loadGeneralParameters();

    // Load projects
    this.searchProjects();
  }

  // Project
  loadGeneralParameters() {
    this.defaultUPCV3ParamsService.get().subscribe({
      next: (res) => this.defaultUPCV3Params = res,
      error: (err) => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors du chargement des paramètres par défaut"),
    })
  }

  fakeSearch(term, item) { return item }

  searchProjects(filter: string = '') {
    this.projectService.getPage(10, 0, filter).subscribe(
      res => {
        switch (res.code) {
          case Code.PROJECT_RECOVERED:
            this.projects = res.result.content;
            break;
          case Code.UNAUTHORIZED:
            this.projects = [];
            break;
        }
      }
    )
  }

  loadVersions() {
    // Show only installation
    if (this.selectedProjectId) {
      this.projectService.getVersions(this.selectedProjectId).subscribe(
        res => {
          switch (res.code) {

            case Code.VERSION_RECOVERED:
              this.versions = res.result.filter(v => v.versionType == VersionType.INSTALLATION)
              break;

            case Code.PROJECT_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
              this.versions = [];
              break;

          }
        }
      )
    } else {
      this.selectedBeltId = null;
      this.selectedVersionId = null;
      this.version = null;
    }
  }

  versionSelected() {
    // Get project
    if (this.selectedProjectId && this.selectedVersionId) {
      this.loadingVersion = true;
      this.projectService.get(this.selectedProjectId).subscribe(
        res => {
          switch (res.code) {

            case Code.PROJECT_RECOVERED:
              this.project = res.result;

              // Get full version
              this.versionService.get(this.selectedVersionId).subscribe(
                res => {
                  switch (res.code) {

                    case Code.VERSION_RECOVERED:
                      this.version = res.result;
                      break;

                    case Code.VERSION_DOESNT_EXSIST:
                    case Code.UNAUTHORIZED:
                      break;

                  }

                  this.loadingVersion = false;
                }
              );
              break;

            case Code.PROJECT_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
              this.loadingVersion = false;
              this.version = null;
              break;

          }
        }
      )
    }
  }

  beltSelected() {
    if (this.selectedProjectId && this.selectedVersionId && this.selectedBeltId) {

      // Find belt
      var belt: Belt = this.version.belts.find(b => b.id == this.selectedBeltId);
      if (belt) {
        const upc = new UPCV3();
        upc.upcNameId = belt.name;
        upc.lat = this.project?.lat ?? 0;
        upc.lng = this.project?.lng ?? 0;

        let generalParameters = new GeneralParameters();
        generalParameters.co2PresOutRef1 = Math.round(belt.pressureInt1 * 1000);
        generalParameters.co2PresOutRef2 = Math.round(belt.pressureInt2 * 1000);
        generalParameters.co2PresOutRef3 = Math.round(belt.pressureInt3 * 1000);
        generalParameters.co2PresOutRef4 = Math.round(belt.pressureInt4 * 1000);
        generalParameters.co2PresOutRef5 = Math.round(belt.pressureInt5 * 1000);
        generalParameters.co2PresOutRef6 = Math.round(belt.pressureInt6 * 1000);
        generalParameters.co2PresOutRef7 = Math.round(belt.pressureInt7 * 1000);
        generalParameters.co2PresOutRef8 = Math.round(belt.pressureInt8 * 1000);
        generalParameters.co2PresOutRef9 = Math.round(belt.pressureInt9 * 1000);
        generalParameters.co2PresOutRef10 = Math.round(belt.pressureCalculated * 1000);
        generalParameters.upcTrapNum = belt.trapsNumber;
        generalParameters.co2FlowRefTrap = this.defaultUPCV3Params.co2FlowRefTrap;
        generalParameters.refFlowRateGperhour = this.defaultUPCV3Params.refFlowRateGperhour;
        upc.generalParameters = generalParameters;
        
        let diffusionParameters = new DiffusionParameters()
        let diffProg1 = new DiffCo2Program();
        diffProg1.daysCode = DaysCode[this.defaultUPCV3Params.daysCode1];
        diffProg1.start = this.defaultUPCV3Params.startTime1;
        diffProg1.end = this.defaultUPCV3Params.endTime1;
        diffProg1.intensity = this.defaultUPCV3Params.intensity1;

        let diffProg2 = new DiffCo2Program();
        diffProg2.daysCode = DaysCode[this.defaultUPCV3Params.daysCode2];
        diffProg2.start = this.defaultUPCV3Params.startTime2;
        diffProg2.end = this.defaultUPCV3Params.endTime2;
        diffProg2.intensity = this.defaultUPCV3Params.intensity2;

        let diffSunset = new DiffCo2()
        diffSunset.delay = this.defaultUPCV3Params.sunsetDelay;
        diffSunset.duration = this.defaultUPCV3Params.sunsetDuration;
        diffSunset.intensity = this.defaultUPCV3Params.sunsetIntensity;

        let diffSunrise = new DiffCo2()
        diffSunrise.delay = this.defaultUPCV3Params.sunriseDelay;
        diffSunrise.duration = this.defaultUPCV3Params.sunriseDuration;
        diffSunrise.intensity = this.defaultUPCV3Params.sunriseIntensity;

        diffusionParameters.diffCo2Program1 = diffProg1;
        diffusionParameters.diffCo2Program2 = diffProg2;
        diffusionParameters.diffCo2Sunrise = diffSunrise;
        diffusionParameters.diffCo2Sunset = diffSunset;

        upc.diffusionParameters = diffusionParameters;

        const alarmsParameters = new AlarmsParameters();
        alarmsParameters.alrResLowEn = this.defaultUPCV3Params.alrResLowEn;
        alarmsParameters.alrResEmptyEn = this.defaultUPCV3Params.alrResEmptyEn;
        alarmsParameters.alrPresInpEn = this.defaultUPCV3Params.alrPresInpEn;
        alarmsParameters.alrPresOutEn = this.defaultUPCV3Params.alrPresOutEn;
        alarmsParameters.alrFlowAvgEn = this.defaultUPCV3Params.alrFlowAvgEn;
        alarmsParameters.alrPowBackEn = this.defaultUPCV3Params.alrPowBackEn;
        alarmsParameters.alrPowDownEn = this.defaultUPCV3Params.alrPowDownEn;
        alarmsParameters.alrEmptyPress = this.defaultUPCV3Params.alrEmptyPress;
        alarmsParameters.alrEmptyVol = this.defaultUPCV3Params.alrEmptyVol;
        alarmsParameters.alrPresInpTol = this.defaultUPCV3Params.alrPresInpTol;
        alarmsParameters.alrPresOutTol = this.defaultUPCV3Params.alrPresOutTol;
        alarmsParameters.alrFlowSetTol = this.defaultUPCV3Params.alrFlowSetTol;
        alarmsParameters.alrResLowLevel = this.defaultUPCV3Params.alrResLowLevel;
        alarmsParameters.alrResEmptyFlow = this.defaultUPCV3Params.alrResEmptyFlow;
        alarmsParameters.alrSmsNum1 = this.defaultUPCV3Params.alrSmsNum0;

        upc.alarmsParameters = alarmsParameters;

        const communicationParamters = new CommunicationParameters();
        communicationParamters.comGsmName = this.defaultUPCV3Params.comGsmName;
        communicationParamters.comGsmPass = this.defaultUPCV3Params.comGsmPass;
        communicationParamters.comWebSrvUrl = this.defaultUPCV3Params.comWebSrvUrl;
        communicationParamters.comWiFiName = this.defaultUPCV3Params.comWiFiName;
        communicationParamters.comWiFiPass = this.defaultUPCV3Params.comWiFiPass;
        upc.communicationParameters = communicationParamters

        this.upcv3ToCreate = {
          upcNameId: upc.upcNameId,
          lat: upc.lat,
          lng: upc.lng,
          belt: belt.id,
          alarmsParameters: upc.alarmsParameters,
          communicationParameters: upc.communicationParameters,
          diffusionParameters: diffusionParameters,
          generalParameters: upc.generalParameters,
        };

      }
    }
  }



  // Form
  onFormSubmit() {
    this.formSubmitted = true;
    this.siteService.createUPCV3(this.subject.id, this.upcv3ToCreate).subscribe({
      next: (res) => {
        this.router.navigateByUrl('/upc/v3/' + res.id);
      },
      error: (err) => {
        this.toasterService.pop('error', 'Erreur', "Une erreur s'est produite lors de la création de l'UPC");
        this.router.navigateByUrl('/sites');
      }
    })
  }

}