<div class="card-body py-2 px-3">

  <h6 class="card-title">Valeurs par défaut</h6>

  <!-- Node Icon Size -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Taille de l'icône</label>
    <input type="number" class="form-control form-control-sm" placeholder="Taille des icônes" min="1" [(ngModel)]="gdp.version.defaultNodeIconSize">
  </div>

  <!-- Node Title Color -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Couleur du texte</label>
    <input type="color" class="form-control" placeholder="Couleur du texte" [(ngModel)]="gdp.version.defaultNodeTitleColor" style="height: 2rem;">
  </div>

  <!-- Node Title Background Color -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Couleur du fond</label>
    <input type="color" class="form-control" placeholder="Couleur du fond" [(ngModel)]="gdp.version.defaultNodeTitleBackgroundColor" style="height: 2rem;">
  </div>

  <!-- Node Title Size -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Taille du texte</label>
    <input type="number" class="form-control form-control-sm" placeholder="Taille du texte" min="1" [(ngModel)]="gdp.version.defaultNodeTitleSize">
  </div>

  <!-- Path Color -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Couleur du lien</label>
    <input type="color" class="form-control" placeholder="Couleur du lien" [(ngModel)]="gdp.version.defaultPathColor" style="height: 2rem;">
  </div>

  <!-- Path Width -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Largeur du lien</label>
    <input type="number" class="form-control form-control-sm" placeholder="Largeur du lien" min="1" [(ngModel)]="gdp.version.defaultPathWidth">
  </div>

  <!-- Path Diameter -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Diamètre du tube</label>
    <input type="number" class="form-control form-control-sm" placeholder="Diamètre du tube" min="1" [(ngModel)]="gdp.version.defaultPathDiameter">
  </div>

  <hr />

  <!-- Path Diameter -->
  <div class="form-group mb-2">
    <label for="name" class="col-form-label-sm m-0 p-0">Transparence</label>
    <input type="number" class="form-control form-control-sm" placeholder="Transparence" min="0" max="100" step="5" [(ngModel)]="gdp.version.opacity" (ngModelChange)="updateOpacity()">
  </div>

</div>