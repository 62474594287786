import { UPCV3 } from '../upcv3/upc/upcv3';
import { jsonMember, jsonArrayMember, jsonObject, TypedJSON } from 'typedjson';

export type JobStatus = "STARTED" | "ERROR" | "FINISHED" | "RETRYING";
export type JobType = "FirmwareUpdateJob" | "SyncJob";

export interface Job {
  upcv3: UPCV3;
  jobStatus: JobStatus,
  creationTimestamp: Date,
  updateTimestamp: Date,
  nextTry: Date,
  tryCount: number,
  jobType: JobType,
  progress: number,
}
@jsonObject
export class FirmwareJob implements Job {

  @jsonMember
  firmwareFilename: string;

  @jsonMember
  upcv3: UPCV3;

  @jsonMember
  jobStatus: JobStatus;

  @jsonMember
  creationTimestamp: Date;

  @jsonMember
  updateTimestamp: Date;

  @jsonMember
  nextTry: Date;

  @jsonMember
  tryCount: number;

  @jsonMember
  jobType: JobType;

  @jsonMember
  progress: number;

  static loadFromJson(json): FirmwareJob {
    let serializer = new TypedJSON(FirmwareJob)
    return serializer.parse(json);
  }
}

@jsonObject
export class SyncJob implements Job {

  @jsonMember
  parameters: string;

  @jsonMember
  upcv3: UPCV3;

  @jsonMember
  jobStatus: JobStatus;

  @jsonMember
  creationTimestamp: Date;

  @jsonMember
  updateTimestamp: Date;

  @jsonMember
  nextTry: Date;

  @jsonMember
  tryCount: number;

  @jsonMember
  jobType: JobType;

  @jsonMember
  progress: number;

  static loadFromJson(json): SyncJob {
    let serializer = new TypedJSON(SyncJob)
    return serializer.parse(json);
  }
}