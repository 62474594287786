import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { first, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;

  constructor(
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    // Check if logged
    if (authService.isLoggedIn) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
        username: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.submitted = false;
      this.toasterService.popAsync('error', "Erreur", "Le formulaire semble invalide");
      return;
    }

    // Try login
    this.authService.login(this.loginForm.value).pipe(
      first(),
      finalize(() => this.submitted = false)
    ).subscribe();
  }

}
