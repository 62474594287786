import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OperatorService } from '../../../services/management/operator.service';
import { Operator } from '../../../models/management/operator';
import { SiteService } from '../../../services/management/site.service';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'edit-operator-content',
  templateUrl: './edit-operator.component.html'
})
export class EditOperatorModalComponent extends Modal<Operator> implements OnInit {
  // Form
  usernameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  sites: any[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private operatorService: OperatorService,
    private siteService: SiteService,
    private toasterService: ToasterService,
  ) {
    super();

    // Form
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: '',
      lastName: '',
      firstName: '',
      phone: '',
      sites: [],
      isAdmin: '',
      isAlertsEnabled: '',
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  ngOnInit() {
    var sites = [];
    this.subject.sites.forEach(site => sites.push(site.id));

    // Update form
    this.form.setValue({
      username: this.subject.username,
      password: '',
      lastName: this.subject.lastName,
      firstName: this.subject.firstName,
      phone: this.subject.phone || '',
      sites: sites,
      isAdmin: this.subject.isAdmin || false,
      isAlertsEnabled: this.subject.isAlertsEnabled || false,
    });

    // Load sites
    this.siteService.getAll().subscribe(
      res => {
        this.sites = res;


      }, () => this.close()
    )
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try edit
    this.formSubmitted = true;
    this.operatorService.edit(this.subject.id, this.form).subscribe({
      next: (res) => this.close(true),
      error: (err: HttpErrorResponse) => {
        if(err.status == 409) {
          this.usernameAlreadyUsed = true;
            this.formSubmitted = false;
          this.toasterService.pop('error', "Error", "Un conflit avec les données sur le serveurs c'est produit, un utilisiteur existe déja avec une partie de ces informations.");
        } else {
          this.toasterService.pop('error', "Error", "Une erreur s'est produite");
        }
        this.close(false);
      }
    })
  }

}