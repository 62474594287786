<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">UPC tests</h4>

    </div>

    <div class="container">
      <div class="row align-items-start">
        <div class="col">
          <mat-slide-toggle (toggleChange)="toggleFilterMcuNotAssociatedToBelt()">Afficher uniquement les cartes non
            associées a une ceinture</mat-slide-toggle>
        </div>
        <div class="col">
          <mat-form-field>
            <input matInput placeholder="Filter" type="text" [formControl]="filterInputFormControl" [(ngModel)]="filterInputValue">
            <button class="clearFilterButton" mat-button *ngIf="filterInputValue" matSuffix mat-icon-button
              aria-label="Clear" (click)="filterInputValue=''">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <mat-divider></mat-divider>


    <div class="container-fluid mat-elevation-z8">
      <div class="loading-shade h-100" *ngIf="isLoadingResults">
        <mat-spinner color="accent" diameter="40" *ngIf="isLoadingResults"></mat-spinner>
      </div>

      <div class="table-container">
        <table mat-table [dataSource]="datasource" matSort class="mat-elevation-z8" (matSortChange)="sortData($event)">

          <ng-container matColumnDef="upcMcuUid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> McuId </th>
            <td mat-cell *matCellDef="let element"> {{element.upcMcuUid}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="id_boitier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID Boitier </th>
            <td mat-cell *matCellDef="let element"> {{element.id_boitier}} </td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="upcv3Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ceinture </th>
            <td mat-cell *matCellDef="let element"> {{element.upcv3Name}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="first_test">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date du premier test </th>
            <td mat-cell *matCellDef="let element"> {{element.first_test.toLocaleDateString('fr-FR')}} </td>
          </ng-container>

          <ng-container matColumnDef="last_test">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date du dernier test </th>
            <td mat-cell *matCellDef="let element"> {{element.last_test.toLocaleDateString('fr-FR')}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getTableEntry(row)"></tr>
        </table>
      </div>
      <!-- Pagination -->
      <div class="col-12 mb-3">
        <mat-paginator #paginator class="paginator" showFirstLastButtons [length]="totalData" [pageSizeOptions]="pageSizeOptions">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>