import { OnInit, Component, Input, HostListener } from "@angular/core";
import { CAD, Mode, Tool } from "../../../../../../models/project/cad";
import { NodeType } from "../../../../../../models/project/node";
import { TrapsColor } from "../../../../../../models/project/belt";

import * as PIXI from 'pixi.js';
import '@pixi/canvas-graphics';
import '@pixi/graphics-extras';
@Component({
  selector: 'cad-pixi',
  styleUrls: ['./pixi.component.css'],
  templateUrl: './pixi.component.html'
})
export class CADPixiComponent implements OnInit {
  // NF
  @Input('cad') cad: CAD;
  pixi: any;
  mousePosition: MousePosition = MousePosition.OUT;

  // UI
  belts: any;
  protectedAreas: any;
  backgroundImage: any;
  controlTexture;
  controlShelteredTexture;
  hexagonalTrap1Texture;
  hexagonalTrap2Texture;
  hexagonalTrap3Texture;
  rectangularTrap1Texture;

  constructor() {}

  ngOnInit() {
    // Load pixi
    this.pixi = new PIXI.Application({
      width: document.getElementById('cad-pixi-container').offsetWidth,
      height: document.getElementById('cad-pixi-container').offsetHeight,
      backgroundColor: 0x808080
    });
    document.getElementById('cad-pixi-container').appendChild(this.pixi.view);


    // Init stage
    this.cad.translatePosition.x = document.getElementById('cad-pixi-container').offsetWidth / 2;
    this.cad.translatePosition.y = document.getElementById('cad-pixi-container').offsetHeight / 2;
    this.zoomChanged();
    this.pixi.renderer.resolution = 2;


    // Load background image
    this.backgroundImage = PIXI.Sprite.from(`https://maps.googleapis.com/maps/api/staticmap?center=${ this.cad.project.lat },${ this.cad.project.lng }&zoom=${ this.cad.project.zoom }&size=${ this.cad.googleMapsParams.width }x${ this.cad.googleMapsParams.height }&scale=${ this.cad.googleMapsParams.scale }&maptype=${ this.cad.version.mapType }&style=feature:all|element:labels|visibility:off&key=AIzaSyDNhsUG6VjyDQ_R_gZ2cT2ktTBbsy6M_uQ`);
    this.backgroundImage.texture.baseTexture.on('loaded', () => {
      this.backgroundImage.setTransform(-this.backgroundImage.width / 2, -this.backgroundImage.height / 2);
      this.opacityChanged();
    });
    this.backgroundImage.interactive = true;
    this.backgroundImage.mouseover = () => { this.mousePosition = MousePosition.IN }
    this.backgroundImage.mousedown = (e) => {
      // Unselect
      this.cad.unSelectBelt();

      // Disable contextMenu
      if (e.data.buttons == 1 && this.cad.showContextMenu) this.cad.showContextMenu = false;

      // TODO: When drawing
      if (this.cad.tool == Tool.DRAW) {
        return;
      }

      // TODO: When editing protectedArea
      if (this.cad.selectedProtectedArea) {
        
      }

      // Move map if no nodes selected
      if (
        (this.mousePosition == MousePosition.IN && e.data.buttons == 1) &&
        (this.cad.selectedNodes.length == 0 && !this.cad.selectedElectricalOutlet)
      ) {
        this.cad.startDragOffset.x = e.data.global.x - this.cad.translatePosition.x;
        this.cad.startDragOffset.y = e.data.global.y - this.cad.translatePosition.y;
      }

    }
    this.backgroundImage.mousemove = (e) => {
      var clickPos = this.getClickPosition(e),
          correctedPos = this.cad.rotate(0, 0, clickPos.x, clickPos.y, this.cad.version.rotation);

      // Move element if selected
      if (this.mousePosition == MousePosition.IN && e.data.buttons == 1 && (this.cad.selectedNodes.length == 1 || this.cad.selectedElectricalOutlet)) {
        var position = this.cad.getCoordinates(correctedPos.x, correctedPos.y);
        this.cad.version.setNodePosition(this.cad.selectedNodes[0].id, position.lat, position.lng);
        this.drawBelts();
      }


      // Move map if no element selected
      if (
        this.mousePosition == MousePosition.IN && e.data.buttons == 1 &&
        this.cad.selectedNodes.length == 0 && !this.cad.selectedElectricalOutlet && this.cad.selectedProtectedAreaPointIndex == null
      ) {
        this.cad.translatePosition.x = e.data.global.x - this.cad.startDragOffset.x;
        this.cad.translatePosition.y = e.data.global.y - this.cad.startDragOffset.y;
        this.zoomChanged();
      }

    }
    this.backgroundImage.mouseout = () => { this.mousePosition = MousePosition.OUT }
    this.pixi.stage.addChild(this.backgroundImage);

    // Load belts images
    this.controlTexture = PIXI.Texture.from('assets/img/markers/regie.png');
    this.controlShelteredTexture = PIXI.Texture.from('assets/img/markers/regie_abris.png');
    this.hexagonalTrap1Texture = PIXI.Texture.from('assets/img/markers/trap_hexa_1.png');
    this.hexagonalTrap2Texture = PIXI.Texture.from('assets/img/markers/trap_hexa_2.png');
    this.hexagonalTrap3Texture = PIXI.Texture.from('assets/img/markers/trap_hexa_3.png');
    this.rectangularTrap1Texture = PIXI.Texture.from('assets/img/markers/trap_rect_1.png');

    // Draw
    this.drawBelts();
    this.drawProtectedAreas();
  }


  /* Listeners */
  @HostListener('document:CADRotationChanged')
  rotationChanged() {
    this.pixi.stage.setTransform(
      this.cad.translatePosition.x, this.cad.translatePosition.y, // Position x, y
      this.cad.version.zoom, this.cad.version.zoom,               // Scale x, y
      this.cad.version.rotation * Math.PI / 180                   // Rotation (radians)
    );
    this.drawBelts();
    this.drawProtectedAreas();
  }

  @HostListener('document:CADZoomChanged')
  zoomChanged() {
    this.pixi.stage.setTransform(
      this.cad.translatePosition.x, this.cad.translatePosition.y, // Position x, y
      this.cad.version.zoom, this.cad.version.zoom,               // Scale x, y
      this.cad.version.rotation * Math.PI / 180                   // Rotation (radians)
    );
  }

  @HostListener('document:CADOpacityChanged')
  opacityChanged() {
    this.backgroundImage.alpha = this.cad.version.mapOpacity / 100;
  }

  @HostListener('document:CADMapTypeChanged')
  mapTypeChanged() {
    var x = this.backgroundImage.x, y = this.backgroundImage.y;
    this.pixi.stage.removeChild(this.backgroundImage);
    this.backgroundImage = PIXI.Sprite.from(`https://maps.googleapis.com/maps/api/staticmap?center=${ this.cad.project.lat },${ this.cad.project.lng }&zoom=${ this.cad.project.zoom }&size=${ this.cad.googleMapsParams.width }x${ this.cad.googleMapsParams.height }&scale=${ this.cad.googleMapsParams.scale }&maptype=${ this.cad.version.mapType }&style=feature:all|element:labels|visibility:off&key=AIzaSyDNhsUG6VjyDQ_R_gZ2cT2ktTBbsy6M_uQ`);
    this.backgroundImage.texture.baseTexture.on('loaded', () => {
      this.backgroundImage.setTransform(x, y);
      this.opacityChanged();
    });
    this.pixi.stage.addChild(this.backgroundImage);
  }

  @HostListener('document:CADIconsSizeChanged')
  iconsSizeChanged() {
    this.drawBelts();
  }

  @HostListener('document:CADSelectionChanged')
  @HostListener('document:CADModeChanged')
  selectionChanged() {
    this.drawBelts();
    this.drawProtectedAreas();
    //this.drawElectric();
  }

  @HostListener('document:CADBeltChanged')
  beltChanged() {
    this.drawBelts();
    //this.drawElectric();
  }


  /* Draw methods */
  drawBelts() {
    this.pixi.stage.removeChild(this.belts);
    this.belts = new PIXI.Graphics();
    this.pixi.stage.addChild(this.belts);

    // Move it to the beginning of the line
    this.belts.position.set(0, 0);

    // Draw lines first
    this.cad.version.belts.forEach(belt => {
      belt.branches.filter(b => b.nodes.length > 0).forEach(branch => {
        // Color
        var color = this.colorConverter(branch.color);
        if (this.cad.mode == Mode.ELECTRIC) color = this.colorConverter('#303030');

        // Draw inter-branch lines
        if (branch.parentBranch != null && branch.parentNode != null) {
          // Get branch and node
          var parentNode = this.cad.version.getNode(branch.parentNode),
              parentNodePosition = parentNode != null ? this.cad.getPosition(parentNode.lat, parentNode.lng) : null;
              
          // Get first node of currentBranch
          var firstNode = branch.nodes[0];
          var firstNodePosition = this.cad.getPosition(firstNode.lat, firstNode.lng);

          // Check
          if (parentNode != null && firstNode != null) {
            if (firstNode.prevLinkBuried) this.belts.addChild(this.drawdash(firstNodePosition.x, firstNodePosition.y, parentNodePosition.x, parentNodePosition.y, branch.width, color));
            else this.belts.lineStyle(branch.width, color).moveTo(firstNodePosition.x, firstNodePosition.y).lineTo(parentNodePosition.x, parentNodePosition.y);
          }
        }
        
        branch.nodes.forEach((node, index) => {
          if (index > 0) {
            // Nodes position
            var previousNodePosition = this.cad.getPosition(branch.nodes[index - 1].lat, branch.nodes[index - 1].lng);
            var nodePosition = this.cad.getPosition(node.lat, node.lng);

            // Draw line
            if (node.prevLinkBuried) this.belts.lineStyle(branch.width, color).moveTo(nodePosition.x, nodePosition.y).lineTo(previousNodePosition.x, previousNodePosition.y);
            else this.belts.lineStyle(branch.width, color).moveTo(nodePosition.x, nodePosition.y).lineTo(previousNodePosition.x, previousNodePosition.y);
          }
        })
      })
    });

    // Then draw markers & number
    this.cad.version.belts.forEach((belt, index) => {
      var suffixIndex = [{ suffix: '', index: 1 }];
      belt.branches.forEach(branch => {
        // Color
        var color = this.colorConverter(branch.numbersColor);
        if (this.cad.mode == Mode.ELECTRIC) color = this.colorConverter('#303030');
        
        if (suffixIndex.findIndex(s => s.suffix === branch.suffix) == -1) suffixIndex.push({ suffix: branch.suffix, index: 1 });
        
        branch.nodes.forEach(node => {
          // Get position From LatLng
          var nodePosition = this.cad.getPosition(node.lat, node.lng);
          
          // Icon & Number
          var marker;
          switch (node.nodeType) {

            case NodeType.CONTROL:
            marker = this.genMarker(belt.controlSheltered ? this.controlShelteredTexture : this.controlTexture, nodePosition.x, nodePosition.y);
            break;

            case NodeType.HEXAGONAL_TRAP:
            case NodeType.RECTANGULAR_TRAP:
            suffixIndex.find(s => s.suffix === branch.suffix).index++;
            if (this.cad.selectedBelt == null || this.cad.selectedBelt.id == belt.id) {
              var number = '';
              if (this.cad.version.belts.length > 1 && this.cad.selectedBelt == null) number = `${index + 1}-${suffixIndex.find(s => s.suffix === branch.suffix).index + branch.suffix}`;
              else number = suffixIndex.find(s => s.suffix === branch.suffix).index + branch.suffix;
              
              var distanceFromOrigin = this.cad.distance(0, 0, nodePosition.x, nodePosition.y) / 100;
              this.belts.addChild(this.genNodeNumber(number, color, nodePosition.x + distanceFromOrigin * Math.cos(-this.cad.version.rotation * Math.PI / 180),
                nodePosition.y + distanceFromOrigin * Math.sin(-this.cad.version.rotation * Math.PI / 180), node.textDistance, node.textAngle));
            }
            if (node.nodeType == NodeType.HEXAGONAL_TRAP) {
              switch (belt.trapsColor) {
                case TrapsColor.WOOD: marker = this.genMarker(this.hexagonalTrap1Texture, nodePosition.x, nodePosition.y); break;
                case TrapsColor.BROWN: marker = this.genMarker(this.hexagonalTrap2Texture, nodePosition.x, nodePosition.y); break;
                case TrapsColor.GREEN: marker = this.genMarker(this.hexagonalTrap3Texture, nodePosition.x, nodePosition.y); break;
              }
            } else marker = this.genMarker(this.rectangularTrap1Texture, nodePosition.x, nodePosition.y);
            break;

            case NodeType.INTERMEDIATE:
            marker = this.genCircle(nodePosition.x, nodePosition.y, this.cad.version.iconsSize / 10, color, color);
            break;

          }

          // Add listener
          marker.interactive = true;
          marker.mousedown = () => { this.cad.selectNode(node.id, false) }

          // Add marker
          if (this.cad.selectedNodes.find(n => n.id === node.id) != null) {
            var filter = new PIXI.filters.ColorMatrixFilter();
            filter.browni(true);
            marker.filters = [ filter ];
          }
          this.belts.addChild(marker);
        })
      })
    })
  }

  drawProtectedAreas() {
    this.pixi.stage.removeChild(this.protectedAreas);
    this.protectedAreas = new PIXI.Graphics();
    this.pixi.stage.addChild(this.protectedAreas);
    
    // Draw protected area
    this.cad.version.belts.forEach(belt => {
      belt.protectedAreas.forEach(protectedArea => {
        
        // Add points
        var points = [];
        protectedArea.points.forEach(point => {
          var pointPosition = this.cad.getPosition(point[0], point[1]);
          points.push(pointPosition.x, pointPosition.y);
        });

        // Generate texture
        /*let texture: PIXI.Texture = this.pixi.renderer.generateTexture(this.genPattern(protectedArea.width, this.colorConverter(protectedArea.color)));
        var tilingSprite = new PIXI.extras.TilingSprite(texture, 800, 400);
        tilingSprite.rotation = -this.cad.version.rotation * Math.PI / 180;

        // Create Polygone
        var polygone = new PIXI.Graphics();
        polygone.position.set(-this.cad.googleMapsParams.width * this.cad.googleMapsParams.scale / 2, -this.cad.googleMapsParams.height * this.cad.googleMapsParams.scale / 2);
        polygone.drawPolygon(points);
        polygone.addChild(tilingSprite);
        this.protectedAreas.addChild(polygone);*/
        //tilingSprite.mask = polygone;

        //this.protectedAreas.beginFill(new PIXI.Sprite(texture));
        //this.protectedAreas.drawPolygon(points);
        //this.protectedAreas.endFill();
      });
    });
  }

  private genMarker(texture: any, x, y) {
    var marker = new PIXI.Sprite(texture);
    marker.width = this.cad.version.iconsSize;
    marker.height = this.cad.version.iconsSize;
    marker.position.set(x, y);
    marker.pivot.set(this.cad.version.iconsSize * 1.25, this.cad.version.iconsSize * 1.25);
    marker.rotation = -this.cad.version.rotation * Math.PI / 180;
    return marker;
  }

  private genNodeNumber(number, backgroundColor, x, y, textDistance, textAngle) {
    var nodeNumber = new PIXI.Container(),
        text = new PIXI.Text(number, { fontFamily : 'Verdana', fontSize: this.cad.version.iconsSize / 2, fill : 0xffffff, align : 'center' }),
        background = new PIXI.Sprite(PIXI.Texture.WHITE),
        padding = this.cad.version.iconsSize / 2 * 0.5;
    background.tint = backgroundColor;
    background.width = text.width + padding * 2;
    background.height = text.height + padding;

    text.position.set(padding, padding / 2)
    nodeNumber.position.set(x, y);
    
    nodeNumber.pivot.set(background.width / 2, background.height / 2);
    nodeNumber.rotation = -textAngle;

    // Translate
    x += (textDistance + this.cad.version.iconsSize) * Math.cos(textAngle * Math.PI / 180);
    y += (textDistance + this.cad.version.iconsSize) * Math.sin(textAngle * Math.PI / 180);

    nodeNumber.position.set(x, y);
    nodeNumber.rotation = -this.cad.version.rotation * Math.PI / 180;

    nodeNumber.addChild(background, text);
    return nodeNumber;
  }

  private genCircle(x, y, radius, fillColor, borderColor) {
    var circle = new PIXI.Graphics();
    circle.beginFill(fillColor, 1); 
    circle.lineStyle(1, borderColor);
    circle.drawCircle(x, y, radius);
    circle.endFill(); 
    return circle;
  }

  private genPattern(width, color) {
    var graphics = new PIXI.Graphics(),
        height = width / 2,
        lineWidth1 = 3 * width / 200,
        lineWidth2 = 2 * width / 200;

    graphics.lineStyle(lineWidth1, color).moveTo(0, -height).lineTo(-width, 0);
    graphics.lineStyle(lineWidth2, color).moveTo(0, -height + height / 20).lineTo(-width + width / 20, 0);
    graphics.lineStyle(lineWidth2, color).moveTo(-width / 20, -height).lineTo(-width, -height / 20);

    graphics.lineStyle(lineWidth1, color).moveTo(0, -height / 100).lineTo(-width / 100, 0);



    var x0 = width + (width / 2), x1 = - (width / 2),
        y0 = - (height / 2), y1 = height + (height / 2),

        offset = height / 20,
        offset2 = height + height / 1.14,
        offset3 = height + height;

    //graphics.lineStyle(lineWidth1, color).moveTo(0, 5).lineTo(5, 0);
    //graphics.lineStyle(lineWidth1, color).moveTo(x0 + offset2 - width / 2, y0 - height / 2).lineTo(x1 + offset2 - width / 2, y1 - height / 2);
    //graphics.lineStyle(lineWidth1, color).moveTo(x0 - offset - width / 2, y0 - height / 2).lineTo(x1 - offset - width / 2, y1 - height / 2);
    //graphics.lineStyle(lineWidth1, color).moveTo(x0 + offset - width / 2, y0 - height / 2).lineTo(x1 + offset - width / 2, y1 - height / 2);


    //graphics.lineStyle(lineWidth2, color).moveTo(offset, -height + offset).lineTo(-width + offset, offset);
    //graphics.lineStyle(lineWidth2, color).moveTo(- offset, -height - offset).lineTo(-width - offset, -offset);

    return graphics;
  }

  private drawdash(x0, y0, x1, y1, lineWidth, color) {
    function angle(x0, y0, x1, y1) {
      var diff_x = Math.abs(x1 - x0),
          diff_y = Math.abs(y1 - y0),
          cita;
      if (x1 > x0) {
        if (y1 > y0) cita= 360*Math.atan(diff_y/diff_x)/(2*Math.PI);
        else {
          if (y1 < y0) cita=-360*Math.atan(diff_y/diff_x)/(2*Math.PI);
          else cita = 0;
        }
      } else {
        if (x1 < x0) {
          if (y1 > y0) cita = 180 - 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
          else {
            if (y1 < y0) cita = 180 + 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
            else cita = 180;
          }
        } else { 
          if (y1 > y0) cita = 90;
          else {
            if (y1 < y0) cita = -90;
            else cita=0;
          }
        }
      }
      return cita;
    }
    
    var dashed = new PIXI.Graphics();
    dashed.lineStyle(lineWidth, color, 1);
    dashed.moveTo(0, 0);
    dashed.lineTo(lineWidth, 0);
    dashed.moveTo(lineWidth * 3, 0);
    dashed.lineTo(lineWidth * 4, 0);

    var dashedtexture = dashed.generateCanvasTexture(1, 1) as PIXI.Texture,
        linelength = Math.pow(Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2), 0.5),
        tilingSprite = new PIXI.TilingSprite(dashedtexture, linelength, lineWidth);
    tilingSprite.x = x0;
    tilingSprite.y = y0;
    tilingSprite.rotation = angle(x0, y0, x1, y1) * Math.PI / 180;
    tilingSprite.pivot.set(lineWidth / 2, lineWidth / 2);
    return tilingSprite;
  }

  private colorConverter(color: string): number {
    return parseInt(`0x${ color.substr(1, color.length) }`);
  }

  /* Other functions */
  getClickPosition(e) {
    var rect = document.getElementById('cad-pixi-container').getBoundingClientRect();
    return {
      x: ((e.data.originalEvent.clientX - rect.left) - this.cad.translatePosition.x) / this.cad.version.zoom,
      y: ((e.data.originalEvent.clientY - rect.top)- this.cad.translatePosition.y) / this.cad.version.zoom
    }
  }

}

export enum MousePosition {
  OUT = 'OUT',
  IN = 'IN'
}