<div class="modal-header">
  <h4 class="modal-title">Nouveau Dessinateur</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Username -->
    <div class="form-group">
      <label for="username">Email *</label>
      <input type="email" class="form-control" id="username" placeholder="Email *" formControlName="username" [ngClass]="{ 'is-invalid': usernameAlreadyUsed || f.username.errors }" (keyup)="usernameAlreadyUsed = false">
      <div *ngIf="usernameAlreadyUsed || f.username.errors" class="invalid-feedback">
        <div *ngIf="f.username.errors && f.username.errors.required">Email obligatoire</div>
        <div *ngIf="usernameAlreadyUsed">Email déjà utilisé</div>
      </div>
    </div>

    <!-- Password -->
    <div class="form-group">
      <label for="password">Mot de passe *</label>
      <input type="text" class="form-control" id="username" placeholder="Mot de passe *" formControlName="password" [ngClass]="{ 'is-invalid': f.password.errors }" (keyup)="usernameAlreadyUsed = false">
      <div *ngIf="f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors && f.password.errors.required">Mot de passe obligatoire</div>
      </div>
    </div>

    <!-- Last name -->
    <div class="form-group">
      <label for="lastName">Nom</label>
      <input type="text" class="form-control" id="lastName" placeholder="Nom" formControlName="lastName">
    </div>

    <!-- First name -->
    <div class="form-group">
      <label for="firstName">Prénom</label>
      <input type="text" class="form-control" id="firstName" placeholder="Prénom" formControlName="firstName">
    </div>

    <!-- First name -->
    <div class="form-group">
      <label for="phone">Téléphone</label>
      <input type="tel" class="form-control" id="phone" placeholder="Téléphone" formControlName="phone">
    </div>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Ajouter</span>
    </button>
  </div>
</form>