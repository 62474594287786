<p class="text-center" *ngIf="events && events.length == 0">Aucune donnée</p>
<div style="height: 500px" *ngIf="events && events.length > 0">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          style="width: 5vw"
          mwlCalendarPreviousView
          [view]="view"
          [daysInWeek]="daysInWeek"
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange()"
        >
          Previous
        </div>
        <div
          class="btn btn-outline-secondary"
          style="width: 5vw"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          Today
        </div>
        <div
          class="btn btn-primary"
          mwlCalendarNextView
          style="width: 5vw"
          [view]="view"
          [daysInWeek]="daysInWeek"
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange()"
        >
          Next
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row justify-content-md-center">
      <h3>{{ viewDate | calendarDate:('week' + 'ViewTitle'):'fr':0:[]:[daysInWeek]}}</h3>
    </div>
  </div>
  <div class="row justify-content-md-center">
    
  </div>
  <mwl-calendar-week-view
    precision="minutes"
    [viewDate]="viewDate"
    [daysInWeek]="daysInWeek"
    [events]="events"
    [refresh]="refresh"
    [weekStartsOn]="weekStartsOn"
    [weekendDays]="weekendDays"
    locale="fr"
    hourSegmentHeight="12"
    hourSegments="2"
    minimumEventHeight="20"
    (eventTimesChanged)="eventTimesChanged($event)"
  >
  </mwl-calendar-week-view>
</div>
