import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
@jsonObject
export class CommunicationParameters {
	
	@jsonMember
	comWiFiName: string = "";
	
	@jsonMember
	comWiFiPass: string = "";
	
	@jsonMember
	comWiFiIpAdr: string = "";
	
	@jsonMember
	comGsmName: string = "";
	
	@jsonMember
	comGsmPass: string = "";
	
	@jsonMember
	comGsmIpAdr: string = "0.0.0.0";
	
	@jsonMember
	comGsmMode: number = 0;
	
	@jsonMember
	comGsmLevel: number = 0;
	
	@jsonMember
	comWebSrvUrl: string = "";
	
	@jsonMember
	comMdmApnId: string = "";
	
	@jsonMember
	comMdmApnId2: string = "orange.m2m";
	
	@jsonMember
	comMdmApnUser: string = "orange";
	
	@jsonMember
	comMdmApnPass: string = "orange";
	
	@jsonMember
	comWifiApCh: number = 11;

	get comGsmModeString(): string {
		switch (this.comGsmMode) {
			case 0: return 'Non enregistré';
			case 1: return '2G GPRS';
			case 2: return '2G EDGE';
			case 3: return '3G WCDMA';
			case 4: return '3G HSDPA';
			case 5: return '3G HSUPA';
			case 6: return '3G HSDPA/HSUPA';
			case 7: return '4G';
		}
	}

	static loadFromJSON(json): CommunicationParameters {
		const serializer = new TypedJSON(CommunicationParameters);
    return serializer.parse(json);	}
}