import { Component, OnInit } from '@angular/core';
import { Stock } from '../../../models/management/stock';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StockService } from '../../../services/management/stock.service';
import { BottleTypeService } from '../../../services/management/bottleType.service';
import { BottleType } from '../../../models/management/bottleType';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { ToasterService } from 'angular2-toaster';
import { StockPutDto } from 'src/app/models/dto/StockPutDto';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'edit-stock-content',
  templateUrl: './edit-stock.component.html'
})
export class EditStockModalComponent extends Modal<Stock> implements OnInit {
  // Form
  nameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  bottleTypes: BottleType[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private stockService: StockService,
    private bottleTypeService: BottleTypeService,
    private toasterService: ToasterService
  ) {
    super();

    // Form
    this.form = this.fb.group({ name: ['', Validators.required], bottleTypes: [] });

    // On location change
    this.router.events.subscribe(() => this.close());
  }

  ngOnInit() {
    var bottleTypes = [];
    this.subject.bottleTypes.forEach(bottleType => bottleTypes.push(bottleType.id));

    // Update form
    this.form.setValue({ name: this.subject.name, bottleTypes: bottleTypes });

    // Load bottleTypes
    this.bottleTypeService.getAll().subscribe({
      next: (bottleTypes) => this.bottleTypes = bottleTypes,
      error: err => {
        this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la recupération des informations du stock");
        this.close();
      },
    })
  }

  // Form
  get f() { return this.form.controls }

  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Try edit
    this.formSubmitted = true;

    let formData: StockPutDto = {
      bottleTypes: this.form.value.bottleTypes,
      name: this.form.value.name,
    }

    this.stockService.edit(this.subject.id, formData).pipe(
      finalize(() => this.formSubmitted = false)
    ).subscribe({
      next: (response) => this.close(true),
      error: (err) => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la modification du stock"),
    });
  }

}