import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Pages
import { TasksComponent } from "./tasks.component";
import { MaterialModule } from '../../material-module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: [
    // Pages
    TasksComponent,
  ]
})
export class TasksModule { }