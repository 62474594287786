<!-- Message -->
<div id="cad-message" class="card">
  <div class="card-body pr-2 pt-1 pl-2 pb-1">
    <small class="font-weight-bold">
      <!-- Previous node distance -->
      <ng-container *ngIf="cad.selectedNodes.length == 1 && !cad.tool">Distance du noeud précédent : {{ cad.selectedNodes[0].prevDistance.toFixed(2) }} m</ng-container>

      <!-- Control Message -->
      <ng-container *ngIf="cad.tool == 'DRAW'">Cliquez sur l'image pour ajouter des points<br>Sélectionnez un point pour le déplacer</ng-container>

      <!-- Parent node -->
      <ng-container *ngIf="cad.tool == 'SELECT_PARENT_NODE'">Cliquez sur le noeud parent</ng-container>

      <!-- Parent node -->
      <ng-container *ngIf="cad.tool == 'SELECT_PIXELS_RATIO'">Cliquez sur l'image pour ajouter le point de départ et d'arrivée</ng-container>
    </small>
  </div>
</div>