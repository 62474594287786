import { BottleEvent, BottleHistory } from "../management/bottle";
import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { Site } from "../management/site";
import { Operator } from "../management/operator";
import { UPCV3 } from "./upc/upcv3";
import { GroupUPCV3 } from "../GroupUPCV3/GroupUPCV3";

export enum Origin {
  WEB = "WEB", MOBILE= "MOBILE", 
}
@jsonObject
export class InterventionV3 {
  @jsonMember
  id: number;

  @jsonMember
  dateCreated: Date;
  
  @jsonMember
  start: Date;
  
  @jsonMember
  end: Date;
  
  @jsonMember
  objet: string;

  @jsonMember
  rapport: string;
  
  @jsonMember(() => Operator)
  operateur: Operator;
  
  @jsonArrayMember(() => Operator)
  intervenants: Operator[];
  
  @jsonArrayMember(() => BottleEvent)
  bottleEvents: Array<BottleEvent>;
  
  @jsonMember(() => Site)
  site: Site;

  @jsonMember(() => UPCV3)
  upcv3: UPCV3;

  @jsonMember(() => GroupUPCV3)
  groupUPCV3: GroupUPCV3;

  @jsonMember
	co2B1ActualContent: number;

	@jsonMember
	co2B2ActualContent: number;

	@jsonMember
	co2B1ContentToDate: number;

	@jsonMember
	co2B2ContentToDate: number;

	@jsonMember
	co2TotalAddedB1: number;

	@jsonMember
	co2TotalAddedB2: number;
	
	@jsonMember
	co2TotalRealAddedB1: number;

	@jsonMember
	co2TotalRealAddedB2: number;

	@jsonMember
	co2ReplaceAllB1: boolean;

	@jsonMember
	co2ReplaceAllB2: boolean;

  @jsonMember
  b1Empty: boolean;

  @jsonMember
  b2Empty: boolean;

  @jsonArrayMember(() => BottleHistory)
  bottlesBeforeIntervention: Array<BottleHistory>;

  @jsonArrayMember(() => BottleHistory)
  bottlesAfterIntervention: Array<BottleHistory>;

  @jsonMember
  origin: Origin;

  static loadFromJSON(json): InterventionV3 {
    let serializer = new TypedJSON(InterventionV3);
		return serializer.parse(json);
  }
}