import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// ngx-charts
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';

// Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// Pages
import { SitesComponent } from "./sites.component";
import { SiteComponent } from "./site/site.component";
import { UPCComponent } from "./site/upc/upc.component";
import { ParametersComponent } from "./site/upc/parameters/parameters.component";
import { InterventionComponent } from "./site/interventions/intervention/intervention.component";
import { TestsComponent } from "./site/upc/tests/tests.component";
import { EventsComponent } from "./site/upc/events/events.component";
import { StatsComponent } from "./site/upc/stats/stats.component";
import { InterventionsComponent } from "./site/interventions/interventions.component";
import { DiffCanvasComponent } from "./site/upc/parameters/diff-canvas/diff-canvas.component";
import { AddInterventionComponent } from "./site/interventions/add-intervention/add-intervention.component";
import { GroupParametersComponent } from "./site/upc/parameters/group/groupParameters.component";

// Modals
import { AddSiteComponent } from "./add-site/add-site.component";
import { EditSiteModalComponent } from "./edit-site/edit-site.component";
import { DeleteSiteModalComponent } from "./delete-site/delete-site.component";
import { AssociateBeltModalComponent } from "./site/associate-belt/associate-belt.component";
import { DissociateBeltModalComponent } from "./site/dissociate-belt/dissociate-belt.component";
import { DeleteInterventionModalComponent } from "./site/upc/delete-intervention/delete-intervention.component";
import { LinkUPCV3ModalComponent } from "./site/upc/link-upcv3/link-upcv3.component";
import { InitUPCModalComponent } from "./site/upc/init-upc/init-upc.component";
import { WipeUPCModalComponent } from "./site/upc/wipe-upc/wipe-upc.component";
import { AddBottleModalComponent } from "./site/interventions/add-intervention/add-bottle/add-bottle.component";
import { RestoreUPCModalComponent } from "./site/upc/restore-upc/restore-upc.component";
import { CreateBeltModalComponent } from "./site/create-belt/create-belt.component";
import { AddBottlesComponent } from './site/upc/parameters/add-bottles/add-bottles.component';
import { AddbottlesComponent } from './upc/parameters/addbottles/addbottles.component';
import { InterventionhistoryComponent } from './site/interventions/intervention/interventionhistory/interventionhistory.component';
import { VersionmodalComponent } from './site/upc/versionmodal/versionmodal.component';
import { UPCGroupComponent } from "./site/upc/upcGroup.component";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { GroupStatsComponent } from "./site/upc/stats/groups/Stats/groupStats.component";
import { DiffusionStatsComponent } from './site/upc/stats/groups/Stats/DiffusionStats/diffusionStats.component'
import { DiffusionBetaStatsComponent } from "./site/upc/stats/groups/Stats/DiffusionBetaStats/diffusionBetaStats.component";
import { ConsoStatsComponent } from "./site/upc/stats/groups/Stats/ConsoStats/consoStats.component";
import { ContentStatsComponent } from "./site/upc/stats/groups/Stats/ContentStats/contentStats.component";
import { TableStatsComponent } from './site/upc/stats/groups/Stats/TableStats/tableStats.component';
import { ParameterbeltsModule } from 'src/app/pages/parameterbelts/parameterbelts.module';
import { ButtonModule } from "primeng/button";
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ComponentsModule } from 'src/app/components/components.module';
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { MatIconModule } from '@angular/material/icon';
import { LocationSelectionModalComponent } from './site/interventions/add-intervention/location-selection/location-selection.component';
import { NgPipesModule } from 'ngx-pipes';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InterventionLegacyComponent } from "./site/interventions/intervention-legacy/intervention-legacy.component";
import { CalendarModule as PrimeNgCalendarModule } from 'primeng/calendar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    NgxChartsModule,
    UiSwitchModule,
    AutocompleteLibModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ParameterbeltsModule,
    ButtonModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ComponentsModule,
    TableModule,
    TooltipModule,
    NgPipesModule,
    MatTableModule,
    MatPaginatorModule,
    PrimeNgCalendarModule,
  ],
  declarations: [
    // Pages
    SitesComponent,
    AddSiteComponent,
    SiteComponent,
    UPCComponent,
    UPCGroupComponent,
    ParametersComponent,
    InterventionComponent,
    InterventionLegacyComponent,
    TestsComponent,
    EventsComponent,
    StatsComponent,
    GroupStatsComponent,
    DiffusionStatsComponent,
    DiffusionBetaStatsComponent,
    ConsoStatsComponent,
    ContentStatsComponent,
    TableStatsComponent,
    InterventionsComponent,
    DiffCanvasComponent,
    AddInterventionComponent,
    GroupParametersComponent,
    // Modals
    EditSiteModalComponent,
    DeleteSiteModalComponent,
    AssociateBeltModalComponent,
    DissociateBeltModalComponent,
    CreateBeltModalComponent,
    DeleteInterventionModalComponent,
    LinkUPCV3ModalComponent,
    InitUPCModalComponent,
    WipeUPCModalComponent,
    RestoreUPCModalComponent,
    AddBottleModalComponent,
    LocationSelectionModalComponent,
    AddBottlesComponent,
    AddbottlesComponent,
    InterventionhistoryComponent,
    VersionmodalComponent
  ]
})
export class SitesModule { }