import { Component, Input } from '@angular/core';
import { CAD } from '../../../../../../models/project/cad';

@Component({
  selector: 'cad-bottom-left',
  templateUrl: './bottom-left.component.html'
})
export class CADBottomLeftComponent {
  // NF
  @Input('cad') cad: CAD;

  constructor() {}
  
}