import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Konva
import { KonvaModule } from "ng2-konva";

// Knob
import { Ng14KnobModule } from 'ng14-knob/dist/ng14-knob';

// Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// maps
import { AgmCoreModule } from '@agm/core';

// Pages
import { VersionComponent } from "./project/version/version.component";
import { KonvaComponent } from "./project/version/konva/konva.component";
import { ContextMenuComponent } from "./project/version/context-menu/context-menu.component";
import { TreeviewComponent } from "./project/version/treeview/treeview.component";
import { ProjectsComponent } from "./projects.component";
import { ProjectComponent } from "./project/project.component";
import { SelectionComponent } from "./project/version/selection/selection.component";
import { SettingsComponent } from "./project/version/settings/settings.component";

// Modals
import { AddProjectModalComponent } from "./add-project/add-project.component";
import { EditProjectModalComponent } from "./edit-project/edit-project.component";
import { DeleteProjectModalComponent } from "./delete-project/delete-project.component";
import { AddVersionModalComponent } from "./project/add-version/add-version.component";
import { EditVersionModalComponent } from "./project/edit-version/edit-version.component";
import { DeleteVersionModalComponent } from "./project/delete-version/delete-version.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    KonvaModule,
    Ng14KnobModule,
    UiSwitchModule,
    AgmCoreModule
  ],
  declarations: [
    // Pages
    ProjectsComponent,
    ProjectComponent,
    VersionComponent,
    KonvaComponent,
    ContextMenuComponent,
    TreeviewComponent,
    SelectionComponent,
    SettingsComponent,
    // Modals
    AddProjectModalComponent,
    EditProjectModalComponent,
    DeleteProjectModalComponent,
    AddVersionModalComponent,
    EditVersionModalComponent,
    DeleteVersionModalComponent
  ]
})
export class GDPModule { }