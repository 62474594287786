import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { Version } from 'src/app/models/gdp/version';
import { VersionService } from 'src/app/services/gdp/version.service';

@Component({
  templateUrl: './edit-version.component.html'
})
export class EditVersionModalComponent extends Modal<Version> implements OnInit {
  // Form
  form: FormGroup;
  formSubmitted = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private versionService: VersionService
  ) {
    super();

    // Form
    this.form = this.fb.group({
      type: [ '', Validators.required ],
      numberOrder: [ 1, Validators.required ]
    });

    // On location change
    this.router.events.subscribe(() => this.close());

  }

  ngOnInit() {
    this.form.patchValue({
      type: this.subject.type,
      numberOrder: this.subject.numberOrder
    })
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid) return;

    // Try create
    this.formSubmitted = true;
    this.versionService.edit(this.subject.id, this.form).subscribe(
      () => this.close(true),
      () => this.close(true)
    );
  }

}