
import {map,  takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy, Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import {
  ConsoChartDataWithMetadata,
  ConsoChartDataWithoutMetadata,
  UPCV3Service,
} from "src/app/services/upcv3/upcv3.service";
import { CalendarData } from "../groupStats.component";
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { ToasterService } from 'angular2-toaster';

interface ConsoChartAggregated extends ConsoChartDataWithoutMetadata {
  details: {
    conso: number;
    upcNameid: string;
  }[];
}

interface ConsoChartSeries {
  name: string;
  value: number;
  tooltip: {
    conso: number;
    upcNameid: string;
  }[]; // arbitrary object
}

interface ConsoChartFormatted {
  name: string;
  series: ConsoChartSeries[];
}

@Component({
  selector: "app-upc-group-stats-consommation",
  templateUrl: "./consoStats.component.html",
  styleUrls: ["./consoStats.component.css"],
})
export class ConsoStatsComponent implements OnInit, OnDestroy {
  @Input("upcv3Group") upcv3Group: GroupUPCV3;
  @Input("from") from: NgbDate;
  @Input("to") to: NgbDate;
  @Input() dateChanged: Observable<CalendarData>;

  consoTotal = 0;
  consoStats: any[];
  consoStatsGroup: any[] = [];
  consoChartData = [{ name: "Consommation", series: [] }];
  
  private onDestroy$: Subject<void> = new Subject<void>();
  private dateChangedSubscription: Subscription;

  constructor(private upcv3Service: UPCV3Service, private toasterService: ToasterService) {}

  ngOnInit(): void {
    this.loadConsoStats({from: this.from, to: this.to});
    this.dateChangedSubscription = this.dateChanged.subscribe((calendarData) => {
      this.loadConsoStats(calendarData);
      console.log("dateChanged Event received by ConsoStat")
    });
  }

  ngOnDestroy(): void {
    this.dateChangedSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  dateToString(date: NgbDate) {
    return (
      date.year +
      "-" +
      (date.month > 9 ? date.month : "0" + date.month) +
      "-" +
      (date.day > 9 ? date.day : "0" + date.day)
    );
  }

  chartConsoDataAggregateGenerator(
    upcsData: Array<ConsoChartDataWithMetadata>
  ): Array<ConsoChartAggregated> {
    // Les upcs doivent renvoyer le même nombre de point à afficher si nous voulons combiner ces informations correctement
    if (
      upcsData.some(
        (upcData) => upcData.data.length !== upcsData[0].data.length
      )
    ) {
      console.error(
        `data length mismatch - one upc of the group does not have the same amount of data than the others`
      );
      return [];
    }

    let dataAgrregated: Array<ConsoChartAggregated> = [];

    // le nombre de points à aggréger est égal au nombre de points d'une upc, car nous savons que ce nombre est le même pour chaque upc.
    const nbDataToAggregate = upcsData[0].data.length;

    for (
      let indexDataToAggregate = 0;
      indexDataToAggregate < nbDataToAggregate;
      indexDataToAggregate++
    ) {
      let details: Array<any> = [];
      // Calcul le cumul de la valeur de conso pour chaque ensemble de points de la liste des upcs du groupe
      const conso = upcsData.reduce(
        (prev, cur) => prev + cur.data[indexDataToAggregate].conso,
        0
      );
      // construit l'objet détaillant les valeurs pour chaque point agrrégé, utilisé pour l'affichage du tooltip
      for (const upcData of upcsData) {
        details = [
          ...details,
          {
            conso: upcData.data[indexDataToAggregate].conso,
            upcNameId: upcData.upcNameId,
          },
        ];
      }
      dataAgrregated = [
        ...dataAgrregated,
        {
          ...upcsData[0].data[indexDataToAggregate],
          conso: conso,
          details: details,
        },
      ];
    }
    return dataAgrregated;
  }

  loadConsoStats(calendarData: CalendarData) {
    const dataObservaleArray = [];
    const groupConsoData: Array<ConsoChartDataWithMetadata> = [];
    const formattedChartData: Array<ConsoChartFormatted> = [];

    for (const upc of this.upcv3Group.upcv3s) {
      dataObservaleArray.push(
        this.upcv3Service
          .getConsoStats(
            upc.id,
            this.dateToString(calendarData.from),
            this.dateToString(calendarData.to)
          )
          .pipe(takeUntil(this.onDestroy$)).pipe(
          map((res) => {
            if(!Array.isArray(res)) {
              this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des données de Consommation");
              return;
            }
            groupConsoData.push({
              upcNameId: upc.upcNameId,
              data: res,
            });
          }))
      );
    }
    
    forkJoin(dataObservaleArray).subscribe(() => {
      const chartDataAggregated =
        this.chartConsoDataAggregateGenerator(groupConsoData);

      formattedChartData.push({ name: "Consommation", series: [] });

      for (const data of chartDataAggregated) {
        const date = new Date(data.date).toLocaleDateString();

        formattedChartData[0].series.push({
          name: date,
          value: data.conso,
          tooltip: data.details,
        });
      }
      this.consoChartData = [...formattedChartData];
      this.consoStats = [...formattedChartData];
    });
  }
}
