import { LocalDateTime } from "../LocalDateTime";
import { Belt } from "./belt";

export class Version {
  id: string = '';

	type: Type = Type.PRE_ESTIMATE;
	numberOrder: number = 0;

	opacity: number = 0;
	rotation: number = 0;
	zoom: number = 0;

	installationPrice: number = 0;
	maintenancePrice: number = 0;
	materialPrice: number = 0;

	creationDate: LocalDateTime = new LocalDateTime();
  lastEditionDate: LocalDateTime = new LocalDateTime();

  defaultNodeIconSize: number = 25;
	defaultNodeTitleColor: string = '#ffffff';
	defaultNodeTitleBackgroundColor: string = '#0A5514';
	defaultNodeTitleSize: number = 10;
	defaultPathColor: string = '#0A5514';
	defaultPathWidth: number = 3;
	defaultPathDiameter: number = 6;

  belts: Belt[] = [];
  
  // UI
  selectedBelt: string;
  selectedProtectedArea: string;

  get versionTypeToString(): string {
    switch(this.type) {
      case Type.PRE_ESTIMATE: return 'Pré-devis';
      case Type.ESTIMATE: return 'Devis';
      case Type.ACCEPTED_ESTIMATE: return 'Devis accepté';
      case Type.INSTALLATION: return 'Installation';
      case Type.EXPLOITATION: return 'Exploitation';
    }
  }
  
  static loadFromJSON(json): Version {
    var version: Version = Object.assign(new Version, json);
    if (json.type) version.type = <Type> Type[json.type];
    if (json.creationDate) version.creationDate = LocalDateTime.loadFromJSON(json.creationDate);
    if (json.lastEditionDate) version.lastEditionDate = LocalDateTime.loadFromJSON(json.lastEditionDate);
    if (json.belts) {
      version.belts = [];
      json.belts.forEach(jsonBelt => version.belts.push(Belt.loadFromJSON(jsonBelt)));
    }

    // Link paths & nodes
    version.belts.forEach(belt => {
      belt.paths.forEach(path => {
        if (path.from) path.from = belt.nodes.find(n => n.id == path.from.id);
        if (path.to) path.to = belt.nodes.find(n => n.id == path.to.id);
      })
    });

    return version;
  }
}

export enum Type {
  PRE_ESTIMATE = 'PRE_ESTIMATE',
  ESTIMATE = 'ESTIMATE',
  ACCEPTED_ESTIMATE = 'ACCEPTED_ESTIMATE',
  INSTALLATION = 'INSTALLATION',
  EXPLOITATION = 'EXPLOITATION'
}