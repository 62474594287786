import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Site, State } from 'src/app/models/management/site';
import { AgmMap } from '@agm/core';
import { Code } from 'src/app/models/ApiResponse';
import { DashboardService } from 'src/app/services/management/dashboard.service';

declare var google: any;

@Component({
  selector: 'app-dashboard-map',
  templateUrl: './map.component.html'
})
export class MapComponent implements AfterViewInit {
  @ViewChild('AgmMap', { static: false }) agmMap: AgmMap;
  googleMap: any;

  sites: Site[];
  clusters: any[];
  markers: Site[];
  selectedSite: Site;

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngAfterViewInit() {
    this.agmMap.mapReady.subscribe(map => {
      this.googleMap = map;

      this.dashboardService.getMap().subscribe(
        res => {
          switch (res.code) {
            
            case Code.SITE_RECOVERED:
            this.sites = res.result;
  
            // Init cluster
            const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
            this.sites.forEach(site => bounds.extend(new google.maps.LatLng(site.latitude, site.longitude)))
            map.fitBounds(bounds);
            break;
          
          }
        }
      )
    })
  }

  // Maps
  async refreshClusters(mapZoom: number = 0) {
    this.clusters = [];
    this.markers = [];

    this.sites.forEach(site => {

      // Add first Site
      if (this.clusters.length == 0) {
        this.clusters.push({ lat: site.latitude, lng: site.longitude, icon: 'assets/img/map/cluster_green.png', sites: [ site ] });
      } else {
        // Get min distance Beetween clusters
        var distance = -1, minCluster;
        this.clusters.forEach(cluster => {

          // Calculate first cluster
          if (distance == -1) {
            distance = this.calculateDistance(site.latitude, site.longitude, cluster.lat, cluster.lng);
            minCluster = cluster;
          }
          
          // Compare
          else {
            var newDistance = this.calculateDistance(site.latitude, site.longitude, cluster.lat, cluster.lng);
            if (distance > newDistance) {
              distance = newDistance;
              minCluster = cluster;
            }
          }

        });

        // New cluster
        if (distance > Math.pow(25 - mapZoom, (25 - mapZoom) / (25 + Math.pow(mapZoom, mapZoom / 4))) - 1) this.clusters.push({ lat: site.latitude, lng: site.longitude, sites: [ site ] });
        // Append to cluster
        else minCluster.sites.push(site);
      }
    });

    // Replace cluster with one site by marker
    this.clusters.forEach(cluster => { if (cluster.sites.length == 1) this.markers.push(cluster.sites[0]) });
    this.clusters = this.clusters.filter(c => c.sites.length > 1);
  }
  private calculateDistance(lat1: number, lng1: number, lat2: number, lng2: number) {    
    // Radians
    lat1 = (lat1 * 2.0 * Math.PI) / 60.0 / 360.0;      
    lng1 = (lng1 * 2.0 * Math.PI) / 60.0 / 360.0;    
    lat2 = (lat2 * 2.0 * Math.PI) / 60.0 / 360.0;   
    lng2 = (lng2 * 2.0 * Math.PI) / 60.0 / 360.0;       


    // Use to different earth axis length    
    var a = 6378137.0;        // Earth Major Axis (WGS84)    
    var b = 6356752.3142;     // Minor Axis    
    var f = (a-b) / a;        // "Flattening"    
    var e = 2.0*f - f*f;      // "Eccentricity"      

    var beta = (a / Math.sqrt( 1.0 - e * Math.sin( lat1 ) * Math.sin( lat1 )));    
    var cos = Math.cos( lat1 );    
    var x = beta * cos * Math.cos( lng1 );    
    var y = beta * cos * Math.sin( lng1 );    
    var z = beta * ( 1 - e ) * Math.sin( lat1 );      

    beta = ( a / Math.sqrt( 1.0 -  e * Math.sin( lat2 ) * Math.sin( lat2 )));    
    cos = Math.cos( lat2 );   
    x -= (beta * cos * Math.cos( lng2 ));    
    y -= (beta * cos * Math.sin( lng2 ));    
    z -= (beta * (1 - e) * Math.sin( lat2 ));       

    return (Math.sqrt( (x*x) + (y*y) + (z*z) ) / 1000);  
  }

  showSite(site: Site) {
    this.selectedSite = site;
  }

  clusterZoom(cluster: any) {
    // Zoom
    var bounds = new google.maps.LatLngBounds();
    cluster.sites.forEach(site => bounds.extend(new google.maps.LatLng(site.latitude, site.longitude)));
    this.googleMap.fitBounds(bounds);
  }

  getSiteIcon(site: Site) {
    if (this.selectedSite != null && this.selectedSite.id == site.id)
      return 'assets/img/map/marker_selected.png';

    switch(site.state) {

      case State.RESERVE_1:
      return 'assets/img/map/marker_green.png';

      case State.RESERVE_2:
      return 'assets/img/map/marker_orange.png';

      case State.DISABLE:
      return 'assets/img/map/marker_red.png';

      case State.HIBERNATING:
      return 'assets/img/map/marker_snow.png';

      default:
      return 'assets/img/map/marker_green.png';

    }
  }

  getSiteComms(site: Site): boolean {
    return !site.communicationState
  }

  getClusterIcon(cluster: any) {

    // If one site is in DISABLE state
    if (cluster.sites.filter(s => s.state == State.DISABLE).length > 0) return 'assets/img/map/cluster_red.png';

    // If one site is in RESERVE_2 state
    else if (cluster.sites.filter(s => s.state == State.RESERVE_2).length > 0) return 'assets/img/map/cluster_orange.png';

    // If all site is in HIBERNATING state
    else if (cluster.sites.filter(s => s.state == State.HIBERNATING).length == cluster.sites.length) return 'assets/img/map/cluster_red.png';

    // Everything OK
    else return 'assets/img/map/cluster_green.png';

  }
  
  getClusterComms(cluster: any): boolean {
    return cluster.sites.filter(s => !s.communicationState).length > 0
  }

}