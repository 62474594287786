import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Site } from '../../models/management/site';
import { UPCV3 } from '../../models/upcv3/upc/upcv3';
import { UPCV2 } from '../../models/upcv2/upcv2';
import { SitePostDto } from 'src/app/models/dto/SitePost.dto';
import { SitePutDto } from 'src/app/models/dto/SitePut.dto';
import { CommentairePostDto } from 'src/app/models/dto/CommentairePost.dto';
import { Commentaires } from 'src/app/models/upcv3/commentaire';
import { UPCV3PostDto } from 'src/app/models/dto/UPCV3/UPCV3Post.dto';
import { GroupUPCV3PostDto } from 'src/app/models/dto/GroupUPCV3Post.dto';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';
import { Stock } from 'src/app/models/management/stock';


@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(
    private http: HttpClient,
  ) { }

  // Codes: SITE_RECOVERED | UNAUTHORIZED
  public getAll(archived: boolean = false): Observable<Site[]> {
    let params = new HttpParams().append("archived", archived);
    return this.http.get<ApiResponse<Site[]>>(`${environment.apiUrl}site/all`, { params }).pipe(map(
      res => res.result.map(jsonSite => Site.loadFromJSON(jsonSite))
    ))
  }
  // Codes: SITE_RECOVERED | SITE_DOESNT_EXSIST | UNAUTHORIZED
  public get(id: string): Observable<Site> {
    return this.http.get<ApiResponse<Site>>(`${environment.apiUrl}site/${id}`).pipe(map(
      res => Site.loadFromJSON(res.result)
    ))
  }

  public getStocks(id: string): Observable<Stock[]> {
    return this.http.get<ApiResponse<Stock[]>>(`${environment.apiUrl}site/${id}/stocks`).pipe(map(
      res => res.result.map(jsonStock => Stock.loadFromJSON(jsonStock))
    ))
  }

  // Codes: SITE_CREATED | WRONG_PARAMS | SITE_ALREADY_EXSIST | STOCK_DOESNT_EXSIST | UNAUTHORIZED
  public create(form: SitePostDto): Observable<Site> {
    return this.http.post<ApiResponse<Site>>(`${environment.apiUrl}site`, form).pipe(map(
      res => Site.loadFromJSON(res.result)
    ))
  }

  // Codes: SITE_EDITED | SITE_DOESNT_EXSIST | SITE_ALREADY_EXSIST | STOCK_DOESNT_EXSIST | UNAUTHORIZED
  public edit(siteId: string, form: SitePutDto): Observable<Site> {
    return this.http.put<ApiResponse<Site>>(`${environment.apiUrl}site/${siteId}`, form).pipe(map(
      res => Site.loadFromJSON(res.result)
    ))
  }



  // Codes: SITE_DELETED | SITE_DOESNT_EXSIST | SITE_USED | UNAUTHORIZED
  public delete(id: string): Observable<{}> {
    return this.http.delete<ApiResponse<{}>>(`${environment.apiUrl}site/${id}`).pipe(map(
      res => res.result
    ))
  }

  // Codes: UPCV3_CREATED | SITE_DOESNT_EXSIST | UNAUTHORIZED
  public createUPCV3(id: string, value: UPCV3PostDto): Observable<UPCV3> {
    return this.http.post<ApiResponse<UPCV3>>(environment.apiUrl + 'site/' + id + '/upcv3', value).pipe(map(
      res => UPCV3.loadFromJSON(res.result)
    ))
  }

  // Codes: SITE_EDITED | SITE_DOESNT_EXSIST | UNAUTHORIZED
  public associateUpc(id: string, form: FormGroup): Observable<any> {
    return this.http.post<ApiResponse<any>>(environment.apiUrl + 'site/' + id + '/associateUpc', form.value).pipe(map(
      res => res
    ))
  }

  public addComment(siteId: string, comment: CommentairePostDto) {
    return this.http.post<ApiResponse<void>>(`${environment.apiUrl}site/${siteId}/commentaire`, comment).pipe(map(
      res => null
    ))
  }

  public createGroup(siteId: string, form: GroupUPCV3PostDto): Observable<void> {
    return this.http.post<ApiResponse<void>>(`${environment.apiUrl}site/${siteId}/groupUpcv3`, form).pipe(map(
      res => null
    ))
  }

  public deleteGroup(siteId: string, groupId: number): Observable<void> {
    return this.http.delete<ApiResponse<void>>(`${environment.apiUrl}site/${siteId}/groupUpcV3/${groupId}`).pipe(map(
      res => res.result
    ))
  }
}
