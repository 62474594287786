<div class="modal-header">
  <h4 class="modal-title">Modifier le Projet</h4>
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>

<!-- Form -->
<form id="form" [formGroup]="form" (ngSubmit)="onFormSubmit()">
  <div class="modal-body">

    <!-- Name -->
    <div class="form-group">
      <label for="name">Nom *</label>
      <input type="text" class="form-control" id="name" placeholder="Nom *" formControlName="name" [ngClass]="{ 'is-invalid': nameAlreadyUsed || f.name.errors }" (keyup)="nameAlreadyUsed = false">
      <div *ngIf="nameAlreadyUsed || f.name.errors" class="invalid-feedback">
        <div *ngIf="f.name.errors && f.name.errors.required">Nom obligatoire</div>
        <div *ngIf="nameAlreadyUsed">Nom déjà utilisé</div>
      </div>
    </div>

    <!-- Client -->
    <div class="form-group">
      <label for="client">Client</label>
      <input type="text" class="form-control" id="client" placeholder="Client" formControlName="client">
    </div>

    <hr class="ml-0">

    <!-- Address -->
    <div class="form-group">
      <label for="address">Adresse</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="address" placeholder="Adresse" formControlName="address" [ngClass]="{ 'is-invalid': addressUnknow }" (keyup)="addressUnknow = false">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="geolocate()">Géolocaliser</button>
        </div>
        <div *ngIf="addressUnknow" class="invalid-feedback">
          <div *ngIf="addressUnknow">Adresse non trouvée</div>
        </div>
      </div>
    </div>

    <!-- Lat -->
    <div class="form-group">
      <label for="lat">Latitude</label>
      <input type="number" class="form-control" id="lat" placeholder="Latitude" step="0.01" formControlName="lat">
    </div>

    <!-- Lng -->
    <div class="form-group">
      <label for="lng">Longitude</label>
      <input type="number" class="form-control" id="lng" placeholder="Longitude" step="0.01" formControlName="lng">
    </div>

    <!-- HBM Distance -->
    <div class="form-group">
      <label for="hbmDistance">Distance de HBM Distribution</label>
      <div class="input-group mb-3">
        <input type="text" class="form-control" id="hbmDistance" placeholder="Distance de HBM Distribution" formControlName="hbmDistance">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button" (click)="geoCalculate()">Calculer</button>
        </div>
      </div>
    </div>

    <hr class="ml-0">

    <!-- Custom Picture -->
    <div class="form-group">
      <label>Image personnalisée</label><br>
      <ui-switch formControlName="customPicture"></ui-switch>
    </div>

    <hr class="ml-0">

    <!-- File -->
    <form [formGroup]="customPictureForm" (ngSubmit)="onCustomPictureFormSubmit()" *ngIf="this.f.customPicture.value">
      <label>Charger une nouvelle image</label>
      <div class="input-group">
        <input type="file" class="form-control" (change)="onFileChange($event)" [ngClass]="{ 'is-invalid': cpf.file.errors }">
        <div class="input-group-append">
          <button class="btn btn-outline-primary" type="submit" [disabled]="customPictureFormSubmitted">
            <i class="fa fa-circle-o-notch fa-spin" *ngIf="customPictureFormSubmitted"></i>
            <span *ngIf="!customPictureFormSubmitted">Charger</span>
          </button>
        </div>
        <div *ngIf="cpf.file.errors" class="invalid-feedback">
          <div *ngIf="cpf.file.errors && cpf.file.errors.required && !readingFile">Fichier obligatoire</div>
          <div *ngIf="readingFile"><i class="fa fa-circle-o-notch fa-spin mr-2"></i>Chargement du fichier...</div>
        </div>
      </div>
    </form>

    <!-- Custom Picture preview -->
    <img *ngIf="customPictureData" [src]="customPictureData" />

    <!-- Map -->
    <agm-map  #map
              *ngIf="!this.f.customPicture.value"
              [latitude]="this.f.lat.value"
              [longitude]="this.f.lng.value"
              [zoom]="this.f.zoom.value"
              [mapTypeControl]="true"
              [mapTypeId]="'hybrid'"
              [usePanning]="true"
              [fullscreenControl]="true"
              [rotateControl]="true"
              style="height: 70vh;">
      <agm-marker #marker [latitude]="this.f.lat.value" [longitude]="this.f.lng.value" [markerDraggable]="true" (dragEnd)="dragEnd($event)"></agm-marker>
    </agm-map>

  </div>

  <div class="modal-footer">
    <input type="button" class="btn btn-outline-secondary" value="Annuler" (click)="close()" [disabled]="formSubmitted" ngbButton>
    <button type="submit" class="btn btn-outline-success" [disabled]="formSubmitted" ngbButton>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
      <span *ngIf="!formSubmitted">Modifier</span>
    </button>
  </div>
</form>