import { Component, Input } from '@angular/core';
import { CAD } from '../../../../../../models/project/cad';

@Component({
  selector: 'cad-top-left',
  templateUrl: './top-left.component.html'
})
export class CADTopLeftComponent {
  // NF
  @Input('cad') cad: CAD;

  constructor() {}

}