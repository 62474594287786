<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-12 mb-3 text-center" *ngIf="!interventionLegacy"><i class="fa fa-circle-o-notch fa-spin"></i></div>

    <!-- Title -->
    <div class="col-12 mb-2" *ngIf="interventionLegacy">
      <h4 class="float-left" *ngIf="interventionLegacy">Intervention - {{ interventionLegacy.objet }}</h4>
    </div>

    <!-- Infos -->
    <div class="col-12 mb-2" *ngIf="interventionLegacy">
      <div class="card-deck">
        <div class="card">
          <h5  class="card-header">Date</h5 >
          <div class="card-body">
            <p class="card-text text-center" *ngIf="interventionLegacy">{{ interventionLegacy.datetime.toLocaleString() }}</p>
          </div>
        </div>

        <div class="card">
          <h5  class="card-header">Objet</h5 >
          <div class="card-body">
            <p class="card-text text-center" *ngIf="interventionLegacy">{{ interventionLegacy.objet }}</p>
          </div>
        </div>

        <div class="card">
          <h5  class="card-header">Opérateurs + Rédacteur</h5 >
          <div class="card-body">
            <p class="card-text text-center font-weight-bold" *ngIf="interventionLegacy">{{ "Opérateurs : "+interventionLegacy.intervenant }}</p>
            <p class="card-text text-center font-weight-bold" *ngIf="interventionLegacy">{{"Rédacteur : "+interventionLegacy.operateur}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- B1 -->
    <div class="col-md-6 mb-2" *ngIf="interventionLegacy">
      <div class="card">
        <h5  class="card-header">Bouteilles B1</h5 >
        <div class="card-body text-center">
          <!-- UPC-V3 -->
          <ul style="list-style: none; margin-bottom: 0; padding-left: 0;" *ngIf="interventionLegacy">
            <li class="bottle-empty" *ngIf="bottleB1.length == 0 && bottleENB1.length == 0 && currentB1.designation.length == 0">Aucune bouteille </li>
            
            <li class ="current-bottle" *ngFor="let cbottleB1 of currentB1.designation;let i = index;">
              {{cbottleB1}} 
              <br>
              <small>{{currentB1.barcode[i]}}</small>
              <br/>{{currentB1.state[i]}}
              <br/> Inchangée
            </li>
            <li class="bottle" *ngFor="let bottle of bottleB1" [ngClass]="{'progress-bar-striped': bottle.previousIntervention}">
              {{ bottle.bottleType.contenue }} {{bottle.bottleType.contenue == '50' ? ' lb':' kg'}}
              <br>
              <small>{{ bottle.barcode ? bottle.barcode : '-' }}</small>
              <br/>{{bottle.state === "IN_USE"?"En Ligne":""}}{{bottle.state === "FULL"?"Pleine":""}}{{bottle.state === "EMPTY"?"Vide":""}}
              <br/> Ajoutée
            </li>
            <li class="bottle-deleted" *ngFor="let bottleEN of bottleENB1" [ngClass]="{'progress-bar-striped': bottleEN.previousIntervention}">
              {{bottleEN.bottleType.designation}} 
              <br>
              <small>{{bottleEN.barcode ? bottleEN.barcode : '-'}}</small>
              <br/>{{bottleEN.state === "IN_USE"?"Entamé":""}}{{bottleEN.state === "FULL"?"Pleine":""}}{{bottleEN.state === "EMPTY"?"Vide":""}}
              <br/>Enlevée
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- B2 -->
    <div class="col-md-6 mb-2" *ngIf="interventionLegacy">
      <div class="card">
        <h5  class="card-header">Bouteilles B2</h5 >
        <div class="card-body text-center">
          <!-- UPC-V3 -->
          <ul style="list-style: none; margin-bottom: 0; padding-left: 0;" *ngIf="interventionLegacy">
            <li class="bottle-empty" *ngIf="bottleB2.length == 0 && bottleENB2.length == 0 && currentB2.designation.length == 0">Aucune bouteille </li>
            <li class ="current-bottle" *ngFor="let cbottleB2 of currentB2.designation;let i = index;">
              {{cbottleB2}} 
              <br>
              <small>{{currentB2.barcode[i]}}</small>
              <br/> {{currentB2.state[i]}}
              <br/> Inchangée
            </li>
            <li class="bottle" *ngFor="let bottle of bottleB2" [ngClass]="{'progress-bar-striped': bottle.previousIntervention}">
              {{ bottle.bottleType.designation }} {{bottle.bottleType.designation == '50' ? ' lb':' kg'}}
              <br>
              <small>{{ bottle.barcode ? bottle.barcode : '-' }}</small>
              <br/>{{bottle.state === "IN_USE"?"En Ligne":""}}{{bottle.state === "FULL"?"Pleine":""}}{{bottle.state === "EMPTY"?"Vide":""}}
              <br/> Ajoutée
            </li>
            <li class="bottle-deleted" *ngFor="let bottleEN of bottleENB2" [ngClass]="{'progress-bar-striped': bottleEN.previousIntervention}">
              {{bottleEN.bottleType.designation}} 
              <br>
              <small>{{bottleEN.barcode ? bottleEN.barcode : '-'}}</small>
              <br/>{{bottleEN.state === "IN_USE"?"Entamé":""}}{{bottleEN.state === "FULL"?"Pleine":""}}{{bottleEN.state === "EMPTY"?"Vide":""}}
              <br/> Enlevée
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-2" *ngIf="interventionLegacy">
      <div class="card">
        <h5 class="card-header">Bouteilles Hors Ligne</h5>
        <div class="card-body text-center">
          <ul style="list-style: none; margin-bottom: 0; padding-left: 0;" *ngIf="interventionLegacy">
            <li class="bottle-empty" *ngIf="bottleHL.length == 0 && currentHL.designation == 0 && bottleENHL.length == 0">Aucune bouteille </li>
            <li class ="current-bottle" *ngFor="let cbottleHL of currentHL.designation;let i = index;">
              {{cbottleHL}} 
              <br>
              <small>{{currentHL.barcode[i]}}</small>
              <br/> {{currentHL.state[i]}}
              <br/>Inchangée
            </li>
            <li class="bottle" *ngFor="let bottle of bottleHL" [ngClass]="{'progress-bar-striped': bottle.previousIntervention}">
              {{ bottle.bottleType.designation }} {{bottle.bottleType.designation == '50' ? ' lb':' kg'}}
              <br>
              <small>{{ bottle.barcode ? bottle.barcode : '-' }}</small>
              <br/>{{bottle.state === "IN_USE"?"Entamé":""}}{{bottle.state === "FULL"?"Pleine":""}}{{bottle.state === "EMPTY"?"Vide":""}}
              <br/>Ajoutée
            </li>
            <li class="bottle-deleted" *ngFor="let bottleEN of bottleENHL" [ngClass]="{'progress-bar-striped': bottleEN.previousIntervention}">
              {{bottleEN.bottleType.designation}} 
              <br>
              <small>{{bottleEN.barcode ? bottleEN.barcode : '-'}}</small>
              <br/>{{bottleEN.state === "IN_USE"?"Entamé":""}}{{bottleEN.state === "FULL"?"Pleine":""}}{{bottleEN.state === "EMPTY"?"Vide":""}}
              <br/> Enlevée
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-2" *ngIf="interventionLegacy && interventionLegacy?.commentaires?.length>0">
      <div class="card">
        <h5 class="card-header">Commentaires</h5>
        <div class="card-body text-center">
          <ul style="list-style: none; margin-bottom: 0;padding-left: 0;" *ngIf="interventionLegacy && interventionLegacy?.commentaires?.length>0">
            <li class="bottle-empty">{{interventionLegacy?.commentaires[0].auteur + " : " + interventionLegacy?.commentaires[0].mess}}</li>
          </ul>

        </div>
        <button (click)="openCommentHistoryModal()" class="btn btn-primary">Historique</button>
      </div>
    </div>
  </div>
</div>
