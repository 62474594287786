<div class="container-fluid">
  <div class="row mt-3">

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left">Tableau de bord</h4>
    </div>

    <div class="col-12 mb-3">
      <ul ngbNav #nav="ngbNav" class="nav-pills" [(activeId)]="activeId">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Carte</a>
          <ng-template ngbNavContent>
            <app-dashboard-map></app-dashboard-map>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>UPC-V3</a>
          <ng-template ngbNavContent>
            <app-dashboard-upcv3></app-dashboard-upcv3>
          </ng-template>
        </li>

      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

    </div>

  </div>
</div>