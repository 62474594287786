import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Routing
import { AppRoutingModule } from '../../app-routing.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Pages
import { SettingsComponent } from "./settings.component";
import { UserComponent } from "./user/user.component";
import { FirmwaresComponent } from "./firmwares/firmwares.component";
import { DefaultParamsComponent } from "./default-params/default-params.component";
import { DeleteUPCV3Component } from "./delete-upcv3/delete-upcv3.component";

// Modals
import { DeleteFirmwareModalComponent } from "./firmwares/delete-firmware/delete-firmware.component";
import { AddFirmwareModalComponent } from "./firmwares/add-firmware/add-firmware.component";
import { UpdateUPCFirmwareModalComponent } from "./firmwares/update-upc-firmware/update-upc-firmware.component";
import { DeleteUPCModalComponent } from "./delete-upcv3/delete-upc/delete-upc.component";
import { BilanConsommationsComponent } from './bilan-consommations/bilan-consommations.component';

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    UiSwitchModule,
    NgSelectModule
  ],
  declarations: [
    // Pages
    SettingsComponent,
    UserComponent,
    FirmwaresComponent,
    DefaultParamsComponent,
    DeleteUPCV3Component,
    BilanConsommationsComponent,
    // Modals
    DeleteFirmwareModalComponent,
    AddFirmwareModalComponent,
    UpdateUPCFirmwareModalComponent,
    DeleteUPCModalComponent,
  ]
})
export class SettingsModule { }