
import { map, takeUntil } from 'rxjs/operators';
import { intensities } from "./../../../../../../../../models/upcv3/upc/diffCo2Program";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

import { Component, OnInit, Input, OnDestroy, Injectable } from "@angular/core";

import {
  CustomDateFormatter,
  CustomEventTitleFormatter,
} from "../../Provider/CustomFormatter.provider";

import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from "date-fns";

import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarView,
  DateFormatterParams,
  DAYS_OF_WEEK,
} from "angular-calendar";

import { formatDate } from "@angular/common";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import {
  UPCV3Service,
  ContentChartDataWithoutMetadata,
  ContentChartDataWithMetadata,
  ConsoChartDataWithoutMetadata,
  ConsoChartDataWithMetadata,
  DiffusionChartDataWithoutMetadata,
} from "src/app/services/upcv3/upcv3.service";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { CalendarData } from "../groupStats.component";
import { ToasterService } from 'angular2-toaster';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';

// Diff Stats

@Component({
  selector: "app-upc-group-stats-diffusion-beta",
  templateUrl: "./diffusionBetaStats.component.html",
  styleUrls: ["./diffusionBetaStats.component.css"],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class DiffusionBetaStatsComponent implements OnInit, OnDestroy {
  @Input("upcv3Group") upcv3Group: GroupUPCV3;
  @Input("from") from: NgbDate;
  @Input("to") to: NgbDate;
  @Input() dateChanged: Observable<CalendarData>;

  private onDestroy$: Subject<void> = new Subject<void>();

  max: NgbDate;

  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();

  getdiffChartColors(index: number = 0, total: number = 1) {
    // TODO generate color based on name;
    const hslValue = Math.round((360 * index) / total);
    return [
      { name: "Aucune diffusion", value: `hsla(${hslValue}, 50%, 50%, 0)` },
      { name: "Intensité : 1", value: `hsla(${hslValue}, 50%, 50%, .1)` },
      { name: "Intensité : 2", value: `hsla(${hslValue}, 50%, 50%, .2)` },
      { name: "Intensité : 3", value: `hsla(${hslValue}, 50%, 50%, .3)` },
      { name: "Intensité : 4", value: `hsla(${hslValue}, 50%, 50%, .4)` },
      { name: "Intensité : 5", value: `hsla(${hslValue}, 50%, 50%, .5)` },
      { name: "Intensité : 6", value: `hsla(${hslValue}, 50%, 50%, .6)` },
      { name: "Intensité : 7", value: `hsla(${hslValue}, 50%, 50%, .7)` },
      { name: "Intensité : 8", value: `hsla(${hslValue}, 50%, 50%, .8)` },
      { name: "Intensité : 9", value: `hsla(${hslValue}, 50%, 50%, .9)` },
      { name: "Intensité : 10", value: `hsla(${hslValue}, 50%, 50%, 1)` },
    ];
  }

  diffStats: DiffusionChartDataWithoutMetadata[];
  diffChartData = [];
  events: CalendarEvent[] = [];
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  daysInWeek: number = 21;
  private dateChangedSubscription: Subscription;

  constructor(
    public calendar: NgbCalendar,
    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService,
  ) { }

  ngOnInit(): void {
    this.loadDiffusionsStats({ from: this.from, to: this.to });
    this.dateChangedSubscription = this.dateChanged.subscribe(
      (calendarData) => {
        this.loadDiffusionsStats(calendarData);
      }
    );
  }

  public ngOnDestroy(): void {
    this.dateChangedSubscription.unsubscribe();
    this.onDestroy$.next();
  }

  dateToString(date: NgbDate) {
    return (
      date.year +
      "-" +
      (date.month > 9 ? date.month : "0" + date.month) +
      "-" +
      (date.day > 9 ? date.day : "0" + date.day)
    );
  }

  setView(view: CalendarView) {
    this.view = view;
  }
  viewDateChange(): void {
    this.from = new NgbDate(
      this.viewDate.getUTCFullYear(),
      this.viewDate.getUTCMonth() + 1,
      this.viewDate.getUTCDate()
    );
    this.to = this.calendar.getNext(this.from, "d", this.daysInWeek);
    this.loadDiffusionsStats({ from: this.from, to: this.to });
  }

  loadDiffusionsStats(calendarData: CalendarData) {
    const dataObservaleArray = [];

    for (const upc of this.upcv3Group.upcv3s) {
      dataObservaleArray.push(
        this.upcv3Service
          .getDiffStats(
            upc.id,
            this.dateToString(calendarData.from),
            this.dateToString(calendarData.to)
          )
          .pipe(takeUntil(this.onDestroy$)).pipe(
            map((res) => {
              if (!Array.isArray(res)) {
                this.diffStats = [];
                this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors de la récupération des statistiques de diffusion");
                return;
              }
              return {
                upcNameId: upc.upcNameId,
                data: res,
              };
            }))
      );
    }

    forkJoin(dataObservaleArray).subscribe((res) => {
      if (!res) {
        console.log(
          'The server return no diffChart Data for the selected period"'
        );
      }
      this.viewDate = new Date(
        this.from.year,
        this.from.month - 1,
        this.from.day
      );
      let newData = this.chartDiffusionDataAggregateGenerator(res);
      this.events = newData;
    });
  }

  chartDiffusionDataAggregateGenerator(upcsData: Array<any>): Array<any> {
    if (
      upcsData.some(
        (upcData) => upcData.data.length !== upcsData[0].data.length
      )
    ) {
      console.error(
        `data length mismatch - one upc of the group does not have the same amount of data than the others`
      );
      return [];
    }

    const eventArray: CalendarEvent[] = [];
    const nbDataToAggregate = upcsData.length;

    for (
      let indexDataToAggregate = 0;
      indexDataToAggregate < nbDataToAggregate;
      indexDataToAggregate++
    ) {
      for (const upcData of upcsData[indexDataToAggregate].data) {
        if (upcData.diffs && upcData.diffs.length !== 0) {
          for (const diff of upcData.diffs) {
            let start = new Date(upcData.date + 'T' + diff.start);
            let end = new Date(upcData.date + 'T' + diff.stop);

            let newEvent = {
              start: start,
              end: end,
              title: `Intensité ${diff.intensity} pour Upc : ${upcsData[indexDataToAggregate].upcNameId}`,
              color: {
                primary: this.getdiffChartColors(
                  indexDataToAggregate,
                  nbDataToAggregate
                )[10].value,
                secondary: this.getdiffChartColors(
                  indexDataToAggregate,
                  nbDataToAggregate
                )[diff.intensity].value, //max intensity
              },
              resizable: {
                beforeStart: false,
                afterEnd: false,
              },
              draggable: false,
              allDay: false,
              meta: {
                intensity: diff.intensity,
                upc: upcsData[indexDataToAggregate].upcNameId,
                displayInnerBarDesc: false,
              },
            };
            //try to check for contiguous event
            let previousEvent = eventArray.slice(-1).pop();

            //
            if (
              previousEvent != null &&
              previousEvent.end.getTime() == newEvent.start.getTime() &&
              previousEvent.meta.intensity == newEvent.meta.intensity &&
              previousEvent.meta.upc === newEvent.meta.upc
            ) {
              eventArray[eventArray.length - 1].end = newEvent.end;
            } else {
              eventArray.push(newEvent);
            }
          }
        }
      }
    }
    return eventArray;
  }
}
