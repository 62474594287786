import { Component, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      {{ body }}
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">Ok</button>
  </div>
  `
})

export class ConfirmationModal {
  @Input() title: string;
  @Input() body: string;
  constructor(public modal: NgbActiveModal) {  }
}
