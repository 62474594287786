<div class="container h-100 mt-5">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-12 col-sm-10 col-md-7 col-lg-5">
      
      <!-- Logo -->
      <div class="card mb-3">
        <img class="card-img-top" src="/assets/img/logo.png" />
      </div>

      <!-- Form -->
      <div class="card">

        <!-- Title -->
        <div class="card-header">Se Connecter</div>

        <!-- Body -->
        <div class="card-body">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <!-- Email -->
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-user"></i></span>
                </div>
                <input type="email" class="form-control" placeholder="Email" formControlName="username" [ngClass]="{ 'is-invalid': loginForm.touched && f['username'].errors }">
                <div *ngIf="loginForm.touched && f['username'].errors" class="invalid-feedback">
                  <div *ngIf="f['username'].errors.required">Email obligatoire</div>
                  <div *ngIf="f['username'].errors.email">Email invalide</div>
                </div>
              </div>
            </div>

            <!-- Password -->
            <div class="form-group mb-4">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-lock"></i></span>
                </div>
                <input type="password" class="form-control" placeholder="Mot de passe" formControlName="password" [ngClass]="{ 'is-invalid': loginForm.touched && f['password'].errors }">
                <div *ngIf="f['password'].errors" class="invalid-feedback">
                  <div *ngIf="f['password'].errors.required">Mot de passe obligatoire</div>
                </div>
              </div>
            </div>
            
            <button type="submit" class="btn btn-primary btn-block" [disabled]="submitted">
              <i class="fa fa-circle-o-notch fa-spin" *ngIf="submitted"></i>
              <span *ngIf="!submitted">Connexion</span>
            </button>
          </form>
        </div>
        
      </div>
    </div>
  </div>
</div>
