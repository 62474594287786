export class Node {
  id: string = '';

	type: Type = Type.HEXAGONAL_TRAP;

	x: number = 0;
	y: number = 0;

	iconSize: number = 0;

	title: string = '';
	titleX: number = 0;
	titleY: number = 0;
	titleColor: string = '';
  titleBackgroundColor: string = '';
  titleSize: number = 0;

  // Calculations
  flowIn: number = 0;
	flowOut: number = 0;
	flowCumulated: number = 0;
	flowPercent: number = 0;
	pressure: number = 0;
	lossPressureCumulated: number = 0;

  // UI
  selected: boolean = false;
  get centeredX(): number { return this.x - this.iconSize / 2 }
  get centeredY(): number { return this.y - this.iconSize / 2 }
  get calculatedTitle(): string {
    switch (this.type) {
      case Type.CONTROL: return 'Régie';
      case Type.RECTANGULAR_TRAP: case Type.HEXAGONAL_TRAP: return 'Piège ' + this.title;
      case Type.INTERMEDIATE: return 'Noeud intermédiaire';
      default: return 'Noeud';
    }
  }

  resetCalculationValues() {
		this.flowIn = 0;
	  this.flowOut = 0;
    this.flowCumulated = 0;
    this.flowPercent = 0;
    this.pressure = 0;
    this.lossPressureCumulated = 0;
	}
  
  static loadFromJSON(json): Node {
    var node: Node = Object.assign(new Node, json);
    if (json.type) node.type = <Type> Type[json.type];
    return node;
  }
}

export enum Type {
  CONTROL = 'CONTROL',
  RECTANGULAR_TRAP = 'RECTANGULAR_TRAP',
  HEXAGONAL_TRAP = 'HEXAGONAL_TRAP',
  INTERMEDIATE = 'INTERMEDIATE'
}