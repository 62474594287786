<div class="bottom-sticky-speedial">
  <p-speedDial 
    *ngIf='selectedUpcs.length != 0' 
    [model]="selectedUpcsAction" 
    radius="100" 
    direction="up-right" 
    type="quarter-circle">
  </p-speedDial>
</div>
<div class="row mt-3">
  <div class="col-12">
    <!-- Paramétrer ceinture -->
    <!-- Loading -->

    <!-- Table -->

    <p-table
      #upcDataTable
      [value]="upcv3"
      [loading]="!displayableContentLoaded"
      [showLoader]="false"
      [(selection)]="selectedUpcs"
      dataKey="name"
      scrollHeight="flex"
      [globalFilterFields]="['name']"
      sortField="statusSeverity"
      sortOrder="-1"
      sortMode="single"
      expandableRows="true"
      filterLocale="fr-FR"
      responsiveLayout="scroll"
      (onHeaderCheckboxToggle)="onHeaderCheckboxToggle($event)"
    >
      <ng-template pTemplate="caption">
        <div class="d-flex flex-wrap flex-md-wrap gap-sm align-items-center">
          <button
            pButton
            [label]='"Rafraîchi " + this.lastLoadedDataDateDisplayable'
            class="p-button-outlined mr-auto"
            icon="pi pi-refresh"
            (click)="ngOnInit()"
            [loading]="!displayableContentLoaded"
          ></button>

          <span>
            <mat-slide-toggle [formControl]="toggleShowArchived" (change)="onToggleShowArchived()">
              Afficher les ceintures archivées
            </mat-slide-toggle>
          </span>

          <p-columnFilter type="text" matchMode="in" field="site.departement" [showMenu]="false" [showClearButton]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect #departementsFilter [options]="departements" (onChange)="filter($event.value)" [displaySelectedLabel]="false"
                placeholder="Département">
                <ng-template let-option pTemplate="item">
                  <div class="p-multiselect-departement-option">
                    <span class="p-ml-1">{{option.value}}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>

          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              #InputFilterRef
              pInputText
              type="text"
              (input)="
                upcDataTable.filterGlobal($event.target.value, 'contains')
              "
              placeholder="Recherche..."
            />
          </span>

          <button
            pButton
            label="Clear"
            class="p-button-outlined"
            icon="pi pi-filter-slash"
            (click)="clearFilters($event)"
          ></button>
        </div>
        
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th rowspan="3" style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th rowspan="3" pSortableColumn="isRembo" class="header-fit-content">
            <p-sortIcon field="isRembo"></p-sortIcon>
            <p-columnFilter type="boolean" field="isRembo" display="menu">
              <ng-template pTemplate="filter" let-isRembo let-filter="filterCallback">
                <p-triStateCheckbox class="tristate-boolean" name="isRembo" label="Rembo ?"
                    (onChange)="filter($event.value)" [inputId]="upcv3.name">
                </p-triStateCheckbox>
              </ng-template>   
            </p-columnFilter>
            Rembo
          </th>
          <th rowspan="3" pSortableColumn="upc.alertsSincePolling.length">
            Alarme
            <p-sortIcon field="alarmSeverity"></p-sortIcon>
            <p-columnFilter type="numeric" field="upc.alertsSincePolling.length" display="menu"></p-columnFilter>
          </th>
         
          <th rowspan="3" pSortableColumn="name" field="name">
            Ceinture
            <p-sortIcon field="name"></p-sortIcon>
            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
          </th>
          <th rowspan="3" pSortableColumn="trapNumber">
            Pièges<p-sortIcon
              field="upc.trapNumber"
            ></p-sortIcon>           
            <p-columnFilter type="numeric" field="upc.trapNumber" display="menu"></p-columnFilter>
          </th>
          <th rowspan="3" pSortableColumn="upcStatusString">
            Mode diff.<p-sortIcon field="upc.upcStatusString"></p-sortIcon>
            <p-columnFilter type="text" field="upc.upcStatusString" display="menu"></p-columnFilter>
          </th>
          
          <th colspan="5">Reserves de CO2</th>
          <th rowspan="6" pSortableColumn="comment.lastComment?.messsage">
            Commentaires<p-sortIcon field="comment.lastComment?.messsage"></p-sortIcon>
          </th>
          <th rowspan="3" pSortableColumn="site.name">
            Site
            <p-sortIcon field="site.name"></p-sortIcon>
          </th>
         
          <th rowspan="3" pSortableColumn="">
            Dernier pool réussi<p-sortIcon field=""></p-sortIcon>
          </th>
          <th rowspan="3" pSortableColumn="">
            Pool actif<p-sortIcon field=""></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th rowspan="2">Reserve active</th>
          <th colspan="2">B1</th>
          <th colspan="2">B2</th>
        </tr>
        <tr>
          <th>Cont. calc. (kg)</th>
          <th pSortableColumn="upc.reservesParameters.co2Res1ActDur">
            Durée est. (jours)<p-sortIcon field="upc.reservesParameters.co2Res1ActDur"></p-sortIcon>
          </th>
          <th>Cont. calc. (kg)</th>
          <th pSortableColumn="upc.reservesParameters.co2Res2ActDur">
            Durée est. (jours)<p-sortIcon field="upc.reservesParameters.co2Res2ActDur"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="loadingbody">
        <tr *ngFor="let i of skeletonCounter(8)">
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
          <td><p-skeleton width="100%" height="3rem"></p-skeleton></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-upcv3 let-expanded="expanded" let-rowIndex="rowIndex">
        <tr *ngIf="!upcv3.isGroup">
          <td>
            <p-tableCheckbox [value]="upcv3"></p-tableCheckbox>
          </td>
          <td>
            <i class="pi" [ngClass]="{'true-icon pi-check-circle': upcv3.isRembo}"></i>
          </td>
          <td>
            {{upcv3.severity}}
            <p-badge
              *ngIf="upcv3.upc.showDashboardAsterisk"
              [(pTooltip)]="upcv3.upc.upcAlertsSinceLastPollingTooltip"
              [value]="upcv3.upc.alertsSincePolling.length"
              [severity]="upcv3.severityStringUI"
              [ngClass]="getAlarmSeverityClass(upcv3)"
            >
            </p-badge>
          </td>
          
          <td class="left-align">
            <a
              [ngClass]="getStatusSeverityClass(upcv3.upc.getStatusSeverity())"
              routerLink="/upc/v3/{{ upcv3.upc.id }}"
              container="div"
              [(pTooltip)]="upcv3.upc.upcAlertsSinceLastPollingTooltip"
              >{{ upcv3.name }}
            </a>
          </td>
          <td>
            {{ upcv3.trapNumber }}
          </td>
          <td>
            <p-chip
              [label]="upcv3.upc.upcStatusString"
              [styleClass]="upcv3.upc.upcStatusChipStyle"
            ></p-chip>
          </td>
          <td>
            {{ upcv3.upc.co2ResActiveString }}
          </td>
          <td [ngClass]="upcv3.upc.co2Res1ActVolClass">
            {{
              co2LitersToKg(upcv3.upc.reservesParameters.co2Res1ActVol).toFixed(1)
            }}
          </td>
          <td [ngClass]="upcv3.upc.co2Res1ActVolClass">
            {{ upcv3.upc.reservesParameters.co2Res1ActDur }}
          </td>
          <td [ngClass]="upcv3.upc.co2Res2ActVolClass">
            {{
              co2LitersToKg(upcv3.upc.reservesParameters.co2Res2ActVol).toFixed(1)
            }}
          </td>
          <td [ngClass]="upcv3.upc.co2Res2ActVolClass">
            {{ upcv3.upc.reservesParameters.co2Res2ActDur }}
          </td>
          <td>
            <p-button
              *ngIf="upcv3.comment.lastComment != null; else noComment"
              [badge]="
                toLocaleString(upcv3.comment.lastCommentDate, '') || ''
              "
              badgeClass="bigger"
              [label]="upcv3.comment.lastComment.message"
              (click)="onOpenCommentModal(upcv3.site, upcv3.upc, null)"
              [style]="{ width: '100%' }"
              [styleClass]="
                'p-button-sm center max-width small-text p-button-' +
                upcv3.comment.commentClass
              "
            >
            </p-button>
            <ng-template #noComment>
              <p-button
                badgeClass="bigger"
                icon="pi pi-plus"
                iconPos="center"
                (click)="onOpenCommentModal(upcv3.site, upcv3.upc, null)"
                [style]="{ width: '100%' }"
                styleClass="center max-width p-button-raised p-button-outlined"
              ></p-button>
            </ng-template>
          </td>
          <td class="left-align">
            <a
              routerLink="/site/{{ upcv3.site.id }}"
              container="div"
              >{{ upcv3.site.name }}
            </a>
          </td>
          <td>
            <div
              [pTooltip]="'Dernier pool: ' + toLocaleString(upcv3.upc.upcLastPollDatetime, '-')"
            >
              {{ upcv3.upc.lastSuccessfullPollingEvent?.serverDatetime.toLocaleString() }}
            </div>
          </td>
          <td>
            <button
              class="button"
              [ngClass]="
                upcv3.upc.upcPollEnable ? 'button-success' : 'button-alert'
              "
              (click)="togglePollModalConfirmation(upcv3.upc)"
            >
              {{ upcv3.upc.upcPollEnable ? "Oui" : "Non" }}
            </button>
          </td>
        </tr>

        <tr *ngIf="upcv3.isGroup">
          <td>
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="upcv3"
              class="p-button-text pi-button-small p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></button>
          </td>
          <td>
            <i class="pi" [ngClass]="{'true-icon pi-check-circle': upcv3.isRembo}"></i>
          <td>
            <p-badge
              *ngIf="upcv3.groupe.showDashboardAsterisk"
              [(pTooltip)]="upcv3.groupe.upcAlertsSinceLastPollingTooltip"
              [value]="upcv3.groupe.alertsSincePolling.length"
              [severity]="upcv3.severityStringUI"
              [ngClass]="getAlarmSeverityClass(upcv3)"
            >
            </p-badge>
          </td>
         
          <td class="left-align">
            <a
              [ngClass]="upcv3.groupe.groupNameClass"
              routerLink="/upc/v3/groupe/{{ upcv3.groupId }}"
              container="div"
              [(pTooltip)]="upcv3.groupe.upcAlertsSinceLastPollingTooltip"
              >{{ upcv3.name }}
            </a>
          </td>
          <td>{{ upcv3.groupe.trapNumber }}</td>
          <td>
            <p-chip
              [label]="upcv3.groupe.upcGroupStatusString"
              [styleClass]="upcv3.groupe.upcGroupStatusString"
            ></p-chip>
          </td>
          <td>
            {{ upcv3.groupe._co2ResActiveString }}
          </td>
          <td>
            {{ co2LitersToKg(upcv3.groupe._co2Res1ActVol).toFixed(1) }}
          </td>
          <td>
            {{ upcv3.groupe._co2Res1ActDur }}
          </td>
          <td>
            {{ co2LitersToKg(upcv3.groupe._co2Res2ActVol).toFixed(1) }}
          </td>
          <td>
            {{ upcv3.groupe._co2Res2ActDur }}
          </td>
          <td>
            <p-button
              *ngIf="
                upcv3.comment.lastComment != null;
                else noComment
              "
              [badge]="
                toLocaleString(
                  upcv3.comment.lastCommentDate,
                  ''
                )
              "
              badgeClass="bigger"
              [label]="upcv3.comment.lastComment.message"
              (click)="onOpenCommentModal(upcv3.site, null, upcv3.groupe)"
              [style]="{ width: '100%' }"
              [styleClass]="
                'p-button-sm center max-width small-text p-button-' +
                upcv3.comment.commentClass
              "
            >
            </p-button>
            <ng-template #noComment>
              <p-button
                [badge]="
                  toLocaleString(upcv3.comment.lastComment?.date, '') || ''
                "
                badgeClass="bigger"
                icon="pi pi-plus"
                iconPos="center"
                (click)="onOpenCommentModal(upcv3.site, null, upcv3.groupe)"
                [style]="{ width: '100%' }"
                styleClass="center max-width p-button-raised p-button-outlined"
              ></p-button>
            </ng-template>
          </td>
          <td class="left-align">
            <a
              routerLink="/site/{{ upcv3.site.id }}"
              container="div"
              >{{ upcv3.site.name }}
            </a>
          </td>

          <td>
            
          </td>
          <td>
            <button
              class="button"
              [ngClass]="
                upcv3.groupe.groupPollEnableClass
              "
              (click)="togglePollModalConfirmationforGroup(upcv3.groupe)"
            >
              {{ upcv3.groupe.groupPollEnableString }}
            </button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-upcv3>
        <!--  Début template row expanson--> 
        
        <tr [@rowExpansionTrigger]="'active'" class='expanded' *ngFor="let displayableUpc of upcv3.displayableUpcs">
          
          <td>
            <p-tableCheckbox [value]="displayableUpc"></p-tableCheckbox>
          </td>
          <td>
            <i class="pi" [ngClass]="{'true-icon pi-check-circle': displayableUpc.isRembo}"></i>
          <td>
            <p-badge
              *ngIf="displayableUpc.upc.showDashboardAsterisk"
              [(pTooltip)]="displayableUpc.upc.upcAlertsSinceLastPollingTooltip"
              [value]="displayableUpc.upc.alertsSincePolling.length"
              [severity]="getAlarmSeverityString(displayableUpc)"
              [ngClass]="getAlarmSeverityClass(displayableUpc)"
            >
            </p-badge>
          </td>
          
          
          <td class="left-align">
            
            <a
              [ngClass]="displayableUpc.upc.upcNameIdClass"
              routerLink="/upc/v3/{{ displayableUpc.upc.id }}"
              container="div"
              [(pTooltip)]="displayableUpc.upc.upcAlertsSinceLastPollingTooltip"
              >{{ displayableUpc.name }}
            </a>
          </td>
          <td>
            {{ displayableUpc.upc.generalParameters.upcTrapNum }}
          </td>
          <td>
            <p-chip
              [label]="displayableUpc.upc.upcStatusString"
              [styleClass]="displayableUpc.upc.upcStatusChipStyle"
            ></p-chip>
          </td>
          <td>
            {{ displayableUpc.upc.co2ResActiveString }}
          </td>
          <td>
            {{
              co2LitersToKg(displayableUpc.upc.reservesParameters.co2Res1ActVol).toFixed(1)
            }}
          </td>
          <td>
            {{ displayableUpc.upc.reservesParameters.co2Res1ActDur }}
          </td>
          <td>
            {{
              co2LitersToKg(displayableUpc.upc.reservesParameters.co2Res2ActVol).toFixed(1)
            }}
          </td>
          <td>
            {{ displayableUpc.upc.reservesParameters.co2Res2ActDur }}
          </td>
          <td>
            <p-button
              *ngIf="displayableUpc.comment.lastComment != null; else noComment"
              [badge]="toLocaleString(displayableUpc.comment.lastCommentDate, '') || ''"
              badgeClass="bigger" 
              [label]="displayableUpc.comment.lastComment.message"
              (click)="onOpenCommentModal(displayableUpc.site, displayableUpc.upc, null)"
              [style]="{ width: '100%' }"
              [styleClass]="
                'p-button-sm center max-width small-text p-button-' +
                displayableUpc.comment.commentClass
              "
            >
            </p-button>
            <ng-template #noComment>
              <p-button
                badgeClass="bigger"
                icon="pi pi-plus"
                iconPos="center"
                (click)="onOpenCommentModal(displayableUpc.site, displayableUpc.upc)"
                [style]="{ width: '100%' }"
                styleClass="center max-width p-button-raised p-button-outlined"
              ></p-button>
            </ng-template>
          </td>
          <td class="left-align">
            <a
              routerLink="/site/{{ displayableUpc.site.id }}"
              container="div"
              >{{ displayableUpc.site.name }}
            </a>
          </td>
          <td>
            <div
              [pTooltip]="toLocaleString(displayableUpc.upc.upcLastPollDatetime, '-')"
            >
              {{ displayableUpc.lastPollingHumanReadable }}
            </div>
          </td>
          <td>
            <button
              class="button"
              [ngClass]="
              displayableUpc.upc.upcPollEnable ? 'button-success' : 'button-alert'
              "
              (click)="togglePollModalConfirmation(displayableUpc.upc)"
            >
              {{ displayableUpc.upc.upcPollEnable ? "Oui" : "Non" }}
            </button>
          </td>
        </tr>
        <!-- Fin template row Expansion -->
      </ng-template>
      <ng-template pTemplate="summary">
        <small>
          Légende
          <ul>
            <li>
              <b>Ceinture</b> : <b class="text-success">Vert</b> : Tout va bien
              | <b class="text-warning">Orange</b> : Une des réserves est sous
              le seuil vide | <b class="text-danger">Rouge</b> : Les deux
              réserves sont sous le seuil vide ou la dernière mise à jour a
              échoué
            </li>
            <li>
              <b>Contenu calculé B1 et B2</b> :
              <b class="text-danger">Rouge</b> : Le contenu est sous le seuil de
              contenu vide
            </li>
            <li>
              <b>Dernier pool</b> : <b class="text-danger">Rouge</b> : La
              dernière mise à jour a échoué ou la période a expiré
            </li>
            <li>
              <b>Pool actif</b> : <b class="text-danger">Rouge</b> : La mise à
              jour est désactivée
            </li>
          </ul>
        </small>
      </ng-template>
    </p-table>

    <!-- Legend -->

    <!-- Footer -->
    <footer class="page-footer font-small blue pt-4">
      <!-- Copyright -->
    </footer>
  </div>
</div>
