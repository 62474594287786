<div class="card mt-3">

  <!-- Loading -->
  <div class="card-body text-center" *ngIf="!years"><i class="fa fa-circle-o-notch fa-spin"></i></div>

  <!-- Form -->
  <form [formGroup]="form" (ngSubmit)="onFormSubmit()" *ngIf="years">
    <div class="card-body">

      <div class="form-group">
        <label for="year">Année de consommations</label>
        <select class="custom-select custom-select-sm" formControlName="year">
          <option [ngValue]="null" disabled>Sélectionner l'année</option>
          <option *ngFor="let year of years" [ngValue]="year">{{year}}</option>
        </select>
      </div>

      <button type="submit" class="btn btn-primary" [disabled]="formSubmitted" ngbButton>
        <i class="fa fa-circle-o-notch fa-spin" *ngIf="formSubmitted"></i>
        <span *ngIf="!formSubmitted">Télécharger</span>
      </button>

      <div class="alert alert-warning mt-3" role="alert">
        Attention ! La génération et le téléchargement du fichier peut prendre plusieurs minutes.
      </div>

    </div>
  </form>
</div>