import { Component, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { VersionService } from '../../../../../services/project/version.service';
import { ClipCanvasComponent } from './clip-canvas/clip-canvas.component';
import { CAD } from '../../../../../models/project/cad';
import { CADCanvasComponent } from './canvas/canvas.component';

@Component({
  selector: 'app-cad',
  templateUrl: './cad.component.html',
  styleUrls: ['./cad.component.css']
})
export class CADComponent {
  // NF
  @Input('cad') cad: CAD;


  // General
  showDiffusionParameters: boolean = false;

  // Canvas
  @ViewChild('canvas', { static: false }) canvas: CADCanvasComponent;

  // Clip
  @ViewChild('clipCanvas', { static: false }) clipCanvas: ClipCanvasComponent;
  dowloadImage: boolean = false;

  constructor(
    public toasterService: ToasterService,
    public versionService: VersionService
  ) { }

  @HostListener('document:CADImageShown')
  showImage() {
    setTimeout(() => {
      this.clipCanvas.init(this.canvas)
    }, 1000)
  }

}