import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Project } from '../../../models/project/project';
import { ProjectService } from '../../../services/project/project.service';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';
import { Modal } from 'src/app/models/ui/modal';
import { ToasterService } from 'angular2-toaster';
import { mergeMap, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Code } from 'src/app/models/ApiResponse';

@Component({
  selector: 'add-project-content',
  templateUrl: './add-project.component.html'
})
export class AddProjectModalComponent extends Modal<Project> {
  // Form
  addressUnknow = false;
  nameAlreadyUsed = false;
  form: FormGroup;
  formSubmitted = false;

  customPictureForm: FormGroup;
  customPictureFormSubmitted = false;
  customPictureData = null;
  readingFile = false;
  loadingCustomPicture = false;


  // Map
  @ViewChild('map', { static: false }) map: AgmMap;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private cd: ChangeDetectorRef,
    private toasterService: ToasterService, 
  ) {
    super();

    // Form
    this.form = this.fb.group({
      name: ['', Validators.required],
      client: ['', Validators.required],
      address: ['', Validators.required],
      lat: [null, Validators.required],
      lng: [null, Validators.required],
      zoom: 0,
      hbmDistance: [null, Validators.required],
      customPicture: false
    });

    this.customPictureForm = this.fb.group({
      file: [ null, Validators.required ]
    });


    // On location change
    this.router.events.subscribe(() => this.close());

  }

  // Geolcate
  geolocate() {
    new window['google'].maps.Geocoder().geocode({ 'address': this.f.address.value }, function(results, status) {
      if (status == 'OK') {
        // Update form
        this.form.patchValue({
          address: results[0].formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          zoom: 15
        });
        if(this.map) {
          this.map.latitude = results[0].geometry.location.lat();
          this.map.longitude = results[0].geometry.location.lng();
          this.map.triggerResize();
        }
        
        this.geoCalculate();

      } else {
        this.addressUnknow = true;
      }
    }.bind(this));
  }

  geoCalculate() {
    new window['google'].maps.DistanceMatrixService().getDistanceMatrix({
      origins: [ new window['google'].maps.LatLng(43.6585209, 7.1359285) ],
      destinations: [ new window['google'].maps.LatLng(this.form.controls.lat.value, this.form.controls.lng.value) ],
      travelMode: google.maps.TravelMode.DRIVING
    }, (response, status) => {
      if (status == 'OK') {
        if (
          response.rows.length > 0 &&
          response.rows[0].elements.length > 0 &&
          response.rows[0].elements[0].distance != undefined
        ) {
          // Update form
          this.form.patchValue({
            hbmDistance: response.rows[0].elements[0].distance.value / 1000
          })
        }
      } else {
        alert('Distance matrix was not successful for the following reason: ' + status);
      }
    })
  }

  // Map
  dragEnd(event) {
    this.form.patchValue({
      lat: event.coords.lat,
      lng: event.coords.lng
    });
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid
    if (this.form.invalid)
      return;

    // Get map zoom
    this.form.patchValue({
      zoom: this.map?.zoom ?? 4
    });

    // Try create
    this.formSubmitted = true;
    if (this.f.customPicture.value) {
      this.projectService.create(this.form).pipe(
        mergeMap((res) => {
          if(res.code != Code.PROJECT_CREATED) {
            if(res.code == Code.PROJECT_ALREADY_EXSIST) {
              this.nameAlreadyUsed = true;
              this.formSubmitted = false;
            }
            else {
              console.error("Error trying to create the project with custom picture")
              this.close(true);
            }
            return throwError(() => new Error(`Error trying to create the project with custom picture`));
          }
          return this.projectService.uploadCustomPicture(res.result.id, this.customPictureForm)
        }
      )
      ).subscribe((resUploadImage) => {
        this.close(true);
      });
    } else {
      this.projectService.create(this.form).subscribe(res => {
        switch (res.code) {
          case Code.PROJECT_CREATED:
          case Code.WRONG_PARAMS:
          case Code.UNAUTHORIZED:
          this.close(true);
          break;

          case Code.PROJECT_ALREADY_EXSIST:
          this.nameAlreadyUsed = true;
          this.formSubmitted = false;
          break;
        }
      })
    }
     
  }

  // File
  onFileChange(event) {
    const reader = new FileReader();
 
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      // Check file size
      var filesize = ((file.size / 1024) / 1024); // MB
      if (filesize > 100) {
        this.toasterService.pop('error', 'Le fichier sélectionné dépasse la limite des 100 Mo !');
        event.target.value = null;
        return;
      }

      this.readingFile = true;
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        this.customPictureForm.patchValue({
          file: new File([new Blob([reader.result])], file.name)
        });
      
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
        this.readingFile = false;
      };
    }
  }

  get cpf() { return this.customPictureForm.controls }
  onCustomPictureFormSubmit() {
    // stop here if form is invalid
    if (this.customPictureForm.invalid)
      return;

    // Try edit
    this.customPictureFormSubmitted = true;
    this.projectService.uploadCustomPicture(this.subject.id, this.customPictureForm).subscribe(
      res => {
        switch (res.code) {

          case Code.PROJECT_EDITED:
          this.customPictureFormSubmitted = false;
          break;

          case Code.WRONG_PARAMS:
          case Code.PROJECT_DOESNT_EXSIST:
          case Code.UNAUTHORIZED:
          this.close(true);
          break;

        }
      },
      () => this.close()
    )
  }

}