import { Component, OnInit } from '@angular/core';
import {  NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Version, VersionType } from 'src/app/models/project/version';
import { Modal } from 'src/app/models/ui/modal';
import { VersionService } from 'src/app/services/project/version.service';
import { Belt } from 'src/app/models/project/belt';

@Component({
  selector: 'app-versionmodal',
  templateUrl: './versionmodal.component.html',
  styleUrls: ['./versionmodal.component.css']
})
export class VersionmodalComponent extends Modal<any> implements OnInit {
  versions = [];
  version;
  constructor(private versionService : VersionService) { 
    super();
  }

  ngOnInit() {
    localStorage.removeItem("idversion");
    var version = JSON.parse(localStorage.getItem("versions"));
    if (version != null){
      version.forEach(item=>{
      
        //if(Version.loadFromJSON(item).versionType == VersionType.INSTALLATION){
          
          var vr = Belt.loadFromJSON(item);
          vr.versionString = vr.version.versionTypeToString + " " +vr.version.numberOrder+ " - "+vr.trapsNumber+" MP"+" - "+vr.name;
          this.versions.push(vr);
  
        //}
        
        //this.versionService.get(item.version);
      })
    } else {
      this.versions = [];
    }
    
    
  }
  syncPieges() {
  
    localStorage.setItem("idversion",this.version.id);
    this.close();
  }
  
}
