<div class="row mt-3">
  <div class="col-12">
    <div class="card">
      <!-- Paramétrer ceinture -->
      
      <!-- Table -->
      <div class="table-responsive">
        <table class="table table-striped table-sm text-center" style="font-size: .85rem;">
            <thead>
              <tr>
                
                <th rowspan="3">UPC</th>
                <th rowspan="3">UUID</th>
                
              </tr>
              
            </thead>
            <tbody>
              <tr *ngFor="let upc of upcnnoinit;let i = index;" [ngClass]="{ 'text-primary': upc.hibernated }">
                
                <td class="font-weight-bold p-1">
                  
                    <a class="text-primary" (click)="onTestUPC();">{{' '+upc.upcBoxId }}</a>
                    <a class="text-primary" *ngIf="upc.upcBoxId === ''" (click)="onTestUPC();">{{'BioBelt'}}</a>
                </td>
                <td>{{ upc.upcMcuUid }}</td>
                
                
                
              </tr>
            </tbody>
          </table>
      </div>

      <!-- Loading -->
      <div class="col-12 mb-3 text-center" *ngIf="!upcnnoinit"><i class="fa fa-circle-o-notch fa-spin"></i></div>
      
      
      
    </div>
  </div>
</div>
