<div *ngIf="group">
  <div class="row">
    <hr />
    <!-- Status -->
    <div class="col-12">
      <div class="card card-body border-top-0">
        <div class="row">
          <!-- CO2 Status -->
          <div class="col-lg-4">
            <div class="card bg-light mb-2">
              <div class="card-body">
                <h5 class="card-title">Status CO2</h5>
                <dl class="row mb-0">
                  <!-- co2ResType -->
                  <dd class="col-md-6 mb-0">Caractère de la réserve</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{ red: group._co2ResTypeString === null }"
                      value="{{
                        group._co2ResTypeString === null
                          ? 'Voir ceintures individuelles'
                          : group._co2ResTypeString
                      }}"
                    />
                  </dt>

                  <!-- co2ResActive -->
                  <dd class="col-md-6 mb-0">Réserve active</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{ red: group._co2ResActiveString === null }"
                      value="{{
                        group._co2ResActiveString === null
                          ? 'Voir ceintures individuelles'
                          : group._co2ResActiveString
                      }}"
                    />
                  </dt>

                  <!-- co2ResActPrev -->
                  <dd class="col-md-6 mb-0">Est. rés. active vide</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    {{ group._co2ResActPrev.toLocaleString() }}
                  </dt>

                  <!-- co2ResInactPrev -->
                  <dd class="col-md-6 mb-0">Est. rés. inactive vide</dd>
                  <dt class="col-md-6 mb-0 text-md-right">
                    {{ group._co2ResInactPrev.toLocaleString() }}
                  </dt>
                </dl>
              </div>
            </div>
          </div>

          <!-- B1 -->
          <div class="col-sm-6 col-lg-4">
            <div class="card bg-light mb-2">
              <div class="card-body">
                <h5 class="card-title">B1</h5>
                <dl class="row mb-0">
                  <!-- co2Res1Enabled -->
                  <dd class="col-md-6 mb-0">Utilisée ?</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <ng-template
                      [ngIf]="group._co2Res1Enabled === null"
                      [ngIfElse]="res1EnabledSame"
                    >
                      <div [ngClass]="'red'">Voir ceintures individuelles</div>
                    </ng-template>
                    <ng-template #res1EnabledSame>
                      <ui-switch
                        size="small"
                        disabled
                        [ngModel]="group._co2Res1Enabled"
                      >
                      </ui-switch>
                    </ng-template>
                  </dt>
                  <!-- co2Res1Status -->
                  <dd class="col-md-6 mb-0">État du contenu</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{
                        red: group._co2Res1StatusString === null
                      }"
                      value="{{
                        this.group._co2Res1StatusString === null
                          ? 'Voir ceintures individuelles'
                          : group._co2Res1StatusString
                      }}"
                    />
                  </dt>

                  <!-- co2Res1ActVol -->
                  <dd class="col-md-6 mb-0">Contenu actuel [kg]</dd>
                  <dt class="col-md-6 mb-0 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{ red: group._co2Res1ActVol === null }"
                      value="{{ co2LitersToKg(group._co2Res1ActVol.toFixed(1)) | number }}"
                    />
                  </dt>
                  <br /><br />
                  <dd class="col-md-6 mb-0">Capacité [kg]</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{ red: group._co2Res1StartVol === null }"
                      value="{{ co2LitersToKg(group._co2Res1StartVol.toFixed(1)) | number }}"
                    />
                  </dt>
                  <br /><br />
                </dl>
              </div>
            </div>
          </div>

          <!-- B2 -->
          <div class="col-sm-6 col-lg-4">
            <div class="card bg-light mb-2">
              <div class="card-body">
                <h5 class="card-title">B2</h5>
                <dl class="row mb-0">
                  <!-- co2Res2Enabled -->
                  <dd class="col-md-6 mb-0">Utilisée ?</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <ng-template
                      [ngIf]="group._co2Res2Enabled === null"
                      [ngIfElse]="res2EnabledSame"
                    >
                      <div [ngClass]="'red'">Voir ceintures individuelles</div>
                    </ng-template>
                    <ng-template #res2EnabledSame>
                      <ui-switch
                        size="small"
                        disabled
                        [ngModel]="group._co2Res2Enabled"
                      >
                      </ui-switch>
                    </ng-template>
                  </dt>
                  <!-- co2Res2Status -->
                  <dd class="col-md-6 mb-0">État du contenu</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{
                        red: group._co2Res2StatusString === null
                      }"
                      value="{{
                        group._co2Res2StatusString === null
                          ? 'Voir ceintures individuelles'
                          : group._co2Res2StatusString
                      }}"
                    />
                  </dt>

                  <!-- co2Res2ActVol -->
                  <dd class="col-md-6 mb-0">Contenu actuel [kg]</dd>
                  <dt class="col-md-6 mb-0 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{ red: group._co2Res2ActVol === null }"
                      value="{{ co2LitersToKg(group._co2Res2ActVol.toFixed(1)) | number }}"
                    />
                  </dt>
                  <br /><br />
                  <dd class="col-md-6 mb-0">Capacité [kg]</dd>
                  <dt class="col-md-6 mb-2 text-md-right">
                    <input
                      disabled
                      class="custom-select custom-select-sm"
                      [ngClass]="{ red: group._co2Res2StartVol === null }"
                      value="{{ co2LitersToKg(group._co2Res2StartVol.toFixed(1)) | number }}"
                    />
                  </dt>
                  <br /><br />
                </dl>
              </div>
            </div>
          </div>

          <!-- Bouteilles B1-->
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h5>Bouteilles en B1</h5>
                <bottles-table [bottles]="bottlesB1" [displayedColumns]="displayedColumns"></bottles-table>
              </div>
            </div>
          </div>

          <!-- Bouteilles B2-->
          <div class="col-lg-6">
            <div class="card bg-light">
              <div class="card-body">
                <h5>Bouteilles en B2</h5>
                <bottles-table [bottles]="bottlesB2" [displayedColumns]="displayedColumns"></bottles-table>
              </div>
            </div>
          </div>

          <!-- Bouteilles Site-->
          <div class="col-lg-12 mt-2">
            <div class="card bg-light">
              <div class="card-body">
                <h5>Bouteilles sur Site</h5>
                <bottles-table [bottles]="bottlesSite" [displayedColumns]="displayedColumns"></bottles-table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
