<div class="row mt-3" *ngIf="!site">
  <div class="col-12 text-center"><i class="fa fa-circle-o-notch fa-spin"></i></div>
</div>

<div class="container-fluid" *ngIf="site">
  <div class="row mt-3">
    <!-- Title -->
    <div class="col-12">
      <h4 class="float-left">Site - {{ site.name }}</h4>
      <div class="button-group">
        <button type="button" class="btn btn-primary" (click)="openAssociateBeltModal()" *ngIf="authService.isAdmin"><i class="fa fa-link"></i> Associer</button>
        <button type="button" class="btn btn-secondary" (click)="openCreateBeltModal()" *ngIf="authService.isAdmin"><i class="fa fa-plus"></i> UPC-V3</button>
        <button type="button" class="btn p-button-raised btn-info" (click)="openCreateGroupe()" *ngIf="authService.isAdmin"><i class="fa fa-link"></i> Créer un groupe</button>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a ngbNavLink>UPCs</a>
          <ng-template ngbNavContent>
            <!-- UPCs -->
            <div class="row mt-2">
              <div class="col-12">
                <h5>UPCs</h5>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <ul class="list-group list-group-striped">
                  <!-- Header -->
                  <li class="list-group-item d-none d-md-block">
                    <div class="row">
                      <div class="col-10">
                        <div class="row">
                          <p class="col-4 mb-0 font-weight-bold">Nom</p>
                          <p class="col-4 mb-0 font-weight-bold">Nombre de pièges</p>
                          <p class="col-2 mb-0 font-weight-bold">Status</p>
                          <p class="col-2 mb-0 font-weight-bold">Archivée?</p>
                        </div>
                      </div>
                      <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
                    </div>
                  </li>

                  <!-- Loading & Empty -->
                  <li class="list-group-item px-3 py-2 text-center" *ngIf="!site.upcv3s"><i class="fa fa-circle-o-notch fa-spin"></i></li>
                  <li class="list-group-item px-3 py-2 text-center" *ngIf="(site.upcv3s) && (site.upcv3s.length == 0)">Aucune ceinture</li>

                  <!-- Row -->
                  <ng-container *ngIf="site.upcv3s">

                    <!-- Row - UPC-V3 -->
                    <li class="list-group-item px-3 py-1" *ngFor="let upc of site.upcv3s">
                      <div class="row">
                        <div class="col-10">
                          <a class="row no-link" routerLink="/upc/v3/{{ upc.id }}">
                            <p class="col-md-4 mt-1 mb-0 font-weight-bold"><small class="d-md-none mr-2">Nom</small>{{ upc.upcNameId }} <small>(UPC-V3)</small></p>
                            <p class="col-md-4 mt-1 mb-0"><small class="d-md-none mr-2">Nombre de pièges</small>{{ upc.generalParameters.upcTrapNum }}</p>
                            <p class="col-md-2 mt-1 mb-0">
                              <small class="d-md-none mr-2">Status</small>
                              <span class="badge badge-info" *ngIf="upc.hibernated">Hivernage</span>
                              <span class="badge badge-success" *ngIf="!upc.hibernated">Active</span>
                            </p>
                            <p class="col-md-2 mt-1 mb-0">
                              <small class="d-md-none mr-2">Archivée?</small>
                              <span class="badge badge-info" *ngIf="upc.archived == true">Oui</span>
                              <span class="badge badge-success" *ngIf="upc.archived == false">Non</span>
                            </p>
                          </a>
                        </div>
                        <div class="col-2 mb-0 text-right">
                          <div placement="bottom-right" ngbDropdown>
                            <button class="btn btn-link text-dark px-3 py-0" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
                            <div class="p-0" ngbDropdownMenu>
                              <button class="dropdown-item px-3 py-2" (click)="openDissociateBeltModal(upc.id, 3)" *ngIf="authService.isAdmin"><i class="fa fa-unlink"></i> Dissocier</button>
                              <button class="dropdown-item px-3 py-2" (click)="openArchiveUpcModal(upc.id)" *ngIf="authService.isAdmin"><i class="fa fa-trash"></i> Archiver</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <!-- Groups -->
            <div class="row mt-5">
              <div class="col-12">
                <h5>Groupes d'UPC</h5>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12">
                <ul class="list-group list-group-striped">
                  <!-- Header -->
                  <li class="list-group-item d-none d-md-block">
                    <div class="row">
                      <div class="col-10">
                        <div class="row">
                          <p class="col-4 mb-0 font-weight-bold">Nom</p>
                          <p class="col-4 mb-0 font-weight-bold">Nombre de pièges</p>
                          <p class="col-2 mb-0 font-weight-bold">Status</p>
                          <p class="col-2 mb-0 font-weight-bold">Archivée?</p>
                        </div>
                      </div>
                      <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
                    </div>
                  </li>
  
                  <!-- Loading & Empty -->
                  <li class="list-group-item px-3 py-2 text-center" *ngIf="!site.groupUPCV3s"><i class="fa fa-circle-o-notch fa-spin"></i></li>
                  <li class="list-group-item px-3 py-2 text-center" *ngIf="(site.groupUPCV3s) && (site.groupUPCV3s.length == 0)">Aucun groupe</li>
  
                  <!-- Row -->
                  <ng-container *ngIf="site.groupUPCV3s">
  
                    <!-- Row - UPC-V3 -->
                    <li class="list-group-item px-3 py-1" *ngFor="let groupUpc of site.groupUPCV3s">
                      <div class="row">
                        <div class="col-10">
                          <a class="row no-link" routerLink="/upc/v3/groupe/{{ groupUpc.id }}">
                            <p class="col-md-4 mt-1 mb-0 font-weight-bold"><small class="d-md-none mr-2">Nom</small>{{ groupUpc.name }} <small>(Groupe UPC-V3)</small></p>
                            <p class="col-md-4 mt-1 mb-0"><small class="d-md-none mr-2">Nombre de pièges</small>{{ groupUpc.trapNumber }}</p>
                            <p class="col-md-2 mt-1 mb-0">
                              <small class="d-md-none mr-2">Status</small>
                              <span class="badge badge-secondary" *ngIf="groupUpc.hibernated === undefined">###</span>
                              <span class="badge badge-info" *ngIf="groupUpc.hibernated">Hivernage</span>
                              <span class="badge badge-success" *ngIf="groupUpc.hibernated === false">Active</span>
                            </p>
                            <p class="col-md-2 mt-1 mb-0">
                              <small class="d-md-none mr-2">Archivée?</small>
                              <span class="badge badge-info" *ngIf="groupUpc.archived == true">Oui</span>
                              <span class="badge badge-success" *ngIf="groupUpc.archived == false">Non</span>
                            </p>
                          </a>
                        </div>
                        <div class="col-2 mb-0 text-right">
                          <div placement="bottom-right" ngbDropdown>
                            <button class="btn btn-link text-dark px-3 py-0" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
                            <div class="p-0" ngbDropdownMenu>
                              <button class="dropdown-item px-3 py-2" (click)="openArchiveGroupModal(groupUpc.id)" *ngIf="authService.isAdmin && groupUpc.archived == false"><i class="fa fa-unlink"></i> Archiver</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <h5>Bouteilles stockées sur site</h5>
              </div>
              <div class="col-12 mb-3">
                <bottles-table
                  *ngIf="storedBottles"
                  [bottles]="storedBottles"
                ></bottles-table>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>Interventions</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 mb-2">
                <button type="button" class="btn btn-primary float-right" (click)="openAddInterventionModal()"><i class="fa fa-plus mr-1"></i>Intervention</button>
              </div>  
            </div>
            <app-site-interventions [interventionsOwner]="site"></app-site-interventions>
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
