import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse, Code } from '../../models/ApiResponse';
import { environment } from '../../../environments/environment';
import { Version } from '../../models/project/version';
import { ToasterService } from 'angular2-toaster';
import { Belt } from 'src/app/models/project/belt';

const strings = {
  titles : {
    added: 'Version ajoutée', notAdded: 'Version non ajoutée',
    synchronized: 'Version synchronisée', notSynchronized: 'Version non synchronisée'
  },
  bodies: {
    internalError: 'Erreur interne',
    versionDoesntExsist: 'Version inexistant',
    projectDoesntExsist: 'Projet inexistant',
    unauthorized: 'Opération non autorisée'
  }
}

@Injectable({
  providedIn: 'root',
})
export class VersionService {

  constructor(
    private http: HttpClient,
    private toasterService: ToasterService
  ) { }

  // Codes: VERSION_RECOVERED | VERSION_DOESNT_EXSIST | UNAUTHORIZED
  public get(id: string): Observable<ApiResponse<Version>> {
    return this.http.get<ApiResponse<Version>>(environment.apiUrl + 'version/' + id).pipe(map(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
          res.result = Version.loadFromJSON(res.result);
          break;

          case Code.VERSION_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.bodies.versionDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: VERSION_CREATED | WRONG_PARAMS | INTERNAL_ERROR | PROJECT_DOESNT_EXSIST | VERSION_ALREADY_EXSIST | UNAUTHORIZED
  public create(form: FormGroup): Observable<ApiResponse<Version>> {
    return this.http.post<ApiResponse<Version>>(environment.apiUrl + 'version', form.value).pipe(map(
      res => {
        switch (res.code) {

          case Code.VERSION_CREATED:
          res.result = Version.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.added);
          break;

          case Code.WRONG_PARAMS:
          case Code.INTERNAL_ERROR:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.internalError);
          break;

          case Code.PROJECT_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.projectDoesntExsist);
          break;

          case Code.VERSION_ALREADY_EXSIST:
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notAdded, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: VERSION_SYNCHRONIZED | VERSION_DOESNT_EXSIST | UNAUTHORIZED
  public sync(version: Version): Observable<ApiResponse<Version>> {
    
    return this.http.post<ApiResponse<Version>>(environment.apiUrl + 'version/' + version.id + '/sync', version).pipe(map(
      res => {
        switch (res.code) {

          case Code.VERSION_SYNCHRONIZED:
          res.result = Version.loadFromJSON(res.result);
          this.toasterService.pop('success', strings.titles.synchronized);
          break;

          case Code.VERSION_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.titles.notSynchronized, strings.bodies.versionDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.titles.notSynchronized, strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }

  // Codes: none
  public excelSheet(id: string): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('accept', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    return this.http.get(environment.apiUrl + 'version/' + id + '/excelSheet', { headers: headers, responseType: 'arraybuffer' })
  }

  // Codes: VERSION_RECOVERED | VERSION_DOESNT_EXSIST | UNAUTHORIZED
  public getBelt(id: string): Observable<ApiResponse<Belt>> {
    return this.http.get<ApiResponse<Belt>>(environment.apiUrl + 'version/belt/' + id).pipe(map(
      res => {
        switch (res.code) {

          case Code.VERSION_RECOVERED:
          res.result = Belt.loadFromJSON(res.result);
          break;

          case Code.VERSION_DOESNT_EXSIST:
          this.toasterService.pop('error', strings.bodies.versionDoesntExsist);
          break;

          case Code.UNAUTHORIZED:
          this.toasterService.pop('error', strings.bodies.unauthorized);
          break;

        }

        return res;
      }
    ))
  }
  public getBelts(name : string) : Observable<ApiResponse<Belt[]>> {
    return this.http.get<ApiResponse<Belt[]>>(environment.apiUrl+"version/getBelts/"+name).pipe(map(
      res=>{
        
        switch (res.code) {
          case Code.VERSION_RECOVERED :
            var belt : Belt[] = [];
            res.result.forEach(item=>{belt.push(Belt.loadFromJSON(item))});
            res.result = belt;
            break;
          case Code.VERSION_DOESNT_EXSIST:
            this.toasterService.pop('error', strings.bodies.versionDoesntExsist);
            break;

          case Code.UNAUTHORIZED:
            this.toasterService.pop('error', strings.bodies.unauthorized);
            break;
        }
        return res;
      }
    ))
  }

}
