import { NgbActiveModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export class Modal<T> {
  subject: T;
  subject2: T;
  modalRef: NgbModalRef;
  callBack: Function;
  public activeModal: NgbActiveModal;

  init(subject: T, modalRef: NgbModalRef, callBack: Function) {
    this.subject = subject;
    this.modalRef = modalRef;
    this.callBack = callBack;
  }
  init2(subject: T, subject2: T, modalRef: NgbModalRef, callBack: Function) {
    this.subject = subject;
    this.subject2 = subject2;
    this.modalRef = modalRef;
    this.callBack = callBack;
  }
  close(callback: boolean = false) {
    
    this.modalRef.close(1);
    if (callback) this.callBack();
  }
}