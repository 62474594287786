import { ToasterService } from 'angular2-toaster';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UPCV3, co2LitersToKg } from '../../../../models/upcv3/upc/upcv3';
import { UPCV3Service } from '../../../../services/upcv3/upcv3.service';
import { NgbModal } from '../../../../../../node_modules/@ng-bootstrap/ng-bootstrap';
import { LinkUPCV3ModalComponent } from './link-upcv3/link-upcv3.component';
import { InitUPCModalComponent } from './init-upc/init-upc.component';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { RestoreUPCModalComponent } from './restore-upc/restore-upc.component';
import { Site } from 'src/app/models/management/site';
import { CommentmodalComponent } from 'src/app/pages/dashboard/commentmodal/commentmodal.component';
import { Commentaires } from 'src/app/models/upcv3/commentaire';
import { WipeUPCModalComponent } from './wipe-upc/wipe-upc.component';
import _ from 'lodash';


@Component({
  selector: 'app-upc',
  templateUrl: './upc.component.html',
  styleUrls: ["./upc.component.css"]
})
export class UPCComponent implements OnInit {
  // UPC-V3
  upcv3: UPCV3;
  site: Site;
  activeId: number = 1;

  lastCommentforUpc: Commentaires;
  commentairesClass: string
  upccvnoInit: any;

  constructor(
    private fb: FormBuilder,

    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    public authService: AuthService,
    private upcv3Service: UPCV3Service,
  ) { }

  ngOnInit() {
    // Load upc
    this.route.params.subscribe(params => {
      this.upcv3Service.get(params['id']).subscribe({
        next: upc => {
          this.upcv3 = upc;
          if(upc.groupUpcv3 != null) {
            this.site = upc.groupUpcv3.site;
            this.upcv3.site = this.site;
          } else {
            this.site = upc.site;
          }
          this.lastCommentforUpc = this.upcv3.lastComment;
          this.commentairesClass = this.upcv3.lastActionNotDone ? 'warning' : 'primary';
        },
        error: (err) => {
          this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération de l'upc");
          this.router.navigateByUrl('/sites');
        }
      })
    })
  }

  co2LitersToKg = co2LitersToKg;

  onOpenCommentModal() {

    var commentModal = this.modalService.open(CommentmodalComponent);

    commentModal.componentInstance.init({ site: this.site, upc: this.upcv3, group: null }, commentModal, () => {});
    commentModal.result.then(data => {
      this.ngOnInit();

    }, (reason) => {
      this.ngOnInit();
    })


  }
  // UPC-V3
  openLinkUpcv3Modal() {
    var linkUpcv3ModalRef = this.modalService.open(LinkUPCV3ModalComponent);
    linkUpcv3ModalRef.componentInstance.init(this.upcv3, linkUpcv3ModalRef, () => location.reload())
  }

  openInitUpcv3Modal() {
    var initUpcv3ModalRef = this.modalService.open(InitUPCModalComponent);
    initUpcv3ModalRef.componentInstance.init(this.upcv3.id, initUpcv3ModalRef, () => {
      this.toasterService.pop("success", "Operation Réussie", "L'upc a entamé son Initialisation");
    }, () => {
      this.toasterService.pop("error", "Erreur", "Impossible de lancer l'initialisation, cette opération est déja peut-être en cours.");
    });
  }

  openWipeUpcv3Modal() {
    var wipeUpcv3ModalRef = this.modalService.open(WipeUPCModalComponent);
    wipeUpcv3ModalRef.componentInstance.init(this.upcv3.id, wipeUpcv3ModalRef, () => {
      this.toasterService.pop("success", "Operation Réussie", "L'upc a été Wipe avec succès");
    }, () => {
      this.toasterService.pop("error", "Erreur", "Impossible de se connecter à l'UPC et d'effectuer le WIPE");
    });
  }

  openRestoreUpcv3Modal() {
    var restoreUpcv3ModalRef = this.modalService.open(RestoreUPCModalComponent);
    restoreUpcv3ModalRef.componentInstance.init(this.upcv3.id, restoreUpcv3ModalRef, () => {
      this.toasterService.pop("success", "Operation Réussie", "L'upc entame sa restauration");
    }, () => {
      this.toasterService.pop("error", "Erreur", "Impossible de demarrer l'operation de restauration, cette opération est déja peut être en cours");
    });
  }

  immediatePoll() {
    this.upcv3Service.poll(this.upcv3.id).subscribe({
      next: () => this.toasterService.pop('success', 'Polling initié'),
      error: () => this.toasterService.pop("error", "Erreur", "Une erreur c'est produit au lancement du polling")
    });
  }

  onUpcv3Changed(upcv3: UPCV3) {
    this.upcv3 = upcv3;
  }

  togglePoll(upc: UPCV3, upcPollEnable: boolean) {
    this.upcv3Service.edit(upc.id, this.fb.group({ upcPollEnable })).subscribe({
      next: res => upc.upcPollEnable = res.upcPollEnable,
      error: () => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de l'activation/désactivation du polling")
    })
  }

  toggleHibernated(upc: UPCV3, hibernated: boolean) {
    this.upcv3Service.edit(upc.id, this.fb.group({ hibernated })).subscribe({
      next: res => upc.hibernated = res.hibernated,
      error: () => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de l'activation/désactivation de l'hibernage")
    })
  }

  openAddInterventionModal(id: number) {
    this.router.navigate(["site/"+this.site.id+"/intervention/add"], { queryParams: { upcv3Id: this.upcv3.id }});
  }

  toLocaleString(date: Date, defaultValue: string = "-"): string {
    if (date == null || _.isEqual(date, new Date(0))) {
      return defaultValue;
    }
    return date.toLocaleString("fr-FR");
  }

  onConfigurationShown() {
    this.ngOnInit();
  }
}