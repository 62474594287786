export class Project {
  id: string = '';

	name: string = '';
	client: string = '';

	address: string = '';
	lat: number = 0;
	lng: number = 0;
	zoom: number = 0;
	distance: number = 0;

	pictureSource: PictureSource = PictureSource.GOOGLE

	customPictureFilename: string = '';
	customPicturePixelsPerMeter: number = 0;
	
	get pixelsPerMeter(): number {

		// PPM calculation
		if (this.pictureSource == PictureSource.GOOGLE) {
			var scale = 2;
			return (156543.03392 * Math.cos(this.lat * Math.PI / 180) / Math.pow(2, this.zoom)) / scale;
		}
		
		return this.customPicturePixelsPerMeter;

	}
  
  static loadFromJSON(json): Project {
		return Object.assign(new Project, json)
  }
}

export enum PictureSource {
	GOOGLE = 'GOOGLE',
	CUSTOM = 'CUSTOM'
}