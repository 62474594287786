import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project/project';
import { Version, VersionType } from 'src/app/models/project/version';
import { ProjectService } from 'src/app/services/project/project.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VersionService } from 'src/app/services/project/version.service';
import { SiteService } from 'src/app/services/management/site.service';
import { Router } from '@angular/router';
import { Stock } from 'src/app/models/management/stock';
import { StockService } from 'src/app/services/management/stock.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { DefaultUPCV3ParamsService } from 'src/app/services/upcv3/defaultUPCV3Params.service';
import { DefaultUPCV3Params } from 'src/app/models/upcv3/defaultUPCV3Params';
import { intensities, daysCodes } from 'src/app/models/upcv3/upc/diffCo2Program';
import { ToasterService } from 'angular2-toaster';
import { Code } from 'src/app/models/ApiResponse';
import { SitePostDto } from 'src/app/models/dto/SitePost.dto';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'add-site-content',
  templateUrl: './add-site.component.html',
  styleUrls: ['./add-site.component.css']
})
export class AddSiteComponent implements OnInit {
  // Project
  projects: Project[];
  selectedProjectId: string;
  project: Project;

  versions: Version[];
  selectedVersionId: string;
  version: Version;
  loadingVersion = false;

  // Stock
  stocks: Stock[];

  // Position
  addressUnknow = false;

  // Belts
  defaultUPCV3Params: DefaultUPCV3Params;
  belts: UPCV3[] = [];
  intensities: any[] = intensities;
  daysCodes: any[] = daysCodes;

  // Form
  form: FormGroup;
  formSubmitted = false;
  nameAlreadyUsed = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,

    private projectService: ProjectService,
    private versionService: VersionService,
    private siteService: SiteService,
    private stockService: StockService,
    private defaultUPCV3ParamsService: DefaultUPCV3ParamsService,
    private toasterService: ToasterService,
  ) {

    // Form
    this.form = this.fb.group({
      clientNumber: '',
      name: ['', Validators.required],
      infos: '',
      address: '',
      postcode : '',
      city: '',
      departement: '',
      lat: 0,
      lng: 0,
      stocks: null,
      version: null,
      belts: []
    })

  }

  ngOnInit() {
    // Load general parameters
    this.loadGeneralParameters();

    // Load projects
    this.searchProjects();

    // Load stocks
    this.loadStocks();
  }

  // Project
  loadGeneralParameters() {
    this.defaultUPCV3ParamsService.get().subscribe({
      next: (res) => this.defaultUPCV3Params = res,
      error: (err) => this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors du chargement des Paramètres UPC par défaut")
    })
  }

  fakeSearch(term, item) { return item }

  searchProjects(filter: string = '') {
    this.projectService.getPage(10, 0, filter).subscribe({
      next: (res) => this.projects = res.result.content,
      error: (err) => {
        this.toasterService.pop('error', "Erreur", "Une erreur s'est produite lors du chargement des projets");
        this.projects = [];
      }
    })
  }

  loadVersions() {
    // Show only installation
    if (this.selectedProjectId) {
      this.projectService.getVersions(this.selectedProjectId).subscribe(
        res => {
          switch (res.code) {

            case Code.VERSION_RECOVERED:
              this.versions = res.result.filter(v => v.versionType == VersionType.INSTALLATION)
              break;

            case Code.PROJECT_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
              this.versions = [];
              break;

          }
        }
      )
    } else {
      this.selectedVersionId = null;
      this.version = null;
      this.form.reset();
    }
  }
  versionSelected() {
    // Get project
    if (this.selectedProjectId && this.selectedVersionId) {
      this.loadingVersion = true;
      this.projectService.get(this.selectedProjectId).subscribe(
        res => {
          switch (res.code) {

            case Code.PROJECT_RECOVERED:
              this.project = res.result;

              // Get full version
              this.versionService.get(this.selectedVersionId).subscribe(
                res => {
                  switch (res.code) {

                    case Code.VERSION_RECOVERED:
                      this.version = res.result;

                      // Update form
                      this.form.patchValue({ name: this.project.name, address: this.project.address, lat: this.project.lat, lng: this.project.lng, version: this.version.id });

                      // Parse belt
                      this.version.belts.forEach(belt => {
                        var upcv3 = new UPCV3(this.defaultUPCV3Params);
                        upcv3.upcNameId = belt.name;
                        upcv3.generalParameters.upcTrapNum = belt.trapsNumber;
                        upcv3.lat = this.project.lat;
                        upcv3.lng = this.project.lng;

                        upcv3.generalParameters.co2PresOutRef1 = Math.round(belt.pressureInt1 * 1000);
                        upcv3.generalParameters.co2PresOutRef2 = Math.round(belt.pressureInt2 * 1000);
                        upcv3.generalParameters.co2PresOutRef3 = Math.round(belt.pressureInt3 * 1000);
                        upcv3.generalParameters.co2PresOutRef4 = Math.round(belt.pressureInt4 * 1000);
                        upcv3.generalParameters.co2PresOutRef5 = Math.round(belt.pressureInt5 * 1000);
                        upcv3.generalParameters.co2PresOutRef6 = Math.round(belt.pressureInt6 * 1000);
                        upcv3.generalParameters.co2PresOutRef7 = Math.round(belt.pressureInt7 * 1000);
                        upcv3.generalParameters.co2PresOutRef8 = Math.round(belt.pressureInt8 * 1000);
                        upcv3.generalParameters.co2PresOutRef9 = Math.round(belt.pressureInt9 * 1000);
                        upcv3.generalParameters.co2PresOutRef10 = Math.round(belt.pressureCalculated * 1000);



                        upcv3.belt = belt.id;

                        this.belts.push(upcv3);
                      })
                      break;

                    case Code.VERSION_DOESNT_EXSIST:
                    case Code.UNAUTHORIZED:
                      break;

                  }

                  this.loadingVersion = false;
                }
              );
              break;

            case Code.PROJECT_DOESNT_EXSIST:
            case Code.UNAUTHORIZED:
              this.loadingVersion = false;
              this.version = null;
              break;

          }
        }
      )
    } else {
      // Reset form
      this.form.reset();
    }
  }

  // Stock
  loadStocks() {
    this.stockService.getAll().subscribe({
      next: res => this.stocks = res,
      error: err => this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors de la récupération des stocks"),
    })
  }

  // Position
  geolocate() {
    new window['google'].maps.Geocoder().geocode({ 'address': this.f.address.value }, (results, status) => {
      if (status == 'OK') {
        // Update form
        this.form.patchValue({
          address: results[0].formatted_address,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
          postcode: results[0].address_components.find(a => a.types.includes('postal_code'))?.long_name,
          city: results[0].address_components.find(a => a.types.includes('locality'))?.long_name,
          departement: results[0].address_components.find(a => a.types.includes('administrative_area_level_2'))?.long_name
        })
      } else {
        this.addressUnknow = true;
      }
    });
  }

  // Form
  get f() { return this.form.controls }
  onFormSubmit() {
    // stop here if form is invalid

    if (this.form.invalid) return;

    // Parse belts
    this.form.patchValue({
      belts: this.belts
    });

    // Try create
    this.formSubmitted = true;
    const newSiteDto: SitePostDto = {
      ...(this.form.value.name != null && { name: this.form.value.name }),
      ...(this.form.value.clientNumber != null && { clientNumber: this.form.value.clientNumber }),
      ...(this.form.value.infos != null && { infos: this.form.value.infos }),
      ...(this.form.value.address != null && { address: this.form.value.address }),
      ...(this.form.value.latitude != null && { latitude: this.form.value.latitude }),
      ...(this.form.value.longitude != null && { longitude: this.form.value.longitude }),
      ...(this.form.value.stocks != null && { stocks: this.form.value.stocks }),
      ...(this.form.value.postcode != null && { postcode: this.form.value.postcode }),
      ...(this.form.value.city != null && { city: this.form.value.city }),
      ...(this.form.value.departement != null && { departement: this.form.value.departement }),
    }
    this.siteService.create(newSiteDto).subscribe({
      next: (res) => {
        this.toasterService.pop("success", "Succès", "Le nouveau site a été créé avec succès");
        this.router.navigateByUrl('/site/' + res.id);
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 409) {
          this.toasterService.pop('error', "Erreur", "Une erreur s'est produite, un site avec le même nom doit surement déja exister");
          this.nameAlreadyUsed = true;
          this.formSubmitted = false;
        } else {
          this.toasterService.pop('error', "Erreur", "Une erreur s'est produite...");
        }
      }
    })
  }
}