import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';
import { MaterialModule } from '../../material-module';
import { ParameterBeltsAlarmsComponent } from './alarms/parameters-alarms.component';
import { ParameterbeltsComponent } from './parameterbelts.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    UiSwitchModule,
    NgbModule,
  ],
  declarations: [
    ParameterbeltsComponent,
    ParameterBeltsAlarmsComponent,
  ],
  exports: [
    ParameterBeltsAlarmsComponent,
  ]
})
export class ParameterbeltsModule {}