import { Component, Input, Output, EventEmitter } from "@angular/core";
import { GDP } from "src/app/models/gdp/gdp";

@Component({
  selector: 'gdp-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent {
  // NF
  @Input('gdp') gdp: GDP;
  @Output('opacityChange') opacityChange = new EventEmitter();

  constructor() { }

  /* Methods */
  updateOpacity() {
    this.opacityChange.emit();
  }

}