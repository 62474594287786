<!-- Search -->
<div class="col-sm-6 col-lg-4 offset-lg-5 mb-2">
  <mat-form-field>
    <mat-label>Filtre</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
  </mat-form-field>
</div>

<mat-table [dataSource]="dataSource" matSort class="w-100">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <!-- CodeBarre Column  -->
  <ng-container matColumnDef="barcode">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Code-Barre</mat-header-cell>
    <mat-cell *matCellDef="let rack">{{ rack.barcode }}</mat-cell>
  </ng-container>

  <!-- NbOfBottles Column  -->
  <ng-container matColumnDef="numberOfBottles">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de bouteilles</mat-header-cell>
    <mat-cell *matCellDef="let rack">{{ rack.bottles.length }}</mat-cell>
  </ng-container>

  <!-- Action Column  -->
  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let rack">
      <div>
        <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #actionsMenu="matMenu">
          <button mat-menu-item (click)="showBottles(rack)">
            <mat-icon>list</mat-icon>
            <span>Voir les bouteilles</span>
          </button>
          <button mat-menu-item (click)="openReturnToSupplier(rack)" [disabled]="!permitReturnToSupplier">
            <mat-icon>keyboard_return</mat-icon>
            <span>Renvoyer au fournisseur</span>
          </button>
        </mat-menu>
      </div>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<!-- Pagination -->
<div class="col-12 mb-3">
  <mat-paginator #paginator class="paginator" showFirstLastButtons [length]="totalData" [pageSizeOptions]="pageSizeOptions">
  </mat-paginator>
</div>