<div class="container-fluid">
  <div class="row mt-3">

    <div class="col-12 mb-3 text-center" *ngIf="!project"><i class="fa fa-circle-o-notch fa-spin"></i></div>

    <!-- Title -->
    <div class="col-12 mb-2" *ngIf="project">
      <h4 class="float-left">Projet - {{ project.name }}</h4>

      <button type="button" class="btn btn-primary float-right" (click)="openAddVersionModal()"><i class="fa fa-plus"></i> Version</button>
    </div>

    <!-- Content -->
    <div class="col-12 mb-3" *ngIf="project">
      <ul class="list-group list-group-striped">

        <!-- Header -->
        <li class="list-group-item d-none d-md-block">
          <div class="row">
            <div class="col-10">
              <div class="row">
                <p class="col-3 mb-0 font-weight-bold">Version</p>
                <p class="col-3 mb-0 font-weight-bold">N° Ordre</p>
                <p class="col-3 mb-0 font-weight-bold">Création</p>
                <p class="col-3 mb-0 font-weight-bold">Édition</p>
              </div>
            </div>
            <p class="col-2 mb-0 font-weight-bold text-right">Actions</p>
          </div>
        </li>

        <!-- Loading & Empty -->
        <li class="list-group-item px-3 py-2 text-center" *ngIf="!versions"><i class="fa fa-circle-o-notch fa-spin"></i></li>
        <li class="list-group-item px-3 py-2 text-center" *ngIf="versions && versions?.length == 0">Aucune version</li>

        <!-- Row -->
        <li class="list-group-item px-3 py-1" *ngFor="let version of versions">
          <div class="row">
            <div class="col-10">
              <a class="row no-link" routerLink="version/{{ version.id }}">
                <p class="col-md-3 mt-1 mb-0 font-weight-bold"><small class="d-md-none mr-2">Version</small>{{ version.versionTypeToString }}</p>
                <p class="col-md-3 mt-1 mb-0"><small class="d-md-none mr-2">N° Ordre</small>{{ version.numberOrder }}</p>
                <p class="col-md-3 mt-1 mb-0"><small class="d-md-none mr-2">Création</small>{{ version.creationDate.toLocaleString() }}</p>
                <p class="col-md-3 mt-1 mb-0"><small class="d-md-none mr-2">Édition</small>{{ version.lastEditionDate.toLocaleString() }}</p>
              </a>
            </div>
            <div class="col-2 mb-0 text-right">
              <div placement="bottom-right" ngbDropdown>
                <button class="btn btn-link text-dark px-3 py-0" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
                <div class="p-0" ngbDropdownMenu>
                  <button class="dropdown-item px-3 py-2" (click)="openEditVersionModal(version)"><i class="fa fa-edit"></i> Modifier</button>
                  <button class="dropdown-item px-3 py-2" (click)="openDeleteVersionModal(version)" *ngIf="authService.isAdmin"><i class="fa fa-trash"></i> Supprimer</button>
                </div>
              </div>
            </div>
          </div>
        </li>

      </ul>
    </div>

  </div>
</div>