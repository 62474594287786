<div class="container py-5 px-4">
  <!-- For demo purpose-->

  <div class="row rounded-lg overflow-hidden shadow">
    <!-- Users box-->

    <!-- Chat Box-->
    <div class="col-12 px-0 flex">
      <div style="min-height: 50vh; overflow-y: 'scroll'">
        <div *ngFor="let comment of comments">
          <div *ngIf="comment.auteur.id !== auteur.id">
            <div class="media w-80 mb-3 ml-auto p-2">
              <div class="col-2">
                <div class="row justify-content-center">
                  <p-avatar
                    [style]="{
                      'background-color': '#0c5c27',
                      color: '#ffffff'
                    }"
                    size="large"
                    label="{{
                      comment.auteur?.firstName.toUpperCase()[0] +
                        comment.auteur?.lastName.toUpperCase()[0]
                    }}"
                    shape="circle"
                  ></p-avatar>
                </div>
                <div class="mt-1 row text-center small justify-content-center text-muted" >
                  {{comment.auteur?.firstName + ' ' + comment.auteur?.lastName}}
                </div>
              </div>
              <div class="media-body ml-3">
                <div class="bg-primary rounded py-2 px-3 mb-0">
                  <p class="text-small mb-0 text-light">
                    {{ comment.message }}
                  </p>
                </div>
                
                <div class="small clearfix text-muted">
                  <span *ngIf="comment.toBeDone === true && comment.done === false" class="badge badge-pill badge-warning">Todo</span>
                  <span *ngIf="comment.done === true" class="badge badge-pill badge-success">Fait</span>                  
                  {{ comment.date.toLocaleString("fr-FR") }}
                  <button 
                  *ngIf="comment.toBeDone === true && comment.done === false"                    type="button"
                    style="border-radius: 1rem"  
                    class="mt-1 btn btn-outline-primary btn-sm float-right"
                    [ngbPopover]="popConfirmationComment"
                    popOverTitle="Demande de confirmation"
                    >
                    Marquer comme fait
                  </button>
                  <ng-template #popConfirmationComment>
                    Etes vous sûre de vouloir marqué ce commentaire comme 'Fait' ? 
                    <div class="clearfix">
                      <button
                      (click)="markCommentAsDone(comment)"
                      type="button"
                      class=" float-right mt-1 btn btn-primary btn-sm"
                      >
                        Oui
                      </button>
                    </div>
                  </ng-template>
                </div>

              </div>
            </div>
          </div>

          <div *ngIf="comment.auteur.id === auteur.id">
            <div class="media w-80 mb-3 p-2">

              <div class="media-body mr-3">
                <div class="bg-light rounded py-2 px-3 mb-2">
                  <p class="text-small mb-0 text-muted">
                    {{ comment.message }}
                  </p>
                </div>
                       
                <div class="small clearfix text-muted">
                  <span *ngIf="comment.toBeDone === true && comment.done === false" class="badge badge-pill badge-warning">Todo</span>
                  <span *ngIf="comment.done === true" class="badge badge-pill badge-success">Fait</span>                  
                  {{ comment.date.toLocaleString("fr-FR") }}
                  <button 
                  *ngIf="comment.toBeDone === true && comment.done === false"
                    type="button"
                    style="border-radius: 1rem"  
                    class="mt-1 btn btn-outline-primary btn-sm float-right"
                    [ngbPopover]="popConfirmationComment"
                    popOverTitle="Demande de confirmation"
                    >
                    Marquer comme fait
                  </button>
                  <ng-template #popConfirmationComment>
                    Etes vous sûre de vouloir marqué ce commentaire comme 'Fait' ? 
                    <div class="clearfix">
                      <button
                      (click)="markCommentAsDone(comment)"
                      type="button"
                      class=" float-right mt-1 btn btn-primary btn-sm"
                      >
                        Oui
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="col-2">
                <div class="row justify-content-center">
                  <p-avatar
                    [style]="{
                      'background-color': '#0c5c27',
                      color: '#ffffff'
                    }"
                    size="large"
                    label="{{
                      comment.auteur?.firstName.toUpperCase()[0] +
                        comment.auteur?.lastName.toUpperCase()[0]
                    }}"
                    shape="circle"
                  ></p-avatar>
                </div>
                <div class="mt-1 row text-center small justify-content-center text-muted" >
                  {{comment.auteur?.firstName + ' ' + comment.auteur?.lastName}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Typing area -->
      <form (ngSubmit)="postComment()" [formGroup]="commentForm">
        <input
          type="text"
          placeholder="Message"
          aria-describedby="button-addon2"
          class="form-control rounded-0 border-0 py-4 bg-light"
          formControlName="comment"
        />
        <div class="row mt-3">
          <div class="col">
            <div class="form-inline">
              <div class="form-group mt-2">
                <label for="todoSwitch">A faire</label>
                <p-inputSwitch
                  inputId="todoSwitch"
                  formControlName="markAsToDo"
                  aria-label="A faire"
                  class="ml-2"
                ></p-inputSwitch>
              </div>
            </div>
          </div>
          <div class="ml-5 col">
            <div class="input-group-append">
              <button
                id="button-addon2"
                type="submit"
                class="btn btn-primary btn-block mt-2"
              >
                <i class="fa fa-paper-plane mr-2"></i>Envoyer
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
