import { Component, OnInit, Input } from '@angular/core';
import { Test } from '../../../../../models/upcv3/test/test';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-upc-tests',
  templateUrl: './tests.component.html'
})
export class TestsComponent implements OnInit {
  @Input('upcv3') upcv3: UPCV3;
  tests: Test[];

  constructor(
    private upcv3Service: UPCV3Service,
    private toasterService: ToasterService,
  ) { }

  ngOnInit() {
    // Load tests
    this.upcv3Service.getTests(this.upcv3.id).subscribe({
      next: tests => this.tests = tests,
      error: err => this.toasterService.pop("error","Erreur", "Une rreur s'est produite lors de la récupération des tests")
    })
  }

  abs(number) {
    return Math.abs(number).toFixed(2)
  }

}