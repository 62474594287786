<div id="cad-clip-canvas" class="p-3">

  <div class="card" style="position: absolute; left: 15px; top: 15px; right: 15px; bottom: 15px;">
    <div class="card-header p-2">Enregistrer l'image</div>
    
    <div id="cad-clip-canvas-card-body" class="card-body position-relative p-0" style="overflow: hidden;">
      <div #clipRect class="resizable">
        <div class='resizers'>
          <div class='resizer top-left'></div>
          <div class='resizer top-right'></div>
          <div class='resizer bottom-left'></div>
          <div class='resizer bottom-right'></div>
        </div>
      </div>
      <canvas #clipCanvas id="clip-canvas" style="width: 100%; height: 100%;"></canvas>
      <input #range type="range" class="custom-range" min="0" max="50" step="1" value="5" (change)="draw()">
    </div>
    
    <div class="card-footer text-muted p-2">
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Annuler</button>
      <button type="button" class="btn btn-success btn-sm float-right" (click)="download()">Enregistrer</button>
    </div>
  </div>

</div>