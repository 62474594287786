<div class="card">
  <div class="card-header p-2">Paramètrer les diffusions</div>
  <div class="card-body p-0">
    <table class="table text-center">
      <thead>
        <tr>
          <th class="p-1"></th>
          <th class="p-1">S1</th>
          <th class="p-1">S2</th>
          <th class="p-1">S3</th>
          <th class="p-1">S4</th>
          <th class="p-1">Intensité</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="p-1">Janvier</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.januaryDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.januaryDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.januaryDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.januaryDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.januaryDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Février</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.februaryDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.februaryDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.februaryDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.februaryDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.februaryDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Mars</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.marchDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.marchDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.marchDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.marchDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.marchDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Avril</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.aprilDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.aprilDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.aprilDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.aprilDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.aprilDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Mai</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.mayDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.mayDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.mayDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.mayDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.mayDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Juin</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.juneDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.juneDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.juneDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.juneDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.juneDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Juillet</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.julyDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.julyDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.julyDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.julyDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.julyDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Août</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.augustDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.augustDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.augustDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.augustDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.augustDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Septembre</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.septemberDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.septemberDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.septemberDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.septemberDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.septemberDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Octobre</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.octoberDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.octoberDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.octoberDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.octoberDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.octoberDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Novembre</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.novemberDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.novemberDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.novemberDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.novemberDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.novemberDiff[4]" min="0" max="10"></td>
        </tr>
        <tr>
          <th class="p-1">Décembre</th>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.decemberDiff[0]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.decemberDiff[1]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.decemberDiff[2]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.decemberDiff[3]" min="0" max="100"></td>
          <td class="p-1"><input type="number" [(ngModel)]="cad.version.decemberDiff[4]" min="0" max="10"></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="card-footer text-muted p-2">
    <button type="button" class="btn btn-secondary btn-sm" (click)="cad.showDiffusionParameters = false">Fermer</button>
  </div>
</div>