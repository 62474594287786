import { Component, OnInit, Input, OnDestroy, Injectable } from "@angular/core";
import { UPCV3 } from "src/app/models/upcv3/upc/upcv3";
import { UPCV3Service } from "src/app/services/upcv3/upcv3.service";
import { NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { forkJoin, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { GroupUPCV3 } from "src/app/models/GroupUPCV3/GroupUPCV3";

export interface CalendarData {
  from: NgbDate;
  to: NgbDate;
}

@Component({
  selector: "app-upc-group-stats",
  templateUrl: "./groupStats.component.html",
  styleUrls: ["./groupStats.component.css"],
})
export class GroupStatsComponent implements OnInit, OnDestroy {
  @Input("group") upcv3Group: GroupUPCV3;

  // Stats
  wrongRange: boolean = false;
  from: NgbDate;
  to: NgbDate;
  max: NgbDate;

  private onDestroy$: Subject<void> = new Subject<void>();
  dateChangeEvent: Subject<CalendarData> = new Subject<CalendarData>();

  constructor(
    public calendar: NgbCalendar,
    private upcv3Service: UPCV3Service
  ) {
    this.from = calendar.getPrev(calendar.getToday(), "d", 28);
    this.to = calendar.getToday();
    this.max = calendar.getToday();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  ngOnInit() {}

  navChanged() {
    // https://github.com/swimlane/ngx-charts/issues/650
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  onDateSelection() {
    if (!this.calendar.isValid(this.from) || !this.calendar.isValid(this.to))
      return;

    if (
      new NgbDate(this.from.year, this.from.month, this.from.day).after(this.to)
    ) {
      this.wrongRange = true;
      return;
    }
    //this.from is a string in case it is not valid when entered in the text field
    if (typeof this.from === "string" || typeof this.to === "string") return;

    this.wrongRange = false;
    this.dateChangeEvent.next({
      from: this.from,
      to: this.to,
    }); //send Event to child components
  }
}
