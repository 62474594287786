import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Modal } from 'src/app/models/ui/modal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InterventionV3Service } from 'src/app/services/upcv3/interventionV3.service';
import { Commentaires } from 'src/app/models/upcv3/commentaire';
import { ToasterService } from 'angular2-toaster';
import { Operator } from 'src/app/models/management/operator';
import { OperatorService } from 'src/app/services/management/operator.service';
import { UPCV3Service } from 'src/app/services/upcv3/upcv3.service';
import { SiteService } from 'src/app/services/management/site.service';
import { Site } from 'src/app/models/management/site';
import { FormControl, FormGroup } from '@angular/forms';
import { CommentairePostDto } from 'src/app/models/dto/CommentairePost.dto';
import { CommentaireService } from 'src/app/services/management/commentaire.service';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';
import { GroupUPCV3 } from 'src/app/models/GroupUPCV3/GroupUPCV3';

@Component({
  selector: 'app-commentmodal',
  templateUrl: './commentmodal.component.html',
  styleUrls: ['./commentmodal.component.css']
})
export class CommentmodalComponent extends Modal<any> implements OnInit {
  @ViewChild('edit', { static: false }) private editContainer: ElementRef;
  comments: Commentaires[] = [];
  site: Site;

  upc?: UPCV3;
  group?: GroupUPCV3;


  @ViewChild('scrollMe', { static: false }) private myScrollContainer: ElementRef;
  public event: EventEmitter<any> = new EventEmitter();
  private ngbModalRef: NgbModalRef;

  commentForm = new FormGroup({
    comment: new FormControl(''),
    markAsToDo: new FormControl(false),
  })

  auteur: Operator;
  constructor(
    private toasterService: ToasterService,
    private siteService: SiteService,
    private operatorService: OperatorService,
    private commentaireService: CommentaireService) {
    super();

    this.operatorService.getConnected().subscribe({
      next: (res) => this.auteur = res,
      error: (err) => {
        this.toasterService.pop("error", "Erreur", "Erreur lors de la récupération des informations de l'utilisateur connecté");
        this.modalRef.close(1)
      }
    })
  }

  ngOnInit() {
    this.upc = this.subject?.upc ?? null;
    this.group = this.subject?.group ?? null;
    this.site = this.subject.site;

    if(this.upc != null && this.group != null) {
      this.toasterService.pop("error", "Erreur", "Impossible d'ajouter un commentaire a la fois sur un groupe et une UPC");
      this.modalRef.close(1)
    }

    this.commentaireService.getBySiteId(this.site.id).subscribe({
      next: (comments) => {
        let commentsSorted = [...comments].sort((a,b)=> a.date.getTime() - b.date.getTime())
        this.comments = commentsSorted.filter((comment) => {
          return comment.upcv3?.id == this.upc?.id && comment.groupUPCV3?.id == this.group?.id;
        })
      },
      error: err => {
        this.toasterService.pop("error", "Erreur" , "Une erreur s'est produite lors de la récupération des commentaires");
        this.modalRef.close(1);
      }
    });

  }

  postComment() {
    let comment: CommentairePostDto = {
      auteurId: this.auteur.id,
      message: this.commentForm.value.comment,
      objet: "object",
      toBeDone: this.commentForm.value.markAsToDo,
      ...(this.upc != null ? {upcv3Id: this.upc.id} : null),
      ...(this.group != null ? {groupUpcv3Id: this.group.id} : null),
      showDashboard: true,
    }
  
    this.siteService.addComment(this.site.id, comment).subscribe({
      next: (res) => {
        this.toasterService.pop("success", "Commentaire envoyé")
        this.modalRef.close(0)
      },
      error: (err) => {
        this.toasterService.pop("error", "Echec de l'envoie du commentaire");
        this.modalRef.close(1)
      }

    })
  }

  markCommentAsDone(comment: Commentaires) {
    this.commentaireService.markAsDone(comment.id).subscribe({
      next: (res) => this.ngOnInit(),
      error: (err) => {
        console.error(err)
        this.toasterService.pop("error", "Erreur", "Une erreur s'est produite lors du marquage de ce commentaire comme Fait")
      }
    })
  }
}
