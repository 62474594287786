import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '../../models/ApiResponse';
import { Commentaires } from 'src/app/models/upcv3/commentaire';

@Injectable({
  providedIn: 'root',
})
export class CommentaireService {

  constructor(
    private http: HttpClient,
  ) { }

  // Codes: BOTTLE_TYPE_RECOVERED | UNAUTHORIZED
  public markAsDone(id: number): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}commentaire/${id}/done`, {}).pipe(map(
      res => { return; }
    ))
  }

  public getBySiteId(siteId: string): Observable<Array<Commentaires>> {
    return this.http.get<ApiResponse<Array<Commentaires>>>(`${environment.apiUrl}commentaire/getBySite/${siteId}`).pipe(map(
      res => res.result.map(json => Commentaires.loadFromJSON(json))
    ));
  }
}