import { Component, Type, Input} from '@angular/core';
import { ComponentFixtureAutoDetect } from '@angular/core/testing';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UPCV3 } from 'src/app/models/upcv3/upc/upcv3';

@Component({
  selector: 'app-confirmation-poll-modal-content',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Gestion pooling des UPC</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Etes vous sûre de vouloir <strong>{{this.upc.upcPollEnable ? 'désactiver': 'activer'}}</strong> le polling sur le l'UPC
        <span class="text-primary">
        {{this.upc.upcNameId}}
      </span>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close(false)">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">Ok</button>
  </div>
  `
})

export class ConfirmationPollModalContent {
  @Input() upc: UPCV3;
  constructor(public modal: NgbActiveModal) {  }
}
