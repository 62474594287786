import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-tests',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">{{getTitleString()}}</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <app-upcmcu-tests [upcMcu]="upcMcu"></app-upcmcu-tests>
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.close(true)">Ok</button>
    </div>
  `
})

export class ModalWithUpcMcuTestsComponent {
  @Input() upcMcu: any;
  constructor(public modal: NgbActiveModal) { }

  public getTitleString(): string {
    const commonSection = `Aperçu des tests pour le Mcu ${this.upcMcu.upcMcuUid}`;
    if (this.upcMcu.upcv3Name === null) {
      return `${commonSection} attaché à aucune ceinture pour le moment`;
    } else {
      return `${commonSection} attaché à la ceinture ${this.upcMcu.upcv3Name}`;
    }
  }
}
