<div class="container-fluid">
  <div class="row mt-3">
    <div class="col-12 mb-3 text-center" *ngIf="!upcv3">
      <i class="fa fa-circle-o-notch fa-spin"></i>
    </div>

    <!-- Alerts -->
    <div class="col-12">
      <ngb-alert
        [dismissible]="false"
        *ngIf="upcv3 && !upcv3.generalParameters.upcMcuUid.length"
        (click)="openLinkUpcv3Modal()"
        style="cursor: pointer"
      >
        <strong>Attention !</strong> Cette ceinture n'a pas encore de boitier
        associé, cliquez ici pour en sélectionner un.
      </ngb-alert>
    </div>

    <!-- Title -->
    <div class="col-12 mb-2">
      <h4 class="float-left" *ngIf="upcv3">Ceinture - {{ upcv3.upcNameId }}</h4>

      <ng-container *ngIf="upcv3 && upcv3.generalParameters.upcMcuUid.length">
        <button
          type="button"
          class="btn btn-primary float-right"
          (click)="immediatePoll()"
          [disabled]="upcv3.communicationParameters.comGsmIpAdr == '0.0.0.0'"
        >
          <i class="fa fa-refresh"></i> Poll immédiat
        </button>
        <button
          type="button"
          class="btn btn-danger float-right mr-2"
          (click)="openInitUpcv3Modal()"
          [disabled]="upcv3.communicationParameters.comGsmIpAdr == '0.0.0.0'"
        >
          <i class="fa fa-upload"></i> Initialiser
        </button>
        <button
          type="button"
          class="btn btn-warning float-right mr-2"
          (click)="openRestoreUpcv3Modal()"
          [disabled]="upcv3.communicationParameters.comGsmIpAdr == '0.0.0.0'"
        >
          <i class="fa fa-download"></i> Restorer
        </button>
        <button
          type="button"
          class="btn btn-danger float-right mr-2"
          (click)="openWipeUpcv3Modal()"
          [disabled]="upcv3.communicationParameters.comGsmIpAdr == '0.0.0.0'"
        >
          <i class="fa fa-warning"></i> WIPE
        </button>
      </ng-container>
    </div>
    <h6 class="ml-5" *ngIf="upcv3 && upcv3.id != -1 && site">
      {{ site.address }}
    </h6>
    <!-- Status -->
    <div class="col-12 mb-3" *ngIf="upcv3">
      <div class="card">
        <div class="table-responsive">
          <!-- UPC-V3-->
          <table class="table table-sm text-center mb-0">
            <thead>
              <tr>
                <th rowspan="3">Pièges</th>
                <th rowspan="3">Mode diff.</th>
                <th colspan="9">Reserves de CO2</th>
                <th rowspan="3">Dern. visite</th>
                <th rowspan="3">Dernier pool</th>
                <th rowspan="3">Pool actif</th>
                <th rowspan="3">Hivernage</th>
              </tr>
              <tr>
                <th rowspan="2">Reserve active</th>
                <th colspan="2">B1</th>
                <th colspan="2">B2</th>
                <!--<th colspan="4">Dern. remplissage</th>-->
              </tr>
              <tr>
                <th>Cont. calc.</th>
                <th>Durée est.</th>
                <th>Cont. calc.</th>
                <th>Durée est.</th>
                <th>Commentaires</th>
              </tr>
            </thead>
            <tbody>
              <tr [ngClass]="{ 'text-primary': upcv3.hibernated }">
                <td>{{ upcv3.generalParameters.upcTrapNum }}</td>
                <td [ngClass]="upcv3.upcStatusClass">
                  {{ upcv3.upcStatusString }}
                </td>
                <td>{{ upcv3.co2ResActiveString }}</td>
                <td [ngClass]="upcv3.co2Res1ActVolClass">
                  {{
                    co2LitersToKg(
                      upcv3.reservesParameters.co2Res1ActVol
                    ).toFixed(1)
                  }}
                  kg
                </td>
                <td [ngClass]="upcv3.co2Res1ActVolClass">
                  {{ upcv3.reservesParameters.co2Res1ActDur }} jours
                </td>
                <td [ngClass]="upcv3.co2Res2ActVolClass">
                  {{
                    co2LitersToKg(
                      upcv3.reservesParameters.co2Res2ActVol
                    ).toFixed(1)
                  }}
                  kg
                </td>
                <td [ngClass]="upcv3.co2Res2ActVolClass">
                  {{ upcv3.reservesParameters.co2Res2ActDur }} jours
                </td>

                <td colspan="4">
                  <div>
                    <p-button
                      *ngIf="lastCommentforUpc != null; else noComment"
                      [badge]="toLocaleString(lastCommentforUpc.date, '')"
                      badgeClass="bigger"
                      [label]="lastCommentforUpc.message"
                      (click)="onOpenCommentModal()"
                      [style]="{ width: '100%' }"
                      [styleClass]="
                        'p-button-sm center max-width small-text p-button-' +
                        commentairesClass
                      "
                    >
                    </p-button>
                    <ng-template #noComment>
                      <p-button
                        badgeClass="bigger"
                        icon="pi pi-plus"
                        iconPos="center"
                        (click)="onOpenCommentModal()"
                        [style]="{ width: '100%' }"
                        styleClass="center max-width p-button-raised p-button-outlined"
                      ></p-button>
                    </ng-template>
                  </div>
                </td>

                <!--<td>{{ upcv3.reservesParameters.co2Res1FillTime?.toLocaleDateString() }}</td>
                <td>{{ upcv3.co2LettersToKg(upcv3.reservesParameters.co2Res1FillVol).toFixed(1) }} kg</td>
                <td>{{ upcv3.reservesParameters.co2Res2FillTime?.toLocaleDateString() }}</td>
                <td>{{ upcv3.co2LettersToKg(upcv3.reservesParameters.co2Res2FillVol).toFixed(1) }} kg</td>-->
                <td>{{ upcv3.logLastVisit?.toLocaleDateString() }}</td>
                <td
                  placement="top"
                  [ngClass]="upcv3.upcLastPollDatetimeClass"
                  ngbTooltip="{{
                    upcv3.upcLastPollDatetime
                      ? upcv3.upcLastPollDatetime.toLocaleString()
                      : '-'
                  }}"
                  container="body"
                >
                  {{ upcv3.upcLastPollDatetimeString }}
                </td>
                <td
                  [ngClass]="upcv3.upcPollEnableClass"
                  (click)="togglePoll(upcv3, !upcv3.upcPollEnable)"
                  style="cursor: pointer"
                >
                  {{ upcv3.upcPollEnable ? "Oui" : "Non" }}
                </td>
                <td
                  (click)="toggleHibernated(upcv3, !upcv3.hibernated)"
                  style="cursor: pointer"
                >
                  {{ upcv3.hibernated ? "Oui" : "Non" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Content -->
    <div class="col-12 mb-3" *ngIf="upcv3">
      <ul ngbNav #nav="ngbNav" class="nav-pills" [(activeId)]="activeId">
        <!-- Configuration -->
        <li *ngIf="upcv3" [ngbNavItem]="1">
          <a ngbNavLink (click)="onConfigurationShown()">Configuration</a>
          <ng-template ngbNavContent>
            <app-upc-parameters
              [upcv3]="upcv3"
              (upcv3Changed)="onUpcv3Changed($event)"
            ></app-upc-parameters>
          </ng-template>
        </li>

        <!-- Interventions -->
        <li [ngbNavItem]="2">
          <a ngbNavLink>Interventions</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 mb-2">
                <button type="button" class="btn btn-primary float-right" (click)="openAddInterventionModal()"><i class="fa fa-plus mr-1"></i>Intervention</button>
              </div>  
            </div>
            <app-site-interventions [interventionsOwner]="upcv3" [displayedColumns]='["start", "objet", "b1", "b2", "site", "stock", "rapport"]'></app-site-interventions>
          </ng-template>
        </li>

        <!-- Event -->
        <li [ngbNavItem]="3" *ngIf="upcv3">
          <a ngbNavLink>Événements</a>

          <ng-template ngbNavContent>
            <app-upc-events [upcv3]="upcv3"></app-upc-events>
          </ng-template>
        </li>

        <!-- Stats -->
        <li [ngbNavItem]="4" *ngIf="upcv3">
          <a ngbNavLink>Statistiques</a>
          <ng-template ngbNavContent>
            <app-upc-stats [upcv3]="upcv3"></app-upc-stats>
          </ng-template>
        </li>

        <!-- Tests -->
        <li [ngbNavItem]="5" *ngIf="authService.isAdmin && upcv3">
          <a ngbNavLink>Tests UPC</a>
          <ng-template ngbNavContent>
            <app-upc-tests [upcv3]="upcv3"></app-upc-tests>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
