import { jsonObject, jsonMember, TypedJSON, jsonArrayMember } from 'typedjson';
import { UPCV3 } from './upcv3';
import { CardTest } from '../test/cardTest';
import { BoxTest } from '../test/boxTest';


@jsonObject
export class UpcMcu {
  @jsonMember
  upcMcuUid: string;

  @jsonArrayMember(CardTest)
  cardTests: Array<CardTest>;

  @jsonArrayMember(BoxTest)
  boxTests: Array<BoxTest>;

  @jsonArrayMember(UPCV3)
  upcv3: Array<UPCV3>;

  @jsonMember
  upcv3Id: number;

  @jsonMember
  upcv3Name: string;

  static loadFromJSON(json): UpcMcu {
		let serializer = new TypedJSON(UpcMcu);
		return serializer.parse(json);
	}
}


