import { TypedJSON, jsonArrayMember, jsonMember, jsonObject } from 'typedjson';
import { Bottle } from "./bottle";
import { Stock } from './stock';
import { Site } from './site';

export enum LocationType  {
  SITE = "site",
  STOCK = "stock",
  RACK = "rack",
}

@jsonObject({
  typeResolver: sourceObject => {
    switch (sourceObject.locationType as LocationType) {
      case LocationType.SITE:
        return Site;
      case LocationType.STOCK:
        return Stock;
      case LocationType.RACK:
        return Rack;
    }
    return Location;
  }
})
export abstract class Location {
  @jsonMember
  id: string;

  @jsonMember
  name: string;

  @jsonMember
  address: string;

  @jsonMember
  postcode: string;

  @jsonMember
  city: string;

  @jsonMember
  departement: string;

  @jsonArrayMember(() => Bottle)
  bottles: Bottle[];

  @jsonMember({constructor: String})
  locationType: LocationType;
};

// Je dois le mettre ici sinon ça rentre dans un bordel d'erreurs d'importations
// circulaires
@jsonObject
export class Rack extends Location {
  @jsonMember
  barcode: string;

  @jsonMember
  location: Location;

  static loadFromJSON(json): Rack {
    let serializer = new TypedJSON(Rack);
		return serializer.parse(json);
  }
}